
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { usePopupDeleteStore } from '@/store/modules/popup-delete';
import BaseIcon from '@/components/Icons/BaseIcon.vue';
import Button from '@/components/share/Button/index.vue';

export default {
  components: { BaseIcon, Button },
  setup() {
    const i18n = useI18n();
    const t = i18n.t;
    const store = usePopupDeleteStore();
    const dialogContent = ref<HTMLDivElement>();
    const optionDelete = computed(() => store.state.optionDelete);

    const close = () => {
      store.dispatch('hide');
    };
    const onDelete = async (onSubmit) => {
      if (onSubmit) {
        await onSubmit();
      }
      close();
    };
    const clickOutside = (e) => {
      if (!dialogContent.value?.contains(e.target)) {
        close();
      }
    };

    return {
      t,
      optionDelete,
      dialogContent,
      close,
      onDelete,
      clickOutside,
    };
  },
};
