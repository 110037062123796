import { ref } from 'vue';
import { getAuthority, updateAuthority } from '@/api/authority';
import { AuthorityData, FunctionPolicies } from '../types/Authority';
import { convertErrorFromServerToJson } from '@/util/useValidateForm';

export function useGetListAuthority() {
  const authorityData = ref<AuthorityData | Record<string, never>>({});

  async function getAuthorityListData(params) {
    try {
      const res = await getAuthority(params);
      switch (res.status) {
        case 200:
          authorityData.value = res.data.data;
          break;
        default:
          authorityData.value = {};
          break;
      }
    } catch (error) {
      console.error(error);
    }
  }

  return { authorityData, getAuthorityListData };
}

export function handleUpdateAuthority(params: FunctionPolicies[]) {
  const errors = ref({});
  const errorsCommon = ref<string>();
  return {
    update: (async () => {
      const response = await updateAuthority(params);
      switch (response.status) {
        case 200:
          return null;
        default:
          if (!response.data.errors[0].source) {
            errorsCommon.value = response.data.errors[0].title;
          } else {
            errors.value = convertErrorFromServerToJson(response.data.errors);
          }
          return {
            errors,
            errorsCommon,
          };
      }
    })(),
  };
}
