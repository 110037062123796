<template>
  <svg
    width="24"
    height="48"
    viewBox="0 0 24 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M12 0V24M24 24H12M12 24V48" stroke="#333333" />
  </svg>
</template>
