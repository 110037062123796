<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.875 3.75H19.5C19.6989 3.75 19.8897 3.82902 20.0303 3.96967C20.171 4.11032 20.25 4.30109 20.25 4.5V7.12497"
      stroke="#333333"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16.875 20.25H19.5C19.6989 20.25 19.8897 20.171 20.0303 20.0304C20.171 19.8897 20.25 19.6989 20.25 19.5V16.875"
      stroke="#333333"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.125 20.25H4.5C4.30109 20.25 4.11032 20.171 3.96967 20.0304C3.82902 19.8897 3.75 19.6989 3.75 19.5V16.875"
      stroke="#333333"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.125 3.75H4.5C4.30109 3.75 4.11032 3.82902 3.96967 3.96967C3.82902 4.11032 3.75 4.30109 3.75 4.5V7.12497"
      stroke="#333333"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12 13.5C13.6569 13.5 15 12.1569 15 10.5C15 8.84315 13.6569 7.5 12 7.5C10.3431 7.5 9 8.84315 9 10.5C9 12.1569 10.3431 13.5 12 13.5Z"
      stroke="#333333"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.02344 16.5C7.50147 15.5945 8.21749 14.8366 9.09437 14.308C9.97125 13.7794 10.9757 13.5 11.9996 13.5C13.0235 13.5 14.028 13.7794 14.9049 14.308C15.7818 14.8366 16.4978 15.5945 16.9758 16.5"
      stroke="#333333"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
