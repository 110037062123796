
import './button.scss';
import { reactive, computed } from 'vue';

interface PropTypes {
  icon?: {
    src?: string;
    alt?: string;
  };
  typeFnBtn: string;
  loading: boolean;
  label: string;
  isNav: boolean;
  fontSize: string;
  primary: boolean;
  size: string;
  backgroundColor: string;
  fontWeight: string;
  background: string;
  color: string;
  isButtonWithIcon: boolean;
  isActive: boolean;
  paddingBottom: string;
  disabled: boolean;
}
export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    isNav: {
      type: Boolean,
      default: false,
    },
    fontSize: {
      type: String,
      default: '14px',
    },
    primary: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      validator: function (value: string) {
        return ['small', 'medium', 'large'].indexOf(value) !== -1;
      },
    },
    backgroundColor: {
      type: String,
    },
    typeFnBtn: {
      type: String,
    },
    fontWeight: {
      type: String,
      default: 'normal',
    },
    background: {
      type: String,
    },
    color: {
      type: String,
    },
    paddingBottom: {
      type: String,
    },
    isActive: {
      type: Boolean,
    },
    isButtonWithIcon: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: Object,
    },
    disabled: {
      type: Boolean,
    },
  },

  emits: ['click', 'mousedown'],

  setup(props: Partial<PropTypes>, { emit }) {
    props = reactive(props);
    const icon = props.icon && reactive(props.icon);
    return {
      image: {
        src: icon && icon.src,
        alt: icon && icon.alt,
      },
      id: props.typeFnBtn,
      classes: computed(() => ({
        'storybook-button': !props.isButtonWithIcon,
        'storybook-button--primary': props.primary,
        'storybook-button--secondary':
          (!props.primary && props.icon && !props.icon.src) ||
          (!props.icon && !props.primary),
        'storybook-button--icon': icon && icon.src && !props.isButtonWithIcon,
        [`rounded inline-flex items-center stamp-wrapper--${props.typeFnBtn} justify-center gap-x-4`]: props.isButtonWithIcon,
        [`storybook-button--${props.size || 'medium'}`]:
          (props.icon && !props.icon.src) || !props.icon,
        [`font-${props.fontWeight}`]: true,
        disabled: props.disabled,
      })),
      style: computed(() => ({
        backgroundColor: props.backgroundColor,
        color: props.color,
        background: props.background,
      })),
      styleImage: computed(() => ({
        paddingBottom: `${props.paddingBottom}px`,
      })),
      onClick() {
        emit('click');
      },
      onMouseDown() {
        emit('mousedown');
      },
    };
  },
};
