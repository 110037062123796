import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "ind-app-policy-container ml-40 mr-40 mt-10 mb-10" }
const _hoisted_2 = { class: "form-container" }
const _hoisted_3 = { class: "form-title font-size-24" }
const _hoisted_4 = { class: "form-section mt-5" }
const _hoisted_5 = { class: "form-section-title mb-5 font-size-24" }
const _hoisted_6 = { class: "form-item" }
const _hoisted_7 = { class: "form-item" }
const _hoisted_8 = { class: "form-item" }
const _hoisted_9 = { class: "form-section mt-5" }
const _hoisted_10 = { class: "form-section-title mb-5" }
const _hoisted_11 = { class: "form-section-title mb-5 font-size-24" }
const _hoisted_12 = { class: "form-item" }
const _hoisted_13 = { class: "form-item" }
const _hoisted_14 = { class: "form-item" }
const _hoisted_15 = { class: "form-item" }
const _hoisted_16 = { class: "form-item" }
const _hoisted_17 = { class: "form-item" }
const _hoisted_18 = { class: "form-section form-section-btn mt-5" }
const _hoisted_19 = { class: "form-btn-left" }
const _hoisted_20 = { class: "form-btn-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Label = _resolveComponent("Label")
  const _component_Select = _resolveComponent("Select")
  const _component_DatePicker = _resolveComponent("DatePicker")
  const _component_Checkbox = _resolveComponent("Checkbox")
  const _component_Button = _resolveComponent("Button")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode("div", _hoisted_3, _toDisplayString(_ctx.t(
            'individualApplicationPolicyCreate.individualApplicationPolicySetting'
          )), 1),
      _createVNode("div", _hoisted_4, [
        _createVNode("div", _hoisted_5, _toDisplayString(_ctx.t('individualApplicationPolicyCreate.basicSetting')), 1),
        _createVNode("div", _hoisted_6, [
          _createVNode(_component_Label, {
            mandatory: "",
            "text-label": _ctx.t('individualApplicationPolicyCreate.targetEmployee')
          }, null, 8, ["text-label"]),
          _createVNode(_component_Select, {
            class: "form-item-input relative",
            placeholder: _ctx.t('pleaseSelect'),
            modelValue: _ctx.individualApplicationParams.pp_employee_id,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.individualApplicationParams.pp_employee_id = $event)),
            errors: _ctx.errors.pp_employee_id,
            "onUpdate:errors": _cache[2] || (_cache[2] = ($event: any) => (_ctx.errors.pp_employee_id = $event)),
            "options-select": _ctx.listApprovalEmployee,
            disabled: _ctx.isCheckButtonDelete,
            validated: _ctx.validated,
            required: true,
            "item-text": "name",
            "item-value": "id"
          }, null, 8, ["placeholder", "modelValue", "errors", "options-select", "disabled", "validated"])
        ]),
        _createVNode("div", _hoisted_7, [
          _createVNode(_component_Label, {
            mandatory: "",
            "text-label": 
              _ctx.t('individualApplicationPolicyCreate.applicationStartDate')
            
          }, null, 8, ["text-label"]),
          _createVNode(_component_DatePicker, {
            format: "YYYY/MM/DD",
            class: "form-item-input relative w-40",
            placeholder: _ctx.DATE_FORMAT,
            type: "date",
            required: !_ctx.isCheckButtonDelete ? true : false,
            validated: !_ctx.isCheckButtonDelete ? _ctx.validated : null,
            modelValue: _ctx.individualApplicationParams.apply_start_date,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.individualApplicationParams.apply_start_date = $event)),
            errors: _ctx.errors.apply_start_date,
            "onUpdate:errors": _cache[4] || (_cache[4] = ($event: any) => (_ctx.errors.apply_start_date = $event)),
            disabled: _ctx.isCheckButtonDelete
          }, null, 8, ["placeholder", "required", "validated", "modelValue", "errors", "disabled"])
        ]),
        _createVNode("div", _hoisted_8, [
          _createVNode(_component_Label, {
            "text-label": 
              _ctx.t('individualApplicationPolicyCreate.applicationEndDate')
            
          }, null, 8, ["text-label"]),
          _createVNode(_component_DatePicker, {
            format: "YYYY/MM/DD",
            class: "form-item-input relative w-40",
            placeholder: _ctx.DATE_FORMAT,
            type: "date",
            validated: _ctx.validated,
            modelValue: _ctx.individualApplicationParams.apply_end_date,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (_ctx.individualApplicationParams.apply_end_date = $event)),
            errors: _ctx.errors.apply_end_date,
            "onUpdate:errors": _cache[6] || (_cache[6] = ($event: any) => (_ctx.errors.apply_end_date = $event)),
            disabled: _ctx.isCheckButtonDelete
          }, null, 8, ["placeholder", "validated", "modelValue", "errors", "disabled"])
        ])
      ]),
      _createVNode("div", _hoisted_9, [
        _createVNode("div", _hoisted_10, [
          _createVNode("div", _hoisted_11, _toDisplayString(_ctx.t('individualApplicationPolicyCreate.applyMaster')), 1),
          _createVNode("div", _hoisted_12, [
            _createVNode(_component_Label, {
              "text-label": 
                _ctx.t('individualApplicationPolicyCreate.companyCalendar')
              
            }, null, 8, ["text-label"]),
            _createVNode(_component_Select, {
              class: "form-item-input relative",
              placeholder: _ctx.t('pleaseSelect'),
              modelValue: _ctx.individualApplicationParams.national_holiday_group_id,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (_ctx.individualApplicationParams.national_holiday_group_id = $event)),
              errors: _ctx.errors.company_calendar,
              "onUpdate:errors": _cache[8] || (_cache[8] = ($event: any) => (_ctx.errors.company_calendar = $event)),
              validated: _ctx.validated,
              "options-select": _ctx.nationalHolidaysList,
              "item-text": "national_holiday_group_name",
              "item-value": "id"
            }, null, 8, ["placeholder", "modelValue", "errors", "validated", "options-select"])
          ]),
          _createVNode("div", _hoisted_13, [
            _createVNode(_component_Label, {
              "text-label": 
                _ctx.t('individualApplicationPolicyCreate.prescribedLaborPattern')
              
            }, null, 8, ["text-label"]),
            _createVNode(_component_Select, {
              class: "form-item-input relative",
              placeholder: _ctx.t('pleaseSelect'),
              validated: _ctx.validated,
              modelValue: 
                _ctx.individualApplicationParams.administrative_working_schedule_id
              ,
              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => (
                _ctx.individualApplicationParams.administrative_working_schedule_id
               = $event)),
              errors: _ctx.errors.prescribed_labor_pattern,
              "onUpdate:errors": _cache[10] || (_cache[10] = ($event: any) => (_ctx.errors.prescribed_labor_pattern = $event)),
              "options-select": _ctx.resultScheduleWorking,
              "item-text": "administrative_working_schedule_name",
              "item-value": "id"
            }, null, 8, ["placeholder", "validated", "modelValue", "errors", "options-select"])
          ]),
          _createVNode("div", _hoisted_14, [
            _createVNode(_component_Label, {
              "text-label": 
                _ctx.t('individualApplicationPolicyCreate.closingDatePattern')
              
            }, null, 8, ["text-label"]),
            _createVNode(_component_Select, {
              class: "form-item-input relative",
              placeholder: _ctx.t('pleaseSelect'),
              modelValue: _ctx.individualApplicationParams.monthly_closing_day_id,
              "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => (_ctx.individualApplicationParams.monthly_closing_day_id = $event)),
              errors: _ctx.errors.closing_date_pattern,
              "onUpdate:errors": _cache[12] || (_cache[12] = ($event: any) => (_ctx.errors.closing_date_pattern = $event)),
              "options-select": _ctx.closingDayMasterData,
              validated: _ctx.validated,
              "item-text": "monthly_closing_day_name",
              "item-value": "id"
            }, null, 8, ["placeholder", "modelValue", "errors", "options-select", "validated"])
          ]),
          _createVNode("div", _hoisted_15, [
            _createVNode(_component_Label, {
              "text-label": _ctx.t('individualApplicationPolicyCreate.workingStyle')
            }, null, 8, ["text-label"]),
            _createVNode(_component_Select, {
              class: "form-item-input relative",
              placeholder: _ctx.t('pleaseSelect'),
              modelValue: _ctx.individualApplicationParams.working_style_id,
              "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => (_ctx.individualApplicationParams.working_style_id = $event)),
              validated: _ctx.validated,
              errors: _ctx.errors.working_style,
              "onUpdate:errors": _cache[14] || (_cache[14] = ($event: any) => (_ctx.errors.working_style = $event)),
              "options-select": _ctx.workingStyleMasterData,
              "item-text": "working_style_name",
              "item-value": "id"
            }, null, 8, ["placeholder", "modelValue", "validated", "errors", "options-select"])
          ]),
          _createVNode("div", _hoisted_16, [
            _createVNode(_component_Label, {
              "text-label": 
                _ctx.t('individualApplicationPolicyCreate.timezoneDivisionGroup')
              
            }, null, 8, ["text-label"]),
            _createVNode(_component_Checkbox, {
              modelValue: 
                _ctx.individualApplicationParams.is_use_time_zone_session_type
              ,
              "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => (
                _ctx.individualApplicationParams.is_use_time_zone_session_type
               = $event)),
              fontSize: 14,
              class: "mr-8",
              label: 
                _ctx.t('individualApplicationPolicyCreate.userTimeZoneDivisionGroup')
              ,
              onChange: _cache[16] || (_cache[16] = ($event: any) => (_ctx.controlTimezoneDivisionGroups($event)))
            }, null, 8, ["modelValue", "label"]),
            _createVNode(_component_Select, {
              class: "form-item-input relative",
              placeholder: _ctx.t('pleaseSelect'),
              validated: _ctx.validated,
              modelValue: _ctx.individualApplicationParams.time_zone_session_type_id,
              "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => (_ctx.individualApplicationParams.time_zone_session_type_id = $event)),
              errors: _ctx.errors.time_zone_session_group_id,
              "onUpdate:errors": _cache[18] || (_cache[18] = ($event: any) => (_ctx.errors.time_zone_session_group_id = $event)),
              "options-select": _ctx.timezoneGroupData,
              disabled: _ctx.isCheckDisabled.is_use_time_zone_group,
              "item-text": "time_zone_session_type_name",
              "item-value": "id"
            }, null, 8, ["placeholder", "validated", "modelValue", "errors", "options-select", "disabled"])
          ]),
          _createVNode("div", _hoisted_17, [
            _createVNode(_component_Label, {
              "text-label": _ctx.t('individualApplicationPolicyCreate.authority')
            }, null, 8, ["text-label"]),
            _createVNode(_component_Select, {
              class: "form-item-input relative",
              placeholder: _ctx.t('pleaseSelect'),
              modelValue: _ctx.individualApplicationParams.role_id,
              "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => (_ctx.individualApplicationParams.role_id = $event)),
              errors: _ctx.errors.role_id,
              "onUpdate:errors": _cache[20] || (_cache[20] = ($event: any) => (_ctx.errors.role_id = $event)),
              "options-select": _ctx.roleData,
              validated: _ctx.validated,
              "item-text": "role_name",
              "item-value": "id"
            }, null, 8, ["placeholder", "modelValue", "errors", "options-select", "validated"])
          ])
        ]),
        _createVNode("div", _hoisted_18, [
          _createVNode("div", _hoisted_19, [
            _createVNode(_component_Button, {
              onClick: _ctx.handleFnPersonalSetting,
              class: "w-50 mr-5 mb-5",
              label: _ctx.t('individualApplicationPolicyCreate.save')
            }, null, 8, ["onClick", "label"]),
            _createVNode(_component_Button, {
              class: "w-50 mr-5",
              onClick: _ctx.redirectEmployeeInformation,
              variant: "secondary",
              label: _ctx.t('cancel')
            }, null, 8, ["onClick", "label"])
          ]),
          _createVNode("div", _hoisted_20, [
            (_ctx.isCheckButtonDelete)
              ? (_openBlock(), _createBlock(_component_Button, {
                  key: 0,
                  class: "w-50",
                  variant: "outline-danger",
                  onClick: _cache[21] || (_cache[21] = ($event: any) => (_ctx.openDeletePersonalPopup())),
                  label: _ctx.t('actionDelete')
                }, null, 8, ["label"]))
              : _createCommentVNode("", true)
          ])
        ])
      ])
    ])
  ]))
}