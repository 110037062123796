import { ref } from 'vue';
import { convertErrorFromServerToJson } from '@/util/useValidateForm';
import { TimeZoneGroupPostData } from '@/model/TimeZoneGroup';
import {
  deleteTimezoneGroup,
  getListTimezoneGroup,
  getTimezoneGroupDetail,
  postTimezoneGroup,
  updateTimezoneGroup,
} from '../timezoneGroup';

export function getDataTimezoneGroupListData(params) {
  const timezoneGroupData = ref();
  const loading = ref(false);
  const pagination = ref({
    current_page: 1,
    per_page: 20,
    total: 0,
  });
  const response = ref();

  async function getDataTimezoneGroupList(params) {
    try {
      const res = await getListTimezoneGroup(params);

      switch (res.status) {
        case 200:
          timezoneGroupData.value = res.data.data;
          response.value = res.data;
          pagination.value = response.value?.meta.pagination;
          break;
        default:
          timezoneGroupData.value = [];
          break;
      }
    } catch (error) {
      console.error(error);
    } finally {
      loading.value = false;
    }
  }

  getDataTimezoneGroupList(params);

  return {
    pagination,
    timezoneGroupData,
    getDataTimezoneGroupList,
  };
}

export function timezoneGroupRegister(data: TimeZoneGroupPostData) {
  const errors = ref({});
  const errorsCommon = ref<string>();
  return {
    registered: (async () => {
      const response = await postTimezoneGroup(data);
      switch (response.status) {
        case 200:
          return null;
        default:
          if (response.data.errors[0].source) {
            errors.value = convertErrorFromServerToJson(response.data.errors);
          } else {
            errorsCommon.value = response.data.errors[0].title;
          }
          return {
            errors,
            errorsCommon,
          };
      }
    })(),
  };
}

export function useTimezoneGroupDetailSetting() {
  const timezoneGroupDetail = ref();
  const loading = ref(false);

  async function getTimezoneGroupDetailSettings(id) {
    try {
      loading.value = true;
      const res = await getTimezoneGroupDetail(id);
      switch (res.status) {
        case 200:
          timezoneGroupDetail.value = res.data.data;
          break;
        default:
          timezoneGroupDetail.value = {};
          break;
      }
    } catch (error) {
      console.error(error);
    } finally {
      loading.value = false;
    }
  }
  return { timezoneGroupDetail, loading, getTimezoneGroupDetailSettings };
}

export function handleUpdateTimezoneGroup(
  id: string,
  data: TimeZoneGroupPostData
) {
  const errors = ref({});
  const errorsCommon = ref<string>();
  return {
    update: (async () => {
      const response = await updateTimezoneGroup(id, data);
      switch (response.status) {
        case 200:
          return null;
        default:
          if (response.data.errors[0].source) {
            errors.value = convertErrorFromServerToJson(response.data.errors);
          } else {
            errorsCommon.value = response.data.errors[0].title;
          }
          return {
            errors,
            errorsCommon,
          };
      }
    })(),
  };
}

export function deleteTimezoneGroupID(id) {
  return {
    deleteData: (async () => {
      const response = await deleteTimezoneGroup(id);
      switch (response.status) {
        case 200:
          return null;
        default:
          return response.data.errors[0].title;
      }
    })(),
  };
}
