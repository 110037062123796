
import { computed, defineComponent, reactive, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import Select from '@/components/Select.vue';
import Plus from '@/assets/svg/Plus.svg';
import Checkbox from '@/components/Checkbox.vue';
import router from '@/router';
import Popup from '@/components/Popup.vue';
import Table from '@/components/Table.vue';
import Input from '@/components/Input.vue';
import Label from '@/components/Label.vue';
import ConstantsRouter from '@/constants/router';
import { UnitApiGetResult } from '@/api/types/UnitApi';

export default defineComponent({
  components: {
    Select,
    Checkbox,
    Popup,
    Table,
    Label,
    Input,
  },
  setup() {
    const i18n = useI18n();
    const t = i18n.t;
    const optionsChoseTime = reactive([
      { text: i18n.t('onTheDay'), value: 0 },
      { text: i18n.t('beforeDay'), value: 1 },
      { text: i18n.t('nextDay'), value: 2 },
    ]);
    const cardRegistrationHeaders = [
      {
        text: 'スタッフコード',
        value: 'staff_code',
        width: '15%',
      },
      {
        text: 'スタッフ名',
        value: 'staff_name',
        width: '15%',
      },
      {
        text: '所属組織',
        value: 'affiliation_organization',
        width: '15%',
      },
      {
        text: '就労形態',
        value: 'working_style',
        width: '15%',
      },
      {
        text: 'ICカード番号',
        value: 'action',
        width: '40%',
      },
    ];
    const cardRegistrationData = [
      {
        staff_code: '1001',
        staff_name: '氏名',
        affiliation_organization: '人事課',
        working_style: 'アルバイトA ',
        action: 'ID ',
      },
      {
        staff_code: '1002',
        staff_name: '氏名',
        affiliation_organization: '人事課',
        working_style: 'アルバイトA ',
        action: 'ID ',
      },
      {
        staff_code: '1003',
        staff_name: '氏名',
        affiliation_organization: '人事課',
        working_style: 'アルバイトA ',
        action: 'ID ',
      },
      {
        staff_code: '1004',
        staff_name: '氏名',
        affiliation_organization: '人事課',
        working_style: 'アルバイトA ',
        action: 'ID ',
      },
    ];
    const errorsServer = ref({});
    const validated = ref(false);
    const errors = ref({});
    const organizationText = computed(() =>
      organizationSelectedConfirm.value
        .map((item) => item['unit_name'])
        .join(', ')
    );
    const handleCancel = () => {
      router.push(ConstantsRouter.WorkPatternMaster);
    };
    const organizationSelected = ref<UnitApiGetResult[]>([]);
    const organizationSelectedConfirm = ref<UnitApiGetResult[]>([]);
    const isShow = ref(false);
    const showPopupSelect = () => {
      organizationSelected.value = [...organizationSelectedConfirm.value];
      isShow.value = true;
    };
    const hiddenPopupSelect = () => {
      isShow.value = false;
    };
    const saveSelectedOrganization = () => {
      organizationSelectedConfirm.value = [...organizationSelected.value];
      hiddenPopupSelect();
    };
    const affiliationOrganizationOption = reactive([
      { text: i18n.t('cardRegistration.textSelectExample'), value: '' },
    ]);
    const workingStyleOption = reactive([
      { text: i18n.t('cardRegistration.textSelectExample2'), value: '' },
    ]);
    return {
      t,
      Plus,
      optionsChoseTime,
      isShow,
      validated,
      errors,
      organizationSelected,
      organizationSelectedConfirm,
      organizationText,
      errorsServer,
      cardRegistrationHeaders,
      cardRegistrationData,
      affiliationOrganizationOption,
      workingStyleOption,
      handleCancel,
      showPopupSelect,
      hiddenPopupSelect,
      saveSelectedOrganization,
    };
  },
});
