
import ConstantsRouter from '@/constants/router';
import router from '@/router';
import { computed, defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import Select from '@/components/share/Dropdown/Dropdown.vue';
import { useRoute } from 'vue-router';
import { postWaitingAllowanceApplication } from '@/api/composables/waitingAllowance';
import { WaitingAllowanceApiPost } from '@/api/types/WaitingAllowanceApplication';
import { useLoginAccountStore } from '@/store/modules/login-account';
import { showToast } from '@/util/useToastMessage';
import moment from 'moment';
import ApplicationApproveFlowDetail from '@/components/Application/ApplicationApproveFlowDetail.vue';
import useUnitApi from '@/api/composables/useUnit';
import { isValidateForm } from '@/util/useValidateForm';
import BackPreviousPage from '@/components/share/BackPreviousPage/index.vue';
import ReasonCreateApplication from '@/components/Application/ReasonCreateApplication.vue';

export default defineComponent({
  components: {
    Select,
    ReasonCreateApplication,
    ApplicationApproveFlowDetail,
    BackPreviousPage,
  },
  setup() {
    const i18n = useI18n();
    const t = i18n.t;
    const returnScreen = () => {
      router.push(`/new${ConstantsRouter.TimeCard}`);
    };
    const isAllParams = ref(true);
    const { unitList } = useUnitApi({
      is_all: isAllParams.value,
    });
    const accountStore = useLoginAccountStore();
    const route = useRoute();
    const myAccount = computed(() => accountStore.state.myAccount);
    const isDisableSubmit = computed(() =>
      Object.values(errors.value).some((e) => !!e)
    );
    const apiPostData = ref<WaitingAllowanceApiPost>({
      pp_employee_id: '',
      wait_numbers: 1,
      application_reason: '',
      application_define_id: '',
      target_date: '',
      unit_id: '',
      checkin_unit_id: myAccount.value.unit_id,
      application_form_to_delete_id: null,
      is_request_delete: false,
    });
    const targetDate = ref(
      moment(route.query.target_date?.toString()).isValid()
        ? moment(route.query.target_date?.toString()).format(
            `YYYY${t('year')}MM${t('month')}DD${t('day')}（dd）`
          )
        : ''
    );
    const backToTimeCard = () => {
      router.push(`/new${ConstantsRouter.TimeCard}`);
    };
    const optionSelect = [
      { text: 1, value: 1 },
      { text: 2, value: 2 },
      { text: 3, value: 3 },
      { text: 4, value: 4 },
      { text: 5, value: 5 },
      { text: 6, value: 6 },
      { text: 7, value: 7 },
      { text: 8, value: 8 },
      { text: 9, value: 9 },
      { text: 10, value: 10 },
    ];
    const errorsServer = ref({});
    const errors = ref({});
    const validated = ref(false);
    const isValid = () => {
      validated.value = true;
      return isValidateForm(errors);
    };
    const createAllowanceApplication = async () => {
      if (!isValid()) return;

      apiPostData.value.pp_employee_id = myAccount.value.pp_employee_id;
      apiPostData.value.unit_id = myAccount.value.unit_id;
      apiPostData.value.application_define_id = route.query.id?.toString();
      apiPostData.value.target_date = moment(
        route.query.target_date?.toString()
      ).isValid()
        ? moment(route.query.target_date?.toString()).format('YYYY-MM-DD')
        : '';
      const { registered } = postWaitingAllowanceApplication(apiPostData.value);
      registered
        .then((errors) => {
          if (errors) {
            errorsServer.value = errors.errors.value;
            if (errors?.errorsCommon.value) {
              showToast('error', errors?.errorsCommon.value);
            } else {
              showToast('warning', t('invalidInput'));
            }
          } else {
            showToast('success', t('theApplicationHasBeenCreated'));
            router.push(`/new${ConstantsRouter.TimeCard}`);
          }
        })
        .catch(() => {
          showToast('error', t('errors.serverError'));
        });
    };

    return {
      t,
      i18n,
      returnScreen,
      apiPostData,
      optionSelect,
      createAllowanceApplication,
      accountStore,
      route,
      myAccount,
      errorsServer,
      errors,
      targetDate,
      unitList,
      validated,
      isDisableSubmit,
      backToTimeCard,
    };
  },
});
