const ConstantsRouter = {
  Dashboard: '/dashboard', //ダッシュボード

  AuthLogin: '/login', //ログイン
  AuthRegister: 'register', //登録
  AuthForgotPassword: '/forgot-password', //パスワード忘れ
  AuthChangePassword: '/change-password', //パスワード変更

  Home: '/home', //ホーム
  Stamp: '/stamp', //打刻
  TimeCard: '/time-card', //勤怠実績
  MyTimeCard: '/time-card/my-time-card', //勤怠実績
  AllMemberTimeCard: '/time-card/all-member-time-card', //勤怠実績
  AllMemberTimeCardDetail: '/time-card/my-time-card-detail', //勤怠実績
  TimeCardRegister: 'time-card/stamp-register', //打刻申請
  Settings: '/settings', //設定
  OverTimeRegister: '/overtime-register', //超過勤務申請

  ShiftManager: '/shift-manager', //シフト計画一覧
  ShiftRegisterMembers: '/shift-manager/shift-register-member', //
  RequestSubmissionShift: '/shift-manager/request-submission-shift', //提出依頼対象メンバー
  ShiftSubmissionStatus: 'shift-submission-status', //
  ShiftBeingCreatedNew: '/shift-manager/shift-being-created',
  PastShiftPlans: '/shift-manager/shift-being-created/past-shift-plans',
  CreateNewShift: '/shift-manager/shift-being-created/create',
  ConfirmNewShift: '/shift-manager/confirm',
  ConfirmNewShiftName: '/shift-manager/shift-being-created/confirm/:id',
  ConfirmNewShiftID: '/shift-manager/shift-being-created/confirm/:id',
  CreateNewShiftName: '/shift-manager/shift-being-created/update/:id',
  CreateNewShiftID: '/shift-manager/shift-being-created/update',

  ShiftRegisterCreateNew: '/shift-register-create-member', //古くなってしまってます
  ShiftRegistration: '/shift-registration', //希望シフトを提出する
  SubmitDesiredShift: '/shift-registration/submit-desired-shift', //提出を依頼されているシフト
  EnterSubmitDesiredShift: '/shift-registration/submit-desired-shift/update', //希望のシフトを提出する
  MyShift: 'my-shift', //私のシフト
  EveryoneShift: 'everyone-shift', //みんなのシフト
  SubmitDesired: '/shift-registration/submit-desired', //希望のシフトを提出する

  TimeCardLateEarlyRegister: '/time-card/late-early-register', //遅刻・早退申請
  EarlyApplication: '/application/late-early-register',
  WorkingScheduleRegister: '/time-card/working-schedule-register', //勤務予定変更申請
  WorkingTimeRegister: '/time-card/working-time-register', //遅刻早退申請
  ApplicationApproval: '/application-approval', //申請・承認＞申請一覧
  ApplicationConfirm: '/application-confirm', //申請履歴

  Timezone: '/settings/time-zone-session', //時間帯一覧
  TimezoneGroup: '/settings/timezone-session-group', //時間帯区分グループ
  TimezoneGroupRegister: '/settings/timezone-session-group/create',
  TimezoneGroupUpdate: '/settings/timezone-session-group/:id',
  TimezoneRegister: '/settings/time-zone-session/create', //時間帯設定
  UpdateTimezoneDivisionSettingID: '/settings/time-zone-session/:id', //時間帯更新・詳細
  UpdateTimezoneDivisionSettingName: '/settings/time-zone-session/:id', //時間帯更新・詳細

  WorkingStyleMaster: '/settings/working-style-master', //就労形態マスタ一覧
  WorkingStyleMasterRegister: '/settings/working-style-master/create', //就労形態マスタ作成
  WorkingStyleMasterUpdate: '/settings/working-style-master/update',
  WorkingStyleMasterCopy: '/settings/working-style-master/copy',

  WorkPatternMaster: '/settings/work-pattern', //勤務パターン一覧
  WorkPatternMasterNew: '/new/settings/work-pattern',
  WorkPatternAdd: '/settings/work-pattern/create', //勤務パターン申請
  WorkPatternUpdate: '/settings/work-pattern/update', //勤務パターン申請
  WorkPatternCopy: '/settings/work-pattern/copy/',
  WorkPatternAddNew: '/new/settings/work-pattern/create', //勤務パターン申請
  WorkPatternUpdateNew: '/new/settings/work-pattern/update', //勤務パターン申請
  WorkPatternCopyNew: '/new/settings/work-pattern/copy/',

  ScheduledLaborMaster: 'scheduled-labor-master', //所定労働マスター一覧
  Holidays: '/settings/scheduled-labor-master/national-holiday', //休日一覧
  HolidaysSignsUp: '/settings/scheduled-labor-master/national-holiday/create', //休日作成
  HolidaysUpdateName: 'national-holiday-update', //休日作成
  HolidaysUpdate: '/settings/scheduled-labor-master/national-holiday/:id', //休日作成
  ScheduledLaborPattern:
    '/settings/scheduled-labor-master/scheduled-labor-pattern', //所定労働パターン
  ScheduledLaborPatternRegister:
    '/settings/scheduled-labor-master/scheduled-labor-pattern/create', //所定労働パターン設定
  ScheduledLaborPatternUpdate:
    '/settings/scheduled-labor-master/scheduled-labor-pattern/update',
  ScheduledLaborPatternCopy:
    '/settings/scheduled-labor-master/scheduled-labor-pattern/copy',
  CloseDayMaster: '/settings/scheduled-labor-master/closing-day-master', //締め日一覧
  CloseDayMasterRegister:
    '/settings/scheduled-labor-master/closing-day-master-register', //締め日作成

  MonthlyClosingDayList: '/settings/scheduled-labor-master/monthly-closing-day', //締め日一覧
  MonthlyClosingDayCreat:
    '/settings/scheduled-labor-master/monthly-closing-day/create', //締め日作成
  MonthlyClosingDayCopy:
    '/settings/scheduled-labor-master/monthly-closing-day/copy', //締め日作成
  MonthlyClosingDayUpdate:
    '/settings/scheduled-labor-master/monthly-closing-day/update', //締め日作成

  AminSetting: '/settings/permission-setting', //権限設定
  AminSettingRoleList: '/settings/role-setting', //
  AminSettingRoleCreate: '/settings/role-setting/create', //
  AminSettingRoleUpdate: '/settings/role-setting/update', //
  AminSettingRoleCopy: '/settings/role-setting/copy', //
  PermissionPrivilegeEditing: '/settings/role-setting/authority', //
  ApprovedSetting: '/settings/approval', //申請フロー
  ApprovedCardRegistrationSetting: '/settings/card-registration', //ICカード登録
  TimeRecoder: '/settings/time-recoder/create',
  TimeRecoderList: '/settings/time-recoder/list',
  TimeRecoderDetail: '/settings/time-recoder',

  ApprovedSettingFlow: '/settings/approval-flow', //approved-setting-flow
  BasicFlowSetting: '/settings/approval-flow/basic-flow-setting', //approved-setting-flow
  ApprovedSettingFlowUpdate:
    '/settings/approval-flow/basic-flow-setting/update', //approved-setting-flow
  ApprovedPersonalFlow: '/settings/approval-flow/personal-flow-update', //申請フロー設定（個別設定）
  PersonalFlowSetting: '/settings/approval-flow/personal-flow-setting', //personal-flow-setting
  PersonalFlowSettingCreate:
    '/settings/approval-flow/personal-flow-setting/update', //personal-flow-setting

  OrganizationalInformation: '/settings/organizational-information', //所属情報
  EmployeeInformation: '/settings/employee-information', //従業員設定
  IndividualApplicationPolicyCreate:
    '/settings/individual-application-policy/create', //個別適用ポリシー設定
  IndividualApplicationPolicyList:
    '/settings/individual-application-policy/list', //個別適用ポリシー設定
  IndividualApplicationPolicySettingUpdate:
    '/settings/individual-application-policy/update',
  VacationMaster: '/settings/vacation-master', //勤怠マスタ
  VacationMasterSetting: '/settings/vacation-master-setting', //勤怠マスタ
  ShiftTimeManager: '/shift-manager/create-new-shift/shift-time',
  ShiftTimeManagerId: '/shift-manager/create-new-shift/shift-time/:id',
  ShiftTimeManagerName: '/shift-manager/create-new-shift/shift-time/:id',

  Application: '/application',
  ApplicationApprovalRequest: '/application/approval-request',
  ApplicationMyApproval: '/application/my-approval',
  TimeZoneSessionList: '/time-zone-sessions',
  TimeZoneSessionUpdate: '/time-zone-sessions/:id',
  TimeZoneSessionCreate: '/time-zone-sessions/create',

  AllMemberCheckTime: '/all-member-check-time',
  WaitingAllowanceApplicationCreate: '/time-card/allowance-application',
  WaitingAllowanceApplicationDelete:
    '/application/allowance-application-delete',
  WorkRecordApplication: '/application/work-record-application',
  WorkScheduleChangeApplicationCreate:
    '/time-card/working-schedule-change-application',
  CheckTimeApplication: '/application/check-time-application',
  CheckTimeApplicationCreate: '/time-card/check-time-application',
  AbsenceApplicationCreat: '/time-card/absence-application/create',
  AbsenceApplicationDetail: '/application/absence-application',
  SubstituteApplication: '/application/substitute-application',
  SubstituteApplicationCreate: '/time-card/substitute-application/create',
  RegisterIcCard: '/register-ic-card',
  TransferAttendanceApplicationDetail:
    '/application/transfer-attendance-application',
  TransferAttendanceApplication: '/time-card/transfer-attendance-application',
  WorkInHolidayApplicationDetail: '/application/work-in-holiday-application',
  WorkInHolidayApplication: '/time-card/work-in-holiday-application',
  AlertSetting: '/setting/alert-setting',
  AlertSettingDetailUpdate: '/setting/alert-setting/update',

  YearPaidHoliday: '/settings/year-paid-holiday',
  YearPaidHolidayCreate: '/settings/year-paid-holiday/create',
  YearPaidHolidayUpdate: '/settings/year-paid-holiday/update',

  SystemError: '/system-error', //
  NotFound: '/not-found', //
  Other: '/:other(.*)', //
};
export default ConstantsRouter;
