import { ref } from 'vue';
import { getAllMembersCheckTime } from '../allMemberCheckTime';

export default function (paramsInit) {
  const membersCheckTime = ref();

  async function getAllMember(params = paramsInit) {
    try {
      const res = await getAllMembersCheckTime(params);
      switch (res.status) {
        case 200:
          membersCheckTime.value = res.data.data;
          break;
        default:
          membersCheckTime.value = [];
          break;
      }
    } catch (error) {
      console.error(error);
    }
  }

  return {
    membersCheckTime,
    getAllMember,
  };
}
