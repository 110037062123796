import { ref } from 'vue';
import { convertErrorFromServerToJson } from '@/util/useValidateForm';
import {
  getDetailAlertSetting,
  postAlertSetting,
  updateAlertSetting,
} from '@/api/alertSetting';
import { AlertSettingParamsPost } from '@/api/types/AlertSettingApi';

export function usePostAlertSetting(data: AlertSettingParamsPost) {
  const errors = ref({});
  const errorsCommon = ref<string>();
  const loading = ref(false);
  const saveSettingCreatedId = ref();
  return {
    postData: (async () => {
      const response = await postAlertSetting(data);
      switch (response.status) {
        case 200:
          loading.value = false;
          saveSettingCreatedId.value = response.data.data?.id;
          return null;
        default:
          if (response.data.errors[0].source) {
            errors.value = convertErrorFromServerToJson(response.data.errors);
          } else {
            errorsCommon.value = response.data.errors[0].title;
          }
          loading.value = false;
          return {
            errors,
            errorsCommon,
          };
      }
    })(),
    saveSettingCreatedId,
  };
}
export function useGetDetailAlertSetting() {
  const alertSettingDetail = ref();
  const loading = ref(false);

  async function useDetailAlertSetting(id) {
    try {
      loading.value = true;
      const res = await getDetailAlertSetting(id);
      switch (res.status) {
        case 200:
          alertSettingDetail.value = res.data.data;
          break;
        default:
          alertSettingDetail.value = {};
          break;
      }
    } catch (error) {
      console.error(error);
    } finally {
      loading.value = false;
    }
  }
  return {
    alertSettingDetail,
    loading,
    useDetailAlertSetting,
  };
}

export function useUpdateAlertSetting(
  id: string,
  data: AlertSettingParamsPost
) {
  const errors = ref({});
  const errorsCommon = ref<string>();
  const loading = ref(false);
  return {
    update: (async () => {
      loading.value = true;
      const response = await updateAlertSetting(id, data);
      switch (response.status) {
        case 200:
          loading.value = false;
          return null;
        default:
          if (response.data.errors[0].source) {
            errors.value = convertErrorFromServerToJson(response.data.errors);
          } else {
            errorsCommon.value = response.data.errors[0].title;
          }
          loading.value = false;
          return {
            errors,
            errorsCommon,
          };
      }
    })(),
  };
}
