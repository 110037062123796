import api, { ApiResponse } from '@/clients/api';
import { ErrorCode } from './types/general/ErrorCode';
import {
  JSONApiResponseBody,
  JSONApiResponseErrorBody,
} from './types/general/JSONApiResponse';
import {
  WorkingStyleMasterApiGetListParams,
  WorkingStyleMasterApiGetListResult,
  WorkingStyleMasterApiPostData,
  WorkingStyleMasterApiPostResult,
  WorkingStyleMasterApiUpdateData,
  WorkingStyleMasterApiUpdateResult,
} from '@/api/types/WorkingStyleMaster';
import ConstantsAPI from '@/constants/api';
export async function postWorkingStyleMaster(
  postData: WorkingStyleMasterApiPostData
): Promise<
  | ApiResponse<200, JSONApiResponseBody<WorkingStyleMasterApiPostResult>>
  | ApiResponse<400, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.post(ConstantsAPI.WorkingStyleMasterCreate, postData);
}

export async function deleteWorkingStyleMaster(
  id: string
): Promise<
  | ApiResponse<200, JSONApiResponseBody<WorkingStyleMasterApiPostResult>>
  | ApiResponse<400, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.delete(ConstantsAPI.WorkingStyleMasterDetail(id));
}

export async function getWorkingStyleMaster(
  params: WorkingStyleMasterApiGetListParams
): Promise<
  | ApiResponse<200, JSONApiResponseBody<WorkingStyleMasterApiGetListResult[]>>
  | ApiResponse<400, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.get(ConstantsAPI.WorkingStyleMasterList, { params: params });
}

export async function getWorkingStyleMasterDetail(
  id: string
): Promise<
  | ApiResponse<200, JSONApiResponseBody<WorkingStyleMasterApiUpdateData>>
  | ApiResponse<400, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.get(ConstantsAPI.WorkingStyleMasterDetail(id));
}

export async function updateWorkingStyleMaster(
  id: string,
  updateData: WorkingStyleMasterApiUpdateData
): Promise<
  | ApiResponse<200, JSONApiResponseBody<WorkingStyleMasterApiUpdateResult>>
  | ApiResponse<400, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.put(ConstantsAPI.WorkingStyleMasterDetail(id), updateData);
}
