
import ConstantsRouter from '@/constants/router';
import router from '@/router';
import BackPreviousPage from '@/components/share/BackPreviousPage/index.vue';
import Button from '@/components/share/Button/index.vue';
import RadioGroup from '@/components/share/Radio/RadioGroup.vue';
import RadioInput from '@/components/share/Radio/RadioInputGroup.vue';
import InputTime from '@/components/share/Input/InputTime.vue';
import Select from '@/components/share/Dropdown/Dropdown.vue';
import {
  USING_TYPE_SUBSTITUTE_APPLICATION,
  USING_TYPE_SUBSTITUTE_HALF_DAY,
  USING_TYPE_SUBSTITUTE_SETTING_DAY_TYPE,
} from '@/constants/typeSubstituteApplication';
import PopupDetailSubstituteApplication from '@/views/New/SubstituteApplication/PopupDetailSubstituteApplication.vue';
import { computed, defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import { useLoginAccountStore } from '@/store/modules/login-account';
import { isValidateForm } from '@/util/useValidateForm';
import { formatTimeDate, DEFINE_DAY, DAY_FORMAT } from '@/util/time';
import {
  postSubstituteApplication,
  useSubstituteLeaveApplicationDetail,
} from '@/api/composables/useSubstituteLeaveApplication';
import { SubstituteLeaveApplicationApiPost } from '@/api/types/SubstituteLeaveApplication';
import { showToast } from '@/util/useToastMessage';
import { watchEffect } from '@vue/runtime-core';
import ReasonUpdateStatusApplication from '@/components/Application/ReasonUpdateStatusApplication.vue';
import ApplicationApproveFlowDetail from '@/components/Application/ApplicationApproveFlowDetail.vue';
import ReasonCreateApplication from '@/components/Application/ReasonCreateApplication.vue';

export default defineComponent({
  components: {
    PopupDetailSubstituteApplication,
    ReasonUpdateStatusApplication,
    ApplicationApproveFlowDetail,
    ReasonCreateApplication,
    Button,
    InputTime,
    RadioGroup,
    RadioInput,
    Select,
    BackPreviousPage,
  },
  setup() {
    const i18n = useI18n();
    const t = i18n.t;
    const accountStore = useLoginAccountStore();
    const route = useRoute();
    const applicationFormId = route.query.application_form_id || null;
    const id = route.query.id || null;
    const myAccount = computed(() => accountStore.state.myAccount);
    const createdAt = computed(
      () => substituteLeaveApplicationDetail.value?.created_at
    );
    const applicationReason = computed(
      () => substituteLeaveApplicationDetail.value?.application_reason
    );
    const statusApplicationFlow = computed(
      () => substituteLeaveApplicationDetail.value?.application_status
    );
    const acquisitionList = [
      {
        value: USING_TYPE_SUBSTITUTE_APPLICATION.ALL_DAY,
        label: i18n.t('substituteApplication.allDay'),
      },
      {
        value: USING_TYPE_SUBSTITUTE_APPLICATION.HALF_DAY,
        label: i18n.t('substituteApplication.halfDay'),
      },
      {
        value: USING_TYPE_SUBSTITUTE_APPLICATION.TIME_OFF,
        label: i18n.t('substituteApplication.timeOff'),
      },
    ];
    const halfDayList = [
      {
        value: USING_TYPE_SUBSTITUTE_HALF_DAY.MORNING,
        label: i18n.t('substituteApplication.defineTimeMorning'),
      },
      {
        value: USING_TYPE_SUBSTITUTE_HALF_DAY.AFTERNOON,
        label: i18n.t('substituteApplication.defineTimeAfternoon'),
      },
    ];
    const optionsChoseTime = [
      {
        text: i18n.t('onTheDay'),
        value: USING_TYPE_SUBSTITUTE_SETTING_DAY_TYPE.TODAY,
      },
      {
        text: i18n.t('beforeDay'),
        value: USING_TYPE_SUBSTITUTE_SETTING_DAY_TYPE.YESTERDAY,
      },
      {
        text: i18n.t('nextDay'),
        value: USING_TYPE_SUBSTITUTE_SETTING_DAY_TYPE.TOMORROW,
      },
    ];

    const apiPostData = ref<SubstituteLeaveApplicationApiPost>({
      workflow_define_id: '',
      target_date: '',
      substitute_time_type: 0,
      half_day_type: 0,
      setting_day_type: '',
      start_time: '',
      time_off: '',
      application_reason: '',
      pp_employee_id: '',
      unit_id: '',
    });
    const targetDate = route.query.target_date;
    const validated = ref(false);
    const isValid = () => {
      validated.value = true;
      return isValidateForm(errors);
    };
    const errorsServer = ref({});
    const errors = ref({});
    const isShowPopupSubstitute = ref(false);
    const openPopupSubstitute = () => {
      isShowPopupSubstitute.value = true;
    };
    const closePopupSubstitute = async () => {
      isShowPopupSubstitute.value = false;
    };
    const isDisableSubmit = computed(() =>
      Object.values(errors.value).some((e) => !!e)
    );
    const createSubstituteLeaveApplication = async () => {
      if (!isValid()) return;
      apiPostData.value.pp_employee_id = myAccount.value.pp_employee_id;
      apiPostData.value.unit_id = myAccount.value.unit_id;
      apiPostData.value.workflow_define_id = route.query.id?.toString();
      apiPostData.value.target_date = formatTimeDate(targetDate, DAY_FORMAT);
      const { registered } = postSubstituteApplication(apiPostData.value);
      registered
        .then((errors) => {
          if (errors) {
            errorsServer.value = errors.errors.value;
            if (errors?.errorsCommon.value) {
              showToast('error', errors?.errorsCommon.value);
            } else {
              showToast('warning', t('invalidInput'));
            }
          } else {
            showToast('success', t('theApplicationHasBeenCreated'));
            router.push(`/new${ConstantsRouter.TimeCard}`);
          }
        })
        .catch(() => {
          showToast('error', t('errors.serverError'));
        });
    };
    const {
      loading,
      substituteLeaveApplicationDetail,
      getSubstituteLeaveApplicationDetail,
    } = useSubstituteLeaveApplicationDetail();
    if (applicationFormId) {
      getSubstituteLeaveApplicationDetail(applicationFormId);
    }

    watchEffect(() => {
      if (applicationFormId) {
        apiPostData.value = { ...substituteLeaveApplicationDetail.value };
      }
    });
    const returnScreen = () => {
      if (applicationFormId) {
        router.push(`/new${ConstantsRouter.ApplicationMyApproval}`);
      } else if (id) {
        router.push(`/new${ConstantsRouter.TimeCard}`);
      }
    };

    return {
      t,
      i18n,
      loading,
      substituteLeaveApplicationDetail,
      apiPostData,
      applicationFormId,
      createdAt,
      applicationReason,
      statusApplicationFlow,
      id,
      validated,
      accountStore,
      route,
      myAccount,
      errorsServer,
      errors,
      formatTimeDate,
      targetDate,
      acquisitionList,
      isShowPopupSubstitute,
      optionsChoseTime,
      halfDayList,
      isDisableSubmit,
      USING_TYPE_SUBSTITUTE_APPLICATION,
      DEFINE_DAY,
      createSubstituteLeaveApplication,
      closePopupSubstitute,
      openPopupSubstitute,
      isValid,
      returnScreen,
    };
  },
});
