import api, { ApiResponse } from '@/clients/api';
import { ErrorCode } from './types/general/ErrorCode';
import {
  JSONApiResponseBody,
  JSONApiResponseErrorBody,
} from './types/general/JSONApiResponse';
import ConstantsAPI from '@/constants/api';
import {
  WorkInHolidayDayApplicationApiPostData,
  WorkInHolidayApplicationApiPostResult,
  WorkInHolidayDayApplicationApiGetResult,
} from '@/api/types/WorkInHolidayApplicationApi';

export async function postWorkInHolidayApplication(
  postData: WorkInHolidayDayApplicationApiPostData
): Promise<
  | ApiResponse<200, JSONApiResponseBody<WorkInHolidayApplicationApiPostResult>>
  | ApiResponse<400, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.post(ConstantsAPI.WorkInHolidayApplication, postData);
}

export async function getWorkInHolidayApplicationDetail(
  id: string
): Promise<
  | ApiResponse<
      200,
      JSONApiResponseBody<WorkInHolidayDayApplicationApiGetResult>
    >
  | ApiResponse<400, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.get(ConstantsAPI.WorkInHolidayApplicationDetail(id));
}
