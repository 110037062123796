import api, { ApiResponse } from '@/clients/api';
import { ErrorCode } from './types/general/ErrorCode';
import {
  JSONApiResponseBody,
  JSONApiResponseErrorBody,
} from './types/general/JSONApiResponse';
import {
  TimezoneSettingsApiPostData,
  TimezoneSettingsApiPostResult,
  TimezoneSettingsApiDeleteResult,
  TimezoneSettingsApiUpdateData,
} from '@/api/types/TimezoneSettings';
import {
  TimezoneApiGetListParams,
  TimezoneApiGetListResult,
} from '@/api/types/TimezoneList';
import ConstantsAPI from '@/constants/api';
export async function postTimezoneSettings(
  postData: TimezoneSettingsApiPostData
): Promise<
  | ApiResponse<200, JSONApiResponseBody<TimezoneSettingsApiPostResult>>
  | ApiResponse<400, JSONApiResponseErrorBody<ErrorCode>>
  | ApiResponse<500, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.post(ConstantsAPI.TimeZoneSessions(''), postData);
}

export async function deleteTimezoneSetting(
  id: string
): Promise<
  | ApiResponse<200, JSONApiResponseBody<TimezoneSettingsApiDeleteResult>>
  | ApiResponse<400, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.delete(ConstantsAPI.TimeZoneSessions(id));
}
//ConstantsAPI.TimeZoneSessions(id)
export async function getListTimezone(
  params: TimezoneApiGetListParams
): Promise<
  | ApiResponse<200, JSONApiResponseBody<TimezoneApiGetListResult[]>>
  | ApiResponse<400, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.get(ConstantsAPI.TimeZoneSessionList, { params: params });
}

export async function getTimezoneDetail(
  id: string
): Promise<
  | ApiResponse<200, JSONApiResponseBody<TimezoneSettingsApiUpdateData>>
  | ApiResponse<400, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.get(ConstantsAPI.TimeZoneSessions(id));
}

export async function updateTimezoneSettings(
  id: string,
  updateData: TimezoneSettingsApiUpdateData
): Promise<
  | ApiResponse<200, JSONApiResponseBody<TimezoneSettingsApiPostResult>>
  | ApiResponse<400, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.put(ConstantsAPI.TimeZoneSessions(id), updateData);
}
