
import './../assets/index.css';
import { reactive, computed } from 'vue';

interface PropTypes {
  size: string;
  isBadge: boolean;
  width: string;
  height: string;
}
export default {
  name: 'Avatar',
  props: {
    src: {
      type: String,
    },
    size: {
      type: String,
      validator: function (value: string) {
        return ['small', 'medium', 'large'].indexOf(value) !== -1;
      },
      default: 'medium',
    },
    isBadge: {
      type: Boolean,
      default: false,
    },
    width: String,

    height: {
      type: String,
    },
  },
  emits: ['click'],
  setup(props: Partial<PropTypes>, { emit }) {
    props = reactive(props);

    return {
      classes: computed(() => ({
        'inline object-cover rounded-full cursor-pointer avatar_wrapper': true,
        'w-16 h-16': props.size === 'large',
        'w-12 h-12': props.size === 'medium',
        'w-8 h-8': props.size === 'small',
        'inline-block': props.isBadge,
      })),
      classesBadge: computed(() => ({
        'absolute bottom-0 right-0 inline-block w-3 h-3 bg-green-600 border-2 border-white rounded-full':
          props.isBadge,
      })),
      styles: computed(() => ({
        width: props.width,
        height: props.height,
      })),
      onClick() {
        emit('click');
      },
    };
  },
};
