
import { defineComponent, onBeforeMount, onBeforeUnmount } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from '@/store';
import ConstantsRouter from '@/constants/router';
import iconSearch from '@/assets/svg/iconSearch.svg';
import Input from '@/components/Input.vue';
import router from '@/router';

export default defineComponent({
  components: {
    Input,
  },
  props: {
    currentTab: Number,
  },
  setup() {
    const i18n = useI18n();
    const store = useStore();
    const dataTabs = [
      {
        text: i18n.t('beingCreated'),
        path: ConstantsRouter.ShiftBeingCreatedNew,
        name: ConstantsRouter.ShiftBeingCreatedNew,
      },
    ];
    const currentRouter = router.currentRoute;
    onBeforeMount(() => {
      store.commit('root/SET_CURRENT_PAGE', i18n.t(''));
    });
    onBeforeUnmount(() => {
      store.commit('root/SET_CURRENT_PAGE', '');
    });
    return {
      t: i18n.t,
      dataTabs,
      iconSearch,
      currentRouter,
    };
  },
});
