import { ref } from 'vue';
import {
  deleteScheduleWorking,
  getListSchedulesWorking,
  getScheduleWorkingDetail,
  postScheduleWorking,
  updateScheduleWorking,
} from '@/api/scheduleWorking';
import { convertErrorFromServerToJson } from '@/util/useValidateForm';
import {
  ScheduleWorkingApiPostData,
  ScheduleWorkingApiUpdateData,
} from '@/api/types/ScheduleWorking';

export default function (params) {
  const resultScheduleWorking = ref();
  const loading = ref(false);
  const pagination = ref({
    current_page: 1,
    per_page: '',
    total: '',
  });
  const response = ref();

  async function useGetListScheduleWorking(params) {
    try {
      loading.value = true;
      const res = await getListSchedulesWorking(params);
      switch (res.status) {
        case 200:
          resultScheduleWorking.value = res.data.data;

          response.value = res.data;
          pagination.value = response.value.meta.pagination;
          break;
        default:
          resultScheduleWorking.value = [];
          break;
      }
    } catch (error) {
      console.error(error);
    } finally {
      loading.value = false;
    }
  }

  useGetListScheduleWorking(params)
    .then((r) => () => {
      console.log(r);
    })
    .catch((e) => {
      console.log(e);
    });

  return {
    pagination,
    resultScheduleWorking,
    loading,
    useGetListScheduleWorking,
  };
}

export function useDeleteScheduleWorking(id) {
  return {
    deleteData: (async () => {
      const response = await deleteScheduleWorking(id);
      switch (response.status) {
        case 200:
          return null;
        default:
          return response.data.errors[0].title;
      }
    })(),
  };
}

export function useUpdateScheduleWorking(
  id: string,
  data: ScheduleWorkingApiUpdateData
) {
  const errors = ref({});
  const errorsCommon = ref<string>();
  const loading = ref(false);
  return {
    update: (async () => {
      loading.value = true;
      const response = await updateScheduleWorking(id, data);
      switch (response.status) {
        case 200:
          loading.value = false;
          return null;
        default:
          if (response.data.errors[0].source) {
            errors.value = convertErrorFromServerToJson(response.data.errors);
          } else {
            errorsCommon.value = response.data.errors[0].title;
          }
          loading.value = false;
          return {
            errors,
            errorsCommon,
          };
      }
    })(),
  };
}

export function useDetailScheduleWorking() {
  const scheduleWorkingDetail = ref<ScheduleWorkingApiUpdateData>();
  const loading = ref(false);

  async function getDetailData(id) {
    try {
      loading.value = true;
      const res = await getScheduleWorkingDetail(id);
      const response = ref();
      switch (res.status) {
        case 200:
          response.value = res.data.data;
          scheduleWorkingDetail.value = response.value;
          break;
        default:
          scheduleWorkingDetail.value = {};
          break;
      }
    } catch (error) {
      console.error(error);
      return {};
    } finally {
      loading.value = false;
    }
  }

  return { scheduleWorkingDetail, loading, getDetailData };
}

export function usePostScheduleWorking(data: ScheduleWorkingApiPostData) {
  const errors = ref({});
  const errorsCommon = ref<string>();
  const loading = ref(false);
  return {
    registered: (async () => {
      loading.value = true;
      const response = await postScheduleWorking(data);
      switch (response.status) {
        case 200:
          loading.value = false;
          return null;
        case 201:
          loading.value = false;
          return null;
        default:
          if (response.data.errors[0].source) {
            errors.value = convertErrorFromServerToJson(response.data.errors);
          } else {
            errorsCommon.value = response.data.errors[0].title;
          }
          loading.value = false;
          return {
            errors,
            errorsCommon,
          };
      }
    })(),
  };
}
