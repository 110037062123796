import api, { ApiResponse } from '@/clients/api';
import { ErrorCode } from './types/general/ErrorCode';
import {
  JSONApiResponseBody,
  JSONApiResponseErrorBody,
} from '@/api/types/general/JSONApiResponse';
import ConstantsAPI from '@/constants/api';
import { ShiftPlanRequestParams } from './types/ShiftPlanRequest';

export async function getListShiftPlanRequest(
  params: ShiftPlanRequestParams
): Promise<
  | ApiResponse<200, JSONApiResponseBody<ShiftPlanRequestParams[]>>
  | ApiResponse<400, JSONApiResponseErrorBody<ErrorCode>>
  | ApiResponse<500, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.get(ConstantsAPI.ShiftPlanRequest, {
    params,
  });
}
