import { ref } from 'vue';
import { getApplicationApproveFlowDetail } from '../applicationApproveFlowDetail';

export default function () {
  const applicationApproveFlowDetail = ref();
  async function getApplicationApprove(params) {
    try {
      const res = await getApplicationApproveFlowDetail(
        params.id,
        params.unitId
      );
      switch (res.status) {
        case 200:
          applicationApproveFlowDetail.value = res.data.data;
          break;
        default:
          applicationApproveFlowDetail.value = {};
          break;
      }
    } catch (error) {
      console.error(error);
    }
  }

  return {
    applicationApproveFlowDetail,
    getApplicationApprove,
  };
}
