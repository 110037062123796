
import { defineComponent, toRefs, watchEffect } from '@vue/runtime-core';
import { useI18n } from 'vue-i18n';
import { computed, ref, watch } from 'vue';
import moment from 'moment';
import { isEmpty } from '@/util/useValidateForm';
export default defineComponent({
  props: {
    placeholder: {
      type: String,
      default: 'hh:mm',
    },
    label: {
      type: String,
    },
    modelValue: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    validated: {
      type: Boolean,
      default: false,
    },
    errorServer: {
      type: String,
      default: '',
    },
    format: {
      type: String,
      default: 'HH:ss',
    },
    height: {
      type: String,
    },
    width: {
      type: String,
    },
  },
  setup(props, { emit }) {
    const { required, errors } = toRefs(props);
    const i18n = useI18n();
    const t = i18n.t;
    const errorMessage = ref(errors.value);
    const isShowTimeSelected = ref(false);

    const checkRequired = () => {
      if (required.value && isEmpty(value.value)) {
        errorMessage.value = t('errors.required');
      } else {
        errorMessage.value = '';
      }
      emit('update:errors', errorMessage.value);
    };
    const value = ref(props.modelValue);
    const hour = ref(moment(props.modelValue, 'HH:mm').hour() || '');
    const minutes = ref(moment(props.modelValue, 'HH:mm').minute() || '');
    const firstEnter = ref(false);
    watch(props, (newValue) => {
      hour.value = moment(newValue.modelValue, 'HH:mm').hour();
      minutes.value = moment(newValue.modelValue, 'HH:mm').minute();
      value.value = newValue.modelValue;
    });
    const chooseHour = (item) => {
      hour.value = item;
      if (
        (hour.value === 0 || hour.value) &&
        (minutes.value === 0 || minutes.value)
      ) {
        value.value = moment(hour.value + ':' + minutes.value, 'HH:ss').format(
          props.format
        );
        emit('update:modelValue', value.value);
      }
    };
    const chooseMinutes = (item) => {
      minutes.value = item;
      if (
        (hour.value === 0 || hour.value) &&
        (minutes.value === 0 || minutes.value)
      ) {
        value.value = moment(hour.value + ':' + minutes.value, 'HH:ss').format(
          props.format
        );
        emit('update:modelValue', value.value);
      }
    };
    const onChangeInput = (e) => {
      firstEnter.value = true;
      value.value = e.target.value;
      emit('update:modelValue', e.target.value);
    };
    watchEffect(() => {
      checkRequired();
    });
    const style = computed(() => ({
      height: props.height,
      width: props.width,
    }));
    return {
      t,
      errorMessage,
      isShowTimeSelected,
      minutes,
      hour,
      value,
      firstEnter,
      style,
      chooseMinutes,
      chooseHour,
      isEmpty,
      checkRequired,
      onChangeInput,
    };
  },
});
