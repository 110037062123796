
import './table.css';
import { computed, ref, toRefs } from 'vue';
import { useI18n } from 'vue-i18n';
import iconChevronLeft from '@/assets/svg/iconChevronLeft.svg';
import iconChevronRight from '@/assets/svg/iconChevronRight.svg';
import Checkbox from '@/components/Checkbox.vue';
import { useLoadingStore } from '@/store/modules/use-loading';
export default {
  components: { Checkbox },
  props: {
    currentPage: {
      type: Number,
      default: 1,
    },
    recordTotal: {
      type: Number,
      default: 0,
    },
    itemsPerPages: {
      type: Number,
      default: 20,
    },
    showPagination: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    fixedColumnLeft: {
      type: Number,
      default: 0,
    },
    data: {
      type: Array,
      required: true,
      default: () => [],
    },
    headers: {
      type: Array,
      required: true,
      default: () => [],
    },
    basicTable: {
      type: Boolean,
      default: true,
    },
    showSelect: {
      type: Boolean,
      default: false,
    },
    selectedItem: {
      type: Array,
      default: () => [],
    },
    fixedHeader: {
      type: Boolean,
      default: false,
    },
    visibleTable: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, ctx) {
    const i18n = useI18n();
    const {
      basicTable,
      recordTotal,
      itemsPerPages,
      currentPage,
      data,
      selectedItem,
    } = toRefs(props);
    const pagesTotal = computed(() => {
      if (recordTotal.value % itemsPerPages.value === 0) {
        return Math.trunc(recordTotal.value / itemsPerPages.value);
      }
      return Math.trunc(recordTotal.value / itemsPerPages.value) + 1;
    });
    const isActive = (value) => {
      return currentPage.value === value;
    };
    const changePages = (value) => {
      if (currentPage.value === value) return;
      if (value >= 1 && value <= pagesTotal.value) {
        currentPage.value = value;
        ctx.emit('update:currentPage', value);
        ctx.emit('onChangePage');
      }
    };
    const isCheckAll = computed(
      () => selectedItemList.value.length === data.value.length
    );
    const onSelectedAll = () => {
      if (selectedItemList.value.length !== data.value.length) {
        selectedItemList.value = [...data.value];
      } else {
        selectedItemList.value = [];
      }
      ctx.emit('update:selectedItem', selectedItemList.value);
    };
    const selectedItemList = ref(selectedItem.value);
    const onSelected = () => {
      ctx.emit('update:selectedItem', selectedItemList.value);
    };
    const loadingStore = useLoadingStore();
    const isLoading = computed(() => loadingStore.state.countApiWaiting > 0);
    return {
      t: i18n.t,
      classes: computed(() => ({
        'storybook-button--basic-table': basicTable.value,
      })),
      iconChevronLeft,
      iconChevronRight,
      pagesTotal,
      isCheckAll,
      selectedItemList,
      isLoading,
      changePages,
      isActive,
      onSelectedAll,
      onSelected,
    };
  },
};
