
import { defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import Button from '@/components/AdminButton.vue';
import Table from '@/components/Table.vue';
import { usePopupDeleteStore } from '@/store/modules/popup-delete';
import { RoleApiParamSearch } from '@/api/types/Role';
import { useDeleteRole, useGetListRole } from '@/api/composables/useRole';
import router from '@/router';
import ConstantsRouter from '@/constants/router';
import { showToast } from '@/util/useToastMessage';
import PermissionSetting from '@/views/PermissionSetting.vue';
import { ROLES_DEFAULT } from '@/constants/basicFlow';

export default defineComponent({
  components: {
    Button,
    Table,
    PermissionSetting,
  },
  props: {
    currentTab: Number,
  },
  setup() {
    const i18n = useI18n();
    const t = i18n.t;
    const store = usePopupDeleteStore();
    const handleAddPermissions = () => {
      router.push({
        path: ConstantsRouter.AminSettingRoleCreate,
      });
    };
    const handleUpdatePermissions = (item) => {
      router.push({
        path: ConstantsRouter.AminSettingRoleUpdate,
        query: { id: item.id },
      });
    };
    const roleListHeaders = [
      {
        text: t('permissionCode'),
        value: 'permission_code',
        width: '15%',
      },
      {
        text: t('authorityName'),
        value: 'authority_name',
        width: '70%',
      },
      {
        text: t('edit'),
        value: 'edit',
        width: '65px',
        background: '#f2f8fc',
      },
      {
        text: t('delete'),
        value: 'delete',
        width: '65px',
        background: '#f2f8fc',
      },
      {
        text: t('copy'),
        value: 'copy',
        width: '65px',
        background: '#f2f8fc',
      },
    ];
    const roleParams = ref<RoleApiParamSearch>({});
    const { roleData, getRoleListData, pagination } = useGetListRole(
      roleParams.value
    );
    const deleteAction = (id) => {
      const { deleteData } = useDeleteRole(id);
      deleteData
        .then((error) => {
          if (error) {
            showToast('error', t('errors.privilegeRoleDeletedFail'));
          } else {
            showToast('success', t('successMessage.privilegeRoleDeleted'));
            getRoleListData(roleParams.value);
          }
        })
        .catch(() => {
          showToast('error', t('errors.serverError'));
        });
    };
    const handleDeleteItem = (item) => {
      store.dispatch('show', {
        isShown: true,
        onSubmit: () => deleteAction(item.id),
      });
    };
    const handleCopyItem = (item) => {
      router.push({
        path: ConstantsRouter.AminSettingRoleCopy,
        query: { id: item.id },
      });
    };
    return {
      t,
      roleListHeaders,
      roleData,
      store,
      pagination,
      getRoleListData,
      ROLES_DEFAULT,
      handleDeleteItem,
      deleteAction,
      handleAddPermissions,
      handleUpdatePermissions,
      handleCopyItem,
    };
  },
});
