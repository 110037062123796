import api, { ApiResponse } from '@/clients/api';
import {
  JSONApiResponseBody,
  JSONApiResponseErrorBody,
} from '@/api/types/general/JSONApiResponse';
import {} from '@/api/types/NationalHolidays';
import { ErrorCode } from '@/api/types/general/ErrorCode';
import { CheckinTimeKeepingCurrentStatus } from '@/api/types/TimeKeepingCurrentStatus';
import ConstantsAPI from '@/constants/api';

export async function getCurrentStatusTimeKeeping(
  targetDate?: string
): Promise<
  | ApiResponse<200, JSONApiResponseBody<CheckinTimeKeepingCurrentStatus>>
  | ApiResponse<404, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.get(ConstantsAPI.TimeKeepingCurrentStatus(targetDate));
}
