<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.5 3.75H4.5C4.08579 3.75 3.75 4.08579 3.75 4.5V19.5C3.75 19.9142 4.08579 20.25 4.5 20.25H19.5C19.9142 20.25 20.25 19.9142 20.25 19.5V4.5C20.25 4.08579 19.9142 3.75 19.5 3.75Z"
      stroke="#333333"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16.5 2.25V5.25"
      stroke="#333333"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.5 2.25V5.25"
      stroke="#333333"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M3.75 8.25H20.25"
      stroke="#333333"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.625 12H11.25L9.75 13.875C9.99664 13.875 10.2395 13.9358 10.457 14.0521C10.6745 14.1683 10.86 14.3364 10.9971 14.5415C11.1341 14.7465 11.2185 14.9822 11.2427 15.2276C11.267 15.4731 11.2303 15.7207 11.136 15.9486C11.0417 16.1765 10.8926 16.3776 10.702 16.5342C10.5114 16.6907 10.2852 16.7978 10.0433 16.846C9.80143 16.8943 9.55139 16.8821 9.31534 16.8106C9.07928 16.7392 8.86449 16.6106 8.68999 16.4363"
      stroke="#333333"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.5 13.125L15 12V16.875"
      stroke="#333333"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
