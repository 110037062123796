
import { computed, defineComponent, reactive, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import Input from '@/components/share/Input/index.vue';
import Select from '@/components/share/Dropdown/Dropdown.vue';
import Plus from '@/assets/svg/Plus.svg';
import router from '@/router';
import Table from '@/components/share/Table/Table.vue';
import useShiftPattern, {
  useDetailShiftPattern,
  useUpdateShiftPattern,
} from '@/api/composables/useShiftPattern';
import { ShiftPatterns } from '@/model/ShiftPattern';
import { isValidateForm } from '@/util/useValidateForm';
import InputTime from '@/components/share/Input/InputTime.vue';
import { showToast } from '@/util/useToastMessage';
import useUnitApi from '@/api/composables/useUnit';
import ConstantsRouter from '@/constants/router';
import { UnitApiGetResult } from '@/api/types/UnitApi';
import Label from '@/components/Label.vue';
import MultiSelect from '@/components/share/Dropdown/MultiSelect.vue';
import Button from '@/components/share/Button/index.vue';
import BackPreviousPage from '@/components/share/BackPreviousPage/index.vue';

export default defineComponent({
  components: {
    InputTime,
    Input,
    Select,
    Table,
    Label,
    MultiSelect,
    Button,
    BackPreviousPage,
  },
  setup() {
    const i18n = useI18n();
    const t = i18n.t;
    const optionsChoseTime = reactive([
      { text: i18n.t('onTheDay'), value: 0 },
      { text: i18n.t('beforeDay'), value: 1 },
      { text: i18n.t('nextDay'), value: 2 },
    ]);
    const patternBreakTableHeaders = [
      {
        text: '',
        value: 'function',
        width: '40px',
      },
      {
        text: t('timezone'),
        value: 'shiftPattern',
      },
    ];
    const patternTableHeaders = [
      {
        text: '',
        value: 'function',
        width: '40px',
      },
      {
        text: t('timezone'),
        value: 'shiftPattern',
      },
    ];
    const errorsServer = ref({});
    const validated = ref(false);
    const errors = ref({});
    const isValid = () => {
      validated.value = true;
      return isValidateForm(errors);
    };
    const organizationSelected = ref([]);
    const organizationSelectedConfirm = ref<UnitApiGetResult[]>([]);
    const isShow = ref(false);
    const apiPostData = ref<ShiftPatterns>({
      unit_ids: [],
      shift_pattern_times: [
        {
          start_time_day_type: 0,
          end_time_day_type: 0,
          start_time: '',
          end_time: '',
          is_break: false,
        },
        { is_break: false },
      ],
    });
    const apiPostDataBreakTime = ref<ShiftPatterns>({
      shift_pattern_times: [{ is_break: true }],
    });
    const isAllParams = ref(true);

    const { unitList } = useUnitApi({
      is_all: isAllParams.value,
    });
    const backToWorkPatternMasterList = () => {
      router.push(ConstantsRouter.WorkPatternMasterNew);
    };

    const shiftPatternId = router.currentRoute.value.query.id as string;
    const { shiftPatternDetail, getDetail } = useDetailShiftPattern();
    const getDataShiftPattern = async () => {
      if (shiftPatternId) {
        const res = await getDetail(shiftPatternId);
        if (JSON.stringify(res) === '{}') {
          showToast('error', t('workPatternAlreadyDeleted'));
          return;
        }
        apiPostData.value = { ...shiftPatternDetail.value };
        const breakTimeData = apiPostData.value.shift_pattern_times?.filter(
          (item) => item.is_break === true
        );
        const dataBreakTimeFalse = apiPostData.value.shift_pattern_times?.filter(
          (item) => item.is_break === false
        );
        apiPostData.value.shift_pattern_times = dataBreakTimeFalse;
        apiPostData.value.shift_pattern_times?.push({});

        apiPostDataBreakTime.value.shift_pattern_times = breakTimeData;
        apiPostDataBreakTime.value.shift_pattern_times?.push({});

        if (isCopyAction) {
          apiPostData.value.shift_pattern_code = '';
          apiPostData.value.shift_pattern_name += 'のコピー';
        }
      }
    };
    getDataShiftPattern();
    const valueChoseHaflHoliday = ref(1);
    const valueLateEarly = ref(1);
    const removeTimezoneDetail = (index) => {
      apiPostData.value.shift_pattern_times?.splice(index, 1);
    };
    const addTimezoneDetail = (item, index) => {
      apiPostData.value.shift_pattern_times?.splice(index, 0, {
        start_time_day_type: item.end_time_day_type,
        start_time: item.end_time,
        end_time_day_type: item.end_time_day_type,
        end_time: item.end_time,
        is_break: false,
      });
    };
    const removeTimezoneDetailBreakTime = (index) => {
      apiPostDataBreakTime.value.shift_pattern_times?.splice(index, 1);
    };
    const addTimezoneDetailBreakTime = (item, index) => {
      if (!item) {
        item = {};
      }
      apiPostDataBreakTime.value.shift_pattern_times?.splice(index, 0, {
        start_time_day_type: item.start_time_day_type || 0,
        start_time: item.start_time || '',
        end_time_day_type: item.end_time_day_type || 0,
        end_time: item.end_time || '',
        is_break: true,
      });
    };

    const submitRegisterShiftPattern = () => {
      if (!isValid()) {
        return;
      }
      const shiftPostPatternTimes = { ...apiPostData.value };
      shiftPostPatternTimes.shift_pattern_times = apiPostData.value.shift_pattern_times
        ?.slice(0, -1)
        .concat(
          apiPostDataBreakTime.value.shift_pattern_times?.slice(0, -1) || []
        );
      const { registered } = useShiftPattern(shiftPostPatternTimes);
      registered
        .then((errors) => {
          if (errors) {
            errorsServer.value = errors.errors.value;
            if (errors?.errorsCommon.value) {
              showToast('error', errors?.errorsCommon.value);
            } else {
              showToast('warning', t('invalidInput'));
            }
          } else {
            showToast('success', t('successMessage.workPatternCreated'));
            backToWorkPatternMasterList();
          }
        })
        .catch(() => {
          showToast('error', t('errors.serverError'));
        });
    };
    const handleRegisterOrUpdate = () => {
      if (isUpdateAction) {
        submitUpdateShiftPattern();
        return;
      }
      submitRegisterShiftPattern();
    };

    const isCopyAction =
      `/new${ConstantsRouter.WorkPatternCopy}` ===
      router.currentRoute.value.path;
    const isUpdateAction = shiftPatternId && !isCopyAction;

    const submitUpdateShiftPattern = () => {
      if (!isValid()) {
        return;
      }
      const shiftPostPatternTimes = { ...apiPostData.value };
      shiftPostPatternTimes.shift_pattern_times = apiPostData.value.shift_pattern_times
        ?.slice(0, -1)
        .concat(
          apiPostDataBreakTime.value.shift_pattern_times?.slice(0, -1) || []
        );
      const { update } = useUpdateShiftPattern(
        shiftPatternId,
        shiftPostPatternTimes
      );
      update
        .then((errors) => {
          if (errors) {
            errorsServer.value = errors.errors.value;
            if (errors?.errorsCommon.value) {
              showToast('error', errors?.errorsCommon.value);
            } else {
              showToast('warning', t('invalidInput'));
            }
          } else {
            showToast('success', t('successMessage.workPatternUpdated'));
            backToWorkPatternMasterList();
          }
        })
        .catch(() => {
          showToast('error', t('errors.serverError'));
        });
    };
    const isDisableSubmit = () => {
      for (const property in errors.value) {
        if (errors.value[property].length > 0) {
          return true;
        }
      }
      return false;
    };

    const shiftPatternTimesLength = computed(
      () => apiPostData.value.shift_pattern_times?.length || 0
    );

    return {
      t,
      Plus,
      optionsChoseTime,
      valueChoseHaflHoliday,
      valueLateEarly,
      apiPostData,
      isShow,
      validated,
      errors,
      organizationSelected,
      organizationSelectedConfirm,
      shiftPatternDetail,
      errorsServer,
      unitList,
      isAllParams,
      removeTimezoneDetail,
      addTimezoneDetail,
      handleRegisterOrUpdate,
      backToWorkPatternMasterList,
      isValid,
      patternTableHeaders,
      apiPostDataBreakTime,
      removeTimezoneDetailBreakTime,
      addTimezoneDetailBreakTime,
      patternBreakTableHeaders,
      isUpdateAction,
      shiftPatternTimesLength,
      isDisableSubmit,
    };
  },
});
