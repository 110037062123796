
import { computed, defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import Table from '@/components/share/Table/Table.vue';
import Input from '@/components/share/Input/index.vue';
import Button from '@/components/share/Button/index.vue';
import { WorkingScheduleChangeApplicationParam } from '@/model/WorkingScheduleChangeApplication';
import { isValidateForm } from '@/util/useValidateForm';
import { showToast } from '@/util/useToastMessage';
import {
  useDetailWorkingScheduleChangeApplication,
  usePostWorkingScheduleChangeApplication,
} from '@/api/composables/useWorkingScheduleChangeApplication';
import router from '@/router';
import moment from 'moment';
import { useLoginAccountStore } from '@/store/modules/login-account';
import useUnit from '@/api/composables/useUnit';
import InputTime from '@/components/share/Input/InputTime.vue';
import Dropdown from '@/components/share/Dropdown/Dropdown.vue';
import ConstantsRouter from '@/constants/router';
import BackPreviousPage from '@/components/share/BackPreviousPage/index.vue';

export default defineComponent({
  components: { Dropdown, InputTime, Button, Input, Table, BackPreviousPage },
  setup() {
    const i18n = useI18n();
    const t = i18n.t;
    const workingScheduleChangeApplicationHeaders = [
      { text: '', value: 'type', dataAlign: 'center' },
      {
        text: t('checkIn'),
        value: 'check_in',
        dataAlign: 'center',
        width: '120px',
      },
      {
        text: t('checkOut'),
        value: 'check_out',
        dataAlign: 'center',
        width: '120px',
      },
      {
        text: t('breakStartTimeRounding'),
        value: 'break_start_time_rounding',
        dataAlign: 'center',
      },
      {
        text: t('breakEndTimeRounding'),
        value: 'break_end_time_rounding',
        dataAlign: 'center',
      },
      {
        text: t('breakTime'),
        value: 'break_time',
        dataAlign: 'center',
        width: '120px',
      },
      { text: t('goOut'), value: 'go_out', dataAlign: 'center' },
      { text: t('goBack'), value: 'go_back', dataAlign: 'center' },
      {
        text: t('scheduledOvertime'),
        value: 'scheduled_overtime',
        dataAlign: 'center',
      },
      {
        text: t('attendancePlace'),
        value: 'attendance_place',
        dataAlign: 'center',
      },
      { text: t('checkinUnit'), value: 'checkin_unit', dataAlign: 'center' },
    ];
    const workingScheduleChangeApplicationData = [
      {
        type: t('scheduledToWork'),
        check_in: '09:00',
        check_out: '18:00',
        break_time: '01:00',
        checkin_unit: '東日本営業本部インサイドセールスチーム',
      },
      { type: t('application') },
    ];
    const targetDate = router.currentRoute.value.query.target_date as string;
    const applicationDefineId = router.currentRoute.value.query.id as string;
    const displayTargetDate = (date) => {
      if (date && moment(date.toString()).isValid()) {
        return moment(date).format(
          `YYYY${t('year')}MM${t('month')}DD${t('day')}（dd）`
        );
      }
      return '';
    };
    const accountStore = useLoginAccountStore();
    const myAccount = computed(() => accountStore.state.myAccount);
    const { unitList } = useUnit({});
    const backToTimeCard = () => {
      router.push(`/new${ConstantsRouter.TimeCard}`);
    };
    const {
      workingScheduleChangeApplicationDetail,
      getWorkingScheduleChangeApplicationDetailData,
    } = useDetailWorkingScheduleChangeApplication();
    getWorkingScheduleChangeApplicationDetailData(applicationDefineId);

    const formDataWorkingScheduleChangeApplication = ref<WorkingScheduleChangeApplicationParam>(
      {}
    );
    const validated = ref(false);
    const errors = ref({});
    const errorServer = ref({});
    const isValid = () => {
      validated.value = true;
      return isValidateForm(errors);
    };
    const submitWorkingScheduleChangeApplication = async () => {
      if (!isValid()) return;
      const { registered } = usePostWorkingScheduleChangeApplication({
        ...formDataWorkingScheduleChangeApplication.value,
        ...{
          target_date: targetDate,
          application_define_id: applicationDefineId,
          pp_employee_id: myAccount.value.pp_employee_id,
        },
      });
      await registered
        .then((error) => {
          if (error) {
            errorServer.value = error.errors.value;
            if (error.errorsCommon.value) {
              showToast('error', error.errorsCommon.value);
            } else {
              showToast('warning', t('invalidInput'));
            }
          } else {
            showToast('success', t('theApplicationHasBeenCreated'));
            backToTimeCard();
          }
        })
        .catch(() => {
          showToast('error', i18n.t('errors.serverError'));
        });
    };

    return {
      t,
      workingScheduleChangeApplicationHeaders,
      workingScheduleChangeApplicationData,
      formDataWorkingScheduleChangeApplication,
      validated,
      errorServer,
      targetDate,
      unitList,
      errors,
      getWorkingScheduleChangeApplicationDetailData,
      workingScheduleChangeApplicationDetail,
      submitWorkingScheduleChangeApplication,
      isValid,
      displayTargetDate,
      backToTimeCard,
    };
  },
});
