import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-grey-3 pb-4 mr-40 ml-40" }
const _hoisted_2 = { class: "title text-center mb-3 font-bold text-4xl" }
const _hoisted_3 = { class: "content m-auto" }
const _hoisted_4 = { class: "flex justify-between mb-4" }
const _hoisted_5 = { class: "mr-24 w-1/2" }
const _hoisted_6 = { class: "mb-1" }
const _hoisted_7 = { class: "w-1/2" }
const _hoisted_8 = { class: "mb-1" }
const _hoisted_9 = { class: "mb-1 m-auto" }
const _hoisted_10 = { class: "flex items-center" }
const _hoisted_11 = { class: "flex items-center justify-center" }
const _hoisted_12 = { class: "mr-12" }
const _hoisted_13 = { class: "mr-4 font-bold text-4xl" }
const _hoisted_14 = { class: "mr-4 font-bold text-5xl text-grey-67" }
const _hoisted_15 = { class: "font-bold text-4xl" }
const _hoisted_16 = { class: "mb-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Select = _resolveComponent("Select")
  const _component_Checkbox = _resolveComponent("Checkbox")
  const _component_Avatar = _resolveComponent("Avatar")
  const _component_Table = _resolveComponent("Table")
  const _component_Button = _resolveComponent("Button")
  const _component_Popup = _resolveComponent("Popup")

  return (_openBlock(), _createBlock(_component_Popup, {
    modelValue: _ctx.isShow,
    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.isShow = $event)),
    width: "1086px",
    persistent: ""
  }, {
    default: _withCtx(() => [
      _createVNode("div", _hoisted_1, [
        _createVNode("div", _hoisted_2, _toDisplayString(_ctx.t('pleaseSelectTheTargetPerson')), 1),
        _createVNode("div", _hoisted_3, [
          _createVNode("div", _hoisted_4, [
            _createVNode("div", _hoisted_5, [
              _createVNode("div", _hoisted_6, _toDisplayString(_ctx.t('department')), 1),
              _createVNode("div", null, [
                _createVNode(_component_Select, {
                  "options-select": _ctx.departmentList,
                  "item-text": "name",
                  "item-value": "id",
                  "model-value": "1"
                }, null, 8, ["options-select"])
              ])
            ]),
            _createVNode("div", _hoisted_7, [
              _createVNode("div", _hoisted_8, _toDisplayString(_ctx.t('workingStyle')), 1),
              _createVNode("div", null, [
                _createVNode(_component_Select, {
                  "options-select": _ctx.workingStyleList,
                  "item-text": "name",
                  "item-value": "id",
                  "model-value": "1"
                }, null, 8, ["options-select"])
              ])
            ])
          ]),
          _createVNode("div", _hoisted_9, [
            _createVNode(_component_Checkbox, {
              class: "mb-1",
              "option-checkbox": { label: _ctx.t('selectAll') },
              onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onSelectAllPerson($event))),
              modelValue: _ctx.isSelectAll,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.isSelectAll = $event))
            }, null, 8, ["option-checkbox", "modelValue"]),
            _createVNode(_component_Table, {
              class: "mb-4 h-110 text-center",
              headers: _ctx.membersToSubmittedHeaders,
              data: _ctx.personToSubmittedData,
              "fixed-header": ""
            }, {
              "header-checkbox": _withCtx(({ item }) => [
                _createTextVNode(_toDisplayString(item.text), 1)
              ]),
              checkbox: _withCtx(({ index }) => [
                _createVNode(_component_Checkbox, {
                  classes: "justify-center",
                  modelValue: _ctx.personSelected[Number(index)].checked,
                  "onUpdate:modelValue": ($event: any) => (_ctx.personSelected[Number(index)].checked = $event)
                }, null, 8, ["modelValue", "onUpdate:modelValue"])
              ]),
              name: _withCtx(({ item }) => [
                _createVNode("div", _hoisted_10, [
                  _createVNode(_component_Avatar, {
                    width: "40px",
                    height: "40px",
                    src: item.avatar,
                    class: "mr-2"
                  }, null, 8, ["src"]),
                  _createVNode("div", null, _toDisplayString(item.name), 1)
                ])
              ]),
              _: 1
            }, 8, ["headers", "data"])
          ]),
          _createVNode("div", _hoisted_11, [
            _createVNode("div", _hoisted_12, [
              _createVNode("span", _hoisted_13, _toDisplayString(_ctx.t('choosing')), 1),
              _createVNode("span", _hoisted_14, _toDisplayString(_ctx.numberOfPeopleSelected), 1),
              _createVNode("span", _hoisted_15, _toDisplayString(_ctx.t('person')), 1)
            ]),
            _createVNode("div", null, [
              _createVNode("div", _hoisted_16, [
                _createVNode(_component_Button, {
                  class: "btn-gradation-light-blue w-50 h-12",
                  onClick: _ctx.onClosePopup
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t('addAction')), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _createVNode("div", null, [
                _createVNode(_component_Button, {
                  class: "btn-gradation-secondary w-50 h-12",
                  onClick: _ctx.onClosePopup
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t('buttonCancel')), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ])
            ])
          ])
        ])
      ])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}