import { ref } from 'vue';
import { convertErrorFromServerToJson } from '@/util/useValidateForm';
import {
  RoleApiParamSearch,
  RoleApiPostData,
  RoleApiUpdateData,
  RoleGetData,
} from '@/api/types/Role';
import {
  deleteRole,
  getRole,
  getRoleDetail,
  postRole,
  updateRole,
} from '@/api/role';

export function usePostRole(data: RoleApiPostData) {
  const errors = ref({});
  const errorsCommon = ref<string>();
  const loading = ref(false);
  return {
    registered: (async () => {
      loading.value = true;
      const response = await postRole(data);
      switch (response.status) {
        case 200:
          loading.value = false;
          return null;
        case 201:
          loading.value = false;
          return null;
        default:
          if (response.data.errors[0].source) {
            errors.value = convertErrorFromServerToJson(response.data.errors);
          } else {
            errorsCommon.value = response.data.errors[0].title;
          }
          loading.value = false;
          return {
            errors,
            errorsCommon,
          };
      }
    })(),
  };
}

export function useGetListRole(params: RoleApiParamSearch) {
  const roleData = ref<RoleGetData[]>();
  const loading = ref(false);
  const response = ref();
  const pagination = ref({
    current_page: 1,
    per_page: 20,
    total: 0,
  });

  async function getRoleListData(params) {
    try {
      loading.value = true;
      const res = await getRole(params);
      switch (res.status) {
        case 200:
          response.value = res.data;
          roleData.value = response.value.data;
          // pagination.value = response.value.meta.pagination;
          break;
        default:
          roleData.value = [];
          break;
      }
    } catch (error) {
      console.error(error);
    } finally {
      loading.value = false;
    }
  }

  getRoleListData(params);

  return { roleData, loading, pagination, getRoleListData };
}

export function useUpdateRole(id: string, data: RoleApiUpdateData) {
  const errors = ref({});
  const errorsCommon = ref<string>();
  const loading = ref(false);
  return {
    update: (async () => {
      loading.value = true;
      const response = await updateRole(id, data);
      switch (response.status) {
        case 200:
          loading.value = false;
          return null;
        default:
          if (response.data.errors[0].source) {
            errors.value = convertErrorFromServerToJson(response.data.errors);
          } else {
            errorsCommon.value = response.data.errors[0].title;
          }
          loading.value = false;
          return {
            errors,
            errorsCommon,
          };
      }
    })(),
  };
}

export function useDetailRole() {
  const roleDetail = ref<RoleApiUpdateData>({});
  const loading = ref(false);

  async function getRoleDetailData(id) {
    try {
      loading.value = true;
      const res = await getRoleDetail(id);
      const response = ref();
      switch (res.status) {
        case 200:
          response.value = res.data.data;
          roleDetail.value = response.value;
          break;
        default:
          roleDetail.value = {};
          break;
      }
    } catch (error) {
      console.error(error);
      return {};
    } finally {
      loading.value = false;
    }
  }

  return { roleDetail, loading, getRoleDetailData };
}

export function useDeleteRole(id) {
  const loading = ref(false);
  return {
    deleteData: (async () => {
      loading.value = true;
      const response = await deleteRole(id);
      switch (response.status) {
        case 200:
          loading.value = false;
          return null;
        default:
          loading.value = false;
          return response.data.errors[0].title;
      }
    })(),
  };
}
