
import { computed, defineComponent, onBeforeMount, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import workingStyleMasterRegister, {
  useDetailWorkingStyleMaster,
  useUpdateWorkingStyleMaster,
} from '@/api/composables/useWorkingStyleMaster';
import { useStore } from 'vuex';
import {
  WorkingStyleMasterDefault,
  WorkingStyleMasterParams,
} from '@/model/WorkingStyleMaster';
import router from '@/router';
import Button from '@/components/share/Button/index.vue';
import Input from '@/components/share/Input/index.vue';
import Select from '@/components/share/Dropdown/Dropdown.vue';
import RadioGroup from '@/components/share/Radio/RadioGroup.vue';
import InputTime from '@/components/share/Input/InputTime.vue';
import WorkingSchedule from '@/views/New/WorkingStyleMaster/WorkingSchedule.vue';
import BreakSetting from '@/views/New/WorkingStyleMaster/BreakSetting.vue';
import GoOutSetting from '@/views/New/WorkingStyleMaster/GoOutSetting.vue';
import BeingLateTime from '@/views/New/WorkingStyleMaster/BeingLateTime.vue';
import AutomaticBreak from '@/views/New/WorkingStyleMaster/AutomaticBreak.vue';
import ConfirmRegisterWorkingStyleMaster from '@/views/New/WorkingStyleMaster/ConfirmRegisterWorkingStyleMaster.vue';
import ConstantsRouter from '@/constants/router';
import {
  DISTINGUISHING_HOLIDAY_TYPE,
  MIDNIGHT_DAY_TYPE,
  WAY_OF_WORKING,
  WEEK_DAY,
  WORKING_TYPE,
} from '@/constants/workingStyleMaster';
import { showToast } from '@/util/useToastMessage';
import { isEmpty, isValidateForm } from '@/util/useValidateForm';
import moment from 'moment';
import { MONTH_FORMAT } from '@/constants/date';
import ErrorMessage from '@/components/share/Input/ErrorMessage.vue';
import BackPreviousPage from '@/components/share/BackPreviousPage/index.vue';

export default defineComponent({
  components: {
    ErrorMessage,
    GoOutSetting,
    BreakSetting,
    WorkingSchedule,
    InputTime,
    ConfirmRegisterWorkingStyleMaster,
    Button,
    AutomaticBreak,
    BeingLateTime,
    Select,
    Input,
    RadioGroup,
    BackPreviousPage,
  },
  setup() {
    const i18n = useI18n();
    const store = useStore();
    onBeforeMount(() => {
      store.commit(
        'root/SET_BACK_ROUTER_PATH',
        ConstantsRouter.WorkingStyleMaster
      );
      store.commit('root/SET_CURRENT_PAGE', i18n.t('workingStyleMaster'));
    });

    const labelCheckboxFollowingDay = {
      label: i18n.t('followingDay'),
    };
    const dayOfWeekList = [
      { key: WEEK_DAY.SUNDAY, value: i18n.t('daysOfWeek.sun') },
      { key: WEEK_DAY.MONDAY, value: i18n.t('daysOfWeek.mon') },
      { key: WEEK_DAY.TUESDAY, value: i18n.t('daysOfWeek.tue') },
      { key: WEEK_DAY.WEDNESDAY, value: i18n.t('daysOfWeek.wed') },
      { key: WEEK_DAY.THURSDAY, value: i18n.t('daysOfWeek.thu') },
      { key: WEEK_DAY.FRIDAY, value: i18n.t('daysOfWeek.fri') },
      { key: WEEK_DAY.SATURDAY, value: i18n.t('daysOfWeek.sat') },
    ];
    const closingDayOptionList = ref();
    // init list closingDayOptionList is 31 days
    closingDayOptionList.value = Array.from(Array(31)).map((e, i) => {
      return { value: i + 1, text: i + 1 };
    });
    closingDayOptionList.value.push({ text: i18n.t('end'), value: 0 });
    const distinguishingOption = [
      {
        value: DISTINGUISHING_HOLIDAY_TYPE.DISTINGUISHING,
        label: i18n.t('distinguish'),
      },
      {
        value: DISTINGUISHING_HOLIDAY_TYPE.NOT_DISTINGUISHING,
        label: i18n.t('notDistinguish'),
      },
    ];
    const distinguishingOptionCustom = [
      {
        value: DISTINGUISHING_HOLIDAY_TYPE.NOT_DISTINGUISHING,
        label: i18n.t('notDistinguish'),
      },
      {
        value: DISTINGUISHING_HOLIDAY_TYPE.DISTINGUISHING,
        label: i18n.t('distinguish'),
      },
    ];

    const convertHolidayTypeList = [
      {
        value: true,
        label: i18n.t('change'),
      },
      {
        value: false,
        label: i18n.t('notChange'),
      },
    ];
    const workingTypeList = [
      {
        value: WORKING_TYPE.STANDARD_LABOR,
        label: i18n.t('standardLabor'),
        // label: `${i18n.t('standardLabor') + i18n.t('fixedService')}`,
      },
      // {
      //   value: WORKING_TYPE.STANDARD_LABOR_WORK_SHIFT,
      //   label: `${i18n.t('standardLabor') + i18n.t('shiftWorkService')}`,
      // },
      {
        value: WORKING_TYPE.TRANSFORMATION_LABOR,
        label: i18n.t('transformationLabor'),
        textTooltip: i18n.t('noteDutySystem'),
      },
      {
        value: WORKING_TYPE.DISCRETIONARY_LABOR,
        label: i18n.t('discretionaryLabor'),
      },
      { value: WORKING_TYPE.FLEXTIME_SYSTEM, label: i18n.t('flextimeSystem') },
    ];
    const wayOfWorkingList = [
      { value: WAY_OF_WORKING.FIXED, label: i18n.t('fixedDuty') },
      { value: WAY_OF_WORKING.SHIFT, label: i18n.t('shiftWork') },
    ];
    const optionsChoseTime = [
      { text: i18n.t('onTheDay'), value: MIDNIGHT_DAY_TYPE.ON_DAY },
      { text: i18n.t('beforeDay'), value: MIDNIGHT_DAY_TYPE.BEFORE_DAY },
      { text: i18n.t('nextDay'), value: MIDNIGHT_DAY_TYPE.NEXT_DAY },
    ];
    const dailyClosingTimeDayTypeOption = [
      { text: i18n.t('onTheDay'), value: MIDNIGHT_DAY_TYPE.ON_DAY },
      { text: i18n.t('nextDay'), value: MIDNIGHT_DAY_TYPE.NEXT_DAY },
    ];
    const isRequiredMidnightTime = computed(
      () =>
        !isEmpty(workingStyleMasterParams.value.midnight_working_start) ||
        !isEmpty(workingStyleMasterParams.value.midnight_start_day_type) ||
        !isEmpty(workingStyleMasterParams.value.midnight_working_end) ||
        !isEmpty(workingStyleMasterParams.value.midnight_end_day_type)
    );

    const errors = ref({});
    let workingStyleMasterParams = ref<WorkingStyleMasterParams>(
      JSON.parse(JSON.stringify(WorkingStyleMasterDefault))
    );
    const validated = ref(false);
    const errorServer = ref({});
    const isValid = () => {
      validated.value = true;
      return isValidateForm(errors);
    };

    const isShowRegisterWorkingStyleMasterPopup = ref(false);
    const openRegisterWorkingStyleMaster = () => {
      isShowRegisterWorkingStyleMasterPopup.value = true;
    };
    const closeRegisterWorkingStyleMaster = () => {
      isShowRegisterWorkingStyleMasterPopup.value = false;
    };

    const workingStyleMasterUpdateId = router.currentRoute.value.query
      .id as string;
    const isCopyAction =
      `/new${ConstantsRouter.WorkingStyleMasterCopy}` ===
      router.currentRoute.value.path;
    const isUpdateAction = workingStyleMasterUpdateId && !isCopyAction;
    const {
      workingStyleMasterDetail,
      getDetailData,
    } = useDetailWorkingStyleMaster();
    const getWorkingStyleMasterDetail = async () => {
      const res = await getDetailData(workingStyleMasterUpdateId);
      if (JSON.stringify(res) === '{}') {
        showToast('error', i18n.t('workStyleMasterAlreadyDeleted'));
        return;
      }
      // set default value for workingStyleMasterParams
      if (workingStyleMasterUpdateId && workingStyleMasterDetail.value) {
        // set min a element for working_style_standard_session_breaks
        if (
          workingStyleMasterDetail.value
            .working_style_standard_session_breaks &&
          workingStyleMasterDetail.value.working_style_standard_session_breaks
            .length === 0
        ) {
          workingStyleMasterDetail.value.working_style_standard_session_breaks =
            workingStyleMasterParams.value.working_style_standard_session_breaks;
        }
        // set min a element for working_style_standard_automatic_breaks
        if (
          workingStyleMasterDetail.value
            .working_style_standard_automatic_breaks &&
          workingStyleMasterDetail.value.working_style_standard_automatic_breaks
            .length === 0
        ) {
          workingStyleMasterDetail.value.working_style_standard_automatic_breaks =
            workingStyleMasterParams.value.working_style_standard_automatic_breaks;
        }
        // set min a element for working_style_special_overtimes
        if (
          workingStyleMasterDetail.value.working_style_special_overtimes &&
          workingStyleMasterDetail.value.working_style_special_overtimes
            .length === 0
        ) {
          workingStyleMasterDetail.value.working_style_special_overtimes =
            workingStyleMasterParams.value.working_style_special_overtimes;
        }
        if (
          workingStyleMasterDetail.value.working_style_schedule_weeks &&
          workingStyleMasterDetail.value.working_style_schedule_weeks.length ===
            0
        ) {
          workingStyleMasterDetail.value.working_style_schedule_weeks =
            workingStyleMasterParams.value.working_style_schedule_weeks;
        }

        workingStyleMasterParams.value = workingStyleMasterDetail.value;
        workingStyleMasterParams.value.apply_start_date = moment(
          workingStyleMasterParams.value.apply_start_date
        ).format(MONTH_FORMAT);

        if (!workingStyleMasterParams.value.working_style_standard) {
          workingStyleMasterParams.value.working_style_standard = {};
        }
        if (!workingStyleMasterParams.value.working_style_deformation) {
          workingStyleMasterParams.value.working_style_deformation = {};
        }
        workingStyleMasterParams.value.working_style_standard_automatic_breaks?.forEach(
          (item) => {
            item['working_time_hour'] = item.working_time?.split(':')[0];
            item['working_time_minutes'] = item.working_time?.split(':')[1];
            item['break_time_hour'] = item.break_time?.split(':')[0];
            item['break_time_minutes'] = item.break_time?.split(':')[1];
          }
        );
        workingStyleMasterParams.value.is_convert_holiday_type =
          workingStyleMasterParams.value.is_convert_holiday_type === true;
      }

      if (isCopyAction) {
        workingStyleMasterParams.value.working_style_code = '';
        workingStyleMasterParams.value.working_style_name += 'のコピー';
      }
    };
    if (workingStyleMasterUpdateId) {
      getWorkingStyleMasterDetail();
    }

    const submitCreateWorkingStyleMaster = async () => {
      const { registered } = workingStyleMasterRegister(
        workingStyleMasterParams.value
      );
      await registered
        .then((error) => {
          if (error) {
            errorServer.value = error.errors.value;
            if (error.errorsCommon.value) {
              showToast('error', error.errorsCommon.value);
            } else {
              showToast('warning', i18n.t('invalidInput'));
            }
          } else {
            showToast(
              'success',
              i18n.t('successMessage.workStyleMasterHasBeenCreated')
            );
            backToListWorkingStyleMaster();
          }
        })
        .catch(() => {
          showToast('error', i18n.t('errors.serverError'));
        });
    };
    const submitUpdateWorkingStyleMaster = async () => {
      const { update } = useUpdateWorkingStyleMaster(
        workingStyleMasterUpdateId,
        workingStyleMasterParams.value
      );
      await update
        .then((error) => {
          if (error) {
            errorServer.value = error.errors.value;
            if (error.errorsCommon.value) {
              showToast('error', error.errorsCommon.value);
            } else {
              showToast('warning', i18n.t('invalidInput'));
            }
          } else {
            showToast(
              'success',
              i18n.t('successMessage.workStyleMasterHasBeenUpdated')
            );
            backToListWorkingStyleMaster();
          }
        })
        .catch(() => {
          showToast('error', i18n.t('errors.serverError'));
        });
    };
    const submitCreateOrUpdateWorkingStyleMaster = async () => {
      if (!isValid()) return;
      if (isUpdateAction) {
        await submitUpdateWorkingStyleMaster();
      } else {
        await submitCreateWorkingStyleMaster();
      }
      if (!errorServer.value['apply_start_date']) {
        closeRegisterWorkingStyleMaster();
      }
    };
    const backToListWorkingStyleMaster = () => {
      router.push(`/new${ConstantsRouter.WorkingStyleMaster}`);
    };
    const isDisableSubmit = () => {
      return Object.values(errors.value).some((e) => !!e);
    };
    const changeDistinguishingHolidayStatus = (value) => {
      if (
        workingStyleMasterParams.value.is_convert_holiday_type === null &&
        value
      ) {
        workingStyleMasterParams.value.is_convert_holiday_type = true;
      }
    };
    return {
      t: i18n.t,
      optionsChoseTime,
      labelCheckboxFollowingDay,
      dayOfWeekList,
      workingStyleMasterParams,
      closingDayOptionList,
      store,
      errors,
      isShowRegisterWorkingStyleMasterPopup,
      distinguishingOption,
      convertHolidayTypeList,
      workingTypeList,
      wayOfWorkingList,
      validated,
      errorServer,
      workingStyleMasterDetail,
      isRequiredMidnightTime,
      isUpdateAction,
      dailyClosingTimeDayTypeOption,
      DISTINGUISHING_HOLIDAY_TYPE,
      WORKING_TYPE,
      isValid,
      submitCreateWorkingStyleMaster,
      openRegisterWorkingStyleMaster,
      closeRegisterWorkingStyleMaster,
      submitUpdateWorkingStyleMaster,
      submitCreateOrUpdateWorkingStyleMaster,
      backToListWorkingStyleMaster,
      isDisableSubmit,
      changeDistinguishingHolidayStatus,
      distinguishingOptionCustom,
    };
  },
});
