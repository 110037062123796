export const convertNullPropertyToEmptyStringProperty = (obj = {}) => {
  return Object.keys(obj).reduce((s, key) => {
    if (typeof obj[key] !== 'boolean' && !obj[key]) {
      return {
        ...s,
        [key]: '',
      };
    }
    return {
      ...s,
      [key]: obj[key],
    };
  }, {});
};
