import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "organizational-information mt-8 ml-8 mr-8 text-gray-333 mb-20" }
const _hoisted_2 = { class: "flex items-center mb-6 font-bold text-2xl page-title" }
const _hoisted_3 = { class: "flex items-base text-2xl font-bold text-gray-333" }
const _hoisted_4 = { class: "mr-8" }
const _hoisted_5 = { class: "organizational-information__content flex justify-between items-center" }
const _hoisted_6 = { class: "organizational-information__box" }
const _hoisted_7 = { class: "organizational-information__desc" }
const _hoisted_8 = { class: "organizational-information__title" }
const _hoisted_9 = { class: "organizational-information__button" }
const _hoisted_10 = { class: "flex mt-4" }
const _hoisted_11 = { class: "flex action--add whitespace-no-wrap" }
const _hoisted_12 = { class: "overflow-auto" }
const _hoisted_13 = { class: "flex justify-center" }
const _hoisted_14 = { class: "flex justify-self-center" }
const _hoisted_15 = { class: "flex justify-self-center" }
const _hoisted_16 = { class: "flex justify-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Input = _resolveComponent("Input")
  const _component_Table = _resolveComponent("Table")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode("div", _hoisted_3, [
        (!_ctx.backRouterPath)
          ? (_openBlock(), _createBlock("img", {
              key: 0,
              src: _ctx.backNavigation,
              alt: "",
              srcset: "",
              class: "mr-4 cursor-pointer",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.backRoute && _ctx.backRoute(...args)))
            }, null, 8, ["src"]))
          : _createCommentVNode("", true),
        _createTextVNode(" " + _toDisplayString(_ctx.currentPageTextHeader), 1)
      ]),
      _createVNode("div", _hoisted_4, _toDisplayString(_ctx.t('organizationalInformation.affiliationInformation')), 1),
      _createVNode("div", null, [
        _createVNode(_component_Input, {
          class: "input-search",
          radius: "59px",
          icon: _ctx.iconSearch,
          placeholder: _ctx.t('organizationalInformation.searchByDepartment')
        }, null, 8, ["icon", "placeholder"])
      ])
    ]),
    _createVNode("div", _hoisted_5, [
      _createVNode("div", _hoisted_6, [
        _createVNode("p", _hoisted_7, _toDisplayString(_ctx.t('organizationalInformation.desc')), 1),
        _createVNode("p", _hoisted_8, _toDisplayString(_ctx.t('organizationalInformation.title')), 1)
      ]),
      _createVNode("div", _hoisted_9, [
        _createVNode("div", _hoisted_10, [
          _createVNode("span", _hoisted_11, [
            _createVNode("img", {
              src: _ctx.Plus,
              alt: "",
              srcset: ""
            }, null, 8, ["src"]),
            _createTextVNode(_toDisplayString(_ctx.t('organizationalInformation.buttonAdd')), 1)
          ])
        ])
      ])
    ]),
    _createVNode("div", _hoisted_12, [
      _createVNode(_component_Table, {
        "show-select": "",
        headers: _ctx.OrganizationalInformationOptionHeader,
        data: _ctx.unitList,
        "selected-item": _ctx.OrganizationalInformationSelected,
        "onUpdate:selected-item": _cache[2] || (_cache[2] = ($event: any) => (_ctx.OrganizationalInformationSelected = $event)),
        "show-pagination": true,
        loading: _ctx.loading,
        recordTotal: _ctx.pagination.total,
        itemsPerPages: _ctx.pagination.per_page,
        "current-page": _ctx.currentPage,
        "onUpdate:current-page": _cache[3] || (_cache[3] = ($event: any) => (_ctx.currentPage = $event)),
        onOnChangePage: _ctx.onChangePage
      }, {
        unit_id: _withCtx(({ index }) => [
          _createVNode("div", _hoisted_13, [
            _createVNode("div", null, _toDisplayString(_ctx.convertNumberPage(index)), 1)
          ])
        ]),
        unit_code: _withCtx(({ item }) => [
          _createVNode("div", _hoisted_14, [
            _createVNode("div", null, _toDisplayString(item.unit_code), 1)
          ])
        ]),
        unit_name: _withCtx(({ item }) => [
          _createVNode("div", _hoisted_15, [
            _createVNode("div", null, _toDisplayString(item.unit_name), 1)
          ])
        ]),
        date_change_time: _withCtx(({ item }) => [
          _createVNode("div", _hoisted_16, [
            _createVNode("div", null, _toDisplayString(item.unit_update), 1)
          ])
        ]),
        _: 1
      }, 8, ["headers", "data", "selected-item", "loading", "recordTotal", "itemsPerPages", "current-page", "onOnChangePage"])
    ])
  ]))
}