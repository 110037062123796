import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "scheduled-labor-master-register-container mb-11" }
const _hoisted_2 = { class: "font-bold font-size-24 mb-5" }
const _hoisted_3 = { class: "flex items-center mb-6" }
const _hoisted_4 = { class: "w-112" }
const _hoisted_5 = { class: "flex items-center mb-6" }
const _hoisted_6 = { class: "w-112" }
const _hoisted_7 = { class: "flex items-center mb-7" }
const _hoisted_8 = { class: "w-28" }
const _hoisted_9 = { class: "mb-4" }
const _hoisted_10 = { class: "text-center" }
const _hoisted_11 = { class: "text-center p-1" }
const _hoisted_12 = { class: "flex justify-center" }
const _hoisted_13 = { class: "h-12 p-2 flex items-center justify-center w-1/5 bg-gray-200" }
const _hoisted_14 = { class: "text-center p-1" }
const _hoisted_15 = { class: "flex justify-center" }
const _hoisted_16 = { class: "h-12 p-2 flex items-center justify-center w-1/5 bg-gray-200" }
const _hoisted_17 = { class: "h-12 p-2 flex items-center justify-center w-1/5 bg-gray-200" }
const _hoisted_18 = { class: "text-center p-1" }
const _hoisted_19 = { class: "flex justify-center items-center" }
const _hoisted_20 = { class: "h-12 p-2 flex items-center justify-center w-1/5 bg-gray-200" }
const _hoisted_21 = { class: "h-12 p-2 flex items-center justify-center w-1/5 bg-gray-200" }
const _hoisted_22 = { class: "flex justify-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Label = _resolveComponent("Label")
  const _component_Input = _resolveComponent("Input")
  const _component_YearInput = _resolveComponent("YearInput")
  const _component_Table = _resolveComponent("Table")
  const _component_Button = _resolveComponent("Button")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, _toDisplayString(_ctx.t('scheduledLaborPatternSetting')), 1),
    _createVNode("div", _hoisted_3, [
      _createVNode("div", _hoisted_4, [
        _createVNode(_component_Label, {
          mandatory: "",
          "text-label": _ctx.t('workingScheduleCode')
        }, null, 8, ["text-label"]),
        _createVNode(_component_Input, {
          modelValue: _ctx.paramApiPost.administrative_working_schedule_code,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.paramApiPost.administrative_working_schedule_code = $event)),
          validated: _ctx.validated,
          required: "",
          disabled: _ctx.isDisableAction,
          errors: _ctx.errors.administrative_working_schedule_code,
          "onUpdate:errors": _cache[2] || (_cache[2] = ($event: any) => (_ctx.errors.administrative_working_schedule_code = $event)),
          "error-server": _ctx.errorsServer.administrative_working_schedule_code
        }, null, 8, ["modelValue", "validated", "disabled", "errors", "error-server"])
      ])
    ]),
    _createVNode("div", _hoisted_5, [
      _createVNode("div", _hoisted_6, [
        _createVNode(_component_Label, {
          mandatory: "",
          "text-label": _ctx.t('workingScheduleName')
        }, null, 8, ["text-label"]),
        _createVNode(_component_Input, {
          disabled: _ctx.isDisableAction,
          modelValue: _ctx.paramApiPost.administrative_working_schedule_name,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.paramApiPost.administrative_working_schedule_name = $event)),
          validated: _ctx.validated,
          required: "",
          errors: _ctx.errors.administrative_working_schedule_name,
          "onUpdate:errors": _cache[4] || (_cache[4] = ($event: any) => (_ctx.errors.administrative_working_schedule_name = $event)),
          "error-server": _ctx.errorsServer.administrative_working_schedule_name
        }, null, 8, ["disabled", "modelValue", "validated", "errors", "error-server"])
      ])
    ]),
    _createVNode("div", _hoisted_7, [
      _createVNode("div", _hoisted_8, [
        _createVNode(_component_Label, {
          mandatory: "",
          "text-label": _ctx.t('targetYear')
        }, null, 8, ["text-label"]),
        _createVNode(_component_YearInput, {
          isShowChevron: "",
          class: "w-50",
          modelValue: _ctx.paramApiPost.administrative_working_schedule_year_apply,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (_ctx.paramApiPost.administrative_working_schedule_year_apply = $event)),
          validated: _ctx.validated,
          required: "",
          errors: _ctx.errors.administrative_working_schedule_year_apply,
          "onUpdate:errors": _cache[6] || (_cache[6] = ($event: any) => (_ctx.errors.administrative_working_schedule_year_apply = $event)),
          "error-server": 
            _ctx.errorsServer.administrative_working_schedule_year_apply
          
        }, null, 8, ["modelValue", "validated", "errors", "error-server"])
      ])
    ]),
    _createVNode("div", _hoisted_9, [
      _createVNode(_component_Table, {
        class: "table-item-alternating",
        headers: _ctx.scheduleLaborPatternHeaders,
        data: _ctx.scheduleLaborPatternData
      }, {
        month: _withCtx(({ item }) => [
          _createVNode("div", _hoisted_10, _toDisplayString(item.month), 1)
        ]),
        scheduled_working_days: _withCtx(({ index }) => [
          _createVNode("div", _hoisted_11, [
            _createVNode("div", _hoisted_12, [
              _createVNode(_component_Input, {
                classes: "radius-bottom-right-0 radius-top-right-0 text-center",
                class: "w-20",
                modelValue: 
                  _ctx.paramApiPost.administrative_working_schedule_details[index]
                    .working_days_in_month
                ,
                "onUpdate:modelValue": ($event: any) => (
                  _ctx.paramApiPost.administrative_working_schedule_details[index]
                    .working_days_in_month
                 = $event),
                "error-server": 
                  _ctx.errorsServer[
                    `administrative_working_schedule_details.${index}.working_days_in_month`
                  ]
                
              }, null, 8, ["modelValue", "onUpdate:modelValue", "error-server"]),
              _createVNode("span", _hoisted_13, _toDisplayString(_ctx.t('sunday')), 1)
            ])
          ])
        ]),
        scheduled_working_hours: _withCtx(({ index }) => [
          _createVNode("div", _hoisted_14, [
            _createVNode("div", _hoisted_15, [
              _createVNode(_component_Input, {
                classes: "radius-bottom-right-0 radius-top-right-0 text-center",
                class: "w-20",
                modelValue: 
                  _ctx.paramApiPost.administrative_working_schedule_details[index]
                    .working_hours_in_month_by_organization
                ,
                "onUpdate:modelValue": ($event: any) => (
                  _ctx.paramApiPost.administrative_working_schedule_details[index]
                    .working_hours_in_month_by_organization
                 = $event),
                "error-server": 
                  _ctx.errorsServer[
                    `administrative_working_schedule_details.${index}.working_hours_in_month_by_organization`
                  ]
                
              }, null, 8, ["modelValue", "onUpdate:modelValue", "error-server"]),
              _createVNode("span", _hoisted_16, _toDisplayString(_ctx.t('hour')), 1),
              _createVNode(_component_Input, {
                classes: "radius-0 text-center",
                class: "w-20",
                modelValue: 
                  _ctx.paramApiPost.administrative_working_schedule_details[index]
                    .working_minutes_in_month_by_organization
                ,
                "onUpdate:modelValue": ($event: any) => (
                  _ctx.paramApiPost.administrative_working_schedule_details[index]
                    .working_minutes_in_month_by_organization
                 = $event),
                "error-server": 
                  _ctx.errorsServer[
                    `administrative_working_schedule_details.${index}.working_minutes_in_month_by_organization`
                  ]
                
              }, null, 8, ["modelValue", "onUpdate:modelValue", "error-server"]),
              _createVNode("div", _hoisted_17, _toDisplayString(_ctx.t('minute')), 1)
            ])
          ])
        ]),
        legal_working_hours: _withCtx(({ index }) => [
          _createVNode("div", _hoisted_18, [
            _createVNode("div", _hoisted_19, [
              _createVNode(_component_Input, {
                class: "w-20",
                classes: "radius-bottom-right-0 radius-top-right-0 text-center",
                modelValue: 
                  _ctx.paramApiPost.administrative_working_schedule_details[index]
                    .working_hours_in_month_by_statutory
                ,
                "onUpdate:modelValue": ($event: any) => (
                  _ctx.paramApiPost.administrative_working_schedule_details[index]
                    .working_hours_in_month_by_statutory
                 = $event),
                "error-server": 
                  _ctx.errorsServer[
                    `administrative_working_schedule_details.${index}.working_hours_in_month_by_statutory`
                  ]
                
              }, null, 8, ["modelValue", "onUpdate:modelValue", "error-server"]),
              _createVNode("div", _hoisted_20, _toDisplayString(_ctx.t('hour')), 1),
              _createVNode(_component_Input, {
                classes: "radius-0 text-center",
                class: "w-20",
                modelValue: 
                  _ctx.paramApiPost.administrative_working_schedule_details[index]
                    .working_minutes_in_month_by_statutory
                ,
                "onUpdate:modelValue": ($event: any) => (
                  _ctx.paramApiPost.administrative_working_schedule_details[index]
                    .working_minutes_in_month_by_statutory
                 = $event),
                "error-server": 
                  _ctx.errorsServer[
                    `administrative_working_schedule_details.${index}.working_minutes_in_month_by_statutory`
                  ]
                
              }, null, 8, ["modelValue", "onUpdate:modelValue", "error-server"]),
              _createVNode("div", _hoisted_21, _toDisplayString(_ctx.t('minute')), 1)
            ])
          ])
        ]),
        _: 1
      }, 8, ["headers", "data"])
    ]),
    _createVNode("div", _hoisted_22, [
      _createVNode("div", null, [
        _createVNode(_component_Button, {
          class: "btn-register mr-6",
          onClick: _ctx.handleCreateOrUpdateScheduleWorking
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t('toRegister')), 1)
          ]),
          _: 1
        }, 8, ["onClick"])
      ]),
      _createVNode("div", null, [
        _createVNode(_component_Button, {
          class: "btn-back",
          onClick: _ctx.backToListScheduleLaborPattern
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t('backToTheList')), 1)
          ]),
          _: 1
        }, 8, ["onClick"])
      ])
    ])
  ]))
}