
import { defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import router from '@/router';
import {
  deleteWorkingStyleMasterByID,
  useGetListWorkingStyleMaster,
} from '@/api/composables/useWorkingStyleMaster';
import moment from 'moment';
import { showToast } from '@/util/useToastMessage';
import { usePopupDeleteStore } from '@/store/modules/popup-delete';
import ConstantsRouter from '@/constants/router';
import Button from '@/components/share/Button/index.vue';
import Table from '@/components/share/Table/Table.vue';
import BaseIcon from '@/components/Icons/BaseIcon.vue';

export default defineComponent({
  components: {
    BaseIcon,
    Button,
    Table,
  },
  setup() {
    const i18n = useI18n();
    const t = i18n.t;
    const attendanceDataHeaders = [
      {
        text: i18n.t('attendanceDataHeaders.workingStyleCode'),
        value: 'working_style_code',
        width: '15%',
        align: 'center',
      },
      {
        text: i18n.t('attendanceDataHeaders.workingStyleName'),
        value: 'working_style_name',
        width: '50%',
        align: 'center',
      },
      {
        text: i18n.t('attendanceDataHeaders.operationStartDate'),
        value: 'working_style_apply_date',
        width: '20%',
        align: 'center',
      },
      {
        text: i18n.t('attendanceDataHeaders.editAction'),
        value: 'edit',
        width: '60px',
        align: 'center',
      },
      {
        text: i18n.t('attendanceDataHeaders.removeAction'),
        value: 'remove',
        width: '60px',
        align: 'center',
      },
      {
        text: i18n.t('duplicate'),
        value: 'copy',
        width: '60px',
        align: 'center',
      },
    ];
    const displayDate = (date) => {
      return (
        moment(date).format('YYYY') +
        t('year') +
        moment(date).format('MM') +
        t('month')
      );
    };

    const store = usePopupDeleteStore();
    const removeWorkingStyleMaster = (item) => {
      store.dispatch('show', {
        isShown: true,
        title: t('deletionOfWorkStyleMaster'),
        body: `${t('workingStyleMasterName')}「${item.working_style_name}」${t(
          'toRemove'
        )}`,
        onSubmit: () => deleteAction(item.id),
      });
    };
    const deleteAction = async (id) => {
      const { deleteData } = deleteWorkingStyleMasterByID(id);
      deleteData
        .then((error) => {
          if (error) {
            showToast('error', t('deteleWorkingStyleMasterFailure'));
          } else {
            if (
              workingStyleMasterData.value?.length === 1 &&
              apiWorkingStyleMasterListParams.value.page > 1
            ) {
              apiWorkingStyleMasterListParams.value.page -= 1;
            }
            getWorkingStyleMasterList(apiWorkingStyleMasterListParams.value);
            showToast('success', t('deteleWorkingStyleMasterSuccessfully'));
          }
        })
        .catch(() => {
          showToast('error', t('errors.serverError'));
        });
    };

    const apiWorkingStyleMasterListParams = ref({
      keyword: '',
      page: 1,
    });
    const {
      workingStyleMasterData,
      pagination,
      getWorkingStyleMasterList,
    } = useGetListWorkingStyleMaster(apiWorkingStyleMasterListParams.value);
    const onChangePage = () => {
      getWorkingStyleMasterList(apiWorkingStyleMasterListParams.value);
    };

    const copyWorkingStyleMaster = (item) => {
      router.push({
        path: `/new${ConstantsRouter.WorkingStyleMasterCopy}`,
        query: { id: item.id },
      });
    };
    const registerWorkingStyleMaster = () => {
      router.push(`/new${ConstantsRouter.WorkingStyleMasterRegister}`);
    };
    const updateWorkingStyleMaster = (item) => {
      router.push({
        path: `/new${ConstantsRouter.WorkingStyleMasterUpdate}`,
        query: { id: item.id },
      });
    };
    return {
      t,
      apiWorkingStyleMasterListParams,
      workingStyleMasterData,
      getWorkingStyleMasterList,
      pagination,
      attendanceDataHeaders,
      registerWorkingStyleMaster,
      deleteAction,
      displayDate,
      onChangePage,
      removeWorkingStyleMaster,
      updateWorkingStyleMaster,
      copyWorkingStyleMaster,
    };
  },
});
