import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-grey-3 px-5" }
const _hoisted_2 = { class: "title font-size-24 pb-3 mb-10" }
const _hoisted_3 = { class: "flex justify-end m-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Table = _resolveComponent("Table")
  const _component_Button = _resolveComponent("Button")
  const _component_Modal = _resolveComponent("Modal")

  return (_openBlock(), _createBlock(_component_Modal, {
    modelValue: _ctx.isShow,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.isShow = $event)),
    width: "600px",
    noScrollBody: ""
  }, {
    default: _withCtx(() => [
      _createVNode("div", _hoisted_1, [
        _createVNode("div", _hoisted_2, _toDisplayString(_ctx.t('substituteApplication.expirationDateTitle')), 1),
        _createVNode(_component_Table, {
          class: "text-center",
          headers: _ctx.headerTableSubstitute,
          data: _ctx.substituteList
        }, null, 8, ["headers", "data"]),
        _createVNode("div", _hoisted_3, [
          _createVNode(_component_Button, {
            onClick: _ctx.handleCancel,
            class: "h-10 mb-2"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('closeBtn')), 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ])
      ])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}