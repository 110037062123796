
import { computed, defineComponent, PropType, ref, toRefs } from 'vue';
import { useI18n } from 'vue-i18n';
import moment from 'moment';
import { DATE_FORMAT } from '@/constants/date';

export default defineComponent({
  props: {
    date: {
      type: (null as unknown) as PropType<Date | null>,
      required: true,
    },
    monthLabels: {
      type: Array as PropType<string[]>,
      default: () => [
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        '10',
        '11',
        '12',
      ],
    },
    format: {
      type: String,
      default: DATE_FORMAT,
    },
  },
  setup(props, { emit }) {
    const i18n = useI18n();
    const t = i18n.t;
    const { date, format } = toRefs(props);
    const currentMonth = ref(moment().format('YYYY/MM'));
    const selectedYear = computed(() => moment(currentMonth.value).year());
    const selectedMonth = computed(() => moment(currentMonth.value).month());
    const onNextOrPrevMonth = (value) => {
      currentMonth.value = moment(currentMonth.value)
        .add(value, 'months')
        .format('YYYY/MM');
    };
    const datesOfMonth = computed(() => {
      const startOfMonth = new Date(selectedYear.value, selectedMonth.value, 1);
      const endOfMonth = new Date(
        selectedYear.value,
        selectedMonth.value + 1,
        0
      );
      return Array(startOfMonth.getDay())
        .fill(null)
        .concat(
          Array(endOfMonth.getDate())
            .fill(null)
            .map((_, i) => i + 1)
        )
        .concat(Array(6 - endOfMonth.getDay()).fill(null));
    });
    const isSelected = (item) => {
      try {
        if (date.value) {
          return (
            new Date(date.value).getFullYear() === selectedYear.value &&
            new Date(date.value).getMonth() === selectedMonth.value &&
            new Date(date.value).getDate() === item
          );
        }
      } catch (e) {
        return false;
      }
    };
    const dayLabels = [
      t('sunday'),
      t('monday'),
      t('tuesday'),
      t('wednesday'),
      t('thursday'),
      t('friday'),
      t('monday'),
      t('saturday'),
    ];
    const onClickDate = (date) => {
      if (date) {
        emit(
          'update:date',
          moment(
            new Date(selectedYear.value, selectedMonth.value, date)
          ).format(format.value)
        );
        emit('update:isShowCalendar', false);
      }
    };
    return {
      selectedYear,
      selectedMonth,
      datesOfMonth,
      dayLabels,
      currentMonth,
      isSelected,
      onClickDate,
      onNextOrPrevMonth,
    };
  },
});
