
import { computed, defineComponent, onMounted, ref, watchEffect } from 'vue';
import { useI18n } from 'vue-i18n';
import useUnit from '@/api/composables/useUnit';
import ConstantsRouter from '@/constants/router';
import router from '@/router';
import Table from '@/components/share/Table/Table.vue';
import Button from '@/components/share/Button/index.vue';
import InputTime from '@/components/share/Input/InputTime.vue';
import ReasonUpdateStatusApplication from '@/components/Application/ReasonUpdateStatusApplication.vue';
import ReasonCreateApplication from '@/components/Application/ReasonCreateApplication.vue';
import moment from 'moment';
import CheckboxInput from '@/components/share/Checkbox/CheckboxInput.vue';
import Dropdown from '@/components/share/Dropdown/Dropdown.vue';
import DatePickerInput from '@/components/share/Calendar/DatePickerInput.vue';
import { CheckTimeApplicationParams } from '@/model/CheckTimeApplication';
import {
  useDetailCheckTimeApplication,
  useEmployeeChekTimeAndWorkSchedule,
  useGetCheckTimeApplication,
} from '@/api/composables/useCheckTimeApplication';
import { CheckTimeApplicationSearchParams } from '@/api/types/CheckTimeApplicationApi';
import { useLoginAccountStore } from '@/store/modules/login-account';
import { CHECK_TIME_TYPE } from '@/constants/checkTime';
import { showToast } from '@/util/useToastMessage';
import { isValidateForm } from '@/util/useValidateForm';
import { convertToDateTime } from '@/util/composables';
import { onScrollToTopMainContent } from '@/util/useScrollIntoView';
import ApplicationApproveFlowDetail from '@/components/Application/ApplicationApproveFlowDetail.vue';
import { useRoute } from 'vue-router';
import BackPreviousPage from '@/components/share/BackPreviousPage/index.vue';

export default defineComponent({
  components: {
    DatePickerInput,
    Dropdown,
    CheckboxInput,
    ReasonUpdateStatusApplication,
    ReasonCreateApplication,
    InputTime,
    Button,
    Table,
    ApplicationApproveFlowDetail,
    BackPreviousPage,
  },
  setup() {
    const i18n = useI18n();
    const t = i18n.t;
    const route = useRoute();
    const checkTimeTypeList = [
      { value: CHECK_TIME_TYPE.CHECK_IN, label: t('checkIn') },
      { value: CHECK_TIME_TYPE.CHECK_OUT, label: t('checkOut') },
      {
        value: CHECK_TIME_TYPE.BREAK_START,
        label: t('breakStartTimeRounding'),
      },
      { value: CHECK_TIME_TYPE.BREAK_END, label: t('breakEndTimeRounding') },
      { value: CHECK_TIME_TYPE.GO_OUT, label: t('goOut') },
      { value: CHECK_TIME_TYPE.GO_BACK, label: t('goBack') },
      { value: CHECK_TIME_TYPE.TELE_WORK, label: t('teleworkCommute') },
      { value: CHECK_TIME_TYPE.CHECK_IN_OFFICE, label: t('checkInOffice') },
    ];
    const checkTimeApplicationHeaders = [
      { text: '', value: 'type', dataAlign: 'center', width: '80px' },
      {
        text: t('checkIn'),
        value: 'checkin_time',
        dataAlign: 'center',
        width: '120px',
      },
      {
        text: t('checkOut'),
        value: 'checkout_time',
        dataAlign: 'center',
        width: '120px',
      },
      {
        text: t('break'),
        value: 'break_time',
        dataAlign: 'center',
        width: '120px',
      },
      {
        text: t('breakTime'),
        value: 'total_break_minutes',
        dataAlign: 'center',
        width: '120px',
      },
      {
        text: t('goOut'),
        value: 'go_out',
        dataAlign: 'center',
        width: '120px',
      },
      {
        text: t('overtimeWorkingHours'),
        value: 'prescribed_outside_of_working_time',
        dataAlign: 'center',
        width: '120px',
      },
      {
        text: t('nonStatutoryWorkingHours'),
        value: 'statutory_outside_of_working_time',
        dataAlign: 'center',
        width: '120px',
      },
      {
        text: t('longNightWorkingHours'),
        value: 'midnight_working_time',
        dataAlign: 'center',
        width: '120px',
      },
      {
        text: t('attendanceOrganization'),
        value: 'unit_name',
        dataAlign: 'center',
        width: '120px',
      },
      {
        text: t('attendancePlace'),
        value: 'is_remote',
        dataAlign: 'center',
        width: '120px',
      },
    ];
    const checkTimeApplicationData = [
      {
        type: t('scheduledToWork'),
      },
      { type: t('workRecord') },
    ];
    const isMyApplication = computed(() => route.query.is_my_application || '');

    const redirectWhenCallAPI = () => {
      if (parseInt(isMyApplication.value?.toString()))
        router.push(`/new${ConstantsRouter.ApplicationApprovalRequest}`);
      else router.push(`/new${ConstantsRouter.ApplicationMyApproval}`);
    };

    const stampRecordHeaders = [
      { text: t('stampType'), value: 'check_time_type', dataAlign: 'center' },
      { text: t('time3'), value: 'time', dataAlign: 'center' },
      {
        text: t('attendanceOrganization'),
        value: 'unit_name',
        dataAlign: 'center',
      },
    ];

    const fixedWorkResultsHeaders = [
      { text: t('stampType'), value: 'check_time_type', dataAlign: 'center' },
      { text: t('time3'), value: 'time', dataAlign: 'center' },
      {
        text: t('attendanceOrganization'),
        value: 'unit_name',
        dataAlign: 'center',
      },
      { text: t('delete'), value: 'delete', dataAlign: 'center' },
    ];

    const backToTimeCard = () => {
      router.push(`/new${ConstantsRouter.TimeCard}`);
    };
    const goToBack = () => {
      if (applicationFormId) {
        redirectWhenCallAPI();
      } else {
        backToTimeCard();
      }
    };
    const targetDate = router.currentRoute.value.query.target_date as string;
    const applicationDefineId = router.currentRoute.value.query.id as string;
    const applicationFormId = router.currentRoute.value.query
      .application_form_id as string;
    const displayTargetDate = (date) => {
      if (date && moment(date.toString()).isValid()) {
        return moment(date).format(
          `YYYY${t('year')}MM${t('month')}DD${t('day')}（dd）`
        );
      }
      return '';
    };
    const formData = ref<CheckTimeApplicationParams>({
      time_keepings: [],
      check_time_types: [],
      application_reason: '',
      total_break_time: '',
    });
    const removeCheckTime = (index) => {
      formData.value.check_time_types.splice(index, 1);
    };
    const addCheckTime = () => {
      formData.value.check_time_types.push({});
    };
    const accountStore = useLoginAccountStore();
    const myAccount = computed(() => accountStore.state.myAccount);
    const { unitList } = useUnit({});
    const {
      checkTimeApplication,
      getEmployeeChekTimeAndWorkScheduleData,
      employeeCheckTimeAndWorkSchedule,
    } = useEmployeeChekTimeAndWorkSchedule();
    const checkTimeApplicationSearchParams = ref<CheckTimeApplicationSearchParams>(
      {
        target_date: targetDate,
        pp_employee_id: myAccount.value.pp_employee_id,
      }
    );
    const {
      checkTimeApplicationDetail,
      getCheckTimeApplicationDetailData,
    } = useDetailCheckTimeApplication();
    const getCheckTimeDetailAndEmployeeCheckTime = async () => {
      await getEmployeeChekTimeAndWorkScheduleData(
        checkTimeApplicationSearchParams.value
      );
      if (applicationFormId) {
        await getCheckTimeApplicationDetailData(applicationFormId);
      }
    };
    getCheckTimeDetailAndEmployeeCheckTime();

    const validated = ref(false);
    const errors = ref({});
    const errorServer = ref({});
    const isValid = () => {
      validated.value = true;
      return isValidateForm(errors);
    };
    const submitPostCheckTimeApplication = async () => {
      if (!isValid()) return;

      const { registered } = useGetCheckTimeApplication({
        ...formData.value,
        ...{
          target_date: targetDate,
          unit_id: myAccount.value.unit_id,
          pp_employee_id: myAccount.value.pp_employee_id,
          application_define_id: applicationDefineId,
          time_keepings: employeeCheckTimeAndWorkSchedule.value.actual_check_time.map(
            (item) => {
              return {
                time_keeping_id: item.time_keeping_id,
                is_delete: !!item.is_delete,
              };
            }
          ),
        },
      });
      await registered
        .then((error) => {
          if (error) {
            errorServer.value = error.errors.value;
            if (error.errorsCommon.value) {
              showToast('error', error.errorsCommon.value);
            } else {
              showToast('warning', t('invalidInput'));
            }
          } else {
            showToast('success', i18n.t('theApplicationHasBeenCreated'));
            backToTimeCard();
          }
        })
        .catch(() => {
          showToast('error', i18n.t('errors.serverError'));
        });
    };

    const displayScheduleBreakTime = (item, index) => {
      if (!item) return '';
      if (index === 0) {
        return item.work_schedule_break_time_range
          ?.map((e) => `${e.break_start_time} ~ ${e.break_end_time}`)
          .join('\n');
      }
      if (item.break_start_time && item.break_end_time) {
        return `${item.break_start_time} ~ ${item.break_end_time}`;
      }
    };
    onMounted(() => {
      onScrollToTopMainContent();
    });
    const displayFormatTime = (time, format) => {
      if (!time) return;
      return moment(time).format(format);
    };

    const totalTime = computed(() => {
      return checkTimeApplicationDetail.value.total_break_time;
    });
    const isDetail = applicationFormId;

    const totalBreakMinutes = computed(() => {
      return (
        employeeCheckTimeAndWorkSchedule.value?.after_processing_check_time
          ?.employee_check_time?.total_break_minutes || 0
      );
    });
    const convertNumberToDateTime = (number) => {
      if (!number) {
        return '00:00';
      }
      return convertToDateTime(number, '');
    };
    watchEffect(() => {
      if (
        employeeCheckTimeAndWorkSchedule.value?.after_processing_check_time
          ?.employee_check_time
      ) {
        formData.value.total_break_time = convertNumberToDateTime(
          totalBreakMinutes.value
        );
      }
    });
    const isDisableSubmit = computed(() =>
      Object.values(errors.value).some((e) => !!e)
    );

    const isDistinguishingWorkplace = computed(
      () => router.currentRoute.value.query.is_distinguishing_workplace
    );
    const getCheckTimeTypeName = (item) => {
      if (Number(isDistinguishingWorkplace.value) === 0) {
        return checkTimeTypeList[item.check_time_type].label;
      }

      if (item.check_time_type === CHECK_TIME_TYPE.CHECK_IN && item.is_remote) {
        return t('teleworkCommute');
      }
      if (item.check_time_type === CHECK_TIME_TYPE.CHECK_IN) {
        return t('checkInOffice');
      }
      return checkTimeTypeList[item.check_time_type].label;
    };

    return {
      t,
      checkTimeApplicationHeaders,
      checkTimeApplicationData,
      targetDate,
      stampRecordHeaders,
      fixedWorkResultsHeaders,
      applicationFormId,
      formData,
      unitList,
      employeeCheckTimeAndWorkSchedule,
      checkTimeTypeList,
      checkTimeApplication,
      validated,
      errors,
      errorServer,
      checkTimeApplicationDetail,
      CHECK_TIME_TYPE,
      isMyApplication,
      totalTime,
      isDetail,
      totalBreakMinutes,
      isValid,
      addCheckTime,
      removeCheckTime,
      backToTimeCard,
      displayTargetDate,
      submitPostCheckTimeApplication,
      convertToDateTime,
      displayScheduleBreakTime,
      displayFormatTime,
      goToBack,
      isDisableSubmit,
      getCheckTimeTypeName,
    };
  },
});
