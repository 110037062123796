import api, { ApiResponse } from '@/clients/api';
import { ErrorCode } from './types/general/ErrorCode';
import {
  JSONApiResponseBody,
  JSONApiResponseErrorBody,
} from './types/general/JSONApiResponse';
import ConstantsAPI from '@/constants/api';
import {
  ParamsIcCardApiGetData,
  RegisterIcCardApiPostData,
  RegisterIcCardApiPostDataResult,
  ResultIcCardApiGetData,
} from './types/RegisterIcCard';

export async function postRegisterIcCard(
  postData: RegisterIcCardApiPostData
): Promise<
  | ApiResponse<200, JSONApiResponseBody<RegisterIcCardApiPostDataResult>>
  | ApiResponse<400, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.post(ConstantsAPI.RegisterIcCardPost, postData);
}

export async function deleteIcCard(
  id: string
): Promise<
  | ApiResponse<200, JSONApiResponseBody<RegisterIcCardApiPostDataResult>>
  | ApiResponse<400, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.delete(ConstantsAPI.IcCardDelete(id));
}

export async function getDataIcCard(
  params: ParamsIcCardApiGetData
): Promise<
  | ApiResponse<200, JSONApiResponseBody<ResultIcCardApiGetData>>
  | ApiResponse<400, JSONApiResponseErrorBody<ErrorCode>>
  | ApiResponse<500, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.get(ConstantsAPI.IcCardData, { params });
}
