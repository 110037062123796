<template>
  <svg
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 20.5C18.2091 20.5 20 18.7091 20 16.5C20 14.2909 18.2091 12.5 16 12.5C13.7909 12.5 12 14.2909 12 16.5C12 18.7091 13.7909 20.5 16 20.5Z"
      stroke="#00D3D1"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.63532 22.8646C8.79868 22.0294 8.13493 21.0374 7.68206 19.9454C7.2292 18.8534 6.99609 17.6829 6.99609 16.5007C6.99609 15.3185 7.2292 14.1479 7.68206 13.0559C8.13493 11.9639 8.79868 10.9719 9.63532 10.1367"
      stroke="#00D3D1"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M22.3633 10.1367C23.1999 10.9719 23.8637 11.9639 24.3165 13.0559C24.7694 14.1479 25.0025 15.3185 25.0025 16.5007C25.0025 17.6829 24.7694 18.8534 24.3165 19.9454C23.8637 21.0374 23.1999 22.0294 22.3633 22.8646"
      stroke="#00D3D1"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6.10155 26.3986C4.80011 25.0994 3.76762 23.5563 3.06316 21.8576C2.3587 20.159 1.99609 18.3381 1.99609 16.4991C1.99609 14.6602 2.3587 12.8393 3.06316 11.1406C3.76762 9.44192 4.80011 7.89883 6.10155 6.59961"
      stroke="#00D3D1"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M25.8984 6.59961C27.1999 7.89883 28.2324 9.44192 28.9368 11.1406C29.6413 12.8393 30.0039 14.6602 30.0039 16.4991C30.0039 18.3381 29.6413 20.159 28.9368 21.8576C28.2324 23.5563 27.1999 25.0994 25.8984 26.3986"
      stroke="#00D3D1"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
