import api, { ApiResponse } from '@/clients/api';
import { ErrorCode } from './types/general/ErrorCode';
import {
  JSONApiResponseBody,
  JSONApiResponseErrorBody,
} from './types/general/JSONApiResponse';
import ConstantsAPI from '@/constants/api';
import {
  CheckTimeApplicationApiGetResult,
  CheckTimeApplicationApiPostParams,
  CheckTimeApplicationApiPostResult,
  CheckTimeApplicationSearchParams,
  EmployeeCheckTimeAndWorkScheduleApiGetResult,
} from '@/api/types/CheckTimeApplicationApi';

export async function getEmployeeChekTimeAndWorkSchedule(
  params: CheckTimeApplicationSearchParams
): Promise<
  | ApiResponse<
      200,
      JSONApiResponseBody<EmployeeCheckTimeAndWorkScheduleApiGetResult>
    >
  | ApiResponse<404, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.get(ConstantsAPI.EmployeeChekTimeAndWorkSchedule, {
    params,
  });
}

export async function getCheckTimeApplication(
  postData: CheckTimeApplicationApiPostParams
): Promise<
  | ApiResponse<200, JSONApiResponseBody<CheckTimeApplicationApiPostResult>>
  | ApiResponse<400, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.post(ConstantsAPI.CheckTimeApplication, postData);
}

export async function getCheckTimeApplicationDetail(
  id: string
): Promise<
  | ApiResponse<200, JSONApiResponseBody<CheckTimeApplicationApiGetResult>>
  | ApiResponse<400, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.get(ConstantsAPI.CheckTimeApplicationDetail(id));
}
