
import { defineComponent, ref, toRefs, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import Table from '@/components/share/Table/Table.vue';
import Select from '@/components/share/Dropdown/Dropdown.vue';
import { WorkingStyleMasterParams } from '@/model/WorkingStyleMaster';
import { ShiftPatternsParamSearch } from '@/model/ShiftPattern';
import { useGetShiftPatternList } from '@/api/composables/useShiftPattern';
import {
  SETTING_WORKING_DAY_TYPE,
  WAY_OF_WORKING,
} from '@/constants/workingStyleMaster';

export default defineComponent({
  components: { Select, Table },
  props: {
    modelValue: {
      default: {},
    },
    validated: {
      default: false,
    },
    errors: {
      default: '',
    },
  },
  setup(props) {
    const i18n = useI18n();
    const { modelValue } = toRefs(props);
    const workingStyleData = [
      { day_of_week: i18n.t('daysOfWeek.mon') },
      { day_of_week: i18n.t('daysOfWeek.tue') },
      { day_of_week: i18n.t('daysOfWeek.wed') },
      { day_of_week: i18n.t('daysOfWeek.thu') },
      { day_of_week: i18n.t('daysOfWeek.fri') },
      { day_of_week: i18n.t('daysOfWeek.sat') },
      { day_of_week: i18n.t('daysOfWeek.sun') },
      { day_of_week: i18n.t('nationalHoliday') },
    ];
    const workingStyleHeaders = [
      {
        text: i18n.t('workingStyleHeaders.dayOfWeek'),
        value: 'day_of_week',
        width: '12%',
      },
      {
        text: i18n.t('workingStyleHeaders.workingType'),
        value: 'setting_working_day',
        width: '20%',
      },
      {
        text: i18n.t('workPatternMaster'),
        value: 'work_patter',
        width: '20%',
      },
      {
        text: i18n.t('workingHour'),
        value: 'working_hours',
      },
      {
        text: i18n.t('workingStyleHeaders.break'),
        value: 'break',
      },
    ];
    const workTypeList = [
      { key: SETTING_WORKING_DAY_TYPE.WEEKDAYS, value: i18n.t('weekdays') },
      {
        key: SETTING_WORKING_DAY_TYPE.LEGAL_HOLIDAYS,
        value: i18n.t('legalHoliday'),
      },
      {
        key: SETTING_WORKING_DAY_TYPE.SCHEDULED_HOLIDAYS,
        value: i18n.t('scheduledHoliday'),
      },
    ];

    const displayShiftPatternBreak = (id) => {
      const shiftPattern =
        shiftPatternData.value.find((item) => item.shift_pattern_id === id) ||
        {};
      if (shiftPattern.shift_pattern_times) {
        return shiftPattern.shift_pattern_times
          .filter((item) => item.is_break)
          .map((item) => {
            return item.start_time + ' ~ ' + item.end_time;
          })
          .join('／');
      }
      return '';
    };

    const displayShiftPatternWorkingHour = (id) => {
      const shiftPattern =
        shiftPatternData.value.find((item) => item.shift_pattern_id === id) ||
        {};
      if (shiftPattern.shift_pattern_times) {
        return shiftPattern.shift_pattern_times
          .filter((item) => !item.is_break)
          .map((item) => {
            return item.start_time + ' ~ ' + item.end_time;
          })
          .join('／');
      }
      return '';
    };

    const errorsData = ref({});
    const workingStyleMasterParams = ref<WorkingStyleMasterParams>({});
    workingStyleMasterParams.value = modelValue.value;
    watch(props, () => {
      workingStyleMasterParams.value = modelValue.value;
      errorsData.value = props.errors;
    });
    const paramsSearchShiftPattern = ref<ShiftPatternsParamSearch>({
      unit_id: '',
    });
    const { shiftPatternData, getShiftPatternList } = useGetShiftPatternList();
    getShiftPatternList(paramsSearchShiftPattern.value);
    return {
      t: i18n.t,
      workTypeList,
      workingStyleData,
      workingStyleHeaders,
      errorsData,
      workingStyleMasterParams,
      shiftPatternData,
      WAY_OF_WORKING,
      SETTING_WORKING_DAY_TYPE,
      getShiftPatternList,
      displayShiftPatternBreak,
      displayShiftPatternWorkingHour,
    };
  },
});
