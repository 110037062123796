import api, { ApiResponse } from '@/clients/api';
import { ErrorCode } from './types/general/ErrorCode';
import {
  JSONApiResponseBody,
  JSONApiResponseErrorBody,
} from './types/general/JSONApiResponse';
import ConstantsAPI from '@/constants/api';

export async function getStreetAddressFrom(
  latitude: number,
  comma: string,
  longitude: number,
  key: string
): Promise<
  | ApiResponse<200, JSONApiResponseBody<never[]>>
  | ApiResponse<400, JSONApiResponseErrorBody<ErrorCode>>
  | ApiResponse<500, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.get(
    ConstantsAPI.StreetAddressFrom +
      `${latitude}` +
      `${comma}` +
      `${longitude}` +
      `${key}`
  );
}
