
import {} from 'vue';

export default {
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const handleToggleSwitch = () => {
      emit('update:modelValue', !props.modelValue);
      emit('change', !props.modelValue);
    };
    return {
      handleToggleSwitch,
    };
  },
};
