import { ref } from 'vue';
import { getStreetAddressFrom } from '@/api/stresstAddressFrom';
import { KEY_MAP } from '@/constants/keyGoogleMap';
export default function () {
  const address = ref();
  const loading = ref(false);
  async function useGetListStreetAddressFrom() {
    const key = KEY_MAP;
    try {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          position.coords.latitude, position.coords.longitude;
          const res = await getStreetAddressFrom(
            position.coords.latitude,
            ',',
            position.coords.longitude,
            key
          );
          switch (res.status) {
            case 200:
              console.log('address', res.data);
              address.value = res.data;
              break;
            default:
              address.value = [];
              break;
          }
        },
        (error) => {
          console.log(error.message);
        }
      );
    } catch (error) {
      console.error(error);
    } finally {
      loading.value = false;
    }
  }
  useGetListStreetAddressFrom();
  return {
    address,
    loading,
    useGetListStreetAddressFrom,
  };
}
