import api, { ApiResponse } from '@/clients/api';
import { ErrorCode } from './types/general/ErrorCode';
import {
  JSONApiResponseBody,
  JSONApiResponseErrorBody,
} from './types/general/JSONApiResponse';
import {
  TimezoneGroupSettingData,
  TimezoneSettingsApiDeleteResult,
  TimezoneSettingsApiPostResult,
} from '@/api/types/TimezoneSettings';

import ConstantsAPI from '@/constants/api';
import {
  TimeZoneGroupParamGetList,
  TimeZoneGroupPostData,
} from '@/model/TimeZoneGroup';
export async function postTimezoneGroup(
  postData: TimeZoneGroupPostData
): Promise<
  | ApiResponse<200, JSONApiResponseBody<TimezoneSettingsApiPostResult>>
  | ApiResponse<400, JSONApiResponseErrorBody<ErrorCode>>
  | ApiResponse<500, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.post(ConstantsAPI.TimeZoneGroupCreate, postData);
}

export async function updateTimezoneGroup(
  id: string,
  postData: TimeZoneGroupPostData
): Promise<
  | ApiResponse<200, JSONApiResponseBody<TimezoneSettingsApiPostResult>>
  | ApiResponse<400, JSONApiResponseErrorBody<ErrorCode>>
  | ApiResponse<500, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.put(ConstantsAPI.TimeZoneGroupUpdate(id), postData);
}

export async function getListTimezoneGroup(
  params
): Promise<
  | ApiResponse<200, JSONApiResponseBody<TimeZoneGroupParamGetList>>
  | ApiResponse<400, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.get(ConstantsAPI.TimezoneGroupList, { params: params });
}

export async function getTimezoneGroupDetail(
  id: string
): Promise<
  | ApiResponse<200, JSONApiResponseBody<TimezoneGroupSettingData>>
  | ApiResponse<400, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.get(ConstantsAPI.TimeZoneGroupDetail(id));
}

export async function deleteTimezoneGroup(
  id: string
): Promise<
  | ApiResponse<200, JSONApiResponseBody<TimezoneSettingsApiDeleteResult>>
  | ApiResponse<400, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.delete(ConstantsAPI.TimeZoneGroupDelete(id));
}
