
import { computed, defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import Table from '@/components/Table.vue';
import Input from '@/components/Input.vue';
import iconSearch from '@/assets/svg/iconSearch.svg';
import { useStore } from '@/store';
import Plus from '@/assets/svg/Plus.svg';
import router from '@/router';
import backNavigation from '@/assets/svg/nav/backNavigation.svg';
import useUnitApi from '@/api/composables/useUnit';
export default defineComponent({
  components: { Input, Table },
  setup() {
    const i18n = useI18n();
    const store = useStore();
    const currentPageTextHeader = computed(() => store.state.root.currentPage);
    const backRouterPath = computed(() => store.state.root.backRouterPath);
    const OrganizationalInformationOptionHeader = [
      {
        text: i18n.t('organizationalInformation.No'),
        value: 'unit_id',
        width: '4%',
      },
      {
        text: i18n.t('organizationalInformation.departmentCode'),
        value: 'unit_code',
        width: '32%',
      },
      {
        text: i18n.t('organizationalInformation.departmentName'),
        value: 'unit_name',
        width: '32%',
      },
      {
        text: i18n.t('organizationalInformation.dateChangeTime'),
        value: 'unit_update',
        width: '32%',
      },
    ];
    const currentPage = ref(1);
    const isAllParams = ref(false);
    const OrganizationalInformationSelected = ref([]);
    const { pagination, loading, unitList, getUnitData } = useUnitApi({
      is_all: isAllParams.value,
      page: currentPage.value,
    });
    const onChangePage = () => {
      getUnitData({
        is_all: isAllParams.value,
        page: currentPage.value,
      });
    };
    const convertNumberPage = (index) => {
      return (currentPage.value - 1) * pagination.value.per_page + 1 + index;
    };
    return {
      t: i18n.t,
      router,
      OrganizationalInformationOptionHeader,
      iconSearch,
      OrganizationalInformationSelected,
      Plus,
      currentPageTextHeader,
      currentPage,
      backRouterPath,
      backNavigation,
      unitList,
      pagination,
      loading,
      convertNumberPage,
      useUnitApi,
      getUnitData,
      onChangePage,
    };
  },
  methods: {
    backRoute() {
      this.router.push(this.backRouterPath);
    },
  },
});
