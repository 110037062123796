import { CheckinTimeKeepingCurrentStatus } from '@/api/types/TimeKeepingCurrentStatus';
import { ref } from 'vue';
import { getCurrentStatusTimeKeeping } from '@/api/timeKeepingCurrentStatus';

export default function (targetDate?: string) {
  const currentTimeKeeping = ref<CheckinTimeKeepingCurrentStatus>({});
  const loading = ref(false);

  async function getListStatusTimeKeepingCurrent(targetDate?: string) {
    try {
      loading.value = true;
      const res = await getCurrentStatusTimeKeeping(targetDate);

      switch (res.status) {
        case 200:
          currentTimeKeeping.value = res.data.data;
          break;
        default:
          currentTimeKeeping.value = {};
          break;
      }
    } catch (error) {
      console.error(error);
    } finally {
      loading.value = false;
    }
  }

  getListStatusTimeKeepingCurrent(targetDate);

  return {
    currentTimeKeeping,
    loading,
    getCurrentStatusTimeKeeping,
    getListStatusTimeKeepingCurrent,
  };
}
