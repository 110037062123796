<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.5 11.25L3 15.75L7.5 20.25"
      :stroke="color"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.5 5.25H15.75C17.1424 5.25 18.4777 5.80312 19.4623 6.78769C20.4469 7.77226 21 9.10761 21 10.5V10.5C21 11.1894 20.8642 11.8721 20.6004 12.5091C20.3365 13.1461 19.9498 13.7248 19.4623 14.2123C18.9748 14.6998 18.3961 15.0865 17.7591 15.3504C17.1221 15.6142 16.4394 15.75 15.75 15.75H3"
      :stroke="color"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
<script>
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    color: {
      type: String,
      default: '#ED5D5D',
    },
  },
});
</script>
