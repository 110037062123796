/**
 * , 0 => '標準勤務'
 * , 1 => '標準勤務シフト'
 * , 2 => '変形労働'
 * , 3 => '裁量労働'
 * , 4 => 'アルバイト'
 */
export const DEFINE_TYPE_WORKING_STYLE = {
  STANDARD_WORK: '01FVGQCZKBK75F2XHA0FPXGG88',
  STANDARD_WORK_SHIFT: '01FVF08J4J1JZDA094G1ZCNPZV',
  TRANSFORMATION_LABOR: '01FVF086CY0HP9EAMYAY3QV0AP',
  DISCRETIONARY_LABOR: '01FVF0745R66ZGQ3EVSJW58FW6',
  PART_TIME_JOB: '01FVEZYZ4257K3D71C7C8MEJ8H',
};

/**
 * true => to approve
 * false => not allowed
 */

export const DEFINE_TYPE_APPROVE = {
  TO_APPROVE: true,
  NOT_ALLOWED: false,
};
