import api, { ApiResponse } from '@/clients/api';
import { ErrorCode } from './types/general/ErrorCode';
import {
  JSONApiResponseBody,
  JSONApiResponseErrorBody,
} from './types/general/JSONApiResponse';
import ConstantsAPI from '@/constants/api';
import {
  WorkingScheduleChangeApplicationApiPostData,
  WorkingScheduleChangeApplicationGetResult,
  WorkingScheduleChangeApplicationPostResult,
} from '@/api/types/WorkingScheduleChangeApplication';

export async function postWorkingScheduleChangeApplication(
  postData: WorkingScheduleChangeApplicationApiPostData
): Promise<
  | ApiResponse<
      200,
      JSONApiResponseBody<WorkingScheduleChangeApplicationPostResult>
    >
  | ApiResponse<400, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.post(
    ConstantsAPI.WorkingScheduleChangeApplication,
    postData
  );
}
export async function getWorkingScheduleChangeApplicationDetail(
  id: string
): Promise<
  | ApiResponse<
      200,
      JSONApiResponseBody<WorkingScheduleChangeApplicationGetResult>
    >
  | ApiResponse<400, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.get(ConstantsAPI.WorkingScheduleChangeApplicationDetail(id));
}
