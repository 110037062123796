
import {
  defineComponent,
  onBeforeMount,
  onBeforeUnmount,
  reactive,
  ref,
} from 'vue';
import { useI18n } from 'vue-i18n';
import Plus from '@/assets/svg/Plus.svg';
import { useStore } from '@/store';
import Input from '@/components/Input.vue';
import RadioGroup from '@/components/RadioGroup.vue';
import Select from '@/components/Select.vue';
import Label from '@/components/Label.vue';

export default defineComponent({
  components: {
    Input,
    Label,
    RadioGroup,
    Select,
  },
  setup() {
    const i18n = useI18n();
    const t = i18n.t;
    const store = useStore();
    const paramsWorkingScheduleRegister = ref([{}]);
    const boardName = ref('');

    onBeforeMount(() => {
      store.commit('root/SET_BACK_ROUTER_PATH', '/time-card');
      store.commit('root/SET_CURRENT_PAGE', i18n.t(''));
    });
    onBeforeUnmount(() => {
      store.commit('root/SET_BACK_ROUTER_PATH', '');
      store.commit('root/SET_CURRENT_PAGE', '');
    });
    let workingScheduleRegisterParams = ref({});
    const optionReasonApplication = reactive([
      { text: i18n.t('dailyAttendanceHeaders.apply'), value: '' },
      { text: i18n.t('dailyAttendanceHeaders.date'), value: '' },
      { text: i18n.t('dailyAttendanceHeaders.finalConfirmation'), value: '' },
      { text: i18n.t('dailyAttendanceHeaders.finalConfirmation'), value: '' },
      {
        text: i18n.t('dailyAttendanceHeaders.confirmationOfApplicant'),
        value: '',
      },
      {
        text: i18n.t('dailyAttendanceHeaders.confirmationOfApplicant'),
        value: '',
      },
    ]);
    const workScheduleChangeOption = [
      {
        value: 0,
        label: i18n.t('workingScheduleRegister.selectFromShiftPatterns'),
      },
      { value: 1, label: i18n.t('workingScheduleRegister.enterDirectly') },
    ];
    const addWorkingScheduleRegister = () => {
      paramsWorkingScheduleRegister.value.push({
        //Todo => Push value working schedule register
      });
    };
    return {
      optionReasonApplication,
      t,
      Plus,
      workScheduleChangeOption,
      workingScheduleRegisterParams,
      paramsWorkingScheduleRegister,
      boardName,
      addWorkingScheduleRegister,
    };
  },
});
