import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "flex flex-wrap pt-3 p-8 pb-0 working-style-master-container" }
const _hoisted_2 = { class: "flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row ml-8" }
const _hoisted_3 = { class: "w-full sm:mr-20 md:mr-40 lg:mr-56 sm:ml-20 md:ml-40 lg:ml-56" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")
  const _component_router_view = _resolveComponent("router-view")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("ul", _hoisted_2, [
      (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.dataTabs, (item, index) => {
        return (_openBlock(), _createBlock(_component_router_link, {
          class: ["text-grey-999 text-center cursor-pointer px-5 py-3 rounded block tab-link", _ctx.currentRouter.path.includes(item.name) ? 'active-page' : ''],
          key: index,
          to: item.path
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(item.text), 1)
          ]),
          _: 2
        }, 1032, ["to", "class"]))
      }), 128))
    ]),
    _createVNode("div", _hoisted_3, [
      _createVNode(_component_router_view)
    ])
  ]))
}