
import { defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import DatePicker from '@/components/DatePicker.vue';
import Select from '@/components/Select.vue';
import Checkbox from '@/components/Checkbox.vue';
import Table from '@/components/Table.vue';
import Button from '@/components/Button.vue';

export default defineComponent({
  components: { Button, Table, DatePicker, Select, Checkbox },
  setup() {
    const i18n = useI18n();
    const organizationOption = [
      { text: i18n.t('allAffiliatedOrganizations'), value: 0 },
    ];
    const directorOption = [{ text: i18n.t('allJobTitles'), value: 0 }];
    const workingStyleOption = [{ text: i18n.t('allWorkingStyle'), value: 0 }];
    const applicationHistoryData = [
      {
        applicationDate: '2021/00/0017:00',
        applicationType2: '休暇申請有給',
        targetDate: '2021/04/01',
        status: '一次承認待ち',
        acknowledgementTimeOfDay: '2021/00/0017:00',
        applicationMessage: '私用のため',
      },
      {
        applicationDate: '2021/00/0017:00',
        applicationType2: '休暇申請有給',
        targetDate: '2021/04/01',
        status: '2次承認待ち',
        acknowledgementTimeOfDay: '2021/00/0017:00',
        applicationMessage: '私用のため',
      },
    ];
    const applicationHistoryHeaders = [
      {
        text: i18n.t('applicationDate'),
        value: 'applicationDate',
      },
      {
        text: i18n.t('applicationType2'),
        value: 'applicationType2',
      },
      {
        text: i18n.t('targetDate'),
        value: 'targetDate',
      },
      {
        text: i18n.t('status'),
        value: 'status',
        width: '200px',
      },
      {
        text: i18n.t('acknowledgementTimeOfDay'),
        value: 'acknowledgementTimeOfDay',
      },
      {
        text: i18n.t('affiliationOrganization'),
        value: 'organization',
      },
      {
        text: i18n.t('staffCode'),
        value: 'staffCode',
      },
      {
        text: i18n.t('fullName'),
        value: 'fullName',
        align: 'left',
      },
      {
        text: i18n.t('applicationMessage'),
        value: 'applicationMessage',
        align: 'left',
      },
    ];
    const DateTypeOption = [
      { value: 0, label: i18n.t('late') },
      { value: 1, label: i18n.t('early') },
    ];
    const searchParams = ref({
      applicationTargetDate: 0,
      applicationDate: 0,
    });
    return {
      t: i18n.t,
      organizationOption,
      directorOption,
      workingStyleOption,
      applicationHistoryData,
      applicationHistoryHeaders,
      DateTypeOption,
      searchParams,
    };
  },
});
