import type { AvatarUserState, userInfo } from '@/types';

export enum TypeErrors {}

export type State = {
  listUsers: userInfo[];
  listUsersSelected: userInfo[];
  avatarUsers: AvatarUserState;
};

export const state: State = {
  listUsers: [],
  listUsersSelected: [],
  avatarUsers: {},
};
