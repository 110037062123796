
import { computed, defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import AvatarSample from '@/assets/svg/AvatarSample.svg';
import Table from '@/components/Table.vue';
import Input from '@/components/Input.vue';
import iconSearch from '@/assets/svg/iconSearch.svg';
import Button from '@/components/Button.vue';
import InputTime from '@/components/InputTime.vue';
import Checkbox from '@/components/Checkbox.vue';
import {
  useChangeConfirmationStatusShift,
  useChangeNotificationStatusShift,
  useGetRequirementPersonnelRegisterShiftDetailData,
  usePostManagerRegisterShiftForPersonnel,
} from '@/api/composables/useShiftPlan';
import useGetPlanDetail from '@/api/composables/useShiftPlanDetail';
import { watchEffect } from '@vue/runtime-core';
import moment from 'moment';
import { ManagerRegisterShiftForPersonnelParams } from '@/model/ShiftPlan';
import { showToast } from '@/util/useToastMessage';
import { useGetShiftPatternList } from '@/api/composables/useShiftPattern';
import { ShiftPatternsApiParamSearch } from '@/api/types/ShiftPatern';
import { useLoginAccountStore } from '@/store/modules/login-account';
import ShiftTimeManagerCreatePopup from '@/views/ShiftTimeManagerCreatePopup.vue';
import {
  ChangeConfirmationStatusShiftParam,
  ChangeNotificationStatusShiftParam,
  ShiftPlanEmployeeDay,
} from '@/api/types/ShiftPlan';
import {
  EMPLOYEE_EXPERT_TYPE,
  MANAGER_CONFIRM_TYPE,
  SHIFT_CONFIRMATION_STATUS,
  SHIFT_NOTIFICATION_STATUS,
} from '@/constants/shiftPlan';
import router from '@/router';
import ConstantsRouter from '@/constants/router';
import useUnitApi from '@/api/composables/useUnit';

export default defineComponent({
  components: {
    ShiftTimeManagerCreatePopup,
    Checkbox,
    InputTime,
    Button,
    Table,
    Input,
  },
  setup() {
    const i18n = useI18n();
    const membersToSubmittedHeadersDefault = ref([
      {
        text: '',
        value: 'checkbox',
        width: '50px',
      },
      {
        text: i18n.t('name'),
        value: 'name',
        width: '200px',
      },
      {
        text: i18n.t('sure'),
        value: 'name_confirmed',
        width: '140px',
      },
      {
        text: i18n.t('notice'),
        value: 'notice',
        width: '140px',
      },
      {
        text: i18n.t('kinds'),
        value: 'kinds',
      },
    ]);
    const membersToSubmittedHeaders = ref();
    const employeeExpertOptions = [
      {
        text: i18n.t('unSelect'),
        value: null,
      },
      {
        text: i18n.t('selectFromWorkPatterns'),
        value: EMPLOYEE_EXPERT_TYPE.SELECT_FROM_WORK_PATTERN,
      },
      {
        text: i18n.t('addManual'),
        value: EMPLOYEE_EXPERT_TYPE.ADD_MANUALLY,
      },
      {
        text: i18n.t('impossibility'),
        value: EMPLOYEE_EXPERT_TYPE.IMPOSSIBILITY,
      },
    ];
    const optionManagerConfirmTypeList = [
      {
        text: i18n.t('unSelect'),
        value: MANAGER_CONFIRM_TYPE.UN_SELECT,
        color: '#FF0000',
      },
      {
        text: i18n.t('reflectYourWishesInYourWorkSchedule'),
        value: MANAGER_CONFIRM_TYPE.YOUR_WORK_SCHEDULE,
        color: '#538D71',
      },
      {
        text: i18n.t('selectFromWorkPatterns'),
        value: MANAGER_CONFIRM_TYPE.FROM_WORK_PATTERN,
        color: '#22AEE5',
      },
      {
        text: i18n.t('addManually'),
        value: MANAGER_CONFIRM_TYPE.ADD_MANUALLY,
        color: '#f8bf2d',
      },
      {
        text: i18n.t('legalHolidays'),
        value: MANAGER_CONFIRM_TYPE.LEGAL_HOLIDAY,
        color: '#979797',
      },
      {
        text: i18n.t('makeItDesignatedHoliday'),
        value: MANAGER_CONFIRM_TYPE.DESIGNATED_HOLIDAY,
        color: '#979797',
      },
    ];

    const ITEM_STATUS = 3;
    //ShiftTimeManager id
    const shiftPlanId = router.currentRoute.value.params.id as string;
    const formValue = ref<ManagerRegisterShiftForPersonnelParams>({
      employees: [],
      shift_plan_id: shiftPlanId,
    });

    //call api to get shift_plan_status
    const { shiftPlanDetail, getPlanDetailData } = useGetPlanDetail();
    const statusNewShift = ref();
    const dataTableSelected = async () => {
      if (shiftPlanId) {
        //Call api get status
        await getPlanDetailData(shiftPlanId);
        statusNewShift.value = shiftPlanDetail.value.shift_plan_status;
      }
    };
    dataTableSelected();

    const isShowShiftTimeCreatePopup = ref(false);
    const closeShiftTimeCreatePopup = () => {
      isShowShiftTimeCreatePopup.value = false;
    };
    const openShiftTimeCreatePopup = () => {
      // TODO
      // isShowShiftTimeCreatePopup.value = true;
    };
    const paramsSearchUnit = ref({ is_all: true });
    const { unitList } = useUnitApi(paramsSearchUnit.value);
    const {
      requirementPersonnelRegisterShiftDetail,
      getRequirementPersonnelRegisterShiftData,
    } = useGetRequirementPersonnelRegisterShiftDetailData();
    getRequirementPersonnelRegisterShiftData(shiftPlanId);
    watchEffect(() => {
      if (requirementPersonnelRegisterShiftDetail) {
        membersToSubmittedHeaders.value = membersToSubmittedHeadersDefault.value.slice();
        requirementPersonnelRegisterShiftDetail.value.target_dates.forEach(
          (item: string) => {
            membersToSubmittedHeaders.value.push({
              text: moment(item).format('MM/DD（dd）'),
              value: 'date',
            });
          }
        );
        // mapping requirementPersonnelRegisterShiftDetail and formValue
        formValue.value = {
          shift_plan_id: requirementPersonnelRegisterShiftDetail.value.id,
          employees: requirementPersonnelRegisterShiftDetail.value.shift_plan_employees.map(
            (item) => {
              item.date_info = item.date_info ? item.date_info : [];
              const days = item.date_info.map((dateInfo) => {
                const shiftPlanEmployeeDay = ref<ShiftPlanEmployeeDay>({});
                shiftPlanEmployeeDay.value = dateInfo.shift_plan_employee_day
                  ? dateInfo.shift_plan_employee_day
                  : {};
                return {
                  day: dateInfo.target_date,
                  manager_confirm_type:
                    shiftPlanEmployeeDay.value.manager_confirm_type || null,
                  expected_shift_pattern_id:
                    shiftPlanEmployeeDay.value.expect_shift_pattern_id || null,
                  confirmed_shift_pattern_id:
                    shiftPlanEmployeeDay.value.confirmed_shift_pattern_id ||
                    null,
                  employee_expect_work_start_time:
                    shiftPlanEmployeeDay.value
                      .employee_expect_work_start_time || null,
                  employee_expect_work_end_time:
                    shiftPlanEmployeeDay.value.employee_expect_work_end_time ||
                    null,
                  work_start_time:
                    shiftPlanEmployeeDay.value
                      .manager_confirm_work_start_time || null,
                  work_end_time:
                    shiftPlanEmployeeDay.value.manager_confirm_work_end_time ||
                    null,
                  break_start_time:
                    shiftPlanEmployeeDay.value
                      .manager_confirm_break_start_time || null,
                  break_end_time:
                    shiftPlanEmployeeDay.value.manager_confirm_break_end_time ||
                    null,
                };
              });
              return {
                pp_employee_id: item.pp_employee_id,
                full_name: item.full_name,
                days: days,
              };
            }
          ),
        };
      }
    });

    const errorServer = ref({});
    const submitPostManagerRegisterShiftForPersonnel = async () => {
      const { registered } = usePostManagerRegisterShiftForPersonnel(
        formValue.value
      );
      await registered
        .then((error) => {
          if (error) {
            errorServer.value = error.errors.value;
            if (error.errorsCommon.value) {
              showToast('error', error.errorsCommon.value);
            }
          } else {
            showToast('success', i18n.t('successMessage.theShiftWasSaved'));
            getRequirementPersonnelRegisterShiftData(shiftPlanId);
          }
        })
        .catch(() => {
          showToast('error', i18n.t('errors.serverError'));
        });
    };
    const shiftPatternSearchParams = ref<ShiftPatternsApiParamSearch>({
      is_all: true,
      unit_id: '',
    });
    const accountStore = useLoginAccountStore();
    const myAccount = computed(() => accountStore.state.myAccount);
    const { shiftPatternData, getShiftPatternList } = useGetShiftPatternList();
    const toggleSelect = (index, indexDate, item) => {
      formValue.value.employees[index].days[indexDate].manager_confirm_type =
        item.value;
      if (item.value !== MANAGER_CONFIRM_TYPE.FROM_WORK_PATTERN) {
        formValue.value.employees[index].days[indexDate].work_start_time = '';
        formValue.value.employees[index].days[indexDate].work_end_time = '';
        formValue.value.employees[index].days[indexDate].break_start_time = '';
        formValue.value.employees[index].days[indexDate].break_end_time = '';
        formValue.value.employees[index].days[
          indexDate
        ].expected_shift_pattern_id = null;
        formValue.value.employees[index].days[
          indexDate
        ].confirmed_shift_pattern_id = null;
      }
      isShowOptionTime.value = false;
    };
    const toggleSelectShiftPattern = (item, index, indexDate) => {
      formValue.value.employees[index].days[indexDate].work_start_time =
        item.shift_pattern_times[0].start_time;
      formValue.value.employees[index].days[indexDate].work_end_time =
        item.shift_pattern_times[0].end_time;
      formValue.value.employees[index].days[indexDate].break_start_time =
        item.shift_pattern_times[0].start_time;
      formValue.value.employees[index].days[indexDate].break_end_time =
        item.shift_pattern_times[0].end_time;
      formValue.value.employees[index].days[
        indexDate
      ].expected_shift_pattern_id = item.shift_pattern_id;
      formValue.value.employees[index].days[
        indexDate
      ].confirmed_shift_pattern_id = item.shift_pattern_id;
    };
    watchEffect(() => {
      if (myAccount.value.pp_employee_id) {
        shiftPatternSearchParams.value.unit_id = myAccount.value.unit_id;
        getShiftPatternList(shiftPatternSearchParams.value);
      }
    });
    const isShowOptionTime = ref(false);
    const employeeShiftTimeSelectedList = ref<string[]>([]);
    const isAllEmployeeShiftTimeList = computed(
      () =>
        employeeShiftTimeSelectedList.value?.length ===
        formValue.value.employees?.length
    );
    const choseAllEmployeeShiftTime = () => {
      if (isAllEmployeeShiftTimeList.value) {
        employeeShiftTimeSelectedList.value = [];
      } else {
        employeeShiftTimeSelectedList.value = formValue.value.employees.map(
          (item) => item.pp_employee_id || ''
        );
      }
    };
    const displayRegisterDate = (item) => {
      if (item.register_start_date && item.register_end_date) {
        item.register_start_date = moment(item.register_start_date).format(
          'YYYY/MM/DD (dd)'
        );
        item.register_end_date = moment(item.register_end_date).format(
          'YYYY/MM/DD (dd)'
        );
        return `${item.register_start_date} ～ ${item.register_end_date}`;
      }
      return '';
    };
    const getBackgroundManagerConfirmType = (type) => {
      return optionManagerConfirmTypeList.find((item) => item.value === type)
        ?.color;
    };
    const getTextManagerConfirmType = (type) => {
      return optionManagerConfirmTypeList.find((item) => item.value === type)
        ?.text;
    };
    const isShowExpectWorkInfo = (index, indexDate) => {
      return requirementPersonnelRegisterShiftDetail.value.shift_plan_employees[
        index
      ].date_info[indexDate].shift_plan_employee_day;
    };
    const displayEmployeeExpertInfo = (item) => {
      if (
        item.employee_expect_work_start_time &&
        item.employee_expect_work_end_time
      ) {
        return `${item.employee_expect_work_start_time} ~ ${item.employee_expect_work_end_time}`;
      }
      return '';
    };
    const displayEmployeeExpertType = (shiftPlanEmployeeDay) => {
      if (shiftPlanEmployeeDay.expect_shift_pattern_id) {
        return shiftPatternData.value.find(
          (item) =>
            item.shift_pattern_id ===
            shiftPlanEmployeeDay.expect_shift_pattern_id
        )?.shift_pattern_name;
      }
      return employeeExpertOptions.find(
        (item) => item.value === shiftPlanEmployeeDay.employee_expect_type
      )?.text;
    };
    const changeConfirmationStatusShiftParam = ref<ChangeConfirmationStatusShiftParam>(
      {
        shift_plan_id: shiftPlanId,
        shift_confirmation_status: 1,
        pp_employee_ids: [],
      }
    );
    const displayUnitName = () => {
      return unitList.value.find(
        (item) =>
          item.unit_id === requirementPersonnelRegisterShiftDetail.value.unit_id
      )?.unit_name;
    };

    const submitShiftHasBeenConfirmed = (employeeIds) => {
      const msgSuccess = i18n.t('successMessage.theShiftHasBeenConfirmed');
      changeConfirmationStatusShiftParam.value = {
        shift_plan_id: shiftPlanId,
        shift_confirmation_status: SHIFT_CONFIRMATION_STATUS.CONFIRM,
        pp_employee_ids: employeeIds,
      };
      submitChangeConfirmationStatusShift(msgSuccess);
    };
    const submitAbleToCancelTheShift = (employeeIds) => {
      const msgSuccess = i18n.t('successMessage.wasAbleToCancelTheShift');
      changeConfirmationStatusShiftParam.value = {
        shift_plan_id: shiftPlanId,
        shift_confirmation_status: SHIFT_CONFIRMATION_STATUS.CANCEL,
        pp_employee_ids: employeeIds,
      };
      submitChangeConfirmationStatusShift(msgSuccess);
    };
    const submitChangeConfirmationStatusShift = (msgSuccess) => {
      const { updated } = useChangeConfirmationStatusShift(
        changeConfirmationStatusShiftParam.value
      );
      updated
        .then((error) => {
          if (error) {
            errorServer.value = error.errors.value;
            if (error.errorsCommon.value) {
              showToast('error', error.errorsCommon.value);
            }
          } else {
            showToast('success', msgSuccess);
            getRequirementPersonnelRegisterShiftData(shiftPlanId);
          }
        })
        .catch(() => {
          showToast('error', i18n.t('errors.serverError'));
        });
    };
    const changeNotificationStatusShiftParam = ref<ChangeNotificationStatusShiftParam>(
      {
        shift_plan_id: shiftPlanId,
        shift_notification_status: 1,
        pp_employee_ids: [],
      }
    );
    const submitAbleToNotifyTheShift = (employeeIds) => {
      const msgSuccess = i18n.t('successMessage.wasAbleToNotifyTheShift');
      changeNotificationStatusShiftParam.value = {
        shift_plan_id: shiftPlanId,
        shift_notification_status: SHIFT_NOTIFICATION_STATUS.NOTIFICATION,
        pp_employee_ids: employeeIds,
      };
      submitChangeNotificationStatusShift(msgSuccess);
    };
    const submitCancelTheNotificationOfTheShift = (employeeIds) => {
      const msgSuccess = i18n.t(
        'successMessage.wasAbleToCancelTheNotificationOfTheShift'
      );
      changeNotificationStatusShiftParam.value = {
        shift_plan_id: shiftPlanId,
        shift_notification_status: SHIFT_NOTIFICATION_STATUS.CANCEL,
        pp_employee_ids: employeeIds,
      };
      submitChangeNotificationStatusShift(msgSuccess);
    };
    const submitChangeNotificationStatusShift = (msgSuccess) => {
      const { updated } = useChangeNotificationStatusShift(
        changeNotificationStatusShiftParam.value
      );
      updated
        .then((error) => {
          if (error) {
            errorServer.value = error.errors.value;
            if (error.errorsCommon.value) {
              showToast('error', error.errorsCommon.value);
            }
          } else {
            showToast('success', msgSuccess);
            getRequirementPersonnelRegisterShiftData(shiftPlanId);
          }
        })
        .catch(() => {
          showToast('error', i18n.t('errors.serverError'));
        });
    };
    const returnShiftCreate = () => {
      router.push({
        name: ConstantsRouter.CreateNewShiftName,
        params: { shiftPlanId },
      });
    };

    return {
      t: i18n.t,
      changeConfirmationStatusShiftParam,
      membersToSubmittedHeaders,
      membersToSubmittedHeadersDefault,
      iconSearch,
      optionManagerConfirmTypeList,
      formValue,
      isShowShiftTimeCreatePopup,
      requirementPersonnelRegisterShiftDetail,
      AvatarSample,
      shiftPatternData,
      MANAGER_CONFIRM_TYPE,
      SHIFT_CONFIRMATION_STATUS,
      SHIFT_NOTIFICATION_STATUS,
      getRequirementPersonnelRegisterShiftData,
      isShowOptionTime,
      employeeShiftTimeSelectedList,
      isAllEmployeeShiftTimeList,
      getShiftPatternList,
      isShowExpectWorkInfo,
      choseAllEmployeeShiftTime,
      closeShiftTimeCreatePopup,
      openShiftTimeCreatePopup,
      submitPostManagerRegisterShiftForPersonnel,
      toggleSelect,
      toggleSelectShiftPattern,
      submitChangeConfirmationStatusShift,
      submitShiftHasBeenConfirmed,
      submitAbleToCancelTheShift,
      submitAbleToNotifyTheShift,
      submitCancelTheNotificationOfTheShift,
      submitChangeNotificationStatusShift,
      statusNewShift,
      returnShiftCreate,
      ITEM_STATUS,
      displayRegisterDate,
      getBackgroundManagerConfirmType,
      getTextManagerConfirmType,
      displayEmployeeExpertInfo,
      displayEmployeeExpertType,
      displayUnitName,
    };
  },
});
