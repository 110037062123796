
import { useActionStore } from '@/store/modules/copy-action';
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';
import Input from '@/components/Input.vue';
import Button from '@/components/Button.vue';
import router from '@/router';
import { ref, watchEffect } from '@vue/runtime-core';
import Select from '@/components/Select.vue';
import { useRoute } from 'vue-router';
import { isValidateForm } from '@/util/useValidateForm';
import { MonthlyClosingParams } from '@/api/types/MonthlyClosingDay';
import {
  useMonthlyClosingDayDetail,
  useUpdateMonthlyClosingDay,
} from '@/api/composables/useMonthlyClosingDay';
import { useStore } from '@/store';
import ConstantsRouter from '@/constants/router';
import Label from '@/components/Label.vue';
export default defineComponent({
  components: { Select, Button, Input, Label },
  setup() {
    const storeAction = useActionStore();
    const i18n = useI18n();
    const route = useRoute();
    const store = useStore();
    const closeDayOptionList = ref();
    const paramsMonthlyClosingDay = ref<MonthlyClosingParams>({
      monthly_closing_day_name: '',
      monthly_closing_day_code: '',
      monthly_closing_day: 0,
    });

    const {
      getMonthlyClosingDayDetail,
      monthlyClosingDetail,
    } = useMonthlyClosingDayDetail();
    getMonthlyClosingDayDetail(`${route.query.id}`);
    watchEffect(() => {
      if (monthlyClosingDetail.value) {
        paramsMonthlyClosingDay.value = {
          monthly_closing_day_code:
            monthlyClosingDetail.value.monthly_closing_day_code,
          monthly_closing_day_name:
            monthlyClosingDetail.value.monthly_closing_day_name,
          monthly_closing_day: monthlyClosingDetail.value.monthly_closing_day,
        };
      }
    });
    const updateDataClosingDay = () => {
      if (!isValid()) return;
      let data = {
        monthly_closing_day_code:
          paramsMonthlyClosingDay.value.monthly_closing_day_code,
        monthly_closing_day_name:
          paramsMonthlyClosingDay.value.monthly_closing_day_name,
        monthly_closing_day: paramsMonthlyClosingDay.value.monthly_closing_day,
      };
      const { update } = useUpdateMonthlyClosingDay(`${route.query.id}`, data);
      update
        .then((error) => {
          if (error) {
            listErrorServerReturn.value = error.errors.value;
            if (error.errorsCommon.value) {
              store.commit('root/SET_NOTIFICATION', {
                type: 'error',
                message: error.errorsCommon.value,
              });
            }
          } else {
            store.commit('root/SET_NOTIFICATION', {
              type: 'success',
              message: i18n.t('successMessage.updateClosingDaySuccess'),
            });
            router.push(ConstantsRouter.MonthlyClosingDayList);
          }
        })
        .catch(() => {
          store.commit('root/SET_NOTIFICATION', {
            type: 'error',
            message: i18n.t('errors.serverError'),
          });
        });
    };
    const isSubmit = ref(false);
    const updateDataMonthlyClosingDay = () => {
      if (!isValid()) return;
      updateDataClosingDay();
    };
    const listErrorServerReturn = ref({});
    const errors = ref({});
    const isValid = () => {
      isSubmit.value = true;
      return isValidateForm(errors);
    };
    closeDayOptionList.value = Array.from(Array(31)).map((e, i) => {
      return { value: i + 1, text: i + 1 };
    });
    closeDayOptionList.value.push(
      { text: i18n.t('none'), value: 32 },
      { text: i18n.t('end'), value: 0 }
    );
    const backToListCloseDayMaster = () => {
      router.push(ConstantsRouter.MonthlyClosingDayList);
    };
    return {
      t: i18n.t,
      paramsMonthlyClosingDay,
      errors,
      isValid,
      listErrorServerReturn,
      closeDayOptionList,
      isSubmit,
      storeAction,
      monthlyClosingDetail,
      backToListCloseDayMaster,
      updateDataClosingDay,
      updateDataMonthlyClosingDay,
      getMonthlyClosingDayDetail,
    };
  },
});
