export const MANAGER_CONFIRM_TYPE = {
  UN_SELECT: null,
  YOUR_WORK_SCHEDULE: 0,
  FROM_WORK_PATTERN: 1,
  ADD_MANUALLY: 2,
  LEGAL_HOLIDAY: 3,
  DESIGNATED_HOLIDAY: 4,
};
export const SHIFT_CONFIRMATION_STATUS = {
  CONFIRM: 1,
  CANCEL: 0,
};
export const SHIFT_NOTIFICATION_STATUS = {
  NOTIFICATION: 1,
  CANCEL: 0,
};
export const EMPLOYEE_EXPERT_TYPE = {
  SELECT_FROM_WORK_PATTERN: 0,
  ADD_MANUALLY: 1,
  IMPOSSIBILITY: 2,
};

export const SHIFT_PLAN_USER_AVATAR_PER_PAGE_LOAD_MORE = 10;
