import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  width: "17",
  height: "12",
  viewBox: "0 0 17 12",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("svg", _hoisted_1, [
    _createVNode("path", {
      d: "M16.25 0.75L5.75 11.2495L0.5 6",
      stroke: _ctx.color,
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }, null, 8, ["stroke"])
  ]))
}