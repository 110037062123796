
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';
import Button from '@/components/Button.vue';
import Table from '@/components/Table.vue';
export default defineComponent({
  components: {
    Button,
    Table,
  },
  setup() {
    const i18n = useI18n();
    const t = i18n.t;

    const headers = [
      {
        text: t('vacationHeaders.vacationCode'),
        value: 'vacation_code',
        width: '5%',
      },
      {
        text: t('vacationHeaders.vacationName'),
        value: 'vacation_name',
        width: '15%',
        whitespace: true,
      },
      {
        text: t('vacationHeaders.abbreviation'),
        value: 'abbreviation',
        width: '13%',
        whitespace: true,
      },
      {
        text: t('vacationHeaders.vacationType'),
        value: 'vacation_type',
        width: '10%',
        whitespace: true,
      },
      {
        text: t('vacationHeaders.displayImage'),
        value: 'display_image',
        width: '10%',
      },
      {
        text: t('vacationHeaders.paidType'),
        value: 'paid_type',
        width: '10%',
        whitespace: true,
      },
      {
        text: t('vacationHeaders.additionType'),
        value: 'addition_type',
        width: '10%',
        whitespace: true,
      },
      {
        text: t('vacationHeaders.halfDayUnitAcquisition'),
        value: 'half_day_unit_acquisition',
        width: '10%',
        whitespace: true,
      },
      {
        text: t('vacationHeaders.timeUnitAcquisition'),
        value: 'time_unit_acquisition',
        width: '7%',
        whitespace: true,
      },
      {
        text: t('edit'),
        value: 'edit',
        width: '5%',
      },
      {
        text: t('delete'),
        value: 'delete',
        width: '5%',
      },
    ];
    const data = [
      {
        vacation_code: 300,
        vacation_name: '代休',
        abbreviation: '代休',
        vacation_type: '代休',
        display_image: '代休',
        paid_type: 'ー',
        addition_type: '減算',
        half_day_unit_acquisition: '許可',
        time_unit_acquisition: '不許可',
        background: '#6B53FF',
        border: '#000000',
      },
      {
        vacation_code: 400,
        vacation_name: '振替休日',
        abbreviation: '振休',
        vacation_type: '振替休日',
        display_image: '振休',
        paid_type: 'ー',
        addition_type: 'ー',
        half_day_unit_acquisition: 'ー',
        time_unit_acquisition: 'ー',
        background: '#FF4F4F',
        border: '#FF4F4F',
      },
      {
        vacation_code: 500,
        vacation_name: '年次有給休暇（正社員）',
        abbreviation: '年次有休ａ',
        vacation_type: '年次有給休暇',
        display_image: '年次有休ａ',
        paid_type: '有給',
        addition_type: '有給',
        half_day_unit_acquisition: '許可',
        time_unit_acquisition: '許可',
        background: '#FF8D5C',
        border: '#FF8D5C',
      },
      {
        vacation_code: 501,
        vacation_name: '年次有給休暇（正社員以外）',
        abbreviation: '年次有休ｂ',
        vacation_type: '年次有給休暇',
        display_image: '年次有休ｂ',
        paid_type: '有給',
        addition_type: '減算',
        half_day_unit_acquisition: '許可',
        time_unit_acquisition: '不許可',
        background: '#FF8D5C',
        border: '#FF8D5C',
      },
      {
        vacation_code: 600,
        vacation_name: '夏季休暇（正社員）',
        abbreviation: '夏季休暇A',
        vacation_type: '特別休暇',
        display_image: '夏季休暇Ａ',
        paid_type: '有給',
        addition_type: '減算',
        half_day_unit_acquisition: '許可',
        time_unit_acquisition: '許可',
        background: '#45D1D1',
        border: '#45D1D1',
      },
      {
        vacation_code: 601,
        vacation_name: '夏季休暇（契約社員）',
        abbreviation: '夏季休暇B',
        vacation_type: '特別休暇',
        display_image: '夏季休暇B',
        paid_type: '有給',
        addition_type: '減算',
        half_day_unit_acquisition: '許可',
        time_unit_acquisition: '不許可',
        background: '#45D1D1',
        border: '#45D1D1',
      },
      {
        vacation_code: 602,
        vacation_name: '夏季休暇（パートタイマー）',
        abbreviation: '夏季休暇C',
        vacation_type: '特別休暇',
        display_image: '夏季休暇C',
        paid_type: '有給',
        addition_type: '減算',
        half_day_unit_acquisition: '許可',
        time_unit_acquisition: '不許可',
        background: '#45D1D1',
        border: '#45D1D1',
      },
      {
        vacation_code: 700,
        vacation_name: '生理休暇（無給）',
        abbreviation: '生理休暇',
        vacation_type: '特別休暇',
        display_image: '生理休暇',
        paid_type: '有給',
        addition_type: '減算',
        half_day_unit_acquisition: '許可',
        time_unit_acquisition: '許可',
        background: '#FF8C84',
        border: '#FF8C84',
      },
      {
        vacation_code: 800,
        vacation_name: '介護休暇',
        abbreviation: '介護休暇',
        vacation_type: '特別休暇',
        display_image: '介護休暇',
        paid_type: '有給',
        addition_type: '加算',
        half_day_unit_acquisition: '不許可',
        time_unit_acquisition: '不許可',
        background: '#863232',
        border: '#863232',
      },
      {
        vacation_code: 900,
        vacation_name: '子の看護休暇',
        abbreviation: '子の看護休暇',
        vacation_type: '特別休暇',
        display_image: '子の看護休暇',
        paid_type: '無給',
        addition_type: '減算',
        half_day_unit_acquisition: '許可',
        time_unit_acquisition: '許可',
        background: '#FF82DC',
        border: '#FF82DC',
      },
    ];
    return {
      t,
      headers,
      data,
    };
  },
});
