{
  "add": "追加",
  "stamp": "打刻",
  "nameCompany": "アイングループ",
  "timeCard": "タイムカード",
  "checkIn": "出勤",
  "checkOut": "退勤",
  "year": "年",
  "month": "月",
  "monday": "月",
  "tuesday": "火",
  "wednesday": "水",
  "thursday": "木",
  "friday": "金",
  "saturday": "土",
  "sunday": "日",
  "commuted": "出勤打刻をしました。",
  "goneout": "退勤打刻をしました。",
  "checkInSuccess": "チェックイン成功",
  "checkOutSuccess": "チェックアウト成功",
  "attendanceRecord": "勤怠実績",
  "humanResources": "人事課",
  "dailyAttendanceHeaders": {
    "apply": "申請",
    "date": "日付",
    "finalConfirmation": "最終確定",
    "confirmationOfSuperior": "上長確定",
    "confirmationOfApplicant": "本人確定",
    "workSchedule": "勤務予定",
    "performanceType": "実績種別",
    "checkin": "出勤",
    "checkout": "退勤",
    "breakStartTimeRounding": "休憩開始",
    "breakEndTimeRounding": "休憩終了",
    "breakStartTime": "休憩開始打刻",
    "breakEndTime": "休憩終了打刻",
    "timeWorkFixed": "所定時間",
    "overtime": "所定外時間",
    "statutoryOvertime": "法定外時間",
    "nightWorkingTime": "深夜時間",
    "statutoryHolidayTime": "法定休日 時間",
    "break": "休憩",
    "late": "遅刻",
    "early": "早退",
    "deemedPaidTime": "有給みなし 時間",
    "organization": "出勤組織",
    "office": "オフィス",
    "teleWork": "テレワーク",
    "unapprovedOvertime": "未承認残業",
    "actualWorkingHours": "実労働時間",
    "specialAllowance": "待機手当",
    "note": "備考",
    "actualCheckin": "出勤打刻",
    "actualCheckout": "退勤打刻",
    "overtimeDivision1": "時間外区分1",
    "overtimeDivision2": "時間外区分2",
    "schedule": "スケジュール",
    "workingDayType": "勤務日種別",
    "midnightCount": "深夜回数",
    "semiWaiting": "セミ待機",
    "prescribed": "所定",
    "overDuty": "超過勤務",
    "legalHolidayPrescribed": "法定休日所定",
    "prescribedHolidaysPrescribed": "所定休日所定",
    "midnightOvertime": "深夜残業",
    "lateEarlyDepartureTime": "遅刻早退時間",
    "totalWorkingHours": "労働時間合計",
    "applicationMessage": "申請メッセージ"
  },
  "required": "必須",
  "requiredMessage": "続行するには、このフィールドに記入してください",
  "breakStartMsg": "休憩開始打刻をしました。",
  "breakEndMsg": "休憩終了打刻をしました。",
  "dailyAttendanceRecord": "日別勤怠実績",
  "loadingText": "読み込み中",
  "configuration": "設定",
  "organizationStaffMaster": "組織・スタッフマスタ",
  "attendanceMaster": "勤怠マスタ",
  "vacationMaster": "休暇マスタ",
  "vacationHeaders": {
    "vacationCode": "休暇コード",
    "vacationName": "休暇名",
    "abbreviation": "略称",
    "vacationType": "休暇タイプ",
    "displayImage": "表示イメージ",
    "paidType": "有給/無給種別",
    "additionType": "加算/減算種別",
    "halfDayUnitAcquisition": "半日単位取得",
    "timeUnitAcquisition": "時間単位取得"
  },
  "scheduledWorkingMaster": "所定勤務マスタ",
  "vacationMasterSetting": "休暇マスタ設定",
  "vacationGroupSetting": "休暇グループ設定",
  "scheduledWorkHolidayMaster": "所定労働・休日マスタ",
  "workingStyleMaster": "就労形態マスタ",
  "timeZoneDivisionSetting": "時間帯区分設定",
  "administratorSettings": "管理者設定",
  "displayNotificationSettings": "表示・通知設定",
  "scheduledWorkMaster": "勤務予定マスタ",
  "systemWideSettingsAndConfirmation": "システムの利用状況やシステム全体の設定や確認",
  "leaveMaster": "所定労働・休日マスタ",
  "holidayMaster": "勤務パターンマスタ",
  "timeZoneDivisionGroup": "時間帯区分グループ",
  "timeFrameDivison": "時間帯区分設定",
  "applicableDate": "適用年月",
  "showAllEmploymentTypes": "全ての雇用形態を表示",
  "showAllWorkingStyleTypes": "全ての就労形態を表示",
  "narrowSearch": "絞り込み検索",
  "individualApplicationPolicyCreate": {
    "individualApplicationPolicySetting": "個別適用ポリシー設定",
    "basicSetting": "基本設定",
    "targetEmployee": "対象社員",
    "applicationStartDate": "適用開始年月",
    "applicationEndDate": "適用終了年月",
    "applyMaster": "適用するマスタ",
    "companyCalendar": "会社カレンダー",
    "prescribedLaborPattern": "所定労働パターン",
    "closingDatePattern": "締め日パターン",
    "workingStyle": "就労形態",
    "holidayGroup": "休暇グループ",
    "timezoneDivisionGroup": "時間帯区分",
    "userTimeZoneDivisionGroup": "時間帯区分を利用する",
    "authority": "権限",
    "save": "保存する"
  },
  "attendanceDataHeaders": {
    "employmentTypeName": "雇用形態名",
    "employmentTypeCode": "雇用形態コード",
    "workingStyleName": "就労形態名",
    "workingStyleCode": "就労形態コード",
    "operationStartDate": "適用年月",
    "editAction": "編集",
    "removeAction": "削除",
    "copyAction": "コピー"
  },
  "addAction": "追加する",
  "automatictBreak": {
    "overtimeSetting": "時間外設定",
    "treatmentOfOvertime": "所定外時間の扱い",
    "noteTreatmentOfOvertime": "※日の法定時間は8時間、週の法定時間は40時間となります。",
    "treatOptionLegalHours": "すべて時間外として計上する",
    "treatOptionNoRules": "所定時間外と法定時間外で区別して計上する",
    "weeklyCalculationOutside": "週単位での法定労働時間外の計算",
    "do": "する",
    "doNot": "しない",
    "calculateWeekCrossedDealine": "締め日をまたぐ週の計算方法",
    "calculateIncludingPreviousMonth": "前月を含めて計算する",
    "calculateOnDailyBasic": "日割りで計算する",
    "overtimeApplicationFunc": "時間外申請機能",
    "extraOvertimeFunction": "特別割増時間外集計機能",
    "use": "使用する",
    "notUse": "使用しない",
    "overtimeAppUse": "使用する",
    "overtimeAppNotUse": "使用しない",
    "overtimeAppOvertimeHoursAllowed": "勤務予定時間内の時間外労働は認める",
    "extraOvertimeFunc": "割増残業機能",
    "summaryItemsPremiumHours": "割増時間外の集計項目選択",
    "summaryIncludeHolidayWork": "法定休日出勤分を含む",
    "summaryNotIncludeHolidayWork": "法定休日出勤分を含まない",
    "extraOver": "割増残業",
    "afterExtraHours": "特別割増時間外",
    "overtimeWorkOfMonth": "月の時間外労働が",
    "overtimeHours": "残業時間が",
    "recordTimeExceeded": "を超える場合に計上する",
    "roundingCalculatingWeeks": "締め日をまたぐ週の計算時の端数処理（少数第一位）",
    "truncate": "切り捨てる",
    "roundUp": "切り上げる",
    "rounding": "四捨五入"
  },
  "placeholderSelectWorkingStyle": "選択",
  "placeholderSelect": "選択してください",
  "beingLateTime": {
    "roundingSetting": "丸め設定",
    "seconds": "秒単位（打刻時間の丸め)",
    "attendanceTimeStamp": "出勤打刻時間",
    "truncate": "切り捨てる",
    "roundUp": "切り上げる",
    "doNotRound": "丸めない",
    "leaveTime": "退勤打刻時間",
    "breakTimeStart": "休憩開始打刻時間",
    "breakTimeEnd": "休憩終了打刻時間",
    "minutesTitle": "分単位（実績時間の丸め）",
    "attendanceStamp": "出勤打刻",
    "unitMinuties": "分",
    "leaveWorkStamp": "退勤打刻",
    "roundingForWorkSchedule": "勤務予定に対する丸め処理",
    "fixCommutingTime": "出勤時間を勤務予定の開始時間に固定する",
    "fixedAction": "固定する",
    "notFixedAction": "固定しない",
    "fixLeaveTime": "退勤時間を勤務予定の終了時間に固定する",
    "roundUpTime": "勤務予定終了時間から定めた時間まで丸める",
    "fixCommutingHint": "「固定する」を選択すると勤務予定の開始時刻に出勤時間を丸めます。勤務予定の開始時間より出勤時刻が早い場合のみ適用されます。",
    "fixLeaveTimeHint": "「固定する」を選択する勤務予定の開始時刻に出勤時間を丸めます。勤務予定の開始時間より出勤時刻が早い場合のみ適用されます。",
    "fromTheShiftEndTime": "シフト終了時刻から",
    "roundToLessMinutes": "分未満まで丸める",
    "ifYouSelectFix": "「固定する」を選択した場合、勤務予定の開始時刻に勤務実績の出勤を丸めます。勤務予定の開始時間より出勤時刻が早い場合のみ適用されます。"
  },
  "attendanceNote": "労働時間の集計に関する詳細設定を行います。同じ就労形態でも時間外集計や休憩時間の設定、締め日や端数時間の丸めの設定が異なる場合は、新規作成の上で新たに設定する必要があります。",
  "basicSettings": "基本設定",
  "update": "更新",
  "employmentTypeCode": "雇用形態コード",
  "employmentTypeName": "雇用形態名",
  "employmentStatus": "雇用形態",
  "workingStyleName": "就労形態名",
  "workingStyleCode": "就労形態コード",
  "businessDaySwitchTime": "日付変更時刻",
  "attendanceDeadline": "勤怠の締め日",
  "weeklyClosingDay": "週の締め曜日",
  "lateNightWorkingTimeFrame": "深夜勤務時間帯",
  "followingDay": "翌日",
  "pleaseEnter": "入力してください",
  "pleaseSelect": "選んでください",
  "time": "時",
  "day": "日",
  "daysOfWeek": {
    "mon": "月曜日",
    "tue": "火曜日",
    "wed": "水曜日",
    "thu": "木曜日",
    "fri": "金曜日",
    "sat": "土曜日",
    "sun": "日曜日"
  },
  "workingStyleSettings": "就労形態設定",
  "dutySystem": "勤務体系",
  "noteDutySystem": "毎日の所定労働時間を管理者が設定し、日、週ごとに時間外労働を計算します。休日労働時間、深夜労働時間も計算されます。",
  "standardLabor": "標準労働",
  "fixedService": "（固定勤務）",
  "shiftWorkService": "（シフト勤務）",
  "transformationLabor": "変形労働",
  "discretionaryLabor": "裁量労働",
  "flextimeSystem": "フレックスタイム制",
  "fixedDuty": "固定勤務",
  "shiftWork": "シフト勤務",
  "weeklyWorkPatternAndWorkingHours": "週の勤務パターンと勤務時間",
  "workingStyleHeaders": {
    "dayOfWeek": "曜日",
    "workingType": "勤務種別",
    "fixedWorkingHours": "固定勤務時間",
    "break": "休憩"
  },
  "dayOfWeek": "曜日",
  "workingType": "作業タイプ",
  "fixedWorkingHours": "固定労働時間",
  "dateAndTime": "日時",
  "nationalHoliday": "祝日",
  "employeeWorkday": "出勤日",
  "legalHoliday": "法定休日",
  "scheduledHoliday": "所定休日",
  "breakSetting": "休憩設定",
  "automaticBreakFunction": "自動休憩機能",
  "automaticallyTakeBreaks": "自動で休憩を取得する",
  "standardWorkBreaks": "標準勤務の休憩を使用する",
  "notAutomaticBreak": "自動で休憩を取得しない(打刻休憩のみ有効）",
  "breakYourWorkPattern": "勤務パターンの休憩を取得する",
  "automaticBreak": "自動休憩",
  "workingHours": "労働時間が",
  "moreThanMinutes": "を超える場合は",
  "oneMinuteBreak": "の休憩を与える",
  "priorityOfAutomaticBreaks": "自動休憩を引く項目の優先順位",
  "timeStampAndAutomaticBreakOverlap": "打刻休憩と自動休憩の重複時",
  "selectWorkDescription": "本日の出勤場所を選択してください",
  "officeAttendance": "オフ ィス出勤",
  "teleworkCommute": "テレワーク出勤",
  "labelAttendance": "出勤組織",
  "labelAttendancePlaceholder": "東京丸の内店",
  "weekly": "毎週",
  "onTheDay": "当日",
  "beforeDay": "前日",
  "nextDay": "翌日",
  "monthy": "毎月",
  "1st": "第１",
  "2st": "第２",
  "3st": "第３",
  "4st": "第４",
  "5st": "第５",
  "jan": "1月",
  "feb": "2月",
  "mar": "3月",
  "apr": "4月",
  "may": "5月",
  "jun": "6月",
  "jul": "7月",
  "aug": "8月",
  "sep": "9月",
  "oct": "10月",
  "nov": "11月",
  "dec": "12月",
  "priorityBreakList": {
    "normalLateToNight": "普通＞深夜",
    "lateNightToNormal": "深夜＞普通",
    "one": "所定＞所定外＞深夜",
    "two": "所定＞深夜＞所定外",
    "three": "深夜＞所定＞所定外",
    "four": "深夜＞所定外＞深夜",
    "five": "所定外＞所定＞深夜",
    "six": "所定外＞深夜＞所定"
  },
  "onlyTimedBreak": "打刻休憩のみを休憩時間とする",
  "automaticBreaksAsBreaks": "自動休憩を休憩時間とする",
  "sumOfStampedBreakAndAutomaticBreak": "打刻休憩と自動休憩を合算したものを休憩時間とする",
  "noteAttendanceDeadline": "※０を入力すると月末という扱いになります",
  "errors": {
    "required": "必須項目です",
    "max": "フィールドは以下でなければなりません",
    "min": "フィールドはより大きくなければなりません",
    "characters": "文字",
    "range": "で入力してください",
    "timeInvalid": "時間は無効です",
    "serverError": "エラーが発生しました。もう一度お試しください",
    "shiftPatternDeleteFail": "勤務パターンが削除できませんでした",
    "updateTimeCardFail": "表示項目が設定できませんでした"
  },
  "timeZone": {
    "buttonApply": "適用",
    "placeholder": "絞り込み検索",
    "description": "特定の時間帯における勤務時間を集計したい場合に設定します。ここで設定した時間帯区分マスタを、スタッフ一人ひとりに当てはめることで集計できるようになります。",
    "timeZoneCode": "時間帯コード",
    "timeZoneName": "時間帯名",
    "lastModified": "最終更新日時 "
  },
  "successMessage": {
    "workStyleMasterHasBeenCreated": "就労形態マスタが作成されました",
    "workStyleMasterHasBeenUpdated": "就労形態マスタが更新されました",
    "timezoneDivisionCreated": "時間帯区分を登録しました",
    "timezoneDivisionUpdated": "時間帯区分を保存しました",
    "workPatternCreated": "勤務パターンが作成されました",
    "timezoneDivisionCreatedFail": "時間帯が作成できませんでした",
    "timezoneDivisionUpdatedFail": "時間帯が更新できませんでした",
    "workPatternUpdated": "勤務パターンが更新されました",
    "workPatternDeleted": "勤務パターンが削除されました",
    "monthlyClosingDayDeleteSuccess": "締め日が削除されました",
    "createClosingDaySuccess": "締日が作成されました",
    "updateClosingDaySuccess": "締日が更新されました",
    "successMessageDeleteScheduleWorking": "所定労働パターンが削除されました",
    "privilegeRoleCreated": "権限ロールが作成されました",
    "privilegeRoleUpdated": "権限ロールが更新されました",
    "privilegeRoleDeleted": "権限ロールが削除されました",
    "workingScheduleCreated": "所定労働パターンが作成されました",
    "workingScheduleUpdated": "所定労働パターンが更新されました",
    "personaSettingApplyCreat": "社員適用が作成できました",
    "personaSettingApplyUpdate": "社員適用が更新できました",
    "personaSettingApplyDelete": "社員適用が削除できました",
    "theShiftHasBeenConfirmed": "シフトが確定できました",
    "wasAbleToCancelTheShift": "シフトが確定解除できました",
    "wasAbleToNotifyTheShift": "シフトが通知できました",
    "wasAbleToCancelTheNotificationOfTheShift": "シフトが通知解除できました",
    "theShiftWasSaved": "シフトが保存できました",
    "shiftPlanHasBeenCreated": "シフトプランが作成されました",
    "shiftPlanHasBeenUpdated": "シフトプランが更新されました",
    "updateTimeCard": "表示項目が設定されました",
    "shiftSubmitted": "シフトが提出されました"
  },
  "failMessage": {
    "monthlyClosingDayFail": " 締め日が削除できませんでした",
    "scheduleWorkingFail": "所定労働パターンが削除できませんでした",
    "privilegeRoleDeletedFail": "削除できませんでした"
  },
  "timezoneCode": "時間帯コード",
  "timezoneName": "時間帯名称",
  "settingTargetDate": "設定対象日",
  "timezoneDetails": "時間帯詳細",
  "timezoneDetailsNote": "テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト",
  "timezoneHeaders": {
    "dayOfWeek": "曜日",
    "settingTargetDate": "設定対象日",
    "fixedWorkingHours": "固定勤務時間"
  },
  "overtimeRegister": {
    "overtimeApplication": "超過勤務申請",
    "targetDate": "対象日",
    "exampleDate": "2021/04/05（月）",
    "overtimeApplicationTime": "超過勤務申請時間",
    "labelCheckin": "出勤：",
    "textDateExample": "hh:mm",
    "totalWorkingHours": "合計勤務時間：",
    "overtime": "超過勤務時間：",
    "buttonApply": "申請する",
    "buttonCancel": "キャンセル",
    "attendanceAtWorkLb": "出勤",
    "leavingWork": "退勤",
    "rest": "休憩",
    "workingTime": "労働時間",
    "schedule": "スケジュール",
    "stampingRecord": "打刻実績",
    "timeExample": "8:00",
    "timeExample2": "17:00",
    "timeExample3": "7:00",
    "timeExample4": "1:00",
    "timeExample5": "10:00",
    "timeExample6": "2:00",
    "ReasonApplication": "申請理由",
    "approvalRoute": "承認ルート",
    "primaryApproval": "１次承認",
    "placeholderSelectOvertime": "選択または入力して下さい"
  },
  "removeItemTitle": "{name} を削除する",
  "removeItemBody": "設定した {name} が全て削除されます。",
  "confirmDelete": "本当に削除してよろしいですか？",
  "actionDelete": "削除する",
  "deteleTimeZoneSuccessfully": "時間帯が削除されました",
  "deteleTimeZoneFailure": "時間帯が削除できませんでした",
  "deteleWorkingStyleMasterSuccessfully": "就労形態が削除されました",
  "deteleWorkingStyleMasterFailure": "就労形態マスタが削除できませんでした",
  "apply": "適用",
  "newRegistration": "新規登録する",
  "noData": "データがなし",
  "generalErrorMessage": "{name}は{error}です。",
  "timeZoneEnd": "終了時間",
  "timeZoneStart": "開始時間",
  "daysTotal": "日数集計を見る",
  "weekdays": "平日",
  "holiday": "休日",
  "earlyLeave": "早退",
  "closed": "有休",
  "absence": "欠勤",
  "exchangeDay": "振休",
  "summerVacation": "夏季休暇",
  "newYearHolidays": "年末年始休暇",
  "paidSpecialLeave": "有給：特別休暇",
  "unpaidSpecialLeave": "無給：特別休暇",
  "adjustmentDate": "調整日",
  "timeSummary": "時間集計を見る",
  "laborStandardsHours": "労働基準時間",
  "timeWorkFixed": "所定時間",
  "overDuty": "超過勤務",
  "extraOvertimeFunc": "割り増し残業",
  "legalHolidayPrescribed": "法定休日所定",
  "prescribedHolidaysPrescribed": "所定休日所定",
  "extraOver": "割増残業",
  "break": "休憩",
  "laborTotal": "労働合計",
  "workData": "勤務データ",
  "businessMonth": "営業月",
  "lateEarlyApplication": "遅刻早退申請",
  "targetDate": "対象日",
  "scheduledToWork": "勤務予定",
  "stampingRecord": "打刻実績",
  "totalWorkingHours": "合計勤務時間",
  "applicationType": "申請種類",
  "reasonsForBeingLateEarly": "遅刻/早退の理由",
  "attachment": "添付ファイル",
  "uploadFile": "ファイルをアップロード",
  "register": "申請する",
  "cancel": "キャンセル",
  "late": "遅刻",
  "early": "早退",
  "checkin": "出勤",
  "checkout": "退勤",
  "breakStartTimeRounding": "休憩開始",
  "breakEndTimeRounding": "休憩終了",
  "enterTheApplicationMessage": "申請メッセージを入力する",
  "noFileChosen": "ファイルが選択されていません",
  "files": "ファイル",
  "applicationApproval": "全申請承認",
  "searchByName": "氏名で検索",
  "approvalPending": "承認待ち",
  "inProgress": "進行中",
  "confessed": "承認完了",
  "allTypesOfApplicationNote": "プルダウンは\n申請全種類が表示選択可能",
  "operateOnAllChecked": "チェックした全てに操作",
  "approve": "承認",
  "reject": "棄却",
  "representationPeriod": "表示期間",
  "allApplications": "全ての申請",
  "engravingApplication": "打刻申請",
  "vacationRequest": "休暇申請",
  "disabilityApplication": "残業申請",
  "holidayCommutingApplication": "休日出勤申請",
  "applicationForGoingOut": "外出申請",
  "lateEarlyDepartureApplication": "遅刻早退申請",
  "deploy": "部署",
  "employmentDivision": "雇用区分",
  "targetEmployees": "対象従業員",
  "commentOfApprover": "承認者のコメント",
  "approverInResponse": "対応中の承認者",
  "finalAppointer": "最終承認者",
  "enterAdministratorComment": "管理者コメントを入力",
  "case": "件",
  "applyForRecognition": "申請承認",
  "applicationHistory": "申請履歴",
  "applicationDate": "申請日時",
  "monthDesignation": "月指定",
  "specifyThePeriod": "期間指定",
  "applicationTargetDate": "申請対象日",
  "affiliationOrganization": "所属組織",
  "allAffiliatedOrganizations": "すべての所属組織",
  "director": "役職",
  "allJobTitles": "すべての役職",
  "workingStyle": "就労形態",
  "allWorkingStyle": "すべての就労形態",
  "applicationType2": "申請種別",
  "engraveApplication": "打刻申請",
  "overtimeApplication": "残業申請",
  "transferApplication": "振替申請",
  "waitingAllowanceApplication": "待機手当申請",
  "status": "ステータス",
  "waitingForPrimaryApproval": "一次承認待ち",
  "waitingForSecondApproval": "二次承認待ち",
  "remand": "差戻",
  "completion": "完了",
  "search": "検索",
  "export": "エクスポート",
  "acknowledgementTimeOfDay": "承認 / 差戻日時",
  "staffCode": "スタッフコード",
  "fullName": "氏名",
  "applicationMessage": "申請メッセージ",
  "stampRegister": {
    "application": "打刻申請",
    "targetDate": "対象日",
    "dateExample": "2021年04月05日（月）",
    "workPlace": "出勤場所",
    "stampEditing": "打刻編集",
    "stampType": "打刻種別",
    "stampingDateAndTime": "打刻日時",
    "attendanceOrganization": "出社組織",
    "delete": "削除",
    "applicationMessage": "申請メッセージ",
    "attendanceWork": "出勤",
    "buttonApply": "申請する",
    "buttonCancel": "キャンセル",
    "leavingWork": "退勤",
    "breakStart": "休憩開始",
    "breakEnd": "休憩終了",
    "goingBack": "外出戻り",
    "textDateExample": "hh:mm",
    "goOut": "外出",
    "return": "戻り",
    "placeholderTextExample": "申請メッセージを入力する",
    "placeholderTextExample1": "東日本営業本部インサイドセールスチーム",
    "approvalRoute": "承認ルート",
    "applicationTime": "申請時刻",
    "setterAndCommunicator": "設定者/権限ロール",
    "approvalDateAndTime": "承認日時",
    "comment": "コメント",
    "officeAttendance": "オフィス 出勤",
    "teleworkCommute": "テレワーク出勤",
    "checkInOffice": "オフィス出勤",
    "checkInSuccess": "出勤打刻をしました",
    "checkOutSuccess": "退勤打刻をしました",
    "breakStartSuccess": "休憩開始打刻をしました",
    "breakEndSuccess": "休憩終了打刻をしました",
    "goutStart": "外出打刻をしました",
    "goOutEnd": "外出戻り打刻をしました"
  },
  "allEmployeesCanView": "全社員閲覧可能",
  "onlyViewableByEmpOfTheDepartment": "所属部署社員のみ閲覧可能",
  "onlyYouCanView": "自身のみ閲覧可能",
  "notViewable": "閲覧不可",
  "applicationReason": "申請の理由",
  "permissionSetting": "権限設定",
  "applicationFlow": "申請フロー",
  "timeClockSettings": "タイムレコーダー設定",
  "monthlySummaryOutputLayoutSettings": "月次集計出力レイアウト設定",
  "iCCardRegistration": "ICカード登録",
  "permissionCode": "権限コード",
  "authorityName": "権限名称",
  "permissionRoleList": "権限ロール一覧",
  "privilegeEditing": "権限編集",
  "addPermissions": "権限を追加する",
  "toRegister": "登録する",
  "backToTheList": "一覧へもどる",
  "selectAllPermissions": "全ての権限を選択",
  "employeeAuthority": "従業員権限",
  "leaderAuthority": "リーダー権限",
  "selectAuthToEdit": "編集する権限ロールを選択",
  "managerPrivileges": "マネージャー権限",
  "personnelManagerAuthority": "人事管理者権限",
  "sysAdminPrivi": "システム管理者権限",
  "employeeCode": "スタッフコード",
  "shiftRegistration": {
    "menu": "シフト",
    "submitDesired": "希望シフトを提出する",
    "myShift": "私のシフト",
    "everyoneShift": "みんなのシフト",
    "buttonText": "提出する",
    "shiftPattern": "シフト パターン",
    "targetTime": "対象時間",
    "timeTotal": "合計時間",
    "confirmedShift": "確定されたシフト",
    "hopeShift": "希望シフト",
    "director": "役職",
    "name": "名前",
    "9AM": "9:00",
    "10AM": "10:00",
    "11AM": "11:00",
    "12AM": "12:00",
    "1PM": "13:00",
    "2PM": "14:00",
    "3PM": "15:00",
    "4PM": "16:00",
    "5PM": "17:00",
    "6PM": "18:00",
    "7PM": "19:00",
    "8PM": "20:00",
    "9PM": "21:00",
    "10PM": "22:00",
    "11PM": "23:00",
    "12PM": "24:00"
  },
  "workingScheduleRegister": {
    "workScheduleChangeApplication": "勤務予定変更申請",
    "currentWorkSchedule": "現在の勤務予定",
    "shiftPatternName": "シフトパターン名",
    "patternTimeExample": "パターンA (8:00-17:00)",
    "timeExample": "12:00",
    "timeExample1": "13:00",
    "workScheduleAfterChange": "変更後の勤務予定",
    "selectFromShiftPatterns": "シフトパターンから選択する",
    "enterDirectly": "直接入力する"
  },
  "workingTimeRegister": {
    "leaveTime": "遅刻/早退時間",
    "leaveApplication": "遅刻/早退申請",
    "checkin": "出勤",
    "checkout": "退勤",
    "breakStart": "休憩開始",
    "breakEnd": "休憩終了",
    "breakTime": "休憩時間",
    "goOut": "外出",
    "goBack": "外出戻り",
    "overtime": "超過時間",
    "checkinPlace": "出勤場所",
    "workingOrganization": "勤務組織",
    "workingPlan": "勤務予定",
    "stampingRecord": "打刻実績",
    "lateTime": "遅刻時間",
    "apply": "申請",
    "planTime": "予定時間",
    "reason": "申請理由",
    "attach": "添付",
    "lateApplication": "遅刻申請",
    "earlyApplication": "早退申請",
    "deleteApplication": "申請削除"
  },
  "scheduledLaborMaster": "所定労働マスタ",
  "workPatternMaster": "勤務パターンマスタ",
  "workingStyleMaster2": "就労形態マスタ",
  "holidaySettings": "祝日設定",
  "scheduleLaborPattern": "所定労働パターン",
  "closeDayMaster": "締め日マスタ",
  "belong": "所属",
  "allAffiliations": "全ての所属",
  "express": "表示",
  "signUp": "新規登録",
  "japaneseHolidayRegistration": "日本の祝日登録",
  "exportExcel": "Excelで出力",
  "holidayName": "祝日名称",
  "edit": "編集",
  "delete": "削除",
  "date": "日付",
  "dateStamp": "日",
  "patternCode": "パターンコード",
  "patternName": "パターン名称",
  "copy": "コピー",
  "closeDayCode": "締め日コード",
  "closeDayName": "締め日マスタ名",
  "closeDayMasterSettings": "締め日マスタ設定",
  "closeDay": "締め日",
  "scheduledWorkingDays": "所定労働日数",
  "scheduledWorkingHours": "所定労働時間",
  "totalOutTime": "外出時間",
  "totalOfficeWorkTime": "オフィス",
  "totalRemoteWorkTime": "テレワーク",
  "legalWorkingHours": "法定労働時間",
  "hour": "時間",
  "minute": "分",
  "scheduledLaborPatternSetting": "所定労働パターン設定",
  "targetYear": "対象年",
  "end": "末",
  "hours": "時間",
  "registerMember": {
    "menu": "シフト管理",
    "shiftSubmissionStatus": "シフトの提出状況",
    "shiftRequest": "シフト依頼",
    "notifyMembersCreated": "メンバーに作成したシフトを通知する",
    "target": "対象のメンバー全てを表示させる",
    "targetPeriod": "対象期間",
    "textExample": "2021/04/01~2021/04/10",
    "addMember": "メンバーを追加す る",
    "workPattern": "勤務パターン"
  },
  "organizationalInformation": {
    "No": "No",
    "departmentCode": "部署コード",
    "departmentName": "部署名",
    "dateChangeTime": "日付け変更時間",
    "desc": "所属情報は、事務所、支店、店舗のような管理単位となります。基本的にはタイムレコーダーの設置単位毎に作成してください。",
    "title": "CYDAS PEOPLEの組織情報と連携している為、この画面で編集、削除はできません。",
    "buttonAdd": "日付け変更時間の登録",
    "affiliationInformation": "所属情報",
    "searchByDepartment": "部署コード、部署名で検索する"
  },
  "employeeInformation": {
    "employeeSettings": "従業員設定",
    "searchName": "名前で検索する",
    "allAffiliations": "全ての所属",
    "employee": "従業員",
    "display": "表示",
    "allEmploymentCategories": "全ての雇用区分",
    "displayedResults": "表示件数",
    "100Cases": "100件",
    "employeeCode": "社員コード",
    "loginID": "ログインID",
    "iCRegistration": "IC登録",
    "fingerRegistration": "指静脈登録",
    "dateOfJoining": "入社年月日",
    "emailAddress": "メールアドレス",
    "passwordLastUpdate": "パスワード最終更新日時"
  },
  "shiftManagement": "シフト管理",
  "requestSubmissionOfDesiredShift": "希望シフトの提出を依頼",
  "submitDesiredShift": "希望シフトを提出する",
  "departmentForShiftCreation": "シフト作成対象部署",
  "applicationSubmit": "提出対象期間",
  "submissionDeadline": "提出期限",
  "membersToSubmitted": "提出依頼対象メンバー",
  "shiftPlanList": "シフト計画一覧",
  "beingCreated": "シフトを作成する",
  "addTargetPerson": "対象者を追加する",
  "name": "名前",
  "organization": "組織",
  "requestHistory": "依頼履歴",
  "numberOfTargetPeople": "対象者数",
  "requestDateAndTime": "依頼日時",
  "cancel2": "取消",
  "numberOfPeopleToBeSubmitted": "提出依頼対象者数",
  "requestSubmissionOfDesiredShiftBtn": "希望シフト提出を依頼する",
  "person": "名",
  "buttonCancel": "キャンセル",
  "department": "部署",
  "selectAll": "全て選択",
  "choosing": "選択中",
  "pleaseSelectTheTargetPerson": "対象者を選択してください。",
  "choice": "選択",
  "departmentToBeShifted": "シフト対象部署",
  "submittedMembers": "提出済みのメンバー",
  "unSubmittedMembers": "未提出のメンバー",
  "promptToSendRequest": "提出の依頼を催促する",
  "settingApplication": "申請フロー設定",
  "approvedSetting": {
    "btnBasicFlowSetting": "基本フロー設定",
    "btnBasicFlowApplication": "基本フロー適用",
    "organizationCode": "組織コード",
    "organization": "組織",
    "basicFlowApply": "基本フロー適用",
    "individualFlowSetting": "個別フロー設定",
    "organizationName": "組織名"
  },
  "approvedFlow": {
    "settingApplication": "申請フロー設定（基本フロー設定）",
    "vacationRequest": "休暇申請",
    "approvedCopy": "申請フローコピー",
    "stampCorrection": "打刻修正申請",
    "btnAddApproved": "承認者を追加する",
    "toRegister": "登録する",
    "backToTheList": "一覧へもどる",
    "approvalFlowTextHeader": "承認フロー",
    "authorityRoleTextHeader": "承認者／承認権限ロール",
    "authorizer": "承認者",
    "privilegeRole": "権限ロール",
    "manager": "課長",
    "generalAffairs": "総務課"
  },
  "shiftSubmissionStatus": "シフトの提出状況",
  "stampPage": {
    "stampingMethod": "打刻方法",
    "stampingTime": "打刻時刻",
    "attendanceOrganizationCode": "出勤組織コード",
    "stampingPoint": "打刻地点",
    "changeOrganization": "出勤組織を変更する",
    "selectOrganization": "出勤組織を選択",
    "searchOrganizations": "出勤組織の検索"
  },
  "areYouSureDeleteTitle": "削除しますか?",
  "areYouSureDeleteBody": "このアイテムを削除してもよろしいですか?",
  "sureSubmissionRequestDelete": "提出依頼を取り消しますか？",
  "sureSubmissionRequestDeleteContent": "既にスタッフから提出された希望シフトも削除されてしまいます。",
  "submissionRequestBtn": "提出依頼を取り消す",
  "affiliationDepartment": "所属部署",
  "shiftsRequestedToBeSubmitted": "提出を依頼されているシフト",
  "desiredShiftInput": "希望シフト入力",
  "enterTheDesiredShift": "希望シフトを入力する",
  "handOver": "提出する",
  "desiredWorkPattern": "希望勤務パターン",
  "targetTime": "対象時間",
  "withdrawSubmission": "提出を取り下げる",
  "workPatternSetting": "１週間の勤務パターン設定",
  "stampingBreak": "打刻休憩",
  "notUse": "使用しない",
  "use": "使用する",
  "addABreak": "休憩を追加する",
  "breakType": "休憩種別",
  "standardWorkBreakTime": "標準勤務休憩時間",
  "breakTimeWhenStampedBreaks": "打刻休憩と自動休憩の重複時",
  "stampedBreak": "打刻休憩を取得する",
  "stampedBreakAsBreakTime": "打刻休憩を休憩時間とする",
  "totalBreakTimeIsBreakTime": "打刻休憩を合算したものを休憩時間とする",
  "autoBreakAndPatternBreaksOverlap": "休憩時間帯の重複",
  "workPatternsAsBreaks": "勤務パターンの休憩を休憩時間とする",
  "standardWorkAndPatternOverlap": "標準勤務休憩と勤務パターンの休憩の重複時",
  "breakTimeForStandardWork": "標準勤務の休憩を休憩時間とする",
  "stampBreakAndPatternOverlap": "打刻休憩と勤務パターンの休憩の重複時",
  "sumTimeStampedBreakAndWorkPatternBreak": "打刻休憩と勤務パターンの休憩を合算したものを休憩時間とする",
  "roundingCalculatingWeeksAcrossDeadlines": "締め日をまたぐ週の計算時の端数処理（少数第一位）",
  "pleaseEnterTheApplicableDate": "適用年月を入力してください",
  "nextMonth": "翌月",
  "closeBtn": "閉じる",
  "theWorkingHoursOfRegisteredShiftValid": "登録されるシフトの勤務時間が有効となります",
  "addWork": "勤務パターンを追加する",
  "workPatternCode": "勤務パターンコード",
  "workPatternName": "勤務パターン名",
  "ApplicableOra": "適用組織",
  "workHoursAndBreaks": "勤務時間と休憩時間",
  "halfHolidaySetting": "半休設定",
  "halfOfhalfHoliday": "勤務予定時間の半分を半休とする",
  "advanSetting": "詳細設定",
  "lateEarlyLeave": "遅刻/早退",
  "invalid": "無効",
  "valid": "有効",
  "halfADayOff": "午前半休",
  "halfAfternoon": "午後半休",
  "workingHou": "勤務時間帯",
  "none": "なし",
  "canBe": "あり",
  "deemedTime": "みなし時間",
  "contrasWorkHours": "日の契約労働時間×0.5（小数点以下切り上げ）",
  "breakTimes": "休憩時間帯",
  "breakTime": "休憩時間",
  "tokyoMar": "T000　東京丸の内店",
  "applicationApprovalContent": {
    "title": " 申請内容",
    "textArea": "退勤打刻忘れのため",
    "approverComment": "承認者コメント",
    "updateDateAndTime": "更新日時",
    "btnClose": "閉じる",
    "withdrawApplication": "申請を取り下げる ",
    "applicationStatus": "申請状況",
    "exampleTime": "-- : --",
    "applicationList": "申請一覧",
    "approvalRequest": "承認依頼",
    "ownApplication": "自身の申請",
    "hideSearchConditions": "検索条件の非表示",
    "attribute": "属性",
    "allAttribute": "すべての属性",
    "applicationTypeDisplaySelection": "申請種別表示選択",
    "all": "すべて",
    "approveAndReject": "承認 / 棄却",
    "detailApplication": "申請詳細",
    "nextApprover": "次回承認者",
    "applicationMessage": "申請メッセージ",
    "applicant": "申請者",
    "subsequentApplication": "事後申請"
  },
  "calendarCode": "会社カレンダーコード",
  "calendarName": "会社カレンダー名",
  "companyCalendarSettings": "会社カレンダー設定",
  "incoJapanHolidays": "日本の祝日を取込む",
  "japanHolidays": "日本の祝日",
  "setCompanyHolidays": "会社休日を設定する",
  "compHolidaysName": "会社休日名称",
  "actionEdit": "変更する",
  "actionCopy": "コピーする",
  "roundingOfMonthlyAggregation": "月次集計の丸め処理（集計時間の丸め）",
  "notRound": "丸めない",
  "round": "丸める",
  "achievementRoundingUnit": "実績の丸め単位",
  "achievementRounding": "実績の丸め処理",
  "truncate": "切り捨てる",
  "roundUp": "切り上げる",
  "rounding": "四捨五入",
  "distinguishingBetweenLegalHolidaysAndRegularHolidays": "法定休日と所定休日の区別",
  "notDistinguish": "区別しない",
  "distinguish": "区別する",
  "changeTheRegularHolidaysAcquiredWithinThe4thWeek": "当月4週内で取得した所定休日を法定休日に変更",
  "loginPage": {
    "systemLogin": "勤怠システムログイン",
    "id": "ID",
    "password": "パスワード",
    "holdLoginId": "ログインIDを保持",
    "forgetYourPassword": "パスワードをお忘れですか？",
    "login": "ログイン",
    "termsOfService": "利用規約",
    "privacyPolicy": "プライバシーポリシー",
    "agreeing": "に同意の上、ご利用ください。",
    "loginSuccess": "ログインしました",
    "loginFail": "IDまたはパスワードが間違いました"
  },
  "selectAppOrangBtn": "適用組織を選択する",
  "selectAppOrang": "適用組織を選択",
  "orangName": "組織名",
  "select": "選択する",
  "forgotPasswordPage": {
    "forgotPassword": "パスワード忘れ"
  },
  "logout": "ログアウト",
  "companyHolidayDeleted": "会社カレンダーが削除されました",
  "companyHolidayCouldNotDeleted": "会社カレンダーが削除できませんでした",
  "companyCalendarCreated": "会社カレンダーが作成されました",
  "companyClendarCreatedError": "会社カレンダーが作成できませんでした",
  "pleaseSetWithTheButtonOnTheRight": "右のボタンで設定してください",
  "llArgument": "不正な引数です。",
  "importTheFile": "ファイルをインポートする",
  "exportTheFile": "ファイルをエクスポートする",
  "basicFlowApplied": "基本フローが適用されました",
  "basicFlowCantApplied": "基本フローが適用できませんでした",
  "basicFlowHasCreated": "申請フローを更新しました",
  "basicFlowHasCreatedFail": "基本フローが作成できませんでした",
  "timezoneDivisionGroupCode": "時間帯区分グループコード",
  "timezoneDivisionGroupName": "時間帯区分グループ名",
  "timezoneDivisionGroupDetails": "時間帯区分グループ詳細",
  "timezoneDivisionGroupSetting": "時間帯区分グループ設定",
  "timezoneDivisionCode": "時間帯区分コード",
  "timezoneDivisionName": "時間帯区分名",
  "timezoneGroupHasBeenCreated": "時間帯グループが作成されました",
  "workingScheduleCode": "所定労働パターンコード",
  "workingScheduleName": "所定労働パターン名",
  "deteleTimeZoneGroupSuccessfully": "時間帯グループが削除されました",
  "deteleTimeZoneGroupFailure": "時間帯グループが削除できませんでした",
  "timezoneDivisionGroupUpdated": "時間帯グループが更新されました",
  "organizationRegistrationEditing": "組織登録・編集",
  "organizationHierarchySetting": "組織階層設定",
  "staffRegistrationEditing": "スタッフ登録・編集",
  "jobTitleSetting": "役職設定",
  "attendanceSetting": "勤怠設定",
  "timezoneDivisionMaster": "時間帯区分マスタ",
  "displaySettings": "表示設定",
  "errorAlertSettings": "エラー・アラート設定",
  "workStyleReformAlertSetting": "働き方改革アラート設定",
  "notificationSettings": "通知設定",
  "shiftRegistrationSettings": "シフト登録",
  "shiftCollectionMaster": "シフト収集マスタ",
  "userStatus": "利用者状況",
  "dateFormatError": "日付のフォマットで入力してください",
  "checkSelectedRoleList": "権限編集画面のアクセスには権限ロール選択は必要です",
  "shiftPlanName": "シフト計画名",
  "creationTargetPeriod": "作成対象期間",
  "shiftCreation": "シフト作成",
  "requestToSubmitDesiredShift": "希望シフト提出依頼",
  "submitted": "提出済み",
  "numberOfClients": "依頼者数",
  "confirmed": "確定済み",
  "notified": "通知済み",
  "hopeShift": "希望シフト",
  "planNewShift": "新規でシフトを計画する",
  "shiftPlanUnder": "作成中のシフト計画",
  "pastShiftPlans": "過去のシフト計画",
  "newShiftPlan": "新規シフト計画",
  "membersShifted": "シフト対象メンバー",
  "stationSquare": "五反田駅前店",
  "timezone": "時間帯",
  "copyMemberFromPreMonth": "前月のメンバーをコピーする",
  "attributeOrOccupation": "属性/職種",
  "doyouwantSendAShiftSubRequest": "シフト提出依頼を送信しますか？",
  "permissionsEdited": "権限が編集されました",
  "authorSettingTarget": "権限設定対象",
  "send": "送信する",
  "proceedToShiftCreation": "シフト作成に進む",
  "saveShiftCreation": "保存する",
  "cancelShiftCreation": "取り消す",
  "displayItemSettings": "表示項目の設定",
  "selectDisplayItems": "表示項目の選択",
  "displayItemList": "表示項目リスト",
  "columnName": "カラム名",
  "additionOfDisplay": "表示追加",
  "hide": "非表示",
  "permissionsEditedFail": "権限が編集できませんでした",
  "cardRegistration": {
    "cardRegistrationInputText": "カード番号を入力するかカードリーダーでカードを読み取って登録してください",
    "connectPaSoRi": "PaSoRiと接続",
    "staff": "スタッフ",
    "icCardUnregisteredOnly": "ICカード未登録のみ",
    "import": "インポート",
    "textSelectExample": "T000　東京丸の内店",
    "textSelectExample2": "アルバイトA",
    "numberCardExample": "949234232"
  },
  "fixedShift": "確定シフト",
  "notificationDateAndTime": "通知日時",
  "submissionStatus": "提出状況",
  "batchConfirmation": "一括確定",
  "cancelAllBatchConfirmation": "一括確定解除",
  "bulkNotification": "一括通知",
  "cancelAllNotifications": "一括通知解除",
  "keep": "保存",
  "excelDownload": "Excelダウンロード",
  "import": "インポート",
  "sure": "確定",
  "notice": "通知",
  "kinds": "種別",
  "hope": "希望",
  "confirmToRelease": "確定解除",
  "noticeCancellation": "通知解除",
  "diligent": "勤",
  "stop": "休",
  "unSelect": "未選択",
  "reflectYourWishesInYourWorkSchedule": "希望を勤務予定に反映",
  "selectFromWorkPatterns": "勤務パターンから選択",
  "addManually": "手入力で追加",
  "legalHolidays": "法定休日とする",
  "makeItDesignatedHoliday": "所定休日とする",
  "employeeStatus": "雇用形態",
  "position": "役職",
  "confirmShift": {
    "deadlineForSubmit": "希望シフト提出期限",
    "submissionDeadline": "提出期限を一括設定する",
    "submissionDeadlineIndividually": "提出期限を個別で設定する",
    "titlePopupCancelShift": "シフト提出依頼を取消しますか？",
    "subtitlePopupCancelShift": "シフト提出依頼を取消しますか？"
  },
  "bulkCancellation": "一括取消",
  "bulkTransmission": "一括送信",
  "personalSettingApply": {
    "successMessage": "個別適用ポリシーが設定されました",
    "buttonAdd": "新規社員設定適用",
    "successMessageCopy": "申請フローがコピーされました",
    "errorMessageCopy": "申請フローがコピーできませんでした",
    "updateSuccessMessage": "申請フロが更新されました",
    "updateErrorMessage": "申請フロが更新されました",
    "substituteLeaveApplication": "代休申請",
    "transferAttendance": "振替出勤申請",
    "absenceApplication": "欠勤申請"
  },
  "shiftPlanRequest": {
    "shiftName": "シフト名",
    "planning": "作成中",
    "decided": "確定",
    "announced": "通知済",
    "achieve": "終了"
  },
  "returnScreen": "戻る",
  "distinguishingBetweenOfficeAndTelework": "オフィス出勤とテレワーク出勤の区別",
  "markWhenYouGoOut": "外出開始打刻時間",
  "goingBackStampingTime": "外出戻り打刻時間",
  "startOut": "外出開始",
  "goBackStamping": "外出戻り打刻",
  "goOutSetting": "外出設定",
  "managementOfOutingTime": "外出時間の管理",
  "handlingOfOutingTime": "外出時間の取扱い",
  "useOutingStamping": "外出打刻を使用する",
  "notUseOutingStamping": "外出打刻を使用しない",
  "notIncludedInWorkingHours": "労働時間に含めない",
  "includedInWorkingHours": "労働時間に含める",
  "takeAnAutomaticBreak": "勤務パターンの休憩が未設定の場合、自動休憩を取得する",
  "distinguishingBetweenOvertimeAndNonStatutoryWorking": "所定外労働時間と法定外労働時間の区別",
  "settingRegularWorkingHoursPerDay": "日の所定労働時間設定",
  "scheduledWorkingHoursAsPrescribedWorking": "勤務予定時間を日の所定労働時間とする",
  "prescribedWorkingInPrescribedWorkingPattern": "所定労働パターンで設定した日の所定労働時間を指定する",
  "scheduledWorkingIsPrescribedAnPrescribedWorkingPattern": "所定労働パターンで設定した日の所定労働時間を限度として、勤務予定時間を日の所定労働時間とする",
  "theShiftPlanHasBeenDeleted": "シフト計画が削除されました",
  "theShiftPlanCouldNotBeDeleted": "シフト計画が削除できませんでした",
  "aShiftSubmissionRequestHasBeenSent": "シフト提出依頼が送信されました",
  "theShiftSubmissionRequestCouldNotBeSent": "シフト提出依頼が送信できませんでした",
  "addManual": "手動で追加",
  "impossibility": "勤務不可",
  "workingOrganizationName": "勤務組織名",
  "staffName": "スタッフ名",
  "desiredWorkingHours": "希望勤務時間",
  "fixedWorkPattern": "確定勤務パターン",
  "fixedWorkHours": "確定勤務時間",
  "workingOrganization": "勤務組織",
  "workingPatternHours": "勤務時間",
  "duplicate": "複製",
  "deletionOfWorkStyleMaster": "就労形態マスタの削除",
  "workingStyleMasterName": "就労形態名",
  "toRemove": "を削除します。",
  "checkTheStampingRecord": "打刻実績を確認する",
  "pleaseEnterYourName": "お名前を入力してください",
  "displayConditions": "表示条件",
  "narrowDown": "絞り込む",
  "resetMember": "リセット",
  "closeUp": "閉じる",
  "previousDayOfStamps": "この時刻より前の「出勤打刻」は、前日の打刻として扱われます。",
  "whatIsModifiedWork": "変形労働とは：毎日の所定労働時間を管理者が設定し、日、週ごとに時間外労働を計算します。休日労働時間、深夜労働時間も計算されます。",
  "notChange": "変更しない",
  "change": "変更する",
  "workingHour": "勤務時間",
  "fixedCheckoutNote1": "「固定する」を選択した場合、勤務予定の終了時刻に勤務実績の退勤を丸めます。勤務予定の終了時刻より退勤時刻が遅い場合のみ適用されます。",
  "fixedCheckoutNote2": "「勤務予定終了時間から定めた時間まで丸める」を選択した場合、勤務予定終了時刻から設定時間を過ぎて退勤された場合は勤務予定終了時刻に退勤時間を丸めます。",
  "fixedCheckoutNote": "「固定する」を選択した場合、勤務予定の終了時刻に勤務実績の退勤を丸めます。勤務予定の終了時刻より退勤時刻が遅い場合のみ適用されます。「勤務予定終了時間から定めた時間まで丸める」を選択した場合、勤務予定終了時刻から設定時間を過ぎて退勤された場合は勤務予定終了時刻に退勤時間を丸めます。",
  "next": "次へ",
  "roundingOfActualTime": "実績時間の丸め",
  "desBreakTimeSetting": "休憩時間の設定がない場合は、就労形態の休憩設定に準じます。</br>ただし、休憩設定で、勤務パターンの休憩が有効になる設定の場合、休憩なしと扱われます。",
  "timeCardNew": {
    "employeeNumber": "社員番号",
    "viewAggregates": "集計を表示する",
    "totalNumber": "日数集計",
    "timeTabulation": "時間集計",
    "attendanceLock": "勤怠ロック　未",
    "targetMonth": "対象月",
    "collectiveIdentity": "一括本人確定",
    "myAttendanceRecord": "わたしの勤怠実績",
    "everyoneAttendanceRecord": "みんなの勤怠実績",
    "numberOfHolidays": "休日数",
    "numberOfDayHolidays": "有休取得日数",
    "numberOfDayTakeSpecial": "特休（有給）取得日数",
    "numberOfDayTakeSpecialHoliday": "特休（無給）取得日数",
    "numberLateArrivals": "遅刻回数",
    "numberOfEarlyDepartures": "早退回数",
    "numberOfWaits": "待機回数",
    "GoingOutTime": "外出時間"
  },

  "useOfStampingWhenGoingOut": "外出時の打刻の使用",
  "time2": "時間",
  "monthlyWorkingHour": "月の労働時間が",
  "detailedSettingsOutsideSpecialExtraHours": "特別割増時間外 詳細設定",
  "automaticBreakAdvancedSettings": "自動休憩 詳細設定",
  "calculate": "計算する",
  "notCalculate": "計算しない",
  "breakStartTime": "休憩開始打刻",
  "goOutAndStartEngraving": "外出開始打刻",
  "breakEndTime": "休憩終了打刻",
  "applicationDateFind": "申請日",
  "remandFind": "差し戻し",
  "cancellation": "申請取り消し",
  "workRecordApplication": "勤務実績申請",
  "workRecordApplicationCheckTime": "待機手当申請",
  "pleaseEnterTheWorkingStyle": "就労形態を入力してください",
  "pleaseEnterTheDepartment": "部署を入力してください",
  "nameApplication": "山田 祐樹",
  "permanentEmployeeFixedA": "正社員固定A",
  "generalAffairsDivision": "総務課",
  "expressCondition": "表示条件",
  "period": "期間",
  "attendanceOrganization": "出勤組織",
  "attribute": "属性",
  "thereIsAnError": "エラーあり",
  "thereIsAnAlert": "アラートあり",
  "reset": "リセット",
  "unsupportedApplication": "未対応申請",
  "error": "エラー",
  "weekdayCommutingDays": "平日出勤日数",
  "holidayWorkDays": "休日出勤日数",
  "numberOfAbsenteeDays": "欠勤日数",
  "holidayDays": "休日日数",
  "numberOfSubstituteHolidays": "代休日数",
  "thereAreDaysToObtain": "有給取得日数",
  "numberOfDaysOfSpecialLeaveTakenPaid": "特別休暇（有給）取得日数",
  "numberOfDaysOfSpecialLeaveTakenUnpaid": "特別休暇（無給）取得日数",
  "waitingDays": "待機日数",
  "overtimeWorkingHours": "所定外労働時間",
  "nonStatutoryWorkingHours": "法定外労働時間",
  "outsideSpecialExtraHours": "特別割増時間外①",
  "outsideSpecialExtra": "特別時間外割増",
  "nightWorkingTime": "深夜時間",
  "legalHolidayWorkingHours": "法定休日労働時間",
  "legalHolidayMidnightTime": "法定休日深夜時間",
  "paidDeemedTime": "有給みなし時間",
  "actualWorkingHours": "実労働時間",
  "sumWorkingHours": "総労働時間",
  "seeMore": "さらに見る",
  "deleteWorkPattern": "勤務パターンの削除",
  "workPattern": "勤務パターン名",
  "pleaseEnterYourOrganization": "組織を入力してください",
  "pleaseEnterYourJobTitle": "役職を入力してください",
  "pleaseEnterTheAttribute": "属性を入力してください",
  "pleaseEnterTheAttendanceOrganization": "出勤組織を入力してください",
  "actionDuplicate": "複製する",
  "basicFlowNote": "申請種類別に申請承認フローを設定してください。部署別に変更が必要な場合は、「部署別フロー」にて設定を行ってください。",
  "formatDownload": "フォーマットのダウンロード",
  "numberOfApprovedFlows": "承認フロー数",
  "initialize": "初期化",
  "basicFlow": "基本フロー",
  "flowByDepartment": "部署別フロー",
  "basicFlowApplicationType": "申請書種別",
  "404": {
    "homePage": "HOMEに戻る",
    "desc404": "申し訳ありませんが、",
    "descNotFound404": "ページが見つかりませんでした。",
    "descNotFoundMain": "お探しのページが削除されていたり、名前が変更されていたり、",
    "descNotFound": "  一時的に利用できなくなっている可能性があります。",
    "signOut": "サインアウト"
  },
  "500": {
    "textHeaderSystem": "System Error",
    "descSystem": "しばらくしてもこの画面が表示される場合は、社内管理者へお問い合わせください。"
  },
  "approvedPersonFlowNote": "申請種類別に申請承認フローを設定してください。部署別に変更が必要な場合は、「部署別フロー」にて設定を行ってください。申請承認フローをリセットすると基本フローになります。",
  "copyTheApplicationFlow": "申請フローをコピーする",
  "youCanCopyTheApplicationFlowOfOtherApplications": "他の申請書の申請フローをコピーすることができます。",
  "primaryApprovalFlow": "次承認フロー",
  "authorizer": "承認者",
  "save": "保存する",
  "applyBtn": "適用する",
  "personalDesignation": "個人指定",
  "privilegeRole": "権限ロール",
  "reasonForApplication": "申請の理由",
  "times": "回",
  "approverMessage": "承認者メッセージ",
  "exampleTextRecord": "ヘルプで別部署へ出勤しました。上長の事前口頭承認済み。",
  "timeApprover": "次承認者",
  "2timeApprover": "2次承認者",
  "personal": "個人",
  "privilegeRoleAdministrator": "権限ロール：管理者",
  "sendBack": "差し戻す",
  "rejectRecord": "棄却する",
  "approveRecord": "承認する",
  "deleteTimezoneModal": {
    "title": "時間帯区分の削除",
    "body": "時間帯区分「{name}」を削除します。"
  },
  "invalidInput": "入力エラーがあります",
  "1": "一",
  "2": "二",
  "3": "三",
  "4": "四",
  "5": "五",
  "6": "六",
  "7": "七",
  "8": "八",
  "9": "九",
  "10": "十",
  "theApplicationHasBeenCreated": "申請が作成されました",
  "workPatternAlreadyDeleted": "すでに削除された勤務パターンです",
  "workStyleMasterAlreadyDeleted": "すでに削除された就労形態マスタです",
  "becauseOfTheWaitingCharge": "待機担当のため",
  "basicFlowAlreadyDeleted": "すでに削除された基本フローです",
  "pleaseEnterTheStampDateAndTime": "打刻日時を入力してください",
  "personalFlowSetting": {
    "descCreate": "申請書別に申請承認フローを設定できます。個別設定しない申請書は基本フローが適用されます。申請承認フローをリセットすると基本フローになります。",
    "individualSetting": "個別設定",
    "business": "東京事業本部"
  },
  "isBasicFlow": "基本",
  "isPersonalFlow": "変更",
  "updateSuccessMessagePersonalFlowSetting": "部署フローが更新されました",
  "updateErrorMessagePersonalFlowSetting": "部署フローが更新されました",
  "createSuccessMessagePersonalFlowSetting": "部署フローが作成されました",
  "createErrorMessagePersonalFlowSetting": "部署フローが作成できませんでした",
  "recalculate": "再計算",
  "messageRecalculateSuccess": "再計算が開始されました",
  "messageRecalculateFail": "再計算が開始できませんでした",
  "basicFlowHasCopied": "基本フローがコピーされました",
  "bulkApproval": "一括承認",
  "bulkRemand": "一括差し戻し",
  "workScheduleChangeApplication": "勤務予定変更申請",
  "buttonApply": "申請する",
  "goOut": "外出",
  "goBack": "外出戻り",
  "scheduledOvertime": "予定超過時間",
  "attendancePlace": "出勤場所",
  "checkinUnit": "出社組織",
  "application": "変更申請",
  "draft": "下書き",
  "deleted": "取り消",
  "thereIsNoData": "データがありません",
  "approveSuccess": "申請が承認されました",
  "notifyWorkRecord": "申請中の勤務実績への追加申請はできません。一度申請を取り消してから再度申請をあげてください。",
  "reapply": "再申請する",
  "cancelTheApplication": "申請を取り消す",
  "longNightWorkingHours": "深夜労働時間",
  "workRecord": "勤務実績",
  "stampType": "打刻種別",
  "time3": "時刻",
  "fixedWorkResults": "勤務実績の修正",
  "fixedWorkResultsNote": "勤務実績を修正・追加する場合は、「追加する」ボタンをクリックして行を追加してください。勤務実績を削除する場合はチェックを入れて申請をしてください。",
  "breakTimeCorrection": "休憩時間修正",
  "breakTimeTotal": "休憩時間（合算）",
  "pleaseEnterAnApplicationMessage": "申請メッセージを入力してください",
  "unit": "部署",
  "checkInOffice": "オフィス出勤",
  "rejectSuccess": "申請が差し戻されました",
  "deleteApplicationSuccess": "申請を取り消しました",
  "resendApplicationSuccess": "申請が再申請されました",
  "resendApplicationFail": "申請が再申請できませんでした",
  "ReasonForAbsenteeism": "欠勤の理由",
  "administratorComments": "承認者メッセージ",
  "reasonForAbsenteeism": "欠勤の理由",
  "numberOfFlows": "フロー数",
  "approveStatus": "承認済み",
  "approveMultiple": "複数の申請を承認します",
  "telework": "テレワーク",
  "office": "オフィス",
  "allRequiredFields": "すべて必須項目です。",
  "other": "+その他",
  "oneDayConsecutiveWorkingHours": "1日の連続最長勤務時間",
  "afterThisTimeTheStampScreenSwitchesToWorkMode": "この時間を経過すると打刻画面は出勤モードに切り替わります。",
  "correspondenceDateEndTime": "対応日時",
  "message": "メッセージ",
  "cannotBeApproved": "承認・差し戻し不可",
  "copyOf": "のコピー",
  "ifYouSelectNotDistinguishAllWillBeLegalHolidays": "区別しないを選択した場合は全て法定休日となります。",
  "previousTextTimeCard": "前",
  "nextDayTextTimeCard": "翌",
  "extraHours1": "特別時間外割増１",
  "extraHours2": "特別時間外割増２",
  "extraHours3": "特別時間外割増３",
  "selectTheStartDateToApplyTheConfigurationChange": "設定の変更を適用する開始年月を選択してください。キャンセルすると前の設定画面に戻ります。",
  "overtime": "超過時間",
  "lateApplication": "遅刻申請",
  "earlyApplication": "早退申請",
  "planTime": "予定時間",
  "behindTime": "遅早時間",
  "attach": "添付",
  "leaveTime": "遅刻/早退時間",
  "lateTime": "遅刻時間",
  "lateOrEarlyApplication": "遅刻/早退申請",
  "approvalRequest": "承認依頼",
  "myApplication": "わたしの申請",
  "substituteApplication": {
    "numberOfDays": "代休残日数",
    "checkExpirationDate": "有効期限を確認する",
    "acquisitionUnit": "取得単位",
    "allDay": "全日",
    "halfDay": "半日",
    "timeOff": "時間休",
    "expirationDateTitle": "取得可能な代休の有効期限",
    "holidayCommutingDay": "休日出勤日",
    "availableHolidays": "取得可能な代休",
    "dateExpiry": "有効期限",
    "from": "から",
    "makeVacation": "時間分を休暇とする",
    "defineTimeMorning": "午後半休   8:00 ～ 12:00",
    "defineTimeAfternoon": "午前半休 13:00 ～ 17:00"
  },
  "noDataAllow": "データがありません",
  "transferAttendanceApplication": "振替出勤申請",
  "transitAttendanceDay": "振替出勤日",
  "transferHoliday": "振替休日",
  "makeVacation": "時間分を休暇とする",
  "defineTimeMorning": "午後半休   8:00 ～ 12:00",
  "defineTimeAfternoon": "午前半休 13:00 ～ 17:00",
  "icCardRegistration": "ICカード登録",
  "icCardNumber": "ICカード番号",
  "cardNumberIdm": "カード番号（IDｍ）",
  "scanAndRegisterIdm": "カードリーダーでカードをスキャンして登録します。 カード番号を入力することもできます。",
  "theIcCardReader": "ICカードリーダーが機能しています。",
  "deleteRegistered": "登録したICカードを削除しますか？",
  "messageDeleteRegister": "モーダルのメッセージ。",
  "individualAuthorityRole": "個人、権限ロール：管理者",
  "individualAuthorityRoleAdministrator": "権限ロール：管理者",
  "calculationLegalWorkingHoursInWeeks": "週単位での法定外労働時間の計算",
  "deleteIcCard": "ICカードが削除されました",
  "registerIcCard": "ICカードが登録されました",
  "specialExtraAmountOutside": "特別割増時間外",
  "specialExtraAmountOutside1": "特別割増時間外①",
  "specialExtraAmountOutside2": "特別割増時間外②",
  "specialExtraAmountOutside3": "特別割増時間外③",
  "departmentCode": "部署コード",
  "fullWidth1": "１",
  "fullWidth2": "２",
  "fullWidth3": "３",
  "fullWidth4": "４",
  "fullWidth5": "５",
  "fullWidth6": "６",
  "fullWidth7": "７",
  "fullWidth8": "８",
  "fullWidth9": "９",
  "fullWidth10": "１０",
  "theDayOfStamps": "この時刻より前の「出勤打刻」は、当日の打刻として扱われます。",
  "deleteAchievement": "この実績を削除する",
  "waitingAllowanceDeleteApplication": "待機手当申請 - 削除申請",
  "waitingAllowanceTargetBeDelete": "削除対象",
  "displayedResults": "表示件数",
  "no": "No",
  "title": "タイトル",
  "member": "メンバー",
  "shiftPlan": "シフト計画",
  "allAreRequiredFields": "すべてが入力必須項目です。",
  "memberSelection": "メンバー選択",
  "shiftPlanNote": "対象部署のメンバーが表示されています。必要に応じて、追加・削除をしてください。対象メンバーが0名の場合は、シフトを確定できません。",
  "addOtherMembers": "他のメンバーを追加する",
  "targetDepartment": "対象部署",
  "targetPeriod": "対象期間",
  "resetToBasicFlow": "基本フローにリセットしました",
  "workInHoliday": {
    "targetDay": "対象日（休日出勤日）",
    "scheduledWorkHolidays": "休日出勤勤務予定",
    "scheduledSubstituteVacation": "代休取得予定",
    "scheduledDate": "代休取得予定日",
    "requiredScheduledDate": "※〇/〇～〇/〇の期間で代休取得予定日を設定してください"
  },
  "employeeNumber": "社員番号",
  "importShiftData": "シフトデータのインポート",
  "importTheCSVFileAndRegisterTheShift": "CSVファイルをインポートしてシフトを登録してください。",
  "exportShiftPlanNote": "シフトは対象部署にて利用可能な勤務パターンや休日のみを登録できます。データは２行目移行から取り込まれます。",
  "exportShiftPlanNote2": "対象期間のシフトデータを編集して更新する場合は、「シフトデータ」から現在のシフトデータを出力してください。",
  "templateFile": "テンプレート",
  "currentShiftData": "シフトデータ",
  "fileSelection": "ファイルの選択",
  "fileSizeLimit": "ファイルサイズ上限",
  "extension": "拡張子",
  "shiftsCanBeAddedManuallyLater": "シフトは後から手動での追加や、",
  "overwritingUpdateByFileUploadPossible": "ファイルアップロードによる上書き更新が可能です。",
  "enterPlaceAdministratorComment": "承認者メッセージを入力してください",
  "breakOfWordPattern": "勤務パターンの休憩",
  "wordPatternBreakNotSet": "勤務パターンの休憩（未設定の場合は自動休憩を反映）",
  "selectWhatToTreat": "自動休憩と勤務パターンの休憩が重複した際に、休憩として扱うものを選択してください",
  "multipleApplication": "複数の申請を差し戻します",
  "availableWorkPatterns": "利用可能な勤務パターン",
  "refer": "参照する",
  "targetPerson": "対象者",
  "theNameHasBeenSelected": "名が選択されています",
  "backToIndex": "一覧に戻る",
  "making": "作成中",
  "resolved": "確定",
  "expired": "終了",
  "removeShift": "シフトの削除",
  "switchUnitMemberSelectionsUpdate": "部署を切り替えると、下部のメンバー選択の一覧が更新されます。",
  "settingTimeRecoder": {
    "workingStyle" : "就労形態",
    "browserStamping" : "ブラウザ打刻",
    "terminalStamping": "端末打刻",
    "faceRecognitionStamp" : "顔認証打刻",
    "toApprove" : "許可する",
    "notAllowed": "許可しない",
    "usagePermissionSetting": "使用許可設定",
    "timeClockType": "タイムレコーダ種別",
    "standardWork": "標準勤務",
    "standardWorkShift": "標準勤務　シフト",
    "transformationLabor":"変形労働",
    "discretionaryLabor": "裁量労働",
    "partTimeJob": "アルバイト",
    "messageCreatSuccess": "タイムレコーダー設定が作成されました",
    "messageUpdateSuccess": "タイムレコーダー設定が更新されました"
  },
  "confirmationOfCapturedData": "取り込みデータの確認",
  "theFileIsBeingRead": "ファイルを読み込み中です。しばらくお待ちください。",
  "takeIn": "取り込む",
  "allLines": "すべての行",
  "thisCSVFileContainsAnError": "このCSVファイルには、エラーが含まれています。下記のエラー内容を確認し、修正したファイルを選択し直してください。",
  "ifYouWantToImportOnlyValidRows": "エラーを含まない有効な行のみをインポートする場合は、「取り込む」を押してください。",
  "validLines": "有効な行",
  "inValidLines": "無効な行",
  "importShiftPlanSuccess": "インポートが完了しました",
  "importShiftPlanError": "取り込みに失敗しました。もう一度お試しください。",
  "errorContents": "エラー内容",
  "theFileSizeLimitHasBeenExceeded": "ファイルサイズ上限を超えています",
  "importingRowData": "行のデータを取り込み中です",
  "abort": "中止する",
  "alertSetting": {
    "timeAttendanceData": "勤怠データ",
    "workSchedule":"勤務予定あり打刻実績なし",
    "noHolidayCommuting": "休日出勤申請なし",
    "noOvertimeApplication": "超過勤務申請なし",
    "duplicateAttendanceData": "勤怠データの重複",
    "duplicateStamps": "打刻の重複",
    "workStyleAlert": "働き方アラート",
    "upperLimitOvertimeWork": "時間外労働の上限",
    "upperLimitOvertimeHolidayWork" : "時間外労働と休日労働の上限",
    "upperLimitOvertimeWorkProvisions": "特別条項に関する時間外労働の上限",
    "errorNotification": "エラー通知",
    "alertNotification": "アラート通知",
    "doNotNotify": "通知しない",
    "messageCreatSuccess": "エラー・アラート設定が作成されました",
    "messageUpdateSuccess": "エラー・アラート設定が更新されました"
  },
  "vacationCode": "休暇コード",
  "vacationName": "休暇名",
  "abbreviation": "略称",
  "vacationType": "休暇タイプ",
  "set10FullWidthCharacters": "※全角10文字以内で設定してください",
  "setNameNotOverlap": "※他のマスタと名称が重複しないように設定してください",
  "advancedSetting": "詳細設定",
  "annualPaidLeaveSetting": "年次有給休暇設定",
  "paidCalculationStandard": "有給算出基準",
  "additionToTheNumberWorkingDays": "勤務日数への加算",
  "toAdd": "加算する",
  "notAdd": "加算しない",
  "handlingOfPaidDeemedTime": "有給みなし時間の取扱い",
  "includedWorkingHours": "労働時間に含む",
  "notIncludedWorkingHours": "労働時間に含まない",
  "takingHalfDayLeave": "半日単位の休暇取得",
  "notAllowed": "許可しない",
  "toApprove" : "許可する",
  "halfDayAcquisitionTimeSetting": "半日単位の取得時間設定",
  "acquisitionTimeSetScheduledWorkingHours": "取得予定日における勤務予定時間で設定した取得時間とする",
  "contractedWorkingHoursBeAcquisitionTime": "契約労働時間の半分を取得時間とする",
  "contractWorkingHoursStaffMaster": "※スタッフマスタの ”契約労働時間” にて設定してください",
  "takeHourlyVacation": "時間単位の休暇取得",
  "maximumNumberOfHoursForAcquisition": "時間単位の取得上限時間数",
  "setUniformly": "一律で設定する",
  "setIndividually": "個別で設定する",
  "pleaseSetMaximumNumberOfHours": "※スタッフマスタの ”時間単位の取得上限時間数” にて設定してください",
  "allowAcquisitionByTime": "時間までの取得を許可する",
  "maximumNumberOfDaysForPaidHourlyLeave": "時間単位有給の上限取得日数",
  "takeVacationDuringTheTrialPeriod": "試用期間中の休暇取得",
  "additionType": "加算/減算種別",
  "additionMethod": "加算方式",
  "subtractionMethod": "減算方式",
  "automaticPaidManagement": "自動有給管理",
  "toManage": "管理する",
  "notManage": "管理しない",
  "validityPeriod": "使用有効期間",
  "fromTheDateOfGrant": "付与日より",
  "validForTheYear": "年有効",
  "maximumHoldingDays": "最高保有日数",
  "initialGrantDays": "初回付与日数",
  "setTheInitialGrantInALumpSumAccordingOfMonth": "初回付与を有給起算日からの経過月数に応じて一括で設定する",
  "fromThePaidStartDate": "有給起算日から",
  "and": "で",
  "grantDay": "日付与する",
  "setTheInitialGrantInALumpSumAccordingForEach": "初回付与を有給起算日毎に個別で設定する",
  "thePaidStartDateIs": "有給起算日が",
  "inTheCaseOf": "の場合は",
  "from": "から",
  "to": "に",
  "numberOfDaysGrantedFromTheNextTimeOnwards": "次回以降付与日数",
  "grantFromTheNextTimeOnwardsFromTheFirstGrantDate": "次回以降付与を初回付与日から",
  "giveTo": "に付与する",
  "grantedEveryYearFromTheNextTime": "次回以降付与を、毎年",
  "withHolidayPayAndDaysTable": "有休付与日数表",
  "withHolidayPaymentAndCalculationMethodOfDays": "有休付与日数計算方法",
  "useNumberPerWeek": "週の契約労働日数を使用",
  "useActualNumberOfWorkingDays": "実労働日数を使用",
  "colorSettings": "カラー設定",
  "displayImage": "表示イメージ",
  "annualPaid": "年次有休",
  "scheduledWorkingDaysPerWeek": "週所定労働日数",
  "scheduledWorkingDaysPerYear": "年所定労働日数",
  "moreThanYear": "年以上"
}
