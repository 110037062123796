
import { computed, defineComponent, ref, watch } from 'vue';
import '@/views/layouts/layout.scss';
import ToastApp from '@/components/ToastApp.vue';
import { useI18n } from 'vue-i18n';
import nameCompanyLogo from '@/assets/svg/nav/name-company.svg';
import Ring from '@/assets/svg/nav/ring.svg';
import { useStore } from '@/store';
import backNavigation from '@/assets/svg/nav/backNavigation.svg';
import {
  deleteCookie,
  handleCheckAuthen,
  PP_EMPLOYEE_ID,
} from '@/util/composables';
import { useLoginAccountStore } from '@/store/modules/login-account';
import BaseIcon from '@/components/Icons/BaseIcon.vue';
import router from '@/router';
import SysLogo from '@/components/Icons/SysLogo.vue';
import NotificationIcon from '@/components/Icons/Notification.vue';
import CydasLogo from '@/components/Icons/CydasLogo.vue';
import LineIcon from '@/components/Icons/LineIcon.vue';
import Avatar from '@/components/share/Avatar/index.vue';
import { getListAvatarUser } from '@/api/composables/useGetListAvatarUser';

import './layout.scss';
import ConstantsRouter from '@/constants/router';
import RectangleIcon from '@/components/Icons/RectangleIcon.vue';
import RectangleLastIcon from '@/components/Icons/RectangleLastIcon.vue';
import { useToggleSidebarStore } from '@/store/modules/sidebar';

export default defineComponent({
  components: {
    RectangleLastIcon,
    RectangleIcon,
    BaseIcon,
    ToastApp,
    SysLogo,
    NotificationIcon,
    CydasLogo,
    LineIcon,
    Avatar,
  },
  setup: function () {
    const accountStore = useLoginAccountStore();
    const notifyNumber = ref(99);
    const userInfo = computed(() => accountStore.state.myAccount);
    const nameAvatar = ref([]);
    const getAvatarEmployee = async () => {
      const res = await getListAvatarUser([userInfo.value.pp_employee_id]);
      nameAvatar.value = res.data;
    };
    getAvatarEmployee();
    const i18n = useI18n();
    const t = i18n.t;
    const store = useStore();
    const profile = computed(() => store.state.auth.profile);

    const nameCompany = {
      label: t('nameCompany'),
      fontSize: '14',
      icon: {
        src: nameCompanyLogo,
        alt: t('nameCompany'),
      },
      fontWeight: 'bold',
      color: '#333333',
      path: '/',
    };

    const isActiveNavMenu = (item) => {
      if (item.child) {
        return item.child?.some((e) =>
          router.currentRoute.value.path.includes(e.path)
        );
      }
      return item.path && router.currentRoute.value.path.includes(item.path);
    };

    const isActiveNavSubMenu = (path) =>
      path && router.currentRoute.value.path.includes(path);

    const goToPage = (nav) => {
      if (nav.path) {
        router.push(nav.path);
      }
    };

    const isShowPopupProfile = ref(false);
    const handleTogglePopupProfile = () => {
      isShowPopupProfile.value = !isShowPopupProfile.value;
    };
    const handleHiddenPopupProfile = () => {
      isShowPopupProfile.value = false;
    };
    const navMenu = [
      { icon: 'nav-bell-ringing', value: 0 },
      { icon: 'nav-menu', value: 1 },
      { icon: 'setting', value: 2 },
    ];
    const navSubMenu = {
      1: {
        title: 'メニュー',
        content: [
          {
            text: '勤怠実績',
            icon: 'nav-attendance',
            path: `/new${ConstantsRouter.TimeCard}`,
          },
          {
            text: '働き方アラート',
            icon: 'nav-alert-working-style-master',
          },
          {
            path: `/new${ConstantsRouter.Application}`,
            text: '申請 / 承認',
            icon: 'nav-user-focus',
          },
          {
            text: 'シフト確認',
            icon: 'nav-calendar-check',
          },
          {
            text: 'シフト作成',
            icon: 'nav-calendar-plus',
            path: `/new${ConstantsRouter.ShiftBeingCreatedNew}`,
          },
          {
            text: 'エラー/アラート',
            icon: 'nav-warning-circle',
          },
          {
            text: 'データ出力',
            icon: 'nav-archive-tray',
          },
          {
            text: '休暇管理',
            icon: 'nav-airplane',
          },
          {
            text: '月次処理',
            icon: 'nav-calendar',
          },
        ],
      },
      2: {
        title: '設定',
        content: [
          {
            text: '組織・スタッフマスタ',
            icon: 'nav-user-three',
            child: [
              {
                text: '組織登録・編集',
                path: `/new${ConstantsRouter.OrganizationalInformation}`,
              },
              { text: '組織階層設定' },
              {
                text: 'スタッフ登録・編集',
                path: `/new${ConstantsRouter.EmployeeInformation}`,
              },
              { text: '役職設定' },
            ],
          },
          {
            text: '勤怠マスタ',
            icon: 'nav-clock',
            child: [
              {
                text: '休暇マスタ',
                path: `/new${ConstantsRouter.YearPaidHoliday}`,
              },
              {
                text: '所定労働マスタ',
                path: `/new${ConstantsRouter.Holidays}`,
              },
              {
                text: '勤務パターンマスタ',
                path: `/new${ConstantsRouter.WorkPatternMaster}`,
              },
              {
                text: '就労形態マスタ',
                path: `/new${ConstantsRouter.WorkingStyleMaster}`,
              },
              {
                text: '時間帯区分マスタ',
                path: `/new${ConstantsRouter.TimeZoneSessionList}`,
              },
            ],
          },
          {
            text: '管理者設定',
            icon: 'nav-id-card',
            child: [
              {
                text: '権限設定',
                path: `/new${ConstantsRouter.AminSettingRoleList}`,
              },
              {
                text: '申請フロー設定',
                path: `/new${ConstantsRouter.ApprovedSettingFlow}`,
              },
              { text: '申請設定' },
              {
                text: 'タイムレコーダー設定',
                path: `/new${ConstantsRouter.TimeRecoderList}`,
              },
              {
                text: 'ICカード登録',
                path: `/new${ConstantsRouter.RegisterIcCard}`,
              },
              {
                text: '月次集計出力レイアウト設定',
              },
            ],
          },
          {
            text: '表示・通知設定',
            icon: 'nav-megaphone',
            child: [
              { text: '表示設定' },
              {
                text: 'エラー・アラート設定',
                path: `/new${ConstantsRouter.AlertSetting}`,
              },
              { text: '働き方アラート設定' },
              { text: '通知設定' },
            ],
          },
          {
            text: '勤務予定マスタ',
            icon: 'nav-calendar-blank',
            child: [{ text: '表示設定' }, { text: 'シフト収集マスタ' }],
          },
          {
            text: 'システムの利用状況やシステム全体の設定や確認',
            icon: 'nav-wrench',
            child: [{ text: '利用者状況' }],
          },
        ],
      },
    };
    const chooseMenu = ref(0);
    const initialDefaultChooseMenu = () => {
      if (
        navSubMenu['1'].content.filter((item) => {
          return (
            item.path && router.currentRoute.value.path.includes(item.path)
          );
        }).length
      )
        chooseMenu.value = 1;
      if (
        navSubMenu['2'].content.filter((item) => {
          return item.child?.some(
            (e) => e.path && router.currentRoute.value.path.includes(e.path)
          );
        }).length
      ) {
        chooseMenu.value = 2;
      }
    };
    initialDefaultChooseMenu();

    const isExpandMenu = ref(false);
    const onClickMenuItem = (item) => {
      if (item.value === 0) {
        router.push(`/new${ConstantsRouter.Stamp}`);
      }
      chooseMenu.value = item.value;
      isExpandMenu.value = true;
    };
    const isShowSubmenu = ref({});
    const handleLogout = () => {
      deleteCookie(PP_EMPLOYEE_ID);
      const isLogout = true;
      handleCheckAuthen(isLogout);
    };
    // update toggle sidebar to store
    const sidebarStore = useToggleSidebarStore();
    watch(isExpandMenu, () => {
      sidebarStore.commit('toggleSidebar', isExpandMenu.value);
    });

    return {
      t,
      router,
      backNavigation,
      store,
      Ring,
      nameCompany,
      profile,
      navMenu,
      navSubMenu,
      chooseMenu,
      isExpandMenu,
      userInfo,
      notifyNumber,
      isShowPopupProfile,
      isShowSubmenu,
      onClickMenuItem,
      goToPage,
      handleTogglePopupProfile,
      handleHiddenPopupProfile,
      handleLogout,
      isActiveNavMenu,
      getAvatarEmployee,
      isActiveNavSubMenu,
      nameAvatar,
    };
  },
});
