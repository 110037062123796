
import { defineComponent, watchEffect, ref, PropType, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import Table from '@/components/share/Table/Table.vue';
import MultiSelect from '@/components/share/Dropdown/MultiSelect.vue';
import RadioInputGroup from '@/components/share/Radio/RadioInputGroup.vue';
import { SETTING_REGISTER_FLOW } from '@/constants/workFlowSetting';
import { ApplicationFlowType } from '@/model/PersonalFlows';
import { usePersonalFlowStore } from '@/store/modules/personal-flows';
import InputSearchEmployee from '@/components/share/Input/InputSearchEmployee.vue';

export default defineComponent({
  components: {
    InputSearchEmployee,
    MultiSelect,
    RadioInputGroup,
    Table,
  },
  props: {
    data: {
      type: Array as PropType<ApplicationFlowType[]>,
      require: true,
    },
    parentKey: { type: Number, require: true },
    approval_level: {
      type: Number,
      required: true,
    },
    listRole: {},
    errors: {
      default: {},
    },
    validated: {
      default: false,
    },
  },
  setup(props, { emit }) {
    const i18n = useI18n();
    const t = i18n.t;
    const store = usePersonalFlowStore();
    const headersBasicFlow = [
      { text: '', width: '48px', value: 'action' },
      { text: t('authorizer'), value: 'authorizer' },
    ];
    const roleOptionList = [
      {
        label: t('personalDesignation'),
        value: 0,
      },
      {
        label: t('privilegeRole'),
        value: 1,
      },
    ];

    const dataTable = ref<ApplicationFlowType[]>();
    watchEffect(() => {
      if (props.data?.length) {
        // filter data same approval_level
        dataTable.value = props.data.filter(
          (item) => item.approval_level == props.approval_level
        );
        dataTable.value?.push({
          approval_level: null,
          level_order: null,
          pp_employee_ids: null,
          role_ids: null,
          setting_register_flow: null,
        });
      } else {
        dataTable.value = [
          {
            approval_level: null,
            level_order: null,
            pp_employee_ids: null,
            role_ids: null,
            setting_register_flow: null,
          },
        ];
      }
    });
    const removeApplicationFlow = (flowIndex, value) => {
      const data = props.data?.filter((item) => item !== value);
      store.commit('updateApplicationFlowDetails', {
        parentIndex: props.parentKey,
        data,
      });
    };
    const addApplicationFlow = () => {
      if (dataTable.value) {
        const emptyData = {
          setting_register_flow: 0,
          approval_level: props.approval_level,
          level_order: dataTable.value?.length,
          role_ids: [],
          pp_employee_ids: [],
        };
        const data = JSON.parse(
          JSON.stringify(props.data?.filter((item) => item.approval_level))
        );
        data.push(emptyData);
        store.commit('updateApplicationFlowDetails', {
          parentIndex: props.parentKey,
          data,
        });
      }
    };
    const errorsMessage = ref({});
    watch(props, () => {
      errorsMessage.value = props.errors;
    });
    watchEffect(() => {
      emit('update:errors', errorsMessage.value);
    });

    return {
      t,
      SETTING_REGISTER_FLOW,
      headersBasicFlow,
      dataTable,
      roleOptionList,
      errorsMessage,
      removeApplicationFlow,
      addApplicationFlow,
    };
  },
});
