
import { defineComponent, onBeforeUnmount, ref } from '@vue/runtime-core';
import DatePickerIcon from '@/assets/svg/DatePickerIcon.svg';
import iconChevronLeft from '@/assets/svg/iconChevronLeftSingle.svg';
import iconChevronRight from '@/assets/svg/iconChevronRightSingle.svg';
import CalendarDay from '@/components/share/Calendar/CalendarDay.vue';
import { toRefs, watch, watchEffect } from 'vue';
import moment from 'moment';
import { DATE_FORMAT } from '@/constants/date';
import Input from '@/components/share/Input/index.vue';

export default defineComponent({
  components: {
    Input,
    CalendarDay,
  },

  props: {
    placeholder: {
      type: String,
      default: DATE_FORMAT,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    format: {
      type: String,
      default: DATE_FORMAT,
    },
    width: {
      type: String,
    },
    icon: {},
    modelValue: { type: String, default: '' },
    isFixed: {
      type: Boolean,
      default: false,
    },
    heightToAlwayBot: {
      type: Number,
      default: 0,
    },
    errorServer: {
      type: Array,
      default: () => [],
    },
    required: {
      type: Boolean,
      default: false,
    },
    validated: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    const isShowCalendar = ref(false);
    const { modelValue, format } = toRefs(props);
    const calendar = ref<HTMLDivElement>();
    const popupCalendar = ref<HTMLDivElement>();
    const heightCalendar = 355;
    const heightToShowBot = 58;

    const top = ref(0);
    const left = ref(0);
    const openCalendar = () => {
      if (calendar.value) {
        top.value =
          window.innerHeight - calendar.value.getBoundingClientRect().height >
          50
            ? calendar.value.getBoundingClientRect().top +
              calendar.value.getBoundingClientRect().height
            : calendar.value.getBoundingClientRect().top + 400;
        left.value =
          window.innerWidth - calendar.value.getBoundingClientRect().width > 50
            ? calendar.value.getBoundingClientRect().left
            : calendar.value.getBoundingClientRect().left;
      }
      isShowCalendar.value = true;
    };
    const closeCalendar = () => {
      isShowCalendar.value = false;
    };
    let date = ref(
      modelValue.value ? moment(modelValue.value).format(format.value) : ''
    );
    const onChangeInput = (e) => {
      date.value = e.target.value;
    };
    watch(props, () => {
      date.value = modelValue.value;
    });
    watchEffect(() => {
      emit('update:modelValue', date.value);
    });
    watchEffect(() => {
      if (isShowCalendar.value && calendar?.value && popupCalendar.value) {
        // show popup calendar
        const distanceElementToBottom =
          window.innerHeight - calendar.value.getBoundingClientRect().top;
        if (!props.isFixed) {
          if (distanceElementToBottom < heightCalendar) {
            popupCalendar.value.style.bottom = heightToShowBot + 'px';
          } else {
            popupCalendar.value.style.bottom = 'unset';
          }
        } else {
          if (props.heightToAlwayBot > heightCalendar + heightToShowBot) {
            if (distanceElementToBottom < heightCalendar) {
              popupCalendar.value.style.bottom = heightToShowBot + 'px';
            } else popupCalendar.value.style.bottom = 'unset';
          } else {
            popupCalendar.value.style.bottom = 'unset';
          }
        }
      }
    });

    const errorMessage = ref();
    watchEffect(() => {
      emit('update:errors', errorMessage.value);
    });
    onBeforeUnmount(() => {
      emit('update:errors', '');
    });

    return {
      isShowCalendar,
      DatePickerIcon,
      iconChevronLeft,
      iconChevronRight,
      date,
      top,
      left,
      calendar,
      popupCalendar,
      errorMessage,
      closeCalendar,
      openCalendar,
      onChangeInput,
    };
  },
});
