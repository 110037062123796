
import { computed, defineComponent, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import Label from '@/components/Label.vue';
import Select from '@/components/Select.vue';
import Button from '@/components/Button.vue';
import Table from '@/components/Table.vue';
import RadioGroup from '@/components/RadioGroup.vue';
import GradationButton from '@/components/GradationButton.vue';
import router from '@/router';
import {
  handleUpdateBasicFlowSetting,
  useGetDetailAppBasicFlowSetting,
  useGetDetailAppPersonalFlowSetting,
  useGetListAppFormDefines,
  useGetSearchRoles,
} from '@/api/composables/approvalSettings';
import { isValidateForm } from '@/util/useValidateForm';
import { showToast } from '@/util/useToastMessage';
import ConstantsRouter from '@/constants/router';
import { getSearchEmploy } from '@/api/composables/useGetSearchEmployee';
import { watchEffect } from '@vue/runtime-core';

export default defineComponent({
  name: 'ApprovedSettingFlow',
  components: {
    Label,
    Select,
    Button,
    Table,
    RadioGroup,
    GradationButton,
  },
  setup() {
    const i18n = useI18n();
    const t = i18n.t;
    const id = router.currentRoute.value.query.id;
    const idDefine = router.currentRoute.value.query.defineId;
    const nameOrg = router.currentRoute.value.query.name;
    const unitId = router.currentRoute.value.query.unitId;
    const isPersonalFlowScreen =
      router.currentRoute.value.path === ConstantsRouter.ApprovedPersonalFlow;
    const isBasicFlowScreen =
      router.currentRoute.value.path === ConstantsRouter.ApprovedSettingFlow;
    if (isPersonalFlowScreen) {
      getSearchEmploy({
        unitId: unitId,
      }).then((res) => {
        listApprovalEmployee.value = res.data.map((el) => {
          return {
            value: el.id,
            text: el.name,
          };
        });
      });
    }
    if (isBasicFlowScreen) {
      getSearchEmploy({}).then((res) => {
        listApprovalEmployee.value = res.data.map((el) => {
          return {
            value: el.id,
            text: el.name,
          };
        });
      });
    }

    const {
      appPersonalSettingDetail,
      handleGetDetailAppPersonalFlowSetting,
    } = useGetDetailAppPersonalFlowSetting();
    if (id && idDefine) {
      handleGetDetailAppPersonalFlowSetting(id);
    }
    const { listAppFormDefines } = useGetListAppFormDefines();
    const {
      appBasicFlowSettingDetail,
      handleDetailAppBasicFlowSetting,
      defineId,
    } = useGetDetailAppBasicFlowSetting();
    const listApprovalEmployee = ref([]);
    const { listApprovalRole } = useGetSearchRoles();
    const keyEl = ref(0);
    const app_form_define_id = ref('');

    watch(listAppFormDefines, () => {
      if (
        listAppFormDefines &&
        listAppFormDefines.value &&
        listAppFormDefines.value[0]
      ) {
        keyEl.value += 1;
        app_form_define_id.value = listAppFormDefines.value[0].value;
        if (isBasicFlowScreen) {
          handleDetailAppBasicFlowSetting(listAppFormDefines.value[0].value);
        }
      }
    });
    watchEffect(() => {
      if (appPersonalSettingDetail.value) {
        appBasicFlowSettingDetail.value = appPersonalSettingDetail.value;
      }
    });
    const defineIdCopy = ref('');

    const AuthorityRoleOptions = [
      {
        value: 0,
        label: t('approvedFlow.authorizer'),
      },
      {
        value: 1,
        label: t('approvedFlow.privilegeRole'),
      },
    ];
    const approvedSettingFlowHeaders = [
      {
        text: t('approvedFlow.approvalFlowTextHeader'),
        value: 'approved_flow_title',
        width: '20%',
      },
      {
        text: t('approvedFlow.authorityRoleTextHeader'),
        value: 'setting_register_flow',
        width: '60%',
      },
      {
        text: t('add'),
        value: 'plus',
        width: '10%',
        background: '#f2f8fc',
      },
      {
        text: t('attendanceDataHeaders.removeAction'),
        value: 'delete',
        width: '10%',
        background: '#f2f8fc',
      },
    ];
    const errors = ref({});
    const backToApprovedSetting = () => {
      router.push(ConstantsRouter.ApprovedSetting);
    };
    const handleSelectAppFlowCopy = (value) => {
      defineIdCopy.value = value;
      if (isBasicFlowScreen) {
        handleDetailAppBasicFlowSetting(value);
      } else {
        router.push({
          query: { defineId: value, id: id, name: nameOrg },
        });
        handleGetDetailAppPersonalFlowSetting(id);
      }
    };
    const handleSelectAppFlow = (value) => {
      if (isBasicFlowScreen) {
        handleDetailAppBasicFlowSetting(value);
      } else {
        router.push({
          query: { defineId: value, id: id, name: nameOrg },
        });
        handleGetDetailAppPersonalFlowSetting(id);
      }
    };
    const disabledAdd = computed(() => {
      const cloneAppBasicFlowSettingDetail = appBasicFlowSettingDetail.value
        ? [...appBasicFlowSettingDetail.value]
        : [];
      return (
        [
          ...new Set(
            cloneAppBasicFlowSettingDetail.map((item) => item.approval_level)
          ),
        ].length === 10
      );
    });
    const handleAddApproved = () => {
      if (
        appBasicFlowSettingDetail.value &&
        appBasicFlowSettingDetail.value.length >= 0
      ) {
        const disabledAddApproved =
          [
            ...new Set(
              [...appBasicFlowSettingDetail.value].map(
                (item) => item.approval_level
              )
            ),
          ].length === 10;
        if (disabledAddApproved) return;
        //add max 10 record approval_level
        const approval_level_next =
          appBasicFlowSettingDetail.value[
            appBasicFlowSettingDetail.value.length - 1
          ]?.approval_level + 1 || 0;
        appBasicFlowSettingDetail.value.push({
          approval_level: approval_level_next,
          level_order: 0,
          setting_register_flow: 0,
          approval_role_id: '',
          approval_employee_id: '',
        });
      } else if (!appBasicFlowSettingDetail.value) {
        appBasicFlowSettingDetail.value = [];
        //add max 10 record approval_level
        const approval_level_next =
          appBasicFlowSettingDetail.value[
            appBasicFlowSettingDetail.value.length - 1
          ]?.approval_level + 1 || 0;
        appBasicFlowSettingDetail.value.push({
          approval_level: approval_level_next,
          level_order: 0,
          setting_register_flow: 0,
          approval_role_id: '',
          approval_employee_id: '',
        });
      }
    };
    const handleAddApproveSameLevel = (item, index) => {
      if (item.setting_register_flow === 0 && !item.approval_employee_id)
        return;
      if (item.setting_register_flow === 1 && !item.approval_role_id) return;
      appBasicFlowSettingDetail.value.splice(index + 1, 0, {
        ...item,
        id: '',
        setting_register_flow: 0,
        approval_role_id: '',
        approval_employee_id: '',
      });
    };
    const isDisableButtonPersonFlow = computed(() => {
      return (
        !appBasicFlowSettingDetail.value ||
        appBasicFlowSettingDetail.value.length <= 0
      );
    });

    const handleDeleteApprove = (index) => {
      if (
        appBasicFlowSettingDetail.value &&
        appBasicFlowSettingDetail.value.length === 1
      )
        return;
      appBasicFlowSettingDetail.value.splice(index, 1);
    };
    const isWorkFlowId = ref();

    const handleRegister = () => {
      if (isValid()) {
        if (isBasicFlowScreen) {
          updateBasicFlowSetting();
        } else if (id || isWorkFlowId.value) {
          // updatePersonalFlowSetting();
        } else if (!isWorkFlowId.value) {
          // createPersonalFlowSetting();
        }
      }
    };
    // const createPersonalFlowSetting = () => {
    //   const { workFlowId, create } = handleCreatePersonalFlowSetting({
    //     application_define_id: app_form_define_id.value,
    //     application_flow_details: appBasicFlowSettingDetail.value,
    //     unit_id: unitId,
    //   });
    //   create.then((error) => {
    //     if (error) {
    //       showToast('error', t('basicFlowHasCreatedFail'));
    //     } else {
    //       isWorkFlowId.value = workFlowId.value;
    //       showToast('success', t('basicFlowHasCreated'));
    //     }
    //   });
    // };

    // const updatePersonalFlowSetting = () => {
    //   const { update } = handleUpdatePersonalFlowSetting({
    //     unit_workflow_id: isWorkFlowId.value ? isWorkFlowId.value : id,
    //     application_define_id: app_form_define_id.value,
    //     application_flow_details: appBasicFlowSettingDetail.value,
    //   });
    //   update.then((error) => {
    //     if (error) {
    //       showToast('error', t('personalSettingApply.updateErrorMessage'));
    //     } else {
    //       showToast('success', t('personalSettingApply.updateSuccessMessage'));
    //     }
    //   });
    // };

    const updateBasicFlowSetting = () => {
      const { update } = handleUpdateBasicFlowSetting({
        application_define_id: app_form_define_id.value,
        application_flow_details: appBasicFlowSettingDetail.value,
      });
      update.then((error) => {
        if (error) {
          showToast('error', t('basicFlowHasCreatedFail'));
        } else {
          showToast('success', t('basicFlowHasCreated'));
        }
      });
    };
    const validated = ref(false);
    const isValid = () => {
      validated.value = true;
      return isValidateForm(errors);
    };
    // const copyAppFlowPersonal = () => {
    //   const { update } = handleUpdatePersonalFlowSetting({
    //     unit_workflow_id: isWorkFlowId.value ? isWorkFlowId.value : id,
    //     application_define_id: app_form_define_id.value,
    //     application_flow_details: appBasicFlowSettingDetail.value,
    //   });
    //   update.then((error) => {
    //     if (error) {
    //       showToast('error', t('personalSettingApply.errorMessageCopy'));
    //     } else {
    //       showToast('success', t('personalSettingApply.successMessageCopy'));
    //     }
    //   });
    // };

    return {
      t,
      approvedSettingFlowHeaders,
      listAppFormDefines,
      app_form_define_id,
      appBasicFlowSettingDetail,
      AuthorityRoleOptions,
      listApprovalRole,
      listApprovalEmployee,
      defineId,
      defineIdCopy,
      errors,
      validated,
      appPersonalSettingDetail,
      keyEl,
      backToApprovedSetting,
      handleSelectAppFlowCopy,
      handleSelectAppFlow,
      handleAddApproved,
      handleAddApproveSameLevel,
      handleDeleteApprove,
      handleRegister,
      isPersonalFlowScreen,
      disabledAdd,
      nameOrg,
      unitId,
      isWorkFlowId,
      isDisableButtonPersonFlow,
      idDefine,
      id,
      // copyAppFlowPersonal,
    };
  },
});
