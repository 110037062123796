import {
  NationalHolidayDatas,
  NationalHolidayDetail,
  NationalHolidayJa,
  NationalHolidaysParamsCreateUpdates,
} from '@/model/NationalHolidays';
import { ref } from 'vue';
import {
  getNationalHolidaysList,
  deleteNationalHoliday,
  getListNationalHolidayJa,
  createNationalHolidays,
  getNationalHolidaysDetail,
  updateNationalHolidays,
} from '@/api/nationalHolidays';
import moment from 'moment';
import { convertErrorFromServerToJson } from '@/util/useValidateForm';
import { DATE_FORMAT } from '@/constants/date';

export default function (params) {
  const nationalHolidaysList = ref<NationalHolidayDatas[]>([]);
  const pagination = ref({});
  const loading = ref(false);

  async function getNationalList(params) {
    try {
      loading.value = true;
      const res = await getNationalHolidaysList(params);

      switch (res.status) {
        case 200:
          nationalHolidaysList.value = res.data.data;
          pagination.value = res.data.meta ? res.data.meta.pagination : {};
          break;
        default:
          nationalHolidaysList.value = [];
          pagination.value = {};
          break;
      }
    } catch (error) {
      console.error(error);
    } finally {
      loading.value = false;
    }
  }

  getNationalList(params);

  return { nationalHolidaysList, loading, getNationalList, pagination };
}

export function deleteNationalHolidayID(id) {
  return {
    deleteData: (async () => {
      const response = await deleteNationalHoliday(id);
      switch (response.status) {
        case 200:
          return null;
        default:
          return response.data;
      }
    })(),
  };
}

export function handleGetListNationalHolidayJa(year) {
  const nationalHolidaysJaList = ref<NationalHolidayJa[]>();
  const loadingNationalHolidayJa = ref(false);
  async function loadListNationalHolidayJp(year) {
    try {
      loadingNationalHolidayJa.value = true;
      const res = await getListNationalHolidayJa(year);
      switch (res.status) {
        case 200:
          nationalHolidaysJaList.value = res.data.map((el) => {
            return {
              national_holiday_name: el.name,
              national_holiday_date: el.date
                ? moment(el.date).format(DATE_FORMAT)
                : '',
            };
          });
          break;
        default:
          nationalHolidaysJaList.value = [];
          break;
      }
    } catch (error) {
      console.error(error);
    } finally {
      loadingNationalHolidayJa.value = false;
    }
  }

  loadListNationalHolidayJp(year);
  return {
    nationalHolidaysJaList,
    loadingNationalHolidayJa,
    loadListNationalHolidayJp,
  };
}

export function handleCreateNationalHolidays(
  params: NationalHolidaysParamsCreateUpdates
) {
  const loadingCreate = ref(false);
  const errors = ref({});
  const errorsCommon = ref<string>();
  return {
    create: (async () => {
      loadingCreate.value = true;
      const response = await createNationalHolidays(params);
      switch (response.status) {
        case 200:
          return null;
        default:
          if (response.data.errors[0].source) {
            errors.value = convertErrorFromServerToJson(response.data.errors);
          } else {
            errorsCommon.value = response.data.errors[0].title;
          }
          loadingCreate.value = false;
          return {
            errors,
            errorsCommon,
          };
      }
    })(),
  };
}
export function handleUpdateNationalHolidays(
  id: string,
  params: NationalHolidaysParamsCreateUpdates
) {
  const errors = ref({});
  const errorsCommon = ref<string>();
  return {
    update: (async () => {
      const response = await updateNationalHolidays(id, params);
      switch (response.status) {
        case 200:
          return null;
        default:
          if (response.data.errors[0].source) {
            errors.value = convertErrorFromServerToJson(response.data.errors);
          } else {
            errorsCommon.value = response.data.errors[0].title;
          }
          return {
            errors,
            errorsCommon,
          };
      }
    })(),
  };
}

export function handleGetNationalHolidayDetail(id) {
  const nationalHolidaysDetail = ref<
    NationalHolidayDetail | Record<string, never>
  >();
  const loadingDetailNationalHolidays = ref(false);

  async function getDetail(id) {
    if (!id) return;
    try {
      loadingDetailNationalHolidays.value = true;
      const res = await getNationalHolidaysDetail(id);
      switch (res.status) {
        case 200:
          nationalHolidaysDetail.value = res.data.data;
          break;
        default:
          nationalHolidaysDetail.value = {};
          break;
      }
    } catch (error) {
      console.error(error);
    } finally {
      loadingDetailNationalHolidays.value = false;
    }
  }

  getDetail(id);
  return { nationalHolidaysDetail, loadingDetailNationalHolidays, getDetail };
}
