<template>
  <svg
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M25.3346 17.0003C25.3346 21.6027 21.6037 25.3337 17.0013 25.3337C16.0808 25.3337 15.3346 26.0799 15.3346 27.0003C15.3346 27.9208 16.0808 28.667 17.0013 28.667C23.4446 28.667 28.668 23.4436 28.668 17.0003C28.668 16.0799 27.9218 15.3337 27.0013 15.3337C26.0808 15.3337 25.3346 16.0799 25.3346 17.0003Z"
      fill="#FF8C84"
    />
    <path
      d="M15.334 7.00016C15.334 6.07969 16.0802 5.3335 17.0007 5.3335H32.0007C32.9211 5.3335 33.6673 6.07969 33.6673 7.00016C33.6673 7.92064 32.9211 8.66683 32.0007 8.66683H17.0007C16.0802 8.66683 15.334 7.92064 15.334 7.00016Z"
      fill="#22AEE5"
    />
    <path
      d="M0.333984 27.0002C0.333984 26.0797 1.08018 25.3335 2.00065 25.3335H17.0007C17.9211 25.3335 18.6673 26.0797 18.6673 27.0002C18.6673 27.9206 17.9211 28.6668 17.0007 28.6668H2.00065C1.08018 28.6668 0.333984 27.9206 0.333984 27.0002Z"
      fill="#FDA53D"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.0007 8.66683C12.3983 8.66683 8.66732 12.3978 8.66732 17.0002C8.66732 17.9206 7.92113 18.6668 7.00065 18.6668C6.08018 18.6668 5.33398 17.9206 5.33398 17.0002C5.33398 10.5568 10.5573 5.3335 17.0007 5.3335C17.9211 5.3335 18.6673 6.07969 18.6673 7.00016C18.6673 7.92064 17.9211 8.66683 17.0007 8.66683Z"
      fill="#007BC3"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.0007 11.167C17.4609 11.167 17.834 11.5401 17.834 12.0003L17.834 17.0003C17.834 17.4606 17.4609 17.8337 17.0007 17.8337C16.5404 17.8337 16.1673 17.4606 16.1673 17.0003L16.1673 12.0003C16.1673 11.5401 16.5404 11.167 17.0007 11.167Z"
      fill="#333333"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.0013 16.1668L20.3346 16.1668C20.7949 16.1668 21.168 16.5399 21.168 17.0002C21.168 17.4604 20.7949 17.8335 20.3346 17.8335L17.0013 17.8335C16.5411 17.8335 16.168 17.4604 16.168 17.0002C16.168 16.5399 16.5411 16.1668 17.0013 16.1668Z"
      fill="#333333"
    />
    <g style="mix-blend-mode: soft-light">
      <path
        d="M18.6673 7.00016C18.6673 7.92064 17.9211 8.66683 17.0007 8.66683C16.0802 8.66683 15.334 7.92064 15.334 7.00016C15.334 6.07969 16.0802 5.3335 17.0007 5.3335C17.9211 5.3335 18.6673 6.07969 18.6673 7.00016Z"
        fill="white"
      />
    </g>
    <path
      d="M7.00065 33.667C6.08018 33.667 5.33398 32.9208 5.33398 32.0003L5.33398 17.0003C5.33398 16.0798 6.08018 15.3337 7.00065 15.3337C7.92113 15.3337 8.66732 16.0798 8.66732 17.0003L8.66732 32.0003C8.66732 32.9208 7.92113 33.667 7.00065 33.667Z"
      fill="#003993"
    />
    <g style="mix-blend-mode: soft-light">
      <path
        d="M8.66732 17.0002C8.66732 17.9206 7.92113 18.6668 7.00065 18.6668C6.08018 18.6668 5.33398 17.9206 5.33398 17.0002C5.33398 16.0797 6.08018 15.3335 7.00065 15.3335C7.92113 15.3335 8.66732 16.0797 8.66732 17.0002Z"
        fill="white"
      />
    </g>
    <path
      d="M27.0007 18.667C26.0802 18.667 25.334 17.9208 25.334 17.0003L25.334 2.00032C25.334 1.07985 26.0802 0.333658 27.0007 0.333658C27.9211 0.333658 28.6673 1.07985 28.6673 2.00032L28.6673 17.0003C28.6673 17.9208 27.9211 18.667 27.0007 18.667Z"
      fill="#F06451"
    />
    <g style="mix-blend-mode: soft-light">
      <path
        d="M28.6673 17.0002C28.6673 17.9206 27.9211 18.6668 27.0007 18.6668C26.0802 18.6668 25.334 17.9206 25.334 17.0002C25.334 16.0797 26.0802 15.3335 27.0007 15.3335C27.9211 15.3335 28.6673 16.0797 28.6673 17.0002Z"
        fill="white"
      />
    </g>
    <g style="mix-blend-mode: soft-light">
      <path
        d="M18.6673 27.0002C18.6673 27.9206 17.9211 28.6668 17.0007 28.6668C16.0802 28.6668 15.334 27.9206 15.334 27.0002C15.334 26.0797 16.0802 25.3335 17.0007 25.3335C17.9211 25.3335 18.6673 26.0797 18.6673 27.0002Z"
        fill="white"
      />
    </g>
  </svg>
</template>
