import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, resolveDirective as _resolveDirective } from "vue"

const _hoisted_1 = {
  class: "form-select relative",
  ref: "dropdown"
}
const _hoisted_2 = { class: "p-2 cursor-default" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Input = _resolveComponent("Input")
  const _directive_click_outside = _resolveDirective("click-outside")

  return _withDirectives((_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", {
      ref: "selectTags",
      class: _ctx.disabled ? 'pointer-events-none' : ''
    }, [
      _createVNode(_component_Input, {
        onClick: _ctx.toggleSelect,
        placeholder: _ctx.placeholder,
        modelValue: _ctx.value,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.value = $event)),
        onChange: _ctx.onChange,
        "icon-prepend": 
          _ctx.isShowOption ? 'cp-icon-chevron-up' : 'cp-icon-chevron-down'
        ,
        validated: _ctx.validated,
        "error-server": _ctx.errorServer,
        required: _ctx.required,
        disabled: _ctx.disabled,
        "is-required-label": _ctx.isRequiredLabel,
        label: _ctx.label,
        "sub-label": _ctx.subLabel,
        "note-message": _ctx.noteMessage,
        errors: _ctx.errorMessage,
        "onUpdate:errors": _cache[2] || (_cache[2] = ($event: any) => (_ctx.errorMessage = $event)),
        colorIcon: _ctx.colorIcon,
        height: _ctx.height
      }, null, 8, ["onClick", "placeholder", "modelValue", "onChange", "icon-prepend", "validated", "error-server", "required", "disabled", "is-required-label", "label", "sub-label", "note-message", "errors", "colorIcon", "height"])
    ], 2),
    _withDirectives(_createVNode("div", {
      class: "form-select--option",
      style: { width: _ctx.widthOptionSelect + 'px' },
      ref: "listOptionDropdown"
    }, [
      (!_ctx.optionsSelectFilter.length)
        ? _renderSlot(_ctx.$slots, "noData", { key: 0 }, () => [
            _createVNode("ul", _hoisted_2, _toDisplayString(_ctx.t('noData')), 1)
          ])
        : (_openBlock(true), _createBlock(_Fragment, { key: 1 }, _renderList(_ctx.optionsSelectFilter, (item, index) => {
            return (_openBlock(), _createBlock("ul", {
              class: [{
          active: index === _ctx.indexSelected,
          'disabled-option': typeof item.disabled == 'boolean' && item.disabled,
        }, "form-select--item"],
              key: index,
              onClick: ($event: any) => (_ctx.selectOption(item))
            }, [
              _createTextVNode(_toDisplayString(typeof item === 'object' ? item[_ctx.itemText] : item) + " ", 1),
              _renderSlot(_ctx.$slots, "default", {
                item: item,
                index: index
              })
            ], 10, ["onClick"]))
          }), 128))
    ], 4), [
      [_vShow, _ctx.isShowOption]
    ])
  ], 512)), [
    [_directive_click_outside, _ctx.clickOutside]
  ])
}