
import ConstantsRouter from '@/constants/router';
import router from '@/router';
import { computed, defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import useGetWaitingData, {
  postWaitingAllowanceApplication,
} from '@/api/composables/waitingAllowance';
import ApplicationApproveFlowDetail from '@/components/Application/ApplicationApproveFlowDetail.vue';
import ReasonUpdateStatusApplication from '@/components/Application/ReasonUpdateStatusApplication.vue';
import { useRoute } from 'vue-router';
import BackPreviousPage from '@/components/share/BackPreviousPage/index.vue';
import ReasonCreateApplication from '@/components/Application/ReasonCreateApplication.vue';
import {
  APPLICATION_MY_REQUEST,
  APPLICATION_STATUS_TYPE,
  APPLICATION_ALLOWANCE_DELETE,
} from '@/constants/typeApplication';
import { showToast } from '@/util/useToastMessage';
import { isValidateForm } from '@/util/useValidateForm';
import { useLoginAccountStore } from '@/store/modules/login-account';
import { formatTimeDate } from '@/util/time';
export default defineComponent({
  components: {
    ApplicationApproveFlowDetail,
    ReasonUpdateStatusApplication,
    BackPreviousPage,
    ReasonCreateApplication,
  },
  setup() {
    const i18n = useI18n();
    const t = i18n.t;
    const route = useRoute();
    const isMyApplication = computed(
      () => route.query.is_my_application as string
    );
    const myApplicationDefine = computed(
      () => route.query.application_define_id as string
    );
    const myApplicationFromId = computed(
      () => route.query.application_form_id as string
    );
    // waiting allow delete
    const isWaitingAllowDelete =
      router.currentRoute.value.query.delete == APPLICATION_ALLOWANCE_DELETE
        ? true
        : false;
    const dataRecordApplicationDelete = ref({
      pp_employee_id: '',
      unit_id: '',
      application_reason: '',
      application_define_id: '',
      checkin_unit_id: '',
      target_date: '',
      wait_numbers: 0,
      is_request_delete: true,
      application_form_to_delete_id: '',
    });
    const applicationModalHeaders = [
      {
        text: i18n.t('overtimeRegister.targetDate'),
        value: 'target_date',
        width: 200,
      },
      {
        text: i18n.t('labelAttendance'),
        value: 'attendance_work',
        width: 200,
      },
      {
        text: i18n.t('timeCardNew.numberOfWaits'),
        value: 'number_of_wait',
        width: 200,
      },
    ];
    const approvalMessage = ref();
    const targetDate = ref();
    const errorsServer = ref({});
    const errors = ref({});
    const validated = ref(false);
    const isValid = () => {
      validated.value = true;
      return isValidateForm(errors);
    };
    const accountStore = useLoginAccountStore();
    const myAccount = computed(() => accountStore.state.myAccount);
    const returnScreen = () => {
      if (isWaitingAllowDelete)
        router.push(`/new${ConstantsRouter.ApplicationApprovalRequest}`);
      if (myApplicationFromId.value) redirectWhenCallAPI();
      else router.push(`/new${ConstantsRouter.ApplicationApprovalRequest}`);
    };
    const { waitingDetail, getWaitingData } = useGetWaitingData();
    const getDataWaitingAllowance = async () => {
      await getWaitingData(myApplicationFromId.value);
      if (waitingDetail.value) {
        dataRecordApplicationDelete.value = waitingDetail.value;
        targetDate.value = formatTimeDate(
          dataRecordApplicationDelete.value.target_date,
          `YYYY${t('year')}MM${t('month')}DD${t('sunday')}`
        );
      }
    };
    getDataWaitingAllowance();
    const statusApplication = computed(
      () => waitingDetail.value?.application_status
    );
    const applicationReason = computed(
      () => waitingDetail.value?.application_reason
    );
    const createdAt = computed(() => waitingDetail.value?.created_at);

    const isDisableSubmit = computed(() =>
      Object.values(errors.value).some((e) => !!e)
    );

    const redirectWhenCallAPI = () => {
      if (isMyApplication.value == APPLICATION_MY_REQUEST.toString())
        router.push(`/new${ConstantsRouter.ApplicationApprovalRequest}`);
      else router.push(`/new${ConstantsRouter.ApplicationMyApproval}`);
    };
    const handleTypeFinished = () => {
      if (statusApplication.value === APPLICATION_STATUS_TYPE.FINISHED) {
        router.push(`/new${ConstantsRouter.WaitingAllowanceApplicationDelete}`);
      }
    };
    const createAllowanceApplicationDelete = async () => {
      if (!isValid()) return;
      dataRecordApplicationDelete.value.is_request_delete = true;
      dataRecordApplicationDelete.value.application_form_to_delete_id =
        myApplicationFromId.value;
      const { registered } = postWaitingAllowanceApplication({
        pp_employee_id: dataRecordApplicationDelete.value.pp_employee_id,
        application_define_id: myApplicationDefine.value,
        wait_numbers: dataRecordApplicationDelete.value.wait_numbers,
        application_reason:
          dataRecordApplicationDelete.value.application_reason,
        target_date: dataRecordApplicationDelete.value.target_date,
        unit_id: myAccount.value.unit_id,
        checkin_unit_id: dataRecordApplicationDelete.value.checkin_unit_id,
        application_form_to_delete_id: myApplicationFromId.value,
        is_request_delete: dataRecordApplicationDelete.value.is_request_delete,
      });
      registered
        .then((errors) => {
          if (errors) {
            errorsServer.value = errors.errors.value;
            if (errors?.errorsCommon.value) {
              showToast('error', errors?.errorsCommon.value);
            } else {
              showToast('warning', t('errors.required'));
            }
          } else {
            showToast('success', t('theApplicationHasBeenCreated'));
            router.push(`/new${ConstantsRouter.TimeCard}`);
          }
        })
        .catch(() => {
          showToast('error', t('errors.serverError'));
        });
    };

    return {
      t,
      i18n,
      returnScreen,
      applicationModalHeaders,
      errorsServer,
      errors,
      approvalMessage,
      waitingDetail,
      dataRecordApplicationDelete,
      targetDate,
      accountStore,
      myAccount,
      statusApplication,
      createdAt,
      applicationReason,
      isMyApplication,
      validated,
      handleTypeFinished,
      isDisableSubmit,
      myApplicationDefine,
      myApplicationFromId,
      APPLICATION_ALLOWANCE_DELETE,
      createAllowanceApplicationDelete,
    };
  },
});
