
import { computed, defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import Table from '@/components/share/Table/Table.vue';
import Button from '@/components/share/Button/index.vue';
import Input from '@/components/share/Input/index.vue';
import InputFile from '@/components/share/Input/InputFile.vue';
import { LateEarlyCheckTime } from '@/model/LateEarlyAplication';
import { CheckTimeApplicationSearchParams } from '@/api/types/CheckTimeApplicationApi';
import router from '@/router';
import { useLoginAccountStore } from '@/store/modules/login-account';
import { useEmployeeChekTimeAndWorkSchedule } from '@/api/composables/useCheckTimeApplication';
import { convertToDateTime } from '@/util/composables';
import moment from 'moment';
import ConstantsRouter from '@/constants/router';
import { showToast } from '@/util/useToastMessage';
import {
  useDetailLateEarlyApplication,
  usePostLateEarlyApplication,
} from '@/api/composables/useLateEarlyApplication';
import BackPreviousPage from '@/components/share/BackPreviousPage/index.vue';
import ApplicationApproveFlowDetail from '@/components/Application/ApplicationApproveFlowDetail.vue';
import { LATE_EARLY_APPLICATION_TYPE } from '@/constants/lateEarlyApplication';
import { isValidateForm } from '@/util/useValidateForm';
import ReasonUpdateStatusApplication from '@/components/Application/ReasonUpdateStatusApplication.vue';
import { APPLICATION_MY_APPROVAL } from '@/constants/typeApplication';

export default defineComponent({
  components: {
    InputFile,
    Input,
    Button,
    Table,
    BackPreviousPage,
    ApplicationApproveFlowDetail,
    ReasonUpdateStatusApplication,
  },
  setup() {
    const i18n = useI18n();
    const t = i18n.t;

    const lateEarlyHeaders = [
      { text: '', value: 'type', dataAlign: 'center', width: '80px' },
      {
        text: t('checkIn'),
        value: 'checkin_time',
        dataAlign: 'center',
        width: '120px',
      },
      {
        text: t('checkOut'),
        value: 'checkout_time',
        dataAlign: 'center',
        width: '120px',
      },
      {
        text: t('breakStartTimeRounding'),
        value: 'break_start_time',
        dataAlign: 'center',
        width: '120px',
      },
      {
        text: t('breakEndTimeRounding'),
        value: 'break_end_time',
        dataAlign: 'center',
        width: '120px',
      },
      {
        text: t('breakTime'),
        value: 'total_break_minutes',
        dataAlign: 'center',
        width: '120px',
      },
      {
        text: t('goOut'),
        value: 'go_out_start',
        dataAlign: 'center',
        width: '120px',
      },
      {
        text: t('goBack'),
        value: 'go_out_end',
        dataAlign: 'center',
        width: '120px',
      },
      {
        text: t('overtime'),
        value: 'prescribed_outside_of_working_time',
        dataAlign: 'center',
        width: '120px',
      },
      {
        text: t('attendancePlace'),
        value: 'is_remote',
        dataAlign: 'center',
        width: '120px',
      },
      {
        text: t('workingOrganization'),
        value: 'unit_name',
        dataAlign: 'center',
        width: '120px',
      },
    ];
    const lateEarlyApplicationData = [
      {
        type: t('scheduledToWork'),
      },
      { type: t('stampingRecord') },
    ];
    const lateEarlyApplication = [{}, {}];
    const lateEarlyRequestHeaders = [
      {
        text: '',
        value: 'type_name',
        dataAlign: 'center',
        width: '80px',
      },
      {
        text: t('planTime'),
        value: 'schedule_check_time',
        dataAlign: 'center',
        width: '120px',
      },
      {
        text: t('stampingRecord'),
        value: 'actual_check_time',
        dataAlign: 'center',
        width: '120px',
      },
      {
        text: t('behindTime'),
        value: 'check_time_stamp',
        dataAlign: 'center',
        width: '120px',
        bodyClass: 'font-bold',
      },
      {
        text: t('workingOrganization'),
        value: 'unit_name',
        dataAlign: 'center',
        width: '120px',
      },
      {
        text: t('applicationReason'),
        value: 'reason',
        dataAlign: 'center',
        width: '120px',
      },
      {
        text: t('attach'),
        value: 'file',
        dataAlign: 'center',
        width: '120px',
      },
    ];

    const lateEarlyTypeToText = (type) => {
      switch (type) {
        case LATE_EARLY_APPLICATION_TYPE.CHECK_IN:
          return t('lateApplication');
        case LATE_EARLY_APPLICATION_TYPE.CHECK_OUT:
          return t('earlyApplication');
      }
    };
    const lateEarlyRequestApplication = ref<LateEarlyCheckTime[]>([]);
    const isAddRequestEarlyTime = ref(true);
    const isAddRequestLateTime = ref(true);
    const errors = ref({});
    const validated = ref(false);
    const isDisableSubmit = computed(() =>
      Object.values(errors.value).some((e) => !!e)
    );
    const isValid = () => {
      validated.value = true;
      return isValidateForm(errors);
    };
    const requestEarlyApplication = () => {
      lateEarlyRequestApplication.value.push({
        check_time_type: LATE_EARLY_APPLICATION_TYPE.CHECK_IN,
        type_name: t('lateApplication'),
        schedule_check_time:
          checkTimeApplication.value[0]?.work_schedule_end_time,
        actual_check_time: checkTimeApplication.value[1]?.checkout_time,
        check_time_stamp: lateEarlyTime.value.early_time,
        unit_name: checkTimeApplication.value[0]?.unit_name,
      });
      isAddRequestEarlyTime.value = false;
    };
    const requestLateApplication = () => {
      lateEarlyRequestApplication.value.push({
        check_time_type: LATE_EARLY_APPLICATION_TYPE.CHECK_OUT,
        type_name: t('earlyApplication'),
        schedule_check_time:
          checkTimeApplication.value[0]?.work_schedule_start_time,
        actual_check_time: checkTimeApplication.value[1]?.checkin_time,
        check_time_stamp: lateEarlyTime.value.late_time,
        unit_name: checkTimeApplication.value[0]?.unit_name,
      });
      isAddRequestLateTime.value = false;
    };

    const displayTargetDate = (date) => {
      if (date && moment(date.toString()).isValid()) {
        return moment(date).format(
          `YYYY${t('year')}MM${t('month')}DD${t('day')}（dd）`
        );
      }
      return '';
    };
    const displayScheduleBreakStartTime = (item, index) => {
      if (!item) return '';
      if (index === 0) {
        return item.work_schedule_break_time_range
          ?.map((e) => `${e.break_start_time}`)
          .join('\n');
      }
      if (item.break_start_time) {
        return `${item.break_start_time}`;
      }
    };
    const displayScheduleBreakEndTime = (item, index) => {
      if (!item) return '';
      if (index === 0) {
        return item.work_schedule_break_time_range
          ?.map((e) => `${e.break_end_time}`)
          .join('\n');
      }
      if (item.break_end_time) {
        return `${item.break_end_time}`;
      }
    };
    const goToBack = () => {
      if (parseInt(isApproval) === APPLICATION_MY_APPROVAL) {
        router.push(`/new${ConstantsRouter.ApplicationMyApproval}`);
      } else if (applicationFormId) {
        router.push(`/new${ConstantsRouter.ApplicationApprovalRequest}`);
      } else {
        router.push(`/new${ConstantsRouter.TimeCard}`);
      }
    };
    const lateEarlyTime = computed(() => {
      let lateTime = 0;
      let earlyTime = 0;
      if (checkTimeApplication.value) {
        const scheduleCheckinTime =
          checkTimeApplication.value[0]?.work_schedule_start_time;
        const checkinTime = checkTimeApplication.value[1]?.checkin_time;
        const scheduleCheckoutTime =
          checkTimeApplication.value[0]?.work_schedule_end_time;
        const checkoutTime = checkTimeApplication.value[1]?.checkout_time;

        if (scheduleCheckinTime && checkinTime) {
          lateTime = moment(checkinTime, 'HH:mm:ss').diff(
            moment(scheduleCheckinTime, 'HH:mm:ss'),
            'minutes'
          );
        }

        if (scheduleCheckoutTime && checkoutTime) {
          earlyTime = moment(checkoutTime, 'HH:mm:ss').diff(
            moment(scheduleCheckoutTime, 'HH:mm:ss'),
            'minutes'
          );
        }
      }
      return {
        late_time: lateTime > 0 ? convertToDateTime(lateTime, 'HH:mm') : '',
        early_time: earlyTime > 0 ? convertToDateTime(earlyTime, 'HH:mm') : '',
      };
    });

    const targetDate = router.currentRoute.value.query.target_date as string;
    const applicationDefineId = router.currentRoute.value.query.id as string;
    const accountStore = useLoginAccountStore();
    const myAccount = computed(() => accountStore.state.myAccount);
    const {
      checkTimeApplication,
      getEmployeeChekTimeAndWorkScheduleData,
    } = useEmployeeChekTimeAndWorkSchedule();
    const checkTimeApplicationSearchParams = ref<CheckTimeApplicationSearchParams>(
      {
        target_date: targetDate,
        pp_employee_id: myAccount.value.pp_employee_id,
      }
    );
    getEmployeeChekTimeAndWorkScheduleData(
      checkTimeApplicationSearchParams.value
    );
    const errorServer = ref({});
    const submitPostLateEarlyApplication = () => {
      if (!isValid()) return;
      const { registered } = usePostLateEarlyApplication({
        application_define_id: applicationDefineId,
        target_date: targetDate,
        pp_employee_id: myAccount.value.pp_employee_id,
        unit_id: myAccount.value.unit_id,
        late_or_early_check_times: lateEarlyRequestApplication.value,
      });

      registered
        .then((error) => {
          if (error) {
            errorServer.value = error.errors.value;
            if (error.errorsCommon.value) {
              showToast('error', error.errorsCommon.value);
            } else {
              showToast('warning', i18n.t('invalidInput'));
            }
          } else {
            showToast('success', i18n.t('theApplicationHasBeenCreated'));
            goToBack();
          }
        })
        .catch(() => {
          showToast('error', i18n.t('errors.serverError'));
        });
    };

    // for detail late early application
    const applicationFormId =
      router.currentRoute.value.query.application_form_id;
    const isApproval = router.currentRoute.value.query
      .is_my_application as string;

    const {
      getLateEarlyApplicationDetailData,
      lateEarlyApplicationDetail,
    } = useDetailLateEarlyApplication();
    const getLateEarlyApplicationDetail = async () => {
      if (applicationFormId) {
        await getLateEarlyApplicationDetailData(applicationFormId);
        if (lateEarlyApplicationDetail.value) {
          lateEarlyApplicationDetail.value.late_or_early_check_times = lateEarlyApplicationDetail
            .value.late_or_early_check_times as [];
          lateEarlyRequestApplication.value = lateEarlyApplicationDetail.value.late_or_early_check_times.map(
            (item) => {
              return {
                ...item,
                ...{
                  type_name: lateEarlyTypeToText(item.check_time_type),
                },
              };
            }
          );
        }
      }
    };
    getLateEarlyApplicationDetail();

    return {
      t,
      lateEarlyHeaders,
      lateEarlyApplicationData,
      lateEarlyApplication,
      lateEarlyRequestHeaders,
      lateEarlyRequestApplication,
      checkTimeApplication,
      targetDate,
      lateEarlyTime,
      isAddRequestEarlyTime,
      isAddRequestLateTime,
      isDisableSubmit,
      validated,
      errors,
      errorServer,
      goToBack,
      requestEarlyApplication,
      requestLateApplication,
      convertToDateTime,
      displayScheduleBreakStartTime,
      displayScheduleBreakEndTime,
      displayTargetDate,
      submitPostLateEarlyApplication,

      isApproval,
      applicationFormId,
      lateEarlyApplicationDetail,
      getLateEarlyApplicationDetailData,
    };
  },
});
