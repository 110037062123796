<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.09821 10.0705C7.32407 9.61883 7.78575 9.3335 8.29078 9.3335H10.6667V10.6668H10.0001C9.63188 10.6668 9.3334 10.9653 9.3334 11.3335C9.3334 11.7017 9.63188 12.0002 10.0001 12.0002H22.0001C22.3683 12.0002 22.6667 11.7017 22.6667 11.3335C22.6667 10.9653 22.3683 10.6668 22.0001 10.6668H21.3334V9.3335H23.7094C24.2144 9.3335 24.6761 9.61883 24.9019 10.0705L26.3852 13.0371C26.5704 13.4074 26.6667 13.8157 26.6667 14.2297V28.0002C26.6667 29.4729 25.4728 30.6668 24.0001 30.6668H8.00007C6.52731 30.6668 5.3334 29.4729 5.3334 28.0002V14.2297C5.3334 13.8157 5.42979 13.4074 5.61493 13.0371L7.09821 10.0705Z"
      fill="#999999"
    />
    <circle cx="16.0001" cy="21.3334" r="6.66667" fill="white" />
    <path
      d="M15.3334 16.6667C15.3334 16.2985 15.6319 16 16.0001 16C16.3683 16 16.6667 16.2985 16.6667 16.6667V21.3333C16.6667 21.7015 16.3683 22 16.0001 22C15.6319 22 15.3334 21.7015 15.3334 21.3333V16.6667Z"
      fill="#999999"
    />
    <path
      d="M16.0001 20.6667C16.3683 20.6667 16.6667 20.9652 16.6667 21.3334C16.6667 21.7016 16.3683 22.0001 16.0001 22.0001L12.6667 22.0001C12.2986 22.0001 12.0001 21.7016 12.0001 21.3334C12.0001 20.9652 12.2986 20.6667 12.6667 20.6667L16.0001 20.6667Z"
      fill="#999999"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.0001 0.666748C10.8955 0.666748 10.0001 1.56218 10.0001 2.66675V12.0001H11.3334V2.66675C11.3334 2.29856 11.6319 2.00008 12.0001 2.00008H20.0001C20.3683 2.00008 20.6667 2.29856 20.6667 2.66675V12.0001H22.0001V2.66675C22.0001 1.56218 21.1046 0.666748 20.0001 0.666748H12.0001ZM12.6667 4.66679C12.6667 4.2986 12.9652 4.00012 13.3334 4.00012H18.6667C19.0349 4.00012 19.3334 4.2986 19.3334 4.66679C19.3334 5.03498 19.0349 5.33346 18.6667 5.33346H13.3334C12.9652 5.33346 12.6667 5.03498 12.6667 4.66679ZM12.6667 7.33346C12.6667 6.96527 12.9652 6.66679 13.3334 6.66679H18.6667C19.0349 6.66679 19.3334 6.96527 19.3334 7.33346C19.3334 7.70164 19.0349 8.00012 18.6667 8.00012H13.3334C12.9652 8.00012 12.6667 7.70164 12.6667 7.33346ZM13.3334 9.33346C12.9652 9.33346 12.6667 9.63193 12.6667 10.0001C12.6667 10.3683 12.9652 10.6668 13.3334 10.6668H16.0001C16.3683 10.6668 16.6667 10.3683 16.6667 10.0001C16.6667 9.63193 16.3683 9.33346 16.0001 9.33346H13.3334Z"
      fill="#999999"
    />
  </svg>
</template>
