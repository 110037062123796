import { InjectionKey } from '@vue/runtime-core';
import { createStore, Store } from 'vuex';

export interface State {
  countApiWaiting: number;
  allowLoading: boolean;
}

export const key: InjectionKey<Store<State>> = Symbol();

export function useLoadingStore() {
  return store;
}

export const store = createStore<State>({
  state() {
    return {
      countApiWaiting: 0,
      allowLoading: true,
    };
  },
  mutations: {
    increaseCountApiWaiting(state) {
      if (state.allowLoading) {
        state.countApiWaiting += 1;
      }
    },
    decreaseCountApiWaiting(state) {
      if (state.allowLoading) {
        state.countApiWaiting -= 1;
      }
    },
    allowLoading(state) {
      state.allowLoading = true;
    },
    refuseLoading(state) {
      state.allowLoading = false;
    },
  },
  actions: {},
});
export default store;
