
import { defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import Table from '@/components/Table.vue';
import iconPlus from '@/assets/svg/Plus.svg';
import Button from '@/components/Button.vue';
import Select from '@/components/Select.vue';
import { computed } from '@vue/runtime-core';
import Avatar from '@/components/Avatar.vue';
import AvatarSample from '@/assets/svg/AvatarSample.svg';
import Popup from '@/components/Popup.vue';

export default defineComponent({
  components: { Popup, Avatar, Select, Button, Table },
  setup(props, { emit }) {
    const i18n = useI18n();
    const isShow = ref(false);
    const membersToSubmittedHeaders = [
      {
        value: 'checkbox',
        width: '30px',
      },
      {
        text: i18n.t('name'),
        value: 'name',
      },
      {
        text: i18n.t('organization'),
        value: 'organization',
      },
      {
        text: i18n.t('employeeStatus'),
        value: 'employee_status',
      },
      {
        text: i18n.t('position'),
        value: 'position',
      },
    ];
    const personToSubmittedData = [
      {
        name: '斉藤 亜沙美',
        organization: '営業本部',
        employee_status: '正社員',
        position: 'マネージャー',
        avatar: AvatarSample,
      },
      {
        name: '安里 久美',
        organization: '営業本部',
        employee_status: '正社員',
        position: 'マネージャー',
        avatar: AvatarSample,
      },
      {
        name: '斉藤 亜沙美',
        organization: '営業本部',
        employee_status: '正社員',
        position: 'マネージャー',
        avatar: AvatarSample,
      },
      {
        name: '斉藤 亜沙美',
        organization: '営業本部',
        employee_status: '正社員',
        position: 'マネージャー',
        avatar: AvatarSample,
      },
      {
        name: '斉藤 亜沙美',
        organization: '営業本部',
        employee_status: '正社員',
        position: 'マネージャー',
        avatar: AvatarSample,
      },
      {
        name: '斉藤 亜沙美',
        organization: '営業本部',
        employee_status: '正社員',
        position: 'マネージャー',
        avatar: AvatarSample,
      },
      {
        name: '斉藤 亜沙美',
        organization: '営業本部',
        employee_status: '正社員',
        position: 'マネージャー',
        avatar: AvatarSample,
      },
      {
        name: '斉藤 亜沙美',
        organization: '営業本部',
        employee_status: '正社員',
        position: 'マネージャー',
        avatar: AvatarSample,
      },
      {
        name: '斉藤 亜沙美',
        organization: '営業本部',
        employee_status: '正社員',
        position: 'マネージャー',
        avatar: AvatarSample,
      },
      {
        name: '斉藤 亜沙美',
        organization: '営業本部',
        employee_status: '正社員',
        position: 'マネージャー',
        avatar: AvatarSample,
      },
      {
        name: '斉藤 亜沙美',
        organization: '営業本部',
        employee_status: '正社員',
        position: 'マネージャー',
        avatar: AvatarSample,
      },
      {
        name: '斉藤 亜沙美',
        organization: '営業本部',
        employee_status: '正社員',
        position: 'マネージャー',
        avatar: AvatarSample,
      },
      {
        name: '斉藤 亜沙美',
        organization: '営業本部',
        employee_status: '正社員',
        position: 'マネージャー',
        avatar: AvatarSample,
      },
      {
        name: '斉藤 亜沙美',
        organization: '営業本部',
        employee_status: '正社員',
        position: 'マネージャー',
        avatar: AvatarSample,
      },
      {
        name: '斉藤 亜沙美',
        organization: '営業本部',
        employee_status: '正社員',
        position: 'マネージャー',
        avatar: AvatarSample,
      },
      {
        name: '斉藤 亜沙美',
        organization: '営業本部',
        employee_status: '正社員',
        position: 'マネージャー',
        avatar: AvatarSample,
      },
      {
        name: '斉藤 亜沙美',
        organization: '営業本部',
        employee_status: '正社員',
        position: 'マネージャー',
        avatar: AvatarSample,
      },
      {
        name: '斉藤 亜沙美',
        organization: '営業本部',
        employee_status: '正社員',
        position: 'マネージャー',
        avatar: AvatarSample,
      },
      {
        name: '斉藤 亜沙美',
        organization: '営業本部',
        employee_status: '正社員',
        position: 'マネージャー',
        avatar: AvatarSample,
      },
      {
        name: '斉藤 亜沙美',
        organization: '営業本部',
        employee_status: '正社員',
        position: 'マネージャー',
        avatar: AvatarSample,
      },
      {
        name: '斉藤 亜沙美',
        organization: '営業本部',
        employee_status: '正社員',
        position: 'マネージャー',
        avatar: AvatarSample,
      },
      {
        name: '斉藤 亜沙美',
        organization: '営業本部',
        employee_status: '正社員',
        position: 'マネージャー',
        avatar: AvatarSample,
      },
    ];
    const departmentList = [
      { id: 1, name: '営業本部' },
      { id: 2, name: '001' },
      { id: 3, name: '002' },
    ];
    const workingStyleList = [
      { id: 1, name: '001' },
      { id: 2, name: '002' },
      { id: 3, name: '003' },
    ];
    const positionList = [
      { id: 1, name: '正社員' },
      { id: 2, name: 'アルバイト' },
    ];
    const attributeOrOccupationList = [
      { id: 1, name: 'すべて選択' },
      { id: 2, name: 'すべて選択2' },
      { id: 2, name: 'すべて選択3' },
    ];
    const numberOfPeopleSelected = computed(
      () => formValue.value.person_selected.length
    );
    const onClosePopup = () => {
      isShow.value = false;
      emit('onClose');
    };
    const formValue = ref({
      department: '',
      working_style: '',
      position: '',
      attribute_occupation: '',
      person_selected: [],
    });
    return {
      t: i18n.t,
      membersToSubmittedHeaders,
      numberOfPeopleSelected,
      iconPlus,
      departmentList,
      workingStyleList,
      personToSubmittedData,
      isShow,
      positionList,
      attributeOrOccupationList,
      formValue,
      onClosePopup,
    };
  },
});
