import api, { ApiResponse } from '@/clients/api';
import { ErrorCode } from './types/general/ErrorCode';
import {
  JSONApiResponseBody,
  JSONApiResponseErrorBody,
} from '@/api/types/general/JSONApiResponse';
import ConstantsAPI from '@/constants/api';
import { ApplicationApproveFlowType } from './types/applicationApproveFlowDetail';

export async function getApplicationApproveFlowDetail(
  id: string,
  unitId: string
): Promise<
  | ApiResponse<200, JSONApiResponseBody<ApplicationApproveFlowType>>
  | ApiResponse<400, JSONApiResponseErrorBody<ErrorCode>>
  | ApiResponse<500, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.get(ConstantsAPI.ApplicationApproveFlowDetail(id, unitId));
}
