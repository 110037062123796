import { ref } from 'vue';
import { postCheckinTimeKeepingGoOutEnd } from '@/api/timeKeepingGoOutEnd';

import { CheckinTimeKeepingGoOutEndParams } from '@/api/types/TimeKeepingGoOutEnd';

import { convertErrorFromServerToJson } from '@/util/useValidateForm';

export function usePostCheckinTimeKeepingGoOutEnd(
  params: CheckinTimeKeepingGoOutEndParams
) {
  const errors = ref({});
  const errorsCommon = ref<string>();
  return {
    postData: (async () => {
      const response = await postCheckinTimeKeepingGoOutEnd(params);
      switch (response.status) {
        case 200:
          return null;
        default:
          if (response.data.errors[0].source) {
            errors.value = convertErrorFromServerToJson(response.data.errors);
          } else {
            errorsCommon.value = response.data.errors[0].title;
          }
          return {
            errors,
            errorsCommon,
          };
      }
    })(),
  };
}
