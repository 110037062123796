import api, { ApiResponse } from '@/clients/api';
import {
  JSONApiResponseBody,
  JSONApiResponseErrorBody,
} from '@/api/types/general/JSONApiResponse';
import {
  NationalHolidayData,
  NationalHolidaysParam,
  NationalHolidayDeleteResult,
  NationalHolidaysParamsCreateUpdate,
  NationalHolidaysParamsCreateResult,
  NationalHolidaysParamsUpdateResult,
} from '@/api/types/NationalHolidays';
import { ErrorCode } from '@/api/types/general/ErrorCode';
import { NationalHolidayDetail } from '@/model/NationalHolidays';
import ConstantsAPI from '@/constants/api';

export async function getNationalHolidaysList(
  params: NationalHolidaysParam
): Promise<
  | ApiResponse<200, JSONApiResponseBody<NationalHolidayData[]>>
  | ApiResponse<404, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.get(ConstantsAPI.NationalHolidaysGetList, { params });
}

export async function getNationalHolidaysDetail(
  id: string
): Promise<
  | ApiResponse<200, JSONApiResponseBody<NationalHolidayDetail>>
  | ApiResponse<404, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.get(ConstantsAPI.NationalHolidaysGetDetail(id));
}

export async function deleteNationalHoliday(
  id: string
): Promise<
  | ApiResponse<200, JSONApiResponseBody<NationalHolidayDeleteResult>>
  | ApiResponse<400, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.delete(ConstantsAPI.NationalHolidaysDelete(id));
}

export async function getListNationalHolidayJa(year: number) {
  return await api.get(`https://api.national-holidays.jp/${year}`);
}

export async function createNationalHolidays(
  params: NationalHolidaysParamsCreateUpdate
): Promise<
  | ApiResponse<200, JSONApiResponseBody<NationalHolidaysParamsCreateResult>>
  | ApiResponse<400, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.post(ConstantsAPI.NationalHolidaysCreate, params);
}

export async function updateNationalHolidays(
  id: string,
  params: NationalHolidaysParamsCreateUpdate
): Promise<
  | ApiResponse<200, JSONApiResponseBody<NationalHolidaysParamsUpdateResult>>
  | ApiResponse<400, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.put(ConstantsAPI.NationalHolidaysUpdate(id), params);
}
