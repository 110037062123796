
import { computed, defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import Table from '@/components/share/Table/Table.vue';
import router from '@/router';
import { useLoginAccountStore } from '@/store/modules/login-account';
import { convertToDateTime } from '@/util/composables';
import ConstantsRouter from '@/constants/router';
import { showToast } from '@/util/useToastMessage';
import BackPreviousPage from '@/components/share/BackPreviousPage/index.vue';
import ApplicationApproveFlowDetail from '@/components/Application/ApplicationApproveFlowDetail.vue';
import { isValidateForm } from '@/util/useValidateForm';
import ReasonUpdateStatusApplication from '@/components/Application/ReasonUpdateStatusApplication.vue';
import { APPLICATION_MY_APPROVAL } from '@/constants/typeApplication';
import DatePickerInput from '@/components/share/Calendar/DatePickerInput.vue';
import { TransferAttendanceApplicationParams } from '@/model/TransferAttendanceApplication';
import Dropdown from '@/components/share/Dropdown/Dropdown.vue';
import useUnit from '@/api/composables/useUnit';
import InputTime from '@/components/share/Input/InputTime.vue';
import {
  useDetailTransferAttendanceApplication,
  usePostTransferAttendanceApplication,
} from '@/api/composables/useTransferAttentdanceApplication';
import { formatTimeDate } from '@/util/time';
import ReasonCreateApplication from '@/components/Application/ReasonCreateApplication.vue';

export default defineComponent({
  components: {
    ReasonCreateApplication,
    InputTime,
    Dropdown,
    DatePickerInput,
    Table,
    BackPreviousPage,
    ApplicationApproveFlowDetail,
    ReasonUpdateStatusApplication,
  },
  setup() {
    const i18n = useI18n();
    const t = i18n.t;
    const { unitList } = useUnit({});

    const targetDate = router.currentRoute.value.query.target_date as string;
    const applicationDefineId = router.currentRoute.value.query.id as string;
    const accountStore = useLoginAccountStore();
    const myAccount = computed(() => accountStore.state.myAccount);
    const displayTargetDate = (date) => {
      return formatTimeDate(
        date,
        `YYYY${t('year')}MM${t('month')}DD${t('day')}（dd）`
      );
    };
    const goToBack = () => {
      if (parseInt(isApproval) === APPLICATION_MY_APPROVAL) {
        router.push(`/new${ConstantsRouter.ApplicationMyApproval}`);
      } else if (applicationFormId) {
        router.push(`/new${ConstantsRouter.ApplicationApprovalRequest}`);
      } else {
        router.push(`/new${ConstantsRouter.TimeCard}`);
      }
    };

    const transferAttendanceHeaders = [
      {
        text: '',
        value: 'action',
        dataAlign: 'center',
        width: '80px',
      },
      {
        text: '',
        value: 'type_name',
        dataAlign: 'center',
        width: '80px',
      },
      {
        text: t('checkIn'),
        value: 'checkin_time',
        dataAlign: 'center',
        width: '120px',
      },
      {
        text: t('checkOut'),
        value: 'checkout_time',
        dataAlign: 'center',
        width: '120px',
      },
      {
        text: t('breakStartTimeRounding'),
        value: 'break_start_time',
        dataAlign: 'center',
        width: '120px',
      },
      {
        text: t('breakEndTimeRounding'),
        value: 'break_end_time',
        dataAlign: 'center',
        width: '120px',
      },
      {
        text: t('breakTime'),
        value: 'break_time',
        dataAlign: 'center',
        width: '120px',
      },
      {
        text: t('goOut'),
        value: 'go_out_start',
        dataAlign: 'center',
        width: '120px',
      },
      {
        text: t('goBack'),
        value: 'go_out_end',
        dataAlign: 'center',
        width: '120px',
      },
      {
        text: t('scheduledOvertime'),
        value: 'scheduled_overtime',
        dataAlign: 'center',
        width: '120px',
      },
      {
        text: t('attendancePlace'),
        value: 'attendance_place',
        dataAlign: 'center',
        width: '120px',
      },
      {
        text: t('checkinUnit'),
        value: 'checkin_unit',
        dataAlign: 'center',
        width: '120px',
      },
    ];
    const transferAttendanceRequestParams = ref<TransferAttendanceApplicationParams>(
      {
        unit_id: myAccount.value.unit_id,
        application_define_id: applicationDefineId,
        target_date: targetDate,
        pp_employee_id: myAccount.value.pp_employee_id,
        exchange_workday_details: [{}],
      }
    );

    const errors = ref({});
    const errorServer = ref({});
    const validated = ref(false);
    const isDisableSubmit = computed(() =>
      Object.values(errors.value).some((e) => !!e)
    );
    const isValid = () => {
      validated.value = true;
      return isValidateForm(errors);
    };
    const addExchangeWorkdayDetails = () => {
      transferAttendanceRequestParams.value.exchange_workday_details.push({});
    };
    const removeExchangeWorkdayDetails = (index) => {
      transferAttendanceRequestParams.value.exchange_workday_details.splice(
        index,
        1
      );
    };
    const submitPostTransferAttendanceApplication = () => {
      if (!isValid()) return;
      const { registered } = usePostTransferAttendanceApplication(
        transferAttendanceRequestParams.value
      );

      registered
        .then((error) => {
          if (error) {
            errorServer.value = error.errors.value;
            if (error.errorsCommon.value) {
              showToast('error', error.errorsCommon.value);
            } else {
              showToast('warning', i18n.t('invalidInput'));
            }
          } else {
            showToast('success', i18n.t('theApplicationHasBeenCreated'));
            goToBack();
          }
        })
        .catch(() => {
          showToast('error', i18n.t('errors.serverError'));
        });
    };

    // for detail transfer attendance application
    const applicationFormId =
      router.currentRoute.value.query.application_form_id;
    const isApproval = router.currentRoute.value.query
      .is_my_application as string;
    const {
      getTransferAttendanceApplicationDetailData,
      transferAttendanceApplicationDetail,
    } = useDetailTransferAttendanceApplication();
    const getTransferAttendanceApplicationDetail = async () => {
      if (applicationFormId) {
        await getTransferAttendanceApplicationDetailData(applicationFormId);
        transferAttendanceRequestParams.value =
          transferAttendanceApplicationDetail.value;
      }
    };
    getTransferAttendanceApplicationDetail();

    return {
      t,
      transferAttendanceHeaders,
      targetDate,
      validated,
      errors,
      errorServer,
      isDisableSubmit,
      transferAttendanceRequestParams,
      unitList,
      goToBack,
      convertToDateTime,
      displayTargetDate,
      submitPostTransferAttendanceApplication,
      addExchangeWorkdayDetails,
      removeExchangeWorkdayDetails,

      isApproval,
      applicationFormId,
      getTransferAttendanceApplicationDetailData,
      transferAttendanceApplicationDetail,
    };
  },
});
