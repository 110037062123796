
import { computed, defineComponent, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import Dropdown from '@/components/share/Dropdown/Dropdown.vue';
import DatePickerInput from '@/components/share/Calendar/DatePickerInput.vue';
import Checkbox from '@/components/share/Checkbox/CheckboxInput.vue';
import Button from '@/components/share/Button/index.vue';
import moment from 'moment';
import MultiSelect from '@/components/share/Dropdown/MultiSelect.vue';
import router from '@/router';
import { convertToDateTime, getCookie } from '@/util/composables';
import { isEmpty } from '@/util/useValidateForm';
import { useGetAllMemberTimeCard } from '@/api/composables/useAllmemberTimeCard';
import useUnit from '@/api/composables/useUnit';
import { AllMemberTimeCardApiGetParams } from '@/api/types/AllMemberTimeCardApi';
import ScrollTable from '@/components/share/Table/ScrollTable.vue';
import ConstantsRouter from '@/constants/router';
import InputSearchEmployee from '@/components/share/Input/InputSearchEmployee.vue';
import {
  isAllValidMonth,
  formatTimeMonth,
  getMonthYearFromDate,
} from '@/util/time';
import { getSearchEmploy } from '@/api/composables/useGetSearchEmployee';
import { useGetListWorkingStyleMaster } from '@/api/composables/useWorkingStyleMaster';

export default defineComponent({
  components: {
    ScrollTable,
    MultiSelect,
    Button,
    Checkbox,
    DatePickerInput,
    Dropdown,
    InputSearchEmployee,
  },
  setup() {
    const i18n = useI18n();
    const t = i18n.t;

    const headersTimeCard = [
      {
        text: t('deploy'),
        value: 'unit_name',
        dataAlign: 'center',
        width: 66,
      },
      {
        text: t('fullName'),
        value: 'pp_employee_name',
        dataAlign: 'center',
        width: 93,
      },
      {
        text: t('workingStyle'),
        value: 'working_style_name',
        dataAlign: 'center',
        width: 80,
      },
      {
        text: t('attribute'),
        value: 'attribute',
        dataAlign: 'center',
        width: 80,
      },
      {
        text: t('attendanceRecord'),
        value: 'working_schedules',
        dataAlign: 'center',
        width: 100,
      },
      {
        text: t('unsupportedApplication'),
        value: 'unsupported_application',
        dataAlign: 'center',
        width: 100,
      },
      {
        text: t('error'),
        value: 'total_number_of_errors',
        dataAlign: 'center',
        width: 80,
      },
      {
        text: t('weekdayCommutingDays'),
        value: 'total_weekday_commuting_days',
        dataAlign: 'center',
        width: 80,
      },
      {
        text: t('holidayWorkDays'),
        value: 'total_holiday_work_days',
        dataAlign: 'center',
        width: 80,
      },
      {
        text: t('numberOfAbsenteeDays'),
        value: 'total_number_of_absentee_days',
        dataAlign: 'center',
        width: 80,
      },
      {
        text: t('holidayDays'),
        value: 'total_number_of_holidays',
        dataAlign: 'center',
        width: 80,
      },
      {
        text: t('numberOfSubstituteHolidays'),
        value: 'total_number_of_substitute_holidays',
        dataAlign: 'center',
        width: 80,
      },
      {
        text: t('thereAreDaysToObtain'),
        value: 'total_number_of_taken_holidays',
        dataAlign: 'center',
        width: 80,
      },
      {
        text: t('numberOfDaysOfSpecialLeaveTakenPaid'),
        value: 'total_number_of_taken_special_holiday_with_paid',
        dataAlign: 'center',
        width: 80,
      },
      {
        text: t('numberOfDaysOfSpecialLeaveTakenUnpaid'),
        value: 'total_number_of_taken_special_holiday_without_paid',
        dataAlign: 'center',
        width: 80,
      },
      {
        text: t('waitingDays'),
        value: 'total_number_of_waits',
        dataAlign: 'center',
        width: 120,
      },
      {
        text: t('laborStandardsHours'),
        value: 'total_working_standard_hours',
        dataAlign: 'center',
        width: 100,
      },
      {
        text: t('scheduledWorkingHours'),
        value: 'prescribed_working_time',
        dataAlign: 'center',
        width: 80,
      },
      {
        text: t('overtimeWorkingHours'),
        value: 'prescribed_outside_of_working_time',
        dataAlign: 'center',
        width: 80,
      },
      {
        text: t('nonStatutoryWorkingHours'),
        value: 'non_statutory_working_hours',
        dataAlign: 'center',
        width: 80,
      },
      {
        text: t('outsideSpecialExtraHours'),
        value: 'outside_special_extra_hours',
        dataAlign: 'center',
        width: 80,
      },
      {
        text: t('nightWorkingTime'),
        value: 'midnight_working_time',
        dataAlign: 'center',
        width: 80,
      },
      {
        text: t('legalHolidayWorkingHours'),
        value: 'statutory_holiday_working_time',
        dataAlign: 'center',
        width: 80,
      },
      {
        text: t('legalHolidayMidnightTime'),
        value: 'statutory_holiday_midnight_working_time',
        dataAlign: 'center',
        width: 80,
      },
      {
        text: t('breakTime'),
        value: 'break_time',
        dataAlign: 'center',
        width: 80,
      },
      {
        text: t('paidDeemedTime'),
        value: 'paid_deemed_time',
        dataAlign: 'center',
        width: 80,
      },
      {
        text: t('actualWorkingHours'),
        value: 'actual_working_hours',
        dataAlign: 'center',
        width: 100,
      },
      {
        text: t('sumWorkingHours'),
        value: 'total_working_hours',
        dataAlign: 'center',
        width: 100,
      },
    ];
    const resetCount = ref(0);
    const unitId = getCookie('unit_id');
    const getParamArray = (params) => {
      if (!params || params == 'undefined') return [];
      if (typeof params === 'string') return [params];
      return params;
    };

    const unitIdInit = ref<string[]>([]);
    const employeeId = ref<string[]>([]);
    const unitCheckinIds = ref<string[]>([]);
    const workingStyleIds = ref<string[]>([]);
    const setParamsSearchFromRouter = () => {
      unitIdInit.value = getParamArray(router.currentRoute.value.query.unit_ids)
        .length
        ? getParamArray(router.currentRoute.value.query.unit_ids)
        : [unitId];
      employeeId.value = getParamArray(
        router.currentRoute.value.query.pp_employee_ids
      );
      unitCheckinIds.value = getParamArray(
        router.currentRoute.value.query.unit_checkin_ids
      );
      workingStyleIds.value = getParamArray(
        router.currentRoute.value.query.working_style_ids
      );
    };
    setParamsSearchFromRouter();
    const paramSearchTimeCard = ref<AllMemberTimeCardApiGetParams>({
      unit_ids: [...unitIdInit.value],
      date: moment().format('YYYY-MM'),
      start_date: moment().startOf('month').format('YYYY-MM-DD'),
      end_date: moment().endOf('month').format('YYYY-MM-DD'),
      pp_employee_ids: [...employeeId.value],
      working_style_ids: [...workingStyleIds.value],
    });
    const isExpand = ref(false);
    const convertNumberToDateTime = (number, format) => {
      if (!number) {
        return '—';
      }
      return convertToDateTime(number, format);
    };
    const {
      allMemberTimeCardList,
      getAllMemberTimeCardData,
    } = useGetAllMemberTimeCard();
    router.push({
      query: paramSearchTimeCard.value,
      path: router.currentRoute.value.fullPath,
    });
    const dateTimeCard = computed(() => {
      if (isAllValidMonth(paramSearchTimeCard.value.date))
        return formatTimeMonth(paramSearchTimeCard.value.date);
      return paramSearchTimeCard.value.date;
    });
    getAllMemberTimeCardData(paramSearchTimeCard.value);

    const resetParamSearch = () => {
      paramSearchTimeCard.value = {
        unit_ids: [unitId],
        unit_checkin_ids: [],
        date: moment().format('YYYY-MM'),
        start_date: moment().startOf('month').format('YYYY-MM-DD'),
        end_date: moment().endOf('month').format('YYYY-MM-DD'),
        pp_employee_ids: [],
        working_style_ids: [],
      };
      router.push({
        query: {
          ...paramSearchTimeCard.value,
        },
        path: router.currentRoute.value.fullPath,
      });
      resetCount.value++;
    };
    const paramSearchUnit = ref({ is_all: true });
    const { unitList } = useUnit(paramSearchUnit.value);

    const searchAllMemberTimeCard = () => {
      const startDate =
        paramSearchTimeCard.value.date &&
        isValidDate(paramSearchTimeCard.value.date)
          ? moment(paramSearchTimeCard.value.date)
              .startOf('month')
              .format('YYYY-MM-DD')
          : paramSearchTimeCard.value.date;
      const endDate =
        paramSearchTimeCard.value.date &&
        isValidDate(paramSearchTimeCard.value.date)
          ? moment(paramSearchTimeCard.value.date)
              .endOf('month')
              .format('YYYY-MM-DD')
          : paramSearchTimeCard.value.date;
      const paramSearch = {
        ...paramSearchTimeCard.value,
        ...{
          start_date: startDate,
          end_date: endDate,
        },
      };
      // delete key value
      delete paramSearch['date'];
      Object.keys(paramSearch).forEach((key) => {
        if (isEmpty(paramSearch[key])) {
          delete paramSearch[key];
        }
      });

      if (
        JSON.stringify(paramSearch) ===
        JSON.stringify(router.currentRoute.value.query)
      ) {
        getAllMemberTimeCardData(paramSearch);
      }
      router.push({
        query: paramSearch,
        path: router.currentRoute.value.fullPath,
      });
    };
    const closeSearchDetail = () => {
      isExpand.value = false;
      paramSearchTimeCard.value = {
        unit_ids: [...unitIdInit.value],
        unit_checkin_ids: [...unitCheckinIds.value],
        date: getMonthYearFromDate(
          router.currentRoute.value.query.start_date
        ) as string,
        pp_employee_ids: [...employeeId.value],
        working_style_ids: [...workingStyleIds.value],
      };
      resetCount.value++;
    };
    const isValidDate = (date) => {
      return moment(date).isValid();
    };
    watch(
      () => router.currentRoute.value.query,
      () => {
        setParamsSearchFromRouter();
        searchAllMemberTimeCard();
      }
    );

    const advancedSearchAllMemberTimeCard = computed(() => {
      return {
        unitName: unitList.value
          .filter((item) =>
            paramSearchTimeCard.value.unit_ids.includes(item.unit_id)
          )
          .map((item) => item.unit_name),
        unitCheckinNames: unitList.value
          .filter((item) =>
            paramSearchTimeCard.value.unit_checkin_ids?.includes(item.unit_id)
          )
          .map((item) => item.unit_name),
      };
    });
    const goDetailAllMemberTimeCard = (item) => {
      router.push({
        path: `/new${ConstantsRouter.AllMemberTimeCardDetail}`,
        query: {
          id: item.pp_employee_id,
          code: item.pp_employee_code,
          name: item.pp_employee_name,
          unitName: item.unit_name,
          startDate:
            paramSearchTimeCard.value.date &&
            isValidDate(paramSearchTimeCard.value.date)
              ? moment(paramSearchTimeCard.value.date)
                  .startOf('month')
                  .format('YYYY-MM-DD')
              : paramSearchTimeCard.value.date,
          endDate:
            paramSearchTimeCard.value.date &&
            isValidDate(paramSearchTimeCard.value.date)
              ? moment(paramSearchTimeCard.value.date)
                  .endOf('month')
                  .format('YYYY-MM-DD')
              : paramSearchTimeCard.value.date,
        },
      });
    };
    const heightRequestSearch = computed(() => {
      if (searchRequest.value) {
        return searchRequest.value.getBoundingClientRect().height;
      }
      return 0;
    });
    const searchRequest = ref<HTMLDivElement>();
    const getNameEmployee = (id) => {
      return (
        optionListEmployee.value.filter((item) => item.value == id)[0]?.text ||
        ''
      );
    };
    const optionListEmployee = ref<{ value: string; text: string }[]>([]);
    const {
      getWorkingStyleMasterList,
      workingStyleMasterData,
    } = useGetListWorkingStyleMaster({});
    getWorkingStyleMasterList({});
    getSearchEmploy().then((res) => {
      optionListEmployee.value = res.data.map((el) => {
        return {
          value: el.id,
          text: el.name,
          url: el.photo,
          name_face_image: el.name_face_image,
        };
      });
    });

    return {
      t,
      headersTimeCard,
      optionListEmployee,
      paramSearchTimeCard,
      isExpand,
      allMemberTimeCardList,
      unitList,
      advancedSearchAllMemberTimeCard,
      dateTimeCard,
      heightRequestSearch,
      searchRequest,
      resetCount,
      unitId,
      workingStyleMasterData,
      employeeId,
      resetParamSearch,
      searchAllMemberTimeCard,
      convertNumberToDateTime,
      goDetailAllMemberTimeCard,
      closeSearchDetail,
      getNameEmployee,
    };
  },
});
