
import { defineComponent, onBeforeMount, onBeforeUnmount } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from '@/store';
import router from '@/router';
import ConstantsRouter from '@/constants/router';

export default defineComponent({
  setup() {
    const i18n = useI18n();
    const t = i18n.t;
    const store = useStore();
    onBeforeMount(() => {
      store.commit('root/SET_BACK_ROUTER_PATH', ConstantsRouter.Settings);
      store.commit('root/SET_CURRENT_PAGE', i18n.t('attendanceMaster'));
    });
    onBeforeUnmount(() => {
      store.commit('root/SET_BACK_ROUTER_PATH', '');
      store.commit('root/SET_CURRENT_PAGE', '');
    });

    const dataTabs = [
      {
        text: i18n.t('vacationMaster'),
        path: ConstantsRouter.VacationMaster,
        name: ConstantsRouter.VacationMaster,
      },
      {
        text: i18n.t('scheduledWorkingMaster'),
        path: '',
      },
      {
        text: i18n.t('workingStyleMaster'),
        path: '',
      },
      {
        text: i18n.t('timezoneDivisionMaster'),
        path: '',
      },
    ];
    const currentPage = router.currentRoute;
    return {
      t,
      currentPage,
      dataTabs,
    };
  },
});
