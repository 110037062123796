import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "popup-container" }
const _hoisted_2 = { class: "popup-body" }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = { class: "txt-title" }
const _hoisted_5 = { class: "body-content py-4" }
const _hoisted_6 = { class: "option-parent flex items-center" }
const _hoisted_7 = { class: "option-child" }
const _hoisted_8 = {
  key: 0,
  class: "text-center mt-4"
}
const _hoisted_9 = {
  key: 0,
  class: "text-error text-center mt-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PermissionSetting = _resolveComponent("PermissionSetting")
  const _component_Checkbox = _resolveComponent("Checkbox")
  const _component_Button = _resolveComponent("Button")
  const _component_TablePriviEdit = _resolveComponent("TablePriviEdit")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_PermissionSetting),
    _withDirectives(_createVNode("div", _hoisted_1, [
      _createVNode("div", _hoisted_2, [
        _createVNode("div", _hoisted_3, [
          _createVNode("span", _hoisted_4, _toDisplayString(_ctx.t('selectAuthToEdit')), 1)
        ]),
        _createVNode("div", _hoisted_5, [
          _createVNode("div", _hoisted_6, [
            _createVNode(_component_Checkbox, {
              labelBold: true,
              "option-checkbox": {
              label: _ctx.t('selectAllPermissions'),
            },
              modelValue: _ctx.isCheckAll,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.isCheckAll = $event)),
              onChange: _ctx.onSelectedAll
            }, null, 8, ["option-checkbox", "modelValue", "onChange"])
          ]),
          _createVNode("div", _hoisted_7, [
            (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.listRoles, (item, index) => {
              return (_openBlock(), _createBlock("div", {
                key: index,
                class: "item ml-4"
              }, [
                _createVNode(_component_Checkbox, {
                  labelBold: true,
                  "option-checkbox": { label: item.text },
                  modelValue: item.checked,
                  "onUpdate:modelValue": ($event: any) => (item.checked = $event),
                  index: index,
                  onChange: _ctx.onSelected
                }, null, 8, ["option-checkbox", "modelValue", "onUpdate:modelValue", "index", "onChange"])
              ]))
            }), 128)),
            (_ctx.listRoles.length < 1)
              ? (_openBlock(), _createBlock("div", _hoisted_8, _toDisplayString(_ctx.t('noData')), 1))
              : _createCommentVNode("", true)
          ])
        ]),
        (!_ctx.isValidSelectedRole)
          ? (_openBlock(), _createBlock("div", _hoisted_9, _toDisplayString(_ctx.t('checkSelectedRoleList')), 1))
          : _createCommentVNode("", true),
        _createVNode("div", {
          class: ["flex justify-center", _ctx.isValidSelectedRole ? 'mt-16' : 'mt-8']
        }, [
          _createVNode(_component_Button, {
            onClick: _ctx.handleSavePriviSetting,
            class: "btn-gradation-light-blue w-50 h-12 mr-4"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('toRegister')), 1)
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_Button, {
            onClick: _ctx.handleBackListRole,
            class: "btn-gradation-secondary w-50 h-12"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('backToTheList')), 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ], 2)
      ])
    ], 512), [
      [_vShow, _ctx.isPopupEdit]
    ]),
    (!_ctx.isPopupEdit)
      ? (_openBlock(), _createBlock(_component_TablePriviEdit, {
          key: 0,
          listRolesSelected: _ctx.listRolesSelected
        }, null, 8, ["listRolesSelected"]))
      : _createCommentVNode("", true)
  ], 64))
}