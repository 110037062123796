
import {
  defineComponent,
  onBeforeMount,
  ref,
  onBeforeUnmount,
  watchEffect,
} from 'vue';
import moment from 'moment-timezone';
import { handlePrefixZero } from '@/util/composables/index';

export default defineComponent({
  emits: ['changeTime'],
  setup(props, { emit }) {
    let refs = ref(0);
    let time = ref('00:00:00');
    time = updateTime();
    onBeforeMount(() => {
      refs.value = setInterval(updateTime, 1000);
    });
    onBeforeUnmount(() => {
      clearInterval(refs.value);
    });
    watchEffect(() => {
      emit('changeTime', time.value);
    });
    function updateTime() {
      const date = moment().tz('Asia/Tokyo');
      time.value =
        handlePrefixZero(date.hours()) +
        ':' +
        handlePrefixZero(date.minutes()) +
        ':' +
        handlePrefixZero(date.seconds());
      return time;
    }
    return {
      time,
    };
  },
});
