import api, { ApiResponse } from '@/clients/api';
import { ErrorCode } from './types/general/ErrorCode';
import {
  JSONApiResponseBody,
  JSONApiResponseErrorBody,
} from '@/api/types/general/JSONApiResponse';
import ConstantsAPI from '@/constants/api';
import {
  WaitingAllowanceApiGet,
  WaitingAllowanceApiPost,
} from './types/WaitingAllowanceApplication';

export async function postWaitingAllowance(
  postData: WaitingAllowanceApiPost
): Promise<
  | ApiResponse<200, JSONApiResponseBody<WaitingAllowanceApiPost>>
  | ApiResponse<400, JSONApiResponseErrorBody<ErrorCode>>
  | ApiResponse<500, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.post(ConstantsAPI.WaitingAllowance, postData);
}

export async function getWaitingAllowanceDetail(
  id: string
): Promise<
  | ApiResponse<200, JSONApiResponseBody<WaitingAllowanceApiGet>>
  | ApiResponse<400, JSONApiResponseErrorBody<ErrorCode>>
  | ApiResponse<500, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.get(ConstantsAPI.WaitingAllowanceDetail(id));
}
