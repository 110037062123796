import api, { ApiResponse } from '@/clients/api';
import { ErrorCode } from './types/general/ErrorCode';
import {
  JSONApiResponseBody,
  JSONApiResponseErrorBody,
} from '@/api/types/general/JSONApiResponse';
import ConstantsAPI from '@/constants/api';
import { ApprovalFlowStampsApiGet } from './types/ApplicationApprovalFlowStamps';

export async function getApplicationApprovalFlow(
  id: string
): Promise<
  | ApiResponse<200, JSONApiResponseBody<ApprovalFlowStampsApiGet>>
  | ApiResponse<400, JSONApiResponseErrorBody<ErrorCode>>
  | ApiResponse<500, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.get(ConstantsAPI.ApplicationApprovalFlowStamps(id));
}
