
import { computed, defineComponent, PropType, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import Popup from '@/components/share/Modal/index.vue';
import Button from '@/components/share/Button/index.vue';
import Input from '@/components/share/Input/index.vue';
import { usePutRejectApplicationReason } from '@/api/composables/useApplicationApproval';
import { showToast } from '@/util/useToastMessage';
import { ApprovalHistories } from '@/model/ApplicationApprovalRequest';
import RejectIcon from '@/components/Icons/RejectIcon.vue';
import { useLoginAccountStore } from '@/store/modules/login-account';

export default defineComponent({
  components: { Popup, Button, Input, RejectIcon },
  props: { listIdHistory: { type: Array as PropType<string[]> } },
  emits: ['onClose', 'reload', 'updateData'],
  setup(props, { emit }) {
    const reasonApprove = ref('');
    const i18n = useI18n();
    const t = i18n.t;
    const isShow = ref(false);
    const handleCancel = () => {
      isShow.value = false;
      emit('onClose');
    };
    const accountStore = useLoginAccountStore();
    const myAccount = computed(() => accountStore.state.myAccount);
    const ppEmployeeId = myAccount.value.pp_employee_id;
    const paramsApproval = computed(() => {
      const listApprovalHistory: ApprovalHistories[] =
        props.listIdHistory?.map((item) => {
          return {
            application_approval_history_id: item,
            reason: reasonApprove.value,
          };
        }) || [];
      return listApprovalHistory;
    });
    const submitAllReason = () => {
      const { updated } = usePutRejectApplicationReason({
        pp_employee_id: ppEmployeeId,
        application_approval_histories: paramsApproval.value,
      });
      updated
        .then((error) => {
          if (error) {
            if (error.errorsCommon.value) {
              showToast('error', error.errorsCommon.value);
            }
          } else {
            emit('updateData');

            showToast('success', t('rejectSuccess'));
            handleCancel();
          }
        })
        .catch(() => {
          showToast('error', i18n.t('errors.serverError'));
        });
    };
    return {
      t: i18n.t,
      submitAllReason,
      isShow,
      handleCancel,
      reasonApprove,
    };
  },
});
