import { GetterTree } from 'vuex';

import type { UserProfiles } from '@/types';

import { RootState } from '@/store';

import { State } from './state';

export type Getters = {
  profile(state: State): UserProfiles;
};

export const getters: GetterTree<State, RootState> & Getters = {
  profile: (state) => state.profile,
};
