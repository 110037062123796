
import { defineComponent, onBeforeMount, reactive } from 'vue';
import { useI18n } from 'vue-i18n';
import Label from '@/components/Label.vue';
import Input from '@/components/Input.vue';
import MyTable from '@/components/Table.vue';
import Plus from '@/assets/svg/Plus.svg';
import Select from '@/components/Select.vue';
import { useStore } from '@/store';
import { ref, watchEffect } from '@vue/runtime-core';
import {
  TimezoneDivisionDetail,
  TimezoneDivisionParams,
} from '@/model/TimezoneDivision';
import { ActionType } from '@/util/composables';
import timezoneSettingsRegister from '@/api/composables/timezoneSettings';
import { TypeErrors } from '@/store/modules/root/state';
import {
  useTimezoneDetailSetting,
  updateTimezone,
} from '@/api/composables/timezoneSettings';
import router from '@/router';
import InputTime from '@/components/InputTime.vue';
import Button from '@/components/Button.vue';
import { LocationQueryValue } from 'vue-router';
import { showToast } from '@/util/useToastMessage';
import ConstantsRouter from '@/constants/router';
import { scrollIntoView } from '@/util/useScrollIntoView';

export default defineComponent({
  components: {
    Select,
    MyTable,
    Input,
    Label,
    InputTime,
    Button,
  },
  setup() {
    const i18n = useI18n();
    const store = useStore();
    const actionType = ref<string | LocationQueryValue[]>(ActionType.CREATE);
    const timezoneId = router.currentRoute.value.params.id;
    const typeAction = router.currentRoute.value.query.action;
    if (timezoneId && typeAction) {
      actionType.value = typeAction;
    }
    const timezoneHeaders = [
      {
        text: i18n.t('timezoneHeaders.settingTargetDate'),
        value: 'day_of_week',
        width: '20%',
      },
      {
        text: i18n.t('timezone'),
        value: 'fixed_working_hours',
        width: '65%',
      },
      {
        text: '',
        value: 'action',
        width: '5%',
      },
    ];
    const dayOptions = reactive([
      { text: i18n.t('daysOfWeek.mon'), value: 0 },
      { text: i18n.t('daysOfWeek.tue'), value: 1 },
      { text: i18n.t('daysOfWeek.wed'), value: 2 },
      { text: i18n.t('daysOfWeek.thu'), value: 3 },
      { text: i18n.t('daysOfWeek.fri'), value: 4 },
      { text: i18n.t('daysOfWeek.sat'), value: 5 },
      { text: i18n.t('daysOfWeek.sun'), value: 6 },
      { text: i18n.t('nationalHoliday'), value: 7 },
      { text: i18n.t('scheduledHoliday'), value: 8 },
      { text: i18n.t('legalHoliday'), value: 9 },
    ]);
    const weekOptions = reactive([
      { text: i18n.t('weekly'), value: 0 },
      { text: i18n.t('1st'), value: 1 },
      { text: i18n.t('2st'), value: 2 },
      { text: i18n.t('3st'), value: 3 },
      { text: i18n.t('4st'), value: 4 },
      { text: i18n.t('5st'), value: 5 },
    ]);
    const optionsChoseTime = reactive([
      { text: i18n.t('onTheDay'), value: 0 },
      { text: i18n.t('beforeDay'), value: 1 },
      { text: i18n.t('nextDay'), value: 2 },
    ]);
    const monthOptions = reactive([
      { text: i18n.t('monthy'), value: 0 },
      { text: i18n.t('jan'), value: 1 },
      { text: i18n.t('feb'), value: 2 },
      { text: i18n.t('mar'), value: 3 },
      { text: i18n.t('apr'), value: 4 },
      { text: i18n.t('may'), value: 5 },
      { text: i18n.t('jun'), value: 6 },
      { text: i18n.t('jul'), value: 7 },
      { text: i18n.t('aug'), value: 8 },
      { text: i18n.t('sep'), value: 9 },
      { text: i18n.t('oct'), value: 10 },
      { text: i18n.t('nov'), value: 11 },
      { text: i18n.t('dec'), value: 12 },
    ]);
    const optionDate = ref();
    optionDate.value = Array.from(Array(31)).map((e, i) => {
      return { value: i + 1, text: i + 1 };
    });
    optionDate.value.push({ text: i18n.t('end'), value: 0 });
    const dateOptions = reactive(optionDate);
    const labelCheckboxFollowingDay = {
      label: i18n.t('followingDay'),
    };
    let paramsAddTimezoneSettings = ref<TimezoneDivisionParams>({
      time_zone_code: '',
      time_zone_name: '',
      setting_target_date: 0,
      time_zone_details: [
        {
          month: null,
          date: null,
          week: null,
          day: null,
          day_or_date: null,
          week_or_month: null,
          time_zone_start: '',
          time_zone_end: '',
          time_zone_start_on_day: null,
          time_zone_end_on_day: null,
          time_zone_detail_id: null,
        },
      ],
    });
    const isDisableAdd = (item) => {
      if (paramsAddTimezoneSettings.value.time_zone_details?.length === 20) {
        return true;
      }
      return !(
        item &&
        (item.time_zone_start_on_day || item.time_zone_start_on_day === 0) &&
        item.time_zone_start &&
        (item.time_zone_end_on_day || item.time_zone_end_on_day === 0) &&
        item.time_zone_end
      );
    };
    const addTimezoneDetail = (item, dataLength) => {
      if (isDisableAdd(item)) {
        return;
      }
      if (dataLength >= 20) return;
      count.value += 1;
      paramsAddTimezoneSettings.value.time_zone_details?.push({
        month: null,
        date: null,
        week: null,
        day: null,
        day_or_date: null,
        week_or_month: null,
        time_zone_start: null,
        time_zone_end: null,
        time_zone_start_on_day: null,
        time_zone_end_on_day: null,
        time_zone_detail_id: null,
      });

      setTimeout(() => {
        if (paramsAddTimezoneSettings.value.time_zone_details) {
          const element = document.getElementsByClassName(
            `tr-timezone_detail_${
              paramsAddTimezoneSettings.value.time_zone_details.length - 1
            }`
          )[0];
          scrollIntoView(element);
        }
      });
    };
    const removeTimezoneDetail = (index) => {
      paramsAddTimezoneSettings.value.time_zone_details?.splice(index, 1);
    };
    const errors = ref({});
    const listErrorServerReturn = ref({});
    const currentTab = ref(4);
    const validated = ref(false);
    const isValid = () => {
      validated.value = true;
      store.commit('root/SET_VALIDATE', [TypeErrors.required]);
      for (const property in errors.value) {
        if (errors.value[property].length > 0) {
          return false;
        }
      }
      return true;
    };
    const {
      timezoneDetail,
      getTimezoneDetailSettings,
      loading: loadingDetail,
    } = useTimezoneDetailSetting();
    if (timezoneId) {
      getTimezoneDetailSettings(timezoneId);
    }

    const submitCreateTimezoneDivisionSettings = () => {
      if (!isValid()) return;
      loadingDetail.value = true;
      const { registered } = timezoneSettingsRegister(
        paramsAddTimezoneSettings.value
      );
      registered
        .then((error) => {
          if (error) {
            listErrorServerReturn.value = error.errors.value;
            if (error?.errorsCommon.value) {
              showToast('error', error?.errorsCommon.value);
            }
          } else {
            listErrorServerReturn.value = '';
            showToast(
              'success',
              i18n.t('successMessage.timezoneDivisionCreated')
            );
            router.push(ConstantsRouter.Timezone);
          }
          loadingDetail.value = false;
        })
        .catch(() => {
          loadingDetail.value = false;
          showToast('error', i18n.t('errors.serverError'));
        });
    };
    const submitUpdateTimezone = (id) => {
      if (!isValid()) return;
      loadingDetail.value = true;
      const { update } = updateTimezone(id, paramsAddTimezoneSettings.value);
      update
        .then((error) => {
          if (error) {
            listErrorServerReturn.value = error.errors.value;
            if (error?.errorsCommon.value) {
              showToast('error', error?.errorsCommon.value);
            }
          } else {
            store.commit('root/SET_NOTIFICATION', {
              type: 'success',
              message: i18n.t('successMessage.timezoneDivisionUpdated'),
            });
            router.push(ConstantsRouter.Timezone);
          }
        })
        .catch(() => {
          loadingDetail.value = false;
          showToast('error', i18n.t('errors.serverError'));
        });
    };
    const submitCreateOrUpdateTimezoneDivisionSettings = () => {
      if (actionType.value === ActionType.CREATE) {
        submitCreateTimezoneDivisionSettings();
      } else {
        submitUpdateTimezone(timezoneId);
      }
    };
    onBeforeMount(() => {
      store.commit('root/SET_BACK_ROUTER_PATH', '/settings/time-zone-session');
      store.commit('root/SET_CURRENT_PAGE', i18n.t('timeZoneDivisionSetting'));
    });
    watchEffect(() => {
      const setting_target_date =
        paramsAddTimezoneSettings.value.setting_target_date;
      paramsAddTimezoneSettings.value.time_zone_details?.forEach((item) => {
        item.day = setting_target_date === 0 ? item.day_or_date : item.day;
        item.date = setting_target_date === 1 ? item.day_or_date : item.date;
        item.week = setting_target_date === 0 ? item.week_or_month : item.week;
        item.month =
          setting_target_date === 1 ? item.week_or_month : item.month;
      });
    });
    watchEffect(() => {
      if (timezoneId) {
        paramsAddTimezoneSettings.value.time_zone_details = [];
        const time_zone_details: TimezoneDivisionDetail[] = [];
        timezoneDetail.value?.time_zone_details?.forEach((item) => {
          time_zone_details.push({
            ...item,
            day_or_date:
              timezoneDetail.value?.setting_target_date === 0
                ? item.day
                : item.date,
            week_or_month:
              timezoneDetail.value?.setting_target_date === 0
                ? item.week
                : item.month,
          });
        });
        paramsAddTimezoneSettings.value = {
          id: timezoneDetail.value?.id,
          time_zone_code: timezoneDetail.value?.time_zone_code,
          time_zone_name: timezoneDetail.value?.time_zone_name,
          time_zone_details: time_zone_details,
          setting_target_date: timezoneDetail.value?.setting_target_date || 0,
        };
      }
    });
    const count = ref(0);
    const optionTargetDate = reactive([
      {
        text: i18n.t('timezoneHeaders.dayOfWeek'),
        value: 0,
      },
      {
        text: i18n.t('dateAndTime'),
        value: 1,
      },
    ]);
    const onSelect = (value) => {
      paramsAddTimezoneSettings.value.setting_target_date = value;
      paramsAddTimezoneSettings.value.time_zone_details &&
        paramsAddTimezoneSettings.value.time_zone_details.map((item) => {
          item.day_or_date = value === 0 ? item.day : item.date;
          item.week_or_month = value === 0 ? item.week : item.month;
        });
      count.value += 1;
    };
    const handleBackToTheList = () => {
      router.push(ConstantsRouter.Timezone);
    };
    return {
      t: i18n.t,
      isValid,
      addTimezoneDetail,
      removeTimezoneDetail,
      submitCreateTimezoneDivisionSettings,
      submitUpdateTimezone,
      submitCreateOrUpdateTimezoneDivisionSettings,
      onSelect,
      handleBackToTheList,
      isDisableAdd,
      timezoneHeaders,
      Plus,
      labelCheckboxFollowingDay,
      dayOptions,
      dateOptions,
      paramsAddTimezoneSettings,
      errors,
      listErrorServerReturn,
      currentTab,
      optionTargetDate,
      validated,
      weekOptions,
      monthOptions,
      optionsChoseTime,
      loadingDetail,
      count,
    };
  },
});
