
import { computed, defineComponent, ref } from 'vue';
import Switch from '@/components/share/Switch/index.vue';
import { useI18n } from 'vue-i18n';
import { convertToDateTime } from '@/util/composables';
import { useLoginAccountStore } from '@/store/modules/login-account';
import router from '@/router';
import { watchEffect } from '@vue/runtime-core';
import Avatar from '@/components/share/Avatar/index.vue';
import { getListAvatarUser } from '@/api/composables/useGetListAvatarUser';
import { TotalSpeacialOvertime } from '@/model/MyTimeCardByMonthly';
import {
  TOTAL_SPEACIAL_OVER_TIME_TEXT,
  TOTAL_SPEACIAL_OVER_TIME_TYPE,
} from '@/constants/typeTotalSpeacialOverTime';

export default defineComponent({
  props: {
    detailTimeCardMonthly: {
      type: Array,
      default: () => [],
    },
    timeSummary: {
      type: Array,
      default: () => [],
    },
    daySummary: {
      type: Array,
      default: () => [],
    },
    totalTimeOver: {
      type: (Array as unknown) as () => Array<TotalSpeacialOvertime[]>,
      default: () => [],
    },
  },
  components: {
    Switch,
    Avatar,
  },
  setup(props) {
    const i18n = useI18n();
    const accountStore = useLoginAccountStore();
    const myAccount = computed(() => accountStore.state.myAccount);
    const ppEmployeeId = router.currentRoute.value.query.id;
    const userInformationTimeCardId = ref({
      employee_code: router.currentRoute.value.query.code,
      employee_name: router.currentRoute.value.query.name,
      unit_name: router.currentRoute.value.query.unitName,
    });
    const nameAvatar = ref([]);
    const getAvatarEmployee = async () => {
      if (ppEmployeeId) {
        const res = await getListAvatarUser([ppEmployeeId]);
        nameAvatar.value = res.data;
      } else {
        const res = await getListAvatarUser([myAccount.value.pp_employee_id]);
        nameAvatar.value = res.data;
      }
    };
    getAvatarEmployee();
    const daySummaryHeaders = [
      {
        text: i18n.t('weekdayCommutingDays'),
        value: 'total_work_day_of_month',
      },
      {
        text: i18n.t('holidayWorkDays'),
        value: 'total_holiday_day_of_month',
      },
      // hide in parse
      // {
      //   text: i18n.t('numberOfAbsenteeDays'),
      //   value: 'total_number_of_absentee_days',
      // },
      {
        text: i18n.t('timeCardNew.numberOfHolidays'),
        value: 'total_number_of_holidays',
      },
      // hide in parse
      // {
      //   text: i18n.t('timeCardNew.numberOfDayHolidays'),
      //   value: 'total_number_of_taken_holidays',
      // },
      // {
      //   text: i18n.t('timeCardNew.numberOfDayTakeSpecial'),
      //   value: 'total_number_of_taken_special_holiday_with_paid',
      // },
      // {
      //   text: i18n.t('timeCardNew.numberOfDayTakeSpecialHoliday'),
      //   value: 'total_number_of_taken_special_holiday_without_paid',
      // },
      {
        text: i18n.t('timeCardNew.numberLateArrivals'),
        value: 'total_number_of_lates',
      },
      {
        text: i18n.t('timeCardNew.numberOfEarlyDepartures'),
        value: 'total_number_of_leave_earlies',
      },
      {
        text: i18n.t('timeCardNew.numberOfWaits'),
        value: 'total_number_of_waits',
      },
    ];
    const timeSummaryHeadersInit = [
      {
        text: i18n.t('laborStandardsHours'),
        value: 'total_working_standard_hours',
      },
      {
        text: i18n.t('scheduledWorkingHours'),
        value: 'total_prescribed_working_time',
      },
      {
        text: i18n.t('overtimeWorkingHours'),
        value: 'total_prescribed_outside_of_working_time',
      },
      // {
      //   text: i18n.t('nonStatutoryWorkingHours'),
      //   value: 'total_number_of_taken_special_holiday_with_paid',
      // },
      {
        text: i18n.t('nonStatutoryWorkingHours'),
        value: 'total_statutory_outside_of_working_time',
      },
      {
        text: i18n.t('longNightWorkingHours'),
        value: 'total_midnight_working_time',
      },
      {
        text: i18n.t('legalHolidayWorkingHours'),
        value: 'total_statutory_holiday_working_time',
      },
      {
        text: i18n.t('dailyAttendanceHeaders.break'),
        value: 'total_break_time',
      },
      {
        text: i18n.t('dailyAttendanceHeaders.late'),
        value: 'total_number_of_late_time',
      },
      {
        text: i18n.t('dailyAttendanceHeaders.early'),
        value: 'total_number_of_leave_early_time',
      },
      // hide in parse
      // {
      //   text: i18n.t('paidDeemedTime'),
      //   value: 'total_paid_deemed_time',
      // },
      {
        text: i18n.t('timeCardNew.GoingOutTime'),
        value: 'total_outing_time',
      },
      {
        text: i18n.t('totalOfficeWorkTime'),
        value: 'total_office_work_time',
      },
      {
        text: i18n.t('totalRemoteWorkTime'),
        value: 'total_telework_work_time',
      },
      {
        text: i18n.t('actualWorkingHours'),
        value: 'total_work_time',
      },
      // hide in parse
      // {
      //   text: i18n.t('sumWorkingHours'),
      //   value: 'total_working_hours',
      // },
    ];

    let timeSummaryHeaders = ref<
      { text: string; value: string; show?: boolean }[]
    >(timeSummaryHeadersInit);
    const convertUserTimCard = ref({});
    watchEffect(() => {
      timeSummaryHeaders.value = timeSummaryHeadersInit.slice();
      if (
        props.totalTimeOver?.length >= TOTAL_SPEACIAL_OVER_TIME_TYPE.LEVEL_1
      ) {
        timeSummaryHeaders.value.splice(6, 0, {
          text: i18n.t('specialExtraAmountOutside1'),
          value: props.totalTimeOver[0]['total_speacial_overtime_1'],
          show: true,
        });
      }
      if (
        props.totalTimeOver?.length >= TOTAL_SPEACIAL_OVER_TIME_TYPE.LEVEL_2
      ) {
        timeSummaryHeaders.value.splice(7, 0, {
          text: i18n.t('specialExtraAmountOutside2'),
          value: props.totalTimeOver[1]['total_speacial_overtime_2'],
          show: true,
        });
      }
      if (
        props.totalTimeOver?.length >= TOTAL_SPEACIAL_OVER_TIME_TYPE.LEVEL_3
      ) {
        timeSummaryHeaders.value.splice(8, 0, {
          text: i18n.t('specialExtraAmountOutside3'),
          value: props.totalTimeOver[2]['total_speacial_overtime_3'],
          show: true,
        });
      }
    });
    watchEffect(() => {
      if (ppEmployeeId) {
        convertUserTimCard.value = userInformationTimeCardId.value;
      } else {
        convertUserTimCard.value = myAccount.value;
      }
    });
    const isViewAggregates = ref(true);
    const convertNumberToDateTime = (number, format) => {
      if (!number) {
        return '-';
      }
      return convertToDateTime(number, format);
    };
    const displayTimeSummary = (value, show) => {
      if (value && show && value !== '00:00') {
        return value;
      }
      if (
        props.detailTimeCardMonthly[value] &&
        props.detailTimeCardMonthly[value] !== '00:00'
      ) {
        return props.detailTimeCardMonthly[value];
      }
      return '-';
    };

    return {
      t: i18n.t,
      daySummaryHeaders,
      myAccount,
      timeSummaryHeaders,
      timeSummaryHeadersInit,
      isViewAggregates,
      ppEmployeeId,
      convertUserTimCard,
      userInformationTimeCardId,
      TOTAL_SPEACIAL_OVER_TIME_TEXT,
      convertNumberToDateTime,
      nameAvatar,
      displayTimeSummary,
    };
  },
});
