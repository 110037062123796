
export default {
  props: {
    textTooltip: {
      type: String,
    },
    locationTooltip: {
      type: String,
      default: 'top',
    },
  },
  setup() {
    return {};
  },
};
