import api, { ApiResponse } from '@/clients/api';
import { ErrorCode } from './types/general/ErrorCode';
import {
  JSONApiResponseBody,
  JSONApiResponseErrorBody,
} from '@/api/types/general/JSONApiResponse';
import {
  SettingViewItemUpdate,
  SettingViewTimeCardData,
  SettingViewTimeCardPostType,
  SettingViewTimeCardPostAPIType,
  RecalculateTimeKeepingType,
  RecalculateTimeKeepingResponseType,
} from '@/api/types/SettingViewItem';
import ConstantsAPI from '@/constants/api';

export async function getSettingViewItem(
  id: string
): Promise<
  | ApiResponse<200, JSONApiResponseBody<SettingViewTimeCardData>>
  | ApiResponse<400, JSONApiResponseErrorBody<ErrorCode>>
  | ApiResponse<500, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.get(ConstantsAPI.SettingViewItemList(id));
}

export async function postSettingViewItem(
  postData: SettingViewTimeCardPostType
): Promise<
  | ApiResponse<200, JSONApiResponseBody<SettingViewTimeCardPostAPIType>>
  | ApiResponse<400, JSONApiResponseErrorBody<ErrorCode>>
  | ApiResponse<500, JSONApiResponseErrorBody<ErrorCode>>
> {
  const formatPostData = {
    pp_employee_id: postData.pp_employee_id,
    setting_view_time_card: JSON.stringify(postData.setting_view_time_card),
  };

  return await api.post(ConstantsAPI.SettingViewItemPost, formatPostData);
}

export async function updateSettingViewItem(
  id: string,
  data: SettingViewItemUpdate
): Promise<
  | ApiResponse<200, JSONApiResponseBody<SettingViewItemUpdate>>
  | ApiResponse<400, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.put(ConstantsAPI.SettingViewItemUpdate(id), data);
}

export async function recalculateTimeKeeping(
  data: RecalculateTimeKeepingType
): Promise<
  | ApiResponse<200, JSONApiResponseBody<RecalculateTimeKeepingResponseType>>
  | ApiResponse<400, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.post(ConstantsAPI.RecalculateTimeKeeping, data);
}
