import { GetterTree } from 'vuex';

import type { userInfo } from '@/types';

import { RootState } from '@/store';

import { State } from './state';

export type Getters = {
  getListUsers(state: State): userInfo[];
};

export const getters: GetterTree<State, RootState> & Getters = {
  getListUsers: (state) => state.listUsers,
};
