
import { defineComponent, onBeforeMount, onBeforeUnmount } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from '@/store';
import { ref } from '@vue/runtime-core';
import moment from 'moment';
import RadioGroup from '@/components/RadioGroup.vue';
import InputFile from '@/components/InputFile.vue';
import InputArea from '@/components/InputArea.vue';
import { DATE_FORMAT } from '@/constants/date';
export default defineComponent({
  components: { InputArea, InputFile, RadioGroup },
  setup() {
    const i18n = useI18n();
    const store = useStore();
    const fileUpload = ref();
    const date = ref(moment().format(DATE_FORMAT));
    const data = {
      pattern: 'パターンB  (8:00-17:00)　',
      checkin: '8:50',
      checkout: '17:50',
      late: '0:50',
      breakStartTimeRounding: '12:00',
      breakEndTimeRounding: '13:00',
      early: ' -  ',
      totalWorkingHours: ' -  ',
    };
    const applicationTypeOption = [
      { value: 0, label: i18n.t('late') },
      { value: 1, label: i18n.t('early') },
    ];
    const enterTheApplicationMessage = i18n.t('enterTheApplicationMessage');
    const onChangeFileUpload = () => {
      console.log(fileUpload.value);
    };
    onBeforeMount(() => {
      store.commit('root/SET_BACK_ROUTER_PATH', '/time-card');
    });
    onBeforeUnmount(() => {
      store.commit('root/SET_BACK_ROUTER_PATH', '');
    });
    return {
      t: i18n.t,
      date,
      data,
      applicationTypeOption,
      fileUpload,
      enterTheApplicationMessage,
      onChangeFileUpload,
    };
  },
});
