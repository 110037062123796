import { InjectionKey } from '@vue/runtime-core';
import { createStore, Store } from 'vuex';
import { PersonalFlowType, ApplicationFlowType } from '@/model/PersonalFlows';

export interface State {
  data: PersonalFlowType[];
}

export const key: InjectionKey<Store<State>> = Symbol();

export function usePersonalFlowStore() {
  return store;
}

export const store = createStore<State>({
  state() {
    return {
      data: [],
    };
  },
  mutations: {
    setPersonalFlowData(state, params: PersonalFlowType[]) {
      state.data = params;
    },
    clearPersonalFlowData(state) {
      state.data = [];
    },
    pushPersonalFlowData(state, params: PersonalFlowType) {
      state.data = state.data.concat(params);
    },
    updateApplicationFlowDetails(
      state,
      params: {
        parentIndex: number;
        data: ApplicationFlowType[];
      }
    ) {
      if (state.data.length) {
        for (let i = 0; i < params.data.length; i++) {
          params.data[i].id = i;
        }
        state.data[params.parentIndex].application_flow_details = params.data;
      }
    },
  },
});
export default store;
