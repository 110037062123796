import { InjectionKey } from '@vue/runtime-core';
import { createStore, Store, useStore as baseUseStore } from 'vuex';

export interface State {
  optionDelete: {
    isShown: boolean;
    title: string;
    body: string;
    btnOnSubmitText: string;
    onSubmit?: () => void;
  };
}

export const key: InjectionKey<Store<State>> = Symbol();

export function usePopupDeleteStore() {
  return baseUseStore(key);
}

export default createStore<State>({
  state() {
    return {
      optionDelete: {
        isShown: false,
        title: '',
        body: '',
        btnOnSubmitText: '',
      },
    };
  },
  mutations: {
    setShown(state, data) {
      state.optionDelete = { ...state.optionDelete, ...data };
    },
  },
  actions: {
    async show(context, data) {
      context.commit('setShown', data);
    },
    async hide(context) {
      context.commit('setShown', { isShown: false });
    },
  },
});
