
import ConstantsRouter from '@/constants/router';
import router from '@/router';
import { computed, defineComponent, ref, watchEffect } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import { usePostAbsenceApplication } from '@/api/composables/useCreateAbsenceApplication';
import { AbsenceApplicationApiPost } from '@/api/types/AbsenceApplication';
import { useLoginAccountStore } from '@/store/modules/login-account';
import { showToast } from '@/util/useToastMessage';
import ApplicationApproveFlowDetail from '@/components/Application/ApplicationApproveFlowDetail.vue';
import ReasonCreateApplication from '@/components/Application/ReasonCreateApplication.vue';
import { isValidateForm } from '@/util/useValidateForm';
import BackPreviousPage from '@/components/share/BackPreviousPage/index.vue';
import { formatTimeDate } from '@/util/time';
import { APPLICATION_MY_APPROVAL } from '@/constants/typeApplication';
import { useGetDetailAbsenceApplication } from '@/api/composables/useCreateAbsenceApplication';

export default defineComponent({
  components: {
    ApplicationApproveFlowDetail,
    BackPreviousPage,
    ReasonCreateApplication,
  },
  setup() {
    const i18n = useI18n();
    const t = i18n.t;
    const returnScreen = () => {
      router.push(`/new${ConstantsRouter.TimeCard}`);
    };
    const workflowDefineId = router.currentRoute.value.query.id
      ? router.currentRoute.value.query.id.toString()
      : '';
    const accountStore = useLoginAccountStore();
    const route = useRoute();
    const myAccount = computed(() => accountStore.state.myAccount);

    const apiPostData = ref<AbsenceApplicationApiPost>({
      pp_employee_id: '',
      target_date: '',
      application_reason: '',
      unit_id: '',
      workflow_define_id: '',
    });
    const targetDate = ref(
      formatTimeDate(
        route.query.target_date,
        `YYYY${t('year')}MM${t('month')}DD${t('sunday')}`
      )
    );
    const validated = ref(false);
    const isValid = () => {
      validated.value = true;
      return isValidateForm(errors);
    };
    const errorsServer = ref({});
    const errors = ref({});
    const createAbsenceApplication = async () => {
      if (!isValid()) return;
      apiPostData.value.pp_employee_id = myAccount.value.pp_employee_id;
      apiPostData.value.unit_id = myAccount.value.unit_id;
      apiPostData.value.workflow_define_id = workflowDefineId;
      apiPostData.value.target_date = formatTimeDate(route.query.target_date);
      const { postData } = usePostAbsenceApplication(apiPostData.value);
      postData
        .then((errors) => {
          if (errors) {
            errorsServer.value = errors.errors.value;
            if (errors?.errorsCommon.value) {
              showToast('error', errors?.errorsCommon.value);
            }
          } else {
            showToast('success', t('theApplicationHasBeenCreated'));
            router.push(`/new${ConstantsRouter.TimeCard}`);
          }
        })
        .catch(() => {
          showToast('error', t('errors.serverError'));
        });
    };
    // for detail application
    const applicationFormId =
      router.currentRoute.value.query.application_form_id;
    const isApproval = router.currentRoute.value.query
      .is_my_application as string;
    const goToBack = () => {
      if (parseInt(isApproval) === APPLICATION_MY_APPROVAL) {
        router.push(`/new${ConstantsRouter.ApplicationMyApproval}`);
      } else if (applicationFormId) {
        router.push(`/new${ConstantsRouter.ApplicationApprovalRequest}`);
      } else {
        router.push(`/new${ConstantsRouter.TimeCard}`);
      }
    };
    const {
      handleGetDetailAbsenceApplication,
      absenceApplication,
    } = useGetDetailAbsenceApplication();
    const getAbsenceApplication = async () => {
      if (applicationFormId) {
        await handleGetDetailAbsenceApplication(applicationFormId);
      }
    };
    getAbsenceApplication();
    const statusApplicationFlow = computed(
      () => absenceApplication.value?.application_status
    );
    const createdAt = computed(() => absenceApplication.value?.created_at);
    watchEffect(() => {
      if (absenceApplication.value?.application_define_id) {
        apiPostData.value.application_reason =
          absenceApplication.value.application_reason;
      }
    });
    const isDisableSubmit = computed(() =>
      Object.values(errors.value).some((e) => !!e)
    );

    return {
      t,
      i18n,
      returnScreen,
      apiPostData,
      validated,
      createAbsenceApplication,
      isValid,
      accountStore,
      route,
      myAccount,
      errorsServer,
      errors,
      targetDate,
      goToBack,
      statusApplicationFlow,
      createdAt,
      isDisableSubmit,
    };
  },
});
