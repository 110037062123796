
import { defineComponent, onMounted, onUnmounted, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import {
  useCURDTimezoneSetting,
  useTimezoneDetailSetting,
} from '@/api/composables/timezoneSettings';
import router from '@/router';
import Input from '@/components/share/Input/index.vue';
import ErrorMessage from '@/components/share/Input/ErrorMessage.vue';
import Button from '@/components/share/Button/index.vue';
import {
  TimezoneSettingsApiPostData,
  TimezoneSettingsApiUpdateData,
} from '@/api/types/TimezoneSettings';
import { ActionType } from '@/util/composables';
import { isValidateForm } from '@/util/useValidateForm';
import { onScrollToTopMainContent } from '@/util/useScrollIntoView';
import { showToast } from '@/util/useToastMessage';
import ConstantsRouter from '@/constants/router';
import InputTime from '@/components/share/Input/InputTime.vue';
import Dropdown from '@/components/share/Dropdown/Dropdown.vue';
import BackPreviousPage from '@/components/share/BackPreviousPage/index.vue';

export default defineComponent({
  components: {
    ErrorMessage,
    Input,
    Button,
    InputTime,
    Dropdown,
    BackPreviousPage,
  },
  setup() {
    const initialFormState = {
      time_zone_code: '',
      time_zone_name: '',
      time_zone_details: [
        {
          time_zone_start: '',
          time_zone_end: '',
          time_zone_start_day_type: 0,
          time_zone_end_day_type: 0,
        },
      ],
    };
    const [
      { errorServer },
      { onCreateTimezone, onUpdateTimezone, onClearErrorServer },
    ] = useCURDTimezoneSetting();

    const { t } = useI18n();
    const dataForm = ref<TimezoneSettingsApiUpdateData>(
      JSON.parse(JSON.stringify(initialFormState))
    );
    const validated = ref(false);
    const errors = ref({});
    const isDisabledBtn = ref(true);
    const formTitle = ref(t('signUp'));
    const submitText = ref(t('toRegister'));
    const {
      timezoneDetail,
      getTimezoneDetailSettings,
    } = useTimezoneDetailSetting();

    if (router.currentRoute.value.params.id) {
      getTimezoneDetailSettings(router.currentRoute.value.params.id);
    }

    watch(
      () => JSON.parse(JSON.stringify(dataForm.value)),
      () => {
        if (
          !dataForm.value.time_zone_code ||
          !dataForm.value.time_zone_name ||
          !dataForm.value.time_zone_details?.[0]?.time_zone_start ||
          !dataForm.value.time_zone_details?.[0]?.time_zone_end
        ) {
          isDisabledBtn.value = true;
          return;
        }
        isDisabledBtn.value = false;
      }
    );

    if (router.currentRoute.value.query.action === ActionType.UPDATE) {
      submitText.value = t('saveShiftCreation');
      formTitle.value = t('edit');
    }

    watch(
      () => router.currentRoute.value.query,
      () => {
        if (router.currentRoute.value.query.action === ActionType.UPDATE) {
          submitText.value = t('saveShiftCreation');
          formTitle.value = t('edit');
          return;
        }
        submitText.value = t('toRegister');
        formTitle.value = t('signUp');
      }
    );

    watch(
      () => router.currentRoute.value.params,
      () => {
        if (router.currentRoute.value.params.id) {
          getTimezoneDetailSettings(router.currentRoute.value.params.id);
        }
      }
    );

    watch(
      () => timezoneDetail.value,
      () => {
        if (!timezoneDetail.value?.id) return;
        const timezoneDetailData: TimezoneSettingsApiUpdateData =
          timezoneDetail.value;
        if (router.currentRoute.value.query.action === ActionType.COPY) {
          dataForm.value = {
            ...timezoneDetailData,
            time_zone_name: timezoneDetailData.time_zone_name + t('copyOf'),
            time_zone_code: '',
          };
          return;
        }
        dataForm.value = timezoneDetailData;
      }
    );

    onMounted(() => {
      onScrollToTopMainContent();
    });

    onUnmounted(() => {
      onClearError();
      onClearErrorServer();
    });

    const settingTargetDateOptions = [
      {
        label: t('dayOfWeek'),
        value: 1,
      },
      {
        label: t('dateAndTime'),
        value: 2,
      },
    ];
    const optionsChoseTime = [
      { text: t('onTheDay'), value: 0 },
      { text: t('beforeDay'), value: 1 },
      { text: t('nextDay'), value: 2 },
    ];

    const onSubmitForm = () => {
      validated.value = true;
      if (!isValidateForm(errors)) return;
      onSubmitTimeZoneSessionSetting(dataForm.value);
    };

    const onSubmitTimeZoneSessionSetting = (
      data: TimezoneSettingsApiPostData
    ) => {
      if (
        !router.currentRoute.value.params.id ||
        router.currentRoute.value.query.action === ActionType.COPY
      ) {
        handleCreateTimezone(data);
        return;
      }
      handleUpdateTimezone(data);
    };

    const handleCreateTimezone = (data) => {
      onCreateTimezone({
        data,
        onSuccess: () => {
          showToast('success', t('successMessage.timezoneDivisionCreated'));
          onCloseSignupForm();
        },
        onFail: handleUpdatingAndCreatingTimezoneError,
      });
    };

    const handleUpdateTimezone = (data) => {
      onUpdateTimezone({
        data,
        onSuccess: () => {
          showToast('success', t('successMessage.timezoneDivisionUpdated'));
          onCloseSignupForm();
        },
        onFail: handleUpdatingAndCreatingTimezoneError,
      });
    };

    const handleUpdatingAndCreatingTimezoneError = (error) => {
      if (error?.errorsCommon?.value) {
        showToast('error', error.errorsCommon.value);
        return;
      }
      showToast('warning', t('invalidInput'));
    };

    const onCloseSignupForm = () => {
      router.push('/new' + ConstantsRouter.TimeZoneSessionList);
      onClearErrorServer();
    };

    const onClearError = () => {
      // TODO: get rid of setTimeout
      setTimeout(() => {
        errors.value = {};
      });
    };
    return {
      errorServer,
      submitText,
      formTitle,
      isDisabledBtn,
      dataForm,
      errors,
      settingTargetDateOptions,
      validated,
      optionsChoseTime,
      t,
      onCloseSignupForm,
      onSubmitForm,
    };
  },
});
