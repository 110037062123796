
import { defineComponent, ref, toRefs, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import Table from '@/components/TableRecusion/TableRecusion.vue';
import iconCircleBlue from '@/assets/svg/iconCircleBlue.svg';
import iconTriangleOrange from '@/assets/svg/iconTriangleOrange.svg';
import iconSquareGreen from '@/assets/svg/iconSquareGreen.svg';
import iconXMarkRed from '@/assets/svg/iconXMarkRed.svg';
import ConstantsRouter from '@/constants/router';
import router from '@/router';
import {
  handleUpdateAuthority,
  useGetListAuthority,
} from '@/api/composables/authority';
import { map } from 'lodash';
import { isEmpty } from '@/util/useValidateForm';
import { FunctionDefines, FunctionPolicies } from '@/api/types/Authority';
import { showToast } from '@/util/useToastMessage';
export default defineComponent({
  components: {
    Table,
  },
  props: {
    listRolesSelected: {
      type: Array,
    },
  },
  setup(props) {
    const i18n = useI18n();

    const VALUE_PERMISSION = {
      AVAI_FOR_ALL: 0,
      ONLY_EMP_OF_DEPARTMENT: 1,
      ONLY_YOU: 2,
      NOT_VIEWABLE: 3,
    };
    const { listRolesSelected } = toRefs(props);
    const listStringRoleIds = map(
      listRolesSelected ? listRolesSelected.value : [],
      'value'
    );
    const rowFirst = {
      text: i18n.t('authorSettingTarget'),
      checked: true,
      value: 'function_name',
      width: '300px',
      position: 'sticky',
      zIndex: 100,
      left: 0,
      background: '#fff',
      borderRight: '1px solid #dcdcdc',
    };
    let datasHeader =
      listRolesSelected && listRolesSelected.value
        ? [...listRolesSelected.value]
        : [];

    datasHeader.unshift(rowFirst);
    const t = i18n.t;
    const { authorityData, getAuthorityListData } = useGetListAuthority();
    const findLevelChild = (arrays, parentId) => {
      if (!parentId) return 0;
      let level = 1;

      const recusion = (id) => {
        const findParent = arrays.find((el) => el.function_code === id);
        if (findParent && findParent.parent_id) {
          level += 1;
          recusion(findParent.parent_id);
        }
      };
      recusion(parentId);
      return level;
    };

    const mapNodeChilds = (flatArr) => {
      const datas: FunctionDefines[] = [];
      flatArr.forEach((node) => {
        if (!node.parent_id) return datas.push(node);
        const parentIndex = flatArr.findIndex(
          (el) => el.function_code === node.parent_id
        );
        if (flatArr[parentIndex] && !flatArr[parentIndex]?.childs) {
          return (flatArr[parentIndex].childs = [node]);
        }

        flatArr[parentIndex] && flatArr[parentIndex].childs?.push(node);
      });
      return datas;
    };
    getAuthorityListData(listStringRoleIds?.join('&roleIds[]='));
    watch(authorityData, () => {
      if (authorityData.value) {
        let { authorities, function_defines } = authorityData.value;
        if (!isEmpty(function_defines)) {
          function_defines = function_defines.map((funcDefine) => {
            return {
              ...funcDefine,
              child_lv: findLevelChild(function_defines, funcDefine.parent_id),
              show_child: true,
            };
          });
          authorities.map((authori) => {
            function_defines = function_defines.map((funcDefine) => {
              const findFuncDefines = authori.function_policies.find(
                (funcPoli) =>
                  funcPoli.function_define_id === funcDefine.function_define_id
              );
              return {
                ...funcDefine,
                [authori.role_id]: findFuncDefines
                  ? findFuncDefines.permission
                  : VALUE_PERMISSION.NOT_VIEWABLE,
              };
            });
          });
          priviEditDatas.value = mapNodeChilds(function_defines);
        }
      }
    });

    let priviEditDatas = ref<FunctionDefines[]>([]);

    const listIcons = [
      {
        text: `: ${t('allEmployeesCanView')}`,
        icon: iconCircleBlue,
      },
      {
        text: `: ${t('onlyViewableByEmpOfTheDepartment')}`,
        icon: iconSquareGreen,
      },
      {
        text: `: ${t('onlyYouCanView')}`,
        icon: iconTriangleOrange,
      },
      {
        text: `: ${t('notViewable')}`,
        icon: iconXMarkRed,
      },
    ];
    const changeAllValueChildByParent = (
      dataChilds,
      key_value,
      valueNext,
      toggleRow
    ) => {
      if (dataChilds && dataChilds.length > 0) {
        dataChilds.map((dataChild) => {
          dataChild[key_value] = toggleRow ? !dataChild[key_value] : valueNext;
          if (dataChild.childs && dataChild.childs.length > 0) {
            changeAllValueChildByParent(
              dataChild.childs,
              key_value,
              valueNext,
              false
            );
          }
        });
      }
    };
    const recursionDatas = (datas, id, key_value, toggleRow) => {
      try {
        if (datas && datas.length > 0) {
          datas.map((data) => {
            if (data.function_code === id) {
              const valueNext =
                (data[key_value] === VALUE_PERMISSION.NOT_VIEWABLE
                  ? VALUE_PERMISSION.AVAI_FOR_ALL
                  : data[key_value] + 1) || VALUE_PERMISSION.AVAI_FOR_ALL;
              data[key_value] = toggleRow ? !data[key_value] : valueNext;
              if (data.childs && data.childs.length > 0) {
                changeAllValueChildByParent(
                  data.childs,
                  key_value,
                  valueNext,
                  toggleRow
                );
              }
            }
            if (data.childs && data.childs.length > 0) {
              recursionDatas(data.childs, id, key_value, toggleRow);
            }
          });
        }
      } catch (error) {
        console.log('error', error);
      }
      priviEditDatas.value = datas;
    };

    const handleChangeValue = (item, value) => {
      const toggleRow = false;
      recursionDatas(
        priviEditDatas.value,
        item.function_code,
        value,
        toggleRow
      );
    };
    const toggleRow = (item, value) => {
      const toggleRow = true;
      recursionDatas(
        priviEditDatas.value,
        item.function_code,
        value,
        toggleRow
      );
    };
    const handleBackListRole = () => {
      router.push(ConstantsRouter.AminSettingRoleList);
    };
    const flattenTreeArray = (a, r) => {
      a.forEach(({ childs, ...rest }) => {
        r.push(rest);
        if (childs) flattenTreeArray(childs, r);
      });
    };
    const handleRegister = () => {
      const dataFlattens: FunctionDefines[] = [];
      flattenTreeArray(priviEditDatas.value, dataFlattens);
      const function_policies: FunctionPolicies[] = [];
      if (!isEmpty(dataFlattens) && !isEmpty(listStringRoleIds)) {
        dataFlattens.map((datas) => {
          listStringRoleIds.map((id) => {
            function_policies.push({
              function_define_id: datas.function_define_id,
              role_id: id,
              permission:
                datas[id] >= VALUE_PERMISSION.AVAI_FOR_ALL
                  ? datas[id]
                  : VALUE_PERMISSION.NOT_VIEWABLE,
            });
          });
        });
      }
      const { update } = handleUpdateAuthority(function_policies);
      update.then((error) => {
        if (error) {
          showToast('error', t('permissionsEditedFail'));
        } else {
          showToast('success', 'permissionsEdited');
          handleBackListRole();
        }
      });
    };
    return {
      t,
      priviEditDatas,
      handleChangeValue,
      toggleRow,
      listIcons,
      handleBackListRole,
      datasHeader,
      handleRegister,
    };
  },
});
