
import { defineComponent, ref, PropType, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import Modal from '@/components/share/Modal/index.vue';
import draggable from 'vuedraggable';
import { postSettingViewAPI } from '@/api/composables/settingViewItem';
import { getCookie } from '@/util/composables';
import { SettingViewTimeCardItemType } from '@/model/SettingViewItem';
import { showToast } from '@/util/useToastMessage';

export default defineComponent({
  components: { Modal, draggable },
  props: {
    viewTimeCardDefine: {
      type: Array as PropType<SettingViewTimeCardItemType[]>,
      default: () => [],
    },
    viewTimeCardSelected: {
      type: Array as PropType<SettingViewTimeCardItemType[]>,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const i18n = useI18n();
    const isShow = ref(false);
    const pp_employee_id = getCookie('pp_employee_id') || '';

    const enabled = ref(true);
    const dragging = ref(false);

    const draggingInfo = () => {
      return dragging.value ? 'under drag' : '';
    };
    const handleCancel = () => {
      isShow.value = false;
      emit('onClose');
    };
    // post data
    const postSettingViewTimeCard = async () => {
      const arrTimeCardSelected = listSelected.value.map(
        (item: SettingViewTimeCardItemType, index: number) => {
          return {
            data_master_name: item.data_master_name,
            data_master_code: item.data_master_code,
            displayOrder: index,
          };
        }
      );
      const { registered } = postSettingViewAPI({
        pp_employee_id: pp_employee_id,
        setting_view_time_card: {
          data: arrTimeCardSelected,
        },
      });
      await registered
        .then((error) => {
          if (error) {
            showToast('error', i18n.t('errors.updateTimeCardFail'));
          } else {
            showToast('success', i18n.t('successMessage.updateTimeCard'));
          }
        })
        .catch(() => {
          showToast('error', i18n.t('errors.serverError'));
        });
      emit('onClose');
    };

    const listDefine = ref<SettingViewTimeCardItemType[]>(
      props.viewTimeCardDefine
    );
    const listSelected = ref<SettingViewTimeCardItemType[]>(
      props.viewTimeCardSelected
    );
    watch(
      () => props.viewTimeCardDefine,
      () => {
        listDefine.value = props.viewTimeCardDefine;
      }
    );

    watch(
      () => props.viewTimeCardSelected,
      () => {
        listSelected.value = props.viewTimeCardSelected;
      }
    );
    return {
      t: i18n.t,
      isShow,
      handleCancel,
      postSettingViewTimeCard,
      enabled,
      dragging,
      draggingInfo,
      listDefine,
      listSelected,
    };
  },
});
