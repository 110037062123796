import { ref } from 'vue';
import { getShiftPlanDepartment } from '@/api/shiftPlanDepartment';

export default function () {
  const shiftPlanOption = ref();

  async function getPlanDepartmentData() {
    try {
      const res = await getShiftPlanDepartment();
      switch (res.status) {
        case 200:
          shiftPlanOption.value = res.data.data;
          break;
        default:
          shiftPlanOption.value = [];
          break;
      }
    } catch (error) {
      console.error(error);
    }
  }

  return {
    shiftPlanOption,
    getPlanDepartmentData,
  };
}
