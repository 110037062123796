
import { defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import Table from '@/components/Table.vue';
import DatePicker from '@/components/DatePicker.vue';
import moment from 'moment';
import Select from '@/components/Select.vue';
import Button from '@/components/Button.vue';
import {
  useGetRequestMyShift,
  usePutRequestMyShift,
} from '@/api/composables/useRequestShift';
import { useLoginAccountStore } from '@/store/modules/login-account';
import { computed, watchEffect } from '@vue/runtime-core';
import { RequestMyShiftApiGetParams } from '@/api/types/RequestShiftApi';
import { EmployeeRegisterShift } from '@/model/RequestMyShift';
import router from '@/router';
import InputTime from '@/components/InputTime.vue';
import { showToast } from '@/util/useToastMessage';
import { useGetShiftPatternList } from '@/api/composables/useShiftPattern';
import { ShiftPatternsApiParamSearch } from '@/api/types/ShiftPatern';
import { MONTH_FORMAT } from '@/constants/date';
import { isValidateForm } from '@/util/useValidateForm';
import { EMPLOYEE_EXPERT_TYPE } from '@/constants/shiftPlan';
import { WEEK_DAY } from '@/constants/workingStyleMaster';

export default defineComponent({
  components: { InputTime, Button, Select, Table, DatePicker },
  setup() {
    const i18n = useI18n();
    const date = ref(moment().format(MONTH_FORMAT));
    const submitDesiredHeaders = ref([
      {
        text: i18n.t('date'),
        value: 'date',
      },
      {
        text: i18n.t('desiredWorkPattern'),
        value: 'desired_work_pattern',
      },
      {
        text: i18n.t('targetTime'),
        value: 'target_time',
        width: '200px',
      },
    ]);
    Array.from(Array(24)).forEach((item, index) => {
      submitDesiredHeaders.value.push({
        text: `${index}:00`,
        value: index === 0 ? 'time_min' : '',
        width: '100px',
      });
    });
    const shiftPatternOptions = [
      {
        text: i18n.t('unSelect'),
        value: null,
        color: '#FF0000',
      },
      {
        text: i18n.t('selectFromWorkPatterns'),
        value: EMPLOYEE_EXPERT_TYPE.SELECT_FROM_WORK_PATTERN,
        color: '#22AEE5',
      },
      {
        text: i18n.t('addManual'),
        value: EMPLOYEE_EXPERT_TYPE.ADD_MANUALLY,
        color: '#f8bf2d',
      },
      {
        text: i18n.t('impossibility'),
        value: EMPLOYEE_EXPERT_TYPE.IMPOSSIBILITY,
        color: '#979797',
      },
    ];
    const getColorDate = (date) => {
      const day = moment(date).day();
      if (day === WEEK_DAY.SATURDAY) {
        return '#22AEE5';
      }
      if (day === WEEK_DAY.SUNDAY) {
        return '#ED5D5D';
      }
      return '#333333';
    };
    const displayDate = (date) => {
      if (date) {
        return moment(date).format('MM / DD (dd)');
      }
      return '';
    };
    const displayManagerConfirmWorkTime = (item) => {
      if (
        item &&
        item.shift_plan_employee_date?.manager_confirm_work_start_time &&
        item.shift_plan_employee_date?.manager_confirm_work_end_time
      )
        return `確定 (${item.shift_plan_employee_date?.manager_confirm_work_start_time} ～ ${item.shift_plan_employee_date?.manager_confirm_work_end_time})`;
    };
    const styleTimeLine = (startTime, endTime) => {
      if (!startTime || !endTime) return;
      const minTime = moment('0:0', 'HH:mm:ss');
      startTime = moment(startTime, 'HH:mm:ss');
      endTime = moment(endTime, 'HH:mm:ss');
      const minutes = endTime.diff(startTime, 'minutes');
      const startMinutes = startTime.diff(minTime, 'minutes');
      return {
        width: (minutes / 60) * 100 + 'px',
        left: (startMinutes / 60) * 100 + 'px',
      };
    };

    const shiftPlanId = router.currentRoute.value.query.id as string;
    const accountStore = useLoginAccountStore();
    const myAccount = computed(() => accountStore.state.myAccount);
    const { getRequestMyShiftData, requestMyShift } = useGetRequestMyShift();
    const requestMyShiftApiGetParams = ref<RequestMyShiftApiGetParams>({});
    const getRequestMyShift = async () => {
      if (myAccount.value.pp_employee_id && shiftPlanId) {
        requestMyShiftApiGetParams.value = {
          pp_employee_id: myAccount.value.pp_employee_id,
          shift_plan_id: shiftPlanId,
        };
        await getRequestMyShiftData(requestMyShiftApiGetParams.value);
        requestMyShiftPostData.value.pp_employee_id =
          myAccount.value.pp_employee_id;
        requestMyShiftPostData.value.dates = [];
        requestMyShift.value.submit_desired_shifts.forEach((item) => {
          requestMyShiftPostData.value.dates.push({
            target_date: item.target_date || '',
            employe_expect_type:
              item.shift_plan_employee_date?.employee_expect_type,
            expected_shift_pattern_id:
              item.shift_plan_employee_date?.expect_shift_pattern_id,
            employee_expect_work_start_time:
              item.shift_plan_employee_date?.employee_expect_work_start_time,
            employee_expect_work_end_time:
              item.shift_plan_employee_date?.employee_expect_work_end_time,
          });
        });
      }
    };
    const shiftPatternSearchParams = ref<ShiftPatternsApiParamSearch>({
      is_all: true,
      unit_id: '',
    });
    const { getShiftPatternList, shiftPatternData } = useGetShiftPatternList();
    watchEffect(() => {
      getRequestMyShift();
      if (myAccount.value.pp_employee_id && shiftPlanId) {
        shiftPatternSearchParams.value.unit_id = myAccount.value.unit_id;
        getShiftPatternList(shiftPatternSearchParams.value);
      }
    });

    const isBtnHandOver = ref(true);
    const errors = ref({});
    const validated = ref(false);
    const isValid = () => {
      validated.value = true;
      return isValidateForm(errors);
    };
    const requestMyShiftPostData = ref<EmployeeRegisterShift>({
      dates: [],
    });
    const errorServer = ref({});
    const onClickBtnHandOver = async () => {
      if (!isValid()) return;
      isBtnHandOver.value = false;
      const { putRequestMyShiftData } = usePutRequestMyShift();
      await putRequestMyShiftData(shiftPlanId, requestMyShiftPostData.value)
        .then((error) => {
          if (error) {
            errorServer.value = error.errors.value;
            if (error.errorsCommon.value) {
              showToast('error', error.errorsCommon.value);
            }
          } else {
            showToast('success', i18n.t('successMessage.shiftSubmitted'));
            getRequestMyShift();
          }
        })
        .catch(() => {
          showToast('error', i18n.t('errors.serverError'));
        });
    };
    const onClickBtnWithdrawSubmission = async () => {
      isBtnHandOver.value = true;
      // TODO
    };

    const disableInputTime = (index) => {
      return (
        requestMyShiftPostData.value.dates[index].employe_expect_type !==
        EMPLOYEE_EXPERT_TYPE.ADD_MANUALLY
      );
    };
    const changeEmployeeExpertType = (index) => {
      if (
        requestMyShiftPostData.value.dates[index].employe_expect_type !==
        EMPLOYEE_EXPERT_TYPE.SELECT_FROM_WORK_PATTERN
      ) {
        requestMyShiftPostData.value.dates[index].expected_shift_pattern_id =
          '';
        requestMyShiftPostData.value.dates[
          index
        ].employee_expect_work_start_time = '';
        requestMyShiftPostData.value.dates[
          index
        ].employee_expect_work_end_time = '';
      }

      if (
        requestMyShiftPostData.value.dates[index].employe_expect_type ===
        EMPLOYEE_EXPERT_TYPE.IMPOSSIBILITY
      ) {
        requestMyShiftPostData.value.dates[
          index
        ].employee_expect_work_start_time = '00:00';
        requestMyShiftPostData.value.dates[
          index
        ].employee_expect_work_end_time = '00:00';
      }
    };
    const isShowTimeLineRequest = (index) => {
      return (
        requestMyShiftPostData.value.dates[index]
          ?.employee_expect_work_start_time &&
        requestMyShiftPostData.value.dates[index]
          ?.employee_expect_work_end_time &&
        !requestMyShift.value.submit_desired_shifts[index]
          ?.shift_plan_employee_date?.employee_expect_work_start_time &&
        !requestMyShift.value.submit_desired_shifts[index]
          ?.shift_plan_employee_date?.employee_expect_work_end_time
      );
    };
    const stylePositionShiftPatternList = ref({
      top: '0px',
      left: '0px',
    });
    const isShowShiftPatternList = ref(false);
    const openShiftPatternList = (event) => {
      const e = event.e;
      const item = event.item;
      if (item.value !== EMPLOYEE_EXPERT_TYPE.SELECT_FROM_WORK_PATTERN) return;
      isShowShiftPatternList.value = true;
      stylePositionShiftPatternList.value.top = `${
        e.getBoundingClientRect().top + e.getBoundingClientRect().height * 2
      }px`;
      stylePositionShiftPatternList.value.left = `${
        e.getBoundingClientRect().left + e.getBoundingClientRect().width
      }px`;
    };
    const closeShiftPatternList = () => {
      isShowShiftPatternList.value = false;
    };
    const choseShiftPattern = (item, index) => {
      requestMyShiftPostData.value.dates[index].expected_shift_pattern_id =
        item.shift_pattern_id;
      requestMyShiftPostData.value.dates[
        index
      ].employee_expect_work_start_time =
        item.shift_pattern_times[0].start_time;
      requestMyShiftPostData.value.dates[index].employee_expect_work_end_time =
        item.shift_pattern_times[0].end_time;
      requestMyShiftPostData.value.dates[index].expected_shift_pattern_name =
        item.shift_pattern_name;
    };
    const getBackgroundOptionEmployeeExpectType = (index) => {
      return shiftPatternOptions.find(
        (item) =>
          item.value ===
          requestMyShiftPostData.value.dates[index].employe_expect_type
      )?.color;
    };
    const employeeExpertTypeText = (index) => {
      if (requestMyShiftPostData.value.dates[index].expected_shift_pattern_id) {
        return requestMyShiftPostData.value.dates[index]
          .expected_shift_pattern_name;
      }
      return null;
    };

    return {
      t: i18n.t,
      submitDesiredHeaders,
      date,
      shiftPatternOptions,
      isBtnHandOver,
      requestMyShift,
      requestMyShiftPostData,
      getRequestMyShiftData,
      isShowTimeLineRequest,
      shiftPatternData,
      stylePositionShiftPatternList,
      isShowShiftPatternList,
      EMPLOYEE_EXPERT_TYPE,
      validated,
      errors,
      employeeExpertTypeText,
      getColorDate,
      displayDate,
      onClickBtnHandOver,
      onClickBtnWithdrawSubmission,
      getRequestMyShift,
      disableInputTime,
      changeEmployeeExpertType,
      displayManagerConfirmWorkTime,
      styleTimeLine,
      openShiftPatternList,
      closeShiftPatternList,
      choseShiftPattern,
      getBackgroundOptionEmployeeExpectType,
      isValid,
    };
  },
});
