import ConstantsRouter from '@/constants/router';
import { FunctionDefine } from '@/model/FunctionDefine';

function getFunctionDefineByCode(code) {
  return NAV_MENUS.find((item) => item.code === code);
}

export function getNavigationMenusByParentId(
  functionDefines: FunctionDefine[],
  parentId: string | null
) {
  const functionDefineChild = functionDefines.filter(
    (item) => item.function_define_parent_code === parentId
  );
  return functionDefineChild.map((item) => {
    return {
      ...getFunctionDefineByCode(item.function_define_code),
      text: item.function_define_name,
      child: getNavigationMenusByParentId(
        functionDefines,
        item.function_define_code
      ),
    };
  });
}

export function isPermission(functionDefines: FunctionDefine[], code) {
  if (!functionDefines) return false;
  return functionDefines.find((item) => item.function_define_code === code);
}

export function isPermissionByPath(functionDefines: FunctionDefine[], path) {
  if (path === '' || path === '/') return true;
  const menuItem = NAV_MENUS.find((item) => path.includes(item.path));
  return (
    menuItem &&
    functionDefines.find((item) => item.function_define_code === menuItem.code)
  );
}

export const FUNCTION_DEFINES_CODE = {
  HOME: '0001', // ホーム
  STAMP: '0002', // 打刻
  WORK_STYLE_ALERT: '0003', // 働き方改革アラート設定
  TIME_CARD: '0004', // 勤怠実績
  APPLICATION_APPROVAL: '0005', // 申請・承認
  SUBMIT_DESIRED_SHIFT: '0006', // シフト確認
  SHIFT_BEING_CREATED_NEW: '0007', // シフト作成
  ERROR_ALERT: '0008', // エラー/アラート
  DATA_OUTPUT: '0009', // データ出力
  VACATION_MANAGEMENT: '0010', // 休暇管理
  MONTHLY_PROCESSING: '0011', // 月次処理
  SETTINGS: '0012', // 設定

  ORGANIZATIONAL_STAFF_MASTER: '0013', // 組織・スタッフマスタ
  ORGANIZATIONAL_INFORMATION: '0014', // 組織登録・編集
  ORGANIZATIONAL_LEVEL_SETTING: '0015', // 組織階層設定
  EMPLOYEE_INFORMATION: '0016', // スタッフ登録・編集
  JOB_TITLE_SETTING: '0017', // 役職設定

  ATTENDANCE_MASTER: '0018', // 勤怠マスタ
  VACATION_MASTER: '0019', // 休暇マスタ
  HOLIDAYS: '0020', // 所定労働マスタ
  WORKING_PATTERN_MASTER: '0021', // 勤務パターン設定
  WORKING_STYLE_MASTER: '0022', // 就労形態マスタ
  TIMEZONE: '0023', // 時間帯区分マスタ
  ATTENDANCE_SETTING: '0024', // 勤怠設定

  MANAGER_SETTING: '0025', // 管理者設定
  ADMIN_SETTING_ROLE: '0026', // 権限設定
  APPROVED_SETTING: '0027', // 申請フロー設定
  APPLICATION_SETTING: '0028', // 申請設定
  TIME_CLOCK_SETTING: '0029', // タイムレコーダー設定
  IC_CARD_REGISTRATION: '0030', // ICカード登録
  MONTHLY_SUMMARY_OUTPUT_LAYOUT_SETTING: '0031', // 月次集計出力レイアウト設定

  DISPLAY_AND_NOTIFICATION_SETTING: '0032', // 表示・通知設定
  INDICATES_SETTING: '0033', // 表示設定
  ERROR_ALERT_SETTING: '0034', // エラー・アラート設定
  WORKING_STYLE_ALERT_SETTING: '0035', // 働き方アラート設定
  NOTIFICATION_SETTING: '0036', // 働き方アラート設定

  SCHEDULE_WORK_MASTER: '0037', // 勤務予定マスタ
  SHIFT_REGISTRATION_SETTING: '0038', // シフト登録
  SHIFT_COLLECTION_MASTER: '0039', // シフト収集マスタ

  SYSTEM_WIDE_SETTING: '040', // システムの利用状況やシステム全体の設定や確認
  USE_STATUS: '0041', // 利用状況
};

export const NAV_MENUS = [
  {
    code: FUNCTION_DEFINES_CODE.HOME,
    path: ConstantsRouter.Home,
  },
  {
    code: FUNCTION_DEFINES_CODE.STAMP,
    path: ConstantsRouter.Stamp,
    icon: 'stamp',
  },
  {
    code: FUNCTION_DEFINES_CODE.WORK_STYLE_ALERT,
    path: '', // Work style alert  働き方改革アラート設定
  },
  {
    code: FUNCTION_DEFINES_CODE.TIME_CARD,
    path: ConstantsRouter.TimeCard, // Attendance record
    icon: 'time-card',
  },
  {
    code: FUNCTION_DEFINES_CODE.APPLICATION_APPROVAL,
    path: ConstantsRouter.ApplicationApproval, // Application and approval
  },
  {
    code: FUNCTION_DEFINES_CODE.SUBMIT_DESIRED_SHIFT,
    path: ConstantsRouter.ShiftRegistration, // Shift confirmation
  },
  {
    code: FUNCTION_DEFINES_CODE.SHIFT_BEING_CREATED_NEW,
    path: ConstantsRouter.ShiftBeingCreatedNew, // Shift creation
  },
  {
    code: FUNCTION_DEFINES_CODE.ERROR_ALERT,
    path: '', // Errors / alerts
  },
  {
    code: FUNCTION_DEFINES_CODE.DATA_OUTPUT,
    path: '', // Data output
  },
  {
    code: FUNCTION_DEFINES_CODE.VACATION_MANAGEMENT,
    path: '', // Vacation management
  },
  {
    code: FUNCTION_DEFINES_CODE.MONTHLY_PROCESSING,
    path: '', // Monthly processing
  },
  {
    code: FUNCTION_DEFINES_CODE.SETTINGS,
    path: ConstantsRouter.Settings, // Configuration
    icon: 'setting',
  },
  {
    code: FUNCTION_DEFINES_CODE.ORGANIZATIONAL_STAFF_MASTER,
    path: '', // Organization / Staff Master
  },
  {
    code: FUNCTION_DEFINES_CODE.ORGANIZATIONAL_INFORMATION,
    path: ConstantsRouter.OrganizationalInformation, // Organization registration / editing
  },
  {
    code: FUNCTION_DEFINES_CODE.ORGANIZATIONAL_LEVEL_SETTING,
    path: '', // Organization level setting
  },
  {
    code: FUNCTION_DEFINES_CODE.EMPLOYEE_INFORMATION,
    path: ConstantsRouter.EmployeeInformation, // Staff registration / editing
  },
  {
    code: FUNCTION_DEFINES_CODE.JOB_TITLE_SETTING,
    path: '', // Job title setting
  },
  {
    code: FUNCTION_DEFINES_CODE.ATTENDANCE_MASTER,
    path: '', // Attendance master
  },
  {
    code: FUNCTION_DEFINES_CODE.VACATION_MASTER,
    path: '', // Vacation master
  },
  {
    code: FUNCTION_DEFINES_CODE.HOLIDAYS,
    path: ConstantsRouter.Holidays, // Prescribed labor master
  },
  {
    code: FUNCTION_DEFINES_CODE.WORKING_PATTERN_MASTER,
    path: ConstantsRouter.WorkPatternMaster, // Work pattern setting
  },
  {
    code: FUNCTION_DEFINES_CODE.WORKING_STYLE_MASTER,
    path: ConstantsRouter.WorkingStyleMaster, // Working style master
  },
  {
    code: FUNCTION_DEFINES_CODE.TIMEZONE,
    path: ConstantsRouter.Timezone, // Time zone division master
  },
  {
    code: FUNCTION_DEFINES_CODE.ATTENDANCE_SETTING,
    path: '', // Attendance setting
  },
  {
    code: FUNCTION_DEFINES_CODE.MANAGER_SETTING,
    path: '', // Manager settings
  },
  {
    code: FUNCTION_DEFINES_CODE.ADMIN_SETTING_ROLE,
    path: ConstantsRouter.AminSettingRoleList, // Permission setting
  },
  {
    code: FUNCTION_DEFINES_CODE.APPROVED_SETTING,
    path: ConstantsRouter.ApprovedSetting, // Application flow setting
  },
  {
    code: FUNCTION_DEFINES_CODE.APPLICATION_SETTING,
    path: '', // Application setting
  },
  {
    code: FUNCTION_DEFINES_CODE.TIME_CLOCK_SETTING,
    path: '', // Time clock settings
  },
  {
    code: FUNCTION_DEFINES_CODE.IC_CARD_REGISTRATION,
    path: '', // IC card registration
  },
  {
    code: FUNCTION_DEFINES_CODE.MONTHLY_SUMMARY_OUTPUT_LAYOUT_SETTING,
    path: '', // Monthly summary output layout settings
  },
  {
    code: FUNCTION_DEFINES_CODE.DISPLAY_AND_NOTIFICATION_SETTING,
    path: '', // Display and notification settings
  },
  {
    code: FUNCTION_DEFINES_CODE.INDICATES_SETTING,
    path: '', // Indicates setting
  },
  {
    code: FUNCTION_DEFINES_CODE.ERROR_ALERT_SETTING,
    path: '', // Error alert settings
  },
  {
    code: FUNCTION_DEFINES_CODE.WORKING_STYLE_ALERT_SETTING,
    path: '', // Work style alert settings
  },
  {
    code: FUNCTION_DEFINES_CODE.NOTIFICATION_SETTING,
    path: '', // Notification settings
  },
  {
    code: FUNCTION_DEFINES_CODE.SCHEDULE_WORK_MASTER,
    path: '', // Scheduled work master
  },
  {
    code: FUNCTION_DEFINES_CODE.SHIFT_REGISTRATION_SETTING,
    path: '', // Shift registration
  },
  {
    code: FUNCTION_DEFINES_CODE.SHIFT_COLLECTION_MASTER,
    path: '', // Shift collection master
  },
  {
    code: FUNCTION_DEFINES_CODE.SYSTEM_WIDE_SETTING,
    path: '', // System usage status and system-wide settings and confirmation
  },
  {
    code: FUNCTION_DEFINES_CODE.USE_STATUS,
    path: '', // Usage situation
  },
];
