
import {
  defineComponent,
  toRefs,
  watchEffect,
  watch,
  onBeforeUnmount,
} from '@vue/runtime-core';
import { useI18n } from 'vue-i18n';
import { computed, ref } from 'vue';
import { isEmpty } from '@/util/useValidateForm';
import ErrorMessage from '@/components/share/Input/ErrorMessage.vue';
export default defineComponent({
  components: { ErrorMessage },
  props: {
    placeholder: {
      type: String,
    },
    label: {
      type: String,
    },
    subLabel: {
      type: String,
    },
    modelValue: {
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    required: {
      type: Boolean,
      default: false,
    },
    max: {
      type: Number,
    },
    min: {
      type: Number,
    },
    error: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    iconPassword: {
      type: Boolean,
      default: false,
    },
    iconTime: {
      type: Boolean,
      default: false,
    },
    iconSearch: {
      type: Boolean,
      default: false,
    },
    iconPrepend: {
      type: String,
      default: '',
    },
    iconAppend: {
      type: String,
      default: '',
    },
    radius: {},
    classes: {
      type: String,
      default: '',
    },
    validated: {
      type: Boolean,
      default: false,
    },
    errorServer: {
      type: Array,
      default: () => [],
    },
    leftSearch: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    noteMessage: {
      type: String,
      required: false,
    },
    isRequiredLabel: {
      type: Boolean,
      default: false,
    },
    isFocus: {
      type: Boolean,
      default: false,
    },
    colorIcon: {
      type: String,
      default: '#666',
    },
    height: {
      default: '48px',
    },
  },
  emits: ['update:errors', 'update:modelValue', 'change'],
  setup(props, { emit }) {
    const refInput = ref<HTMLInputElement>();
    const { required, modelValue } = toRefs(props);
    const i18n = useI18n();
    const t = i18n.t;

    const isRequiredError = computed(
      () => required.value && (firstEnter.value || props.validated)
    );
    const errorMessage = ref('');
    const checkRequired = () => {
      if (required.value && isEmpty(modelValue.value)) {
        errorMessage.value = t('errors.required');
        emit('update:errors', t('errors.required'));
      } else {
        errorMessage.value = '';
        emit('update:errors', '');
      }
    };
    const firstEnter = ref(false);
    const onChangeInput = (e) => {
      emit('update:modelValue', e.target.value);
      firstEnter.value = true;
      emit('change', e);
    };
    watchEffect(() => {
      checkRequired();
    });
    // auto focus when open dropdown
    watch(
      () => props.isFocus,
      () => {
        if (props.isFocus) {
          refInput?.value?.focus();
        }
      }
    );
    onBeforeUnmount(() => {
      emit('update:errors', '');
    });

    return {
      t,
      isRequiredError,
      firstEnter,
      isEmpty,
      checkRequired,
      onChangeInput,
      refInput,
      errorMessage,
    };
  },
});
