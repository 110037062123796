import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex text-center items-center" }
const _hoisted_2 = {
  key: 0,
  class: "flex"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RadioInputGroup = _resolveComponent("RadioInputGroup")
  const _component_InputSearchEmployee = _resolveComponent("InputSearchEmployee")
  const _component_MultiSelect = _resolveComponent("MultiSelect")
  const _component_Table = _resolveComponent("Table")

  return (_openBlock(), _createBlock(_component_Table, {
    class: "mt-1",
    data: _ctx.dataTable,
    headers: _ctx.headersBasicFlow
  }, {
    action: _withCtx(({ index, item }) => [
      _createVNode("div", _hoisted_1, [
        (index !== _ctx.dataTable.length - 1)
          ? (_openBlock(), _createBlock("span", {
              key: 0,
              class: "cursor-pointer cp-icon-minus font-size-32 text-red-ff border-1 border-color-grey-dc rounded-50 hover:opacity-75",
              onClick: ($event: any) => (_ctx.removeApplicationFlow(index, item))
            }, null, 8, ["onClick"]))
          : (_openBlock(), _createBlock("span", {
              key: 1,
              class: "cp-icon-plus cursor-pointer font-size-32 text-light-blue border-1 border-color-grey-dc rounded-50 hover:opacity-75",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.addApplicationFlow && _ctx.addApplicationFlow(...args)))
            }))
      ])
    ]),
    authorizer: _withCtx(({ item }) => [
      (item.approval_level)
        ? (_openBlock(), _createBlock("div", _hoisted_2, [
            _createVNode(_component_RadioInputGroup, {
              class: "mt-4 -mr-3 min-w-40",
              "options-radio": _ctx.roleOptionList,
              modelValue: item.setting_register_flow,
              "onUpdate:modelValue": ($event: any) => (item.setting_register_flow = $event)
            }, null, 8, ["options-radio", "modelValue", "onUpdate:modelValue"]),
            (item.setting_register_flow === 0)
              ? (_openBlock(), _createBlock(_component_InputSearchEmployee, {
                  key: 0,
                  class: "flex-1",
                  "item-url": "photo",
                  modelValue: item.pp_employee_ids,
                  "onUpdate:modelValue": ($event: any) => (item.pp_employee_ids = $event),
                  required: "",
                  errors: 
            _ctx.errorsMessage[
              `application_flow_details.${_ctx.parentKey}.${item.approval_level}.${item.level_order}.pp_employee_ids`
            ]
          ,
                  "onUpdate:errors": ($event: any) => (
            _ctx.errorsMessage[
              `application_flow_details.${_ctx.parentKey}.${item.approval_level}.${item.level_order}.pp_employee_ids`
            ]
           = $event),
                  validated: _ctx.validated
                }, null, 8, ["modelValue", "onUpdate:modelValue", "errors", "onUpdate:errors", "validated"]))
              : (_openBlock(), _createBlock(_component_MultiSelect, {
                  key: 1,
                  class: "flex-1",
                  modelValue: item.role_ids,
                  "onUpdate:modelValue": ($event: any) => (item.role_ids = $event),
                  options: _ctx.listRole,
                  "item-text": "role_name",
                  "item-value": "id",
                  required: "",
                  errors: 
            _ctx.errorsMessage[
              `application_flow_details.${_ctx.parentKey}.${item.approval_level}.${item.level_order}.role_ids`
            ]
          ,
                  "onUpdate:errors": ($event: any) => (
            _ctx.errorsMessage[
              `application_flow_details.${_ctx.parentKey}.${item.approval_level}.${item.level_order}.role_ids`
            ]
           = $event),
                  validated: _ctx.validated
                }, null, 8, ["modelValue", "onUpdate:modelValue", "options", "errors", "onUpdate:errors", "validated"]))
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["data", "headers"]))
}