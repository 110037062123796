
import { computed, defineComponent, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import Checkbox from '@/components/Checkbox.vue';
import { isEmpty } from 'lodash';
import TablePriviEdit from '@/views/TablePriviEdit.vue';
import ConstantsRouter from '@/constants/router';
import router from '@/router';
import { useGetListRole } from '@/api/composables/useRole';
import { ListRolesPermission } from '@/api/types/Authority';
import PermissionSetting from '@/views/PermissionSetting.vue';

export default defineComponent({
  components: {
    Checkbox,
    TablePriviEdit,
    PermissionSetting,
  },

  setup() {
    const i18n = useI18n();
    const t = i18n.t;
    const isPopupEdit = ref(true);

    const handleCanclePopup = () => {
      isPopupEdit.value = false;
    };

    const { roleData } = useGetListRole({});
    watch(roleData, () => {
      if (roleData.value && roleData.value.length > 0) {
        roleData.value.map((data) => {
          listRoles.value.push({
            text: data.role_name || '',
            checked: false,
            value: data.id || '',
            width: '150px',
          });
        });
      }
    });

    const listRoles = ref<ListRolesPermission[]>([]);

    const listRolesSelected = computed(() =>
      listRoles.value.filter((el) => el.checked)
    );

    const isCheckAll = computed(() =>
      isEmpty(listRoles.value.filter((el) => !el.checked))
    );
    const onSelectedAll = (value) => {
      listRoles.value = listRoles.value.map((el) => {
        return {
          ...el,
          checked: value.target.checked,
        };
      });
    };
    const isValidSelectedRole = ref(true);
    const handleSavePriviSetting = () => {
      const listRolesChildChecked = listRoles.value.filter((el) => el.checked);
      if (listRolesChildChecked && listRolesChildChecked.length === 0)
        return (isValidSelectedRole.value = false);
      handleCanclePopup();
    };
    const handleBackListRole = () => {
      router.push(ConstantsRouter.AminSettingRoleList);
    };
    return {
      t,
      listRoles,
      isCheckAll,
      onSelectedAll,
      handleCanclePopup,
      isPopupEdit,
      handleSavePriviSetting,
      listRolesSelected,
      handleBackListRole,
      isValidSelectedRole,
    };
  },
});
