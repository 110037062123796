
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';
import Button from '@/components/Button.vue';
import router from '@/router';
import ConstantsRouter from '@/constants/router';

export default defineComponent({
  components: { Button },
  setup() {
    const i18n = useI18n();
    const tabOptions = [
      {
        text: i18n.t('companyCalendarSettings'),
        path: ConstantsRouter.Holidays,
      },
      {
        text: i18n.t('scheduleLaborPattern'),
        path: ConstantsRouter.ScheduledLaborPattern,
      },
      {
        text: i18n.t('closeDayMaster'),
        path: ConstantsRouter.MonthlyClosingDayList,
      },
    ];
    const currentPage = router.currentRoute;
    const onChangePage = (item) => {
      router.push(item.path);
    };
    return {
      t: i18n.t,
      tabOptions,
      currentPage,
      onChangePage,
    };
  },
});
