
import { computed, defineComponent, ref, watch } from 'vue';
import ConstantsRouter from '@/constants/router';
import Checkbox from '@/components/share/Checkbox/CheckboxInput.vue';
import DatePicker from '@/components/share/Calendar/DatePickerInput.vue';
import { useI18n } from 'vue-i18n';
import useApplicationMyApproval from '@/api/composables/useApplicationMyApproval';
import Table from '@/components/share/Table/ScrollTable.vue';
import router from '@/router';
import { useLoginAccountStore } from '@/store/modules/login-account';
import { useGetListAppFormDefines } from '@/api/composables/approvalSettings';
import {
  ApplicationMyApprovalParams,
  CheckBoxApplication,
} from '@/model/ApplicationMyApproval';
import moment from 'moment';
import {
  APPLICATION_MY_APPROVAL,
  APPLICATION_STATUS_TYPE,
  APPLICATION_TYPE,
} from '@/constants/typeApplication';
import ApplicationSearchArea from '@/components/Application/ApplicationSearchArea.vue';
import ApplicationUserInfo from '@/components/Application/ApplicationUserInfo.vue';

export default defineComponent({
  name: 'ApplicationApprovalRequest',
  components: {
    DatePicker,
    Checkbox,
    Table,
    ApplicationUserInfo,
    ApplicationSearchArea,
  },
  setup() {
    const i18n = useI18n();
    const t = i18n.t;
    const showSearchDetail = ref(false);
    const { listAppFormDefines } = useGetListAppFormDefines();
    const accountStore = useLoginAccountStore();
    const listDefine = ref<CheckBoxApplication[]>([]);
    const userInfo = computed(() => accountStore.state.myAccount);
    const paramsSearchApplication = ref<ApplicationMyApprovalParams>({
      pp_employee_id: userInfo.value.pp_employee_id,
      target_date: '',
      application_form_status: [],
      application_define_ids: [],
      page: 1,
      number_per_page: 20,
    });
    const checkAllSelected = computed(
      () =>
        paramsSearchApplication.value?.application_define_ids.length ===
        listAppFormDefines.value?.length
    );
    const checkAllSelectedStatus = computed(
      () =>
        paramsSearchApplication.value?.application_form_status.length ===
        listStatus.value.length
    );
    const listStatus = ref([
      {
        value: 0,
        label: t('draft'),
      },
      {
        value: 1,
        label: t('inProgress'),
      },
      {
        value: 2,
        label: t('completion'),
      },
      {
        value: 3,
        label: t('remandFind'),
      },
      {
        value: 4,
        label: t('cancelShiftCreation'),
      },
    ]);
    const applicationHeaders = [
      {
        text: i18n.t('applicationDate'),
        value: 'created_at',
        width: 120,
      },
      {
        text: i18n.t('applicationType2'),
        value: 'application_form_define_name',
        width: 150,
      },
      {
        text: i18n.t('stampRegister.targetDate'),
        value: 'target_date',
        width: 120,
      },
      {
        text: i18n.t('applicationMessage'),
        value: 'application_reason',
        width: 150,
      },
      {
        text: i18n.t('status'),
        value: 'application_status',
        width: 120,
      },
      {
        text: i18n.t('applicationApprovalContent.nextApprover'),
        value: 'next_approval_name',
        width: 120,
      },
      {
        text: i18n.t('applicationApprovalContent.detailApplication'),
        value: 'function_detail',
        width: 130,
      },
    ];
    const applicationData = ref([]);
    const applicationStatusTypeList = [
      {
        value: APPLICATION_STATUS_TYPE.DRAFT,
        color: 'draft',
        text: t('draft'),
      },
      {
        value: APPLICATION_STATUS_TYPE.ON_GOING,
        color: 'on-going',
        text: t('inProgress'),
      },
      {
        value: APPLICATION_STATUS_TYPE.FINISHED,
        color: 'finished',
        text: t('completion'),
      },
      {
        value: APPLICATION_STATUS_TYPE.REJECTED,
        color: 'rejected',
        text: t('remandFind'),
      },
      {
        value: APPLICATION_STATUS_TYPE.DELETED,
        color: 'deleted',
        text: t('deleted'),
      },
    ];
    const nextApproval = (item) => {
      return item.join(`<br />`);
    };
    watch(listAppFormDefines, () => {
      if (
        listAppFormDefines &&
        listAppFormDefines.value &&
        listAppFormDefines.value[0]
      ) {
        listDefine.value = listAppFormDefines.value.map((item) => {
          return {
            value: item.value,
            label: item.text,
          };
        });
      }
    });

    //selected all define checkbox
    const checkAll = () => {
      if (checkAllSelected.value) {
        paramsSearchApplication.value.application_define_ids = [];
      } else {
        paramsSearchApplication.value.application_define_ids = listAppFormDefines.value.map(
          (item) => item.value
        );
      }
    };
    const formatDate = (value, isHour) => {
      if (isHour) {
        if (!value) return '';
        return moment(value).isValid() ? moment(value).format('HH:mm') : '';
      } else {
        if (!value) return '';
        return moment(value).isValid()
          ? moment(value).format('YYYY/MM/DD')
          : '';
      }
    };
    //selected all status checkbox
    const checkAllStatus = () => {
      if (checkAllSelectedStatus.value) {
        paramsSearchApplication.value.application_form_status = [];
      } else {
        paramsSearchApplication.value.application_form_status = listStatus.value.map(
          (item) => item.value
        );
      }
    };

    const workRecordApplication = (dataRow) => {
      const query = {
        application_form_id: dataRow.id,
        is_my_application: APPLICATION_MY_APPROVAL,
        target_date: dataRow.target_date,
      };
      switch (dataRow.application_form_define_type) {
        case APPLICATION_TYPE.WAITING_ALLOWANCE:
          router.push({
            path: `/new${ConstantsRouter.WorkRecordApplication}`,
            query: {
              ...query,
              id: dataRow.application_approval_history_id,
              defineId: dataRow.application_form_define_id,
            },
          });
          break;

        case APPLICATION_TYPE.CHECK_TIME_APPLICATION:
          router.push({
            path: `/new${ConstantsRouter.CheckTimeApplication}`,
            query: {
              ...query,
              target_date: dataRow.target_date,
            },
          });
          break;

        case APPLICATION_TYPE.LATE_EARLY_APPLICATION:
          router.push({
            path: `/new${ConstantsRouter.EarlyApplication}`,
            query: {
              ...query,
              target_date: dataRow.target_date,
            },
          });
          break;
        case APPLICATION_TYPE.SUBSTITUTE_APPLICATION:
          router.push({
            path: `/new${ConstantsRouter.SubstituteApplication}`,
            query: {
              ...query,
            },
          });
          break;
        case APPLICATION_TYPE.TRANSFER_APPLICATION:
          router.push({
            path: `/new${ConstantsRouter.TransferAttendanceApplicationDetail}`,
            query: {
              ...query,
            },
          });
          break;
        case APPLICATION_TYPE.HOLIDAY_APPLICATION:
          router.push({
            path: `/new${ConstantsRouter.WorkInHolidayApplicationDetail}`,
            query: {
              ...query,
            },
          });
          break;
      }
    };

    const heightRequestSearch = computed(() => {
      if (searchRequest.value) {
        return searchRequest.value.getBoundingClientRect().height;
      }
      return 0;
    });
    const searchRequest = ref<HTMLDivElement>();
    const showSearchRequired = () => {
      showSearchDetail.value = !showSearchDetail.value;
    };

    const closeSearchDetail = () => {
      showSearchDetail.value = false;
      paramsSearchApplication.value = {
        ...paramsSearchApplication.value,
        target_date: router.currentRoute.value.query.target_date as string,
        application_form_status: router.currentRoute.value.query
          .application_form_status
          ? [
              ...router.currentRoute.value.query.application_form_status,
            ].map((e) => Number(e))
          : [],
        application_define_ids: router.currentRoute.value.query
          .application_define_ids
          ? ([
              ...router.currentRoute.value.query.application_define_ids,
            ] as string[])
          : [],
      };
    };
    const resetSearchInput = () => {
      paramsSearchApplication.value = {
        page: 1,
        pp_employee_id: userInfo.value.pp_employee_id,
        target_date: '',
        application_form_status: [],
        application_define_ids: [],
      };
      router.push({
        path: `/new${ConstantsRouter.ApplicationMyApproval}`,
        query: {
          ...paramsSearchApplication.value,
        },
      });
    };
    const searchMembers = () => {
      paramsSearchApplication.value.page = 1;
      router.push({
        path: `/new${ConstantsRouter.ApplicationMyApproval}`,
        query: {
          ...paramsSearchApplication.value,
        },
      });
    };

    const {
      membersApplication,
      getAllMyApproval,
      pagination,
    } = useApplicationMyApproval();
    const getDataApplication = async () => {
      await getAllMyApproval(paramsSearchApplication.value);
      if (membersApplication.value) {
        applicationData.value = membersApplication.value;
      }
    };
    getDataApplication();
    watch(
      () => router.currentRoute.value.query,
      async () => {
        await getAllMyApproval(paramsSearchApplication.value);
        applicationData.value = membersApplication.value;
      }
    );
    const onChangePage = () => {
      router.push({
        path: router.currentRoute.value.fullPath,
        query: {
          ...paramsSearchApplication.value,
        },
      });
    };
    const onChangeItemPerPage = () => {
      paramsSearchApplication.value.page = 1;
      router.push({
        path: router.currentRoute.value.fullPath,
        query: {
          ...paramsSearchApplication.value,
        },
      });
    };

    return {
      t,
      i18n,
      showSearchRequired,
      listDefine,
      closeSearchDetail,
      resetSearchInput,
      membersApplication,
      searchMembers,
      showSearchDetail,
      applicationData,
      applicationHeaders,
      workRecordApplication,
      paramsSearchApplication,
      userInfo,
      accountStore,
      listStatus,
      listAppFormDefines,
      checkAll,
      checkAllSelected,
      checkAllStatus,
      checkAllSelectedStatus,
      getDataApplication,
      formatDate,
      nextApproval,
      heightRequestSearch,
      searchRequest,
      pagination,
      onChangePage,
      applicationStatusTypeList,
      onChangeItemPerPage,
    };
  },
});
