
import { defineComponent, ref, computed, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import Modal from '@/components/share/Modal/index.vue';
import Input from '@/components/share/Input/index.vue';
import Broadcast from '@/components/Icons/Broadcast.vue';
import Button from '@/components/share/Button/index.vue';
import { usePostRegisterIcCard } from '@/api/composables/registerIcCard';
import { showToast } from '@/util/useToastMessage';
import { connectDevice, detectCard } from '@/util/IcCard/index';
import { useIcCardStore } from '@/store/modules/ic-card';

export default defineComponent({
  components: { Modal, Input, Broadcast, Button },
  props: {
    isHandInputCardNumber: { type: Boolean },
    infoEmployee: { type: Object },
  },
  setup(props, { emit }) {
    const i18n = useI18n();
    const t = i18n.t;
    const isShow = ref(false);
    const cardNumber = ref();
    const icCardStore = useIcCardStore();
    const handleCancel = () => {
      cardNumber.value = '';
      emit('onClose');
    };
    const handleSubmitIcCardNumber = async () => {
      const { registered } = usePostRegisterIcCard({
        ic_card_id: cardNumber.value,
        pp_employee_id: props.infoEmployee?.pp_employee_id,
        unit_id: props.infoEmployee?.unit_id,
      });
      const errorsServer = ref({});
      await registered
        .then((error) => {
          if (error) {
            errorsServer.value = error.errors.value;
            if (error?.errorsCommon.value) {
              showToast('error', error?.errorsCommon.value);
            }
          } else {
            showToast('success', t('registerIcCard'));
          }
        })
        .catch(() => {
          showToast('error', t('errors.serverError'));
        });
      await emit('onSubmit', cardNumber.value);
      cardNumber.value = '';
    };
    const connectIcCard = async () => {
      const device = await connectDevice();
      await detectCard(device);
    };
    const idCard = computed(() => icCardStore.state.idCard);
    const isConnect = computed(() => icCardStore.state.isConnect);
    watch(
      () => idCard.value,
      () => {
        if (idCard.value) {
          cardNumber.value = idCard.value;
        }
      }
    );

    return {
      t,
      i18n,
      isShow,
      handleCancel,
      handleSubmitIcCardNumber,
      connectIcCard,
      cardNumber,
      idCard,
      isConnect,
    };
  },
});
