import api, { ApiResponse } from '@/clients/api';
import { ErrorCode } from '@/api/types/general/ErrorCode';
import { AbsenceApplicationApiPost } from '@/api/types/AbsenceApplication';
import {
  JSONApiResponseBody,
  JSONApiResponseErrorBody,
} from '@/api/types/general/JSONApiResponse';
import ConstantsAPI from '@/constants/api';
import { AbsenceApplicationDetail } from '@/model/AbsenceApplication';

export async function postAbsenceApplication(
  postData: AbsenceApplicationApiPost
): Promise<
  | ApiResponse<200, JSONApiResponseBody<AbsenceApplicationApiPost>>
  | ApiResponse<400, JSONApiResponseErrorBody<ErrorCode>>
  | ApiResponse<500, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.post(ConstantsAPI.AbsenceApplicationCreat, postData);
}
export async function getDetailAbsenceApplication(
  applicationFormId: string
): Promise<
  | ApiResponse<200, JSONApiResponseBody<AbsenceApplicationDetail>>
  | ApiResponse<400, JSONApiResponseErrorBody<ErrorCode>>
  | ApiResponse<500, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.get(
    ConstantsAPI.AbsenceApplicationDetail(applicationFormId)
  );
}
