
import { computed, defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import ConstantsRouter from '@/constants/router';
import router from '@/router';

export default defineComponent({
  components: {},
  props: {
    currentTab: Number,
  },
  setup() {
    const i18n = useI18n();
    const t = i18n.t;
    let isAddPermissonState = ref(false);
    const buttonList = [
      {
        text: t('permissionRoleList'),
        path: ConstantsRouter.AminSettingRoleList,
      },
      {
        text: t('permissionRoleList'),
        path: ConstantsRouter.PermissionPrivilegeEditing,
      },
    ];
    const currentPath = computed(() => router.currentRoute.value.path);
    return {
      t,
      isAddPermissonState,
      buttonList,
      currentPath,
    };
  },
});
