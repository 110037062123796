import {
  getDesiredShiftDetail,
  postDesiredShift,
  postRecallDesiredShift,
} from '@/api/desiredShiftDetail';

import { DesiredShiftPost } from '@/api/types/DesiredShiftDetail';

import { convertErrorFromServerToJson } from '@/util/useValidateForm';

import { ref } from 'vue';

export default function () {
  const desiredShiftDetail = ref();
  const loading = ref(false);

  async function getDesiredShiftDetailData(id) {
    try {
      loading.value = true;
      const res = await getDesiredShiftDetail(id);
      switch (res.status) {
        case 200:
          desiredShiftDetail.value = res.data.data;
          break;
        default:
          desiredShiftDetail.value = {};
          break;
      }
    } catch (error) {
      console.error(error);
    } finally {
      loading.value = false;
    }
  }

  return { desiredShiftDetail, loading, getDesiredShiftDetailData };
}

export function postDesiredShiftDetail(data: DesiredShiftPost) {
  const errors = ref({});
  const errorsCommon = ref<string>();
  return {
    registered: (async () => {
      const response = await postDesiredShift(data);
      switch (response.status) {
        case 200:
          return null;
        default:
          if (response.data.errors[0].source) {
            errors.value = convertErrorFromServerToJson(response.data.errors);
          } else {
            errorsCommon.value = response.data.errors[0].title;
          }
          return {
            errors,
            errorsCommon,
          };
      }
    })(),
  };
}

export function postRecallDesiredShiftDetail(data: DesiredShiftPost) {
  const errors = ref({});
  const errorsCommon = ref<string>();
  return {
    registered: (async () => {
      const response = await postRecallDesiredShift(data);
      switch (response.status) {
        case 200:
          return null;
        default:
          if (response.data.errors[0].source) {
            errors.value = convertErrorFromServerToJson(response.data.errors);
          } else {
            errorsCommon.value = response.data.errors[0].title;
          }
          return {
            errors,
            errorsCommon,
          };
      }
    })(),
  };
}
