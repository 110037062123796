import api, { ApiResponse } from '@/clients/api';
import {
  JSONApiResponseBody,
  JSONApiResponseErrorBody,
} from '@/api/types/general/JSONApiResponse';

import { ErrorCode } from '@/api/types/general/ErrorCode';
import {
  AppFormDefinesDatas,
  ApproveFlowListDatasUpdate,
  ApproveFlowListDatasUpdateRepsone,
  ApproveFlowListParams,
  BasicFlowSettingUpdateParam,
  ListRoles,
  PersonalFlowSettingCreateParam,
  PersonalSettingFlow,
  PersonalFlowSettingUpdateParam,
  PersonalSettingFlowList,
  PersonalSettingFlowType,
} from '@/model/ApproveSetting';
import ConstantsAPI from '@/constants/api';
import { UnitApiGetResult } from './types/UnitApi';

export async function getApproveFlowList(
  params: ApproveFlowListParams
): Promise<
  | ApiResponse<200, JSONApiResponseBody<UnitApiGetResult[]>>
  | ApiResponse<404, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.get(ConstantsAPI.ApprovalFlowList, {
    params: params,
  });
}

//ConstantsAPI.ApprovalFlowList

export async function updateApproveFlowList(
  params: ApproveFlowListDatasUpdate[]
): Promise<
  | ApiResponse<200, JSONApiResponseBody<ApproveFlowListDatasUpdateRepsone>>
  | ApiResponse<400, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.put(ConstantsAPI.ApprovalFlowListUpdate, {
    application_flow_list: params,
  });
}

export async function getListAppFormDefines(): Promise<
  | ApiResponse<200, JSONApiResponseBody<AppFormDefinesDatas[]>>
  | ApiResponse<404, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.get(ConstantsAPI.FormDefines);
}
//ConstantsAPI.FormDefines
export async function getDetailAppBasicFlowSetting(
  id: string
): Promise<
  | ApiResponse<200, JSONApiResponseBody<BasicFlowSettingUpdateParam>>
  | ApiResponse<404, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.get(ConstantsAPI.DetailAppBasicFlowSetting(id));
}

export async function updateBasicFlowSetting(
  params: BasicFlowSettingUpdateParam
): Promise<
  | ApiResponse<200, JSONApiResponseBody<ApproveFlowListDatasUpdateRepsone>>
  | ApiResponse<400, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.post(ConstantsAPI.BasicFlowSettingUpdate, params);
}
//ConstantsAPI.BasicFlowSettingUpdate(id)

export async function getDetailAppPersonalFlowSetting(
  idFlow: string
): Promise<
  | ApiResponse<200, JSONApiResponseBody<PersonalSettingFlow>>
  | ApiResponse<404, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.get(ConstantsAPI.PersonalSettingDetail(idFlow));
}

export async function getListPersonalFlowSettingFollowUnitId(
  params: string
): Promise<
  | ApiResponse<200, JSONApiResponseBody<PersonalSettingFlowList>>
  | ApiResponse<404, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.get(
    ConstantsAPI.PersonalFlowSettingListFollowUnitId(params)
  );
}

export async function getListPersonalFlowSetting(
  params: PersonalSettingFlowType
): Promise<
  | ApiResponse<200, JSONApiResponseBody<PersonalSettingFlowList[]>>
  | ApiResponse<404, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.get(ConstantsAPI.PersonalFlowSettingList, {
    params,
  });
}

export async function createPersonalFlowSetting(
  params: PersonalFlowSettingCreateParam
): Promise<
  | ApiResponse<200, JSONApiResponseBody<ApproveFlowListDatasUpdateRepsone>>
  | ApiResponse<400, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.post(ConstantsAPI.PersonalFlowSetting, params);
}

export async function updatePersonalFlowSetting(
  id: string,
  params: PersonalFlowSettingUpdateParam
): Promise<
  | ApiResponse<200, JSONApiResponseBody<ApproveFlowListDatasUpdateRepsone>>
  | ApiResponse<400, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.put(ConstantsAPI.PersonalUpdateFlowSetting(id), params);
}

export async function getSearchEmploy(): Promise<
  | ApiResponse<200, JSONApiResponseBody<[]>>
  | ApiResponse<404, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.get(`https://jsonplaceholder.typicode.com/todos`);
}
//search/employees

export async function getSearchRoles(): Promise<
  | ApiResponse<200, JSONApiResponseBody<ListRoles[]>>
  | ApiResponse<404, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.get(`/roles`);
}
///roles

export async function ResetPersonalSettingFlow(
  id: string
): Promise<
  | ApiResponse<200, JSONApiResponseBody<[]>>
  | ApiResponse<400, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.delete(ConstantsAPI.PersonalFlowSettingReset(id));
}
