export const CHECK_TIME_TYPE = {
  CHECK_IN: 0,
  CHECK_OUT: 1,
  GO_OUT: 2,
  GO_BACK: 3,
  BREAK_START: 4,
  BREAK_END: 5,
  TELE_WORK: 6,
  CHECK_IN_OFFICE: 7,
};
