
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';
import Button from '@/components/Button.vue';
import router from '@/router';
import ConstantsRouter from '@/constants/router';

export default defineComponent({
  components: { Button },
  setup() {
    const i18n = useI18n();
    const t = i18n.t;

    const dataTabs = [
      {
        text: t('vacationMasterSetting'),
        path: ConstantsRouter.VacationMasterSetting,
        name: ConstantsRouter.VacationMasterSetting,
      },
      {
        text: t('vacationGroupSetting'),
        path: '',
      },
    ];
    const currentPage = router.currentRoute;
    return {
      t,
      dataTabs,
      currentPage,
    };
  },
});
