
import { computed, toRefs } from 'vue';
import { useI18n } from 'vue-i18n';
import Tooltip from '@/components/Tooltip.vue';

export default {
  components: {
    Tooltip,
  },
  props: {
    textToolTip: {
      type: String,
    },
    mandatory: {
      type: Boolean,
    },
    hasHint: {
      type: Boolean,
      default: false,
    },
    textLabel: {
      type: String,
    },
    warningLabel: {
      type: String,
    },
    typeLabel: {
      type: String,
      validator: function (value: string) {
        return ['inline', 'multiple'].indexOf(value) !== -1;
      },
      default: 'inline',
    },
  },
  setup(props) {
    const i18n = useI18n();
    const t = i18n.t;
    const { warningLabel } = toRefs(props);
    return {
      t,
      classTextLabel: computed(() => ({
        'mb-2': !warningLabel,
      })),
    };
  },
};
