
import { defineComponent, ref, toRefs, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import Popup from '../../components/Popup.vue';
import Button from '@/components/Button.vue';
import DatePicker from '@/components/DatePicker.vue';
import { WorkingStyleMasterParams } from '@/model/WorkingStyleMaster';
import { MONTH_FORMAT } from '@/constants/date';

export default defineComponent({
  props: {
    workingStyleMasterParams: {
      default: {},
    },
    errorServer: {
      default: '',
    },
  },
  components: { Button, Popup, DatePicker },
  setup(props, { emit }) {
    const i18n = useI18n();
    const { workingStyleMasterParams } = toRefs(props);
    const applyDateType = {
      choseMonth: 0,
      nextMonth: 1,
    };
    const isShow = ref(false);
    const onClosePopup = () => {
      isShow.value = false;
      emit('onClose');
    };
    const onSubmit = () => {
      isShow.value = false;
      emit('onSubmit');
    };
    const apiPostParams = ref<WorkingStyleMasterParams>({});
    apiPostParams.value = workingStyleMasterParams.value;
    watch(props, () => {
      apiPostParams.value = workingStyleMasterParams.value;
    });
    return {
      t: i18n.t,
      isShow,
      applyDateType,
      apiPostParams,
      MONTH_FORMAT,
      onClosePopup,
      onSubmit,
    };
  },
});
