
import { defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import Popup from '@/components/share/Modal/index.vue';
import { computed } from '@vue/runtime-core';
import { useStore } from 'vuex';
import Button from '@/components/share/Button/index.vue';
import UserIcon from '@/components/Icons/UserIcon.vue';
import InputSearchEmployee from '@/components/share/Input/InputSearchEmployee.vue';

export default defineComponent({
  components: { InputSearchEmployee, UserIcon, Button, Popup },

  setup(props, { emit }) {
    const store = useStore();
    const i18n = useI18n();
    const isShow = ref(false);

    const employeeData = computed(() => store.state.shiftPlan.listUsers);
    const employeeSelectedIds = ref<string[]>([]);
    const employeesOptions = ref(store.state.shiftPlan.listUsers);
    const isDisableBtnAdd = computed(
      () => employeeSelectedIds.value.length === 0
    );

    const closePopup = () => {
      isShow.value = false;
      emit('onClose');
    };
    const addTargetPerson = () => {
      const selectedIds = store.state.shiftPlan.listUsersSelected
        .map((e) => e.id)
        .concat(employeeSelectedIds.value);
      store.commit(
        'shiftPlan/SET_LIST_USER_SELECTED',
        employeeData.value.filter((e) => selectedIds.includes(e.id))
      );
      closePopup();
    };

    return {
      t: i18n.t,
      isShow,
      employeeData,
      employeeSelectedIds,
      employeesOptions,
      isDisableBtnAdd,
      addTargetPerson,
      closePopup,
    };
  },
});
