
export default {
  name: 'tr-node',
  components: {},
  props: {
    headers: {
      type: Array,
      required: true,
      default: () => [],
    },
    item: {
      type: Object,
    },
    child: {
      type: Boolean,
    },
    toRefs: {
      type: Number,
    },
    dataIndex: {
      type: Number,
    },
  },
  setup(props, ctx) {
    const iconValue = {
      0: `
           <svg
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="14"
              cy="14"
              r="11.5"
              stroke="#009BDE"
              stroke-width="5"
            />
          </svg>
        `,
      1: `
         <svg
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="2.5"
              y="2.5"
              width="23"
              height="23"
              stroke="#1D7D48"
              stroke-width="5"
            />
          </svg>
        `,
      2: `
        <svg
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.33975 19L13 4L21.6603 19H4.33975Z"
              stroke="#FE6B00"
              stroke-width="4"
            />
          </svg>`,
      3: `
      <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28" height="28"
      viewBox="0 0 28 28"
      fill="#ff0000">
            <path
        stroke="#ff0000"
        d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z"/>
        </svg>`,
    };
    const handleChangeValue = (item, value, dataIndex) => {
      ctx.emit('changeValue', item, value, dataIndex);
    };
    const toggleRow = (item, value, dataIndex) => {
      ctx.emit('hiddenChild', item, value, dataIndex);
    };
    return {
      iconValue,
      handleChangeValue,
      toggleRow,
    };
  },
};
