import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "font-size-24 font-bold mb-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Tab = _resolveComponent("Tab")
  const _component_router_view = _resolveComponent("router-view")

  return (_openBlock(), _createBlock("div", null, [
    _createVNode("div", _hoisted_1, _toDisplayString(_ctx.t('settingApplication')), 1),
    _createVNode(_component_Tab, { "data-tabs": _ctx.dataTabs }, null, 8, ["data-tabs"]),
    _createVNode(_component_router_view)
  ]))
}