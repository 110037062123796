
import { computed, defineComponent, PropType, toRefs } from 'vue';

export default defineComponent({
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      type: (null as unknown) as PropType<string | number | null>,
      default: null,
    },
    value: {
      type: (null as unknown) as PropType<string | number>,
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: 'default',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { modelValue, value } = toRefs(props);

    const checked = computed(() => modelValue.value === value.value);
    const selectRadio = () => {
      emit('update:modelValue', value.value);
    };
    return {
      checked,
      selectRadio,
    };
  },
});
