import { ref } from 'vue';
import { convertErrorFromServerToJson } from '@/util/useValidateForm';
import { RegisterIcCardApiPostData } from '../types/RegisterIcCard';
import {
  deleteIcCard,
  getDataIcCard,
  postRegisterIcCard,
} from '../registerIcCard';

export function usePostRegisterIcCard(data: RegisterIcCardApiPostData) {
  const errors = ref({});
  const errorsCommon = ref<string>();
  return {
    registered: (async () => {
      const response = await postRegisterIcCard(data);
      switch (response.status) {
        case 200:
          return null;
        default:
          if (response.data.errors[0].source) {
            errors.value = convertErrorFromServerToJson(response.data.errors);
          } else {
            errorsCommon.value = response.data.errors[0].title;
          }
          return {
            errors,
            errorsCommon,
          };
      }
    })(),
  };
}

export function useDeleteIcCard(id: string) {
  const errors = ref({});
  const errorsCommon = ref<string>();
  return {
    deleted: (async () => {
      const response = await deleteIcCard(id);
      switch (response.status) {
        case 200:
          return null;
        default:
          if (response.data.errors[0].source) {
            errors.value = convertErrorFromServerToJson(response.data.errors);
          } else {
            errorsCommon.value = response.data.errors[0].title;
          }
          return {
            errors,
            errorsCommon,
          };
      }
    })(),
  };
}
export function useDataIcCard() {
  const membersIcCard = ref();
  async function getAllDataIcCard(params) {
    try {
      const res = await getDataIcCard(params);
      switch (res.status) {
        case 200:
          membersIcCard.value = res.data.data;
          break;
        default:
          membersIcCard.value = [];
          break;
      }
    } catch (error) {
      console.error(error);
    }
  }

  return {
    membersIcCard,
    getAllDataIcCard,
  };
}
