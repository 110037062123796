
import {
  defineComponent,
  onBeforeUnmount,
  ref,
  toRefs,
  watchEffect,
} from '@vue/runtime-core';
import ErrorMessage from '@/components/share/Input/ErrorMessage.vue';
import { isEmpty } from '@/util/useValidateForm';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  components: { ErrorMessage },
  props: {
    placeholder: {
      type: String,
    },
    modelValue: {
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    validated: {
      type: Boolean,
      default: false,
    },
    errorServer: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const { modelValue, required } = toRefs(props);
    const i18n = useI18n();
    const t = i18n.t;

    const errorMessage = ref();
    const firstEnter = ref(false);
    const checkRequired = () => {
      if (required.value && isEmpty(modelValue.value)) {
        errorMessage.value = t('errors.required');
        emit('update:errors', t('errors.required'));
      } else {
        errorMessage.value = '';
        emit('update:errors', '');
      }
    };
    watchEffect(() => {
      checkRequired();
    });
    onBeforeUnmount(() => {
      emit('update:errors', '');
    });
    const onChangeInput = (e) => {
      emit('update:modelValue', e.target.value);
      firstEnter.value = true;
    };
    return {
      firstEnter,
      errorMessage,
      onChangeInput,
    };
  },
});
