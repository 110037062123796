import api, { ApiResponse } from '@/clients/api';
import ConstantsAPI from '@/constants/api';
import { ErrorCode } from './types/general/ErrorCode';
import {
  JSONApiResponseBody,
  JSONApiResponseErrorBody,
} from './types/general/JSONApiResponse';

import { CheckinBreakEndParams } from '@/api/types/TimeKeepingBreakEnd';

export async function postBreakEndTimeKeeping(
  postData: CheckinBreakEndParams
): Promise<
  | ApiResponse<200, JSONApiResponseBody<CheckinBreakEndParams[]>>
  | ApiResponse<400, JSONApiResponseErrorBody<ErrorCode>>
  | ApiResponse<500, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.post(ConstantsAPI.TimeKeepingBreakEnd, postData);
}
