
import { computed, defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import Table from '@/components/Table.vue';
import Button from '@/components/Button.vue';
import router from '@/router';
import useGetListShiftPlanRequest from '@/api/composables/useShiftPlanRequest';
import { displayDateFormat } from '@/util/composables';
import { watchEffect } from '@vue/runtime-core';
import { useLoginAccountStore } from '@/store/modules/login-account';
import ConstantsRouter from '@/constants/router';

export default defineComponent({
  components: { Button, Table },
  setup() {
    const i18n = useI18n();
    const submitDesiredHeaders = [
      {
        text: i18n.t('shiftPlanRequest.shiftName'),
        value: 'shift_plan_name',
      },
      {
        text: i18n.t('departmentToBeShifted'),
        value: 'unit_name',
      },
      {
        text: i18n.t('applicationSubmit'),
        value: 'start_end_date',
      },
      {
        text: i18n.t('submissionDeadline'),
        value: 'deadline',
      },
      {
        text: i18n.t('status'),
        value: 'shift_plan_status',
      },
      {
        text: i18n.t('requestDateAndTime'),
        value: 'shift_notification_status_date',
      },
      {
        text: i18n.t('desiredShiftInput'),
        value: 'desired_shift_input',
      },
    ];
    const paramsShiftPlan = ref({
      unit_id: '',
      pp_employee_id: '',
      page: 1,
    });
    const shiftPlanRequestSelected = ref([]);
    const goToEnterTheDesiredShift = (item) => {
      router.push({
        path: ConstantsRouter.EnterSubmitDesiredShift,
        query: { id: item.shift_plan_id },
      });
    };
    const unitName = ref('');
    const employeeName = ref('');
    const accountStore = useLoginAccountStore();
    const myAccount = computed(() => accountStore.state.myAccount);
    const {
      shiftPlanRequestData,
      loading,
      pagination,
      getListShiftPlanRequestData,
    } = useGetListShiftPlanRequest();
    watchEffect(() => {
      if (myAccount.value.pp_employee_id) {
        paramsShiftPlan.value.pp_employee_id = myAccount.value.pp_employee_id;
        paramsShiftPlan.value.unit_id = '1';
        getListShiftPlanRequestData(paramsShiftPlan.value);
      }
    });

    const isDisableShiftButton = computed(() => {
      return shiftPlanRequestData.value?.some(
        (el) => el.shift_plan_status === 3 && el.shift_confirmation_status === 1
      );
    });

    const optionDays = {
      0: i18n.t('daysOfWeek.sun'),
      1: i18n.t('daysOfWeek.mon'),
      2: i18n.t('daysOfWeek.tue'),
      3: i18n.t('daysOfWeek.wed'),
      4: i18n.t('daysOfWeek.thu'),
      5: i18n.t('daysOfWeek.fri'),
      6: i18n.t('daysOfWeek.sat'),
    };
    const defineStatusShiftPlan = (value) => {
      if (!value) {
        return '';
      }
      if (value == '0') {
        return `${i18n.t('shiftPlanRequest.planning')}`;
      } else if (value == '1') {
        return `${i18n.t('shiftPlanRequest.decided')}`;
      } else if (value == '2') {
        return `${i18n.t('shiftPlanRequest.announced')}`;
      } else if (value == '3') {
        return `${i18n.t('shiftPlanRequest.achive')}`;
      }
      return value;
    };
    const onChangePage = () => {
      getListShiftPlanRequestData(paramsShiftPlan.value);
    };
    return {
      t: i18n.t,
      displayDateFormat,
      myAccount,
      paramsShiftPlan,
      submitDesiredHeaders,
      shiftPlanRequestData,
      shiftPlanRequestSelected,
      goToEnterTheDesiredShift,
      optionDays,
      employeeName,
      loading,
      unitName,
      pagination,
      isDisableShiftButton,
      defineStatusShiftPlan,
      onChangePage,
    };
  },
});
