import { createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, toDisplayString as _toDisplayString, renderSlot as _renderSlot, vShow as _vShow, withDirectives as _withDirectives, resolveDirective as _resolveDirective } from "vue"

const _hoisted_1 = { class: "dropdown-icon" }
const _hoisted_2 = { class: "group-selected" }
const _hoisted_3 = { class: "truncate text-selected" }
const _hoisted_4 = {
  key: 0,
  class: "p-2 cursor-default"
}
const _hoisted_5 = { class: "flex align-items-center w-100" }
const _hoisted_6 = {
  key: 0,
  class: "mt-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DefaultAvatar = _resolveComponent("DefaultAvatar")
  const _component_DeleteItemSelect = _resolveComponent("DeleteItemSelect")
  const _component_Input = _resolveComponent("Input")
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")
  const _directive_click_outside = _resolveDirective("click-outside")

  return (_openBlock(), _createBlock("div", null, [
    _withDirectives(_createVNode("div", {
      class: "sys-multi-select",
      onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.toggleSelect && _ctx.toggleSelect(...args)))
    }, [
      _createVNode("div", _hoisted_1, [
        _createVNode("span", {
          class: ["text-2xl cursor-pointer", [
            _ctx.isShowOption ? 'cp-icon-chevron-up' : 'cp-icon-chevron-down',
          ]]
        }, null, 2)
      ]),
      _createVNode("div", {
        class: ["selected-list", {
          'validate-error-input':
            _ctx.validated && (_ctx.errorMessage || _ctx.errorServer.length),
          'selected-list__active': _ctx.isShowOption,
        }]
      }, [
        _createVNode("div", _hoisted_2, [
          (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.optionSelectedItem, (item) => {
            return (_openBlock(), _createBlock("div", {
              class: "select__selections",
              key: item
            }, [
              _createVNode("div", {
                class: ["element truncate", item[_ctx.itemUrl] || _ctx.hasIconDefault ? 'pl-10' : 'pl-2']
              }, [
                (item[_ctx.itemUrl])
                  ? (_openBlock(), _createBlock("img", {
                      key: 0,
                      class: "avatar",
                      src: item[_ctx.itemUrl]
                    }, null, 8, ["src"]))
                  : (_ctx.hasIconDefault)
                    ? (_openBlock(), _createBlock(_component_DefaultAvatar, {
                        key: 1,
                        class: "avatar"
                      }))
                    : _createCommentVNode("", true),
                _createVNode("div", _hoisted_3, _toDisplayString(typeof item === 'object' ? item[_ctx.itemText] : item), 1)
              ], 2),
              _createVNode(_component_DeleteItemSelect, {
                class: "select__selections__delete cursor-pointer",
                onClick: ($event: any) => (_ctx.selectOption(item))
              }, null, 8, ["onClick"])
            ]))
          }), 128)),
          _createVNode(_component_Input, {
            class: "flex-1",
            placeholder: _ctx.optionSelectedItem.length === 0 ? _ctx.placeholder : '',
            modelValue: _ctx.valueInput,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.valueInput = $event)),
            onChange: _ctx.changeTextSearch,
            isFocus: _ctx.isShowOption,
            style: { width: _ctx.widthInput }
          }, null, 8, ["placeholder", "modelValue", "onChange", "isFocus", "style"])
        ])
      ], 2),
      _withDirectives(_createVNode("div", {
        class: "option-list mt-1",
        style: { width: _ctx.widthOptionSelect + 'px' }
      }, [
        (_ctx.optionsFilter.length === 0)
          ? (_openBlock(), _createBlock("div", _hoisted_4, _toDisplayString(_ctx.t('thereIsNoData')), 1))
          : (_openBlock(true), _createBlock(_Fragment, { key: 1 }, _renderList(_ctx.optionsFilter, (item, index) => {
              return _withDirectives((_openBlock(), _createBlock("div", {
                class: [{
            active: _ctx.isActive(item),
          }, "option-element cursor-pointer"],
                key: index,
                onClick: ($event: any) => (_ctx.selectOption(item))
              }, [
                _createVNode("div", _hoisted_5, [
                  (item[_ctx.itemUrl])
                    ? (_openBlock(), _createBlock("img", {
                        key: 0,
                        class: "avatar",
                        src: item[_ctx.itemUrl]
                      }, null, 8, ["src"]))
                    : (_ctx.hasIconDefault)
                      ? (_openBlock(), _createBlock(_component_DefaultAvatar, {
                          key: 1,
                          class: "avatar inline"
                        }))
                      : _createCommentVNode("", true),
                  _createVNode("div", {
                    class: ["truncate inline-block", item[_ctx.itemUrl] || _ctx.hasIconDefault ? 'text-option' : 'w-100']
                  }, _toDisplayString(typeof item === 'object' ? item[_ctx.itemText] : item), 3),
                  _renderSlot(_ctx.$slots, "default", {
                    item: item,
                    index: index
                  })
                ])
              ], 10, ["onClick"])), [
                [_vShow, _ctx.limit === 0 || (_ctx.limit && index < _ctx.limit)]
              ])
            }), 128))
      ], 4), [
        [_vShow, _ctx.isShowOption]
      ])
    ], 512), [
      [_directive_click_outside, _ctx.clickOutside]
    ]),
    (_ctx.validated && (_ctx.errorMessage || _ctx.errorServer.length))
      ? (_openBlock(), _createBlock("div", _hoisted_6, [
          _createVNode(_component_ErrorMessage, {
            "error-server": _ctx.errorServer,
            "error-message": _ctx.errorMessage
          }, null, 8, ["error-server", "error-message"])
        ]))
      : _createCommentVNode("", true)
  ]))
}