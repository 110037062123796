import api, { ApiResponse } from '@/clients/api';
import { ErrorCode } from './types/general/ErrorCode';
import {
  JSONApiResponseBody,
  JSONApiResponseErrorBody,
} from './types/general/JSONApiResponse';
import ConstantsAPI from '@/constants/api';
import {
  TransferAttendanceApplicationApiGetResult,
  TransferAttendanceApplicationApiPostData,
  TransferAttendanceApplicationApiPostResult,
} from '@/api/types/TransferAttendanceApplicationApi';

export async function postTransferAttendanceApplication(
  postData: TransferAttendanceApplicationApiPostData
): Promise<
  | ApiResponse<
      200,
      JSONApiResponseBody<TransferAttendanceApplicationApiPostResult>
    >
  | ApiResponse<400, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.post(ConstantsAPI.TransferAttendanceApplication, postData);
}

export async function getTransferAttendanceApplicationDetail(
  id: string
): Promise<
  | ApiResponse<
      200,
      JSONApiResponseBody<TransferAttendanceApplicationApiGetResult>
    >
  | ApiResponse<400, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.get(ConstantsAPI.TransferAttendanceApplicationDetail(id));
}
