
import { defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import Table from '@/components/share/Table/Table.vue';
import BaseIcon from '@/components/Icons/BaseIcon.vue';
import router from '@/router';
import ConstantsRouter from '@/constants/router';
import useUnitApi from '@/api/composables/useUnit';

export default defineComponent({
  components: {
    Table,
    BaseIcon,
  },
  setup: function () {
    const i18n = useI18n();
    const t = i18n.t;
    const paramListUnit = ref({
      page: 1,
      is_all: false,
    });
    const { pagination, unitList, getUnitData } = useUnitApi(
      paramListUnit.value
    );

    const timeRecoderHeader = [
      {
        text: i18n.t('approvedSetting.organizationCode'),
        value: 'unit_code',
        width: '300px',
      },
      {
        text: i18n.t('approvedSetting.organizationName'),
        value: 'unit_name',
        width: '400px',
      },
      {
        text: i18n.t('settingTimeRecoder.terminalStamping'),
        value: 'edit',
        width: '48px',
      },
    ];
    const timeRecoderData = [
      {
        organization_code: '100',
        organization_name: '人事課',
      },
    ];
    const redirectTimeRecoderDetail = (item) => {
      router.push({
        path: `/new${ConstantsRouter.TimeRecoderDetail}`,
        query: { unitId: item.unit_id, unitName: item.unit_name },
      });
    };
    const onChangePage = () => {
      getUnitData(paramListUnit.value);
    };

    return {
      t,
      i18n,
      timeRecoderHeader,
      timeRecoderData,
      onChangePage,
      paramListUnit,
      pagination,
      unitList,
      redirectTimeRecoderDetail,
    };
  },
});
