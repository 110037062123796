
import { defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import router from '@/router';
import ConstantsRouter from '@/constants/router';
import BackPreviousPage from '@/components/share/BackPreviousPage/index.vue';
import Input from '@/components/share/Input/index.vue';
import Dropdown from '@/components/share/Dropdown/Dropdown.vue';
import RadioGroup from '@/components/share/Radio/RadioGroup.vue';
import RadioInputGroup from '@/components/share/Radio/RadioInputGroup.vue';
import RadioInput from '@/components/share/Radio/RadioInput.vue';
import DatePickerInput from '@/components/share/Calendar/DatePickerInput.vue';
import Table from '@/components/Table.vue';
import Button from '@/components/share/Button/index.vue';
import { isValidateForm } from '@/util/useValidateForm';
import { computed } from '@vue/runtime-core';
import {
  useDetailYearPaidHoliday,
  usePostYearPaidHoliday,
  useUpdateYearPaidHoliday,
} from '@/api/composables/useYearPaidHoliday';
import { showToast } from '@/util/useToastMessage';

export default defineComponent({
  components: {
    Button,
    Table,
    DatePickerInput,
    RadioInput,
    RadioInputGroup,
    RadioGroup,
    Dropdown,
    BackPreviousPage,
    Input,
  },
  setup() {
    const i18n = useI18n();
    const t = i18n.t;
    const yearPaidHolidayId = router.currentRoute.value.query.id as string;
    const backYearPaidHolidayList = () => {
      router.push(`/new${ConstantsRouter.YearPaidHoliday}`);
    };

    const yearPaidHolidayParams = ref({});
    const yearPaidHolidayTypeList = [
      { text: '代休', value: 0 },
      { text: '振替休日', value: 1 },
    ];
    const additionWorkDayList = [
      { label: t('toAdd'), value: 0 },
      { label: t('notAdd'), value: 1 },
    ];
    const deemedTimeList = [
      { label: t('includedWorkingHours'), value: 0 },
      { label: t('notIncludedWorkingHours'), value: 1 },
    ];
    const takingHalfDayLeaveList = [
      { label: t('notAllowed'), value: 0 },
      { label: t('toApprove'), value: 1 },
    ];
    const halfDayAcquisitionTimeSettingList = [
      { label: t('acquisitionTimeSetScheduledWorkingHours'), value: 0 },
      { label: t('contractedWorkingHoursBeAcquisitionTime'), value: 1 },
    ];
    const takeHourlyVacationList = [
      { label: t('notAllowed'), value: 0 },
      { label: t('toApprove'), value: 1 },
    ];
    const maximumNumberOfHoursForAcquisitionList = [
      { label: t('setUniformly'), value: 0 },
      { label: t('setIndividually'), value: 1 },
    ];
    const takeVacationDuringTheTrialPeriodList = [
      { label: t('notAllowed'), value: 0 },
      { label: t('toApprove'), value: 1 },
    ];
    const additionTypeList = [
      { label: t('additionMethod'), value: 0 },
      { label: t('subtractionMethod'), value: 1 },
    ];
    const automaticPaidManagementList = [
      { label: t('toManage'), value: 0 },
      { label: t('notManage'), value: 1 },
    ];
    const GRANT_SUM_ACCORDING_TYPE = {
      BY_MONTH: 0,
      FOR_EACH: 1,
    };
    const withHolidayPayAndDayTypeList = [
      { text: t('useNumberPerWeek'), value: 0 },
      { text: t('useActualNumberOfWorkingDays'), value: 1 },
    ];
    const DAY_OF_GRANTED_FROM_THE_NEXT = {
      FIRST_GRAND_DATE: 0,
      EVERY_YEAR: 1,
    };
    const withHolidayPayAndDayHeaders = [
      {
        text: t('scheduledWorkingDaysPerWeek'),
        value: 'scheduled_working_days_per_week',
        align: 'center',
      },
      {
        text: t('scheduledWorkingDaysPerYear'),
        value: 'scheduled_working_days_per_year',
        align: 'center',
      },
      {
        text: `0${t('year')}`,
        value: 'scheduled_working_days_per_year',
        align: 'center',
      },
      {
        text: `1${t('year')}`,
        value: 'scheduled_working_days_per_year',
        align: 'center',
      },
      {
        text: `2${t('year')}`,
        value: 'scheduled_working_days_per_year',
        align: 'center',
      },
      {
        text: `3${t('year')}`,
        value: 'scheduled_working_days_per_year',
        align: 'center',
      },
      {
        text: `4${t('year')}`,
        value: 'scheduled_working_days_per_year',
        align: 'center',
      },
      {
        text: `5${t('year')}`,
        value: 'scheduled_working_days_per_year',
        align: 'center',
      },
      {
        text: `6${t('moreThanYear')}`,
        value: 'scheduled_working_days_per_year',
        align: 'center',
      },
    ];
    const withHolidayPayAndDayData = [
      {
        scheduled_working_days_per_week: '5日',
      },
    ];

    const { getYearPaidHolidayDetailData } = useDetailYearPaidHoliday();
    const getYearPaidHolidayDetail = async () => {
      // await getYearPaidHolidayDetailData(yearPaidHolidayId);
    };
    if (yearPaidHolidayId) {
      getYearPaidHolidayDetail();
    }

    const errors = ref({});
    const validated = ref(false);
    const errorServer = ref({});
    const isValid = () => {
      validated.value = true;
      return isValidateForm(errors);
    };
    const isDisableSubmit = computed(() =>
      Object.values(errors.value).some((e) => !!e)
    );

    const submitRegisterYearPaidHoliday = async () => {
      if (!isValid()) return;

      const { postYearPaidHolidayData } = usePostYearPaidHoliday();
      await postYearPaidHolidayData(yearPaidHolidayParams.value)
        .then(({ res, errors, errorsCommon }) => {
          if (res.value) {
            showToast('success', '');
          } else {
            errorServer.value = errors.value;
            if (errorsCommon.value) {
              showToast('error', errorsCommon.value);
            } else {
              showToast('warning', i18n.t('invalidInput'));
            }
          }
        })
        .catch(() => {
          showToast('error', i18n.t('errors.serverError'));
        });
    };
    const submitUpdateYearPaidHoliday = async () => {
      if (!isValid()) return;

      const { updateYearPaidHolidayData } = useUpdateYearPaidHoliday();
      await updateYearPaidHolidayData(
        yearPaidHolidayId,
        yearPaidHolidayParams.value
      )
        .then(({ res, errors, errorsCommon }) => {
          if (res.value) {
            showToast('success', '');
          } else {
            errorServer.value = errors.value;
            if (errorsCommon.value) {
              showToast('error', errorsCommon.value);
            } else {
              showToast('warning', i18n.t('invalidInput'));
            }
          }
        })
        .catch(() => {
          showToast('error', i18n.t('errors.serverError'));
        });
    };
    const submitRegisterOrUpdateYearPaidHoliday = () => {
      if (yearPaidHolidayId) {
        submitUpdateYearPaidHoliday();
      } else {
        submitRegisterYearPaidHoliday();
      }
    };

    return {
      t,
      yearPaidHolidayId,
      yearPaidHolidayTypeList,
      additionWorkDayList,
      yearPaidHolidayParams,
      deemedTimeList,
      takingHalfDayLeaveList,
      halfDayAcquisitionTimeSettingList,
      takeHourlyVacationList,
      maximumNumberOfHoursForAcquisitionList,
      takeVacationDuringTheTrialPeriodList,
      additionTypeList,
      automaticPaidManagementList,
      GRANT_SUM_ACCORDING_TYPE,
      DAY_OF_GRANTED_FROM_THE_NEXT,
      withHolidayPayAndDayTypeList,
      withHolidayPayAndDayHeaders,
      withHolidayPayAndDayData,
      errorServer,
      validated,
      errors,
      isDisableSubmit,
      backYearPaidHolidayList,
      submitRegisterYearPaidHoliday,
      submitUpdateYearPaidHoliday,
      submitRegisterOrUpdateYearPaidHoliday,
      getYearPaidHolidayDetailData,
    };
  },
});
