import { Store } from 'vuex';

import { COMPANY } from '@/router';
import { useLoadingStore } from '@/store/modules/use-loading';
import { SUB_DOMAIN } from '@/util/composables';

export const getListAvatarUser = async (param = {}) => {
  const useLoading = useLoadingStore();
  useLoading.commit('increaseCountApiWaiting');

  const url = !SUB_DOMAIN
    ? `/api/v1/personal-profile/simple-profiles?employeeIds=${param}`
    : `https://${SUB_DOMAIN}.cydas.com/${COMPANY}/api/v1/personal-profile/simple-profiles?employeeIds=${param}`;

  try {
    const res = (
      await fetch(url, {
        headers: {
          'x-requested-with': 'XMLHttpRequest',
          'X-People-Authorization-Type': 'idp_token_cookie',
        },
        mode: 'cors',
        credentials: 'include',
      })
    ).json();
    return res;
  } catch (error) {
    console.error(error);
  } finally {
    useLoading.commit('decreaseCountApiWaiting');
  }
};

type UpdateAvatarForTheShiftPlanUsers = {
  userIds: string[];
  store: Store<any>;
  forceGet?: boolean;
};

export const updateAvatarForTheShiftPlanUsers = async ({
  userIds = [],
  store,
  forceGet = false,
}: UpdateAvatarForTheShiftPlanUsers) => {
  const currentAvatarUsers = JSON.parse(
    JSON.stringify(store.state.shiftPlan.avatarUsers)
  );

  if (!userIds?.length) {
    return;
  }
  if (userIds.every((id) => id in currentAvatarUsers) && !forceGet) {
    return;
  }

  const res = await getListAvatarUser(userIds);
  const newAvatarUsers = (res.data || []).reduce(
    (s, d) => ({ ...s, [d.id]: d }),
    {}
  );

  store.commit('shiftPlan/SET_AVATAR_USERS', newAvatarUsers);
  store.commit(
    'shiftPlan/SET_LIST_USER',
    (store.state.shiftPlan.listUsers || []).map((u) => {
      if (!(u.id in newAvatarUsers)) return u;
      return {
        ...u,
        ...(newAvatarUsers[u.id] || {}),
        photo: u.photo || newAvatarUsers[u.id]?.profileFile,
      };
    })
  );
};
