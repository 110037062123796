import { ref } from 'vue';
import { convertErrorFromServerToJson } from '@/util/useValidateForm';
import {
  deleteYearPaidHoliday,
  getYearPaidHoliday,
  getYearPaidHolidayDetail,
  postYearPaidHoliday,
  updateYearPaidHoliday,
} from '@/api/yearPaidHoliday';
import {
  YearPaidHolidayApiGetParams,
  YearPaidHolidayApiGetResult,
  YearPaidHolidayApiPostData,
  YearPaidHolidayApiUpdateData,
} from '@/api/types/YearPaidHolidayApi';

export function usePostYearPaidHoliday() {
  const loading = ref(false);
  const errors = ref({});
  const errorsCommon = ref<string>();
  const res = ref();

  async function postYearPaidHolidayData(data: YearPaidHolidayApiPostData) {
    loading.value = true;
    const response = await postYearPaidHoliday(data);
    switch (response.status) {
      case 200:
        res.value = response.data.data;
        break;
      default:
        if (response.data.errors[0].source) {
          errors.value = convertErrorFromServerToJson(response.data.errors);
        } else {
          errorsCommon.value = response.data.errors[0].title;
        }
    }
    loading.value = false;
    return { res, errors, errorsCommon };
  }

  return { loading, postYearPaidHolidayData };
}

export function useGetListYearPaidHoliday() {
  const yearPaidHolidayList = ref<YearPaidHolidayApiGetResult[]>([]);
  const loading = ref(false);
  const pagination = ref({
    current_page: 1,
    per_page: 20,
    total: 0,
  });
  const response = ref();

  async function getYearPaidHolidayData(params: YearPaidHolidayApiGetParams) {
    try {
      loading.value = true;
      const res = await getYearPaidHoliday(params);
      switch (res.status) {
        case 200:
          yearPaidHolidayList.value = res.data.data;
          response.value = res.data;
          pagination.value = response.value.meta.pagination;
          break;
        default:
          yearPaidHolidayList.value = [];
          break;
      }
    } catch (error) {
      console.error(error);
    } finally {
      loading.value = false;
    }
  }

  return {
    loading,
    pagination,
    yearPaidHolidayList,
    getYearPaidHolidayData,
  };
}

export function useDetailYearPaidHoliday() {
  const yearPaidHolidayDetail = ref<YearPaidHolidayApiGetResult>();
  const loading = ref(false);

  async function getYearPaidHolidayDetailData(id) {
    try {
      loading.value = true;
      const res = await getYearPaidHolidayDetail(id);
      switch (res.status) {
        case 200:
          yearPaidHolidayDetail.value = res.data.data;
          return yearPaidHolidayDetail.value;
        default:
          yearPaidHolidayDetail.value = {};
          return yearPaidHolidayDetail.value;
      }
    } catch (error) {
      console.error(error);
      return {};
    } finally {
      loading.value = false;
    }
  }

  return { yearPaidHolidayDetail, loading, getYearPaidHolidayDetailData };
}

export function useUpdateYearPaidHoliday() {
  const loading = ref(false);
  const errors = ref({});
  const errorsCommon = ref<string>();
  const res = ref();

  async function updateYearPaidHolidayData(
    id: string,
    data: YearPaidHolidayApiUpdateData
  ) {
    loading.value = true;
    const response = await updateYearPaidHoliday(id, data);
    switch (response.status) {
      case 200:
        res.value = response.data.data;
        break;
      default:
        if (response.data.errors[0].source) {
          errors.value = convertErrorFromServerToJson(response.data.errors);
        } else {
          errorsCommon.value = response.data.errors[0].title;
        }
    }
    loading.value = false;
    return { res, errors, errorsCommon };
  }

  return { loading, updateYearPaidHolidayData };
}

export function useDeleteYearPaidHoliday(id) {
  return {
    deleteData: (async () => {
      const response = await deleteYearPaidHoliday(id);
      switch (response.status) {
        case 200:
          return null;
        default:
          return response.data.errors[0].title;
      }
    })(),
  };
}
