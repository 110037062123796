import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = {
  id: "permission-setting-container",
  class: "mt-2 ml-8 mr-8 text-gray-333 mb-20"
}
const _hoisted_2 = { class: "flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("ul", _hoisted_2, [
      (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.dataTabs, (item, index) => {
        return (_openBlock(), _createBlock("li", {
          key: `tab${index}`,
          class: "last:mr-0 text-center cursor-pointer"
        }, [
          _createVNode("a", {
            class: ["tab-container text-base px-5 py-2 rounded-8 block", {
            'not--active': _ctx.currentPath && !_ctx.currentPath.includes(item.path),
            active: _ctx.currentPath && _ctx.currentPath.includes(item.path),
          }],
            onClick: ($event: any) => (_ctx.toggleTabs(item))
          }, _toDisplayString(item.text), 11, ["onClick"])
        ]))
      }), 128))
    ]),
    _createVNode(_component_router_view)
  ]))
}