import api, { ApiResponse } from '@/clients/api';
import { ErrorCode } from './types/general/ErrorCode';
import {
  JSONApiResponseBody,
  JSONApiResponseErrorBody,
} from '@/api/types/general/JSONApiResponse';
import ConstantsAPI from '@/constants/api';
import { ShiftPlanDepartment } from './types/shiftPlanDepartment';

export async function getShiftPlanDepartment(): Promise<
  | ApiResponse<200, JSONApiResponseBody<ShiftPlanDepartment[]>>
  | ApiResponse<400, JSONApiResponseErrorBody<ErrorCode>>
  | ApiResponse<500, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.get(ConstantsAPI.DepartmentSelect);
}
