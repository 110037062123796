import api, { ApiResponse } from '@/clients/api';
import { ErrorCode } from './types/general/ErrorCode';
import {
  JSONApiResponseBody,
  JSONApiResponseErrorBody,
} from './types/general/JSONApiResponse';
import ConstantsAPI from '@/constants/api';
import {
  ViewConfirmShiftApiGetResult,
  ViewConfirmShiftParamSearch,
} from '@/api/types/ViewConfirmShift';

export async function getViewConfirmShift(
  params: ViewConfirmShiftParamSearch
): Promise<
  | ApiResponse<200, JSONApiResponseBody<ViewConfirmShiftApiGetResult[]>>
  | ApiResponse<404, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.get(ConstantsAPI.ViewConfirmShift, {
    params: params,
  });
}
