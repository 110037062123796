import { ref } from 'vue';
import { convertErrorFromServerToJson } from '@/util/useValidateForm';
import {
  getDetailTimeRecoder,
  updateTimeRecoder,
  postTimeRecoder,
} from '@/api/timeRecoder';
import { TimeRecoderParamsDetail } from '@/model/TimeRecoder';
import { DEFINE_TYPE_WORKING_STYLE } from '@/constants/defineTypeWorkingStyle';

export function usePostTimeRecoder(data: TimeRecoderParamsDetail) {
  const errors = ref({});
  const errorsCommon = ref<string>();
  const loading = ref(false);
  return {
    postData: (async () => {
      const response = await postTimeRecoder(data);
      switch (response.status) {
        case 200:
          loading.value = false;
          return null;
        default:
          if (response.data.errors[0].source) {
            errors.value = convertErrorFromServerToJson(response.data.errors);
          } else {
            errorsCommon.value = response.data.errors[0].title;
          }
          loading.value = false;
          return {
            errors,
            errorsCommon,
          };
      }
    })(),
  };
}
export function useGetDetailTimeRecoder() {
  const timeRecoder = ref();
  const loading = ref(false);
  const isCheckCreateTimeCoder = ref(false);
  async function useDetailTimeRecoder(unitId) {
    try {
      loading.value = true;
      const res = await getDetailTimeRecoder(unitId);
      switch (res.status) {
        case 200:
          timeRecoder.value = res.data.data;
          break;
        default:
          isCheckCreateTimeCoder.value = true;
          timeRecoder.value = [
            {
              type_of_work_form_define_id:
                DEFINE_TYPE_WORKING_STYLE.STANDARD_WORK,
              is_browser_stamping: true,
              is_ic_card_check_time: true,
              is_face_recognition_stamping: true,
            },
            {
              type_of_work_form_define_id:
                DEFINE_TYPE_WORKING_STYLE.STANDARD_WORK_SHIFT,
              is_browser_stamping: true,
              is_ic_card_check_time: true,
              is_face_recognition_stamping: true,
            },
            {
              type_of_work_form_define_id:
                DEFINE_TYPE_WORKING_STYLE.TRANSFORMATION_LABOR,
              is_browser_stamping: true,
              is_ic_card_check_time: true,
              is_face_recognition_stamping: true,
            },
            {
              type_of_work_form_define_id:
                DEFINE_TYPE_WORKING_STYLE.DISCRETIONARY_LABOR,
              is_browser_stamping: true,
              is_ic_card_check_time: true,
              is_face_recognition_stamping: true,
            },
            {
              type_of_work_form_define_id:
                DEFINE_TYPE_WORKING_STYLE.PART_TIME_JOB,
              is_browser_stamping: true,
              is_ic_card_check_time: true,
              is_face_recognition_stamping: true,
            },
          ];
          break;
      }
    } catch (error) {
      console.error(error);
    } finally {
      loading.value = false;
    }
  }
  return {
    timeRecoder,
    loading,
    isCheckCreateTimeCoder,
    useDetailTimeRecoder,
  };
}

export function useUpdateTimeRecoder(
  unitId: string,
  data: TimeRecoderParamsDetail
) {
  const errors = ref({});
  const errorsCommon = ref<string>();
  const loading = ref(false);
  return {
    update: (async () => {
      loading.value = true;
      const response = await updateTimeRecoder(unitId, data);
      switch (response.status) {
        case 200:
          loading.value = false;
          return null;
        default:
          if (response.data.errors[0].source) {
            errors.value = convertErrorFromServerToJson(response.data.errors);
          } else {
            errorsCommon.value = response.data.errors[0].title;
          }
          loading.value = false;
          return {
            errors,
            errorsCommon,
          };
      }
    })(),
  };
}
