import { ref } from 'vue';
import { convertErrorFromServerToJson } from '@/util/useValidateForm';
import {
  getImportShiftPlanDetail,
  postImportShiftPlan,
  postImportShiftPlanDraft,
} from '@/api/shiftPlan';
import {
  ImportShiftPlanApiGetResult,
  ImportShiftPlanDraftApiPostParams,
  ImportShiftPlanDratApiPostResult,
} from '@/api/types/ShiftPlan';
import { useLoadingStore } from '@/store/modules/use-loading';

export function usePostImportShiftPlanDraft() {
  const res = ref<ImportShiftPlanDratApiPostResult>();
  const errors = ref({});
  const errorsCommon = ref<string>();
  const loadingImportShiftPlanDraft = ref(false);
  const useLoading = useLoadingStore();

  async function postImportShiftPlanDraftData(
    id: string,
    data: ImportShiftPlanDraftApiPostParams,
    onUploadProgress
  ) {
    try {
      useLoading.commit('refuseLoading');
      loadingImportShiftPlanDraft.value = true;
      const response = await postImportShiftPlanDraft(id, data, {
        onUploadProgress,
      });
      switch (response.status) {
        case 200:
          res.value = response.data.data;
          break;
        default:
          if (response.data.errors[0].source) {
            errors.value = convertErrorFromServerToJson(response.data.errors);
          } else {
            errorsCommon.value = response.data.errors[0].title;
          }
          break;
      }
      loadingImportShiftPlanDraft.value = false;
      useLoading.commit('allowLoading');
    } catch (e) {
      console.log(e);
    }
    return {
      res,
    };
  }
  return {
    postImportShiftPlanDraftData,
    loadingImportShiftPlanDraft,
  };
}

export function useGetImportShiftPlanDraft() {
  const importShiftPlanDraftDetail = ref<ImportShiftPlanApiGetResult>();
  const loading = ref(false);

  async function getImportShiftPlanDetailData(shiftPlanImportId) {
    try {
      loading.value = true;
      const res = await getImportShiftPlanDetail(shiftPlanImportId);
      const response = ref();
      switch (res.status) {
        case 200:
          response.value = res.data;
          importShiftPlanDraftDetail.value = response.value.data;
          return importShiftPlanDraftDetail.value;
        default:
          importShiftPlanDraftDetail.value = {};
          return importShiftPlanDraftDetail.value;
      }
    } catch (error) {
      console.error(error);
      return {};
    } finally {
      loading.value = false;
    }
  }

  return { importShiftPlanDraftDetail, loading, getImportShiftPlanDetailData };
}

export function usePostImportShiftPlan() {
  const res = ref();
  const errors = ref({});
  const errorsCommon = ref<string>();
  const loadingImportShiftPlan = ref(false);
  const useLoading = useLoadingStore();

  async function postImportShiftPlanData(shiftPlanId: string, fileId: string) {
    loadingImportShiftPlan.value = true;
    useLoading.commit('refuseLoading');
    const response = await postImportShiftPlan(shiftPlanId, fileId);
    switch (response.status) {
      case 200:
        res.value = response.data.data;
        break;
      default:
        if (response.data.errors[0].source) {
          errors.value = convertErrorFromServerToJson(response.data.errors);
        } else {
          errorsCommon.value = response.data.errors[0].title;
        }
    }
    loadingImportShiftPlan.value = false;
    useLoading.commit('allowLoading');
    return {
      res,
      errors,
      errorsCommon,
    };
  }

  return {
    postImportShiftPlanData,
    loadingImportShiftPlan,
  };
}
