
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';
import Button from '@/components/Button.vue';
import Table from '@/components/Table.vue';
export default defineComponent({
  components: {
    Button,
    Table,
  },
  setup() {
    const i18n = useI18n();
    const t = i18n.t;

    const headers = [
      {
        text: t('vacationHeaders.vacationCode'),
        value: 'vacation_code',
        width: '5%',
      },
      {
        text: t('vacationHeaders.vacationName'),
        value: 'vacation_name',
        width: '15%',
        whitespace: true,
      },
      {
        text: t('vacationHeaders.abbreviation'),
        value: 'abbreviation',
        width: '13%',
        whitespace: true,
      },
      {
        text: t('vacationHeaders.vacationType'),
        value: 'vacation_type',
        width: '10%',
        whitespace: true,
      },
      {
        text: t('vacationHeaders.displayImage'),
        value: 'display_image',
        width: '10%',
      },
      {
        text: t('vacationHeaders.paidType'),
        value: 'paid_type',
        width: '10%',
        whitespace: true,
      },
      {
        text: t('vacationHeaders.additionType'),
        value: 'addition_type',
        width: '10%',
        whitespace: true,
      },
      {
        text: t('vacationHeaders.halfDayUnitAcquisition'),
        value: 'half_day_unit_acquisition',
        width: '10%',
        whitespace: true,
      },
      {
        text: t('vacationHeaders.timeUnitAcquisition'),
        value: 'time_unit_acquisition',
        width: '7%',
        whitespace: true,
      },
      {
        text: t('edit'),
        value: 'edit',
        width: '5%',
      },
      {
        text: t('delete'),
        value: 'delete',
        width: '5%',
      },
    ];
    const data = [];
    return {
      t,
      headers,
      data,
    };
  },
});
