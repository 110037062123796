import { InjectionKey } from '@vue/runtime-core';
import { createStore, Store } from 'vuex';

export interface State {
  toggleSidebar: boolean;
}

export const key: InjectionKey<Store<State>> = Symbol();

export function useToggleSidebarStore() {
  return store;
}

export const store = createStore<State>({
  state() {
    return {
      toggleSidebar: false,
    };
  },
  mutations: {
    toggleSidebar(state, payload) {
      state.toggleSidebar = payload;
    },
  },
  actions: {},
});
export default store;
