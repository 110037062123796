<template>
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.26855 9.78119C5.26731 8.8917 5.44213 8.01076 5.78294 7.18915C6.12375 6.36753 6.62381 5.6215 7.25429 4.99405C7.88476 4.3666 8.6332 3.87014 9.45644 3.53329C10.2797 3.19644 11.1615 3.02586 12.0509 3.03138C15.7625 3.05897 18.7312 6.144 18.7312 9.86593V10.5312C18.7312 13.8889 19.4337 15.8373 20.0524 16.9023C20.1191 17.0161 20.1546 17.1454 20.1553 17.2773C20.156 17.4092 20.1219 17.5389 20.0565 17.6534C19.991 17.7679 19.8966 17.8631 19.7826 17.9294C19.6686 17.9957 19.5392 18.0309 19.4073 18.0312H4.59173C4.45985 18.0309 4.33038 17.9957 4.2164 17.9294C4.10242 17.863 4.00795 17.7678 3.94252 17.6533C3.8771 17.5388 3.84303 17.409 3.84376 17.2771C3.84449 17.1453 3.87999 17.0159 3.94667 16.9021C4.56573 15.8371 5.26855 13.8887 5.26855 10.5312L5.26855 9.78119Z"
      stroke="black"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9 18.0312V18.7812C9 19.5769 9.31607 20.34 9.87868 20.9026C10.4413 21.4652 11.2044 21.7812 12 21.7812C12.7956 21.7812 13.5587 21.4652 14.1213 20.9026C14.6839 20.34 15 19.5769 15 18.7812V18.0312"
      stroke="black"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
