import moment from 'moment';
import { scrollError, scrollFirstErrorMessage } from '@/util/useScrollIntoView';

export function isValidateForm(errors) {
  for (const property in errors.value) {
    if (errors.value[property].length > 0) {
      scrollError();
      return false;
    }
  }
  return true;
}

export function convertErrorFromServerToJson(errors) {
  scrollFirstErrorMessage();
  const res = {};
  errors.forEach((item) => {
    if (res[item.source.pointer.split('/').join('.').substring(1)]) {
      res[item.source.pointer.split('/').join('.').substring(1)].push(
        item.title
      );
    } else {
      res[item.source.pointer.split('/').join('.').substring(1)] = [item.title];
    }
  });
  return res;
}

export function isEmpty(value) {
  if (value === null || value === undefined || value === '') {
    return true;
  }
  if (typeof value === 'string' && value.trim().length === 0) {
    return true;
  }
  return Array.isArray(value) && value.length === 0;
}

export function isValidFormatDate(date, format) {
  return moment(date, format, true).isValid();
}
