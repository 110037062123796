
import arrowDown from '@/assets/svg/arrowDown.svg';
import {
  defineComponent,
  onBeforeUnmount,
  ref,
  toRefs,
  watchEffect,
} from '@vue/runtime-core';
import { useI18n } from 'vue-i18n';
import { computed } from 'vue';
import { isEmpty } from '@/util/useValidateForm';

export interface OptionsSelect {
  text?: string;
}

export default defineComponent({
  props: {
    optionsSelect: {
      type: (Array as unknown) as () => Array<OptionsSelect | string>,
      default: () => [],
    },
    placeholder: {
      type: String,
    },
    modelValue: {
      type: String || Number,
      default: '',
    },
    itemValue: {
      type: String,
      default: 'value',
    },
    itemText: {
      type: String,
      default: 'value',
    },
    selectOptionColor: {
      type: Function,
      default: () => ({}),
    },
    required: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
    },
    background: {
      type: String,
    },
    classes: {
      type: String,
    },
    validated: {
      type: Boolean,
      default: false,
    },
    errorServer: {
      type: String,
      default: '',
    },
    defaultValue: {
      type: String || Number,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    height: {
      type: String,
    },
    customValue: {
      type: String,
      default: null,
    },
  },
  setup(props, { emit }) {
    const selectTags = ref<HTMLHtmlElement>();
    const { modelValue, required, itemText, itemValue, optionsSelect } = toRefs(
      props
    );
    const i18n = useI18n();
    const t = i18n.t;
    let errorsMessage = ref();
    const isShowOption = ref(false);
    const selectOption = (item) => {
      if (typeof item === 'object') {
        emit('update:modelValue', item[itemValue.value]);
      } else {
        emit('update:modelValue', item);
      }
      emit('change', item);
      isShowOption.value = false;
      emit('change');
    };
    const value = computed(() => {
      if (indexSelected.value === null || indexSelected.value === -1) {
        return '';
      }
      if (
        typeof optionsSelect.value[0] === 'object' &&
        optionsSelect.value[indexSelected.value] !== undefined
      ) {
        return optionsSelect.value[indexSelected.value][itemText.value];
      }
      return modelValue.value;
    });
    const checkRequired = () => {
      if (required.value && isEmpty(modelValue.value)) {
        errorsMessage.value = t('errors.required');
      } else {
        errorsMessage.value = '';
      }
      emit('update:errors', errorsMessage.value);
    };
    const widthOptionSelect = ref();
    const toggleSelect = () => {
      isShowOption.value = !isShowOption.value;
      widthOptionSelect.value = selectTags.value?.offsetWidth;
    };
    watchEffect(() => {
      checkRequired();
    });
    const indexSelected = computed(() => {
      if (typeof optionsSelect.value[0] !== 'object') {
        return optionsSelect.value.indexOf(modelValue.value);
      }
      const item = optionsSelect.value.find(
        (item) => item[itemValue.value] === modelValue.value
      );
      return item ? optionsSelect.value.indexOf(item) : null;
    });
    onBeforeUnmount(() => {
      emit('update:errors', '');
    });
    const hoverOption = (e, item) => {
      emit('hoverItem', {
        e: selectTags.value,
        item: item,
      });
    };
    const hoverLeaveOption = (e) => {
      emit('hoverLeaveItem', e);
    };
    return {
      t,
      arrowDown,
      selectTags,
      errorsMessage,
      isShowOption,
      indexSelected,
      widthOptionSelect,
      value,
      toggleSelect,
      selectOption,
      hoverOption,
      hoverLeaveOption,
    };
  },
});
