import api, { ApiResponse } from '@/clients/api';
import { ErrorCode } from './types/general/ErrorCode';
import {
  JSONApiResponseBody,
  JSONApiResponseErrorBody,
} from '@/api/types/general/JSONApiResponse';
import ConstantsAPI from '@/constants/api';
import {
  SubstituteLeaveApplicationParams,
  SubstituteLeaveApplicationDetail,
} from '@/model/SubstituteLeaveApplication';

export async function postSubstituteLeaveApplication(
  data: SubstituteLeaveApplicationParams
): Promise<
  | ApiResponse<200, JSONApiResponseBody<SubstituteLeaveApplicationParams>>
  | ApiResponse<400, JSONApiResponseErrorBody<ErrorCode>>
  | ApiResponse<500, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.post(ConstantsAPI.SubstituteLeaveApplication, data);
}
export async function getSubsApplicationDetail(
  id: string
): Promise<
  | ApiResponse<200, JSONApiResponseBody<SubstituteLeaveApplicationDetail>>
  | ApiResponse<400, JSONApiResponseErrorBody<ErrorCode>>
  | ApiResponse<500, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.get(ConstantsAPI.SubstituteLeaveApplicationDetail(id));
}
