import api, { ApiResponse } from '@/clients/api';
import { ErrorCode } from './types/general/ErrorCode';
import {
  JSONApiResponseBody,
  JSONApiResponseErrorBody,
} from './types/general/JSONApiResponse';
import ConstantsAPI from '@/constants/api';
import { ApplicationBasicFlowSettingsApiGetResult } from '@/api/types/ApplicationBasicFlowSettingsApi';

export async function getApplicationBasicFlowSettings(): Promise<
  | ApiResponse<
      200,
      JSONApiResponseBody<ApplicationBasicFlowSettingsApiGetResult[]>
    >
  | ApiResponse<404, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.get(ConstantsAPI.ApplicationBasicFlowSettings);
}
