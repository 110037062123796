import api, { ApiResponse } from '@/clients/api';
import { ErrorCode } from './types/general/ErrorCode';
import {
  JSONApiResponseBody,
  JSONApiResponseErrorBody,
} from './types/general/JSONApiResponse';
import ConstantsAPI from '@/constants/api';
import {
  YearPaidHolidayApiDeleteResult,
  YearPaidHolidayApiGetParams,
  YearPaidHolidayApiGetResult,
  YearPaidHolidayApiPostData,
  YearPaidHolidayApiPostResult,
  YearPaidHolidayApiUpdateData,
  YearPaidHolidayApiUpdateResult,
} from '@/api/types/YearPaidHolidayApi';

export async function postYearPaidHoliday(
  postData: YearPaidHolidayApiPostData
): Promise<
  | ApiResponse<200, JSONApiResponseBody<YearPaidHolidayApiPostResult>>
  | ApiResponse<400, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.post(ConstantsAPI.YearPaidHolidayCreate, postData);
}

export async function getYearPaidHoliday(
  params: YearPaidHolidayApiGetParams
): Promise<
  | ApiResponse<200, JSONApiResponseBody<YearPaidHolidayApiGetResult[]>>
  | ApiResponse<400, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.get(ConstantsAPI.YearPaidHoliday, { params });
}

export async function getYearPaidHolidayDetail(
  id: string
): Promise<
  | ApiResponse<200, JSONApiResponseBody<YearPaidHolidayApiGetResult>>
  | ApiResponse<400, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.get(ConstantsAPI.YearPaidHolidayDetail(id));
}

export async function updateYearPaidHoliday(
  id: string,
  updateData: YearPaidHolidayApiUpdateData
): Promise<
  | ApiResponse<200, JSONApiResponseBody<YearPaidHolidayApiUpdateResult>>
  | ApiResponse<400, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.put(ConstantsAPI.YearPaidHolidayDetail(id), updateData);
}

export async function deleteYearPaidHoliday(
  id: string
): Promise<
  | ApiResponse<200, JSONApiResponseBody<YearPaidHolidayApiDeleteResult>>
  | ApiResponse<400, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.delete(ConstantsAPI.YearPaidHolidayDetail(id));
}
