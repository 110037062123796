import { ref } from 'vue';
import { getApplicationApprovalFlow } from '../applicationApprovalFlowStamps';

export default function () {
  const applicationApprovalData = ref();

  async function getApplicationApprovalFlowData(params) {
    try {
      const res = await getApplicationApprovalFlow(params);
      switch (res.status) {
        case 200:
          applicationApprovalData.value = res.data.data;
          break;
        default:
          applicationApprovalData.value = {};
          break;
          applicationApprovalData;
      }
    } catch (error) {
      console.error(error);
    }
  }

  return {
    applicationApprovalData,
    getApplicationApprovalFlowData,
  };
}
