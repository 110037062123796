<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 16.875C14.0711 16.875 15.75 15.1961 15.75 13.125C15.75 11.0539 14.0711 9.375 12 9.375C9.92893 9.375 8.25 11.0539 8.25 13.125C8.25 15.1961 9.92893 16.875 12 16.875Z"
      stroke="#333333"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M18.375 10.875C19.2484 10.8743 20.1098 11.0772 20.891 11.4677C21.6722 11.8583 22.3516 12.4256 22.8751 13.1246"
      stroke="#333333"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M1.125 13.1248C1.64854 12.4257 2.3279 11.8584 3.10912 11.4678C3.89033 11.0772 4.75187 10.8743 5.62527 10.875"
      stroke="#333333"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6.60352 20.2499C7.09619 19.238 7.86345 18.385 8.81771 17.7882C9.77197 17.1914 10.8748 16.875 12.0003 16.875C13.1258 16.875 14.2286 17.1914 15.1829 17.7882C16.1371 18.3849 16.9044 19.238 17.3971 20.2499"
      stroke="#333333"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.62444 10.875C5.05523 10.8749 4.49779 10.7129 4.01722 10.4078C3.53665 10.1027 3.15279 9.66726 2.91046 9.1522C2.66812 8.63715 2.57732 8.06378 2.64865 7.49906C2.71998 6.93433 2.9505 6.40155 3.31329 5.96293C3.67609 5.52431 4.15618 5.19796 4.69751 5.02198C5.23883 4.84599 5.81906 4.82765 6.37042 4.96908C6.92178 5.11052 7.42153 5.40589 7.8113 5.82072C8.20108 6.23554 8.4648 6.7527 8.57166 7.31179"
      stroke="#333333"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M15.4277 7.31137C15.5345 6.75219 15.7982 6.23494 16.188 5.82002C16.5778 5.40511 17.0776 5.10965 17.629 4.96816C18.1805 4.82667 18.7608 4.84499 19.3022 5.02098C19.8436 5.19696 20.3238 5.52335 20.6866 5.96203C21.0494 6.4007 21.28 6.93355 21.3513 7.49836C21.4226 8.06316 21.3318 8.6366 21.0894 9.15171C20.847 9.66683 20.4631 10.1023 19.9824 10.4074C19.5018 10.7125 18.9443 10.8745 18.375 10.8745"
      stroke="#333333"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
