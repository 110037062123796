
import { defineComponent, ref, watchEffect } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import Button from '@/components/share/Button/index.vue';
import RadioGroup from '@/components/share/Radio/RadioGroup.vue';
import PlusIcon from '@/components/Icons/PlusIcon.vue';
import { AlertSettingParams } from '@/model/AlertSetting';
import {
  useGetDetailAlertSetting,
  usePostAlertSetting,
  useUpdateAlertSetting,
} from '@/api/composables/useAlertSetting';
import {
  TIME_ATTENDANCE,
  WORK_STYLE_ALERT,
} from '@/constants/typeAlertSetting';
import { isValidateForm } from '@/util/useValidateForm';
import { showToast } from '@/util/useToastMessage';
import router from '@/router';
import ConstantsRouter from '@/constants/router';
export default defineComponent({
  components: {
    Button,
    RadioGroup,
    PlusIcon,
  },
  setup: function () {
    const i18n = useI18n();
    const t = i18n.t;
    const route = useRoute();
    const workScheduleOption = [
      {
        value: TIME_ATTENDANCE.ERROR_NOTIFICATION,
        label: i18n.t('alertSetting.errorNotification'),
      },
      {
        value: TIME_ATTENDANCE.ALERT_NOTIFICATION,
        label: i18n.t('alertSetting.alertNotification'),
      },
      {
        value: TIME_ATTENDANCE.DO_NOT_NOTIFY,
        label: i18n.t('alertSetting.doNotNotify'),
      },
    ];
    const workStyleAlertOption = [
      {
        value: WORK_STYLE_ALERT.ALERT_NOTIFICATION,
        label: i18n.t('alertSetting.alertNotification'),
      },
      {
        value: WORK_STYLE_ALERT.DO_NOT_NOTIFY,
        label: i18n.t('alertSetting.doNotNotify'),
      },
    ];
    const apiPostAlertData = ref<AlertSettingParams>({
      work_schedule_not_stamps: 0,
      work_holiday_not_application: 0,
      not_over_time_application: 0,
      duplicate_attendance_data: 0,
      duplicate_stamps: 0,
      upper_limit_over_time: 0,
      upper_limit_over_time_and_holiday_work: 0,
      upper_limit_over_time_work_special_provisions: 0,
    });
    const errorsServer = ref({});
    const errors = ref({});
    const validated = ref(false);
    const alertSettingId = ref();
    const isValid = () => {
      validated.value = true;
      return isValidateForm(errors);
    };
    const goToDetailAndUpdateAlertSetting = () => {
      router.push({
        path: `/new${ConstantsRouter.AlertSettingDetailUpdate}`,
        query: { id: alertSettingId.value },
      });
    };
    const createPostDataAlertSetting = async () => {
      if (!isValid()) return;
      const { postData, saveSettingCreatedId } = usePostAlertSetting(
        apiPostAlertData.value
      );
      postData
        .then((errors) => {
          if (errors) {
            errorsServer.value = errors.errors.value;
            if (errors?.errorsCommon.value) {
              showToast('error', errors?.errorsCommon.value);
            } else {
              showToast('warning', t('invalidInput'));
            }
          } else {
            showToast('success', t('alertSetting.messageCreatSuccess'));
          }
          alertSettingId.value = saveSettingCreatedId.value;
          if (alertSettingId.value) {
            goToDetailAndUpdateAlertSetting();
          }
        })
        .catch(() => {
          showToast('error', t('errors.serverError'));
        });
    };
    const {
      alertSettingDetail,
      loading,
      useDetailAlertSetting,
    } = useGetDetailAlertSetting();
    const updateDataAlertSetting = async () => {
      if (!isValid()) return;
      const { update } = useUpdateAlertSetting(
        router.currentRoute.value.query.id as string,
        apiPostAlertData.value
      );
      update
        .then((errors) => {
          if (errors) {
            errorsServer.value = errors.errors.value;
            if (errors?.errorsCommon.value) {
              showToast('error', errors?.errorsCommon.value);
            } else {
              showToast('warning', t('invalidInput'));
            }
          } else {
            showToast('success', t('alertSetting.messageUpdateSuccess'));
          }
        })
        .catch(() => {
          showToast('error', t('errors.serverError'));
        });
    };
    const handleNavigationAlertSetting = async () => {
      if (
        !alertSettingId.value &&
        route.path === `/new${ConstantsRouter.AlertSetting}`
      )
        await createPostDataAlertSetting();
      if (
        router.currentRoute.value.query.id &&
        route.path !== `/new${ConstantsRouter.AlertSetting}`
      ) {
        await updateDataAlertSetting();
        await useDetailAlertSetting(router.currentRoute.value.query.id);
      }
    };
    watchEffect(() => {
      if (alertSettingDetail.value) {
        apiPostAlertData.value = {
          ...apiPostAlertData.value,
          ...alertSettingDetail.value,
        };
      }
    });
    useDetailAlertSetting(router.currentRoute.value.query.id);
    return {
      t,
      i18n,
      workScheduleOption,
      workStyleAlertOption,
      errorsServer,
      errors,
      validated,
      apiPostAlertData,
      alertSettingDetail,
      alertSettingId,
      loading,
      isValid,
      goToDetailAndUpdateAlertSetting,
      createPostDataAlertSetting,
      updateDataAlertSetting,
      handleNavigationAlertSetting,
    };
  },
});
