import { ref } from 'vue';
import {
  getSettingViewItem,
  postSettingViewItem,
  updateSettingViewItem,
  recalculateTimeKeeping,
} from '@/api/settingViewItem';
import { convertErrorFromServerToJson } from '@/util/useValidateForm';
import {
  SettingViewItemUpdate,
  SettingViewTimeCardPostType,
  RecalculateTimeKeepingType,
} from '@/api/types/SettingViewItem';

export default function (params) {
  const resultViewItem = ref();
  const loading = ref(false);
  const response = ref();

  async function useGetSettingViewItem(params) {
    try {
      loading.value = true;
      const res = await getSettingViewItem(params);
      switch (res.status) {
        case 200:
          if (res.data.data.setting_view_time_card === '[object Object]') {
            res.data.data.setting_view_time_card = '[]';
          }
          resultViewItem.value = res.data.data;
          response.value = res.data;
          break;
        default:
          resultViewItem.value = {};
          break;
      }
    } catch (error) {
      console.error(error);
    } finally {
      loading.value = false;
    }
  }

  useGetSettingViewItem(params);

  return {
    resultViewItem,
    loading,
    useGetSettingViewItem,
  };
}

export function useUpdateSettingViewItem(
  id: string,
  data: SettingViewItemUpdate
) {
  const errors = ref({});
  const errorsCommon = ref<string>();
  const loading = ref(false);
  return {
    update: (async () => {
      loading.value = true;
      const response = await updateSettingViewItem(id, data);
      switch (response.status) {
        case 200:
          loading.value = false;
          return null;
        default:
          if (response.data.errors[0].source) {
            errors.value = convertErrorFromServerToJson(response.data.errors);
          } else {
            errorsCommon.value = response.data.errors[0].title;
          }
          loading.value = false;
          return {
            errors,
            errorsCommon,
          };
      }
    })(),
  };
}

export function postSettingViewAPI(data: SettingViewTimeCardPostType) {
  const errors = ref({});
  const errorsCommon = ref<string>();
  return {
    registered: (async () => {
      const response = await postSettingViewItem(data);
      switch (response.status) {
        case 200:
          return null;
        default:
          if (response.data.errors[0].source) {
            errors.value = convertErrorFromServerToJson(response.data.errors);
          } else {
            errorsCommon.value = response.data.errors[0].title;
          }
          return {
            errors,
            errorsCommon,
          };
      }
    })(),
  };
}

export function useRecalculateTimeKeeping(data: RecalculateTimeKeepingType) {
  const errors = ref({});
  const errorsCommon = ref<string>();
  return {
    recalculate: (async () => {
      const response = await recalculateTimeKeeping(data);
      switch (response.status) {
        case 200:
          return null;
        default:
          if (response.data.errors[0].source) {
            errors.value = convertErrorFromServerToJson(response.data.errors);
          } else {
            errorsCommon.value = response.data.errors[0].title;
          }
          return {
            errors,
            errorsCommon,
          };
      }
    })(),
  };
}
