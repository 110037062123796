import { ref } from 'vue';
import { getRequestMyShift, putRequestMyShift } from '@/api/requestShift';
import {
  RequestMyShiftApiGetParams,
  RequestMyShiftApiGetResult,
  RequestMyShiftApiUpdateData,
} from '@/api/types/RequestShiftApi';
import { convertErrorFromServerToJson } from '@/util/useValidateForm';

export function useGetRequestMyShift() {
  const requestMyShift = ref<RequestMyShiftApiGetResult>({
    submit_desired_shifts: [
      {
        shift_plan_employee_date: {},
      },
    ],
  });
  const loading = ref(false);

  async function getRequestMyShiftData(params: RequestMyShiftApiGetParams) {
    try {
      loading.value = true;
      const res = await getRequestMyShift(params);

      switch (res.status) {
        case 200:
          requestMyShift.value = res.data.data;
          break;
        default:
          requestMyShift.value = {
            submit_desired_shifts: [{ shift_plan_employee_date: {} }],
          };
          break;
      }
    } catch (error) {
      console.error(error);
    } finally {
      loading.value = false;
    }
  }

  return { requestMyShift, loading, getRequestMyShiftData };
}

export function usePutRequestMyShift() {
  const loading = ref(false);
  const errors = ref({});
  const errorsCommon = ref<string>();

  async function putRequestMyShiftData(
    id: string,
    params: RequestMyShiftApiUpdateData
  ) {
    try {
      loading.value = true;
      const res = await putRequestMyShift(id, params);
      if (res.status === 200) {
        return null;
      } else {
        if (res.data.errors[0].source) {
          errors.value = convertErrorFromServerToJson(res.data.errors);
        } else {
          errorsCommon.value = res.data.errors[0].title;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      loading.value = false;
    }
    return {
      errors,
      errorsCommon,
    };
  }

  return { putRequestMyShiftData };
}
