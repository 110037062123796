import { ref } from 'vue';
import { convertErrorFromServerToJson } from '@/util/useValidateForm';
import {
  LateEarlyApplicationApiGetResult,
  LateEarlyApplicationApiPostData,
} from '@/api/types/LateEarlyApplicationApi';
import {
  getLateEarlyApplicationDetail,
  postLateEarlyApplication,
} from '@/api/lateEarlyApplication';

export function usePostLateEarlyApplication(
  data: LateEarlyApplicationApiPostData
) {
  const errors = ref({});
  const errorsCommon = ref<string>();
  return {
    registered: (async () => {
      const response = await postLateEarlyApplication(data);
      switch (response.status) {
        case 200:
          return null;
        default:
          if (response.data.errors[0].source) {
            errors.value = convertErrorFromServerToJson(response.data.errors);
          } else {
            errorsCommon.value = response.data.errors[0].title;
          }
          return {
            errors,
            errorsCommon,
          };
      }
    })(),
  };
}

export function useDetailLateEarlyApplication() {
  const lateEarlyApplicationDetail = ref<LateEarlyApplicationApiGetResult>({
    late_or_early_check_times: [],
  });
  const loading = ref(false);

  async function getLateEarlyApplicationDetailData(id) {
    try {
      loading.value = true;
      const res = await getLateEarlyApplicationDetail(id);
      const response = ref();
      switch (res.status) {
        case 200:
          response.value = res.data;
          lateEarlyApplicationDetail.value = response.value.data;
          return lateEarlyApplicationDetail.value;
        default:
          lateEarlyApplicationDetail.value = {
            late_or_early_check_times: [],
          };
          return lateEarlyApplicationDetail.value;
      }
    } catch (error) {
      console.error(error);
      return {};
    } finally {
      loading.value = false;
    }
  }

  return {
    lateEarlyApplicationDetail,
    loading,
    getLateEarlyApplicationDetailData,
  };
}
