
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';
import ConstantsRouter from '@/constants/router';
import Tab from '@/components/share/Tabs/index.vue';
export default defineComponent({
  components: {
    Tab,
  },
  setup() {
    const i18n = useI18n();
    const t = i18n.t;
    const dataTabs = [
      {
        path: `/new${ConstantsRouter.ApplicationApprovalRequest}`,
        text: t('approvalRequest'),
      },
      {
        path: `/new${ConstantsRouter.ApplicationMyApproval}`,
        text: t('myApplication'),
      },
    ];
    return {
      t,
      dataTabs,
    };
  },
});
