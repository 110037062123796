
import { computed, defineComponent, ref, watch } from 'vue';
import MorePaging from '@/components/Icons/MorePaging.vue';
import Dropdown from '@/components/share/Dropdown/Dropdown.vue';
import { useI18n } from 'vue-i18n';
import { watchEffect } from '@vue/runtime-core';
export default defineComponent({
  components: {
    Dropdown,
    MorePaging,
  },
  props: {
    totalItem: {
      type: Number,
      default: 1,
    },
    showItemPerPage: {
      type: Boolean,
      default: false,
    },
    pageActive: {
      type: Number,
      default: 1,
    },
    perPage: {
      type: Number,
      default: 10,
    },
  },
  setup(props, { emit }) {
    const i18n = useI18n();
    const t = i18n.t;
    const totalPage = computed(
      () =>
        Math.floor(props.totalItem / props.perPage) +
        (props.totalItem % props.perPage != 0 ? 1 : 0)
    );
    const choosePage = (value) => {
      if (props.pageActive === value) return;
      emit('update:pageActive', value);
      emit('changePage', value);
    };
    const showMoreIncrease = (page) => {
      if (props.pageActive > 1) {
        return page == props.pageActive + 2 && page + 1 <= totalPage.value;
      } else {
        return page == props.pageActive + 3 && page + 3 <= totalPage.value;
      }
    };

    const showMoreDecrease = (page) => {
      if (props.pageActive === 4 && totalPage.value === 4) return false;
      if (props.pageActive <= 3) return false;
      return page == props.pageActive - 2 && page + 1 > 1;
    };

    const itemPerPagesValue = ref(props.perPage);
    watch(props, () => {
      itemPerPagesValue.value = props.perPage;
    });
    watchEffect(() => {
      emit('update:perPage', itemPerPagesValue.value);
      emit('changePerPage', itemPerPagesValue.value);
    });

    return {
      t,
      totalPage,
      itemPerPagesValue,
      choosePage,
      showMoreIncrease,
      showMoreDecrease,
    };
  },
});
