import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "py-10 flex justify-start w-full px-56 pr-80" }
const _hoisted_2 = { class: "justify-end flex mr-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.buttonList, (btn) => {
        return (_openBlock(), _createBlock(_component_router_link, {
          key: btn.text,
          to: btn.path,
          class: [_ctx.currentPath === btn.path ? 'blue-txt border-blue' : '', "rounded-59 height-43 border-solid btn-change-tab flex items-center justify-center mr-4"]
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(btn.text), 1)
          ]),
          _: 2
        }, 1032, ["to", "class"]))
      }), 128))
    ])
  ]))
}