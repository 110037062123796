import { createVNode as _createVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "datepicker--calendar-year" }
const _hoisted_2 = { class: "flex justify-between items-center text-grey-333" }
const _hoisted_3 = { class: "mt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return _withDirectives((_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("header", _hoisted_2, [
      _createVNode("span", {
        class: "cp-icon-chevron-left cursor-pointer text-2xl",
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.prevYear && _ctx.prevYear(...args)))
      }),
      _createVNode("span", {
        class: _ctx.isClickYearHeader ? 'cursor-pointer year-header' : '',
        onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onClickYearHeader && _ctx.onClickYearHeader(...args)))
      }, _toDisplayString(_ctx.startYear) + " - " + _toDisplayString(_ctx.startYear + 9), 3),
      _createVNode("span", {
        class: "cp-icon-chevron-right cursor-pointer text-2xl",
        onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.nextYear && _ctx.nextYear(...args)))
      })
    ]),
    _createVNode("div", _hoisted_3, [
      (_openBlock(true), _createBlock(_Fragment, null, _renderList(Array.from(Array(10).keys()), (item) => {
        return (_openBlock(), _createBlock("span", {
          onClick: ($event: any) => (_ctx.onSelectYear(_ctx.startYear + item)),
          class: ["cell year", [
          _ctx.startYear + item === Number(_ctx.value) ? 'selected' : '',
          _ctx.startYear + item === _ctx.year ? 'year-current' : '',
        ]],
          key: item
        }, _toDisplayString(_ctx.startYear + item), 11, ["onClick"]))
      }), 128))
    ])
  ], 512)), [
    [_vShow, _ctx.isShowCalendar]
  ])
}