
import { useI18n } from 'vue-i18n';

import { defineComponent, ref } from 'vue';
import Avatar from '@/components/share/Avatar/index.vue';
import { getListAvatarUser } from '@/api/composables/useGetListAvatarUser';

export default defineComponent({
  components: { Avatar },
  props: {
    userInfo: {
      type: Object,
    },
  },
  setup(props) {
    const i18n = useI18n();
    const t = i18n.t;
    const nameAvatar = ref([]);
    //call api get avatar employee
    const getAvatarEmployee = async () => {
      const res = await getListAvatarUser([props.userInfo?.pp_employee_id]);
      nameAvatar.value = res.data;
    };
    getAvatarEmployee();

    return {
      t,
      i18n,
      nameAvatar,
    };
  },
});
