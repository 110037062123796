
import { defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import Button from '@/components/share/Button/index.vue';
import Table from '@/components/share/Table/Table.vue';
import { usePopupDeleteStore } from '@/store/modules/popup-delete';
import router from '@/router';
import {
  useDeleteShiftPattern,
  useGetShiftPatternList,
} from '@/api/composables/useShiftPattern';
import ConstantsRouter from '@/constants/router';
import { ShiftPatternsParamSearch } from '@/model/ShiftPattern';
import { showToast } from '@/util/useToastMessage';
import EditIcon from '@/components/Icons/EditIcon.vue';
import CopyIcon from '@/components/Icons/CopyIcon.vue';
import TrashIcon from '@/components/Icons/TrashIcon.vue';

export default defineComponent({
  components: {
    Button,
    Table,
    EditIcon,
    CopyIcon,
    TrashIcon,
  },
  setup() {
    const i18n = useI18n();
    const t = i18n.t;
    const store = usePopupDeleteStore();
    const itemRemoveId = ref();
    const apiGetParamSearch = ref<ShiftPatternsParamSearch>({
      page: 1,
    });
    const headerDatas = [
      {
        text: t('workPatternCode'),
        value: 'shift_pattern_code',
        width: '20%',
        bodyClass: 'truncate-2-line text-center',
      },
      {
        text: t('workPatternName'),
        value: 'shift_pattern_name',
        width: '32.5%',
        bodyClass: 'truncate-2-line text-center',
      },
      {
        text: t('edit'),
        value: 'edit',
        width: '5%',
      },
      {
        text: t('delete'),
        value: 'delete',
        width: '5%',
      },
      {
        text: t('duplicate'),
        value: 'copy',
        width: '5%',
      },
    ];
    const tableDatas = ref([
      {
        id: 1,
        code: '001',
        name: '正社員A　9：00～18：00',
        app_orang: 'すべての組織',
        edit: 'edit',
        delete: 'delete',
        copy: 'copy',
      },
      {
        id: 2,
        code: '002',
        name: '正社員B　8：00～17：00',
        app_orang: 'すべての組織',
        edit: 'edit',
        delete: 'delete',
        copy: 'copy',
      },
      {
        id: 3,
        code: '003',
        name: '〇〇店A　8：30～17：30',
        app_orang: 'T000　東京丸の内店',
        edit: 'edit',
        delete: 'delete',
        copy: 'copy',
      },
      {
        id: 4,
        code: '004',
        name: '〇〇店B　8：00～17：00',
        app_orang: 'T000　東京丸の内店',
        edit: 'edit',
        delete: 'delete',
        copy: 'copy',
      },
      {
        id: 5,
        code: '005',
        name: '〇〇店C　9：00～18：00',
        app_orang: 'T000　東京丸の内店',
        edit: 'edit',
        delete: 'delete',
        copy: 'copy',
      },
    ]);
    const handleAddWork = () => {
      router.push(ConstantsRouter.WorkPatternAddNew);
    };
    const deleteAction = async () => {
      const { deleteData } = useDeleteShiftPattern(itemRemoveId.value);
      await deleteData
        .then((error) => {
          if (error) {
            showToast('error', t('errors.shiftPatternDeleteFail'));
          } else {
            showToast('success', t('successMessage.workPatternDeleted'));
            if (
              shiftPatternData.value?.length === 1 &&
              apiGetParamSearch.value.page
            ) {
              apiGetParamSearch.value.page -= 1;
            }
            getShiftPatternList(apiGetParamSearch.value);
          }
        })
        .catch(() => {
          showToast('error', t('errors.serverError'));
        });
    };
    const handleDeleteItem = (item) => {
      store.dispatch('show', {
        title: t('deleteWorkPattern'),
        body: `${t('workPattern')}「${item.shift_pattern_name}」${t(
          'toRemove'
        )}`,
        isShown: true,
        onSubmit: deleteAction,
      });
      itemRemoveId.value = item.shift_pattern_id;
    };
    const handleUpdateShiftPattern = (item) => {
      router.push({
        path: ConstantsRouter.WorkPatternUpdateNew,
        query: { id: item.shift_pattern_id },
      });
    };

    const {
      shiftPatternData,
      getShiftPatternList,
      pagination,
    } = useGetShiftPatternList();
    getShiftPatternList(apiGetParamSearch.value);
    const onChangePage = () => {
      getShiftPatternList(apiGetParamSearch.value);
    };
    const displayUnitName = (item) => {
      if (item.unit_names?.length > 0) {
        return item.unit_names.join(', ');
      }
      return '';
    };
    const handleCopyShiftPattern = (item) => {
      router.push({
        path: ConstantsRouter.WorkPatternCopyNew,
        query: { id: item.shift_pattern_id },
      });
    };
    return {
      t,
      headerDatas,
      shiftPatternData,
      tableDatas,
      itemRemoveId,
      store,
      getShiftPatternList,
      apiGetParamSearch,
      pagination,
      deleteAction,
      handleAddWork,
      handleDeleteItem,
      handleUpdateShiftPattern,
      onChangePage,
      handleCopyShiftPattern,
      displayUnitName,
    };
  },
});
