import api, { ApiResponse } from '@/clients/api';
import { ErrorCode } from './types/general/ErrorCode';
import {
  JSONApiResponseBody,
  JSONApiResponseErrorBody,
} from './types/general/JSONApiResponse';
import ConstantsAPI from '@/constants/api';
import {
  LateEarlyApplicationApiGetResult,
  LateEarlyApplicationApiPostData,
  LateEarlyApplicationApiPostResult,
} from '@/api/types/LateEarlyApplicationApi';
import { objectToFormData } from '@/util/useJsonToFormData';

export async function postLateEarlyApplication(
  postData: LateEarlyApplicationApiPostData
): Promise<
  | ApiResponse<200, JSONApiResponseBody<LateEarlyApplicationApiPostResult>>
  | ApiResponse<400, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.post(
    ConstantsAPI.LateEarlyApplication,
    objectToFormData(postData)
  );
}

export async function getLateEarlyApplicationDetail(
  id: string
): Promise<
  | ApiResponse<200, JSONApiResponseBody<LateEarlyApplicationApiGetResult>>
  | ApiResponse<400, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.get(ConstantsAPI.LateEarlyApplicationDetail(id));
}
