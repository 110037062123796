
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';
import Table from '@/components/share/Table/Table.vue';
import Button from '@/components/share/Button/index.vue';
import BaseIcon from '@/components/Icons/BaseIcon.vue';
import router from '@/router';
import ConstantsRouter from '@/constants/router';
import { useApplicationBasicFlowSettings } from '@/api/composables/useApplicationBasicFlowSettings';

export default defineComponent({
  components: { BaseIcon, Button, Table },
  setup() {
    const i18n = useI18n();
    const t = i18n.t;
    const basicFlowHeaders = [
      {
        text: t('basicFlowApplicationType'),
        value: 'application_define_name',
      },
      {
        text: `${t('approve')} <br /> ${t('numberOfFlows')}`,
        value: 'total_level',
        type: 'html',
      },
      {
        text: t('edit'),
        value: 'edit',
        width: '48px',
      },
    ];
    const editBasicFlowSettings = (item) => {
      router.push({
        path: `/new${ConstantsRouter.ApprovedSettingFlowUpdate}`,
        query: {
          applicationDefineId: item.application_define_id,
          applicationFlowId: item.application_flow_id,
        },
      });
    };
    const {
      applicationBasicFlowSettingsList,
      getApplicationBasicFlowSettingsData,
    } = useApplicationBasicFlowSettings();
    getApplicationBasicFlowSettingsData();
    return {
      t,
      basicFlowHeaders,
      applicationBasicFlowSettingsList,
      getApplicationBasicFlowSettingsData,
      editBasicFlowSettings,
    };
  },
});
