import {
  ApproveFlowListDatasUpdate,
  BasicFlowSettingUpdateParam,
  PersonalFlowSettingCreateParam,
  PersonalFlowSettingUpdateParam,
} from '@/model/ApproveSetting';
import { ref } from 'vue';
import {
  getApproveFlowList,
  updateApproveFlowList,
  getListAppFormDefines,
  getDetailAppBasicFlowSetting,
  updateBasicFlowSetting,
  getDetailAppPersonalFlowSetting,
  getListPersonalFlowSettingFollowUnitId,
  getSearchEmploy,
  getSearchRoles,
  createPersonalFlowSetting,
  updatePersonalFlowSetting,
  getListPersonalFlowSetting,
  ResetPersonalSettingFlow,
} from '../approvalSettings';
import { isEmpty } from 'lodash';
import { UnitApiGetResult } from '../types/UnitApi';
import { convertErrorFromServerToJson } from '@/util/useValidateForm';

const error = {
  errors: [
    {
      code: '102',
      title: 'トランザクション処理に失敗しました。',
    },
  ],
};

export function useGetApproveFlowList(params) {
  const approveFlowListData = ref<UnitApiGetResult[]>([]);
  const pagination = ref({});
  const loading = ref(false);

  async function handleGetApproveFlowList(params) {
    try {
      loading.value = true;
      const res = await getApproveFlowList(params);

      switch (res.status) {
        case 200:
          approveFlowListData.value = res.data.data;
          pagination.value = res.data.meta ? res.data.meta.pagination : {};
          break;
        default:
          approveFlowListData.value = [];
          pagination.value = {};
          break;
      }
    } catch (error) {
      console.error(error);
    } finally {
      loading.value = false;
    }
  }

  handleGetApproveFlowList(params);

  return { approveFlowListData, loading, handleGetApproveFlowList, pagination };
}

export function handleUpdateApproveFlowList(
  params: ApproveFlowListDatasUpdate[]
) {
  const loadingUpdate = ref(false);
  return {
    update: (async () => {
      try {
        loadingUpdate.value = true;
        const response = await updateApproveFlowList(params);
        switch (response.status) {
          case 200:
            return null;
          default:
            return error;
        }
      } catch (error) {
        return error;
      } finally {
        loadingUpdate.value = false;
      }
    })(),
    loadingUpdate,
  };
}

export function useGetListAppFormDefines() {
  const listAppFormDefines = ref();
  const appFormDefines = ref();
  const loading = ref(false);

  async function handleGetListAppFormDefines() {
    try {
      loading.value = true;
      const res = await getListAppFormDefines();

      switch (res.status) {
        case 200:
          appFormDefines.value = res.data.data;
          listAppFormDefines.value = res.data.data.map((el) => {
            return {
              value: el.id,
              text: el.application_define_name,
            };
          });
          break;
        default:
          listAppFormDefines.value = [];
          break;
      }
    } catch (error) {
      console.error(error);
    } finally {
      loading.value = false;
    }
  }

  handleGetListAppFormDefines();

  return { listAppFormDefines, appFormDefines, handleGetListAppFormDefines };
}

export function useGetDetailAppBasicFlowSetting() {
  const appBasicFlowSettingDetail = ref();
  const basicFlowSettingDetail = ref<BasicFlowSettingUpdateParam>();
  const loading = ref(false);
  const defineId = ref();

  async function handleDetailAppBasicFlowSetting(id) {
    try {
      loading.value = true;
      const res = await getDetailAppBasicFlowSetting(id);

      switch (res.status) {
        case 200:
          basicFlowSettingDetail.value = res.data.data;
          appBasicFlowSettingDetail.value = [];
          defineId.value = res.data.data.application_define_id;
          appBasicFlowSettingDetail.value = !isEmpty(
            res.data.data.application_flow_details
          )
            ? res.data.data.application_flow_details
            : [
                {
                  approval_level: 0,
                  level_order: 0,
                  setting_register_flow: 0,
                  approval_role_id: '',
                  approval_employee_id: '',
                },
              ];
          return basicFlowSettingDetail.value;
        default:
          appBasicFlowSettingDetail.value = [];
          defineId.value = '';
          return basicFlowSettingDetail.value;
      }
    } catch (error) {
      console.error(error);
    } finally {
      loading.value = false;
    }
  }

  return {
    appBasicFlowSettingDetail,
    basicFlowSettingDetail,
    defineId,
    loading,
    handleDetailAppBasicFlowSetting,
  };
}

export function handleUpdateBasicFlowSetting(
  params: BasicFlowSettingUpdateParam
) {
  const loadingUpdate = ref(false);
  return {
    update: (async () => {
      try {
        loadingUpdate.value = true;
        const response = await updateBasicFlowSetting(params);
        switch (response.status) {
          case 200:
            return null;
          default:
            return error;
        }
      } catch (error) {
        return error;
      } finally {
        loadingUpdate.value = false;
      }
    })(),
    loadingUpdate,
  };
}

export function useGetDetailAppPersonalFlowSetting() {
  const appPersonalSettingDetail = ref();
  const loading = ref(false);
  async function handleGetDetailAppPersonalFlowSetting(idFlow) {
    try {
      loading.value = true;
      const res = await getDetailAppPersonalFlowSetting(idFlow);

      switch (res.status) {
        case 200:
          appPersonalSettingDetail.value = res.data.data;
          break;
        default:
          appPersonalSettingDetail.value = [];
          break;
      }
    } catch (error) {
      console.error(error);
    } finally {
      loading.value = false;
    }
  }

  return {
    appPersonalSettingDetail,
    handleGetDetailAppPersonalFlowSetting,
  };
}

export function useGetListPersonalFlowSettingFollowUnitId() {
  const personalSettingFollowUnitIdDetail = ref();
  const loading = ref(false);
  async function handleGetListPersonalFlowSettingFollowUnitId(unitId) {
    try {
      loading.value = true;
      const res = await getListPersonalFlowSettingFollowUnitId(unitId);

      switch (res.status) {
        case 200:
          personalSettingFollowUnitIdDetail.value = res.data.data;
          break;
        default:
          personalSettingFollowUnitIdDetail.value = [];
          break;
      }
    } catch (error) {
      console.error(error);
    } finally {
      loading.value = false;
    }
  }

  return {
    personalSettingFollowUnitIdDetail,
    handleGetListPersonalFlowSettingFollowUnitId,
  };
}

export function useGetListPersonalFlowSetting() {
  const personalSettingData = ref();
  const loading = ref(false);
  async function handleGetListPersonalFlowSetting(unitId) {
    try {
      loading.value = true;
      const res = await getListPersonalFlowSetting(unitId);

      switch (res.status) {
        case 200:
          personalSettingData.value = res.data.data;
          break;
        default:
          personalSettingData.value = [];
          break;
      }
    } catch (error) {
      console.error(error);
    } finally {
      loading.value = false;
    }
  }

  return {
    personalSettingData,
    handleGetListPersonalFlowSetting,
  };
}

export function handleCreatePersonalFlowSetting(
  params: PersonalFlowSettingCreateParam
) {
  const workFlowId = ref();
  const loadingUpdate = ref(false);
  return {
    create: (async () => {
      try {
        loadingUpdate.value = true;
        const response = await createPersonalFlowSetting(params);
        switch (response.status) {
          case 200:
            workFlowId.value = response.data.data;
            return null;
          default:
            return error;
        }
      } catch (error) {
        return error;
      } finally {
        loadingUpdate.value = false;
      }
    })(),
    loadingUpdate,
    workFlowId,
  };
}

export function handleUpdatePersonalFlowSetting(
  id: string,
  params: PersonalFlowSettingUpdateParam
) {
  const loadingUpdate = ref(false);
  return {
    update: (async () => {
      try {
        loadingUpdate.value = true;
        const response = await updatePersonalFlowSetting(id, params);
        switch (response.status) {
          case 200:
            return null;
          default:
            return error;
        }
      } catch (error) {
        return error;
      } finally {
        loadingUpdate.value = false;
      }
    })(),
    loadingUpdate,
  };
}

export function useGetSearchEmploy() {
  const listApprovalEmploye = ref();
  const loading = ref(false);

  async function handleGetSearchEmploy() {
    try {
      loading.value = true;
      const res = await getSearchEmploy();
      switch (res.status) {
        case 200:
          listApprovalEmploye.value = [];

          break;
        default:
          listApprovalEmploye.value = [];
          break;
      }
    } catch (error) {
      console.error(error);
    } finally {
      loading.value = false;
    }
  }
  handleGetSearchEmploy();
  return {
    listApprovalEmploye,
  };
}

export function useGetSearchRoles() {
  const listApprovalRole = ref();
  const loading = ref(false);

  async function handleGetSearchRoles() {
    try {
      loading.value = true;
      const res = await getSearchRoles();

      switch (res.status) {
        case 200:
          listApprovalRole.value = res.data.data
            ? res.data.data.map((el) => {
                return {
                  value: el.id,
                  text: el.role_name,
                };
              })
            : [];

          break;
        default:
          listApprovalRole.value = [];
          break;
      }
    } catch (error) {
      console.error(error);
    } finally {
      loading.value = false;
    }
  }
  handleGetSearchRoles();
  return {
    listApprovalRole,
  };
}

export function useResetPersonalSettingFlow(id: string) {
  const errors = ref({});
  const errorsCommon = ref<string>();
  return {
    deleted: (async () => {
      const response = await ResetPersonalSettingFlow(id);
      switch (response.status) {
        case 200:
          return null;
        default:
          if (response.data.errors[0].source) {
            errors.value = convertErrorFromServerToJson(response.data.errors);
          } else {
            errorsCommon.value = response.data.errors[0].title;
          }
          return {
            errors,
            errorsCommon,
          };
      }
    })(),
  };
}
