import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "mb-8 flex" }
const _hoisted_2 = { class: "flex mr-2" }
const _hoisted_3 = { class: "flex flex-column mr-5" }
const _hoisted_4 = { class: "font-size-12 text-gray-999" }
const _hoisted_5 = { class: "font-bold" }
const _hoisted_6 = { class: "flex flex-column mr-5" }
const _hoisted_7 = { class: "font-size-12 text-gray-999" }
const _hoisted_8 = { class: "flex flex-column mr-5" }
const _hoisted_9 = { class: "font-size-12 text-gray-999" }
const _hoisted_10 = { class: "flex justify-center items-center" }
const _hoisted_11 = { class: "mr-3" }
const _hoisted_12 = {
  key: 0,
  class: "time-card-total"
}
const _hoisted_13 = { class: "mb-4 font-size-18" }
const _hoisted_14 = { class: "time-total mb-8 grid grid-cols-10 divide-x pt-4 pb-4 bg-white" }
const _hoisted_15 = { class: "font-size-14 font-bold" }
const _hoisted_16 = { class: "font-size-24" }
const _hoisted_17 = { class: "mb-4 font-size-18" }
const _hoisted_18 = { class: "time-total divide-x pt-4 pb-4 bg-white" }
const _hoisted_19 = { class: "font-size-14 font-bold" }
const _hoisted_20 = { class: "font-size-24" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Avatar = _resolveComponent("Avatar")
  const _component_Switch = _resolveComponent("Switch")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("div", _hoisted_1, [
      _createVNode("div", _hoisted_2, [
        _createVNode(_component_Avatar, {
          id: _ctx.ppEmployeeId ? _ctx.ppEmployeeId : _ctx.myAccount.pp_employee_id,
          name: _ctx.nameAvatar[0]?.profileFile ? '' : _ctx.nameAvatar[0]?.nameFaceImage,
          src: _ctx.nameAvatar[0]?.profileFile,
          cursor: "default"
        }, null, 8, ["id", "name", "src"])
      ]),
      _createVNode("div", _hoisted_3, [
        _createVNode("span", _hoisted_4, _toDisplayString(_ctx.t('fullName')), 1),
        _createVNode("div", _hoisted_5, [
          _createVNode("div", null, _toDisplayString(_ctx.convertUserTimCard.employee_name), 1)
        ])
      ]),
      _createVNode("div", _hoisted_6, [
        _createVNode("span", _hoisted_7, _toDisplayString(_ctx.t('deploy')), 1),
        _createVNode("div", null, _toDisplayString(_ctx.convertUserTimCard.unit_name), 1)
      ]),
      _createVNode("div", _hoisted_8, [
        _createVNode("span", _hoisted_9, _toDisplayString(_ctx.t('timeCardNew.employeeNumber')), 1),
        _createVNode("div", null, [
          _createVNode("div", null, _toDisplayString(_ctx.convertUserTimCard.employee_code), 1)
        ])
      ]),
      _createVNode("div", _hoisted_10, [
        _createVNode("div", _hoisted_11, _toDisplayString(_ctx.t('timeCardNew.viewAggregates')), 1),
        _createVNode(_component_Switch, {
          modelValue: _ctx.isViewAggregates,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.isViewAggregates = $event))
        }, null, 8, ["modelValue"])
      ])
    ]),
    (_ctx.isViewAggregates)
      ? (_openBlock(), _createBlock("div", _hoisted_12, [
          _createVNode("div", _hoisted_13, _toDisplayString(_ctx.t('timeCardNew.totalNumber')), 1),
          _createVNode("div", _hoisted_14, [
            (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.daySummaryHeaders, (item) => {
              return (_openBlock(), _createBlock("div", {
                key: item.value,
                class: "content text-center time-total__item"
              }, [
                _createVNode("div", _hoisted_15, _toDisplayString(item.text), 1),
                _createVNode("div", _hoisted_16, _toDisplayString(_ctx.detailTimeCardMonthly[item.value] || '0'), 1)
              ]))
            }), 128))
          ]),
          _createVNode("div", _hoisted_17, _toDisplayString(_ctx.t('timeCardNew.timeTabulation')), 1),
          _createVNode("div", _hoisted_18, [
            (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.timeSummaryHeaders, (item) => {
              return (_openBlock(), _createBlock("div", {
                key: item.value,
                class: "content text-center time-total__item"
              }, [
                _createVNode("div", _hoisted_19, _toDisplayString(item.text), 1),
                _createVNode("div", _hoisted_20, _toDisplayString(_ctx.displayTimeSummary(item.value, item.show)), 1)
              ]))
            }), 128))
          ])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}