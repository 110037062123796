import api, { ApiResponse } from '@/clients/api';
import { ErrorCode } from './types/general/ErrorCode';
import {
  JSONApiResponseBody,
  JSONApiResponseErrorBody,
} from '@/api/types/general/JSONApiResponse';
import ConstantsAPI from '@/constants/api';
import {
  ApplicationApprovalApiGetResult,
  ApplicationApprovalRequestParams,
} from './types/ApplicationApprovalRequest';
import {
  ApplicationApprovalApiPutParams,
  ApplicationApprovalApiPutResult,
} from '@/model/ApplicationApprovalRequest';

export async function getAllApplicationApproval(
  params: ApplicationApprovalRequestParams
): Promise<
  | ApiResponse<200, JSONApiResponseBody<ApplicationApprovalApiGetResult>>
  | ApiResponse<400, JSONApiResponseErrorBody<ErrorCode>>
  | ApiResponse<500, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.get(ConstantsAPI.applicationApproval, { params });
}

export async function putApprovalApplicationForm(
  updateData: ApplicationApprovalApiPutParams
): Promise<
  | ApiResponse<200, JSONApiResponseBody<ApplicationApprovalApiPutResult>>
  | ApiResponse<400, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.put(ConstantsAPI.ApprovalApplicationForm, updateData);
}

export async function putRejectApplicationForm(
  updateData: ApplicationApprovalApiPutParams
): Promise<
  | ApiResponse<200, JSONApiResponseBody<ApplicationApprovalApiPutResult>>
  | ApiResponse<400, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.put(ConstantsAPI.RejectApplicationForm, updateData);
}

export async function deleteApplicationForm(
  id: string
): Promise<
  | ApiResponse<200, JSONApiResponseBody<ApplicationApprovalApiPutResult>>
  | ApiResponse<400, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.put(ConstantsAPI.DeleteApplication(id));
}

export async function resendApplicationForm(
  id: string
): Promise<
  | ApiResponse<200, JSONApiResponseBody<ApplicationApprovalApiPutResult>>
  | ApiResponse<400, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.put(ConstantsAPI.ResendApplication(id));
}
