
import { defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import Popup from '@/components/Popup.vue';
import Table from '@/components/Table.vue';
import InputArea from '@/components/InputArea.vue';
import GradationButton from '@/components/GradationButton.vue';
export default defineComponent({
  name: 'ModalApplicationApproval',
  components: {
    InputArea,
    Table,
    Popup,
    GradationButton,
  },
  props: {
    closeModalListUser: { type: Function, default: () => [] },
  },
  setup(props, { emit }) {
    const i18n = useI18n();
    const itemApplicationApproval = ref({
      id: '',
      body: '',
      title: '',
    });
    const applicationModalHeaders = [
      {
        text: '',
        value: 'title',
        width: '200px',
      },
      {
        text: i18n.t('stampRegister.attendanceWork'),
        value: 'attendance_work',
      },
      {
        text: i18n.t('stampRegister.leavingWork'),
        value: 'leaving_work',
      },
      {
        text: i18n.t('stampRegister.breakStart'),
        value: 'break_start',
      },
      {
        text: i18n.t('stampRegister.breakEnd'),
        value: 'break_end',
      },
      {
        text: i18n.t('stampRegister.workPlace'),
        value: 'work_place',
      },
    ];
    const applicationModalData = [
      {
        title: '実績打刻',
        attendance_work: '8 : 52',
        leaving_work: '',
        break_start: '',
        break_end: '',
        work_place: 'テレワーク',
      },
      {
        title: '申請打刻',
        attendance_work: '',
        leaving_work: '',
        break_start: '',
        break_end: '',
        work_place: 'テレワーク',
      },
    ];
    const applicationModalFooterHeaders = [
      {
        text: '',
        value: 'titleFooter',
        width: '200px',
      },
      {
        text: i18n.t('approvedSetting.organization'),
        value: 'organizationFooter',
      },
      {
        text: i18n.t('director'),
        value: 'director',
      },
      {
        text: i18n.t('fullName'),
        value: 'full_name',
      },
      {
        text: i18n.t('applicationApprovalContent.approverComment'),
        value: 'approver_comment',
      },
      {
        text: i18n.t('applicationApprovalContent.updateDateAndTime'),
        value: 'update_date_and_time',
      },
      {
        text: i18n.t('status'),
        value: 'status',
      },
    ];
    const applicationModalFooterData = [
      {
        titleFooter: '一次承認者',
        organizationFooter: '人事課',
        director: '課長',
        full_name: '山口 航平',
        approver_comment: '',
        update_date_and_time: '2021/00/0017:00',
        status: '承認',
      },
      {
        titleFooter: '二次承認者',
        organizationFooter: '人事課',
        director: '課長',
        full_name: '山口 航平',
        approver_comment: '',
        update_date_and_time: '',
        status: '未承認',
      },
      {
        titleFooter: '二次承認者',
        organizationFooter: '人事課',
        director: '課長',
        full_name: '山口 航平',
        approver_comment: '',
        update_date_and_time: '',
        status: '未承認',
      },
      {
        titleFooter: '二次承認者',
        organizationFooter: '人事課',
        director: '課長',
        full_name: '山口 航平',
        approver_comment: '',
        update_date_and_time: '',
        status: '未承認',
      },
      {
        titleFooter: '二次承認者',
        organizationFooter: '人事課',
        director: '課長',
        full_name: '山口 航平',
        approver_comment: '',
        update_date_and_time: '',
        status: '未承認',
      },
      {
        titleFooter: '二次承認者',
        organizationFooter: '人事課',
        director: '課長',
        full_name: '山口 航平',
        approver_comment: '',
        update_date_and_time: '',
        status: '未承認',
      },
    ];
    const isShow = ref(false);
    const handleClose = () => {
      isShow.value = false;
      emit('onClose');
    };

    const applicationSelected = ref([]);

    return {
      t: i18n.t,
      isShow,
      applicationModalData,
      applicationModalHeaders,
      itemApplicationApproval,
      applicationModalFooterHeaders,
      applicationModalFooterData,
      applicationSelected,
      handleClose,
    };
  },
});
