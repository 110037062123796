import { ref } from 'vue';
import { TimezoneApiGetListResult } from '@/api/types/TimezoneList';
import { getListTimezone } from '@/api/timezoneSettings';

export default function (params) {
  const timezoneData = ref<TimezoneApiGetListResult[]>();
  const loading = ref(false);
  const pagination = ref({
    current_page: 1,
    per_page: 20,
    total: 0,
  });
  const response = ref();

  async function getDataTimezoneList(params, noLoading?) {
    try {
      if (!noLoading) {
        loading.value = true;
      }
      const res = await getListTimezone(params);

      switch (res.status) {
        case 200:
          timezoneData.value = res.data.data;
          response.value = res.data;
          pagination.value = response.value.pagination;
          break;
        default:
          timezoneData.value = [];
          break;
      }
    } catch (error) {
      console.error(error);
    } finally {
      loading.value = false;
    }
  }

  getDataTimezoneList(params);

  return {
    pagination,
    timezoneData,
    loading,
    getDataTimezoneList,
  };
}
