import { ref } from 'vue';
import { convertErrorFromServerToJson } from '@/util/useValidateForm';
import {
  getWaitingAllowanceDetail,
  postWaitingAllowance,
} from '../waitingAllowanceApplication';
import { WaitingAllowanceApiPost } from '../types/WaitingAllowanceApplication';

export function postWaitingAllowanceApplication(data: WaitingAllowanceApiPost) {
  const errors = ref({});
  const errorsCommon = ref<string>();
  return {
    registered: (async () => {
      const response = await postWaitingAllowance(data);
      switch (response.status) {
        case 200:
          return null;
        default:
          if (response.data.errors[0].source) {
            errors.value = convertErrorFromServerToJson(response.data.errors);
          } else {
            errorsCommon.value = response.data.errors[0].title;
          }
          return {
            errors,
            errorsCommon,
          };
      }
    })(),
  };
}
export default function () {
  const waitingDetail = ref();

  async function getWaitingData(params) {
    try {
      const res = await getWaitingAllowanceDetail(params);
      switch (res.status) {
        case 200:
          waitingDetail.value = res.data.data;
          break;
        default:
          waitingDetail.value = {};
          break;
      }
    } catch (error) {
      console.error(error);
    }
  }

  return {
    waitingDetail,
    getWaitingData,
  };
}
