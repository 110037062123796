
import './../assets/index.css';
import { reactive, computed } from 'vue';

export default {
  name: 'Badges',
  props: {
    width: {
      type: String,
      defaut: '2',
    },
    height: {
      type: String,
      default: '2',
    },
    background: {
      type: String,
      default: 'bg-red-600',
    },
    label: {
      type: String,
      default: '',
    },
    fontSize: {
      type: String,
      default: '12px',
    },
    classAddition: {
      type: String,
    },
  },
  emits: ['click'],
  setup(props, { emit }) {
    props = reactive(props);

    return {
      classes: computed(() => ({
        'rounded-full': true,
        [`w-${props.width}`]: true,
        [`h-${props.width}`]: true,
        [`${props.background}`]: true,
        'items-center justify-center leading-none': props.label,
        [`${props.classAddition}`]: true,
      })),
      styles: computed(() => ({
        'font-size': props.fontSize,
      })),
      onClick() {
        emit('click');
      },
    };
  },
};
