import { ref } from 'vue';
import {
  postAbsenceApplication,
  getDetailAbsenceApplication,
} from '@/api/absenceApplication';
import { AbsenceApplicationApiPost } from '@/api/types/AbsenceApplication';
import { convertErrorFromServerToJson } from '@/util/useValidateForm';

export function usePostAbsenceApplication(params: AbsenceApplicationApiPost) {
  const errors = ref({});
  const errorsCommon = ref<string>();
  return {
    postData: (async () => {
      const response = await postAbsenceApplication(params);
      switch (response.status) {
        case 200:
          return null;
        default:
          if (response.data.errors[0].source) {
            errors.value = convertErrorFromServerToJson(response.data.errors);
          } else {
            errorsCommon.value = response.data.errors[0].title;
          }
          return {
            errors,
            errorsCommon,
          };
      }
    })(),
  };
}

export function useGetDetailAbsenceApplication() {
  const absenceApplication = ref();
  const loading = ref(false);
  async function handleGetDetailAbsenceApplication(applicationFormId) {
    try {
      loading.value = true;
      const res = await getDetailAbsenceApplication(applicationFormId);

      switch (res.status) {
        case 200:
          absenceApplication.value = res.data.data;
          break;
        default:
          absenceApplication.value = [];
          break;
      }
    } catch (error) {
      console.error(error);
    } finally {
      loading.value = false;
    }
  }

  return {
    loading,
    absenceApplication,
    handleGetDetailAbsenceApplication,
  };
}
