<template>
  <svg
    width="1"
    height="32"
    viewBox="0 0 1 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <line x1="0.5" y1="2.06068e-08" x2="0.499999" y2="32" stroke="#DCDCDC" />
  </svg>
</template>
