
import { defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import Button from '@/components/Button.vue';
import Table from '@/components/Table.vue';
import { usePopupDeleteStore } from '@/store/modules/popup-delete';
import router from '@/router';

import useGetListPersonalSettingApply from '@/api/composables/usePersonalSettingApply';

import ConstantsRouter from '@/constants/router';
import { ShiftPatternsParamSearch } from '@/model/ShiftPattern';
import Checkbox from '@/components/Checkbox.vue';
import moment from 'moment';
import { DATE_FORMAT } from '@/constants/date';
export default defineComponent({
  components: {
    Checkbox,
    Button,
    Table,
  },
  setup() {
    const i18n = useI18n();
    const t = i18n.t;
    const store = usePopupDeleteStore();
    const itemRemoveId = ref();

    const handleRedirectCreate = () => {
      router.push(ConstantsRouter.IndividualApplicationPolicyCreate);
    };

    const headerDatas = [
      {
        text: '対象社員',
        value: 'pp_employee_name',
      },
      {
        text: '適用開始年月',
        value: 'apply_start_date',
      },
      {
        text: '適用終了年月',
        value: 'apply_end_date',
      },
      {
        text: '会社カレンダー',
        value: 'national_holiday_name',
      },
      {
        text: '所定労働パターン',
        value: 'administrative_working_schedule_name',
      },
      {
        text: '締め日パターン',
        value: 'monthly_closing_day_name',
      },
      {
        text: '就労形態',
        value: 'working_style_name',
      },
      {
        text: '時間帯区分グループ',
        value: 'is_use_time_zone_session_type',
      },
      {
        text: '締め日パターン',
        value: 'time_zone_session_type_name',
      },
      {
        text: '権限',
        value: 'role_name',
      },
    ];
    const apiGetParamSearch = ref<ShiftPatternsParamSearch>({
      page: 1,
    });
    const currentPage = ref(1);
    const {
      personalSettingApplyList,
      getPersonalSettingApplyList,
      pagination,
    } = useGetListPersonalSettingApply({});
    const onChangePage = () => {
      getPersonalSettingApplyList({
        page: currentPage.value,
      });
    };
    const redirectUpdatePersonalApplyUpdate = (item) => {
      router.push({
        path: ConstantsRouter.IndividualApplicationPolicySettingUpdate,
        query: { id: item.personal_setting_apply_id },
      });
    };
    const displayDate = (date) => {
      return moment(date).format(DATE_FORMAT);
    };
    return {
      t,
      headerDatas,
      displayDate,
      itemRemoveId,
      store,
      apiGetParamSearch,
      pagination,
      handleRedirectCreate,
      onChangePage,
      personalSettingApplyList,
      currentPage,
      redirectUpdatePersonalApplyUpdate,
    };
  },
});
