
import { defineComponent, ref, toRefs, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import RadioGroup from '@/components/share/Radio/RadioGroup.vue';
import Select from '@/components/share/Dropdown/Dropdown.vue';
import Input from '@/components/share/Input/index.vue';
import { WorkingStyleMasterParams } from '@/model/WorkingStyleMaster';
import {
  ACHIEVEMENT_ROUNDING_TYPE,
  ACHIEVEMENT_ROUNDING_UNIT_MINUTES_LIST,
  FIX_CHECKIN_TO_START_SHIFT_TYPE,
  FIX_CHECKOUT_TO_END_SHIFT_TYPE,
  ROUNDING_OF_AGGREGATION_TIME_TYPE,
  WAY_ROUNDING_MINUTE_BREAK_END_TYPE,
  WAY_ROUNDING_MINUTE_BREAK_START_TYPE,
  WAY_ROUNDING_MINUTE_CHECKIN_TYPE,
  WAY_ROUNDING_MINUTE_CHECKOUT_TYPE,
  WAY_ROUNDING_SECONDS_BREAK_END_TYPE,
  WAY_ROUNDING_SECONDS_BREAK_START_TYPE,
  WAY_ROUNDING_SECONDS_CHECKIN_TYPE,
  WAY_ROUNDING_SECONDS_CHECKOUT_TYPE,
} from '@/constants/workingStyleMaster';
import RadioInputGroup from '@/components/share/Radio/RadioInputGroup.vue';

export default defineComponent({
  components: {
    RadioInputGroup,
    Input,
    RadioGroup,
    Select,
  },
  props: {
    modelValue: { default: {} },
    validated: {
      default: false,
    },
    errorServer: {
      default: '',
    },
  },
  setup(props) {
    const i18n = useI18n();
    const { modelValue } = toRefs(props);
    const t = i18n.t;
    const optionsRoundingMinutes = [1, 5, 10, 15, 30];
    const achievementRoundingUnitOption = ACHIEVEMENT_ROUNDING_UNIT_MINUTES_LIST;
    const roundingOfMonthlyAggregationOption = [
      {
        value: ROUNDING_OF_AGGREGATION_TIME_TYPE.ROUND,
        label: t('round'),
      },
      {
        value: ROUNDING_OF_AGGREGATION_TIME_TYPE.NOT_ROUND,
        label: t('notRound'),
      },
    ];
    const optionsRadioDefault = [
      {
        value: 0,
        label: t('beingLateTime.truncate'),
      },
      {
        value: 1,
        label: t('beingLateTime.roundUp'),
      },
    ];
    const optionsRadioDefaultCustom = [
      {
        value: 0,
        label: t('beingLateTime.truncate'),
      },
      {
        value: 1,
        label: t('beingLateTime.roundUp'),
      },
      {
        value: 2,
        label: t('beingLateTime.doNotRound'),
      },
    ];
    const wayRoundSecondCheckinList = [
      {
        value: WAY_ROUNDING_SECONDS_CHECKIN_TYPE.TRUNCATE,
        label: t('beingLateTime.truncate'),
      },
      {
        value: WAY_ROUNDING_SECONDS_CHECKIN_TYPE.ROUND_UP,
        label: t('beingLateTime.roundUp'),
      },
    ];
    const wayRoundSecondCheckoutList = [
      {
        value: WAY_ROUNDING_SECONDS_CHECKOUT_TYPE.TRUNCATE,
        label: t('beingLateTime.truncate'),
      },
      {
        value: WAY_ROUNDING_SECONDS_CHECKOUT_TYPE.ROUND_UP,
        label: t('beingLateTime.roundUp'),
      },
    ];
    const wayRoundMinuteCheckinList = [
      {
        value: WAY_ROUNDING_MINUTE_CHECKIN_TYPE.TRUNCATE,
        label: t('beingLateTime.truncate'),
      },
      {
        value: WAY_ROUNDING_MINUTE_CHECKIN_TYPE.ROUND_UP,
        label: t('beingLateTime.roundUp'),
      },
      {
        value: WAY_ROUNDING_MINUTE_CHECKIN_TYPE.DO_NOT_ROUND,
        label: t('beingLateTime.doNotRound'),
      },
    ];
    const wayRoundMinuteCheckoutList = [
      {
        value: WAY_ROUNDING_MINUTE_CHECKOUT_TYPE.TRUNCATE,
        label: t('beingLateTime.truncate'),
      },
      {
        value: WAY_ROUNDING_MINUTE_CHECKOUT_TYPE.ROUND_UP,
        label: t('beingLateTime.roundUp'),
      },
      {
        value: WAY_ROUNDING_MINUTE_CHECKIN_TYPE.DO_NOT_ROUND,
        label: t('beingLateTime.doNotRound'),
      },
    ];
    const wayRoundSecondBreakStartList = [
      {
        value: WAY_ROUNDING_SECONDS_BREAK_START_TYPE.TRUNCATE,
        label: t('beingLateTime.truncate'),
      },
      {
        value: WAY_ROUNDING_SECONDS_BREAK_START_TYPE.ROUND_UP,
        label: t('beingLateTime.roundUp'),
      },
    ];
    const wayRoundSecondBreakEndList = [
      {
        value: WAY_ROUNDING_SECONDS_BREAK_END_TYPE.TRUNCATE,
        label: t('beingLateTime.truncate'),
      },
      {
        value: WAY_ROUNDING_SECONDS_BREAK_END_TYPE.ROUND_UP,
        label: t('beingLateTime.roundUp'),
      },
    ];
    const wayRoundMinuteBreakStartList = [
      {
        value: WAY_ROUNDING_MINUTE_BREAK_START_TYPE.TRUNCATE,
        label: t('beingLateTime.truncate'),
      },
      {
        value: WAY_ROUNDING_MINUTE_BREAK_START_TYPE.ROUND_UP,
        label: t('beingLateTime.roundUp'),
      },
      {
        value: WAY_ROUNDING_MINUTE_BREAK_START_TYPE.DO_NOT_ROUND,
        label: t('beingLateTime.doNotRound'),
      },
    ];
    const wayRoundMinuteBreakEndList = [
      {
        value: WAY_ROUNDING_MINUTE_BREAK_END_TYPE.TRUNCATE,
        label: t('beingLateTime.truncate'),
      },
      {
        value: WAY_ROUNDING_MINUTE_BREAK_END_TYPE.ROUND_UP,
        label: t('beingLateTime.roundUp'),
      },
      {
        value: WAY_ROUNDING_MINUTE_BREAK_END_TYPE.DO_NOT_ROUND,
        label: t('beingLateTime.doNotRound'),
      },
    ];
    const optionsRadioFixCommutingOption = [
      {
        value: FIX_CHECKIN_TO_START_SHIFT_TYPE.FIXED,
        label: t('beingLateTime.fixedAction'),
      },
      {
        value: FIX_CHECKIN_TO_START_SHIFT_TYPE.NOT_FIXED,
        label: t('beingLateTime.notFixedAction'),
      },
    ];
    const optionsRadioFixLeaveTime = [
      {
        value: FIX_CHECKOUT_TO_END_SHIFT_TYPE.FIXED,
        label: t('beingLateTime.fixedAction'),
      },
      {
        value: FIX_CHECKOUT_TO_END_SHIFT_TYPE.NOT_FIXED,
        label: t('beingLateTime.notFixedAction'),
      },
      {
        value: FIX_CHECKOUT_TO_END_SHIFT_TYPE.ROUND_UP_TIME,
        label: t('beingLateTime.roundUpTime'),
      },
    ];
    const achievementRoundingList = [
      {
        value: ACHIEVEMENT_ROUNDING_TYPE.TRUNCATE,
        label: t('truncate'),
      },
      {
        value: ACHIEVEMENT_ROUNDING_TYPE.ROUND_UP,
        label: t('roundUp'),
      },
      {
        value: ACHIEVEMENT_ROUNDING_TYPE.ROUNDING,
        label: t('rounding'),
      },
    ];
    const workingStyleMasterParams = ref<WorkingStyleMasterParams>({});
    workingStyleMasterParams.value = modelValue.value;
    watch(props, () => {
      workingStyleMasterParams.value = modelValue.value;
    });

    watch(
      () => workingStyleMasterParams.value.achievement_rounding,
      () => {
        if (workingStyleMasterParams.value.achievement_rounding === null) {
          workingStyleMasterParams.value.achievement_rounding =
            ACHIEVEMENT_ROUNDING_TYPE.TRUNCATE;
        }
        if (
          workingStyleMasterParams.value.achievement_rounding ===
          ACHIEVEMENT_ROUNDING_TYPE.ROUNDING
        ) {
          workingStyleMasterParams.value.achievement_rounding_unit_minutes = 60;
        } else {
          workingStyleMasterParams.value.achievement_rounding_unit_minutes = 5;
        }
      }
    );
    return {
      t,
      optionsRoundingMinutes,
      roundingOfMonthlyAggregationOption,
      achievementRoundingUnitOption,
      workingStyleMasterParams,
      optionsRadioDefault,
      optionsRadioFixCommutingOption,
      optionsRadioFixLeaveTime,
      wayRoundSecondCheckinList,
      wayRoundSecondCheckoutList,
      wayRoundSecondBreakStartList,
      wayRoundSecondBreakEndList,
      wayRoundMinuteBreakStartList,
      wayRoundMinuteBreakEndList,
      wayRoundMinuteCheckinList,
      wayRoundMinuteCheckoutList,
      achievementRoundingList,
      ACHIEVEMENT_ROUNDING_TYPE,
      ROUNDING_OF_AGGREGATION_TIME_TYPE,
      FIX_CHECKOUT_TO_END_SHIFT_TYPE,
      optionsRadioDefaultCustom,
    };
  },
});
