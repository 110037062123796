
import { defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import Table from '@/components/Table.vue';
import iconPlus from '@/assets/svg/Plus.svg';
import Button from '@/components/Button.vue';
import Select from '@/components/Select.vue';
import Checkbox from '@/components/Checkbox.vue';
import { computed } from '@vue/runtime-core';
import Avatar from '@/components/Avatar.vue';
import AvatarSample from '@/assets/svg/AvatarSample.svg';
import Popup from '@/components/Popup.vue';

export default defineComponent({
  components: { Popup, Avatar, Checkbox, Select, Button, Table },
  setup(props, { emit }) {
    const i18n = useI18n();
    const isShow = ref(false);
    const membersToSubmittedHeaders = [
      {
        text: i18n.t('choice'),
        value: 'checkbox',
        width: '30px',
      },
      {
        text: i18n.t('name'),
        value: 'name',
      },
      {
        text: i18n.t('organization'),
        value: 'organization',
      },
      {
        text: i18n.t('workingStyle'),
        value: 'working_style',
      },
    ];
    const personToSubmittedData = [
      {
        name: '斉藤 亜沙美',
        organization: '営業本部',
        working_style: '正社員',
        avatar: AvatarSample,
      },
      {
        name: '安里 久美',
        organization: '営業本部',
        working_style: '正社員',
        avatar: AvatarSample,
      },
      {
        name: '斉藤 亜沙美',
        organization: '営業本部',
        working_style: '正社員',
        avatar: AvatarSample,
      },
      {
        name: '斉藤 亜沙美',
        organization: '営業本部',
        working_style: '正社員',
        avatar: AvatarSample,
      },
      {
        name: '斉藤 亜沙美',
        organization: '営業本部',
        working_style: '正社員',
        avatar: AvatarSample,
      },
      {
        name: '斉藤 亜沙美',
        organization: '営業本部',
        working_style: '正社員',
        avatar: AvatarSample,
      },
      {
        name: '斉藤 亜沙美',
        organization: '営業本部',
        working_style: '正社員',
        avatar: AvatarSample,
      },
      {
        name: '斉藤 亜沙美',
        organization: '営業本部',
        working_style: 'アルバイト',
        avatar: AvatarSample,
      },
      {
        name: '斉藤 亜沙美',
        organization: '営業本部',
        working_style: 'アルバイト',
        avatar: AvatarSample,
      },
      {
        name: '斉藤 亜沙美',
        organization: '営業本部',
        working_style: 'アルバイト',
        avatar: AvatarSample,
      },
      {
        name: '斉藤 亜沙美',
        organization: '営業本部',
        working_style: 'アルバイト',
        avatar: AvatarSample,
      },
      {
        name: '斉藤 亜沙美',
        organization: '営業本部',
        working_style: 'アルバイト',
        avatar: AvatarSample,
      },
      {
        name: '斉藤 亜沙美',
        organization: '営業本部',
        working_style: 'アルバイト',
        avatar: AvatarSample,
      },
      {
        name: '斉藤 亜沙美',
        organization: '営業本部',
        working_style: 'アルバイト',
        avatar: AvatarSample,
      },
      {
        name: '斉藤 亜沙美',
        organization: '営業本部',
        working_style: 'アルバイト',
        avatar: AvatarSample,
      },
      {
        name: '斉藤 亜沙美',
        organization: '営業本部',
        working_style: 'アルバイト',
        avatar: AvatarSample,
      },
      {
        name: '斉藤 亜沙美',
        organization: '営業本部',
        working_style: 'アルバイト',
        avatar: AvatarSample,
      },
      {
        name: '斉藤 亜沙美',
        organization: '営業本部',
        working_style: 'アルバイト',
        avatar: AvatarSample,
      },
      {
        name: '斉藤 亜沙美',
        organization: '営業本部',
        working_style: 'アルバイト',
        avatar: AvatarSample,
      },
      {
        name: '斉藤 亜沙美',
        organization: '営業本部',
        working_style: 'アルバイト',
        avatar: AvatarSample,
      },
      {
        name: '斉藤 亜沙美',
        organization: '営業本部',
        working_style: 'アルバイト',
        avatar: AvatarSample,
      },
      {
        name: '斉藤 亜沙美',
        organization: '営業本部',
        working_style: 'アルバイト',
        avatar: AvatarSample,
      },
    ];
    const departmentList = [
      { id: 1, name: '営業本部' },
      { id: 2, name: '001' },
      { id: 3, name: '002' },
    ];
    const workingStyleList = [
      { id: 1, name: '001' },
      { id: 2, name: '002' },
      { id: 3, name: '003' },
    ];
    let personSelected = ref(personToSubmittedData);
    const isSelectAll = computed(() => {
      return (
        personSelected.value.length ===
        personSelected.value.filter((item) => item['checked'] === true).length
      );
    });
    const numberOfPeopleSelected = computed(() => personSelected.value.length);
    const onSelectAllPerson = (e) => {
      if (e.target.checked) {
        personSelected.value.map((item) => (item['checked'] = true));
      } else {
        personSelected.value.map((item) => (item['checked'] = false));
      }
    };
    const onClosePopup = () => {
      isShow.value = false;
      emit('onClose');
    };
    return {
      t: i18n.t,
      membersToSubmittedHeaders,
      numberOfPeopleSelected,
      iconPlus,
      departmentList,
      workingStyleList,
      personSelected,
      personToSubmittedData,
      isShow,
      isSelectAll,
      onClosePopup,
      onSelectAllPerson,
    };
  },
});
