
import { computed, defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import Table from '@/components/Table.vue';
import moment from 'moment';
import { useGetViewConfirmShift } from '@/api/composables/useViewConfirmShift';
import { ViewConfirmShiftParamSearch } from '@/api/types/ViewConfirmShift';
import { useLoginAccountStore } from '@/store/modules/login-account';
import { useGetShiftPatternList } from '@/api/composables/useShiftPattern';
import { ShiftPatternsApiParamSearch } from '@/api/types/ShiftPatern';
import DatePicker from '@/components/DatePicker.vue';
import { EMPLOYEE_EXPERT_TYPE } from '@/constants/shiftPlan';
import { watchEffect } from '@vue/runtime-core';
import { WEEK_DAY } from '@/constants/workingStyleMaster';

export default defineComponent({
  components: {
    Table,
    DatePicker,
  },
  setup() {
    const i18n = useI18n();
    const shiftPatternOptions = [
      {
        text: i18n.t('unSelect'),
        value: null,
        color: '#FF0000',
      },
      {
        text: i18n.t('selectFromWorkPatterns'),
        value: EMPLOYEE_EXPERT_TYPE.SELECT_FROM_WORK_PATTERN,
        color: '#22AEE5',
      },
      {
        text: i18n.t('addManual'),
        value: EMPLOYEE_EXPERT_TYPE.ADD_MANUALLY,
        color: '#f8bf2d',
      },
      {
        text: i18n.t('impossibility'),
        value: EMPLOYEE_EXPERT_TYPE.IMPOSSIBILITY,
        color: '#979797',
      },
    ];
    const shiftRegisterDataHeader = ref([
      {
        text: i18n.t('dailyAttendanceHeaders.date'),
        value: 'date',
        width: '100px',
      },
      {
        text: i18n.t('desiredWorkPattern'),
        value: 'employee_expect_type',
        width: '200px',
      },
      {
        text: i18n.t('desiredWorkingHours'),
        value: 'employee_expect_work_time',
        width: '160px',
      },
      {
        text: i18n.t('fixedWorkPattern'),
        value: 'manager_confirm_type',
        width: '200px',
      },
      {
        text: i18n.t('fixedWorkHours'),
        value: 'manager_confirm_work_time',
        width: '160px',
      },
      {
        text: i18n.t('workingOrganization'),
        value: 'service_organization',
      },
    ]);
    Array.from(Array(24)).forEach((item, index) => {
      shiftRegisterDataHeader.value.push({
        text: `${index}:00`,
        value: index === 0 ? 'time_min' : '',
        width: '100px',
      });
    });

    const styleTimeLine = (startTime, endTime) => {
      if (!startTime || !endTime) return;
      const minTime = moment('0:0', 'HH:mm:ss');
      startTime = moment(startTime, 'HH:mm:ss');
      endTime = moment(endTime, 'HH:mm:ss');
      const minutes = endTime.diff(startTime, 'minutes');
      const startMinutes = startTime.diff(minTime, 'minutes');
      return {
        width: (minutes / 60) * 100 + 'px',
        left: (startMinutes / 60) * 100 + 'px',
      };
    };
    const displayDate = (date) => {
      if (date) {
        return moment(date).format('MM / DD (dd)');
      }
      return '';
    };
    const getColorDate = (date) => {
      if (!date) {
        return '';
      }
      const day = moment(date).day();
      if (day === WEEK_DAY.SATURDAY) {
        return '#22AEE5';
      }
      if (day === WEEK_DAY.SUNDAY) {
        return '#ED5D5D';
      }
      return '#333333';
    };
    const displayTargetTime = (targetStartTime, targetEndTime) => {
      if (targetStartTime && targetEndTime) {
        return targetStartTime + ' ～ ' + targetEndTime;
      }
    };
    const displayExpectType = (expect_type, pattern_id, pattern_name) => {
      if (pattern_id) return pattern_name;
      return shiftPatternOptions.find((item) => item.value === expect_type)
        ?.text;
    };
    const isShowManagerExpertType = (type) => {
      return shiftPatternOptions.find((item) => item.value === type);
    };
    const getBackgroundExpertType = (type) => {
      return shiftPatternOptions.find((item) => item.value === type)?.color;
    };
    const displayManagerConfirmWorkTime = (item) => {
      if (
        item &&
        item.manager_confirm_work_start_time &&
        item.manager_confirm_work_end_time
      )
        return `確定 (${item.manager_confirm_work_start_time} ～ ${item.manager_confirm_work_end_time})`;
    };
    const accountStore = useLoginAccountStore();
    const myAccount = computed(() => accountStore.state.myAccount);
    const viewConfirmSearchParams = ref<ViewConfirmShiftParamSearch>({
      pp_employee_id: '',
      target_month: moment().format('YYYY-MM'),
    });
    const shiftPatternSearchParams = ref<ShiftPatternsApiParamSearch>({
      is_all: true,
      unit_id: '',
    });
    const {
      viewConfirmShiftList,
      getViewConfirmShiftData,
    } = useGetViewConfirmShift();
    const { shiftPatternData, getShiftPatternList } = useGetShiftPatternList();
    watchEffect(() => {
      if (myAccount.value.pp_employee_id) {
        viewConfirmSearchParams.value.pp_employee_id =
          myAccount.value.pp_employee_id;
        shiftPatternSearchParams.value.unit_id = myAccount.value.unit_id;
        getShiftPatternList(shiftPatternSearchParams.value);
        getViewConfirmShiftData(viewConfirmSearchParams.value);
      }
    });
    const selectTargetMonth = () => {
      getViewConfirmShiftData(viewConfirmSearchParams.value);
    };

    return {
      t: i18n.t,
      shiftRegisterDataHeader,
      shiftPatternOptions,
      viewConfirmShiftList,
      myAccount,
      shiftPatternData,
      getShiftPatternList,
      viewConfirmSearchParams,
      styleTimeLine,
      displayTargetTime,
      displayDate,
      getColorDate,
      displayExpectType,
      getBackgroundExpertType,
      displayManagerConfirmWorkTime,
      selectTargetMonth,
      isShowManagerExpertType,
    };
  },
});
