import { ref } from 'vue';
import { getAllApplicationMyApproval } from '../applicationMyApproval';
import { ApplicationMyApprovalParams } from '@/model/ApplicationMyApproval';

export default function () {
  const membersApplication = ref();
  const pagination = ref({
    current_page: 1,
    per_page: 20,
    total: 0,
  });

  async function getAllMyApproval(params: ApplicationMyApprovalParams) {
    try {
      const res = await getAllApplicationMyApproval(params);
      switch (res.status) {
        case 200:
          if (res.data.meta) {
            pagination.value = res.data.meta.pagination;
          }
          membersApplication.value = res.data.data;
          break;
        default:
          membersApplication.value = [];
          break;
      }
    } catch (error) {
      console.error(error);
    }
  }

  return {
    membersApplication,
    getAllMyApproval,
    pagination,
  };
}
