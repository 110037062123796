
import { computed, defineComponent, PropType } from 'vue';
import { useToastMessageStore } from '@/store/modules/toast-message';

export default defineComponent({
  emits: ['update:show'],
  props: {
    type: {
      type: String as PropType<
        'default' | 'warning' | 'success' | 'info' | 'error'
      >,
      default: 'default',
    },
    hideIn: {
      type: Number,
      default: 8,
    },
    message: {
      type: String,
      default: '',
    },
  },
  setup() {
    const messageStore = useToastMessageStore();
    const messages = computed(() => messageStore.state.toastMessages);
    const hide = (item) => {
      messageStore.commit('closeToast', item);
    };
    return {
      messages,
      hide,
    };
  },
});
