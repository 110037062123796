
import {
  computed,
  ref,
  onMounted,
  onUpdated,
  onUnmounted,
  watch,
  PropType,
} from 'vue';
import { useI18n } from 'vue-i18n';
import iconChevronLeft from '@/assets/svg/iconChevronLeft.svg';
import iconChevronRight from '@/assets/svg/iconChevronRight.svg';
import Checkbox from '@/components/share/Checkbox/CheckboxInput.vue';
import { useToggleSidebarStore } from '@/store/modules/sidebar';
import Pagination from '@/components/share/Pagination/index.vue';
import { watchEffect } from '@vue/runtime-core';

interface HeaderType {
  text: string;
  value: string;
  width: number;
}
interface StringMap {
  [key: string]: number | string;
}

export default {
  components: { Checkbox, Pagination },
  props: {
    data: {
      type: Array as PropType<StringMap[]>,
      required: true,
      default: () => [] as StringMap[],
    },
    headers: {
      type: Array,
      required: true,
      default: () => [],
    },
    fixedLeft: {
      type: Number,
      default: 0,
    },
    itemText: {
      type: String,
      default: 'id',
    },
    modelValue: {
      type: Array,
      default: () => [],
    },
    isFixed: {
      type: Boolean,
      default: false,
    },
    paddingToShowElement: {
      type: Number,
      default: 0,
    },
    truncateHeaderClass: {
      type: String,
      default: 'truncate',
    },
    // infinity scroll
    infinityScroll: {
      type: Boolean,
      default: false,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    recordTotal: {
      type: Number,
      default: 0,
    },
    itemsPerPages: {
      type: Number,
      default: 20,
    },
    initMaxHeight: { type: Number, default: 0 },
    classes: {
      type: String,
      default: '',
    },
    uniqueTable: {
      type: String,
      default: 'scrollTable',
    },
    listDisabled: {
      type: Array,
      default: () => [],
    },
    isPagination: {
      type: Boolean,
      default: true,
    },
    showItemPerPage: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, ctx) {
    const i18n = useI18n();
    const headersFlexible = ref<HTMLDivElement>();
    const bodyFlexible = ref<HTMLDivElement>();
    const bodyRowFixed = ref<HTMLDivElement>();
    const headersFixed = ref<HTMLDivElement>();
    const scrollThumb = ref<HTMLDivElement>();
    const scrollHorizontal = ref<HTMLDivElement>();
    const effectHorizontal = ref<HTMLDivElement>();
    const effectVertical = ref<HTMLDivElement>();
    const bodyTable = ref<HTMLDivElement>();
    const bodyRowFlexible = ref<HTMLDivElement>();
    const positionScroll = ref<number>(0);
    const lastElementInit = ref<HTMLDivElement>();

    //logic for checkbox
    const checkListItem = ref(props.modelValue);
    const checkAll = computed(() => {
      const allItem: (string | number)[] = props.data.map((item) => {
        return item[props.itemText];
      });
      const newListChecked = allItem.filter((item) => {
        return !props.listDisabled.includes(item);
      });
      return (
        checkListItem.value.length === newListChecked.length &&
        checkListItem.value.length !== 0
      );
    });
    const handleClickAll = async () => {
      if (checkAll.value) {
        await ctx.emit('update:modelValue', []);
      } else {
        const allItem: (string | number)[] = props.data.map((item) => {
          return item[props.itemText];
        });
        const newListChecked = allItem.filter((item) => {
          return !props.listDisabled.includes(item);
        });
        await ctx.emit('update:modelValue', newListChecked);
      }
      checkListItem.value = await props.modelValue;
    };

    // logic for scroll
    const totalWidthFlexible = computed(() => {
      let width = 0;
      for (let i = props.fixedLeft; i < props.headers.length; i++) {
        const header: HeaderType = props.headers[i] as HeaderType;
        width += header.width;
      }
      return width;
    });
    const totalWidthFixed = computed(() => {
      let width = 0;
      for (let i = 0; i < props.fixedLeft; i++) {
        const header: HeaderType = props.headers[i] as HeaderType;
        width += header.width;
      }
      return width;
    });
    const noData = computed(() => {
      return totalWidthFlexible.value + totalWidthFixed.value;
    });
    const setWidthScrollThumb = () => {
      if (scrollThumb?.value?.offsetWidth) {
        scrollThumb.value.style.width = totalWidthFlexible.value + 'px';
      }
    };
    const setWidthBodyRowFlexible = () => {
      if (bodyRowFlexible?.value) {
        bodyRowFlexible.value.style.width = totalWidthFlexible.value + 'px';
      }
    };
    const setWidthHeadersAndBodyFlexible = () => {
      if (
        bodyFlexible.value &&
        headersFixed.value?.offsetWidth &&
        headersFlexible.value
      ) {
        headersFlexible.value.style.width = `calc(100% - ${
          headersFixed.value?.offsetWidth + 'px'
        })`;
        bodyFlexible.value.style.width = `calc(100% - ${
          headersFixed.value?.offsetWidth + 'px'
        })`;
      }
    };
    const setWidthAndStyleScrollHorizontal = () => {
      if (scrollHorizontal.value) {
        scrollHorizontal.value.style.width =
          bodyFlexible.value?.offsetWidth + 'px';
        scrollHorizontal.value.style.left = totalWidthFixed.value + 'px';
      }
    };
    const setEffectHorizontalStyle = () => {
      if (effectHorizontal.value) {
        effectHorizontal.value.style.left = totalWidthFixed.value + 'px';
        if (!positionScroll.value) {
          effectHorizontal.value.style.display = 'none';
        }
      }
    };
    const setEffectVerticalWidth = () => {
      if (effectVertical?.value) {
        effectVertical.value.style.width =
          totalWidthFixed.value + totalWidthFlexible.value + 'px';
        effectVertical.value.style.display = 'none';
      }
    };
    const onScrollHorizontal = () => {
      positionScroll.value = scrollHorizontal.value?.scrollLeft || 0;
      const bodyDOM = document.getElementById(
        props.uniqueTable + 'sys-table__body--flexible-row'
      );
      if (bodyDOM) bodyDOM.scrollLeft = positionScroll.value;
    };
    const onScrollRow = () => {
      positionScroll.value = bodyRowFlexible.value?.scrollLeft || 0;
      const scrollDOM = document.getElementById(
        props.uniqueTable + 'scroll-bar-horizontal'
      );
      const headerFlexibleDOM = document.getElementById(
        props.uniqueTable + 'sys-table__headers--flexible'
      );
      if (headerFlexibleDOM)
        headerFlexibleDOM.scrollLeft = positionScroll.value;
      if (scrollDOM) scrollDOM.scrollLeft = positionScroll.value;

      if (effectHorizontal.value) {
        if (positionScroll.value)
          effectHorizontal.value.style.display = 'block';
        else effectHorizontal.value.style.display = 'none';
      }
    };
    // infinity scroll
    const maxHeightTable = ref(0);
    const positionScrollToCallAPI = ref(0);
    const onScrollVertical = () => {
      const positionTopScroll: number = bodyTable.value?.scrollTop || 0;
      if (effectVertical.value) {
        if (positionTopScroll) effectVertical.value.style.display = 'block';
        else effectVertical.value.style.display = 'none';
      }
      //infinity scroll
      // when scroll to position mark scroll
      if (
        props.infinityScroll &&
        positionTopScroll > positionScrollToCallAPI.value
      ) {
        if (props.recordTotal / props.itemsPerPages > props.currentPage) {
          ctx.emit('update:currentPage', props.currentPage + 1);
          positionScrollToCallAPI.value =
            maxHeightTable.value * props.currentPage;
        }
      }
    };
    const setPositionScrollHorizontal = () => {
      if (
        bodyRowFixed.value &&
        props.isFixed &&
        scrollHorizontal.value &&
        effectHorizontal.value
      ) {
        scrollHorizontal.value.style.bottom =
          props.paddingToShowElement - 12 + 'px';
        effectHorizontal.value.style.height =
          (props.infinityScroll && maxHeightTable.value
            ? maxHeightTable.value
            : bodyRowFixed.value.clientHeight) +
          48 +
          'px';
      }
    };
    const initMaxHeightTable = () => {
      if (props.initMaxHeight && bodyTable.value && effectHorizontal.value) {
        bodyTable.value.style.maxHeight =
          props.initMaxHeight + props.paddingToShowElement + 'px';
        bodyTable.value.style.overflow = 'overlay';
        effectHorizontal.value.style.height = props.initMaxHeight + 48 + 'px';
      }
    };
    const setMaxHeightTable = () => {
      if (
        props.infinityScroll &&
        bodyTable.value?.getBoundingClientRect().top &&
        lastElementInit.value?.getBoundingClientRect().bottom
      ) {
        maxHeightTable.value =
          lastElementInit.value?.getBoundingClientRect().bottom -
          bodyTable.value?.getBoundingClientRect().top;
        bodyTable.value.style.maxHeight = maxHeightTable.value + 'px';
        bodyTable.value.style.overflow = 'overlay';
      }
    };
    // logic for paging
    const pageActive = ref(props.currentPage);
    const onChangePage = () => {
      ctx.emit('update:currentPage', pageActive.value);
      ctx.emit('onChangePage', pageActive.value);
    };
    watch(props, () => {
      pageActive.value = props.currentPage;
      itemPerPagesValue.value = props.itemsPerPages;
    });
    const itemPerPagesValue = ref(props.itemsPerPages);
    watchEffect(() => {
      if (itemPerPagesValue.value === props.itemsPerPages) return;
      ctx.emit('update:itemsPerPages', itemPerPagesValue.value);
      ctx.emit('changeItemPerPage', itemPerPagesValue.value);
    });

    const initTable = () => {
      setWidthScrollThumb();
      setWidthBodyRowFlexible();
      setWidthHeadersAndBodyFlexible();
      setWidthAndStyleScrollHorizontal();
      setEffectHorizontalStyle();
      setEffectVerticalWidth();
      setPositionScrollHorizontal();
      setMaxHeightTable();
      initMaxHeightTable();
    };

    onUpdated(() => {
      initTable();
    });

    onMounted(() => {
      initTable();
    });
    // init again table when window resize
    window.addEventListener('resize', initTable);
    onUnmounted(() => {
      window.removeEventListener('resize', initTable);
    });

    // init again table when toggle sidebar
    const sidebarStore = useToggleSidebarStore();
    const toggleSidebar = computed(() => sidebarStore.state.toggleSidebar);
    watch(toggleSidebar, () => {
      setTimeout(() => {
        initTable();
      }, 1);
    });
    return {
      t: i18n.t,
      iconChevronLeft,
      iconChevronRight,
      headersFlexible,
      scrollHorizontal,
      scrollThumb,
      bodyRowFixed,
      bodyFlexible,
      headersFixed,
      effectHorizontal,
      effectVertical,
      bodyTable,
      bodyRowFlexible,
      checkAll,
      checkListItem,
      onScrollHorizontal,
      onScrollVertical,
      onScrollRow,
      handleClickAll,
      lastElementInit,
      positionScrollToCallAPI,
      noData,
      pageActive,
      itemPerPagesValue,
      onChangePage,
    };
  },
};
