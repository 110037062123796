
import { useI18n } from 'vue-i18n';
import { computed, defineComponent, ref } from 'vue';
import InputArea from '@/components/share/Input/InputArea.vue';
import Button from '@/components/share/Button/index.vue';
import { useRoute } from 'vue-router';
import {
  APPLICATION_MY_APPROVAL,
  APPLICATION_MY_REQUEST,
  ON_GOING,
  DRAFF,
} from '@/constants/typeApplication';
import {
  usePutApprovalApplicationReason,
  usePutRejectApplicationReason,
  useDeleteApplication,
  useResendApplication,
} from '@/api/composables/useApplicationApproval';
import ConstantsRouter from '@/constants/router';
import router from '@/router';
import { showToast } from '@/util/useToastMessage';
import { useLoginAccountStore } from '@/store/modules/login-account';

export default defineComponent({
  components: { InputArea, Button },
  props: {
    status: {
      type: Number,
      default: ON_GOING,
    },
  },
  setup() {
    const i18n = useI18n();
    const t = i18n.t;
    const route = useRoute();
    const isMyApplication = computed(() => route.query.is_my_application);
    const enableApproval = computed(() => route.query.enable_approval);
    const approvalMessage = ref('');
    const accountStore = useLoginAccountStore();
    const myAccount = computed(() => accountStore.state.myAccount);
    const ppEmployeeId = myAccount.value.pp_employee_id;
    // params for api
    const historyId = route.query.application_approval_history_id;
    const paramsGetDetail = route.query.application_form_id;

    const redirectWhenCallAPI = () => {
      if (isMyApplication.value == '1')
        router.push(`/new${ConstantsRouter.ApplicationApprovalRequest}`);
      else router.push(`/new${ConstantsRouter.ApplicationMyApproval}`);
    };
    const approveForm = async () => {
      if (paramsGetDetail) {
        const { updated } = usePutApprovalApplicationReason({
          pp_employee_id: ppEmployeeId,
          application_approval_histories: [
            {
              application_approval_history_id: historyId as string,
              reason: approvalMessage.value,
            },
          ],
        });
        updated
          .then((error) => {
            if (error) {
              if (error.errorsCommon.value) {
                showToast('error', error.errorsCommon.value);
              }
            } else {
              showToast('success', t('approveSuccess'));
              redirectWhenCallAPI();
            }
          })
          .catch(() => {
            showToast('error', i18n.t('errors.serverError'));
          });
      }
    };

    const rejectForm = () => {
      if (paramsGetDetail) {
        const { updated } = usePutRejectApplicationReason({
          pp_employee_id: ppEmployeeId,
          application_approval_histories: [
            {
              application_approval_history_id: historyId as string,
              reason: approvalMessage.value,
            },
          ],
        });
        updated
          .then((error) => {
            if (error) {
              if (error.errorsCommon.value) {
                showToast('error', error.errorsCommon.value);
              }
            } else {
              showToast('success', t('rejectSuccess'));
              redirectWhenCallAPI();
            }
          })
          .catch(() => {
            showToast('error', i18n.t('errors.serverError'));
          });
      }
    };
    const deleteForm = () => {
      if (paramsGetDetail) {
        const { deleted } = useDeleteApplication(paramsGetDetail as string);
        deleted
          .then((error) => {
            if (error) {
              if (error.errorsCommon.value) {
                showToast('error', error.errorsCommon.value);
              }
            } else {
              showToast('success', t('deleteApplicationSuccess'));
              redirectWhenCallAPI();
            }
          })
          .catch(() => {
            showToast('error', i18n.t('errors.serverError'));
          });
      }
    };

    const resendApplication = () => {
      if (paramsGetDetail) {
        const { resend } = useResendApplication(paramsGetDetail as string);
        resend
          .then((error) => {
            if (error) {
              if (error.errorsCommon.value) {
                showToast('error', error.errorsCommon.value);
              }
            } else {
              showToast('success', t('resendApplicationSuccess'));
              redirectWhenCallAPI();
            }
          })
          .catch(() => {
            showToast('error', i18n.t('resendApplicationFail'));
          });
      }
    };

    return {
      t,
      i18n,
      enableApproval,
      approvalMessage,
      isMyApplication,
      APPLICATION_MY_APPROVAL,
      APPLICATION_MY_REQUEST,
      ON_GOING,
      DRAFF,
      rejectForm,
      approveForm,
      deleteForm,
      resendApplication,
    };
  },
});
