
import { defineComponent } from 'vue';
import Input from '@/components/Input.vue';
import GradationButton from '@/components/GradationButton.vue';
import ConstantsRouter from '@/constants/router';
import { useI18n } from 'vue-i18n';
import router from '@/router';
export default defineComponent({
  components: {
    Input,
    GradationButton,
  },
  setup() {
    const backLogin = () => {
      router.push(ConstantsRouter.AuthLogin);
    };
    const submit = () => {
      console.log('submit');
    };
    return {
      ConstantsRouter,
      t: useI18n().t,
      backLogin,
      submit,
    };
  },
});
