
import { computed, defineComponent, ref, toRefs, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import Button from '@/components/share/Button/index.vue';
import DatePicker from '@/components/share/Calendar/DatePickerInput.vue';
import { WorkingStyleMasterParams } from '@/model/WorkingStyleMaster';
import { MONTH_FORMAT } from '@/constants/date';

export default defineComponent({
  props: {
    workingStyleMasterParams: {
      default: {},
    },
    errorServer: {
      default: '',
    },
    validated: {
      default: false,
    },
  },
  emits: ['onClose', 'onSubmit', 'update:errors'],
  components: { Button, DatePicker },
  setup(props, { emit }) {
    const i18n = useI18n();
    const { workingStyleMasterParams } = toRefs(props);
    const onClosePopup = () => {
      emit('onClose');
    };
    const onSubmit = () => {
      emit('onSubmit');
    };
    const apiPostParams = ref<WorkingStyleMasterParams>({});
    const errorData = ref({});
    apiPostParams.value = workingStyleMasterParams.value;
    watch(props, () => {
      apiPostParams.value = workingStyleMasterParams.value;
    });
    const isDisableSubmit = computed(() =>
      Object.values(errorData.value).some((e) => !!e)
    );

    return {
      t: i18n.t,
      apiPostParams,
      errorData,
      isDisableSubmit,
      MONTH_FORMAT,
      onClosePopup,
      onSubmit,
    };
  },
});
