
import arrowDown from '@/assets/svg/arrowDown.svg';
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  ref,
  toRefs,
  watch,
  watchEffect,
} from '@vue/runtime-core';
import { useI18n } from 'vue-i18n';
import { PropType } from 'vue';
import Input from '@/components/share/Input/index.vue';
import DeleteItemSelect from '@/components/Icons/DeleteItemSelect.vue';
import Avatar from '@/components/share/Avatar/index.vue';
import ErrorMessage from '@/components/share/Input/ErrorMessage.vue';
import { isEmpty } from '@/util/useValidateForm';
import { getSearchEmploy } from '@/api/composables/useGetSearchEmployee';

export type DropdownOption = {
  value: string | number | null;
  text: string;
};

export default defineComponent({
  components: { ErrorMessage, Input, DeleteItemSelect, Avatar },
  props: {
    options: {
      type: Array as PropType<DropdownOption[]>,
      default: () => [],
    },
    modelValue: {
      type: Array,
      default: () => [],
    },
    itemValue: {
      type: String,
      default: 'value',
    },
    itemText: {
      type: String,
      default: 'text',
    },
    itemUrl: {
      type: String,
      default: 'url',
    },
    placeholder: {
      type: String,
    },
    background: {
      type: String,
    },
    height: {
      type: String,
    },
    classes: {
      type: String,
    },
    required: {
      type: Boolean,
      default: false,
    },
    validated: {
      type: Boolean,
      default: false,
    },
    errorServer: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hasIconDefault: {
      type: Boolean,
      default: false,
    },
    fontSize: {
      type: Number,
      default: 16,
    },
    reset: {
      type: Number,
      default: 0,
    },
    limit: {
      Number,
      default: 0,
    },
  },
  setup(props, { emit }) {
    const selectTags = ref<HTMLHtmlElement>();
    const { itemText, modelValue, required } = toRefs(props);
    const optionsData = ref<DropdownOption[]>(props.options);
    const i18n = useI18n();
    const t = i18n.t;
    const valueInput = ref('');
    const isShowOption = ref(false);
    const widthOptionSelect = ref();
    const optionsFilter = ref<DropdownOption[]>([]);
    if (!optionsData.value.length) {
      getSearchEmploy().then((res) => {
        optionsData.value = res.data.map((el) => {
          return {
            value: el.id,
            text: el.name,
            url: el.photo,
            name_face_image: el.name_face_image,
          };
        });
      });
    }

    const toggleSelect = () => {
      if (valueInput.value) {
        isShowOption.value = !isShowOption.value;
        widthOptionSelect.value = selectTags.value?.offsetWidth;
      }
    };
    const clickOutside = () => {
      valueInput.value = '';
      isShowOption.value = false;
    };
    const removeItemFromModel = (newModel, item) => {
      const index = newModel.indexOf(item);
      if (index !== -1) {
        newModel.splice(index, 1);
      }
    };
    const selectOption = (item) => {
      if (typeof item === 'object') {
        item = item[props.itemValue];
      }
      const newModel = modelValue.value;
      if (newModel.indexOf(item) === -1) {
        newModel.push(item);
      } else {
        removeItemFromModel(newModel, item);
      }
      emit('update:modelValue', newModel);
      emit('change', item, newModel);
      valueInput.value = '';
      isShowOption.value = false;
    };
    const isActive = (item) => {
      if (typeof optionsData.value[0] === 'object')
        return modelValue.value.indexOf(item[props.itemValue]) !== -1;
      return modelValue.value.indexOf(item) !== -1;
    };
    const handleSearchFilter = () => {
      return optionsData.value.filter(
        (item) =>
          item[itemText.value].includes(valueInput.value.toLowerCase()) ||
          item[itemText.value].includes(valueInput.value.toUpperCase())
      );
    };
    const changeTextSearch = () => {
      if (!valueInput.value) isShowOption.value = false;
      else isShowOption.value = true;
      optionsFilter.value = handleSearchFilter();
    };

    const widthInput = computed(() => {
      return `${valueInput.value.length * 14 + 20}px`;
    });
    const optionSelectedItem = computed(() => {
      if (!optionsData.value[0]) {
        return [];
      }
      // options is object
      if (typeof optionsData.value[0] === 'object') {
        return optionsData.value.filter((item) =>
          modelValue.value.includes(item[props.itemValue])
        );
      }
      // option is string | number

      return optionsData.value.filter((item) =>
        modelValue.value.includes(item)
      );
    });

    const errorMessage = ref();
    const checkRequired = () => {
      if (required.value && isEmpty(optionSelectedItem.value)) {
        errorMessage.value = t('errors.required');
        emit('update:errors', t('errors.required'));
      } else {
        errorMessage.value = '';
        emit('update:errors', '');
      }
    };
    watchEffect(() => {
      checkRequired();
    });
    onBeforeUnmount(() => {
      emit('update:errors', '');
    });
    // trigger reset input search employee
    watch(
      () => props.reset,
      () => {
        valueInput.value = '';
      }
    );

    return {
      t,
      arrowDown,
      isShowOption,
      optionsFilter,
      optionSelectedItem,
      selectTags,
      valueInput,
      widthInput,
      widthOptionSelect,
      errorMessage,
      optionsData,
      checkRequired,
      changeTextSearch,
      clickOutside,
      isActive,
      selectOption,
      toggleSelect,
    };
  },
});
