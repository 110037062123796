import api, { ApiResponse } from '@/clients/api';
import { ErrorCode } from '@/api/types/general/ErrorCode';
import {
  JSONApiResponseBody,
  JSONApiResponseErrorBody,
} from '@/api/types/general/JSONApiResponse';
import ConstantsAPI from '@/constants/api';
import { AlertSettingParams, AlertSettingId } from '@/model/AlertSetting';
import { AlertSettingResult } from '@/api/types/AlertSettingApi';

export async function postAlertSetting(
  postData: AlertSettingParams
): Promise<
  | ApiResponse<200, JSONApiResponseBody<AlertSettingId>>
  | ApiResponse<400, JSONApiResponseErrorBody<ErrorCode>>
  | ApiResponse<500, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.post(ConstantsAPI.AlertSettingCreate, postData);
}
export async function getDetailAlertSetting(
  id: string
): Promise<
  | ApiResponse<200, JSONApiResponseBody<[]>>
  | ApiResponse<400, JSONApiResponseErrorBody<ErrorCode>>
  | ApiResponse<500, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.get(ConstantsAPI.AlertSettingDetail(id));
}
export async function updateAlertSetting(
  id: string,
  params: AlertSettingParams
): Promise<
  | ApiResponse<200, JSONApiResponseBody<AlertSettingResult>>
  | ApiResponse<400, JSONApiResponseErrorBody<ErrorCode>>
  | ApiResponse<500, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.put(ConstantsAPI.AlertSettingUpdate(id), params);
}
