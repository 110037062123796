
import { defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import Table from '@/components/share/Table/ScrollTable.vue';
import iconPlus from '@/assets/svg/Plus.svg';
import { usePopupDeleteStore } from '@/store/modules/popup-delete';
import useShiftPlan from '@/api/composables/useShiftPlan';
import router from '@/router';
import ConstantsRouter from '@/constants/router';
import { showToast } from '@/util/useToastMessage';
import { useDeleteShiftPlan } from '@/api/composables/useShiftPlanDetail';
import Button from '@/components/share/Button/index.vue';
import { ShiftPlanParams } from '@/api/types/ShiftPlan';
import BaseIcon from '@/components/Icons/BaseIcon.vue';
import { formatTimeDate } from '@/util/time';

export default defineComponent({
  components: {
    BaseIcon,
    Table,
    Button,
  },
  setup() {
    const i18n = useI18n();
    const store = usePopupDeleteStore();

    const shiftPlanProcessImportStatusList = [
      { text: i18n.t('making'), color: '#F8BF2D' },
      { text: i18n.t('resolved'), color: '#45D1D1' },
      { text: i18n.t('expired'), color: '#999999' },
    ];
    const membersToSubmittedHeaders = [
      {
        text: `${i18n.t('No')}.`,
        value: 'shift_plan_no',
        width: 120,
      },
      {
        text: i18n.t('title'),
        value: 'shift_plan_name',
        width: 120,
        bodyClass: 'truncate-2-line',
      },

      {
        text: i18n.t('unit'),
        value: 'unit_name',
        width: 120,
        bodyClass: 'truncate-2-line',
      },
      {
        text: i18n.t('member'),
        value: 'total_shift_employee',
        width: 250,
      },
      {
        text: i18n.t('period'),
        value: 'date',
        width: 250,
      },
      {
        text: i18n.t('status'),
        value: 'shift_plan_process_import_status',
        width: 120,
      },
      {
        text: i18n.t('edit'),
        value: 'edit',
        width: 60,
      },
      {
        text: i18n.t('delete'),
        value: 'delete',
        width: 60,
      },
    ];
    const shiftPlanSearchParams = ref({
      page: 1,
    });
    const { pagination, shiftPlanList, getListPlanListData } = useShiftPlan();
    getListPlanListData(shiftPlanSearchParams.value);

    const deleteShiftPlan = async (item) => {
      const { deleteData } = useDeleteShiftPlan(item.id);
      await deleteData
        .then((error) => {
          if (error) {
            if (error.errorsCommon.value) {
              showToast('error', error.errorsCommon.value);
            }
          } else {
            showToast('success', i18n.t('theShiftPlanHasBeenDeleted'));
          }
        })
        .catch(() => {
          showToast('error', i18n.t('theShiftPlanCouldNotBeDeleted'));
        });

      await deleteData;
      await getListPlanListData(shiftPlanSearchParams.value);
    };
    const openDeleteShiftPlanConfirm = (item) => {
      store.dispatch('show', {
        title: i18n.t('removeShift'),
        body: `${i18n.t('title')}「${item.shift_plan_name}」${i18n.t(
          'toRemove'
        )}`,
        isShown: true,
        onSubmit: () => deleteShiftPlan(item),
      });
    };
    const openMembersToSubmittedDeleteConfirm = () => {
      store.dispatch('show', {
        isShown: true,
      });
    };

    const rootStore = useStore();
    const onOpenShiftPlanCreateForm = () => {
      router.push('/new' + ConstantsRouter.CreateNewShift);
      rootStore.commit('shiftPlan/SET_LIST_USER_SELECTED', []);
    };
    const goToShiftPlanDetail = (item: ShiftPlanParams) => {
      router.push({
        path: `/new${ConstantsRouter.CreateNewShiftID}`,
        query: { id: item.id },
      });
    };
    const onChangePage = () => {
      getListPlanListData(shiftPlanSearchParams.value);
    };

    return {
      t: i18n.t,
      membersToSubmittedHeaders,
      iconPlus,
      shiftPlanList,
      shiftPlanProcessImportStatusList,
      shiftPlanSearchParams,
      pagination,
      openDeleteShiftPlanConfirm,
      openMembersToSubmittedDeleteConfirm,
      goToShiftPlanDetail,
      deleteShiftPlan,
      onOpenShiftPlanCreateForm,
      formatTimeDate,
      onChangePage,
    };
  },
});
