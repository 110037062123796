
import { defineComponent, onBeforeMount, onBeforeUnmount } from 'vue';
import { useI18n } from 'vue-i18n';
import Plus from '@/assets/svg/Plus.svg';
import { useStore } from '@/store';
import router from '@/router';
import ConstantsRouter from '@/constants/router';

export default defineComponent({
  setup() {
    const i18n = useI18n();
    const t = i18n.t;
    const store = useStore();
    onBeforeMount(() => {
      store.commit('root/SET_BACK_ROUTER_PATH', ConstantsRouter.Settings);
      store.commit('root/SET_CURRENT_PAGE', i18n.t('attendanceMaster'));
    });
    onBeforeUnmount(() => {
      store.commit('root/SET_BACK_ROUTER_PATH', '');
      store.commit('root/SET_CURRENT_PAGE', '');
    });
    const toggleTabs = (item) => {
      router.push(item.path);
    };
    const currentPage = router.currentRoute;
    return {
      toggleTabs,
      t,
      Plus,
      currentPage,
      showAllEmployementTypes: {
        label: t('showAllEmploymentTypes'),
      },
      attendanceDataList: [
        {
          employmentTypeCode: '00001',
          employmentTypeName: '正社員（標準）9:00~18:00',
          operationStartDate: '2020年01月',
        },
        {
          employmentTypeCode: '00001',
          employmentTypeName: '正社員（標準）9:00~18:00',
          operationStartDate: '2020年01月',
        },
      ],
      attendanceDataHeaders: [
        {
          text: i18n.t('attendanceDataHeaders.employmentTypeCode'),
          value: 'employmentTypeCode',
          width: '10%',
        },
        {
          text: i18n.t('attendanceDataHeaders.employmentTypeName'),
          value: 'employmentTypeName',
          width: '52%',
        },
        {
          text: i18n.t('attendanceDataHeaders.operationStartDate'),
          value: 'operationStartDate',
          width: '10%',
        },
        {
          text: i18n.t('attendanceDataHeaders.editAction'),
          value: 'editAction',
          width: '6%',
          background: '#f2f8fc',
        },
        {
          text: i18n.t('attendanceDataHeaders.removeAction'),
          value: 'removeAction',
          width: '6%',
          background: '#f2f8fc',
        },
        {
          text: i18n.t('attendanceDataHeaders.copyAction'),
          value: 'copyAction',
          width: '6%',
          background: '#f2f8fc',
        },
      ],
      dataTabs: [
        {
          text: t('leaveMaster'),
          path: '',
        },
        {
          text: i18n.t('scheduledLaborMaster'),
          path: ConstantsRouter.Holidays,
          name: ConstantsRouter.ScheduledLaborMaster,
        },
        // hide in new design
        // {
        //   text: t('holidayMaster'),
        //   path: ConstantsRouter.WorkPatternMaster,
        //   name: ConstantsRouter.WorkPatternMaster,
        // },
        {
          text: t('workingStyleMaster'),
          path: ConstantsRouter.WorkingStyleMaster,
          name: ConstantsRouter.WorkingStyleMaster,
        },
        {
          text: t('timezoneDivisionMaster'),
          path: ConstantsRouter.Timezone,
          name: ConstantsRouter.Timezone,
        },
        {
          text: t('timeZoneDivisionGroup'),
          path: ConstantsRouter.TimezoneGroup,
          name: ConstantsRouter.TimezoneGroup,
        },
      ],
    };
  },
});
