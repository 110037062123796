import { GetterTree } from 'vuex';

import type { CurrentPageType } from '@/types';

import { RootState } from '@/store';

import { State } from './state';

export type Getters = {
  getCurrentPage(state: State): CurrentPageType;
};

export const getters: GetterTree<State, RootState> & Getters = {
  getCurrentPage: (state) => state.currentPage,
};
