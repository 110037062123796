
import { computed, defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useLoginAccountStore } from '@/store/modules/login-account';
import { useRoute } from 'vue-router';
import { convertToDateTime } from '@/util/composables';
import { showToast } from '@/util/useToastMessage';
import { isValidateForm } from '@/util/useValidateForm';
import { WorkInHolidayApplicationParams } from '@/model/WorkInHolidayApplication';
import useUnit from '@/api/composables/useUnit';
import {
  useDetailWorkInHolidayApplication,
  usePostWorkInHolidayApplication,
} from '@/api/composables/useWokInHolidayApplication';
import { formatTimeDate } from '@/util/time';
import Table from '@/components/share/Table/Table.vue';
import router from '@/router';
import Dropdown from '@/components/share/Dropdown/Dropdown.vue';
import DatePickerInput from '@/components/share/Calendar/DatePickerInput.vue';
import InputTime from '@/components/share/Input/InputTime.vue';
import RadioGroup from '@/components/share/Radio/RadioGroup.vue';
import ReasonUpdateStatusApplication from '@/components/Application/ReasonUpdateStatusApplication.vue';
import ConstantsRouter from '@/constants/router';
import BackPreviousPage from '@/components/share/BackPreviousPage/index.vue';
import ApplicationApproveFlowDetail from '@/components/Application/ApplicationApproveFlowDetail.vue';
import { APPLICATION_MY_APPROVAL } from '@/constants/typeApplication';
import { USING_TYPE_WORK_HOLIDAY_APPLICATION } from '@/constants/typeWorkInHolidayApplication';
import ReasonCreateApplication from '@/components/Application/ReasonCreateApplication.vue';
import moment from 'moment';

export default defineComponent({
  components: {
    ReasonCreateApplication,
    InputTime,
    Dropdown,
    DatePickerInput,
    Table,
    RadioGroup,
    BackPreviousPage,
    ApplicationApproveFlowDetail,
    ReasonUpdateStatusApplication,
  },
  setup() {
    const i18n = useI18n();
    const t = i18n.t;
    const { unitList } = useUnit({});
    const route = useRoute();
    const targetDate = route.query.target_date as string;
    const accountStore = useLoginAccountStore();
    const myAccount = computed(() => accountStore.state.myAccount);
    const takeSubstituteHolidayCurrentDate = ref(moment().format('YYYY/MM/DD'));
    const applicationFormId = route.query.application_form_id;
    const isApproval = route.query.is_my_application as string;
    const applicationDefineId = route.query.id as string;
    const displayTargetDate = (date) => {
      return formatTimeDate(
        date,
        `YYYY${t('year')}MM${t('month')}DD${t('day')}（dd）`
      );
    };
    const goToBack = () => {
      if (parseInt(isApproval) === APPLICATION_MY_APPROVAL) {
        router.push(`/new${ConstantsRouter.ApplicationMyApproval}`);
      } else if (applicationFormId) {
        router.push(`/new${ConstantsRouter.ApplicationApprovalRequest}`);
      } else {
        router.push(`/new${ConstantsRouter.TimeCard}`);
      }
    };
    const acquisitionList = [
      {
        value: USING_TYPE_WORK_HOLIDAY_APPLICATION.CAN_BE,
        label: i18n.t('canBe'),
      },
      {
        value: USING_TYPE_WORK_HOLIDAY_APPLICATION.NONE,
        label: i18n.t('none'),
      },
    ];

    const workInHolidayApplicationHeaders = [
      {
        text: '',
        value: 'action',
        dataAlign: 'center',
        width: '80px',
      },
      {
        text: '',
        value: 'type_name',
        dataAlign: 'center',
        width: '80px',
      },
      {
        text: t('checkIn'),
        value: 'start_time',
        dataAlign: 'center',
        width: '120px',
      },
      {
        text: t('checkOut'),
        value: 'end_time',
        dataAlign: 'center',
        width: '120px',
      },
      {
        text: t('breakStartTimeRounding'),
        value: 'break_start_time',
        dataAlign: 'center',
        width: '120px',
      },
      {
        text: t('breakEndTimeRounding'),
        value: 'break_end_time',
        dataAlign: 'center',
        width: '120px',
      },
      {
        text: t('breakTime'),
        value: 'break_time',
        dataAlign: 'center',
        width: '120px',
      },
      {
        text: t('goOut'),
        value: 'go_out_start',
        dataAlign: 'center',
        width: '120px',
      },
      {
        text: t('goBack'),
        value: 'go_out_end',
        dataAlign: 'center',
        width: '120px',
      },
      {
        text: t('scheduledOvertime'),
        value: 'scheduled_overtime',
        dataAlign: 'center',
        width: '120px',
      },
      {
        text: t('attendancePlace'),
        value: 'attendance_place',
        dataAlign: 'center',
        width: '120px',
      },
      {
        text: t('checkinUnit'),
        value: 'unit_id',
        dataAlign: 'center',
        width: '120px',
      },
    ];
    const workInHolidayApplicationRequestParams = ref<WorkInHolidayApplicationParams>(
      {
        unit_id: myAccount.value.unit_id,
        application_define_id: applicationDefineId,
        target_date: targetDate,
        pp_employee_id: myAccount.value.pp_employee_id,
        application_work_in_holidays: [{}],
        take_substitute_holiday_date: takeSubstituteHolidayCurrentDate.value,
        take_substitute_leave_date_type:
          USING_TYPE_WORK_HOLIDAY_APPLICATION.CAN_BE,
      }
    );

    const errors = ref({});
    const errorServer = ref({});
    const validated = ref(false);
    const isDisableSubmit = computed(() =>
      Object.values(errors.value).some((e) => !!e)
    );
    const isValid = () => {
      validated.value = true;
      return isValidateForm(errors);
    };
    const addWorkInHolidayDetails = () => {
      workInHolidayApplicationRequestParams.value.application_work_in_holidays.push(
        {}
      );
    };
    const removeWorkInHolidayDetails = (index) => {
      workInHolidayApplicationRequestParams.value.application_work_in_holidays.splice(
        index,
        1
      );
    };
    const submitPostWorkInHolidayApplication = () => {
      if (!isValid()) return;
      const { registered } = usePostWorkInHolidayApplication(
        workInHolidayApplicationRequestParams.value
      );

      registered
        .then((error) => {
          if (error) {
            errorServer.value = error.errors.value;
            if (error.errorsCommon.value) {
              showToast('error', error.errorsCommon.value);
            } else {
              showToast('warning', i18n.t('invalidInput'));
            }
          } else {
            showToast('success', i18n.t('theApplicationHasBeenCreated'));
            goToBack();
          }
        })
        .catch(() => {
          showToast('error', i18n.t('errors.serverError'));
        });
    };

    // for detail work in holiday  application
    const {
      getWorkInHolidayApplicationDetailData,
      workInHolidayApplicationDetail,
    } = useDetailWorkInHolidayApplication();
    const getWorkInHolidayApplicationDetail = async () => {
      if (applicationFormId) {
        await getWorkInHolidayApplicationDetailData(applicationFormId);
        workInHolidayApplicationRequestParams.value =
          workInHolidayApplicationDetail.value;
      }
    };
    getWorkInHolidayApplicationDetail();

    return {
      t,
      workInHolidayApplicationHeaders,
      targetDate,
      validated,
      errors,
      errorServer,
      isDisableSubmit,
      workInHolidayApplicationRequestParams,
      unitList,
      applicationDefineId,
      acquisitionList,
      isApproval,
      applicationFormId,
      workInHolidayApplicationDetail,
      takeSubstituteHolidayCurrentDate,
      USING_TYPE_WORK_HOLIDAY_APPLICATION,
      goToBack,
      convertToDateTime,
      displayTargetDate,
      addWorkInHolidayDetails,
      removeWorkInHolidayDetails,
      submitPostWorkInHolidayApplication,
      getWorkInHolidayApplicationDetailData,
    };
  },
});
