
import { computed, defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import Button from '@/components/share/Button/index.vue';
import BackPreviousPage from '@/components/share/BackPreviousPage/index.vue';
import router from '@/router';
import { useExportShiftPlan } from '@/api/composables/useExportShiftPlan';
import InputFile from '@/components/share/Input/InputFile.vue';
import UploadFileImportShiftPlanPopup from '@/views/ShiftManagement/UploadFileImportShiftPlanPopup.vue';
import {
  useGetImportShiftPlanDraft,
  usePostImportShiftPlan,
  usePostImportShiftPlanDraft,
} from '@/api/composables/useImportShiftPlan';
import { ImportShiftPlanDraftApiPostParams } from '@/api/types/ShiftPlan';
import { showToast } from '@/util/useToastMessage';
import ErrorMessage from '@/components/share/Input/ErrorMessage.vue';
import { MAX_SIZE_FILE_10_MB } from '@/constants/fileUpload';
import ImportingShiftPlanPopup from '@/views/ShiftManagement/ImportingShiftPlanPopup.vue';

export default defineComponent({
  components: {
    ImportingShiftPlanPopup,
    ErrorMessage,
    UploadFileImportShiftPlanPopup,
    InputFile,
    Button,
    BackPreviousPage,
  },
  setup(props, { emit }) {
    const i18n = useI18n();
    const t = i18n.t;

    const backShiftPlanRegister = () => {
      emit('onBack');
    };
    const { exportShiftPlanData } = useExportShiftPlan();
    const shiftPlanId = computed(
      () => router.currentRoute.value.query.id as string
    );
    const exportShiftPlan = () => {
      exportShiftPlanData(shiftPlanId.value);
    };

    // for import shift plan
    const importShiftPlanParams = ref<ImportShiftPlanDraftApiPostParams>({});
    const percentage = ref(0);
    const errors = ref({ import_file: '' });
    const isValid = () => {
      errors.value.import_file = '';
      if (
        importShiftPlanParams.value.import_file &&
        importShiftPlanParams.value.import_file.size <= MAX_SIZE_FILE_10_MB
      ) {
        return true;
      }
      errors.value.import_file = t('theFileSizeLimitHasBeenExceeded');
      return false;
    };
    const onUploadFile = (progress) => {
      percentage.value = Math.floor((progress.loaded * 100) / progress.total);
    };
    const {
      importShiftPlanDraftDetail,
      getImportShiftPlanDetailData,
    } = useGetImportShiftPlanDraft();
    const {
      postImportShiftPlanDraftData,
      loadingImportShiftPlanDraft,
    } = usePostImportShiftPlanDraft();
    const submitImportShiftPlanDraft = async () => {
      const { res } = await postImportShiftPlanDraftData(
        shiftPlanId.value,
        importShiftPlanParams.value,
        onUploadFile
      );
      if (res.value) {
        await getImportShiftPlanDetailData(res.value.shift_plan_import_id);
      } else {
        showToast('error', t('importShiftPlanError'));
        closeUploadFileImportShiftPlanPopup();
      }
    };
    const {
      postImportShiftPlanData,
      loadingImportShiftPlan,
    } = usePostImportShiftPlan();
    const submitImportShiftPlan = async () => {
      if (!importShiftPlanDraftDetail.value) return;
      closeUploadFileImportShiftPlanPopup();
      openImportingShiftPlanPopup();

      const { res } = await postImportShiftPlanData(
        importShiftPlanDraftDetail.value.shift_plan_id as string,
        importShiftPlanDraftDetail.value.file_id as string
      );
      if (res.value) {
        showToast('success', t('importShiftPlanSuccess'));
        closeImportingShiftPlanPopup();
      } else {
        showToast('error', t('importShiftPlanError'));
      }
    };

    const isShowUploadFileImportShiftPlanPopup = ref(false);
    const openUploadFileImportShiftPlanPopup = () => {
      if (!isValid()) return;
      importShiftPlanDraftDetail.value = {};
      isShowUploadFileImportShiftPlanPopup.value = true;
      submitImportShiftPlanDraft();
    };
    const closeUploadFileImportShiftPlanPopup = () => {
      isShowUploadFileImportShiftPlanPopup.value = false;
    };

    const isShowImportingShiftPlanPopup = ref(false);
    const openImportingShiftPlanPopup = () => {
      isShowImportingShiftPlanPopup.value = true;
    };
    const closeImportingShiftPlanPopup = () => {
      isShowImportingShiftPlanPopup.value = false;
    };
    const submitAbortImportShiftPlan = () => {
      closeImportingShiftPlanPopup();
    };

    return {
      t,
      shiftPlanId,
      importShiftPlanParams,
      isShowUploadFileImportShiftPlanPopup,
      percentage,
      importShiftPlanDraftDetail,
      errors,
      isShowImportingShiftPlanPopup,
      loadingImportShiftPlanDraft,
      loadingImportShiftPlan,
      openUploadFileImportShiftPlanPopup,
      closeUploadFileImportShiftPlanPopup,
      exportShiftPlanData,
      backShiftPlanRegister,
      exportShiftPlan,
      submitImportShiftPlan,
      onUploadFile,
      submitImportShiftPlanDraft,
      openImportingShiftPlanPopup,
      submitAbortImportShiftPlan,
    };
  },
});
