import api, { ApiResponse } from '@/clients/api';
import { ErrorCode } from './types/general/ErrorCode';
import {
  JSONApiResponseBody,
  JSONApiResponseErrorBody,
} from './types/general/JSONApiResponse';
import {
  ShiftPatternsApiDeleteResult,
  ShiftPatternsApiParamSearch,
  ShiftPatternsApiPostData,
  ShiftPatternsApiPostResult,
  ShiftPatternsApiUpdateData,
  ShiftPatternsGetData,
} from '@/api/types/ShiftPatern';
import ConstantsAPI from '@/constants/api';

export async function postShiftPattern(
  postData: ShiftPatternsApiPostData
): Promise<
  | ApiResponse<200, JSONApiResponseBody<ShiftPatternsApiPostResult>>
  | ApiResponse<201, JSONApiResponseBody<ShiftPatternsApiPostResult>>
  | ApiResponse<400, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.post(ConstantsAPI.ShiftPatternCreate, postData);
}

export async function getShiftPatternDetail(
  id: string
): Promise<
  | ApiResponse<200, JSONApiResponseBody<ShiftPatternsApiUpdateData>>
  | ApiResponse<400, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.get(ConstantsAPI.ShiftPatternDetail(id));
}

export async function updateShiftPattern(
  id: string,
  updateData: ShiftPatternsApiUpdateData
): Promise<
  | ApiResponse<200, JSONApiResponseBody<ShiftPatternsApiPostResult>>
  | ApiResponse<400, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.put(ConstantsAPI.ShiftPatternDetail(id), updateData);
}

export async function getShiftPattern(
  params: ShiftPatternsApiParamSearch
): Promise<
  | ApiResponse<200, JSONApiResponseBody<ShiftPatternsGetData[]>>
  | ApiResponse<404, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.get(ConstantsAPI.ShiftPatternList, { params: params });
}

export async function deleteShiftPattern(
  id: string
): Promise<
  | ApiResponse<200, JSONApiResponseBody<ShiftPatternsApiDeleteResult>>
  | ApiResponse<400, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.delete(ConstantsAPI.ShiftPatternDetail(id));
}
