
import { defineComponent } from 'vue';
import Button from '@/components/share/Button/index.vue';
import { useI18n } from 'vue-i18n';
import router from '@/router';
import ConstantsRouter from '@/constants/router';
import {
  deleteCookie,
  handleCheckAuthen,
  PP_EMPLOYEE_ID,
} from '@/util/composables';

export default defineComponent({
  name: 'NotFound',
  components: {
    Button,
  },
  setup() {
    const i18n = useI18n();
    const t = i18n.t;
    const backToHome = () => {
      router.push('/new' + ConstantsRouter.Stamp);
    };
    const handleLogout = () => {
      deleteCookie(PP_EMPLOYEE_ID);
      const isLogout = true;
      handleCheckAuthen(isLogout);
    };
    return {
      t,
      backToHome,
      handleLogout,
    };
  },
});
