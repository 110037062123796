import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import NotFound from '@/views/NotFound.vue';
import SystemError from '@/views/SystemError.vue';
import Login from '@/views/Login.vue';
import ForgotPassword from '@/views/ForgotPassword.vue';
import ConstantsRouter from '@/constants/router';
import MainLayout from '@/views/layouts/MainLayout.vue';
import Home from '@/views/Home.vue';
import DailyAttendanceRecord from '@/views/New/TimeCard/DailyAttendanceRecord.vue';
import StampRegister from '@/views/StampRegister.vue';
import MasterSettings from '@/views/MasterSettings.vue';
import OvertimeRegister from '@/views/OvertimeRegister.vue';
import ShiftRegisterMembers from '@/views/ShiftRegisterMember/ShiftRegisterMembers.vue';
import RequestSubmissionOfDesiredShift from '@/views/ShiftManagement/RequestSubmissionOfDesiredShift.vue';
import ShiftRegisterCreateNew from '@/views/ShiftRegisterMember/ShiftRegisterCreateNew.vue';
import SubmitDesiredShift from '@/views/ShiftManagement/SubmitDesiredShift.vue';
import EnterTheDesiredShift from '@/views/ShiftManagement/EnterTheDesiredShift.vue';
import ShiftPlanList from '@/views/ShiftManagement/ShiftPlanList.vue';
import ShiftPlanRegister from '@/views/ShiftManagement/ShiftPlanRegister.vue';
import ConfirmNewShift from '@/views/ShiftManagement/ConfirmNewShift.vue';
import ShiftRegistration from '@/views/ShiftRegistration.vue';
import SubmitDesired from '@/views/SubmitDesired.vue';
import MyShift from '@/views/MyShift.vue';
import EveryoneShift from '@/views/EveryoneShift.vue';
import TimeCardLateEarlyRegister from '@/views/TimeCardLateEarlyRegister.vue';
import WorkingScheduleRegister from '@/views/WorkingScheduleRegister.vue';
import WorkingTimeRegister from '@/views/WorkingTimeRegister.vue';
import ApplicationApproval from '@/views/ApplicationApproval.vue';
import ApplicationConfirm from '@/views/ApplicationConfirm.vue';
import Attendance from '@/views/Attendance.vue';
import TimeZone from '@/views/TimeZone.vue';
import TimeZoneGroup from '@/views/TimeZoneGroup.vue';
import WorkingStyleMaster from '@/views/WorkingStyleMaster.vue';
import WorkPatternMaster from '@/views/WorkPatternMaster/WorkPatternMaster.vue';
import WorkPatternMasterNew from '@/views/New/WorkPatternMaster/WorkPatternMasterNew.vue';
import ScheduledLaborMaster from '@/views/ScheduledLaborMaster.vue';
import HolidaySettings from '@/views/HolidaySettings.vue';
import HolidaySettingsSignUp from '@/views/HolidaySettingsSignUp.vue';
import ScheduledLaborPattern from '@/views/ScheduledLaborPattern.vue';
import ScheduledLaborPatternRegister from '@/views/ScheduledLaborPatternRegister.vue';
import MonthlyClosingDayList from '@/views/MonthlyClosingDay/MonthlyClosingDayList.vue';
import MonthlyClosingDayCreat from '@/views/MonthlyClosingDay/MonthlyClosingDayCreat.vue';
import MonthlyClosingDatUpdate from '@/views/MonthlyClosingDay/MonthlyClosingDatUpdate.vue';
import WorkingStyleMasterSettings from '@/views/WorkingStyleMasterSettings.vue';
import WorkingStyleMasterRegister from '@/views/WorkingStyleMasterSettings/WorkingStyleMasterRegister.vue';
import WorkPatternSetting from '@/views/WorkPatternMaster/WorkPatternSetting.vue';
import WorkPatternSettingNew from '@/views/New/WorkPatternMaster/WorkPatternSettingNew.vue';

import TimezoneDivisionSetting from '@/views/TimezoneDivisionSetting.vue';
import TimezoneGroupSetting from '@/views/TimezoneGroupSetting.vue';
import AdminSettings from '@/views/AdminSettings.vue';
import PermissionPrivilegeEditing from '@/views/PermissionPrivilegeEditing.vue';
import PermissionRoleList from '@/views/PermissionRoleList.vue';
import ApprovedPersonalFlow from '@/views/ApprovedSetting/ApprovedPersonalFlow.vue';
import OrganizationalInformation from '@/views/OrganizationalInformation.vue';
import EmployeeInformation from '@/views/EmployeeInformation.vue';
import PermissionRoleRegister from '@/views/PermissionRoleRegister.vue';
import AttendanceMaster from '@/views/AttendanceMaster.vue';
import VacationMaster from '@/views/VacationMaster.vue';
import AttendanceMasterSetting from '@/views/AttendanceMasterSetting.vue';
import VacationMasterSettingTab from '@/views/VacationMasterSettingTab.vue';
import VacationMasterSetting from '@/views/VacationMasterSetting.vue';
import CardRegistration from '@/views/CardRegistrationSetting/CardRegistration.vue';
import IndividualApplicationPolicySetting from '@/views/PersonalSettingApply/IndividualApplicationPolicySetting.vue';
import IndividualApplicationPolicyList from '@/views/PersonalSettingApply/IndividualApplicationPolicyList.vue';
import AllMemberCheckTime from '@/views/New/AllMemberCheckTime/AllMemberCheckTime.vue';
import WaitingAllowanceApplication from '@/views/New/Application/WaitingAllowanceApplication.vue';
import WaitingAllowanceApplicationDelete from '@/views/New/Application/WaitingAllowanceApplicationDelete.vue';
import WorkRecordApplication from '@/views/New/Application/WorkRecordApplication.vue';
import TimeZoneSessionList from '@/views/New/TimeZoneSessionList';
import AbsenceApplication from '@/views/New/Application/AbsenceApplication.vue';

import {
  getCookie,
  handleCheckAuthen,
  PP_EMPLOYEE_ID,
} from '@/util/composables';
import ApprovedSetting from '@/views/ApprovedSetting/ApprovedSetting.vue';
import ShiftsTimeManager from '@/views/ShiftsTimeManager.vue';

// import for new design
import NewMainLayout from '@/components/layout/NewMainLayout.vue';
import TopPage from '@/views/New/TopPage/index.vue';
import WorkingStyleMasterList from '@/views/New/WorkingStyleMaster/WorkingStyleMasterList.vue';
import ApplicationMyApproval from '@/views/New/Application/ApplicationMyApproval.vue';
import ApplicationApprovalRequest from '@/views/New/Application/ApplicationApprovalRequest.vue';
import WorkingStyleMasterCreate from '@/views/New/WorkingStyleMaster/WorkingStyleMasterCreate.vue';
import { handleUserInfor } from '@/api/composables/login';
import AllMemberTimeCard from '@/views/New/TimeCard/AllMemberTimeCard.vue';
import BasicFlowTab from '@/views/New/BasicFlow/BasicFlowTab.vue';
import BasicFlowSetting from '@/views/New/BasicFlow/BasicFlowSetting.vue';
import BasicFlowSettingCreate from '@/views/New/BasicFlow/BasicFlowSettingCreate.vue';
import PersonalFlowSetting from '@/views/New/PersonalFlow/PersonalFlowSetting.vue';
import PersonalFlowSettingCreate from '@/views/New/PersonalFlow/PersonalFlowCreate.vue';
import WorkScheduleChangeApplication from '@/views/New/Application/WorkScheduleChangeApplication.vue';
import ShiftPlanTab from '@/views/New/ShiftPlanTab';
import CheckTimeApplication from '@/views/New/Application/CheckTimeApplication.vue';
import TimeZoneSessionSettingForm from '@/components/TimeZoneSession/TimeZoneSessionSettingForm';
import TimeCardTab from '@/views/New/TimeCard/TimeCardTab.vue';
import LateEarlyApplication from '@/views/New/Application/LateEarlyApplication.vue';
import ApplicationTab from '@/views/New/Application/ApplicationTab.vue';
import SubstituteApplicationCreate from '@/views/New/SubstituteApplication/SubstituteLeaveApplicationCreate.vue';
import RegisterIcCard from '@/views/New/RegisterIcCard/RegisterIcCard.vue';
import WorkInHolidayApplication from '@/views/New/Application/WorkInHolidayApplication.vue';
import TransferAttendanceApplication from '@/views/New/Application/TransferAttendanceApplication.vue';
import TimeRecoder from '@/views/New/TimeRecoder/TimeRecoder.vue';
import TimeRecoderList from '@/views/New/TimeRecoder/TimeRecoderList.vue';
import AlertSettingCreate from '@/views/New/AlertSetting/AlertSetting.vue';
import YearPaidHolidayList from '@/views/New/YearPaidHoliday/YearPaidHolidayList.vue';
import YearPaidHolidayCreate from '@/views/New/YearPaidHoliday/YearPaidHolidayCreate.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: MainLayout,
    beforeEnter: async (to, from, next) => {
      await handleUserInfor();
      // const {
      //   applySettingForSystem,
      //   getApplySettingForSystemData,
      // } = useGetApplySettingForSystem();
      // await getApplySettingForSystemData();
      // if (
      //   !isPermissionByPath(
      //     applySettingForSystem.value.function_defines,
      //     to.path
      //   )
      // ) {
      //   showNotFound();
      // }
      next();
    },
    children: [
      {
        path: '',
        redirect: '/new' + ConstantsRouter.Stamp,
      },
      {
        path: ConstantsRouter.Home,
        component: Home,
      },
      {
        path: ConstantsRouter.Stamp,
        component: TopPage,
      },
      {
        path: ConstantsRouter.TimeCard,
        component: DailyAttendanceRecord,
      },

      {
        path: ConstantsRouter.TimeCardRegister,
        component: StampRegister,
      },
      {
        path: ConstantsRouter.Settings,
        component: MasterSettings,
      },
      {
        path: ConstantsRouter.OverTimeRegister,
        component: OvertimeRegister,
      },
      {
        name: 'shift-manager',
        path: ConstantsRouter.ShiftManager,
        component: ShiftRegisterMembers,
        children: [
          {
            name: 'request-submission-shift',
            path: ConstantsRouter.RequestSubmissionShift,
            component: RequestSubmissionOfDesiredShift,
          },
          {
            name: 'shift-register-member',
            path: ConstantsRouter.ShiftRegisterMembers,
            component: ShiftRegisterCreateNew,
          },
          {
            path: ConstantsRouter.ShiftBeingCreatedNew,
            component: ShiftPlanList,
          },
          {
            path: ConstantsRouter.CreateNewShift,
            component: ShiftPlanRegister,
          },
          {
            path: ConstantsRouter.ConfirmNewShift,
            component: ConfirmNewShift,
          },
          {
            name: ConstantsRouter.ConfirmNewShiftName,
            path: ConstantsRouter.ConfirmNewShiftID,
            component: ConfirmNewShift,
          },
          {
            name: ConstantsRouter.CreateNewShiftName,
            path: ConstantsRouter.CreateNewShiftID,
            component: ShiftPlanRegister,
          },
        ],
      },
      {
        name: 'shift-register-create-member',
        path: ConstantsRouter.ShiftRegisterCreateNew,
        component: ShiftRegisterCreateNew,
        props: {
          currentTab: 1,
        },
      },
      {
        name: 'shift-registration',
        path: ConstantsRouter.ShiftRegistration,
        component: ShiftRegistration,
        children: [
          {
            path: '',
            redirect: ConstantsRouter.SubmitDesiredShift,
          },
          {
            path: ConstantsRouter.SubmitDesiredShift,
            component: SubmitDesiredShift,
          },
          {
            path: ConstantsRouter.EnterSubmitDesiredShift,
            component: EnterTheDesiredShift,
          },
          {
            path: ConstantsRouter.SubmitDesired,
            component: SubmitDesired,
          },
          {
            path: ConstantsRouter.MyShift,
            component: MyShift,
          },
          {
            path: ConstantsRouter.EveryoneShift,
            component: EveryoneShift,
          },
        ],
      },
      {
        path: ConstantsRouter.TimeCardLateEarlyRegister,
        component: TimeCardLateEarlyRegister,
      },
      {
        path: ConstantsRouter.WorkingScheduleRegister,
        component: WorkingScheduleRegister,
      },
      {
        path: ConstantsRouter.WorkingTimeRegister,
        component: WorkingTimeRegister,
      },
      {
        path: ConstantsRouter.ApplicationApproval,
        component: ApplicationApproval,
      },
      {
        path: ConstantsRouter.ApplicationConfirm,
        component: ApplicationConfirm,
      },
      {
        path: ConstantsRouter.Settings,
        component: Attendance,
        children: [
          {
            path: ConstantsRouter.Timezone,
            component: TimeZone,
          },
          {
            path: ConstantsRouter.TimezoneGroup,
            component: TimeZoneGroup,
          },
          {
            path: ConstantsRouter.WorkingStyleMaster,
            component: WorkingStyleMaster,
          },
          {
            path: ConstantsRouter.WorkPatternMaster,
            component: WorkPatternMaster,
          },
          {
            path: ConstantsRouter.ScheduledLaborMaster,
            component: ScheduledLaborMaster,
            children: [
              {
                path: '',
                redirect: ConstantsRouter.Holidays,
              },
              {
                path: ConstantsRouter.Holidays,
                component: HolidaySettings,
              },
              {
                path: ConstantsRouter.HolidaysSignsUp,
                component: HolidaySettingsSignUp,
              },
              {
                name: ConstantsRouter.HolidaysUpdateName,
                path: ConstantsRouter.HolidaysUpdate,
                component: HolidaySettingsSignUp,
              },
              {
                path: ConstantsRouter.ScheduledLaborPattern,
                component: ScheduledLaborPattern,
              },
              {
                path: ConstantsRouter.ScheduledLaborPatternRegister,
                component: ScheduledLaborPatternRegister,
              },
              {
                path: ConstantsRouter.ScheduledLaborPatternUpdate,
                component: ScheduledLaborPatternRegister,
              },
              {
                path: ConstantsRouter.ScheduledLaborPatternCopy,
                component: ScheduledLaborPatternRegister,
              },
              {
                meta: {
                  name: 'monthly-closing-day',
                },
                path: ConstantsRouter.MonthlyClosingDayList,
                component: MonthlyClosingDayList,
              },
              {
                meta: {
                  name: 'monthly-closing-day',
                },
                path: ConstantsRouter.MonthlyClosingDayCreat,
                component: MonthlyClosingDayCreat,
              },
              {
                meta: {
                  name: 'monthly-closing-day',
                },
                path: ConstantsRouter.MonthlyClosingDayCopy,
                component: MonthlyClosingDayCreat,
              },
              {
                meta: {
                  name: 'monthly-closing-day',
                },
                path: ConstantsRouter.MonthlyClosingDayUpdate,
                component: MonthlyClosingDatUpdate,
              },
            ],
          },
        ],
      },
      {
        path: ConstantsRouter.Settings,
        component: AttendanceMaster,
        children: [
          {
            path: ConstantsRouter.VacationMaster,
            component: VacationMaster,
          },
        ],
      },

      {
        path: ConstantsRouter.Settings,
        component: AttendanceMasterSetting,
        children: [
          {
            path: ConstantsRouter.VacationMasterSetting,
            component: VacationMasterSettingTab,
            children: [
              {
                path: ConstantsRouter.VacationMasterSetting,
                component: VacationMasterSetting,
              },
            ],
          },
        ],
      },
      {
        path: ConstantsRouter.Settings,
        component: WorkingStyleMasterSettings,
        children: [
          {
            path: ConstantsRouter.WorkingStyleMasterRegister,
            component: WorkingStyleMasterRegister,
          },
          {
            path: ConstantsRouter.WorkingStyleMasterUpdate,
            component: WorkingStyleMasterRegister,
          },
          {
            path: ConstantsRouter.WorkingStyleMasterCopy,
            component: WorkingStyleMasterRegister,
          },
          {
            path: ConstantsRouter.WorkPatternAdd,
            component: WorkPatternSetting,
          },
          {
            path: ConstantsRouter.WorkPatternUpdate,
            component: WorkPatternSetting,
          },
          {
            path: ConstantsRouter.WorkPatternCopy,
            component: WorkPatternSetting,
          },
          {
            path: ConstantsRouter.TimezoneRegister,
            component: TimezoneDivisionSetting,
          },
          {
            path: ConstantsRouter.TimezoneGroupRegister,
            component: TimezoneGroupSetting,
          },
          {
            name: ConstantsRouter.TimezoneGroupUpdate,
            path: ConstantsRouter.TimezoneGroupUpdate,
            component: TimezoneGroupSetting,
          },
          {
            name: ConstantsRouter.UpdateTimezoneDivisionSettingName,
            path: ConstantsRouter.UpdateTimezoneDivisionSettingID,
            component: TimezoneDivisionSetting,
          },
        ],
      },
      {
        path: ConstantsRouter.AminSetting,
        component: AdminSettings,
        children: [
          {
            path: ConstantsRouter.PermissionPrivilegeEditing,
            component: PermissionPrivilegeEditing,
          },
          {
            path: ConstantsRouter.AminSettingRoleList,
            component: PermissionRoleList,
          },
          {
            path: ConstantsRouter.ApprovedCardRegistrationSetting,
            component: CardRegistration,
          },
          {
            path: ConstantsRouter.AminSettingRoleCreate,
            component: PermissionRoleRegister,
          },
          {
            path: ConstantsRouter.AminSettingRoleUpdate,
            component: PermissionRoleRegister,
          },
          {
            path: ConstantsRouter.AminSettingRoleCopy,
            component: PermissionRoleRegister,
          },
          {
            path: ConstantsRouter.ApprovedSetting,
            component: ApprovedSetting,
          },
          {
            path: ConstantsRouter.ApprovedSettingFlow,
            component: ApprovedPersonalFlow,
          },
          {
            path: ConstantsRouter.PersonalFlowSetting,
            component: PersonalFlowSetting,
          },
          {
            path: ConstantsRouter.ApprovedPersonalFlow,
            component: ApprovedPersonalFlow,
          },
        ],
      },
      {
        path: ConstantsRouter.ShiftTimeManager,
        component: ShiftsTimeManager,
      },
      {
        name: ConstantsRouter.ShiftTimeManagerName,
        path: ConstantsRouter.ShiftTimeManagerId,
        component: ShiftsTimeManager,
      },
      {
        path: ConstantsRouter.OrganizationalInformation,
        component: OrganizationalInformation,
      },
      {
        path: ConstantsRouter.EmployeeInformation,
        component: EmployeeInformation,
      },
      {
        path: ConstantsRouter.IndividualApplicationPolicyList,
        component: IndividualApplicationPolicyList,
      },
      {
        path: ConstantsRouter.IndividualApplicationPolicyCreate,
        component: IndividualApplicationPolicySetting,
      },
      {
        path: ConstantsRouter.IndividualApplicationPolicySettingUpdate,
        component: IndividualApplicationPolicySetting,
      },
    ],
  },
  {
    path: ConstantsRouter.AuthLogin,
    name: 'Login',
    component: Login,
  },
  {
    path: ConstantsRouter.AuthForgotPassword,
    name: 'ForgotPassword',
    component: ForgotPassword,
  },
  {
    path: ConstantsRouter.SystemError,
    name: 'SystemError',
    component: SystemError,
  },
  {
    path: ConstantsRouter.NotFound,
    name: 'NotFound',
    component: NotFound,
  },
  {
    path: ConstantsRouter.Other,
    redirect: { name: 'NotFound' },
    component: NotFound,
  },
  // update new design
  {
    path: '/new/',
    component: NewMainLayout,
    beforeEnter: async (to, from, next) => {
      await handleUserInfor();
      next();
    },
    children: [
      {
        path: '/new/',
        redirect: '/new' + ConstantsRouter.Stamp,
      },
      {
        path: '/new/' + ConstantsRouter.Home,
        component: Home,
      },
      {
        path: '/new/' + ConstantsRouter.Stamp,
        component: TopPage,
      },
      {
        path: '/new/' + ConstantsRouter.TimeCard,
        component: TimeCardTab,
        children: [
          {
            path: '',
            redirect: '/new' + ConstantsRouter.MyTimeCard,
          },
          {
            path: '/new/' + ConstantsRouter.MyTimeCard,
            component: DailyAttendanceRecord,
          },
          {
            path: '/new/' + ConstantsRouter.AllMemberTimeCard,
            component: AllMemberTimeCard,
          },
        ],
      },
      {
        path: '/new/' + ConstantsRouter.AllMemberTimeCardDetail,
        component: DailyAttendanceRecord,
      },
      {
        path: '/new/' + ConstantsRouter.TimeCardRegister,
        component: StampRegister,
      },
      {
        path: '/new/' + ConstantsRouter.Settings,
        component: MasterSettings,
      },
      {
        path: '/new/' + ConstantsRouter.OverTimeRegister,
        component: OvertimeRegister,
      },
      {
        path: '/new/' + ConstantsRouter.CreateNewShift,
        component: ShiftPlanRegister,
      },
      {
        path: '/new' + ConstantsRouter.CreateNewShiftID,
        component: ShiftPlanRegister,
      },
      {
        path: '/new/' + ConstantsRouter.ShiftBeingCreatedNew,
        component: ShiftPlanList,
      },
      {
        name: 'shift-manager',
        path: '/new' + ConstantsRouter.ShiftManager,
        component: ShiftPlanTab,
        children: [
          {
            name: 'request-submission-shift',
            path: '/new/' + ConstantsRouter.RequestSubmissionShift,
            component: RequestSubmissionOfDesiredShift,
          },
          {
            name: 'shift-register-member',
            path: '/new/' + ConstantsRouter.ShiftRegisterMembers,
            component: ShiftRegisterCreateNew,
          },
          {
            path: '/new/' + ConstantsRouter.ConfirmNewShift,
            component: ConfirmNewShift,
          },
          {
            name: '/new/' + ConstantsRouter.ConfirmNewShiftName,
            path: '/new/' + ConstantsRouter.ConfirmNewShiftID,
            component: ConfirmNewShift,
          },
        ],
      },
      {
        name: 'shift-register-create-member',
        path: '/new/' + ConstantsRouter.ShiftRegisterCreateNew,
        component: ShiftRegisterCreateNew,
        props: {
          currentTab: 1,
        },
      },
      {
        name: 'shift-registration',
        path: '/new/' + ConstantsRouter.ShiftRegistration,
        component: ShiftRegistration,
        children: [
          {
            path: '',
            redirect: '/new/' + ConstantsRouter.SubmitDesiredShift,
          },
          {
            path: '/new/' + ConstantsRouter.SubmitDesiredShift,
            component: SubmitDesiredShift,
          },
          {
            path: '/new/' + ConstantsRouter.EnterSubmitDesiredShift,
            component: EnterTheDesiredShift,
          },
          {
            path: '/new/' + ConstantsRouter.SubmitDesired,
            component: SubmitDesired,
          },
          {
            path: '/new/' + ConstantsRouter.MyShift,
            component: MyShift,
          },
          {
            path: '/new/' + ConstantsRouter.EveryoneShift,
            component: EveryoneShift,
          },
        ],
      },
      {
        path: '/new/' + ConstantsRouter.TimeCardLateEarlyRegister,
        component: LateEarlyApplication,
      },
      {
        path: '/new/' + ConstantsRouter.EarlyApplication,
        component: LateEarlyApplication,
      },
      {
        path: '/new/' + ConstantsRouter.WorkingScheduleRegister,
        component: WorkingScheduleRegister,
      },
      {
        path: '/new/' + ConstantsRouter.WorkingTimeRegister,
        component: WorkingTimeRegister,
      },
      {
        path: '/new/' + ConstantsRouter.ApplicationApproval,
        component: ApplicationApproval,
      },
      {
        path: '/new/' + ConstantsRouter.ApplicationConfirm,
        component: ApplicationConfirm,
      },
      {
        path: '/new/' + ConstantsRouter.WorkingStyleMaster,
        component: WorkingStyleMasterList,
      },
      {
        path: '/new/' + ConstantsRouter.WorkPatternMaster,
        component: WorkPatternMasterNew,
      },
      {
        path: '/new/' + ConstantsRouter.WorkPatternAdd,
        component: WorkPatternSettingNew,
      },
      {
        path: '/new/' + ConstantsRouter.WorkPatternUpdate,
        component: WorkPatternSettingNew,
      },
      {
        path: '/new/' + ConstantsRouter.WorkPatternCopy,
        component: WorkPatternSettingNew,
      },
      {
        path: '/new/' + ConstantsRouter.Settings,
        component: Attendance,
        children: [
          {
            path: '/new/' + ConstantsRouter.TimezoneGroup,
            component: TimeZoneGroup,
          },
          {
            path: '/new/' + ConstantsRouter.ScheduledLaborMaster,
            component: ScheduledLaborMaster,
            children: [
              {
                path: '',
                redirect: '/new/' + ConstantsRouter.Holidays,
              },
              {
                path: '/new/' + ConstantsRouter.Holidays,
                component: HolidaySettings,
              },
              {
                path: '/new/' + ConstantsRouter.HolidaysSignsUp,
                component: HolidaySettingsSignUp,
              },
              {
                name: '/new/' + ConstantsRouter.HolidaysUpdateName,
                path: '/new/' + ConstantsRouter.HolidaysUpdate,
                component: HolidaySettingsSignUp,
              },
              {
                path: '/new/' + ConstantsRouter.ScheduledLaborPattern,
                component: ScheduledLaborPattern,
              },
              {
                path: '/new/' + ConstantsRouter.ScheduledLaborPatternRegister,
                component: ScheduledLaborPatternRegister,
              },
              {
                path: '/new/' + ConstantsRouter.ScheduledLaborPatternUpdate,
                component: ScheduledLaborPatternRegister,
              },
              {
                path: '/new/' + ConstantsRouter.ScheduledLaborPatternCopy,
                component: ScheduledLaborPatternRegister,
              },
              {
                meta: {
                  name: 'monthly-closing-day',
                },
                path: '/new/' + ConstantsRouter.MonthlyClosingDayList,
                component: MonthlyClosingDayList,
              },
              {
                meta: {
                  name: 'monthly-closing-day',
                },
                path: '/new/' + ConstantsRouter.MonthlyClosingDayCreat,
                component: MonthlyClosingDayCreat,
              },
              {
                meta: {
                  name: 'monthly-closing-day',
                },
                path: '/new/' + ConstantsRouter.MonthlyClosingDayCopy,
                component: MonthlyClosingDayCreat,
              },
              {
                meta: {
                  name: 'monthly-closing-day',
                },
                path: '/new/' + ConstantsRouter.MonthlyClosingDayUpdate,
                component: MonthlyClosingDatUpdate,
              },
            ],
          },
        ],
      },
      {
        path: '/new/' + ConstantsRouter.Settings,
        component: AttendanceMaster,
        children: [
          {
            path: '/new/' + ConstantsRouter.VacationMaster,
            component: VacationMaster,
          },
        ],
      },
      {
        path: '/new/' + ConstantsRouter.Settings,
        component: AttendanceMasterSetting,
        children: [
          {
            path: '/new/' + ConstantsRouter.VacationMasterSetting,
            component: VacationMasterSettingTab,
            children: [
              {
                path: '/new/' + ConstantsRouter.VacationMasterSetting,
                component: VacationMasterSetting,
              },
            ],
          },
        ],
      },
      {
        path: '/new/' + ConstantsRouter.WorkingStyleMasterRegister,
        component: WorkingStyleMasterCreate,
      },
      {
        path: '/new/' + ConstantsRouter.WorkingStyleMasterUpdate,
        component: WorkingStyleMasterCreate,
      },
      {
        path: '/new/' + ConstantsRouter.WorkingStyleMasterCopy,
        component: WorkingStyleMasterCreate,
      },
      {
        path: '/new/' + ConstantsRouter.Settings,
        component: WorkingStyleMasterSettings,
        children: [
          {
            path: '/new/' + ConstantsRouter.WorkingStyleMasterRegister,
            component: WorkingStyleMasterRegister,
          },
          {
            path: '/new/' + ConstantsRouter.WorkingStyleMasterUpdate,
            component: WorkingStyleMasterRegister,
          },
          {
            path: '/new/' + ConstantsRouter.WorkingStyleMasterCopy,
            component: WorkingStyleMasterRegister,
          },
          {
            path: '/new/' + ConstantsRouter.TimezoneGroupRegister,
            component: TimezoneGroupSetting,
          },
          {
            name: '/new/' + ConstantsRouter.TimezoneGroupUpdate,
            path: '/new/' + ConstantsRouter.TimezoneGroupUpdate,
            component: TimezoneGroupSetting,
          },
          {
            name: '/new/' + ConstantsRouter.UpdateTimezoneDivisionSettingName,
            path: '/new/' + ConstantsRouter.UpdateTimezoneDivisionSettingID,
            component: TimezoneDivisionSetting,
          },
        ],
      },
      {
        path: '/new/' + ConstantsRouter.AminSetting,
        component: AdminSettings,
        children: [
          {
            path: '/new/' + ConstantsRouter.PermissionPrivilegeEditing,
            component: PermissionPrivilegeEditing,
          },
          {
            path: '/new/' + ConstantsRouter.AminSettingRoleList,
            component: PermissionRoleList,
          },
          {
            path: '/new/' + ConstantsRouter.ApprovedCardRegistrationSetting,
            component: CardRegistration,
          },
          {
            path: '/new/' + ConstantsRouter.AminSettingRoleCreate,
            component: PermissionRoleRegister,
          },
          {
            path: '/new/' + ConstantsRouter.AminSettingRoleUpdate,
            component: PermissionRoleRegister,
          },
          {
            path: '/new/' + ConstantsRouter.AminSettingRoleCopy,
            component: PermissionRoleRegister,
          },
          {
            path: '/new/' + ConstantsRouter.ApprovedSetting,
            component: ApprovedSetting,
          },
        ],
      },
      {
        path: '',
        component: BasicFlowTab,
        children: [
          {
            path: '/new/' + ConstantsRouter.ApprovedSettingFlow,
            redirect: '/new' + ConstantsRouter.BasicFlowSetting,
          },
          {
            path: '/new/' + ConstantsRouter.BasicFlowSetting,
            component: BasicFlowSetting,
          },
          {
            path: '/new/' + ConstantsRouter.PersonalFlowSetting,
            component: PersonalFlowSetting,
          },
        ],
      },
      {
        path: '/new/' + ConstantsRouter.ApprovedSettingFlowUpdate,
        component: BasicFlowSettingCreate,
      },
      {
        path: '/new/' + ConstantsRouter.PersonalFlowSettingCreate,
        component: PersonalFlowSettingCreate,
      },
      {
        path: '/new/' + ConstantsRouter.ShiftTimeManager,
        component: ShiftsTimeManager,
      },
      {
        name: '/new/' + ConstantsRouter.ShiftTimeManagerName,
        path: '/new/' + ConstantsRouter.ShiftTimeManagerId,
        component: ShiftsTimeManager,
      },
      {
        path: '/new/' + ConstantsRouter.OrganizationalInformation,
        component: OrganizationalInformation,
      },
      {
        path: '/new/' + ConstantsRouter.EmployeeInformation,
        component: EmployeeInformation,
      },
      {
        path: '/new/' + ConstantsRouter.IndividualApplicationPolicyList,
        component: IndividualApplicationPolicyList,
      },
      {
        path: '/new/' + ConstantsRouter.IndividualApplicationPolicyCreate,
        component: IndividualApplicationPolicySetting,
      },
      {
        path:
          '/new/' + ConstantsRouter.IndividualApplicationPolicySettingUpdate,
        component: IndividualApplicationPolicySetting,
      },
      {
        path: '',
        component: ApplicationTab,
        children: [
          {
            path: '/new/' + ConstantsRouter.Application,
            redirect: '/new' + ConstantsRouter.ApplicationApprovalRequest,
          },
          {
            path: '/new/' + ConstantsRouter.ApplicationApprovalRequest,
            component: ApplicationApprovalRequest,
          },
          {
            path: '/new/' + ConstantsRouter.ApplicationMyApproval,
            component: ApplicationMyApproval,
          },
        ],
      },
      {
        path: '/new/' + ConstantsRouter.AllMemberCheckTime,
        component: AllMemberCheckTime,
      },
      {
        path: '/new/' + ConstantsRouter.WaitingAllowanceApplicationCreate,
        component: WaitingAllowanceApplication,
      },
      {
        path: '/new/' + ConstantsRouter.WaitingAllowanceApplicationDelete,
        component: WaitingAllowanceApplicationDelete,
      },
      {
        path: '/new/' + ConstantsRouter.WorkRecordApplication,
        component: WorkRecordApplication,
      },
      {
        path: '/new/' + ConstantsRouter.TimeZoneSessionList,
        component: TimeZoneSessionList,
      },
      {
        name: ConstantsRouter.TimeZoneSessionUpdate,
        path: '/new' + ConstantsRouter.TimeZoneSessionUpdate,
        component: TimeZoneSessionSettingForm,
      },
      {
        name: ConstantsRouter.TimeZoneSessionCreate,
        path: '/new' + ConstantsRouter.TimeZoneSessionCreate,
        component: TimeZoneSessionSettingForm,
      },
      {
        path: '/new' + ConstantsRouter.WorkScheduleChangeApplicationCreate,
        component: WorkScheduleChangeApplication,
      },
      {
        path: '/new' + ConstantsRouter.CheckTimeApplicationCreate,
        component: CheckTimeApplication,
      },
      {
        path: '/new' + ConstantsRouter.CheckTimeApplication,
        component: CheckTimeApplication,
      },
      {
        path: '/new' + ConstantsRouter.AbsenceApplicationCreat,
        component: AbsenceApplication,
      },
      {
        path: '/new' + ConstantsRouter.AbsenceApplicationDetail,
        component: AbsenceApplication,
      },
      {
        path: '/new' + ConstantsRouter.SubstituteApplicationCreate,
        component: SubstituteApplicationCreate,
      },
      {
        path: '/new' + ConstantsRouter.TransferAttendanceApplicationDetail,
        component: TransferAttendanceApplication,
      },
      {
        path: '/new' + ConstantsRouter.TransferAttendanceApplication,
        component: TransferAttendanceApplication,
      },
      {
        path: '/new' + ConstantsRouter.SubstituteApplication,
        component: SubstituteApplicationCreate,
      },
      {
        path: '/new' + ConstantsRouter.RegisterIcCard,
        component: RegisterIcCard,
      },
      {
        path: '/new' + ConstantsRouter.WorkInHolidayApplicationDetail,
        component: WorkInHolidayApplication,
      },
      {
        path: '/new' + ConstantsRouter.WorkInHolidayApplication,
        component: WorkInHolidayApplication,
      },
      {
        path: '/new' + ConstantsRouter.TimeRecoderList,
        component: TimeRecoderList,
      },
      {
        path: '/new' + ConstantsRouter.TimeRecoder,
        component: TimeRecoder,
      },
      {
        path: '/new' + ConstantsRouter.TimeRecoderDetail,
        component: TimeRecoder,
      },
      {
        path: '/new' + ConstantsRouter.AlertSetting,
        component: AlertSettingCreate,
      },
      {
        path: '/new' + ConstantsRouter.AlertSettingDetailUpdate,
        component: AlertSettingCreate,
      },

      {
        path: '/new' + ConstantsRouter.YearPaidHoliday,
        component: YearPaidHolidayList,
      },
      {
        path: '/new' + ConstantsRouter.YearPaidHolidayCreate,
        component: YearPaidHolidayCreate,
      },
      {
        path: '/new' + ConstantsRouter.YearPaidHolidayUpdate,
        component: YearPaidHolidayCreate,
      },
    ],
  },
];
export const COMPANY = location.pathname.split('/')[1];
const router = createRouter({
  history: createWebHistory(COMPANY),
  routes,
});
router.beforeEach((to, from, next) => {
  if (
    ![
      ConstantsRouter.AuthForgotPassword,
      ConstantsRouter.AuthLogin,
      ConstantsRouter.NotFound,
    ].includes(to.path) &&
    !getCookie(PP_EMPLOYEE_ID) &&
    from.name !== 'Login' &&
    from.path !== '/'
  ) {
    handleCheckAuthen();
  } else {
    next();
  }
});
export default router;

export function showNotFound() {
  router.push({
    name: 'NotFound',
  });
}

export function showSystemError(error: Error) {
  console.error(error);
  router.push({
    name: 'SystemError',
  });
}
export function showLoginError(error?: Error) {
  console.error(error);
  if (COMPANY) {
    router.push({
      name: 'Login',
    });
  } else {
    showNotFound();
  }
}

export function showStamp() {
  router.push({
    path: '/new/stamp',
  });
}
