
import { defineComponent, ref } from 'vue';
import Table from '../components/Table.vue';
import { useI18n } from 'vue-i18n';
import router from '../router';
import Plus from '../assets/svg/Plus.svg';
import {
  deleteWorkingStyleMasterByID,
  useGetListWorkingStyleMaster,
} from '@/api/composables/useWorkingStyleMaster';
import moment from 'moment';
import { showToast } from '@/util/useToastMessage';
import { usePopupDeleteStore } from '@/store/modules/popup-delete';
import ConstantsRouter from '@/constants/router';
import Button from '@/components/Button.vue';

export default defineComponent({
  components: {
    Button,
    Table,
  },
  setup() {
    const i18n = useI18n();
    const t = i18n.t;
    const attendanceDataHeaders = [
      {
        text: i18n.t('attendanceDataHeaders.workingStyleCode'),
        value: 'working_style_code',
        width: '20%',
        align: 'center',
      },
      {
        text: i18n.t('attendanceDataHeaders.workingStyleName'),
        value: 'working_style_name',
        width: '50%',
        align: 'center',
      },
      {
        text: i18n.t('attendanceDataHeaders.operationStartDate'),
        value: 'working_style_apply_date',
        width: '15%',
        align: 'center',
      },
      {
        text: i18n.t('attendanceDataHeaders.editAction'),
        value: 'editAction',
        width: '60px',
        background: '#f2f8fc',
        align: 'center',
      },
      {
        text: i18n.t('attendanceDataHeaders.removeAction'),
        value: 'removeAction',
        width: '60px',
        background: '#f2f8fc',
        align: 'center',
      },
      {
        text: i18n.t('attendanceDataHeaders.copyAction'),
        value: 'copyAction',
        width: '60px',
        background: '#f2f8fc',
        align: 'center',
      },
    ];
    const showAllEmployementTypes = {
      label: t('showAllWorkingStyleTypes'),
    };
    const goToRegisterWorkingStyleMaster = () => {
      router.push(ConstantsRouter.WorkingStyleMasterRegister);
    };
    const updateWorkingStyleMaster = (item) => {
      router.push({
        path: ConstantsRouter.WorkingStyleMasterUpdate,
        query: { id: item.id },
      });
    };
    const store = usePopupDeleteStore();
    const removeItem = (item) => {
      store.dispatch('show', {
        isShown: true,
        onSubmit: () => deleteAction(item.id),
      });
    };
    const deleteAction = async (id) => {
      const { deleteData } = deleteWorkingStyleMasterByID(id);
      deleteData
        .then((error) => {
          if (error) {
            showToast('error', t('deteleWorkingStyleMasterFailure'));
          } else {
            if (
              workingStyleMasterData.value?.length === 1 &&
              apiWorkingStyleMasterListParams.value.page > 1
            ) {
              apiWorkingStyleMasterListParams.value.page -= 1;
            }
            getWorkingStyleMasterList(apiWorkingStyleMasterListParams.value);
            showToast('success', t('deteleWorkingStyleMasterSuccessfully'));
          }
        })
        .catch(() => {
          showToast('error', t('errors.serverError'));
        });
    };
    const apiWorkingStyleMasterListParams = ref({
      keyword: '',
      page: 1,
    });
    const {
      workingStyleMasterData,
      loading,
      pagination,
      getWorkingStyleMasterList,
    } = useGetListWorkingStyleMaster(apiWorkingStyleMasterListParams.value);
    const onChangePage = () => {
      getWorkingStyleMasterList(apiWorkingStyleMasterListParams.value);
    };
    const displayDate = (date) => {
      return (
        moment(date).format('YYYY') +
        t('year') +
        moment(date).format('MM') +
        t('month')
      );
    };
    const copyWorkingStyleMaster = (item) => {
      router.push({
        path: ConstantsRouter.WorkingStyleMasterCopy,
        query: { id: item.id },
      });
    };
    return {
      t,
      Plus,
      loading,
      apiWorkingStyleMasterListParams,
      workingStyleMasterData,
      getWorkingStyleMasterList,
      pagination,
      attendanceDataHeaders,
      showAllEmployementTypes,
      removeItem,
      goToRegisterWorkingStyleMaster,
      deleteAction,
      close,
      displayDate,
      onChangePage,
      updateWorkingStyleMaster,
      copyWorkingStyleMaster,
    };
  },
});
