<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.342 3.47489L13.4081 7.40882L13.9384 10.0605L16.5901 10.5908L20.524 6.65687L20.5244 6.65671C21.0113 7.80611 21.1278 9.07873 20.8575 10.2974C20.5873 11.5161 19.9439 12.6202 19.0167 13.4561C18.0896 14.2919 16.925 14.818 15.6849 14.9609C14.4448 15.1039 13.191 14.8566 12.098 14.2536L12.0982 14.2534L6.84008 20.3408C6.41804 20.7624 5.84588 20.9991 5.24936 20.9989C4.65284 20.9987 4.08081 20.7617 3.65901 20.3399C3.23721 19.9181 3.00017 19.3461 3 18.7495C2.99983 18.153 3.23654 17.5809 3.6581 17.1588L9.74542 11.9008L9.74529 11.9009C9.14229 10.8079 8.89505 9.5541 9.03799 8.31402C9.18094 7.07394 9.70695 5.90929 10.5428 4.98216C11.3787 4.05504 12.4828 3.41158 13.7015 3.14135C14.9202 2.87113 16.1928 2.98758 17.3422 3.47451L17.342 3.47489Z"
      stroke="#333333"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
