import { createApp } from 'vue';
import App from '@/App.vue';
import router from '@/router';
import { store } from '@/store';
import i18n from '@/i18n';
import '@/assets/index.css';
import '@/assets/styles/styles.scss';
import VCalendar from 'v-calendar';
import copyAction, { key as keycopyAction } from '@/store/modules/copy-action';
import usePopupDeleteStore, {
  key as keyPopupDel,
} from './store/modules/popup-delete';
import useLoading, { key as keyLoading } from './store/modules/use-loading';
import { clickOutside } from '@/util/useClickOutside';
import { tooltip } from '@/util/useTooltip';

createApp(App)
  .directive('tooltip', tooltip)
  .directive('click-outside', clickOutside)
  .use(router)
  .use(store)
  .use(usePopupDeleteStore, keyPopupDel)
  .use(useLoading, keyLoading)
  .use(VCalendar, {})
  .use(i18n)
  .use(copyAction, keycopyAction)
  .mount('#app');
