
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';
import router from '@/router';
import ConstantsRouter from '@/constants/router';

export default defineComponent({
  setup() {
    const i18n = useI18n();
    const dataTabs = [
      {
        text: i18n.t('leaveMaster'),
        path: '',
      },
      {
        text: i18n.t('scheduledLaborMaster'),
        path: ConstantsRouter.Holidays,
        name: ConstantsRouter.ScheduledLaborMaster,
      },
      {
        text: i18n.t('holidayMaster'),
        path: ConstantsRouter.WorkPatternMaster,
        name: ConstantsRouter.WorkPatternMaster,
      },
      {
        text: i18n.t('workingStyleMaster'),
        path: ConstantsRouter.WorkingStyleMaster,
        name: ConstantsRouter.WorkingStyleMaster,
      },
      {
        text: i18n.t('timezoneDivisionMaster'),
        path: ConstantsRouter.Timezone,
        name: ConstantsRouter.Timezone,
      },
      {
        text: i18n.t('timeZoneDivisionGroup'),
        path: ConstantsRouter.TimezoneGroup,
        name: ConstantsRouter.TimezoneGroup,
      },
    ];
    const toggleTabs = (item) => {
      router.push(item.path);
    };
    const currentRouter = router.currentRoute;
    return {
      t: i18n.t,
      dataTabs,
      currentRouter,
      toggleTabs,
    };
  },
});
