
import { defineComponent, onBeforeMount } from 'vue';
import { useI18n } from 'vue-i18n';
import Label from '@/components/Label.vue';
import Input from '@/components/Input.vue';
import MyTable from '@/components/Table.vue';
import Plus from '@/assets/svg/Plus.svg';
import { useStore } from '@/store';
import { ref, watchEffect } from '@vue/runtime-core';

import { ActionType } from '@/util/composables';
import { TypeErrors } from '@/store/modules/root/state';
import router from '@/router';
import Button from '@/components/Button.vue';
import { LocationQueryValue } from 'vue-router';
import ConstantsRouter from '@/constants/router';
import getDataTimezoneListData from '@/api/composables/timezoneList';
import { showToast } from '@/util/useToastMessage';
import {
  handleUpdateTimezoneGroup,
  timezoneGroupRegister,
  useTimezoneGroupDetailSetting,
} from '@/api/composables/timezoneGroup';
import { map } from 'lodash';
import { TimezoneApiGetListResult } from '@/api/types/TimezoneList';
export default defineComponent({
  components: {
    MyTable,
    Input,
    Label,
    Button,
  },
  setup() {
    const i18n = useI18n();
    const store = useStore();
    const actionType = ref<string | LocationQueryValue[]>(ActionType.CREATE);
    const timezoneGroupId = router.currentRoute.value.params.id;
    const typeAction = router.currentRoute.value.query.action;
    if (timezoneGroupId && typeAction) {
      actionType.value = typeAction;
    }
    const {
      timezoneGroupDetail,
      getTimezoneGroupDetailSettings,
    } = useTimezoneGroupDetailSetting();

    if (timezoneGroupId) {
      getTimezoneGroupDetailSettings(timezoneGroupId);
    }
    const timezoneHeaders = [
      {
        text: '',
        value: 'checkbox',
        width: '10%',
      },
      {
        text: i18n.t('timezoneDivisionCode'),
        value: 'time_zone_session_type_code',
        width: '45%',
      },
      {
        text: i18n.t('timezoneDivisionName'),
        value: 'time_zone_session_type_name',
        width: '45%',
      },
    ];
    const errors = ref({});
    const listErrorServerReturn = ref({});
    const validated = ref(false);
    const isValid = () => {
      validated.value = true;
      store.commit('root/SET_VALIDATE', [TypeErrors.required]);
      for (const property in errors.value) {
        if (errors.value[property].length > 0) {
          return false;
        }
      }
      return true;
    };
    let params = ref({
      time_zone_session_group_code: '',
      time_zone_session_group_name: '',
      time_zone_session_type_ids: [''],
    });
    const timezoneDataSelects = ref<TimezoneApiGetListResult[]>([]);

    watchEffect(() => {
      if (
        timezoneGroupId &&
        timezoneGroupDetail.value &&
        timezoneData.value &&
        count.value === 0
      ) {
        timezoneDataSelects.value = [];
        params.value = {
          ...params.value,
          time_zone_session_group_code:
            timezoneGroupDetail.value.time_zone_session_group_code,
          time_zone_session_group_name:
            timezoneGroupDetail.value.time_zone_session_group_name,
        };
        const listIdSelect = map(
          timezoneGroupDetail.value.time_zone_session_types,
          'id'
        );
        timezoneData.value.map((el) => {
          if (listIdSelect.includes(el.id)) {
            timezoneDataSelects.value.push(el);
          }
        });
        count.value += 1;
      }
    });
    const createTimezoneGroup = () => {
      params.value = {
        ...params.value,
        time_zone_session_type_ids: map([...timezoneDataSelects.value], 'id'),
      };
      if (!isValid()) return;
      const { registered } = timezoneGroupRegister(params.value);
      registered
        .then((error) => {
          if (error) {
            listErrorServerReturn.value = error.errors.value;
            if (error?.errorsCommon.value) {
              showToast('error', error?.errorsCommon.value);
            }
          } else {
            listErrorServerReturn.value = '';
            showToast('success', i18n.t('timezoneGroupHasBeenCreated'));
            handleBackToTheList();
          }
        })
        .catch(() => {
          showToast('error', i18n.t('errors.serverError'));
        });
    };
    const submitTimezoneGroup = (id) => {
      params.value = {
        ...params.value,
        time_zone_session_type_ids: map([...timezoneDataSelects.value], 'id'),
      };
      if (!isValid()) return;
      const { update } = handleUpdateTimezoneGroup(id, params.value);
      update
        .then((error) => {
          if (error) {
            listErrorServerReturn.value = error.errors.value;
            if (error?.errorsCommon.value) {
              showToast('error', error?.errorsCommon.value);
            }
          } else {
            store.commit('root/SET_NOTIFICATION', {
              type: 'success',
              message: i18n.t('timezoneDivisionGroupUpdated'),
            });
            router.push(ConstantsRouter.TimezoneGroup);
          }
        })
        .catch(() => {
          showToast('error', i18n.t('errors.serverError'));
        });
      if (!isValid()) return;
    };
    const handleSubmit = () => {
      if (actionType.value === ActionType.CREATE) {
        createTimezoneGroup();
      } else {
        submitTimezoneGroup(timezoneGroupId);
      }
    };
    onBeforeMount(() => {
      store.commit('root/SET_BACK_ROUTER_PATH', ConstantsRouter.TimezoneGroup);
      store.commit(
        'root/SET_CURRENT_PAGE',
        i18n.t('timezoneDivisionGroupSetting')
      );
    });
    const count = ref(0);

    const handleBackToTheList = () => {
      router.push(ConstantsRouter.TimezoneGroup);
    };
    const apiTimezoneListParams = ref({ is_all: true });
    const { timezoneData } = getDataTimezoneListData(
      apiTimezoneListParams.value
    );

    return {
      t: i18n.t,
      isValid,
      handleSubmit,
      handleBackToTheList,
      timezoneHeaders,
      Plus,
      errors,
      listErrorServerReturn,
      validated,
      count,
      params,
      timezoneData,
      timezoneDataSelects,
      timezoneGroupDetail,
    };
  },
});
