
import { ref, toRefs, watch, watchEffect } from 'vue';
import { useI18n } from 'vue-i18n';
import { defineComponent, onBeforeUnmount } from '@vue/runtime-core';
import { DATE_FORMAT } from '@/constants/date';
import moment from 'moment';
import { isEmpty } from '@/util/useValidateForm';
import DateInput from '@/components/share/Calendar/DateInput.vue';
import MonthInput from '@/components/share/Calendar/MonthInput.vue';

export default defineComponent({
  components: { MonthInput, DateInput },
  props: {
    width: {
      type: String,
    },
    format: {
      type: String,
      default: DATE_FORMAT,
    },
    isIconChevronLeft: {
      type: Boolean,
      default: false,
    },
    isIconChevronRight: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'date',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    validated: {
      type: Boolean,
      default: false,
    },
    errorServer: {
      type: Array,
      default: () => [],
    },
    required: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    isFixed: {
      type: Boolean,
      default: false,
    },
    heightToAlwayBot: {
      type: Number,
      default: 0,
    },
  },
  setup(props, ctx) {
    const i18n = useI18n();
    const t = i18n.t;
    const { format, type, modelValue } = toRefs(props);
    const date = ref(modelValue.value);

    const onNextDate = (value) => {
      if (isEmpty(date.value)) return;
      if (type.value === 'date') {
        date.value = moment(date.value).add(value, 'days').format(format.value);
      }
      if (type.value === 'month') {
        date.value = moment(date.value)
          .add(value, 'months')
          .format(format.value);
      }
    };

    watch(date, () => {
      ctx.emit('update:modelValue', date.value);
    });
    watch(props, () => {
      date.value = modelValue.value;
    });

    const errorMessage = ref();
    watchEffect(() => {
      ctx.emit('update:errors', errorMessage.value);
    });
    onBeforeUnmount(() => {
      ctx.emit('update:errors', '');
    });

    return {
      t,
      date,
      errorMessage,
      onNextDate,
      DATE_FORMAT,
    };
  },
});
