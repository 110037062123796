
import { defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import Popup from '@/components/Popup.vue';

export default defineComponent({
  components: { Popup },
  setup() {
    const i18n = useI18n();
    const isShow = ref(true);
    const handleCancel = () => {
      isShow.value = false;
    };
    return {
      t: i18n.t,
      isShow,
      handleCancel,
    };
  },
});
