
import { defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import Popup from '@/components/share/Modal/index.vue';
import Button from '@/components/share/Button/index.vue';
import WarningIcon from '@/components/Icons/WarningIcon.vue';
import SpinnerIcon from '@/components/Icons/SpinnerIcon.vue';

export default defineComponent({
  components: { SpinnerIcon, WarningIcon, Button, Popup },
  props: {
    file: {},
    percentage: {
      type: Number,
      default: 0,
    },
    importShiftPlanDraftDetail: {
      default: {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    const i18n = useI18n();
    const isShow = ref(false);

    const closePopup = () => {
      isShow.value = false;
      emit('onClose');
    };
    const onSubmit = () => {
      emit('onSubmit');
    };

    return {
      t: i18n.t,
      isShow,
      onSubmit,
      closePopup,
    };
  },
});
