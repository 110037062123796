import api, { ApiResponse } from '@/clients/api';
import { ErrorCode } from './types/general/ErrorCode';
import {
  JSONApiResponseBody,
  JSONApiResponseErrorBody,
} from '@/api/types/general/JSONApiResponse';
import ConstantsAPI from '@/constants/api';
import { ShiftPlanSubmit, ShiftPlanId } from './types/ShiftPlanSubmit';

export async function postShiftPlanSubmit(
  postData: ShiftPlanSubmit
): Promise<
  | ApiResponse<200, JSONApiResponseBody<ShiftPlanId>>
  | ApiResponse<400, JSONApiResponseErrorBody<ErrorCode>>
  | ApiResponse<500, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.post(ConstantsAPI.ShiftPlanSubmit, postData);
}
