
import DatePickerIcon from '@/assets/svg/DatePickerIcon.svg';
import { computed, ref, toRefs, watch, watchEffect } from 'vue';
import iconChevronLeft from '@/assets/svg/iconChevronLeftSingle.svg';
import iconChevronRight from '@/assets/svg/iconChevronRightSingle.svg';
import moment from 'moment';
import { useI18n } from 'vue-i18n';
import DateInput from '@/components/DateInput.vue';
import MonthInput from '@/components/MonthInput.vue';
import { isEmpty, isValidFormatDate } from '@/util/useValidateForm';
export default {
  name: 'DatePicker',
  components: { MonthInput, DateInput },
  props: {
    width: { type: String },
    format: { type: String, default: 'YYYY/MM' },
    isIconChevronLeft: {
      type: Boolean,
      default: false,
    },
    isIconChevronRight: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'date',
    },
    isRange: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    justifyCenter: {
      type: Boolean,
      default: false,
    },
    defaultValue: {
      type: String,
    },
    validated: {
      type: Boolean,
      default: false,
    },
    errorServer: {
      type: String,
      default: '',
    },
    error: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    defaultNull: {
      type: Boolean,
      default: false,
    },
    modelValue: { type: String, default: '' },
    placeholder: {
      type: String,
      default: '',
    },
    personalSetting: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, ctx) {
    const i18n = useI18n();
    const {
      width,
      format,
      type,
      isRange,
      required,
      error,
      modelValue,
    } = toRefs(props);
    const masks = {
      input: format.value,
    };
    const styleInput = computed(() => `${width.value}px`);
    const rangeValue = ref({
      start: new Date(),
      end: new Date(),
    });
    const date = ref(modelValue.value);
    if (isRange.value) {
      date.value = rangeValue.value;
    }
    const onNextDate = (value) => {
      if (type.value === 'date') {
        date.value = moment(date.value).add(value, 'days').format(format.value);
      }
      if (type.value === 'month') {
        date.value = moment(date.value)
          .add(value, 'months')
          .format(format.value);
      }
    };
    const onDayClick = (date) => {
      ctx.emit('update:modelValue', moment(date.id).format(format.value));
    };
    const handleChange = (event) => {
      if (!event.target.value) {
        ctx.emit('update:modelValue', '');
      }
    };
    const errorMessage = ref(error.value);
    const checkRequired = () => {
      if (required.value && isEmpty(date.value)) {
        errorMessage.value = i18n.t('errors.required');
      } else if (
        required.value &&
        !isValidFormatDate(date.value, format.value)
      ) {
        errorMessage.value = i18n.t('dateFormatError');
      } else {
        errorMessage.value = '';
      }
      ctx.emit('update:errors', errorMessage.value);
    };
    watchEffect(() => {
      checkRequired();
    });
    watch(date, () => {
      ctx.emit('update:modelValue', date.value);
    });
    watch(props, () => {
      date.value = modelValue.value;
    });
    return {
      masks,
      DatePickerIcon,
      styleInput,
      iconChevronLeft,
      iconChevronRight,
      rangeValue,
      errorMessage,
      date,
      onDayClick,
      checkRequired,
      onNextDate,
      handleChange,
    };
  },
};
