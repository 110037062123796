import { getListMyTimeCardByMonthly } from '@/api/myTimeCardByMonthly';
import { ref } from 'vue';
import { MyTimeCardByMonthly } from '@/model/MyTimeCardByMonthly';

export function useGetListMyTimeCardMonthly() {
  const detailTimeCardMonthly = ref<MyTimeCardByMonthly>({});
  const loading = ref(false);

  async function getDetailMyTimeCard(id, params) {
    try {
      loading.value = true;
      const res = await getListMyTimeCardByMonthly(id, params);
      const response = ref();
      switch (res.status) {
        case 200:
          response.value = res.data;
          detailTimeCardMonthly.value = response.value.data;
          break;
        default:
          detailTimeCardMonthly.value = {};
          break;
      }
    } catch (error) {
      console.error(error);
      return {};
    } finally {
      loading.value = false;
    }
  }

  return { detailTimeCardMonthly, loading, getDetailMyTimeCard };
}
