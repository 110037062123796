
import { defineComponent, ref, toRefs } from '@vue/runtime-core';
import iconUpload from '@/assets/svg/iconUpload.svg';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  props: {
    placeholder: {
      type: String,
    },
    modelValue: {},
    multiple: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {},
  setup(props, { emit }) {
    const i18n = useI18n();
    const { multiple } = toRefs(props);
    const fileUpload = ref<HTMLInputElement>();
    const fileName = ref(i18n.t('noFileChosen'));
    const onClick = () => {
      fileUpload.value?.click();
    };
    const onChangeFile = (e) => {
      if (multiple.value && e.target.files.length > 1) {
        emit('update:modelValue', e.target.files);
        fileName.value = e.target.files.length + ' ' + i18n.t('files');
      } else {
        emit('update:modelValue', e.target.files[0]);
        fileName.value = e.target.files[0].name;
      }
      emit('onChange');
    };
    return {
      fileUpload,
      iconUpload,
      fileName,
      onChangeFile,
      onClick,
    };
  },
});
