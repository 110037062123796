import api, { ApiResponse } from '@/clients/api';
import { ErrorCode } from './types/general/ErrorCode';
import {
  JSONApiResponseBody,
  JSONApiResponseErrorBody,
} from './types/general/JSONApiResponse';
import ConstantsAPI from '@/constants/api';
import {
  AllMemberTimeCardApiGetParams,
  AllMemberTimeCardApiGetResult,
} from '@/api/types/AllMemberTimeCardApi';

export async function getAllMemberTimeCard(
  params: AllMemberTimeCardApiGetParams
): Promise<
  | ApiResponse<200, JSONApiResponseBody<AllMemberTimeCardApiGetResult[]>>
  | ApiResponse<404, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.get(ConstantsAPI.AllMemberTimeCard, {
    params,
  });
}
