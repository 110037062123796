import { ref } from 'vue';
import {
  getListShiftPlan,
  getRequirementPersonnelRegisterShift,
  postManagerRegisterShiftForPersonnel,
  putChangeConfirmationStatusShift,
  putChangeNotificationStatusShift,
} from '@/api/shiftPlan';
import {
  ChangeConfirmationStatusShiftParam,
  ChangeNotificationStatusShiftParam,
  ManagerRegisterShiftForPersonnelApiPostParams,
  RequirementPersonnelRegisterShift,
  ShiftPlanSearchParams,
} from '@/api/types/ShiftPlan';
import { convertErrorFromServerToJson } from '@/util/useValidateForm';

export default function () {
  const shiftPlanList = ref();
  const pagination = ref({
    current_page: 1,
    per_page: 20,
    total: 0,
  });
  const response = ref();

  async function getListPlanListData(params: ShiftPlanSearchParams) {
    try {
      const res = await getListShiftPlan(params);
      switch (res.status) {
        case 200:
          shiftPlanList.value = res.data.data;
          response.value = res.data;
          pagination.value = response.value.meta.pagination;
          break;
        default:
          shiftPlanList.value = [];
          break;
      }
    } catch (error) {
      console.error(error);
    }
  }

  return {
    pagination,
    shiftPlanList,
    getListPlanListData,
  };
}

export function useGetRequirementPersonnelRegisterShiftDetailData() {
  const requirementPersonnelRegisterShiftDetail = ref<RequirementPersonnelRegisterShift>(
    {
      shift_plan_employees: [],
      target_dates: [],
    }
  );

  async function getRequirementPersonnelRegisterShiftData(id: string) {
    try {
      const res = await getRequirementPersonnelRegisterShift(id);
      switch (res.status) {
        case 200:
          requirementPersonnelRegisterShiftDetail.value = res.data.data;
          break;
        default:
          break;
      }
    } catch (error) {
      console.error(error);
    }
  }

  return {
    requirementPersonnelRegisterShiftDetail,
    getRequirementPersonnelRegisterShiftData,
  };
}

export function usePostManagerRegisterShiftForPersonnel(
  data: ManagerRegisterShiftForPersonnelApiPostParams
) {
  const errors = ref({});
  const errorsCommon = ref<string>();
  return {
    registered: (async () => {
      const response = await postManagerRegisterShiftForPersonnel(data);
      switch (response.status) {
        case 200:
          return null;
        default:
          if (response.data.errors[0].source) {
            errors.value = convertErrorFromServerToJson(response.data.errors);
          } else {
            errorsCommon.value = response.data.errors[0].title;
          }
          return {
            errors,
            errorsCommon,
          };
      }
    })(),
  };
}

export function useChangeConfirmationStatusShift(
  data: ChangeConfirmationStatusShiftParam
) {
  const errors = ref({});
  const errorsCommon = ref<string>();
  return {
    updated: (async () => {
      const response = await putChangeConfirmationStatusShift(data);
      switch (response.status) {
        case 200:
          return null;
        default:
          if (response.data.errors[0].source) {
            errors.value = convertErrorFromServerToJson(response.data.errors);
          } else {
            errorsCommon.value = response.data.errors[0].title;
          }
          return {
            errors,
            errorsCommon,
          };
      }
    })(),
  };
}

export function useChangeNotificationStatusShift(
  data: ChangeNotificationStatusShiftParam
) {
  const errors = ref({});
  const errorsCommon = ref<string>();
  return {
    updated: (async () => {
      const response = await putChangeNotificationStatusShift(data);
      switch (response.status) {
        case 200:
          return null;
        default:
          if (response.data.errors[0].source) {
            errors.value = convertErrorFromServerToJson(response.data.errors);
          } else {
            errorsCommon.value = response.data.errors[0].title;
          }
          return {
            errors,
            errorsCommon,
          };
      }
    })(),
  };
}
