
import { reactive, computed } from 'vue';

interface PropTypes {
  size: string;
  isBadge: boolean;
  width: string;
  height: string;
  id: string;
  cursor: string;
}
export default {
  name: 'Avatar',
  props: {
    src: {
      type: String,
    },
    name: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      validator: function (value: string) {
        return ['tiny', 'small', 'medium', 'large'].indexOf(value) !== -1;
      },
      default: 'medium',
    },
    isBadge: {
      type: Boolean,
      default: false,
    },
    width: String,
    height: {
      type: String,
    },
    cursor: {
      type: String,
      validator: function (value: string) {
        return ['pointer', 'default'].indexOf(value) !== -1;
      },
      default: 'pointer',
    },
  },
  emits: ['click'],
  setup(props: Partial<PropTypes>, { emit }) {
    props = reactive(props);

    return {
      classes: computed(() => ({
        'inline object-cover rounded-full cursor-pointer avatar_wrapper': true,
        large: props.size === 'large',
        medium: props.size === 'medium',
        small: props.size === 'small',
        tiny: props.size === 'tiny',
        'inline-block': props.isBadge,
      })),
      classesBadge: computed(() => ({
        'absolute bottom-0 right-0 inline-block w-3 h-3 bg-green-600 border-2 border-white rounded-full':
          props.isBadge,
      })),
      classesName: computed(() => ({
        ' object-cover rounded-full cursor-pointer avatar_wrapper flex justify-center items-center text-white font-bold': true,
        large: props.size === 'large',
        medium: props.size === 'medium',
        small: props.size === 'small',
        tiny: props.size === 'tiny',
      })),
      styles: computed(() => ({
        width: props.width,
        height: props.height,
        cursor: props.cursor,
      })),
      backgroundName: computed(
        () => `pp_avatar-color-${props.id ? parseInt(props.id, 16) % 7 : 6}`
      ),
      onClick() {
        emit('click');
      },
    };
  },
};
