export function objectToFormData(obj) {
  const formData = new FormData();
  for (const rootKey in obj) {
    if (Array.isArray(obj[rootKey])) {
      obj[rootKey].forEach((item, index) => {
        for (const itemKey in item) {
          formData.append(`${rootKey}[${index}][${itemKey}]`, item[itemKey]);
        }
      });
    } else if (typeof obj[rootKey] === 'object') {
      for (const keyObject in obj[rootKey]) {
        formData.append(`${rootKey}[${keyObject}]`, obj[rootKey][keyObject]);
      }
    } else {
      formData.append(rootKey, obj[rootKey]);
    }
  }

  return formData;
}
