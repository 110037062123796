import { InjectionKey } from '@vue/runtime-core';
import { createStore, Store } from 'vuex';

export interface State {
  myAccount: {
    employee_name: string;
    pp_employee_id: string;
    employee_code: string;
    unit_id: string;
    unit_name: string;
  };
}

export const key: InjectionKey<Store<State>> = Symbol();

export function useLoginAccountStore() {
  return store;
}

export const store = createStore<State>({
  state() {
    return {
      myAccount: {
        employee_name: '',
        employee_code: '',
        pp_employee_id: '',
        unit_id: '',
        unit_name: '',
      },
    };
  },
  mutations: {
    setLoginAccount(state, user) {
      state.myAccount = user;
    },
  },
  actions: {},
});
export default store;
