import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex items-center mb-8" }
const _hoisted_2 = { class: "mr-4" }
const _hoisted_3 = { class: "mr-4" }
const _hoisted_4 = { class: "text-xs text-gray-600" }
const _hoisted_5 = { class: "text-sm font-bold" }
const _hoisted_6 = { class: "mr-4" }
const _hoisted_7 = { class: "text-xs text-gray-600" }
const _hoisted_8 = { class: "text-sm" }
const _hoisted_9 = { class: "" }
const _hoisted_10 = { class: "text-xs text-gray-600" }
const _hoisted_11 = { class: "text-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Avatar = _resolveComponent("Avatar")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode(_component_Avatar, {
        id: _ctx.userInfo?.pp_employee_id,
        name: _ctx.nameAvatar[0]?.profileFile ? '' : _ctx.nameAvatar[0]?.nameFaceImage,
        src: _ctx.nameAvatar[0]?.profileFile
      }, null, 8, ["id", "name", "src"])
    ]),
    _createVNode("div", _hoisted_3, [
      _createVNode("div", _hoisted_4, _toDisplayString(_ctx.t('fullName')), 1),
      _createVNode("div", _hoisted_5, _toDisplayString(_ctx.userInfo?.employee_name), 1)
    ]),
    _createVNode("div", _hoisted_6, [
      _createVNode("div", _hoisted_7, _toDisplayString(_ctx.t('department')), 1),
      _createVNode("div", _hoisted_8, _toDisplayString(_ctx.userInfo?.unit_name), 1)
    ]),
    _createVNode("div", _hoisted_9, [
      _createVNode("div", _hoisted_10, _toDisplayString(_ctx.t('timeCardNew.employeeNumber')), 1),
      _createVNode("div", _hoisted_11, _toDisplayString(_ctx.userInfo?.employee_code), 1)
    ])
  ]))
}