import { InjectionKey } from '@vue/runtime-core';
import { createStore, Store } from 'vuex';

export interface State {
  idCard: string;
  isConnect: boolean;
}

export const key: InjectionKey<Store<State>> = Symbol();

export function useIcCardStore() {
  return store;
}

export const store = createStore<State>({
  state() {
    return {
      idCard: '',
      isConnect: false,
    };
  },
  mutations: {
    setIcCard(state, payload) {
      state.idCard = payload;
    },
    establishConnect(state) {
      state.isConnect = true;
    },
    disConnect(state) {
      state.isConnect = false;
    },
  },
  actions: {},
});
export default store;
