import {
  IndividualApplicationPolicySettingPostApiParams,
  IndividualApplicationPolicySettingGetIdParams,
} from '@/api/types/IndividualApplicationPolicySetting';
import { ref } from 'vue';
import {
  createPersonalSettingApply,
  getListPersonalSettingApply,
  updatePersonalSettingApply,
  getListDetailPersonalSettingApply,
  deletePersonalSettingApply,
} from '@/api/personalSettingApply';
import { convertErrorFromServerToJson } from '@/util/useValidateForm';

export default function (params) {
  const personalSettingApplyList = ref<
    IndividualApplicationPolicySettingGetIdParams[]
  >([]);
  const pagination = ref({});
  const loading = ref(false);

  async function getPersonalSettingApplyList(params) {
    try {
      loading.value = true;
      const res = await getListPersonalSettingApply(params);

      switch (res.status) {
        case 200:
          personalSettingApplyList.value = res.data.data;
          pagination.value = res.data.meta ? res.data.meta.pagination : {};
          break;
        default:
          personalSettingApplyList.value = [];
          pagination.value = {};
          break;
      }
    } catch (error) {
      console.error(error);
    } finally {
      loading.value = false;
    }
  }

  getPersonalSettingApplyList(params);

  return {
    personalSettingApplyList,
    getPersonalSettingApplyList,
    loading,
    getListPersonalSettingApply,
    pagination,
  };
}

export function useCreatePersonalSettingApply(
  params: IndividualApplicationPolicySettingPostApiParams
) {
  const loadingCreate = ref(false);
  const errors = ref({});
  const errorsCommon = ref<string>();
  return {
    create: (async () => {
      loadingCreate.value = true;
      const response = await createPersonalSettingApply(params);
      switch (response.status) {
        case 200:
          return null;
        default:
          if (response.data.errors[0].source) {
            errors.value = convertErrorFromServerToJson(response.data.errors);
          } else {
            errorsCommon.value = response.data.errors[0].title;
          }
          loadingCreate.value = false;
          return {
            errors,
            errorsCommon,
          };
      }
    })(),
  };
}

export function useUpdatePersonalSettingApply(
  id: string,
  params: IndividualApplicationPolicySettingPostApiParams
) {
  const errors = ref({});
  const errorsCommon = ref<string>();
  return {
    update: (async () => {
      const response = await updatePersonalSettingApply(id, params);
      switch (response.status) {
        case 200:
          return null;
        default:
          if (response.data.errors[0].source) {
            errors.value = convertErrorFromServerToJson(response.data.errors);
          } else {
            errorsCommon.value = response.data.errors[0].title;
          }
          return {
            errors,
            errorsCommon,
          };
      }
    })(),
  };
}
export function useGetDetailPersonalSettingApply(id) {
  const personalSettingApplyDetail = ref();
  const loadingDetailPersonalSettingApply = ref(false);

  async function getDetail(id) {
    if (!id) return;
    try {
      loadingDetailPersonalSettingApply.value = true;
      const res = await getListDetailPersonalSettingApply(id);
      switch (res.status) {
        case 200:
          personalSettingApplyDetail.value = res.data.data;
          break;
        default:
          personalSettingApplyDetail.value = {};
          break;
      }
    } catch (error) {
      console.error(error);
    } finally {
      loadingDetailPersonalSettingApply.value = false;
    }
  }

  getDetail(id);
  return {
    personalSettingApplyDetail,
    loadingDetailPersonalSettingApply,
    getDetail,
  };
}
export function useDeletePersonalSettingApply(id) {
  return {
    deleteData: (async () => {
      const response = await deletePersonalSettingApply(id);
      switch (response.status) {
        case 200:
          return null;
        default:
          return response.data.errors[0].title;
      }
    })(),
  };
}
