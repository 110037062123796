
import { computed, defineComponent, onMounted, ref } from 'vue';
import '@/views/layouts/layout.scss';
import MyButton from '@/components/Button.vue';
import ToastApp from '@/components/share/Toast/index.vue';
import Badges from '@/components/Badges.vue';
import Avatar from '@/components/Avatar.vue';
import { useI18n } from 'vue-i18n';
import nameCompanyLogo from '@/assets/svg/nav/name-company.svg';
import Ring from '@/assets/svg/nav/ring.svg';
import { useStore } from '@/store';
import backNavigation from '@/assets/svg/nav/backNavigation.svg';
import { handleUserInfor } from '@/api/composables/login';
import {
  deleteCookie,
  handleCheckAuthen,
  PP_EMPLOYEE_ID,
  SUB_DOMAIN,
} from '@/util/composables';
import { useLoginAccountStore } from '@/store/modules/login-account';
import { getNavigationMenusByParentId } from '@/util/useFunctionDefine';
import { useApplySettingForSystemStore } from '@/store/modules/apply-setting-for-system';
import BaseIcon from '@/components/Icons/BaseIcon.vue';
import router from '@/router';

export default defineComponent({
  components: {
    BaseIcon,
    MyButton,
    Badges,
    Avatar,
    ToastApp,
  },
  setup() {
    const isShowView = ref(!SUB_DOMAIN);
    const accountStore = useLoginAccountStore();
    handleUserInfor().then((user) => {
      if (user) {
        isShowView.value = true;
        accountStore.commit('setLoginAccount', user.value);
      }
    });

    const i18n = useI18n();
    const t = i18n.t;
    const store = useStore();
    const profile = computed(() => store.state.auth.profile);
    const currentPage = computed(() => store.state.root.currentPage);
    const backRouterPath = computed(() => store.state.root.backRouterPath);

    const nameCompany = {
      label: t('nameCompany'),
      fontSize: '14',
      icon: {
        src: nameCompanyLogo,
        alt: t('nameCompany'),
      },
      fontWeight: 'bold',
      color: '#333333',
      path: '/',
    };
    const applySettingForSystemStore = useApplySettingForSystemStore();
    const navMenu = computed(() => {
      return getNavigationMenusByParentId(
        applySettingForSystemStore.state.functionDefines.function_defines,
        null
      );
    });

    const isActiveNavMenu = (path) =>
      path && router.currentRoute.value.path.includes(path);

    const goToPage = (nav) => {
      router.push(nav.path);
    };
    const backRoute = () => {
      router.push(backRouterPath.value);
    };

    const isShowPopupProfile = ref(false);
    onMounted(() => {
      const btn = document.querySelector('.mobile-menu-button');
      const sidebar = document.querySelector('.sidebar');
      // add our event listener for the click
      if (btn && sidebar) {
        btn.addEventListener('click', () => {
          sidebar.classList.toggle('-translate-x-full');
        });
      }
    });
    const handleToglePopupProfile = () => {
      isShowPopupProfile.value = !isShowPopupProfile.value;
    };
    const handleHiddenPopupProfile = () => {
      isShowPopupProfile.value = false;
    };
    const handleLogout = () => {
      deleteCookie(PP_EMPLOYEE_ID);
      const isLogout = true;
      handleCheckAuthen(isLogout);
    };

    return {
      t,
      router,
      backRouterPath,
      backNavigation,
      store,
      Ring,
      nameCompany,
      profile,
      currentPage,
      isShowView,
      navMenu,
      isShowPopupProfile,
      goToPage,
      handleToglePopupProfile,
      handleHiddenPopupProfile,
      handleLogout,
      isActiveNavMenu,
      backRoute,
    };
  },
});
