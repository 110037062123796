
import { defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import router from '@/router';
import ConstantsRouter from '@/constants/router';
import { getSearchEmploy } from '@/api/composables/useGetSearchEmployee';
import { useGetListRole } from '@/api/composables/useRole';
import { useGetDetailAppPersonalFlowSetting } from '@/api/composables/approvalSettings';
import { computed } from '@vue/runtime-core';
import { useRoute } from 'vue-router';
import { usePersonalFlowStore } from '@/store/modules/personal-flows';
import PersonalFlowElement from './PersonalFlowElement.vue';
import BackPreviousPage from '@/components/share/BackPreviousPage/index.vue';

export default defineComponent({
  components: {
    PersonalFlowElement,
    BackPreviousPage,
  },
  setup() {
    const i18n = useI18n();
    const t = i18n.t;
    const employeeList = ref([]);
    getSearchEmploy({}).then((res) => (employeeList.value = res.data));
    const { roleData } = useGetListRole({});
    const route = useRoute();
    const applicationFlowIds = ref(route.query.applicationFlowIds as []);
    const applicationDefineIds = ref(route.query.applicationDefineIds as []);
    const applicationDefineNames = ref(
      route.query.applicationDefineNames as []
    );
    const backPersonalFlowSettingsList = () => {
      router.push({
        path: `/new${ConstantsRouter.PersonalFlowSetting}`,
        query: {
          page_active: route.query?.page_active,
        },
      });
    };
    const unitName = computed(() => {
      return route.query.unit_name ?? '';
    });
    const store = usePersonalFlowStore();

    // get data from store
    const dataStore = computed(() => {
      return store.state.data;
    });

    // call api get detail personal flow
    const {
      appPersonalSettingDetail,
      handleGetDetailAppPersonalFlowSetting,
    } = useGetDetailAppPersonalFlowSetting();

    const getDetailAppPersonalFlowSetting = async () => {
      for (let i = 0; i < applicationFlowIds.value.length; i++) {
        const applicationDefineName = applicationDefineNames.value[i];
        if (applicationFlowIds.value[i]) {
          await handleGetDetailAppPersonalFlowSetting(
            applicationFlowIds.value[i]
          );
          appPersonalSettingDetail.value.application_define_name = applicationDefineName;
          if (appPersonalSettingDetail.value) {
            await store.commit(
              'pushPersonalFlowData',
              appPersonalSettingDetail.value
            );
          }
        } else {
          const initDataPersonal = {
            application_define_name: applicationDefineName,
            total_level: 1,
            application_define_id: applicationDefineIds.value[i],
            application_flow_details: [
              {
                setting_register_flow: 0,
                role_ids: [],
                pp_employee_ids: [],
              },
            ],
          };
          store.commit('pushPersonalFlowData', initDataPersonal);
        }
      }
    };
    getDetailAppPersonalFlowSetting();
    return {
      t,
      dataStore,
      unitName,
      employeeList,
      roleData,
      appPersonalSettingDetail,
      backPersonalFlowSettingsList,
    };
  },
});
