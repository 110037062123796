import { COMPANY } from '@/router';
import { useLoadingStore } from '@/store/modules/use-loading';
import { queryStringFromObject, SUB_DOMAIN } from '@/util/composables';

export const getSearchEmploy = async (param = {}) => {
  const useLoading = useLoadingStore();
  useLoading.commit('increaseCountApiWaiting');
  const query = queryStringFromObject(param);
  const url = !SUB_DOMAIN
    ? `/api/search/employees?${query}`
    : `https://${SUB_DOMAIN}.cydas.com/${COMPANY}/api/search/employees?${query}`;

  try {
    const res = (
      await fetch(url, {
        headers: {
          'x-requested-with': 'XMLHttpRequest',
          'X-People-Authorization-Type': 'idp_token_cookie',
        },
        mode: 'cors',
        credentials: 'include',
      })
    ).json();
    return res;
  } catch (error) {
    console.error(error);
  } finally {
    useLoading.commit('decreaseCountApiWaiting');
  }
};
