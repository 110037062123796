
import { defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import router from '@/router';
import Button from '@/components/share/Button/index.vue';
import Table from '@/components/share/Table/Table.vue';
import getDataTimezoneListData from '@/api/composables/timezoneList';
import { useCURDTimezoneSetting } from '@/api/composables/timezoneSettings';
import { showToast } from '@/util/useToastMessage';
import { usePopupDeleteStore } from '@/store/modules/popup-delete';
import ConstantsRouter from '@/constants/router';
import { ActionType } from '@/util/composables';
import BaseIcon from '@/components/Icons/BaseIcon.vue';

export default defineComponent({
  components: { Button, Table, BaseIcon },
  setup() {
    const { t } = useI18n();
    const apiTimezoneListParams = ref({
      page: 1,
    });

    const [, { onDeleteTimezone }] = useCURDTimezoneSetting();

    const tableHeaders = [
      {
        text: t('timezoneDivisionCode'),
        value: 'time_zone_session_type_code',
        width: '25%',
        bodyClass: 'truncate-2-line text-center',
      },
      {
        text: t('timezoneDivisionName'),
        value: 'time_zone_session_type_name',
        width: '45%',
        bodyClass: 'truncate-2-line text-center',
      },
      {
        text: t('attendanceDataHeaders.editAction'),
        value: 'editAction',
        width: '10%',
      },
      {
        text: t('delete'),
        value: 'removeAction',
        width: '10%',
      },
      {
        text: t('duplicate'),
        value: 'copyAction',
        width: '10%',
      },
    ];

    const onOpenSignupForm = () => {
      router.push('/new' + ConstantsRouter.TimeZoneSessionCreate);
    };

    const {
      timezoneData,
      pagination,
      loading,
      getDataTimezoneList,
    } = getDataTimezoneListData(apiTimezoneListParams.value);

    const storePopupDel = usePopupDeleteStore();
    const showPopupConfirmDel = (item) => {
      storePopupDel.dispatch('show', {
        isShown: true,
        onSubmit: () => handleDeleteTimezone(item.id),
        title: t('deleteTimezoneModal.title'),
        body: t('deleteTimezoneModal.body', {
          name: item.time_zone_session_type_name,
        }),
        item,
      });
    };

    const handleDeleteTimezone = (id) => {
      onDeleteTimezone({
        id,
        onSuccess: () => {
          showToast('success', t('deteleTimeZoneSuccessfully'));
          getDataTimezoneList(apiTimezoneListParams.value);
          close();
        },
        onFail: () => {
          showToast('error', t('errors.serverError'));
          close();
        },
      });
    };

    const onPushTimezone = (item, actionType) => {
      router.push({
        name: ConstantsRouter.TimeZoneSessionUpdate,
        params: { id: item.id },
        query: { action: actionType },
      });
    };

    const onChangePage = () => {
      getDataTimezoneList(apiTimezoneListParams.value);
    };

    return {
      t,
      loading,
      pagination,
      tableHeaders,
      timeZoneSettingData: timezoneData,
      apiTimezoneListParams,
      ActionType,
      onOpenSignupForm,
      showPopupConfirmDel,
      onPushTimezone,
      onChangePage,
    };
  },
});
