export const USING_TYPE_SUBSTITUTE_APPLICATION = {
  ALL_DAY: 0,
  HALF_DAY: 1,
  TIME_OFF: 2,
};

export const USING_TYPE_SUBSTITUTE_HALF_DAY = {
  MORNING: 0,
  AFTERNOON: 1,
};

export const USING_TYPE_SUBSTITUTE_SETTING_DAY_TYPE = {
  TODAY: 0,
  YESTERDAY: 1,
  TOMORROW: 2,
};
