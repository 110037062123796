import api, { ApiResponse } from '@/clients/api';
import { ErrorCode } from './types/general/ErrorCode';
import {
  JSONApiResponseBody,
  JSONApiResponseErrorBody,
} from '@/api/types/general/JSONApiResponse';
import ConstantsAPI from '@/constants/api';
import {
  ChangeConfirmationStatusApiPutResult,
  ChangeConfirmationStatusShiftParam,
  ChangeNotificationStatusShiftParam,
  ChangeNotificationStatusShiftParamApiPutResult,
  ImportShiftPlanApiGetResult,
  ImportShiftPlanApiPostResult,
  ImportShiftPlanDraftApiPostParams,
  ImportShiftPlanDratApiPostResult,
  ManagerRegisterShiftForPersonnelApiPostParams,
  ManagerRegisterShiftForPersonnelApiPostResult,
  RequirementPersonnelRegisterShift,
  ShiftPlanParams,
  ShiftPlanSearchParams,
} from './types/ShiftPlan';

export async function getListShiftPlan(
  params: ShiftPlanSearchParams
): Promise<
  | ApiResponse<200, JSONApiResponseBody<ShiftPlanParams[]>>
  | ApiResponse<400, JSONApiResponseErrorBody<ErrorCode>>
  | ApiResponse<500, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.get(ConstantsAPI.ShiftPlanList, { params });
}

export async function getRequirementPersonnelRegisterShift(
  id: string
): Promise<
  | ApiResponse<200, JSONApiResponseBody<RequirementPersonnelRegisterShift>>
  | ApiResponse<400, JSONApiResponseErrorBody<ErrorCode>>
  | ApiResponse<500, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.get(
    ConstantsAPI.RequirementPersonnelRegisterShiftDetail(id)
  );
}

export async function postManagerRegisterShiftForPersonnel(
  postData: ManagerRegisterShiftForPersonnelApiPostParams
): Promise<
  | ApiResponse<
      200,
      JSONApiResponseBody<ManagerRegisterShiftForPersonnelApiPostResult>
    >
  | ApiResponse<400, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.post(
    ConstantsAPI.ManagerRegisterShiftForPersonnel,
    postData
  );
}

export async function putChangeConfirmationStatusShift(
  updateData: ChangeConfirmationStatusShiftParam
): Promise<
  | ApiResponse<200, JSONApiResponseBody<ChangeConfirmationStatusApiPutResult>>
  | ApiResponse<400, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.put(ConstantsAPI.ChangeConfirmationStatus, updateData);
}

export async function putChangeNotificationStatusShift(
  updateData: ChangeNotificationStatusShiftParam
): Promise<
  | ApiResponse<
      200,
      JSONApiResponseBody<ChangeNotificationStatusShiftParamApiPutResult>
    >
  | ApiResponse<400, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.put(ConstantsAPI.ChangeNotificationStatusShift, updateData);
}

export async function postImportShiftPlanDraft(
  id: string,
  postData: ImportShiftPlanDraftApiPostParams,
  config
): Promise<
  | ApiResponse<200, JSONApiResponseBody<ImportShiftPlanDratApiPostResult>>
  | ApiResponse<400, JSONApiResponseErrorBody<ErrorCode>>
> {
  const formData = new FormData();
  for (const key in postData) {
    formData.append('import_file', postData[key]);
  }
  return await api.post(
    ConstantsAPI.ImportShiftPlanDraft(id),
    formData,
    config
  );
}

export async function getImportShiftPlanDetail(
  shiftPlanImportId: string
): Promise<
  | ApiResponse<200, JSONApiResponseBody<ImportShiftPlanApiGetResult>>
  | ApiResponse<400, JSONApiResponseErrorBody<ErrorCode>>
  | ApiResponse<500, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.get(ConstantsAPI.ImportShiftPlanDraftGet(shiftPlanImportId));
}

export async function postImportShiftPlan(
  shiftPlanId: string,
  fileId: string
): Promise<
  | ApiResponse<200, JSONApiResponseBody<ImportShiftPlanApiPostResult>>
  | ApiResponse<400, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.post(ConstantsAPI.ImportShiftPlan(shiftPlanId, fileId));
}
