import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, createVNode as _createVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "table-privi-edit mt-12" }
const _hoisted_2 = { class: "w-full justify-end" }
const _hoisted_3 = { class: "list-icon flex flex-wrap justify-end mb-8" }
const _hoisted_4 = { class: "icon" }
const _hoisted_5 = { class: "txt ml-2 font-size-14 whitespace-nowrap font-bold" }
const _hoisted_6 = { class: "overflow-auto border text-gray-333" }
const _hoisted_7 = { class: "flex justify-start p-2" }
const _hoisted_8 = {
  key: 0,
  class: "action flex justify-center mt-12 ml-8"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Table = _resolveComponent("Table")
  const _component_Button = _resolveComponent("Button")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode("div", _hoisted_3, [
        (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.listIcons, (item) => {
          return (_openBlock(), _createBlock("div", {
            key: item,
            class: "item-icon flex items-center ml-12 whitespace-nowrap"
          }, [
            _createVNode("div", _hoisted_4, [
              _createVNode("img", {
                src: item.icon,
                alt: ""
              }, null, 8, ["src"])
            ]),
            _createVNode("div", _hoisted_5, _toDisplayString(item.text), 1)
          ]))
        }), 128))
      ]),
      _createVNode("div", _hoisted_6, [
        (_ctx.datasHeader.length > 1)
          ? (_openBlock(), _createBlock(_component_Table, {
              key: 0,
              data: _ctx.priviEditDatas,
              headers: _ctx.datasHeader,
              onChangeValue: _ctx.handleChangeValue,
              onHiddenChild: _ctx.toggleRow
            }, {
              function_name: _withCtx(({ item }) => [
                _createVNode("div", _hoisted_7, [
                  _createVNode("div", null, _toDisplayString(item.function_name), 1)
                ])
              ]),
              _: 1
            }, 8, ["data", "headers", "onChangeValue", "onHiddenChild"]))
          : _createCommentVNode("", true)
      ]),
      (_ctx.datasHeader.length > 1)
        ? (_openBlock(), _createBlock("div", _hoisted_8, [
            _createVNode(_component_Button, {
              onClick: _ctx.handleRegister,
              class: "btn-gradation-light-blue w-50 h-12 mr-8"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('toRegister')), 1)
              ]),
              _: 1
            }, 8, ["onClick"]),
            _createVNode(_component_Button, {
              onClick: _ctx.handleBackListRole,
              class: "btn-gradation-secondary w-50 h-12"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('backToTheList')), 1)
              ]),
              _: 1
            }, 8, ["onClick"])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}