
import { computed, defineComponent, ref } from 'vue';
import router from '@/router';
import { useI18n } from 'vue-i18n';
import InputArea from '@/components/share/Input/InputArea.vue';
import Button from '@/components/share/Button/index.vue';
import { APPLICATION_ALLOWANCE_DELETE } from '@/constants/typeApplication';

export default defineComponent({
  components: { InputArea, Button },
  props: {
    placeholder: {
      type: String,
    },
    modelValue: {
      type: String,
      default: '',
    },
    errors: {
      type: String,
    },
    errorServer: {
      type: String,
    },
    isValid: {
      type: Boolean,
    },
    validated: {},
  },
  setup(props, { emit }) {
    const i18n = useI18n();
    const t = i18n.t;
    const applicationFormId = router.currentRoute.value.query
      .application_form_id as string;
    const waitingAllowDelete = router.currentRoute.value.query.delete as string;
    const isDetail =
      waitingAllowDelete != APPLICATION_ALLOWANCE_DELETE
        ? applicationFormId
        : false;

    //convert props
    const placeholderReason = ref(
      props.placeholder
        ? props.placeholder
        : t('pleaseEnterAnApplicationMessage')
    );
    const reasonInput = ref(props.modelValue);
    const changeReason = () => {
      emit('update:modelValue', reasonInput.value);
    };

    const errorsReason = computed(() => {
      return props.errors;
    });
    const errorsServerReason = computed(() => {
      return props.errorServer;
    });
    const validatedReason = computed(() => {
      return props.validated;
    });
    const handleCancel = () => {
      emit('handleCancel');
    };
    const handleCreateApplication = () => {
      emit('handleCreateApplication');
    };

    return {
      t,
      isDetail,
      placeholderReason,
      reasonInput,
      errorsReason,
      errorsServerReason,
      validatedReason,
      APPLICATION_ALLOWANCE_DELETE,
      changeReason,
      handleCancel,
      handleCreateApplication,
    };
  },
});
