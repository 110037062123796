import api, { ApiResponse } from '@/clients/api';
import {
  JSONApiResponseBody,
  JSONApiResponseErrorBody,
} from '@/api/types/general/JSONApiResponse';
import {
  IndividualApplicationPolicySettingPostApiParams,
  IndividualApplicationPolicySettingGetIdParams,
  IndividualApplicationPolicySettingGetDetailParams,
  CheckPersonalSettingApplyResponse,
} from '@/api/types/IndividualApplicationPolicySetting';
import { ErrorCode } from '@/api/types/general/ErrorCode';
import ConstantsAPI from '@/constants/api';

export async function getListPersonalSettingApply(
  params: IndividualApplicationPolicySettingGetIdParams
): Promise<
  | ApiResponse<200, JSONApiResponseBody<[]>>
  | ApiResponse<400, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.get(ConstantsAPI.PersonalSettingApplyList, {
    params: params,
  });
}
export async function getListDetailPersonalSettingApply(
  id: IndividualApplicationPolicySettingGetIdParams
): Promise<
  | ApiResponse<
      200,
      JSONApiResponseBody<IndividualApplicationPolicySettingGetDetailParams>
    >
  | ApiResponse<400, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.get(ConstantsAPI.PersonalSettingApplyListDetail(id));
}

export async function createPersonalSettingApply(
  params: IndividualApplicationPolicySettingPostApiParams
): Promise<
  | ApiResponse<200, JSONApiResponseBody<[]>>
  | ApiResponse<400, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.post(ConstantsAPI.PersonalSettingApply, params);
}

export async function updatePersonalSettingApply(
  id: string,
  params: IndividualApplicationPolicySettingPostApiParams
): Promise<
  | ApiResponse<200, JSONApiResponseBody<[]>>
  | ApiResponse<400, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.put(ConstantsAPI.PersonalSettingApplyUpdate(id), params);
}

export async function deletePersonalSettingApply(
  id: IndividualApplicationPolicySettingGetIdParams
): Promise<
  | ApiResponse<200, JSONApiResponseBody<[]>>
  | ApiResponse<400, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.delete(ConstantsAPI.PersonalSettingApplyDelete(id));
}

export async function checkPersonalSettingApply(
  params: IndividualApplicationPolicySettingGetIdParams
): Promise<
  | ApiResponse<200, JSONApiResponseBody<CheckPersonalSettingApplyResponse>>
  | ApiResponse<400, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.get(
    ConstantsAPI.PersonalSettingApplyCheck(params.pp_employee_id)
  );
}
