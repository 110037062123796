import { TimeKeeping } from '@/model/TimeKeeping';
import { ref } from 'vue';
import { getDailyAttendance } from '@/api/timekeeping';
import { convertNullPropertyToEmptyStringProperty } from '@/util/common';

export default function (params) {
  const dailyAttendanceList = ref<TimeKeeping[]>();
  const loading = ref(false);

  async function getDailyAttendanceRecord(params) {
    try {
      loading.value = true;
      const res = await getDailyAttendance(params);

      switch (res.status) {
        case 200:
          dailyAttendanceList.value = (res.data.data || []).map((d) =>
            convertNullPropertyToEmptyStringProperty(d)
          );
          break;
        default:
          dailyAttendanceList.value = [];
          break;
      }
    } catch (error) {
      console.error(error);
    } finally {
      loading.value = false;
    }
  }

  getDailyAttendanceRecord(params);

  return { dailyAttendanceList, loading, getDailyAttendanceRecord };
}
