
import { defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import Modal from '@/components/share/Modal/index.vue';
import Button from '@/components/share/Button/index.vue';
import Table from '@/components/share/Table/Table.vue';

export default defineComponent({
  components: { Modal, Button, Table },
  props: {},
  setup(props, { emit }) {
    const i18n = useI18n();
    const isShow = ref(false);
    const handleCancel = () => {
      isShow.value = false;
      emit('onClose');
    };
    const headerTableSubstitute = [
      {
        text: i18n.t('substituteApplication.holidayCommutingDay'),
        value: 'holiday_commuting_day',
      },
      {
        text: i18n.t('substituteApplication.availableHolidays'),
        value: 'available_holidays',
      },
      {
        text: i18n.t('substituteApplication.dateExpiry'),
        value: 'date_expiry',
      },
    ];
    const substituteList = [
      {
        holiday_commuting_day: '2021/04/02（土）8:00～17:00',
        available_holidays: '1.0',
        date_expiry: '2021/04/30',
      },
      {
        holiday_commuting_day: '2021/04/03（日）10:00～14:00',
        available_holidays: '0.5',
        date_expiry: '2021/04/30',
      },
      {
        holiday_commuting_day: '2021/04/03（日）10:00～14:00',
        available_holidays: '0.3',
        date_expiry: '2021/04/30',
      },
    ];
    return {
      t: i18n.t,
      isShow,
      headerTableSubstitute,
      substituteList,
      handleCancel,
    };
  },
});
