
import ConstantsRouter from '@/constants/router';
import router from '@/router';
import { computed, defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import useGetWaitingData from '@/api/composables/waitingAllowance';
import moment from 'moment';
import ApplicationApproveFlowDetail from '@/components/Application/ApplicationApproveFlowDetail.vue';
import ReasonUpdateStatusApplication from '@/components/Application/ReasonUpdateStatusApplication.vue';
import { useRoute } from 'vue-router';
import BackPreviousPage from '@/components/share/BackPreviousPage/index.vue';
import {
  APPLICATION_MY_REQUEST,
  APPLICATION_STATUS_TYPE,
} from '@/constants/typeApplication';
import Button from '@/components/share/Button/index.vue';
export default defineComponent({
  components: {
    ApplicationApproveFlowDetail,
    ReasonUpdateStatusApplication,
    BackPreviousPage,
    Button,
  },
  setup() {
    const i18n = useI18n();
    const t = i18n.t;
    const route = useRoute();

    const id = router.currentRoute.value.query.id;
    const applicationFormId =
      router.currentRoute.value.query.application_form_id;
    const myApplication = computed(
      () => route.query.is_my_application as string
    );

    const idRecord = id?.toString();
    const dataRecordApplication = ref({
      application_reason: '',
      application_status: null,
      checkin_unit_id: '',
      target_date: '',
      wait_numbers: null,
      checkin_unit_name: '',
    });
    const approvalMessage = ref();
    const targetDate = ref();
    const returnScreen = () => {
      if (applicationFormId) redirectWhenCallAPI();
      else router.push(`/new${ConstantsRouter.ApplicationApprovalRequest}`);
    };
    const { waitingDetail, getWaitingData } = useGetWaitingData();
    const getDataWaitingAllowance = async () => {
      await getWaitingData(applicationFormId);
      if (waitingDetail.value) {
        dataRecordApplication.value = waitingDetail.value;
        targetDate.value = moment(
          dataRecordApplication.value.target_date
        ).isValid()
          ? moment(dataRecordApplication.value.target_date).format(
              `YYYY${t('year')}MM${t('month')}DD${t('sunday')}`
            )
          : '';
      }
    };
    getDataWaitingAllowance();
    const statusApplication = computed(
      () => waitingDetail.value?.application_status
    );
    const isTypeStatusApplication = computed(
      () =>
        waitingDetail.value?.application_status ===
        APPLICATION_STATUS_TYPE.FINISHED
    );
    const applicationReason = computed(
      () => waitingDetail.value?.application_reason
    );
    const createdAt = computed(() => waitingDetail.value?.created_at);

    const redirectWhenCallAPI = () => {
      if (myApplication.value == APPLICATION_MY_REQUEST.toString())
        router.push(`/new${ConstantsRouter.ApplicationApprovalRequest}`);
      else router.push(`/new${ConstantsRouter.ApplicationMyApproval}`);
    };
    const handleTypeFinished = () => {
      if (statusApplication.value === APPLICATION_STATUS_TYPE.FINISHED) {
        router.push({
          path: `/new${ConstantsRouter.WaitingAllowanceApplicationDelete}`,
          query: {
            ...route.query,
            delete: 1,
          },
        });
      }
    };
    return {
      t,
      i18n,
      returnScreen,
      id,
      approvalMessage,
      idRecord,
      applicationFormId,
      waitingDetail,
      dataRecordApplication,
      targetDate,
      statusApplication,
      isTypeStatusApplication,
      createdAt,
      applicationReason,
      myApplication,
      handleTypeFinished,
    };
  },
});
