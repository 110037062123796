
import { defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import Table from '@/components/Table.vue';
import Button from '@/components/Button.vue';
import router from '@/router';
import { usePopupDeleteStore } from '@/store/modules/popup-delete';
import useGetListSchedulesWorking, {
  useDeleteScheduleWorking,
} from '@/api/composables/scheduleWorking';
import { showToast } from '@/util/useToastMessage';
import ConstantsRouter from '@/constants/router';
export default defineComponent({
  components: { Button, Table },
  setup() {
    const i18n = useI18n();
    const closingDaySelected = ref([]);
    const holidayHeaders = [
      {
        text: i18n.t('workingScheduleCode'),
        value: 'administrative_working_schedule_code',
        width: '20%',
      },
      {
        text: i18n.t('workingScheduleName'),
        value: 'administrative_working_schedule_name',
      },
      {
        text: i18n.t('edit'),
        value: 'editAction',
        width: '46px',
        background: '#f2f8fc',
      },
      {
        text: i18n.t('delete'),
        value: 'removeAction',
        width: '46px',
        background: '#f2f8fc',
      },
      {
        text: i18n.t('copy'),
        value: 'copyAction',
        width: '46px',
        background: '#f2f8fc',
      },
    ];
    const currentPage = ref(1);
    const {
      loading,
      resultScheduleWorking,
      useGetListScheduleWorking,
      pagination,
    } = useGetListSchedulesWorking({
      page: currentPage.value,
    });
    const addScheduleLaborPattern = () => {
      router.push(ConstantsRouter.ScheduledLaborPatternRegister);
    };
    const editScheduleLaborPattern = (item) => {
      router.push({
        path: ConstantsRouter.ScheduledLaborPatternUpdate,
        query: { id: item.id },
      });
    };
    const storePopup = usePopupDeleteStore();
    const onDeleteWorkingSchedule = async () => {
      const { deleteData } = useDeleteScheduleWorking(IdWorkingSchedule.value);
      deleteData
        .then((error) => {
          if (error) {
            showToast('error', i18n.t('failMessage.scheduleWorkingFail'));
          } else {
            showToast(
              'success',
              i18n.t('successMessage.successMessageDeleteScheduleWorking')
            );
          }
          useGetListScheduleWorking({
            page: currentPage.value,
            keyword: '',
          });
          close();
        })
        .catch(() => {
          close();
          showToast('error', i18n.t('errors.serverError'));
        });
    };
    const IdWorkingSchedule = ref();

    const openDeleteScheduleLaborPatternPopup = (item) => {
      IdWorkingSchedule.value = item.id;
      storePopup.dispatch('show', {
        isShown: true,
        onSubmit: onDeleteScheduleLaborPattern,
        item,
      });
    };
    const onChangePage = () => {
      useGetListScheduleWorking({
        page: currentPage.value,
        keyword: '',
      });
    };
    const onDeleteScheduleLaborPattern = () => {
      storePopup.dispatch('hide');
      onDeleteWorkingSchedule();
    };
    const copyScheduleLaborPattern = (item) => {
      router.push({
        path: ConstantsRouter.ScheduledLaborPatternCopy,
        query: { id: item.id },
      });
    };
    return {
      t: i18n.t,
      holidayHeaders,
      currentPage,
      loading,
      onChangePage,
      pagination,
      closingDaySelected,
      resultScheduleWorking,
      addScheduleLaborPattern,
      openDeleteScheduleLaborPatternPopup,
      onDeleteScheduleLaborPattern,
      editScheduleLaborPattern,
      copyScheduleLaborPattern,
    };
  },
});
