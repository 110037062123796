
import { defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import Table from '@/components/Table.vue';
import Plus from '@/assets/svg/Plus.svg';
import router from '@/router';
import { deleteTimezoneSettingID } from '@/api/composables/timezoneSettings';
import getDataTimezoneListData from '@/api/composables/timezoneList';
import moment from 'moment';
import { usePopupDeleteStore } from '@/store/modules/popup-delete';
import { ActionType } from '@/util/composables';
import { showToast } from '@/util/useToastMessage';
import ConstantsRouter from '@/constants/router';
import { DATE_FORMAT } from '@/constants/date';
import Button from '@/components/Button.vue';
export default defineComponent({
  components: {
    Table,
    Button,
  },
  setup() {
    const i18n = useI18n();
    const t = i18n.t;
    const itemRemove = ref({
      id: '',
      body: '',
      title: '',
      isOpenModal: false,
    });
    const goToTimezoneRegister = () => {
      router.push(ConstantsRouter.TimezoneRegister);
    };
    const storePopupDel = usePopupDeleteStore();
    const showPopupConfirmDel = (item) => {
      storePopupDel.dispatch('show', {
        isShown: true,
        onSubmit: () => deleteAction(item.id),
        item,
      });
    };
    const deleteAction = async (id) => {
      const { deleteData } = deleteTimezoneSettingID(id);
      deleteData
        .then((error) => {
          if (error) {
            showToast('error', t('deteleTimeZoneFailure'));
          } else {
            showToast('success', t('deteleTimeZoneSuccessfully'));
            if (
              timezoneData.value?.length === 1 &&
              apiTimezoneListParams.value.page > 1
            ) {
              apiTimezoneListParams.value.page -= 1;
            }
            const noLoading = true;
            getDataTimezoneList(apiTimezoneListParams.value, noLoading);
          }
        })
        .catch(() => {
          close();
          showToast('error', t('errors.serverError'));
        });
    };

    const apiTimezoneListParams = ref({
      keyword: '',
      page: 1,
    });

    const displayDate = (date) => {
      return moment(date).format(DATE_FORMAT);
    };
    const onChangePage = () => {
      getDataTimezoneList(apiTimezoneListParams.value);
    };
    const {
      timezoneData,
      pagination,
      getDataTimezoneList,
      loading: loadingListTimeZone,
    } = getDataTimezoneListData(apiTimezoneListParams.value);

    const updateTimezone = (item, actionType) => {
      router.push({
        name: ConstantsRouter.UpdateTimezoneDivisionSettingName,
        params: { id: item.id },
        query: { action: actionType },
      });
    };
    return {
      t,
      deleteAction,
      showPopupConfirmDel,
      getDataTimezoneList,
      goToTimezoneRegister,
      updateTimezone,
      displayDate,
      onChangePage,
      itemRemove,
      pagination,
      ActionType,
      apiTimezoneListParams,
      timezoneData,
      loadingListTimeZone,
      Plus,
      showAllEmployementTypes: {
        label: t('showAllEmploymentTypes'),
      },

      timeZoneDataHeaders: [
        {
          text: i18n.t('timeZone.timeZoneCode'),
          value: 'time_zone_session_type_code',
          width: '20%',
          align: 'center',
        },
        {
          text: i18n.t('timeZone.timeZoneName'),
          value: 'time_zone_session_type_name',
          width: '52%',
          align: 'center',
        },

        {
          text: i18n.t('attendanceDataHeaders.editAction'),
          value: 'editAction',
          width: '6%',
          background: '#f2f8fc',
        },
        {
          text: i18n.t('attendanceDataHeaders.removeAction'),
          value: 'removeAction',
          width: '6%',
          background: '#f2f8fc',
        },
        {
          text: i18n.t('attendanceDataHeaders.copyAction'),
          value: 'copyAction',
          width: '6%',
          background: '#f2f8fc',
        },
      ],
    };
  },
});
