import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "forgot-password" }
const _hoisted_2 = { class: "login__title" }
const _hoisted_3 = { class: "text-white font-size-24 font-bold text-center" }
const _hoisted_4 = { class: "form" }
const _hoisted_5 = { class: "inputs" }
const _hoisted_6 = { class: "inputs-row mt-2" }
const _hoisted_7 = { class: "submit" }
const _hoisted_8 = { class: "button" }
const _hoisted_9 = { class: "button" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Input = _resolveComponent("Input")
  const _component_GradationButton = _resolveComponent("GradationButton")
  const _component_Button = _resolveComponent("Button")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode("p", _hoisted_3, _toDisplayString(_ctx.t('forgotPasswordPage.forgotPassword')), 1)
    ]),
    _createVNode("div", _hoisted_4, [
      _createVNode("div", _hoisted_5, [
        _createVNode("div", _hoisted_6, [
          _createVNode(_component_Input, {
            classes: { 'input-invalid': _ctx.isSubmit && !_ctx.id },
            class: "input",
            placeholder: _ctx.t('loginPage.id'),
            modelValue: _ctx.id,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.id = $event))
          }, null, 8, ["classes", "placeholder", "modelValue"])
        ])
      ]),
      _createVNode("div", _hoisted_7, [
        _createVNode("div", _hoisted_8, [
          _createVNode(_component_GradationButton, {
            class: "login-button border-none outline-none",
            onClick: _ctx.submit
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('apply')), 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ]),
        _createVNode("div", _hoisted_9, [
          _createVNode(_component_Button, {
            onClick: _ctx.backLogin,
            class: "cancel-button w-full h-12 mt-4"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('buttonCancel')), 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ])
      ])
    ])
  ]))
}