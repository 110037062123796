
import { defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import Table from '@/components/Table.vue';
import Button from '@/components/Button.vue';
import DatePicker from '@/components/DatePicker.vue';
import Avatar from '@/components/Avatar.vue';
import Popup from '@/components/Popup.vue';
import Checkbox from '@/components/Checkbox.vue';
import { computed } from '@vue/runtime-core';
import moment from 'moment';
import { DATE_FORMAT } from '@/constants/date';
import router from '@/router';
import { showToast } from '@/util/useToastMessage';
import useGetPlanDepartment from '@/api/composables/useShiftPlanDepartment';
import useDesiredShiftDetailData, {
  postDesiredShiftDetail,
  postRecallDesiredShiftDetail,
} from '@/api/composables/useDesiredShiftDetail';
import { DesiredShiftPost } from '@/api/types/DesiredShiftDetail';
import {
  DesiredShiftDetailParams,
  DesiredShiftEmployees,
  EmployeeRegisterShifts,
  FormDataConfirmShift,
} from '@/model/DesiredShiftDetail';
import ConstantsRouter from '@/constants/router';

export default defineComponent({
  components: {
    DatePicker,
    Avatar,
    Table,
    Button,
    Popup,
    Checkbox,
  },
  setup() {
    // define area
    const i18n = useI18n();
    const membersToBeShiftedSelected = ref<DesiredShiftEmployees[]>();
    const isShowPopupConfirmSendShift = ref(false);
    const isShowPopupConfirmCancelShift = ref(false);
    const membersToBeShiftedHeaders = [
      {
        text: '',
        value: 'checkbox',
        width: '50px',
      },
      {
        text: i18n.t('fullName'),
        value: 'full_name',
        width: '160px',
      },
      {
        text: i18n.t('affiliationOrganization'),
        value: 'unit_name',
      },
      {
        text: i18n.t('employmentStatus'),
        value: 'department',
      },
      {
        text: i18n.t('director'),
        value: 'creation',
      },
      {
        text: `${i18n.t('attributeOrOccupation')}`,
        value: 'numberOfTarget',
      },
      {
        text: `${i18n.t('workingStyle')}`,
        value: 'numberOfClients',
        type: 'html',
      },
      {
        text: `希望シフト提出期限`,
        value: 'deadlineToSend',
      },
      {
        text: `希望シフト <br /> 提出状況`,
        value: 'confirmed',
        type: 'html',
      },

      {
        text: `希望シフト <br /> 提出日時`,
        value: 'confirmed',
        type: 'html',
      },
      {
        text: `提出依頼を <br /> 送信`,
        value: 'sendShift',
        type: 'html',
      },

      {
        text: `提出依頼を <br /> 取消`,
        value: 'removeAction',
        type: 'html',
      },
    ];
    const { shiftPlanOption, getPlanDepartmentData } = useGetPlanDepartment();

    const desiredShiftId = router.currentRoute.value.params.id as string;
    // mockup data area
    const formData = ref<FormDataConfirmShift>({
      shift_plan_name: '',
      unit_id: '',
      register_start_date: '',
      register_end_date: '',
      deadline: '',
      submit_desired_shift_type: 0,
      shift_plan_status: 0,
      unit_name: '',
      shift_plan_employees: [],
    });
    const shiftPlanId = router.currentRoute.value.params.id as string;

    const {
      desiredShiftDetail,
      getDesiredShiftDetailData,
    } = useDesiredShiftDetailData();
    const statusNewShift = ref();
    const ITEM_STATUS = 3;
    //function api Get
    const apiGetShiftDetail = async () => {
      await getDesiredShiftDetailData(desiredShiftId);
      formData.value = { ...desiredShiftDetail.value };
      formData.value.deadline = '';
      await getPlanDepartmentData();
      const nameDepartment = shiftPlanOption.value.find(
        (item) => item.unit_id === formData.value.unit_id
      );
      formData.value.unit_name = nameDepartment.unit_name;
      statusNewShift.value = formData.value.shift_plan_status;
    };
    apiGetShiftDetail();

    const apiPostData = ref<DesiredShiftPost>({
      shift_plan_id: '',
      employee_register_shifts: [
        {
          shift_plan_employee_id: '',
          pp_employee_id: '',
          deadline: '',
        },
      ],
    });
    // get list data from store
    const membersToBeShiftedList = computed(
      () => formData.value.shift_plan_employees
    );
    // disable buttons
    const disableButtons = computed(() =>
      membersToBeShiftedSelected.value?.length ? false : true
    );

    // function define area
    const handleCancelAllShift = () => {
      isShowPopupConfirmCancelShift.value = true;
      // call api here
    };
    const handleSendAllShift = () => {
      isShowPopupConfirmSendShift.value = true;
      // call api here
    };
    const checkSendElement = ref(false);
    // function send shift
    const apiPostSentItem = ref<EmployeeRegisterShifts[]>([]);
    const openPopupConfirmSendShift = (item) => {
      checkSendElement.value = true;
      const dataPostItem = {
        shift_plan_employee_id: item.id,
        pp_employee_id: item.pp_employee_id,
        deadline:
          formData.value.submit_desired_shift_type === 0
            ? formData.value.deadline
            : item.deadline,
      };
      apiPostSentItem.value = [dataPostItem];
      isShowPopupConfirmSendShift.value = true;
    };
    const closePopupConfirmSendShift = () => {
      isShowPopupConfirmSendShift.value = false;
    };

    const handleDataPost = () => {
      apiPostData.value.shift_plan_id = desiredShiftId;
      //Set the submission deadline
      if (formData.value.submit_desired_shift_type === 0) {
        //Set the submission deadline - all item
        if (membersToBeShiftedSelected.value && !checkSendElement.value) {
          const listShiftSelected = membersToBeShiftedSelected.value.map(
            (item) => {
              return {
                shift_plan_employee_id: item.id,
                pp_employee_id: item.pp_employee_id,
                deadline: formData.value.deadline,
              };
            }
          );
          apiPostData.value.employee_register_shifts = listShiftSelected;
        } else {
          //Set the submission deadline - a item
          apiPostData.value.employee_register_shifts = apiPostSentItem.value;
        }
        //Set the submission deadline individually
        //Set the submission deadline individually - all item
      } else {
        if (membersToBeShiftedSelected.value && !checkSendElement.value) {
          const listShiftSelected = membersToBeShiftedSelected.value?.map(
            (item) => {
              const itemSelected = membersToBeShiftedList.value.filter(
                (baseItem) => {
                  return baseItem.id === item.id;
                }
              );

              return {
                shift_plan_employee_id: item.id,
                pp_employee_id: item.pp_employee_id,
                deadline: itemSelected[0]?.deadline,
              };
            }
          );
          apiPostData.value.employee_register_shifts = listShiftSelected;
        } else {
          //Set the submission deadline individually - a item
          apiPostData.value.employee_register_shifts = apiPostSentItem.value;
        }
      }
    };
    const sendShiftElement = () => {
      isShowPopupConfirmSendShift.value = false;
      handleDataPost();
      apiPostDesiredDetail(apiPostData.value);
      checkSendElement.value = false;
    };

    const cancelShiftElement = () => {
      isShowPopupConfirmCancelShift.value = false;
      handleDataPost();
      apiPostRecallDesiredDetail(apiPostData.value);
      checkSendElement.value = false;
    };
    const handleReturn = () => {
      router.push({
        name: ConstantsRouter.CreateNewShiftName,
        params: { shiftPlanId },
      });
    };
    const errorServer = ref({});

    //function api Post Send
    const apiPostDesiredDetail = (params: DesiredShiftDetailParams) => {
      const { registered } = postDesiredShiftDetail(params);
      registered
        .then((error) => {
          if (error) {
            errorServer.value = error.errors.value;
            if (error.errorsCommon.value) {
              showToast('error', error.errorsCommon.value);
            }
          } else {
            showToast('success', i18n.t('aShiftSubmissionRequestHasBeenSent'));
          }
        })
        .catch(() => {
          showToast('error', i18n.t('theShiftSubmissionRequestCouldNotBeSent'));
        });
    };
    //function api Post Recall
    const apiPostRecallDesiredDetail = (params: DesiredShiftDetailParams) => {
      const { registered } = postRecallDesiredShiftDetail(params);
      registered
        .then((error) => {
          if (error) {
            errorServer.value = error.errors.value;
            if (error.errorsCommon.value) {
              showToast('error', error.errorsCommon.value);
            }
          } else {
            showToast(
              'success',
              i18n.t('successMessage.shiftPlanHasBeenCreated')
            );
          }
        })
        .catch(() => {
          showToast('error', i18n.t('errors.serverError'));
        });
    };
    // function cancel shift
    const openPopupConfirmCancelShift = () => {
      checkSendElement.value = true;
      isShowPopupConfirmCancelShift.value = true;
    };
    const closePopupConfirmCancelShift = () => {
      isShowPopupConfirmCancelShift.value = false;
    };

    const optionDays = {
      0: i18n.t('monday'),
      1: i18n.t('tuesday'),
      2: i18n.t('wednesday'),
      3: i18n.t('thursday'),
      4: i18n.t('friday'),
      5: i18n.t('saturday'),
      6: i18n.t('sunday'),
    };

    return {
      t: i18n.t,
      membersToBeShiftedHeaders,
      membersToBeShiftedSelected,
      membersToBeShiftedList,
      disableButtons,
      isShowPopupConfirmCancelShift,
      isShowPopupConfirmSendShift,
      openPopupConfirmSendShift,
      closePopupConfirmSendShift,
      sendShiftElement,
      openPopupConfirmCancelShift,
      closePopupConfirmCancelShift,
      cancelShiftElement,
      handleCancelAllShift,
      handleSendAllShift,
      formData,
      optionDays,
      moment,
      DATE_FORMAT,
      statusNewShift,
      handleReturn,
      ITEM_STATUS,
      apiPostDesiredDetail,
      handleDataPost,
    };
  },
});
