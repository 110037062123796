<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.33203 10.999H22.6148"
      stroke="#333333"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16 16L8.4 10.3C8.2758 10.2069 8.175 10.0861 8.10557 9.94721C8.03614 9.80836 8 9.65525 8 9.5V5C8 4.73478 8.10536 4.48043 8.29289 4.29289C8.48043 4.10536 8.73478 4 9 4H23C23.2652 4 23.5196 4.10536 23.7071 4.29289C23.8946 4.48043 24 4.73478 24 5V9.45515C24 9.60976 23.9642 9.76226 23.8953 9.90068C23.8264 10.0391 23.7263 10.1597 23.603 10.2529L16 16Z"
      stroke="#333333"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16 16L8.4 21.7C8.2758 21.7931 8.175 21.9139 8.10557 22.0528C8.03614 22.1916 8 22.3448 8 22.5V27C8 27.2652 8.10536 27.5196 8.29289 27.7071C8.48043 27.8946 8.73478 28 9 28H23C23.2652 28 23.5196 27.8946 23.7071 27.7071C23.8946 27.5196 24 27.2652 24 27V22.5448C24 22.3902 23.9642 22.2377 23.8953 22.0993C23.8264 21.9609 23.7263 21.8403 23.603 21.7471L16 16Z"
      stroke="#333333"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16 20.9994V16"
      stroke="#333333"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
