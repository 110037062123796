
import { computed, shallowRef } from 'vue';
import kebabCase from 'lodash/kebabCase';

export default {
  props: {
    name: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const icons = shallowRef({});
    const requireComponents = require.context(
      '@/components/Icons/',
      false,
      /[\w]+Icon\.vue$/
    );
    requireComponents.keys().forEach((fileName) => {
      const iconName = kebabCase(fileName.replace(/^\.\/(.+)Icon\.vue/, '$1'));
      const componentConfig = requireComponents(fileName);
      icons.value[iconName] = componentConfig.default || componentConfig;
    });
    const iconComponent = computed(() => {
      return icons.value[props.name];
    });
    return {
      iconComponent,
      icons,
    };
  },
};
