import api, { ApiResponse } from '@/clients/api';
import { ErrorCode } from '@/api/types/general/ErrorCode';
import {
  JSONApiResponseBody,
  JSONApiResponseErrorBody,
} from '@/api/types/general/JSONApiResponse';
import ConstantsAPI from '@/constants/api';
import { TimeRecoderId, TimeRecoderParamsDetail } from '@/model/TimeRecoder';

export async function postTimeRecoder(
  postData: TimeRecoderParamsDetail
): Promise<
  | ApiResponse<200, JSONApiResponseBody<TimeRecoderId>>
  | ApiResponse<400, JSONApiResponseErrorBody<ErrorCode>>
  | ApiResponse<500, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.post(ConstantsAPI.TimeRecoderCreate, postData);
}
export async function getDetailTimeRecoder(
  unitId: string
): Promise<
  | ApiResponse<200, JSONApiResponseBody<[]>>
  | ApiResponse<400, JSONApiResponseErrorBody<ErrorCode>>
  | ApiResponse<500, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.get(ConstantsAPI.TimeRecoderDetail(unitId));
}
export async function updateTimeRecoder(
  unitId: string,
  params: TimeRecoderParamsDetail
): Promise<
  | ApiResponse<200, JSONApiResponseBody<[]>>
  | ApiResponse<400, JSONApiResponseErrorBody<ErrorCode>>
  | ApiResponse<500, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.put(ConstantsAPI.TimeRecoderUpdate(unitId), params);
}
