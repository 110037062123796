import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "child-tab-container" }
const _hoisted_2 = { class: "flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")
  const _component_router_view = _resolveComponent("router-view")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.dataTabs, (item) => {
        return (_openBlock(), _createBlock(_component_Button, {
          class: ["btn-outline-secondary mr-5 w-48 h-8 justify-center radius-59 font-bold font-size-14", _ctx.currentPage.path.includes(item.name) ? 'active-tab' : ''],
          key: item.text
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(item.text), 1)
          ]),
          _: 2
        }, 1032, ["class"]))
      }), 128))
    ]),
    _createVNode(_component_router_view)
  ]))
}