import { ref } from 'vue';
import { convertErrorFromServerToJson } from '@/util/useValidateForm';
import { WorkInHolidayDayApplicationApiPostData } from '@/api/types/WorkInHolidayApplicationApi';
import {
  postWorkInHolidayApplication,
  getWorkInHolidayApplicationDetail,
} from '@/api/workInHolidayApplication';
import { WorkInHolidayApplicationParams } from '@/model/WorkInHolidayApplication';

export function usePostWorkInHolidayApplication(
  data: WorkInHolidayDayApplicationApiPostData
) {
  const errors = ref({});
  const errorsCommon = ref<string>();
  return {
    registered: (async () => {
      const response = await postWorkInHolidayApplication(data);
      switch (response.status) {
        case 200:
          return null;
        default:
          if (response.data.errors[0].source) {
            errors.value = convertErrorFromServerToJson(response.data.errors);
          } else {
            errorsCommon.value = response.data.errors[0].title;
          }
          return {
            errors,
            errorsCommon,
          };
      }
    })(),
  };
}

export function useDetailWorkInHolidayApplication() {
  const workInHolidayApplicationDetail = ref<WorkInHolidayApplicationParams>({
    application_work_in_holidays: [],
  });
  const loading = ref(false);

  async function getWorkInHolidayApplicationDetailData(id) {
    try {
      loading.value = true;
      const res = await getWorkInHolidayApplicationDetail(id);
      const response = ref();
      switch (res.status) {
        case 200:
          response.value = res.data;
          workInHolidayApplicationDetail.value = response.value.data;
          return workInHolidayApplicationDetail.value;
        default:
          workInHolidayApplicationDetail.value = {
            application_work_in_holidays: [],
          };
          return workInHolidayApplicationDetail.value;
      }
    } catch (error) {
      console.error(error);
      return {};
    } finally {
      loading.value = false;
    }
  }

  return {
    workInHolidayApplicationDetail,
    loading,
    getWorkInHolidayApplicationDetailData,
  };
}
