<template>
  <svg
    width="71"
    height="40"
    viewBox="0 0 71 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.3525 13.5117C23.6373 13.5117 26.1412 13.9359 26.1412 15.4837C26.1412 16.1162 25.7032 16.6669 25.0535 16.6669C24.3308 16.6669 23.8344 16.0492 22.3598 16.0492C20.1771 16.0492 19.0456 17.9021 19.0456 20.0527C19.0456 22.1512 20.1917 23.952 22.3598 23.952C23.8417 23.952 24.4403 23.2078 25.163 23.2078C25.9587 23.2078 26.3237 24.0041 26.3237 24.4059C26.3237 26.1025 23.6665 26.4895 22.3598 26.4895C18.7828 26.4895 16.3008 23.6469 16.3008 20.0006C16.2935 16.332 18.7609 13.5117 22.3525 13.5117Z"
      fill="#007BC3"
    />
    <path
      d="M31.0379 20.3849L27.7091 15.6969C27.5485 15.4662 27.4609 15.1834 27.4609 14.9006C27.4609 14.1937 27.9865 13.6133 28.7311 13.6133C29.191 13.6133 29.5925 13.8068 29.8261 14.1416L32.3665 17.8251L34.8996 14.1416C35.1259 13.8068 35.5347 13.6133 35.9946 13.6133C36.7392 13.6133 37.2648 14.1937 37.2648 14.9006C37.2648 15.1834 37.1772 15.4662 37.0166 15.6969L33.6878 20.3849V25.0953C33.6878 25.7502 33.3009 26.3827 32.3665 26.3827C31.4321 26.3827 31.0452 25.7502 31.0452 25.0953V20.3849H31.0379Z"
      fill="#007BC3"
    />
    <path
      d="M38.7578 15.0766C38.7578 14.2655 39.3199 13.7148 40.1156 13.7148H43.0429C46.9922 13.7148 49.2479 16.2598 49.2479 20.174C49.2479 23.8723 46.8535 26.2759 43.2254 26.2759H40.1594C39.5754 26.2759 38.7651 25.9559 38.7651 24.9513V15.0766H38.7578ZM41.4004 23.8426H43.1816C45.4519 23.8426 46.4958 22.1683 46.4958 20.0549C46.4958 17.8002 45.4373 16.1556 43.0064 16.1556H41.4004V23.8426Z"
      fill="#007BC3"
    />
    <path
      d="M53.5977 14.9738C53.897 14.1627 54.5832 13.5078 55.4811 13.5078C56.4155 13.5078 57.0652 14.1254 57.3645 14.9738L60.8174 24.5657C60.9196 24.8485 60.9561 25.094 60.9561 25.2205C60.9561 25.9052 60.394 26.3814 59.737 26.3814C58.9997 26.3814 58.6274 25.9945 58.4522 25.4661L57.9266 23.8067H53.0283L52.5027 25.4512C52.3275 26.0019 51.9552 26.3888 51.2325 26.3888C50.5244 26.3888 49.9258 25.8605 49.9258 25.1536C49.9258 24.8708 50.0134 24.6624 50.0499 24.5732L53.5977 14.9738ZM53.7875 21.3734H57.1382L55.503 16.2611H55.4665L53.7875 21.3734Z"
      fill="#007BC3"
    />
    <path
      d="M70.0871 15.271C70.0871 15.9631 69.6272 16.5733 68.9045 16.5733C68.1818 16.5733 67.6197 16.045 66.5612 16.045C65.802 16.045 65.1158 16.4468 65.1158 17.1909C65.1158 19.0066 70.9996 17.8458 70.9996 22.3925C70.9996 24.9151 68.9191 26.4852 66.4882 26.4852C65.1304 26.4852 62.2031 26.1652 62.2031 24.5058C62.2031 23.8212 62.663 23.2557 63.3857 23.2557C64.2179 23.2557 65.2034 23.9403 66.3422 23.9403C67.5029 23.9403 68.138 23.2854 68.138 22.4222C68.138 20.3386 62.2542 21.5962 62.2542 17.5035C62.2542 15.0329 64.2836 13.5 66.6196 13.5C67.6197 13.5074 70.0871 13.8795 70.0871 15.271Z"
      fill="#007BC3"
    />
    <path
      d="M12.5339 20.8154H7.71586C7.48226 20.8154 7.28516 21.0089 7.28516 21.247V26.069C7.28516 26.3072 7.47496 26.5006 7.71586 26.5006H12.5339C12.7675 26.5006 12.9646 26.3072 12.9646 26.069V21.247C12.9646 21.0015 12.7748 20.8154 12.5339 20.8154Z"
      fill="#007BC3"
    />
    <path
      d="M3.212 19.9445C4.98594 19.9445 6.424 18.5052 6.424 16.7298C6.424 14.9544 4.98594 13.5151 3.212 13.5151C1.43806 13.5151 0 14.9544 0 16.7298C0 18.5052 1.43806 19.9445 3.212 19.9445Z"
      fill="#007BC3"
    />
    <path
      d="M12.7534 14.9586L10.3444 13.567C10.213 13.4926 10.0451 13.4926 9.91367 13.567L7.50467 14.9586C7.37327 15.033 7.29297 15.1744 7.29297 15.3306V18.1137C7.29297 18.27 7.37327 18.4114 7.50467 18.4858L9.91367 19.8773C10.0451 19.9517 10.213 19.9517 10.3444 19.8773L12.7534 18.4858C12.8848 18.4114 12.9651 18.27 12.9651 18.1137V15.3306C12.9651 15.1744 12.8848 15.033 12.7534 14.9586Z"
      fill="#30B1E0"
    />
    <path
      d="M6.36613 25.8468L3.60673 21.0397C3.43883 20.7569 3.03003 20.7569 2.86213 21.0397L0.0589275 25.8468C-0.108973 26.1296 0.102729 26.4868 0.431229 26.4868H6.00113C6.32963 26.4942 6.53403 26.1371 6.36613 25.8468Z"
      fill="#003A84"
    />
  </svg>
</template>
