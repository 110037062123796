
import { defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import Input from '@/components/Input.vue';
import Table from '@/components/Table.vue';
import Button from '@/components/Button.vue';
import iconPlus from '@/assets/svg/Plus.svg';
import DatePicker from '@/components/DatePicker.vue';
import RegisterTargetPersonPopup from '@/views/ShiftManagement/RegisterTargetPersonPopup.vue';
import AvatarSample from '@/assets/svg/AvatarSample.svg';
import Avatar from '@/components/Avatar.vue';
import ShiftSubmissionRequestPopup from '@/views/ShiftManagement/ShiftSubmissionRequestPopup.vue';
import { computed } from '@vue/runtime-core';
import { usePopupDeleteStore } from '@/store/modules/popup-delete';

export default defineComponent({
  components: {
    ShiftSubmissionRequestPopup,
    Avatar,
    RegisterTargetPersonPopup,
    Button,
    Table,
    Input,
    DatePicker,
  },
  setup() {
    const i18n = useI18n();
    const store = usePopupDeleteStore();
    const membersToSubmittedHeaders = [
      {
        text: i18n.t('name'),
        value: 'name',
      },
      {
        text: i18n.t('organization'),
        value: 'organization',
      },
      {
        text: i18n.t('workingStyle'),
        value: 'working_style',
      },
      {
        text: i18n.t('delete'),
        value: 'removeAction',
        background: '#f2f8fc',
      },
    ];
    const membersToSubmittedData = [
      {
        name: '斉藤 亜沙美',
        organization: '営業本部',
        working_style: '正社員',
        avatar: AvatarSample,
      },
      {
        name: '安里 久美',
        organization: '営業本部',
        working_style: '正社員',
        avatar: AvatarSample,
      },
      {
        name: '斉藤 亜沙美',
        organization: '営業本部',
        working_style: '正社員',
        avatar: AvatarSample,
      },
      {
        name: '斉藤 亜沙美',
        organization: '営業本部',
        working_style: '正社員',
        avatar: AvatarSample,
      },
      {
        name: '斉藤 亜沙美',
        organization: '営業本部',
        working_style: '正社員',
        avatar: AvatarSample,
      },
      {
        name: '斉藤 亜沙美',
        organization: '営業本部',
        working_style: '正社員',
        avatar: AvatarSample,
      },
      {
        name: '斉藤 亜沙美',
        organization: '営業本部',
        working_style: '正社員',
        avatar: AvatarSample,
      },
      {
        name: '斉藤 亜沙美',
        organization: '営業本部',
        working_style: 'アルバイト',
        avatar: AvatarSample,
      },
      {
        name: '斉藤 亜沙美',
        organization: '営業本部',
        working_style: 'アルバイト',
        avatar: AvatarSample,
      },
      {
        name: '斉藤 亜沙美',
        organization: '営業本部',
        working_style: 'アルバイト',
        avatar: AvatarSample,
      },
      {
        name: '斉藤 亜沙美',
        organization: '営業本部',
        working_style: 'アルバイト',
        avatar: AvatarSample,
      },
      {
        name: '斉藤 亜沙美',
        organization: '営業本部',
        working_style: 'アルバイト',
        avatar: AvatarSample,
      },
      {
        name: '斉藤 亜沙美',
        organization: '営業本部',
        working_style: 'アルバイト',
        avatar: AvatarSample,
      },
      {
        name: '斉藤 亜沙美',
        organization: '営業本部',
        working_style: 'アルバイト',
        avatar: AvatarSample,
      },
      {
        name: '斉藤 亜沙美',
        organization: '営業本部',
        working_style: 'アルバイト',
        avatar: AvatarSample,
      },
      {
        name: '斉藤 亜沙美',
        organization: '営業本部',
        working_style: 'アルバイト',
        avatar: AvatarSample,
      },
      {
        name: '斉藤 亜沙美',
        organization: '営業本部',
        working_style: 'アルバイト',
        avatar: AvatarSample,
      },
      {
        name: '斉藤 亜沙美',
        organization: '営業本部',
        working_style: 'アルバイト',
        avatar: AvatarSample,
      },
      {
        name: '斉藤 亜沙美',
        organization: '営業本部',
        working_style: 'アルバイト',
        avatar: AvatarSample,
      },
      {
        name: '斉藤 亜沙美',
        organization: '営業本部',
        working_style: 'アルバイト',
        avatar: AvatarSample,
      },
      {
        name: '斉藤 亜沙美',
        organization: '営業本部',
        working_style: 'アルバイト',
        avatar: AvatarSample,
      },
      {
        name: '斉藤 亜沙美',
        organization: '営業本部',
        working_style: 'アルバイト',
        avatar: AvatarSample,
      },
    ];
    const requestHistoryHeaders = [
      {
        text: i18n.t('departmentForShiftCreation'),
        value: 'department_for_shift_creation',
      },
      {
        text: i18n.t('applicationSubmit'),
        value: 'application_submit',
      },
      {
        text: i18n.t('submissionDeadline'),
        value: 'submission_deadline',
      },
      {
        text: i18n.t('numberOfTargetPeople'),
        value: 'number_of_target_people',
      },
      {
        text: i18n.t('requestDateAndTime'),
        value: 'request_date_and_time',
      },
      {
        text: i18n.t('cancel2'),
        value: 'cancel',
        background: '#f2f8fc',
      },
    ];
    const requestHistoryData = [
      {
        department_for_shift_creation: '営業本部',
        application_submit: '2021/04/16(金)　～　2021/04/30(金)',
        submission_deadline: '2021/04/10(土)',
        number_of_target_people: '35名　/　67名',
        request_date_and_time: '2021/04/03(土)　15:34:49',
      },
    ];
    const numberOfPeopleToBeSubmitted = computed(
      () => membersToSubmittedData.length
    );
    const params = ref({
      departmentForShiftCreation: '',
      applicationSubmit: {
        start: new Date(),
        end: new Date(),
      },
      submissionDeadline: new Date(),
    });
    const isShowAddTargetPersonPopup = ref(false);
    const addTargetPerson = () => {
      isShowAddTargetPersonPopup.value = true;
    };
    const closeAddTargetPersonPopup = () => {
      isShowAddTargetPersonPopup.value = false;
    };

    const isShowShiftSubmissionRequestPopup = ref(false);
    const openShiftSubmissionRequestPopup = () => {
      isShowShiftSubmissionRequestPopup.value = true;
    };
    const closeShiftSubmissionRequestPopup = () => {
      isShowShiftSubmissionRequestPopup.value = false;
    };
    const openShiftSubmissionRequestDeleteConfirm = () => {
      store.dispatch('show', {
        title: i18n.t('sureSubmissionRequestDelete'),
        body: i18n.t('sureSubmissionRequestDeleteContent'),
        btnOnSubmitText: i18n.t('submissionRequestBtn'),
        isShown: true,
      });
    };
    const openMembersToSubmittedDeleteConfirm = () => {
      store.dispatch('show', {
        isShown: true,
      });
    };
    return {
      t: i18n.t,
      membersToSubmittedHeaders,
      requestHistoryHeaders,
      numberOfPeopleToBeSubmitted,
      iconPlus,
      params,
      isShowAddTargetPersonPopup,
      membersToSubmittedData,
      requestHistoryData,
      isShowShiftSubmissionRequestPopup,
      addTargetPerson,
      closeAddTargetPersonPopup,
      closeShiftSubmissionRequestPopup,
      openShiftSubmissionRequestPopup,
      openShiftSubmissionRequestDeleteConfirm,
      openMembersToSubmittedDeleteConfirm,
    };
  },
});
