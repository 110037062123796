import { InjectionKey } from '@vue/runtime-core';
import { createStore, Store } from 'vuex';
import { FunctionDefine } from '@/model/FunctionDefine';

export interface State {
  functionDefines: {
    pp_employee_id: string;
    function_defines: FunctionDefine[];
  };
}

export const key: InjectionKey<Store<State>> = Symbol();

export function useApplySettingForSystemStore() {
  return store;
}

export const store = createStore<State>({
  state() {
    return {
      functionDefines: {
        pp_employee_id: '',
        function_defines: [],
      },
    };
  },
  mutations: {
    setFunctionDefines(state, functionDefines) {
      state.functionDefines = functionDefines;
    },
  },
});
export default store;
