
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';
import ConstantsRouter from '@/constants/router';
import Tab from '@/components/share/Tabs/index.vue';
export default defineComponent({
  components: {
    Tab,
  },
  setup() {
    const i18n = useI18n();
    const t = i18n.t;
    const dataTabs = [
      {
        text: i18n.t('timeCardNew.everyoneAttendanceRecord'),
        path: `/new${ConstantsRouter.AllMemberTimeCard}`,
      },
      {
        text: i18n.t('timeCardNew.myAttendanceRecord'),
        path: `/new${ConstantsRouter.MyTimeCard}`,
      },
    ];
    return {
      t,
      dataTabs,
    };
  },
});
