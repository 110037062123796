import api, { ApiResponse } from '@/clients/api';
import { ErrorCode } from './types/general/ErrorCode';
import {
  JSONApiResponseBody,
  JSONApiResponseErrorBody,
} from './types/general/JSONApiResponse';
import {
  DesiredShiftGetData,
  DesiredShiftPost,
  DesiredShiftApiPostResult,
  DesiredShiftPut,
} from '@/api/types/DesiredShiftDetail';
import ConstantsAPI from '@/constants/api';

export async function getDesiredShiftDetail(
  id: string
): Promise<
  | ApiResponse<200, JSONApiResponseBody<DesiredShiftGetData>>
  | ApiResponse<400, JSONApiResponseErrorBody<ErrorCode>>
  | ApiResponse<500, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.get(ConstantsAPI.DesiredShiftDetail(id));
}

export async function postDesiredShift(
  postData: DesiredShiftPost
): Promise<
  | ApiResponse<200, JSONApiResponseBody<DesiredShiftApiPostResult>>
  | ApiResponse<400, JSONApiResponseErrorBody<ErrorCode>>
  | ApiResponse<500, JSONApiResponseErrorBody<ErrorCode>>
> {
  const refactorEmployee = postData.employee_register_shifts.map((item) => {
    return {
      deadline_date: item.deadline,
      pp_employee_id: item.pp_employee_id,
      shift_plan_employee_id: item.shift_plan_employee_id,
    };
  });
  const refactorPostData = {
    shift_plan_id: postData.shift_plan_id,
    employee_register_shifts: refactorEmployee,
  };

  return await api.post(ConstantsAPI.PostDesiredShiftDetail, refactorPostData);
}

export async function postRecallDesiredShift(
  postData: DesiredShiftPut
): Promise<
  | ApiResponse<200, JSONApiResponseBody<DesiredShiftApiPostResult>>
  | ApiResponse<400, JSONApiResponseErrorBody<ErrorCode>>
  | ApiResponse<500, JSONApiResponseErrorBody<ErrorCode>>
> {
  const refactorEmployee = postData.employee_register_shifts.map((item) => {
    return {
      deadline_date: item.deadline,
      pp_employee_id: item.pp_employee_id,
      shift_plan_employee_id: item.shift_plan_employee_id,
    };
  });
  const refactorPostData = {
    shift_plan_id: postData.shift_plan_id,
    employee_register_shifts: refactorEmployee,
  };

  return await api.post(
    ConstantsAPI.PostRecallDesiredShiftDetail,
    refactorPostData
  );
}
