import api, { ApiResponse } from '@/clients/api';
import { ErrorCode } from './types/general/ErrorCode';
import {
  JSONApiResponseBody,
  JSONApiResponseErrorBody,
} from '@/api/types/general/JSONApiResponse';
import {
  ScheduleWorkingApiPostData,
  ScheduleWorkingApiPostResult,
  ScheduleWorkingApiUpdateData,
  ScheduleWorkingApiUpdateResult,
  ScheduleWorkingId,
  ScheduleWorkingParamKey,
  ScheduleWorkingParams,
} from '@/api/types/ScheduleWorking';
import ConstantsAPI from '@/constants/api';

export async function getListSchedulesWorking(
  params: ScheduleWorkingParamKey
): Promise<
  | ApiResponse<200, JSONApiResponseBody<ScheduleWorkingParams[]>>
  | ApiResponse<400, JSONApiResponseErrorBody<ErrorCode>>
  | ApiResponse<500, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.get(ConstantsAPI.WorkingSchedulesList, {
    params: params,
  });
}

export async function deleteScheduleWorking(
  id: ScheduleWorkingId
): Promise<
  | ApiResponse<200, JSONApiResponseBody<ScheduleWorkingId>>
  | ApiResponse<400, JSONApiResponseErrorBody<ErrorCode>>
  | ApiResponse<500, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.delete(ConstantsAPI.WorkingSchedules(id));
}

export async function postScheduleWorking(
  postData: ScheduleWorkingApiPostData
): Promise<
  | ApiResponse<200, JSONApiResponseBody<ScheduleWorkingApiPostResult>>
  | ApiResponse<201, JSONApiResponseBody<ScheduleWorkingApiPostResult>>
  | ApiResponse<400, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.post(ConstantsAPI.WorkingSchedulesCreate, postData);
}

export async function getScheduleWorkingDetail(
  id: string
): Promise<
  | ApiResponse<200, JSONApiResponseBody<ScheduleWorkingApiUpdateData>>
  | ApiResponse<400, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.get(ConstantsAPI.WorkingSchedules(id));
}

export async function updateScheduleWorking(
  id: string,
  updateData: ScheduleWorkingApiUpdateData
): Promise<
  | ApiResponse<200, JSONApiResponseBody<ScheduleWorkingApiUpdateResult>>
  | ApiResponse<400, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.put(ConstantsAPI.WorkingSchedules(id), updateData);
}
