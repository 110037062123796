
import {
  defineComponent,
  onBeforeMount,
  onBeforeUnmount,
  ref,
  computed,
  watch,
} from 'vue';
import { useI18n } from 'vue-i18n';
import moment from 'moment';
import getDailyAttendance from '@/api/composables/timeKeeping';
import { useStore } from '@/store';
import useSettingViewItem, {
  useRecalculateTimeKeeping,
} from '@/api/composables/settingViewItem';
import { convertToDateTime, getCookie } from '@/util/composables';
import { SettingViewTimePostItemType } from '@/api/types/SettingViewItem';
import { watchEffect } from '@vue/runtime-core';
import { useGetListAppFormDefines } from '@/api/composables/approvalSettings';
import { useGetListMyTimeCardMonthly } from '@/api/composables/useGetListMyTimeCardByMonthly';
import { useGetListPersonalFlowSettingFollowUnitId } from '@/api/composables/approvalSettings';
import router from '@/router';
import ConstantsRouter from '@/constants/router';
import Button from '@/components/share/Button/index.vue';
import PopupAttendance from '@/views/PopupAttendanceRecord/PopupAttendanceRecord.vue';
import Table from '@/components/share/Table/ScrollTable.vue';
import MonthPicker from '@/components/share/Calendar/MonthPicker.vue';
import Dropdown from '@/components/share/Dropdown/Dropdown.vue';
import Checkbox from '@/components/share/Checkbox/CheckboxInput.vue';
import { WEEK_DAY } from '@/constants/workingStyleMaster';
import { showToast } from '@/util/useToastMessage';
import UserInformationTimeCard from '@/views/New/TimeCard/UserTotalInformationTimeCard.vue';
import BackPreviousPage from '@/components/share/BackPreviousPage/index.vue';
import { isAllValidMonth } from '@/util/time';
import { APPLICATION_TYPE } from '@/constants/typeApplication';

export default defineComponent({
  components: {
    Button,
    PopupAttendance,
    Table,
    MonthPicker,
    Checkbox,
    Dropdown,
    UserInformationTimeCard,
    BackPreviousPage,
  },
  setup() {
    const i18n = useI18n();
    const store = useStore();
    const ppEmployeeId = router.currentRoute.value.query?.id;
    const startDate = ppEmployeeId
      ? router.currentRoute.value.query?.startDate
      : '';
    const currentMonth = startDate ? moment(startDate as string) : moment();
    const monthSearch = ref(currentMonth.format('YYYY/MM'));
    const isConfirmTimeCard = ref(true);
    const startOfMonth = ref(
      currentMonth.startOf('month').format('YYYY/MM/DD(dd)')
    );
    const endOfMonth = ref(
      currentMonth.endOf('month').format('YYYY/MM/DD(dd)')
    );
    const startDateDailyAttendance = ref();
    const endDateDailyAttendance = ref();
    const { listAppFormDefines, appFormDefines } = useGetListAppFormDefines();
    const paramsGetDailyAttendance = ref({
      month: currentMonth.format('M'),
      year: currentMonth.format('Y'),
      pp_employee_id: ppEmployeeId,
    });
    const pp_employee_id = ppEmployeeId
      ? ppEmployeeId
      : getCookie('pp_employee_id') || null;
    const { resultViewItem, useGetSettingViewItem } = useSettingViewItem(
      pp_employee_id
    );
    const convertResultViewItem = ref<{
      data: SettingViewTimePostItemType[];
    }>({ data: [] });
    watchEffect(() => {
      if (resultViewItem.value) {
        if (
          resultViewItem.value?.setting_view_time_card &&
          resultViewItem.value?.setting_view_time_card?.data?.length
        ) {
          convertResultViewItem.value = JSON.parse(
            resultViewItem.value.setting_view_time_card
          );
        } else {
          convertResultViewItem.value.data =
            resultViewItem.value.setting_view_time_card_defines;
        }
      }
    });
    const headerTableTimeCard = computed(() => {
      let resultTimeCard = convertResultViewItem.value?.data
        .filter(
          (el) =>
            el.data_master_code !== 'application' &&
            el.data_master_code !== 'date'
        )
        .filter(
          (el) =>
            el.data_master_code === 'time_zone_type_1' ||
            !el.data_master_code.includes('time_zone_type')
        )
        .map((el) => {
          return {
            text: el.data_master_name,
            value: el.data_master_code,
            width: 150,
          };
        });
      if (!resultTimeCard) {
        resultTimeCard = [];
      }

      if (ppEmployeeId) {
        resultTimeCard?.unshift({
          text: i18n.t('dailyAttendanceHeaders.date'),
          value: 'date',
          width: 150,
        });
      } else {
        resultTimeCard?.unshift(
          {
            text: i18n.t('dailyAttendanceHeaders.apply'),
            value: 'application',
            width: 110,
          },
          {
            text: i18n.t('dailyAttendanceHeaders.date'),
            value: 'date',
            width: 150,
          }
        );
      }

      return resultTimeCard;
    });
    const {
      dailyAttendanceList,
      getDailyAttendanceRecord,
    } = getDailyAttendance(paramsGetDailyAttendance.value);
    const displayDate = (date) => {
      if (date) {
        return moment(date).format('MM / DD (dd)');
      }
      return '';
    };
    const displayTime = (date, format) => {
      if (date) {
        return moment(date).format(format);
      }
      return '';
    };
    const getColorDate = (dataRow) => {
      const day = moment(dataRow.date).day();
      if (dataRow.is_national_holiday === true) {
        return '#ED5D5D';
      } else if (day === WEEK_DAY.SATURDAY) {
        return '#22AEE5';
      } else if (day === WEEK_DAY.SUNDAY) {
        return '#ED5D5D';
      }
      return '#333333';
    };
    const convertNumberToDateTime = (number, format) => {
      if (!number || number === '0') {
        return '';
      }
      return convertToDateTime(number, format);
    };
    const displayWorkingSchedule = (item) => {
      if (item.working_schedule_start && item.working_schedule_end) {
        return (
          moment(item.working_schedule_start, 'HH:mm').format('HH:mm') +
          '～' +
          moment(item.working_schedule_end, 'HH:mm').format('HH:mm')
        );
      }
      return '';
    };
    const isShowPopupAttendance = ref(false);
    const openPopupAttendance = () => {
      isShowPopupAttendance.value = true;
    };
    const closePopupAttendance = async () => {
      isShowPopupAttendance.value = false;
      await useGetSettingViewItem(pp_employee_id);
      getDailyAttendance(paramsGetDailyAttendance.value);
    };
    // fix hard code to show data daily_work_schedule_type
    const getTextTime = (str) => {
      const time = str.substr(-5, 5);
      let textTime = '';
      if (str.includes('today')) {
        textTime = '';
      } else if (str.includes('yesterday')) {
        textTime = `${i18n.t('previousTextTimeCard')}`;
      } else if (str.includes('tomorrow')) {
        textTime = `${i18n.t('nextDayTextTimeCard')}`;
      }
      return textTime + time;
    };
    const getPeriodTime = (str) => {
      if (
        !getTextTime(str.split(' - ')[0]) &&
        !getTextTime(str.split(' - ')[1])
      ) {
        return '';
      }
      if (!getTextTime(str.split(' - ')[1])) {
        return getTextTime(str.split(' - ')[0]);
      }
      return (
        getTextTime(str.split(' - ')[0]) +
        '〜' +
        getTextTime(str.split(' - ')[1])
      );
    };
    const getTime = (data) => {
      if (!data) {
        return [];
      }
      const listTime = data.split(',');
      let periods: string[] = [];
      for (let i = 0; i < listTime.length; i++) {
        periods.push(getPeriodTime(listTime[i]));
      }
      return periods;
    };
    const handleItemTimeCard = (
      daily_work_schedule_type,
      daily_work_schedule_work_time,
      daily_work_schedule_name
    ) => {
      if (!daily_work_schedule_type) {
        return '';
      }
      if (daily_work_schedule_type == 'weekday') {
        const listTime = [...getTime(daily_work_schedule_work_time)];
        let data = '';
        for (let i = 0; i < listTime.length; i++) {
          if (i > 1) {
            data += '...';
            break;
          }
          if (i === 1) {
            data += listTime[i];
          } else {
            data += listTime[i] + '<br />';
          }
        }
        return data;
      }
      if (
        daily_work_schedule_type == 'legal_holiday' ||
        daily_work_schedule_type == 'scheduled_holiday' ||
        daily_work_schedule_type == 'national_holiday'
      ) {
        return daily_work_schedule_name;
      }
    };
    const {
      getDetailMyTimeCard,
      loading,
      detailTimeCardMonthly,
    } = useGetListMyTimeCardMonthly();
    getDetailMyTimeCard(pp_employee_id, {
      target_month: currentMonth.format('Y-MM'),
    });
    const unitId = getCookie('unit_id') || null;
    const {
      personalSettingFollowUnitIdDetail,
      handleGetListPersonalFlowSettingFollowUnitId,
    } = useGetListPersonalFlowSettingFollowUnitId();
    handleGetListPersonalFlowSettingFollowUnitId(unitId);
    watchEffect(() => {
      // format options appFormDefines, disabled when it didn't have application_flow_id
      if (
        personalSettingFollowUnitIdDetail.value?.length &&
        appFormDefines.value?.length
      ) {
        for (let i = 0; i < appFormDefines.value.length; i++) {
          const item = personalSettingFollowUnitIdDetail.value.filter(
            (item) => appFormDefines.value[i].id === item.application_define_id
          );
          if (item.length && item[0].application_flow_id) {
            appFormDefines.value[i].disabled = false;
          } else {
            appFormDefines.value[i].disabled = true;
          }
        }
      }
    });
    onBeforeMount(() => {
      store.commit('root/SET_CURRENT_PAGE', i18n.t('attendanceRecord'));
    });
    onBeforeUnmount(() => {
      store.commit('root/SET_CURRENT_PAGE', '');
    });
    // generate data from api
    const viewTimeCardSelected = computed(() => {
      let objectData = { data: [] };
      if (resultViewItem?.value?.setting_view_time_card) {
        objectData = JSON.parse(resultViewItem?.value?.setting_view_time_card);
      }
      const listData: SettingViewTimePostItemType[] = objectData.data;
      if (listData.length) {
        return listData.filter((item) => !!item.data_master_code);
      }
      return [];
    });
    const viewTimeCardDefine = computed(() => {
      const listCodeSelected = viewTimeCardSelected.value
        .map((item) => item.data_master_code)
        .filter((item) => item);
      if (resultViewItem.value?.setting_view_time_card_defines?.length) {
        return resultViewItem.value.setting_view_time_card_defines.filter(
          (item) => !listCodeSelected.includes(item.data_master_code)
        );
      }
      return [];
    });

    const backRoute = () => {
      router.push(ConstantsRouter.Home);
    };
    const handleSelectTimeCard = (item, index) => {
      const appFormDefine = appFormDefines.value.find((e) => e.id === item);
      if (dailyAttendanceList.value) {
        switch (appFormDefine.application_define_type) {
          case APPLICATION_TYPE.CHECK_TIME_APPLICATION:
            router.push({
              path: `/new${ConstantsRouter.CheckTimeApplicationCreate}`,
              query: {
                id: item,
                target_date: dailyAttendanceList.value[index].date,
              },
            });
            break;
          case APPLICATION_TYPE.SCHEDULE_APPLICATION:
            router.push({
              path: `/new${ConstantsRouter.WorkScheduleChangeApplicationCreate}`,
              query: {
                id: item,
                target_date: dailyAttendanceList.value[index].date,
              },
            });
            break;
          case APPLICATION_TYPE.SUBSTITUTE_APPLICATION:
            router.push({
              path: `/new${ConstantsRouter.SubstituteApplicationCreate}`,
              query: {
                id: item,
                target_date: dailyAttendanceList.value[index].date,
              },
            });
            break;
          case APPLICATION_TYPE.LATE_EARLY_APPLICATION:
            router.push({
              path: `/new${ConstantsRouter.TimeCardLateEarlyRegister}`,
              query: {
                id: item,
                target_date: dailyAttendanceList.value[index].date,
              },
            });
            break;
          case APPLICATION_TYPE.ABSENCE_APPLICATION:
            router.push({
              path: `/new${ConstantsRouter.AbsenceApplicationCreat}`,
              query: {
                id: item,
                target_date: dailyAttendanceList.value[index].date,
              },
            });
            break;
          case APPLICATION_TYPE.WAITING_ALLOWANCE:
            router.push({
              path: `/new${ConstantsRouter.WaitingAllowanceApplicationCreate}`,
              query: {
                id: item,
                target_date: dailyAttendanceList.value[index].date,
              },
            });
            break;
          case APPLICATION_TYPE.TRANSFER_APPLICATION:
            router.push({
              path: `/new${ConstantsRouter.TransferAttendanceApplication}`,
              query: {
                id: item,
                target_date: dailyAttendanceList.value[index].date,
              },
            });
            break;
          case APPLICATION_TYPE.HOLIDAY_APPLICATION:
            router.push({
              path: `/new${ConstantsRouter.WorkInHolidayApplication}`,
              query: {
                id: item,
                target_date: dailyAttendanceList.value[index].date,
              },
            });
            break;
        }
      }
    };
    // call api when search month
    watch(monthSearch, () => {
      const timeSearch = moment(monthSearch.value);
      if (isAllValidMonth(monthSearch.value)) {
        paramsGetDailyAttendance.value.month = timeSearch.format('MM');
        paramsGetDailyAttendance.value.year = timeSearch.format('YYYY');
        startOfMonth.value = timeSearch
          .startOf('month')
          .format('YYYY/MM/DD(dd)');
        endOfMonth.value = timeSearch.endOf('month').format('YYYY/MM/DD(dd)');
        getDailyAttendanceRecord(paramsGetDailyAttendance.value);
        getDetailMyTimeCard(pp_employee_id, {
          target_month: timeSearch.format('YYYY-MM'),
        });
      }
    });

    const calculateTimeKeeping = () => {
      //call api recalculate
      const { recalculate } = useRecalculateTimeKeeping({
        month: paramsGetDailyAttendance.value.month,
        year: paramsGetDailyAttendance.value.year,
      });
      recalculate
        .then((error) => {
          if (error) {
            showToast('error', i18n.t('messageRecalculateFail'));
          } else {
            showToast('success', i18n.t('messageRecalculateSuccess'));
          }
        })
        .catch(() => {
          showToast('error', i18n.t('errors.serverError'));
        });
    };

    const daySummaryData = (value, key) => {
      const DAY_SUMMARY_FORMAT_KEY = [
        'total_number_of_leave_earlies',
        'total_number_of_lates',
      ];
      if (DAY_SUMMARY_FORMAT_KEY.includes(key)) return `0.0`;
      // return convertNumberToDateTime(value, null);
      return `${value}.0`;
    };

    watchEffect(() => {
      if (dailyAttendanceList.value) {
        dailyAttendanceList.value?.forEach((el, index) => {
          if (index === 0) {
            startDateDailyAttendance.value = el.date;
          }
          if (index - 1) {
            endDateDailyAttendance.value = el.date;
          }
        });
      }
    });
    const formatDateTime = (item) => {
      return moment(item).format('YYYY/MM/DD(dd)');
    };

    const goToBack = () => {
      router.push(`/new${ConstantsRouter.AllMemberTimeCard}`);
    };

    return {
      t: i18n.t,
      dailyAttendanceList,
      personalSettingFollowUnitIdDetail,
      loading,
      startOfMonth,
      endOfMonth,
      getDailyAttendanceRecord,
      resultViewItem,
      isShowPopupAttendance,
      displayDate,
      getColorDate,
      displayTime,
      convertNumberToDateTime,
      displayWorkingSchedule,
      openPopupAttendance,
      closePopupAttendance,
      backRoute,
      handleSelectTimeCard,
      handleItemTimeCard,
      formatDateTime,
      viewTimeCardSelected,
      viewTimeCardDefine,
      convertResultViewItem,
      headerTableTimeCard,
      listAppFormDefines,
      currentMonth,
      isConfirmTimeCard,
      detailTimeCardMonthly,
      monthSearch,
      appFormDefines,
      calculateTimeKeeping,
      daySummaryData,
      goToBack,
      ppEmployeeId,
      startDateDailyAttendance,
      endDateDailyAttendance,
    };
  },
});
