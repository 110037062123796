import api, { ApiResponse } from '@/clients/api';
import { ErrorCode } from './types/general/ErrorCode';
import {
  JSONApiResponseBody,
  JSONApiResponseErrorBody,
} from '@/api/types/general/JSONApiResponse';
import ConstantsAPI from '@/constants/api';
import {
  DeleteShiftPlanEmployee,
  ShiftPlanDetailApiPost,
  ShiftPlanDetailApiUpdate,
  ShiftPlanDetailId,
} from './types/shiftPlanDetail';
import { ShiftPlanId } from './types/ShiftPlanSubmit';

export async function getShiftPlanDetail(
  id: string
): Promise<
  | ApiResponse<200, JSONApiResponseBody<ShiftPlanDetailApiPost>>
  | ApiResponse<400, JSONApiResponseErrorBody<ErrorCode>>
  | ApiResponse<500, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.get(ConstantsAPI.ShiftPlanDetail(id));
}

export async function updateShiftPlanDetail(
  id: string,
  updateData: ShiftPlanDetailApiUpdate
): Promise<
  | ApiResponse<200, JSONApiResponseBody<ShiftPlanDetailId>>
  | ApiResponse<400, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.put(ConstantsAPI.ShiftPlanDetail(id), updateData);
}

export async function deleteShiftPlan(
  id: ShiftPlanId
): Promise<
  | ApiResponse<200, JSONApiResponseBody<ShiftPlanId>>
  | ApiResponse<400, JSONApiResponseErrorBody<ErrorCode>>
  | ApiResponse<500, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.delete(ConstantsAPI.ShiftPlanDetail(id));
}

export async function deleteShiftPlanDetail(
  params: DeleteShiftPlanEmployee
): Promise<
  | ApiResponse<200, JSONApiResponseBody<ShiftPlanId>>
  | ApiResponse<400, JSONApiResponseErrorBody<ErrorCode>>
  | ApiResponse<500, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.delete(ConstantsAPI.ShiftPlanEmployee, { params: params });
}
