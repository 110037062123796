import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "close-day-master-register-container mb-11" }
const _hoisted_2 = { class: "font-bold font-size-24 mb-5" }
const _hoisted_3 = { class: "flex items-center mb-6 ml-56" }
const _hoisted_4 = { class: "w-112" }
const _hoisted_5 = { class: "flex items-center mb-6 ml-56" }
const _hoisted_6 = { class: "w-112" }
const _hoisted_7 = { class: "items-center mb-7 ml-56" }
const _hoisted_8 = { class: "w-1/5 mr-2" }
const _hoisted_9 = { class: "mr-2 w-40" }
const _hoisted_10 = { class: "w-28 inline-block" }
const _hoisted_11 = { class: "font-size-16 w-10 ml-2" }
const _hoisted_12 = { class: "flex ml-64" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Label = _resolveComponent("Label")
  const _component_Input = _resolveComponent("Input")
  const _component_Select = _resolveComponent("Select")
  const _component_Button = _resolveComponent("Button")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, _toDisplayString(_ctx.t('closeDayMasterSettings')), 1),
    _createVNode("div", _hoisted_3, [
      _createVNode("div", _hoisted_4, [
        _createVNode(_component_Label, {
          mandatory: "",
          "text-label": _ctx.t('closeDayCode')
        }, null, 8, ["text-label"]),
        _createVNode(_component_Input, {
          validated: _ctx.isSubmit,
          modelValue: _ctx.paramsMonthlyClosingDay.monthly_closing_day_code,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.paramsMonthlyClosingDay.monthly_closing_day_code = $event)),
          required: true,
          "error-server": _ctx.listErrorServerReturn.monthly_closing_day_code,
          errors: _ctx.errors.monthly_closing_day_code,
          "onUpdate:errors": _cache[2] || (_cache[2] = ($event: any) => (_ctx.errors.monthly_closing_day_code = $event))
        }, null, 8, ["validated", "modelValue", "error-server", "errors"])
      ])
    ]),
    _createVNode("div", _hoisted_5, [
      _createVNode("div", _hoisted_6, [
        _createVNode(_component_Label, {
          mandatory: "",
          "text-label": _ctx.t('closeDayName')
        }, null, 8, ["text-label"]),
        _createVNode(_component_Input, {
          validated: _ctx.isSubmit,
          "error-server": _ctx.listErrorServerReturn.monthly_closing_day_name,
          modelValue: _ctx.paramsMonthlyClosingDay.monthly_closing_day_name,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.paramsMonthlyClosingDay.monthly_closing_day_name = $event)),
          errors: _ctx.errors.monthly_closing_day_name,
          "onUpdate:errors": _cache[4] || (_cache[4] = ($event: any) => (_ctx.errors.monthly_closing_day_name = $event)),
          required: true
        }, null, 8, ["validated", "error-server", "modelValue", "errors"])
      ])
    ]),
    _createVNode("div", _hoisted_7, [
      _createVNode("div", _hoisted_8, [
        _createVNode(_component_Label, {
          mandatory: "",
          "text-label": _ctx.t('closeDay')
        }, null, 8, ["text-label"])
      ]),
      _createVNode("div", _hoisted_9, [
        _createVNode("div", _hoisted_10, [
          _createVNode(_component_Select, {
            validated: _ctx.isSubmit,
            placeholder: _ctx.t('placeholderSelect'),
            "options-select": _ctx.closeDayOptionList,
            modelValue: _ctx.paramsMonthlyClosingDay.monthly_closing_day,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (_ctx.paramsMonthlyClosingDay.monthly_closing_day = $event)),
            "error-server": _ctx.listErrorServerReturn.monthly_closing_day,
            errors: _ctx.errors.monthly_closing_day,
            "onUpdate:errors": _cache[6] || (_cache[6] = ($event: any) => (_ctx.errors.monthly_closing_day = $event)),
            "item-text": "text",
            required: ""
          }, null, 8, ["validated", "placeholder", "options-select", "modelValue", "error-server", "errors"])
        ]),
        _createVNode("span", _hoisted_11, _toDisplayString(_ctx.t('sunday')), 1)
      ])
    ]),
    _createVNode("div", _hoisted_12, [
      _createVNode("div", null, [
        _createVNode(_component_Button, {
          class: "btn-register mr-20",
          onClick: _ctx.updateDataMonthlyClosingDay
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t('toRegister')), 1)
          ]),
          _: 1
        }, 8, ["onClick"])
      ]),
      _createVNode("div", null, [
        _createVNode(_component_Button, {
          class: "btn-back",
          onClick: _ctx.backToListCloseDayMaster
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t('backToTheList')), 1)
          ]),
          _: 1
        }, 8, ["onClick"])
      ])
    ])
  ]))
}