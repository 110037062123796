
import '../table.css';
import { computed, toRefs } from 'vue';
import { useI18n } from 'vue-i18n';
import iconChevronLeft from '@/assets/svg/iconChevronLeft.svg';
import iconChevronRight from '@/assets/svg/iconChevronRight.svg';
import TrBody from './TrBody.vue';
export default {
  name: 'my-table',
  components: { TrBody },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Array,
      required: true,
      default: () => [],
    },
    headers: {
      type: Array,
      required: true,
      default: () => [],
    },
    basicTable: {
      type: Boolean,
      default: true,
    },
    showSelect: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, ctx) {
    const i18n = useI18n();
    const { basicTable } = toRefs(props);
    const handleChangeValue = (item, value, dataIndex) => {
      ctx.emit('changeValue', item, value, dataIndex);
    };
    const toggleRow = (item, value, dataIndex) => {
      ctx.emit('hiddenChild', item, value, dataIndex);
    };
    return {
      t: i18n.t,
      classes: computed(() => ({
        'storybook-button--basic-table': basicTable.value,
      })),
      iconChevronLeft,
      iconChevronRight,
      handleChangeValue,
      toggleRow,
    };
  },
};
