import { ref } from 'vue';
import { getApplicationBasicFlowSettings } from '@/api/applicationBasicFlowSettings';
import { ApplicationBasicFlowSettingsApiGetResult } from '@/api/types/ApplicationBasicFlowSettingsApi';

export function useApplicationBasicFlowSettings() {
  const applicationBasicFlowSettingsList = ref<
    ApplicationBasicFlowSettingsApiGetResult[]
  >([]);
  const loading = ref(false);

  async function getApplicationBasicFlowSettingsData() {
    try {
      loading.value = true;
      const res = await getApplicationBasicFlowSettings();

      switch (res.status) {
        case 200:
          applicationBasicFlowSettingsList.value = res.data.data;
          break;
        default:
          applicationBasicFlowSettingsList.value = [];
          break;
      }
    } catch (error) {
      console.error(error);
    } finally {
      loading.value = false;
    }
  }

  return {
    applicationBasicFlowSettingsList,
    getApplicationBasicFlowSettingsData,
    loading,
  };
}
