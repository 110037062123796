import { useIcCardStore } from '@/store/modules/ic-card';
const icCardStore = useIcCardStore();

export async function sleep(msec) {
  return new Promise((resolve) => setTimeout(resolve, msec));
}

export async function send(device, data) {
  const uint8a = new Uint8Array(data);
  await device.transferOut(2, uint8a);
  await sleep(10);
}

export async function receive(device, len) {
  const data = await device.transferIn(1, len);
  await sleep(10);
  const arr: string[] = [];
  for (let i = data.data.byteOffset; i < data.data.byteLength; i++) {
    arr.push(data.data.getUint8(i));
  }
  return arr;
}

export async function close(device) {
  try {
    device.close();
    icCardStore.commit('disConnect');
  } catch (e) {
    console.log(e);
    icCardStore.commit('disConnect');
  }
}

export async function session(device) {
  await send(device, [0x00, 0x00, 0xff, 0x00, 0xff, 0x00]);
  await send(device, [
    0x00,
    0x00,
    0xff,
    0xff,
    0xff,
    0x03,
    0x00,
    0xfd,
    0xd6,
    0x2a,
    0x01,
    0xff,
    0x00,
  ]);
  await receive(device, 6);
  await receive(device, 13);
  await send(device, [
    0x00,
    0x00,
    0xff,
    0xff,
    0xff,
    0x03,
    0x00,
    0xfd,
    0xd6,
    0x06,
    0x00,
    0x24,
    0x00,
  ]);
  await receive(device, 6);
  await receive(device, 13);
  await send(device, [
    0x00,
    0x00,
    0xff,
    0xff,
    0xff,
    0x03,
    0x00,
    0xfd,
    0xd6,
    0x06,
    0x00,
    0x24,
    0x00,
  ]);
  await receive(device, 6);
  await receive(device, 13);
  await send(device, [
    0x00,
    0x00,
    0xff,
    0xff,
    0xff,
    0x06,
    0x00,
    0xfa,
    0xd6,
    0x00,
    0x01,
    0x01,
    0x0f,
    0x01,
    0x18,
    0x00,
  ]);
  await receive(device, 6);
  await receive(device, 13);
  await send(device, [
    0x00,
    0x00,
    0xff,
    0xff,
    0xff,
    0x28,
    0x00,
    0xd8,
    0xd6,
    0x02,
    0x00,
    0x18,
    0x01,
    0x01,
    0x02,
    0x01,
    0x03,
    0x00,
    0x04,
    0x00,
    0x05,
    0x00,
    0x06,
    0x00,
    0x07,
    0x08,
    0x08,
    0x00,
    0x09,
    0x00,
    0x0a,
    0x00,
    0x0b,
    0x00,
    0x0c,
    0x00,
    0x0e,
    0x04,
    0x0f,
    0x00,
    0x10,
    0x00,
    0x11,
    0x00,
    0x12,
    0x00,
    0x13,
    0x06,
    0x4b,
    0x00,
  ]);
  await receive(device, 6);
  await receive(device, 13);
  await send(device, [
    0x00,
    0x00,
    0xff,
    0xff,
    0xff,
    0x04,
    0x00,
    0xfc,
    0xd6,
    0x02,
    0x00,
    0x18,
    0x10,
    0x00,
  ]);
  await receive(device, 6);
  await receive(device, 13);
  await send(device, [
    0x00,
    0x00,
    0xff,
    0xff,
    0xff,
    0x0a,
    0x00,
    0xf6,
    0xd6,
    0x04,
    0x6e,
    0x00,
    0x06,
    0x00,
    0xff,
    0xff,
    0x01,
    0x00,
    0xb3,
    0x00,
  ]);
  await receive(device, 6);
  const idm: any = (await receive(device, 37)).slice(17, 24);
  let idmStr = '';
  for (let i = 0; i < idm.length; i++) {
    if (idm[i] < 16) {
      idmStr += '0';
    }
    idmStr += idm[i].toString(16);
  }
  return idmStr;
}

export async function connectDevice() {
  let device;
  try {
    device = await navigator.usb.requestDevice({ filters: [] });
    await device.open();
    icCardStore.commit('establishConnect');
    return device;
  } catch (e) {
    alert(e);
    icCardStore.commit('disConnect');
    throw e;
  }
}

export async function detectCard(device) {
  try {
    await device.selectConfiguration(1);
    await device.claimInterface(0);
    do {
      const idCard = await session(device);
      await sleep(500);
      icCardStore.commit('setIcCard', idCard);
    } while (
      /* eslint-disable no-constant-condition */
      true
    );
  } catch (e) {
    close(device);
    device.close();
    icCardStore.commit('disConnect');
    throw e;
  }
}
