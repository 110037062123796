
import { defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import Table from '@/components/Table.vue';
import DatePicker from '@/components/DatePicker.vue';
import Select from '@/components/Select.vue';
import Input from '@/components/Input.vue';
import iconSearch from '@/assets/svg/iconSearch.svg';
import Avatar from '@/components/Avatar.vue';
import avatarSample from '@/assets/svg/AvatarSample.svg';
import Button from '@/components/Button.vue';
import ModalApplicationApproval from '@/views/components/applicationApproval/ModalApplicationApproval.vue';
import router from '@/router';
import DropdownItem from '@/components/DropdownItem.vue';
import Checkbox from '@/components/Checkbox.vue';
import { handleYear, handleMonth } from '@/util/composables';
export default defineComponent({
  components: {
    Checkbox,
    Button,
    Input,
    Select,
    Table,
    DatePicker,
    Avatar,
    DropdownItem,
    ModalApplicationApproval,
  },
  setup() {
    const i18n = useI18n();
    const applicationHeaders = [
      {
        text: '',
        value: 'checkbox',
        width: '40px',
      },
      {
        text: i18n.t('applicationApprovalContent.approveAndReject'),
        value: 'target_date',
        width: '220px',
      },
      {
        text: i18n.t('targetDate'),
        value: 'target_date1',
        width: '180px',
      },
      {
        text: i18n.t('applicationApprovalContent.applicant'),
        value: 'applicant',
        width: '300px',
      },
      {
        text: i18n.t('applicationDate'),
        value: 'applicationDate',
        width: '150px',
      },
      {
        text: i18n.t('applicationApprovalContent.title'),
        value: 'application_title',
        width: '400px',
      },
      {
        text: i18n.t('applicationApprovalContent.applicationMessage'),
        value: 'application_message',
        width: '200px',
      },
      {
        text: i18n.t('applicationApprovalContent.nextApprover'),
        value: 'next_approver',
        width: '150px',
      },
    ];
    const applicationData = [
      {
        target_date: '2020/04/01',
        target_date1: '2020/04/01',
        applicant: '正社員',
        final_appointer: '正社員',
        applicationDate: '2021/00/00',
        applicationDateTime: '17:00',
        avatar: avatarSample,
        next_approver: '長戸 興太',
        application_title: '打刻申請',
        application_title_has: true,
        application_message: '退勤打刻忘れのため',
      },
      {
        target_date: '2020/04/01',
        target_date1: '2020/04/01',
        applicant: '正社員',
        applicationDate: '2021/00/00',
        applicationDateTime: '17:00',
        application_title: '休暇申請（年次有給休暇　全日）　',
        final_appointer: '正社員',
        avatar: avatarSample,
        next_approver: '長戸 興太',
        application_title_has: false,
        application_message: ' 使用のため',
      },
      {
        target_date: '2020/04/01',
        target_date1: '2020/04/01',
        applicant: '正社員',
        applicationDate: '2021/00/00',
        application_title: '休暇申請（年次有給休暇　半日）',
        applicationDateTime: '17:00',
        final_appointer: '正社員',
        avatar: avatarSample,
        next_approver: '長戸 興太',
        application_title_has: true,
        application_message: ' 使用のため',
      },
    ];
    const applicationTypeOptions = [
      {
        text: i18n.t('allApplications'),
        value: 0,
      },
      {
        text: i18n.t('engravingApplication'),
        value: 1,
      },
      {
        text: i18n.t('vacationRequest'),
        value: 2,
      },
      {
        text: i18n.t('disabilityApplication'),
        value: 3,
      },
      {
        text: i18n.t('holidayCommutingApplication'),
        value: 4,
      },
      {
        text: i18n.t('applicationForGoingOut'),
        value: 5,
      },
      {
        text: i18n.t('lateEarlyDepartureApplication'),
        value: 6,
      },
    ];
    const approvalStatusOption = [
      {
        text: i18n.t('approvalPending'),
        total: 3,
        value: 0,
        background: '#fc9c95',
      },
      { text: i18n.t('inProgress'), total: 3, value: 1, background: '#f8bf2d' },
      { text: i18n.t('confessed'), total: 12, value: 2, background: '#45d1d1' },
    ];
    const organizationOption = [
      { text: i18n.t('allAffiliatedOrganizations'), value: 0 },
    ];
    const workingStyleOption = [{ text: i18n.t('allWorkingStyle'), value: 0 }];
    const directorOption = [{ text: i18n.t('allWorkingStyle'), value: 0 }];
    const attributeOption = [{ text: i18n.t('allJobTitles'), value: 0 }];
    const allAttributes = [
      { text: i18n.t('applicationApprovalContent.allAttribute'), value: 0 },
    ];
    const dataTabs = [
      {
        text: i18n.t('applicationApprovalContent.approvalRequest'),
        path: '/application-approval',
      },
      {
        text: i18n.t('inProgress'),
        path: '1',
      },
      {
        text: i18n.t('completion'),
        path: '2',
      },
      {
        text: i18n.t('applicationApprovalContent.ownApplication'),
        path: '3',
      },
    ];
    const currentRouter = router.currentRoute;
    const approvalStatus = ref(0);
    const isShowApplicationPopup = ref(false);
    const closeAddTargetApplicationPopup = () => {
      isShowApplicationPopup.value = false;
    };
    const addTargetApplicationPopup = () => {
      isShowApplicationPopup.value = true;
    };

    const onClickApprovalStatus = (item) => {
      approvalStatus.value = item.value;
    };
    const applicationSelected = ref([]);
    const checkedAll = ref(false);
    const isSelect = ref(false);

    const showDropdownApplication = () => {
      isSelect.value = !isSelect.value;
    };
    const searchParams = ref({
      applicationTargetDate: 0,
      applicationDate: 0,
    });
    const optionsRadioDefault = [
      {
        value: 0,
        label: i18n.t('applicationApprovalContent.all'),
      },
      {
        value: 1,
        label: i18n.t('engravingApplication'),
      },
      {
        value: 2,
        label: i18n.t('vacationRequest'),
      },
      {
        value: 3,
        label: i18n.t('disabilityApplication'),
      },
      {
        value: 4,
        label: i18n.t('holidayCommutingApplication'),
      },
      {
        value: 5,
        label: i18n.t('transferApplication'),
      },
      {
        value: 6,
        label: i18n.t('waitingAllowanceApplication'),
      },
    ];
    return {
      t: i18n.t,
      applicationHeaders,
      applicationData,
      applicationTypeOptions,
      iconSearch,
      applicationSelected,
      approvalStatusOption,
      approvalStatus,
      checkedAll,
      isShowApplicationPopup,
      dataTabs,
      currentRouter,
      isSelect,
      searchParams,
      organizationOption,
      workingStyleOption,
      directorOption,
      attributeOption,
      allAttributes,
      optionsRadioDefault,
      showDropdownApplication,
      onClickApprovalStatus,
      addTargetApplicationPopup,
      closeAddTargetApplicationPopup,
      handleYear,
      handleMonth,
    };
  },
});
