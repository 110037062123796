
import {
  defineComponent,
  onBeforeMount,
  onBeforeUnmount,
  reactive,
  ref,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from '@/store';
import Table from '@/components/Table.vue';
import ConstantsRouter from '@/constants/router';

type ApplicationType = {
  type: string;
  plan_time: string;
  stamping_record: string;
  late_time: string;
  working_organization: string;
  reason: string;
  attach: string;
};

export default defineComponent({
  components: {
    Table,
  },
  setup() {
    const i18n = useI18n();
    const t = i18n.t;
    const store = useStore();

    onBeforeMount(() => {
      store.commit('root/SET_BACK_ROUTER_PATH', ConstantsRouter.TimeCard);
      store.commit('root/SET_CURRENT_PAGE', i18n.t(''));
    });
    onBeforeUnmount(() => {
      store.commit('root/SET_BACK_ROUTER_PATH', '');
      store.commit('root/SET_CURRENT_PAGE', '');
    });

    const lateTime = reactive({
      checkin: '',
      break_time: '',
    });

    const showFlags = reactive({
      late: false,
      early: false,
    });

    const leaveApplicationData = ref<ApplicationType[]>([]);

    const leaveTimeHeader = [
      {
        text: '',
        value: 'type',
      },
      {
        text: t('workingTimeRegister.checkin'),
        value: 'checkin',
      },
      {
        text: t('workingTimeRegister.checkout'),
        value: 'checkout',
      },
      {
        text: t('workingTimeRegister.breakStart'),
        value: 'break_start',
      },
      {
        text: t('workingTimeRegister.breakEnd'),
        value: 'break_end',
      },
      {
        text: t('workingTimeRegister.breakTime'),
        value: 'break_time',
      },
      {
        text: t('workingTimeRegister.goOut'),
        value: 'go_out',
      },
      {
        text: t('workingTimeRegister.goBack'),
        value: 'go_back',
      },
      {
        text: t('workingTimeRegister.overtime'),
        value: 'overtime',
      },
      {
        text: t('workingTimeRegister.checkinPlace'),
        value: 'checkin_place',
      },
      {
        text: t('workingTimeRegister.workingOrganization'),
        value: 'working_organization',
      },
      {
        text: '',
        value: '',
      },
    ];

    const leaveTimeData = [
      {
        type: t('workingTimeRegister.workingPlan'),
        checkin: '08:00',
        checkout: '17:00',
        break_start: '',
        break_end: '',
        break_time: '01:00',
        go_out: '',
        go_back: '',
        overtime: '',
        checkin_place: '',
        working_organization: '東日本営業本部インサイドセールスチーム',
      },
      {
        type: t('workingTimeRegister.stampingRecord'),
        checkin: '08:47',
        checkout: '16:37',
        break_start: '',
        break_end: '',
        break_time: '01:00',
        go_out: '',
        go_back: '',
        overtime: '',
        checkin_place: 'オフィス',
        working_organization: '店舗事業部東京丸の内店',
      },
    ];

    lateTime.checkin = '00:47';
    lateTime.break_time = '00:23';

    const leaveApplicationHeader = [
      {
        text: '',
        value: 'type',
      },
      {
        text: t('workingTimeRegister.planTime'),
        value: 'plan_time',
      },
      {
        text: t('workingTimeRegister.stampingRecord'),
        value: 'stamping_record',
      },
      {
        text: t('workingTimeRegister.lateTime'),
        value: 'late_time',
      },
      {
        text: t('workingTimeRegister.workingOrganization'),
        value: 'working_organization',
      },
      {
        text: t('workingTimeRegister.reason'),
        value: 'reason',
      },
      {
        text: t('workingTimeRegister.attach'),
        value: 'attach',
      },
    ];

    const lateDataSample = {
      type: t('workingTimeRegister.lateApplication'),
      plan_time: '08:00',
      stamping_record: '08:47',
      late_time: '00:47',
      working_organization: '東日本営業本部インサイドセールスチーム',
      reason: '申請理由を入力する',
      attach: '【20210901】電車遅延証明書_.jpg',
    };
    const earlyDataSample = {
      type: t('workingTimeRegister.earlyApplication'),
      plan_time: '17:00',
      stamping_record: '16:37',
      late_time: '00:23',
      working_organization: '東日本営業本部インサイドセールスチーム',
      reason: '申請理由を入力する',
      attach: '',
    };

    const toggleApp = (type: string) => {
      showFlags[type] = !showFlags[type];
      leaveApplicationData.value.length = 0;
      showFlags.late && leaveApplicationData.value.push(lateDataSample);
      showFlags.early && leaveApplicationData.value.push(earlyDataSample);
    };

    return {
      t,
      leaveTimeHeader,
      leaveTimeData,
      lateTime,
      leaveApplicationHeader,
      leaveApplicationData,
      showFlags,
      toggleApp,
    };
  },
});
