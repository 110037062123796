
import { computed, defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import Dropdown from '@/components/share/Dropdown/Dropdown.vue';
import Table from '@/components/share/Table/Table.vue';
import Button from '@/components/share/Button/index.vue';
import RadioInputGroup from '@/components/share/Radio/RadioInputGroup.vue';
import MultiSelect from '@/components/share/Dropdown/MultiSelect.vue';
import router from '@/router';
import ConstantsRouter from '@/constants/router';
import { BasicFlowSettingUpdateParam } from '@/model/ApproveSetting';
import { SETTING_REGISTER_FLOW } from '@/constants/workFlowSetting';
import { useGetListRole } from '@/api/composables/useRole';
import { showToast } from '@/util/useToastMessage';
import {
  handleUpdateBasicFlowSetting,
  useGetDetailAppBasicFlowSetting,
  useGetListAppFormDefines,
} from '@/api/composables/approvalSettings';
import { getSearchEmploy } from '@/api/composables/useGetSearchEmployee';
import { isValidateForm } from '@/util/useValidateForm';
import { useApplicationBasicFlowSettings } from '@/api/composables/useApplicationBasicFlowSettings';
import BackPreviousPage from '@/components/share/BackPreviousPage/index.vue';
import { ROLES_DEFAULT } from '@/constants/basicFlow';
import InputSearchEmployee from '@/components/share/Input/InputSearchEmployee.vue';

export default defineComponent({
  components: {
    InputSearchEmployee,
    MultiSelect,
    RadioInputGroup,
    Button,
    Table,
    Dropdown,
    BackPreviousPage,
  },
  setup() {
    const i18n = useI18n();
    const t = i18n.t;
    const headersBasicFlow = [
      { text: '', width: '48px', value: 'action' },
      { text: t('authorizer'), value: 'authorizer' },
    ];
    const roleOptionList = [
      {
        label: t('personalDesignation'),
        value: SETTING_REGISTER_FLOW.PERSON_DESIGNATION,
      },
      {
        label: t('privilegeRole'),
        value: SETTING_REGISTER_FLOW.PRIVILEGE_ROLE,
      },
    ];
    const numberOfApprovedFlowsList = Array.from(Array(10).keys()).map(
      (item) => item + 1
    );

    const formDataBasicFlow = ref<BasicFlowSettingUpdateParam>({
      application_define_id: null,
      total_level: 1,
      application_flow_details: [
        {
          setting_register_flow: 1,
          approval_level: 1,
          level_order: 1,
          role_ids: [ROLES_DEFAULT.ID], // value dafault là 管理者
        },
      ],
    });
    const {
      basicFlowSettingDetail,
      loading,
      handleDetailAppBasicFlowSetting,
    } = useGetDetailAppBasicFlowSetting();
    const applicationDefineId = router.currentRoute.value.query
      .applicationDefineId as string;
    const applicationFlowId = router.currentRoute.value.query
      .applicationFlowId as string;
    const applicationDefineName = computed(
      () =>
        applicationBasicFlowSettingsList.value.find(
          (item) => item.application_define_id === applicationDefineId
        )?.application_define_name
    );

    const applicationFlowDetailsByLevel = (level) => {
      return formDataBasicFlow.value.application_flow_details.filter(
        (e) => e.approval_level === level
      );
    };

    const addApplicationFlow = (index, approvalLevelIndex) => {
      formDataBasicFlow.value.application_flow_details.push({
        setting_register_flow: 0,
        approval_level: approvalLevelIndex + 1,
        level_order: index + 1,
        role_ids: [],
        pp_employee_ids: [],
      });

      formDataBasicFlow.value.application_flow_details
        .filter((item) => item.approval_level === approvalLevelIndex + 1)
        .forEach((e, index) => (e.level_order = index + 1));
    };
    const removeApplicationFlow = (item, approvalLevelIndex) => {
      const index = formDataBasicFlow.value.application_flow_details.indexOf(
        item
      );
      if (index != -1) {
        formDataBasicFlow.value.application_flow_details.splice(index, 1);
      }

      formDataBasicFlow.value.application_flow_details
        .filter((item) => item.approval_level === approvalLevelIndex + 1)
        .forEach((e, index) => (e.level_order = index + 1));
    };
    const changeTotalLevel = (e) => {
      if (typeof e === 'object') {
        return;
      }
      for (let i = 0; i < e; i++) {
        const approvalLevel = formDataBasicFlow.value.application_flow_details.filter(
          (item) => item.approval_level === i + 1
        );
        if (approvalLevel.length === 0) {
          addApplicationFlow(0, i);
        }
      }
      formDataBasicFlow.value.application_flow_details = formDataBasicFlow.value.application_flow_details.filter(
        (item) => {
          return item.approval_level && item.approval_level <= e;
        }
      );
    };

    const { getRoleListData, roleData } = useGetListRole({});
    const employeeList = ref([]);
    getSearchEmploy({}).then((res) => (employeeList.value = res.data));
    const getDetailBasicFlow = async () => {
      if (applicationFlowId) {
        const res = await handleDetailAppBasicFlowSetting(applicationDefineId);
        if (!res) {
          showToast('error', t('basicFlowAlreadyDeleted'));
          return;
        }
        formDataBasicFlow.value = JSON.parse(
          JSON.stringify(basicFlowSettingDetail.value)
        );
        formDataBasicFlow.value.application_define_id = null;
      }
    };
    getDetailBasicFlow();

    const backBasicFlowSettingsList = () => {
      router.push(`/new${ConstantsRouter.ApprovedSettingFlow}`);
    };
    const {
      handleGetListAppFormDefines,
      listAppFormDefines,
    } = useGetListAppFormDefines();

    const errorServer = ref({});
    const errors = ref({});
    const validated = ref(false);
    const isValid = () => {
      validated.value = true;
      return isValidateForm(errors);
    };
    const isDisableSubmit = computed(() => {
      return (
        Object.values(errors.value).some((e) => !!e) ||
        JSON.stringify(formDataBasicFlow.value) ===
          JSON.stringify({
            ...basicFlowSettingDetail.value,
            application_define_id: null,
          })
      );
    });

    const onSubmitCreateBasicFlow = async () => {
      if (!isValid()) return;
      const { update } = handleUpdateBasicFlowSetting({
        ...formDataBasicFlow.value,
        ...{ application_define_id: applicationDefineId },
      });
      await update
        .then((error) => {
          if (error) {
            errorServer.value = error.errors.value;
            if (error.errorsCommon.value) {
              showToast('error', error.errorsCommon.value);
            } else {
              showToast('warning', t('invalidInput'));
            }
          } else {
            showToast('success', i18n.t('basicFlowHasCreated'));
            backBasicFlowSettingsList();
          }
        })
        .catch(() => {
          showToast('error', i18n.t('errors.serverError'));
        });
    };

    const {
      applicationBasicFlowSettingsList,
      getApplicationBasicFlowSettingsData,
    } = useApplicationBasicFlowSettings();
    getApplicationBasicFlowSettingsData();

    const onChangeAppFormDefines = async (id) => {
      if (typeof id === 'object') {
        return;
      }
      if (id) {
        await handleDetailAppBasicFlowSetting(id);
        formDataBasicFlow.value = JSON.parse(
          JSON.stringify(basicFlowSettingDetail.value)
        );
        formDataBasicFlow.value.application_define = id;
      }
    };

    return {
      t,
      SETTING_REGISTER_FLOW,
      headersBasicFlow,
      formDataBasicFlow,
      roleOptionList,
      numberOfApprovedFlowsList,
      getRoleListData,
      roleData,
      employeeList,
      handleGetListAppFormDefines,
      listAppFormDefines,
      basicFlowSettingDetail,
      handleDetailAppBasicFlowSetting,
      applicationDefineId,
      errors,
      validated,
      applicationFlowId,
      loading,
      applicationBasicFlowSettingsList,
      applicationDefineName,
      onChangeAppFormDefines,
      onSubmitCreateBasicFlow,
      applicationFlowDetailsByLevel,
      addApplicationFlow,
      removeApplicationFlow,
      backBasicFlowSettingsList,
      changeTotalLevel,
      isValid,
      isDisableSubmit,
    };
  },
});
