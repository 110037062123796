
import { defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import Table from '@/components/Table.vue';
import moment from 'moment';
import avatar from '@/assets/svg/avatar.svg';
import Avatar from '@/components/Avatar.vue';
import DatePicker from '@/components/DatePicker.vue';

export default defineComponent({
  components: {
    Avatar,
    Table,
    DatePicker,
  },
  setup() {
    const i18n = useI18n();
    const shiftRegisterDataHeader = [
      {
        text: i18n.t('shiftRegistration.director'),
        value: 'director',
      },
      {
        text: i18n.t('shiftRegistration.name'),
        value: 'name',
      },
      {
        text: i18n.t('shiftRegistration.targetTime'),
        value: 'target_time',
      },
      {
        text: i18n.t('shiftRegistration.9AM'),
        value: '9AM',
        width: '100px',
      },
      {
        text: i18n.t('shiftRegistration.10AM'),
        value: '',
        width: '100px',
      },
      {
        text: i18n.t('shiftRegistration.11AM'),
        value: '',
        width: '100px',
      },
      {
        text: i18n.t('shiftRegistration.12AM'),
        value: '',
        width: '100px',
      },
      {
        text: i18n.t('shiftRegistration.1PM'),
        value: '',
        width: '100px',
      },
      {
        text: i18n.t('shiftRegistration.2PM'),
        value: '',
        width: '100px',
      },
      {
        text: i18n.t('shiftRegistration.3PM'),
        value: '',
        width: '100px',
      },
      {
        text: i18n.t('shiftRegistration.4PM'),
        value: '',
        width: '100px',
      },
      {
        text: i18n.t('shiftRegistration.5PM'),
        value: '',
        width: '100px',
      },
      {
        text: i18n.t('shiftRegistration.6PM'),
        value: '',
        width: '100px',
      },
      {
        text: i18n.t('shiftRegistration.7PM'),
        value: '',
        width: '100px',
      },
      {
        text: i18n.t('shiftRegistration.8PM'),
        value: '',
        width: '100px',
      },
      {
        text: i18n.t('shiftRegistration.9PM'),
        value: '',
        width: '100px',
      },
      {
        text: i18n.t('shiftRegistration.10PM'),
        value: '',
        width: '100px',
      },
      {
        text: i18n.t('shiftRegistration.11PM'),
        value: '',
        width: '100px',
      },
      {
        text: i18n.t('shiftRegistration.timeTotal'),
        value: 'time_total',
        align: 'center',
      },
    ];
    const shiftRegisterData = [
      {
        director: '店長',
        name: '斉藤 亜沙美',
        shift_pattern: 0,
        target_start_time: '9:00',
        target_end_time: '11:00',
        start_time: '9:00',
        end_time: '13:00',
        time_total: 8,
        avatar: avatar,
      },
      {
        director: '店長',
        name: '斉藤 亜沙美',
        shift_pattern: 1,
        target_start_time: '9:00',
        target_end_time: '11:00',
        start_time: '16:00',
        end_time: '21:00',
        time_total: 8,
        avatar: avatar,
      },
      {
        director: '店長',
        name: '斉藤 亜沙美',
        date: '2021-09-03',
        start_time: '13:00',
        end_time: '17:00',
        shift_pattern: 4,
        time_total: 8,
        avatar: avatar,
      },
      {
        director: '店長',
        name: '斉藤 亜沙美',
        shift_pattern: 3,
        target_start_time: '9:00',
        target_end_time: '11:00',
        start_time: '13:00',
        end_time: '17:00',
        color: '#007BC3',
        time_total: 8,
        avatar: avatar,
      },
      {
        director: '店長',
        name: '斉藤 亜沙美',
        shift_pattern: 4,
        target_start_time: '9:00',
        target_end_time: '11:00',
        start_time: '9:00',
        end_time: '15:00',
        color: '#ED5D5D',
        time_total: 8,
        avatar: avatar,
      },
      {
        director: '店長',
        name: '斉藤 亜沙美',
        shift_pattern: 5,
        time_total: 8,
        avatar: avatar,
      },
      {
        director: '店長',
        name: '斉藤 亜沙美',
        shift_pattern: 5,
        time_total: 8,
        avatar: avatar,
      },
    ];
    const date = ref(moment());
    const styleTimeLine = (item) => {
      if (item.target_start_time && item.start_time && item.end_time) {
        const minTime = moment(item.target_start_time, 'HH:mm:ss');
        const startTime = moment(item.start_time, 'HH:mm:ss');
        let endTime = moment(item.end_time, 'HH:mm:ss');
        const minutes = endTime.diff(startTime, 'minutes');
        const startMinutes = startTime.diff(minTime, 'minutes');
        return {
          width: (minutes / 60) * 100 + 'px',
          left: (startMinutes / 60) * 100 + 'px',
        };
      }
    };
    const displayTargetTime = (item) => {
      if (item.target_start_time && item.target_end_time) {
        return item.target_start_time + ' ~ ' + item.target_end_time;
      }
    };
    const displayTimeTotal = (item) => {
      if (item.time_total) {
        return item.time_total + i18n.t('hours');
      }
    };
    return {
      t: i18n.t,
      shiftRegisterData,
      shiftRegisterDataHeader,
      date,
      styleTimeLine,
      displayTargetTime,
      displayTimeTotal,
    };
  },
});
