
import { defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import Table from '@/components/Table.vue';
import Input from '@/components/Input.vue';
import Button from '@/components/Button.vue';
import router from '@/router';
import YearInput from '@/components/YearInput.vue';
import { ScheduleWorking } from '@/model/ScheduleWorking';
import { isValidateForm } from '@/util/useValidateForm';
import ConstantsRouter from '@/constants/router';
import { showToast } from '@/util/useToastMessage';
import {
  useDetailScheduleWorking,
  usePostScheduleWorking,
  useUpdateScheduleWorking,
} from '@/api/composables/scheduleWorking';
import { computed, watchEffect } from '@vue/runtime-core';
import Label from '@/components/Label.vue';
import { useRoute } from 'vue-router';

export default defineComponent({
  components: { YearInput, Button, Table, Input, Label },
  setup() {
    const i18n = useI18n();
    const t = i18n.t;
    const route = useRoute();
    const scheduleLaborPatternData = [
      { month: '1月' },
      { month: '2月' },
      { month: '3月' },
      { month: '4月' },
      { month: '5月' },
      { month: '6月' },
      { month: '7月' },
      { month: '8月' },
      { month: '9月' },
      { month: '10月' },
      { month: '11月' },
      { month: '12月' },
    ];
    const scheduleLaborPatternHeaders = [
      { text: i18n.t('month'), value: 'month', width: '80px' },
      {
        text: i18n.t('scheduledWorkingDays'),
        value: 'scheduled_working_days',
        width: '25%',
        verticalAlign: 'top',
      },
      {
        text: i18n.t('scheduledWorkingHours'),
        value: 'scheduled_working_hours',
        verticalAlign: 'top',
      },
      {
        text: i18n.t('legalWorkingHours'),
        value: 'legal_working_hours',
        verticalAlign: 'top',
      },
    ];
    const backToListScheduleLaborPattern = () => {
      router.push(ConstantsRouter.ScheduledLaborPattern);
    };
    const paramApiPost = ref<ScheduleWorking>({
      administrative_working_schedule_id: '',
      administrative_working_schedule_year_apply: new Date().getFullYear(),
      administrative_working_schedule_details: Array.from(Array(12)).map(
        (e, i) => {
          return {
            month: i + 1,
            working_days_in_month: 0,
            working_hours_in_month_by_organization: 0,
            working_minutes_in_month_by_organization: 0,
            working_hours_in_month_by_statutory: 0,
            working_minutes_in_month_by_statutory: 0,
          };
        }
      ),
    });
    const validated = ref(false);
    const errors = ref({});
    const errorsServer = ref({});
    const isValid = () => {
      validated.value = true;
      return isValidateForm(errors);
    };
    const scheduleWorkingId = router.currentRoute.value.query.id as string;
    const isCopyAction =
      ConstantsRouter.ScheduledLaborPatternCopy ===
      router.currentRoute.value.path;
    const isUpdateAction = scheduleWorkingId && !isCopyAction;
    const submitCreateScheduleWorking = () => {
      const { registered } = usePostScheduleWorking(paramApiPost.value);
      registered
        .then((errors) => {
          if (errors) {
            errorsServer.value = errors.errors.value;
            if (errors?.errorsCommon.value) {
              showToast('error', errors?.errorsCommon.value);
            }
          } else {
            showToast('success', t('successMessage.workingScheduleCreated'));
            backToListScheduleLaborPattern();
          }
        })
        .catch(() => {
          showToast('error', t('errors.serverError'));
        });
    };
    const submitUpdateScheduleWorking = () => {
      const { update } = useUpdateScheduleWorking(
        scheduleWorkingId,
        paramApiPost.value
      );
      update
        .then((errors) => {
          if (errors) {
            errorsServer.value = errors.errors.value;
            if (errors?.errorsCommon.value) {
              showToast('error', errors?.errorsCommon.value);
            }
          } else {
            showToast('success', t('successMessage.workingScheduleUpdated'));
            backToListScheduleLaborPattern();
          }
        })
        .catch(() => {
          showToast('error', t('errors.serverError'));
        });
    };
    const handleCreateOrUpdateScheduleWorking = () => {
      if (!isValid()) return;
      if (isUpdateAction) {
        submitUpdateScheduleWorking();
        return;
      } else if (route.path === ConstantsRouter.ScheduledLaborPatternCopy) {
        submitCreateScheduleWorking();
      } else {
        submitCreateScheduleWorking();
      }
    };
    const { getDetailData, scheduleWorkingDetail } = useDetailScheduleWorking();
    if (scheduleWorkingId) {
      getDetailData(scheduleWorkingId);
    }
    watchEffect(() => {
      if (
        scheduleWorkingDetail.value &&
        route.path === ConstantsRouter.ScheduledLaborPatternUpdate
      ) {
        paramApiPost.value = { ...scheduleWorkingDetail.value };
      } else if (
        scheduleWorkingDetail.value &&
        route.path === ConstantsRouter.ScheduledLaborPatternCopy
      ) {
        paramApiPost.value.administrative_working_schedule_id = scheduleWorkingId;
        paramApiPost.value.administrative_working_schedule_code =
          scheduleWorkingDetail.value?.administrative_working_schedule_code;
        paramApiPost.value.administrative_working_schedule_name =
          scheduleWorkingDetail.value?.administrative_working_schedule_name;
        paramApiPost.value.administrative_working_schedule_year_apply =
          scheduleWorkingDetail.value?.administrative_working_schedule_year_apply;
      }
    });
    const isDisableAction = computed(() => {
      return isCopyAction;
    });
    return {
      t,
      scheduleLaborPatternData,
      scheduleLaborPatternHeaders,
      paramApiPost,
      validated,
      errors,
      isUpdateAction,
      errorsServer,
      isDisableAction,
      scheduleWorkingDetail,
      isValid,
      backToListScheduleLaborPattern,
      submitCreateScheduleWorking,
      handleCreateOrUpdateScheduleWorking,
      submitUpdateScheduleWorking,
    };
  },
});
