import api, { ApiResponse } from '@/clients/api';
import { ErrorCode } from './types/general/ErrorCode';
import ConstantsAPI from '@/constants/api';
import {
  JSONApiResponseBody,
  JSONApiResponseErrorBody,
} from './types/general/JSONApiResponse';

import { CheckinParams } from '@/api/types/CheckinTimeKeeping';

export async function postCheckinTimeKeeping(
  postData: CheckinParams
): Promise<
  | ApiResponse<200, JSONApiResponseBody<CheckinParams[]>>
  | ApiResponse<400, JSONApiResponseErrorBody<ErrorCode>>
  | ApiResponse<500, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.post(ConstantsAPI.TimeKeepingCheckIn, postData);
}
