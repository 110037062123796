
import { defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import Table from '@/components/Table.vue';
import iconPlus from '@/assets/svg/Plus.svg';
import { computed } from '@vue/runtime-core';
import Avatar from '@/components/Avatar.vue';
import AvatarSample from '@/assets/svg/AvatarSample.svg';
import iconSend from '@/assets/svg/iconSend.svg';
import Popup from '@/components/Popup.vue';

export default defineComponent({
  components: { Popup, Avatar, Table },
  setup(props, { emit }) {
    const i18n = useI18n();
    const isShow = ref(false);
    const membersToSubmittedHeaders = [
      {
        text: i18n.t('name'),
        value: 'name',
      },
      {
        text: i18n.t('organization'),
        value: 'organization',
      },
      {
        text: i18n.t('workingStyle'),
        value: 'working_style',
      },
    ];
    const personToSubmittedData = [
      {
        name: '斉藤 亜沙美',
        organization: '営業本部',
        working_style: '正社員',
        avatar: AvatarSample,
      },
      {
        name: '安里 久美',
        organization: '営業本部',
        working_style: '正社員',
        avatar: AvatarSample,
      },
      {
        name: '斉藤 亜沙美',
        organization: '営業本部',
        working_style: '正社員',
        avatar: AvatarSample,
      },
      {
        name: '斉藤 亜沙美',
        organization: '営業本部',
        working_style: '正社員',
        avatar: AvatarSample,
      },
      {
        name: '斉藤 亜沙美',
        organization: '営業本部',
        working_style: '正社員',
        avatar: AvatarSample,
      },
      {
        name: '斉藤 亜沙美',
        organization: '営業本部',
        working_style: '正社員',
        avatar: AvatarSample,
      },
      {
        name: '斉藤 亜沙美',
        organization: '営業本部',
        working_style: '正社員',
        avatar: AvatarSample,
      },
      {
        name: '斉藤 亜沙美',
        organization: '営業本部',
        working_style: 'アルバイト',
        avatar: AvatarSample,
      },
      {
        name: '斉藤 亜沙美',
        organization: '営業本部',
        working_style: 'アルバイト',
        avatar: AvatarSample,
      },
      {
        name: '斉藤 亜沙美',
        organization: '営業本部',
        working_style: 'アルバイト',
        avatar: AvatarSample,
      },
      {
        name: '斉藤 亜沙美',
        organization: '営業本部',
        working_style: 'アルバイト',
        avatar: AvatarSample,
      },
      {
        name: '斉藤 亜沙美',
        organization: '営業本部',
        working_style: 'アルバイト',
        avatar: AvatarSample,
      },
      {
        name: '斉藤 亜沙美',
        organization: '営業本部',
        working_style: 'アルバイト',
        avatar: AvatarSample,
      },
      {
        name: '斉藤 亜沙美',
        organization: '営業本部',
        working_style: 'アルバイト',
        avatar: AvatarSample,
      },
      {
        name: '斉藤 亜沙美',
        organization: '営業本部',
        working_style: 'アルバイト',
        avatar: AvatarSample,
      },
      {
        name: '斉藤 亜沙美',
        organization: '営業本部',
        working_style: 'アルバイト',
        avatar: AvatarSample,
      },
      {
        name: '斉藤 亜沙美',
        organization: '営業本部',
        working_style: 'アルバイト',
        avatar: AvatarSample,
      },
      {
        name: '斉藤 亜沙美',
        organization: '営業本部',
        working_style: 'アルバイト',
        avatar: AvatarSample,
      },
      {
        name: '斉藤 亜沙美',
        organization: '営業本部',
        working_style: 'アルバイト',
        avatar: AvatarSample,
      },
      {
        name: '斉藤 亜沙美',
        organization: '営業本部',
        working_style: 'アルバイト',
        avatar: AvatarSample,
      },
      {
        name: '斉藤 亜沙美',
        organization: '営業本部',
        working_style: 'アルバイト',
        avatar: AvatarSample,
      },
      {
        name: '斉藤 亜沙美',
        organization: '営業本部',
        working_style: 'アルバイト',
        avatar: AvatarSample,
      },
    ];
    const personToUnSubmittedData = [
      {
        name: '斉藤 亜沙美',
        organization: '営業本部',
        working_style: '正社員',
        avatar: AvatarSample,
      },
      {
        name: '斉藤 亜沙美',
        organization: '営業本部',
        working_style: '正社員',
        avatar: AvatarSample,
      },
      {
        name: '斉藤 亜沙美',
        organization: '営業本部',
        working_style: '正社員',
        avatar: AvatarSample,
      },
      {
        name: '斉藤 亜沙美',
        organization: '営業本部',
        working_style: '正社員',
        avatar: AvatarSample,
      },
      {
        name: '斉藤 亜沙美',
        organization: '営業本部',
        working_style: '正社員',
        avatar: AvatarSample,
      },
      {
        name: '斉藤 亜沙美',
        organization: '営業本部',
        working_style: 'アルバイト',
        avatar: AvatarSample,
      },
      {
        name: '斉藤 亜沙美',
        organization: '営業本部',
        working_style: 'アルバイト',
        avatar: AvatarSample,
      },
      {
        name: '斉藤 亜沙美',
        organization: '営業本部',
        working_style: 'アルバイト',
        avatar: AvatarSample,
      },
      {
        name: '斉藤 亜沙美',
        organization: '営業本部',
        working_style: 'アルバイト',
        avatar: AvatarSample,
      },
      {
        name: '斉藤 亜沙美',
        organization: '営業本部',
        working_style: 'アルバイト',
        avatar: AvatarSample,
      },
      {
        name: '斉藤 亜沙美',
        organization: '営業本部',
        working_style: 'アルバイト',
        avatar: AvatarSample,
      },
      {
        name: '斉藤 亜沙美',
        organization: '営業本部',
        working_style: 'アルバイト',
        avatar: AvatarSample,
      },
      {
        name: '斉藤 亜沙美',
        organization: '営業本部',
        working_style: 'アルバイト',
        avatar: AvatarSample,
      },
      {
        name: '斉藤 亜沙美',
        organization: '営業本部',
        working_style: 'アルバイト',
        avatar: AvatarSample,
      },
      {
        name: '斉藤 亜沙美',
        organization: '営業本部',
        working_style: 'アルバイト',
        avatar: AvatarSample,
      },
      {
        name: '斉藤 亜沙美',
        organization: '営業本部',
        working_style: 'アルバイト',
        avatar: AvatarSample,
      },
      {
        name: '斉藤 亜沙美',
        organization: '営業本部',
        working_style: 'アルバイト',
        avatar: AvatarSample,
      },
      {
        name: '斉藤 亜沙美',
        organization: '営業本部',
        working_style: 'アルバイト',
        avatar: AvatarSample,
      },
      {
        name: '斉藤 亜沙美',
        organization: '営業本部',
        working_style: 'アルバイト',
        avatar: AvatarSample,
      },
      {
        name: '斉藤 亜沙美',
        organization: '営業本部',
        working_style: 'アルバイト',
        avatar: AvatarSample,
      },
    ];
    const departmentList = [
      { id: 1, name: '営業本部' },
      { id: 2, name: '001' },
      { id: 3, name: '002' },
    ];
    const workingStyleList = [
      { id: 1, name: '001' },
      { id: 2, name: '002' },
      { id: 3, name: '003' },
    ];
    const personSelected = ref([]);
    const numberOfPeopleSelected = computed(() => personSelected.value.length);
    const onSelectAllPerson = () => {
      personSelected.value = [];
    };
    const onClosePopup = () => {
      isShow.value = false;
      emit('onClose');
    };
    const requestToSubmittedInfo = ref({
      department_to_be_shifted: '営業本部',
      application_submit: {
        start: '2021/04/16',
        end: '2021/04/30',
      },
      submission_deadline: '2021/04/10',
    });
    const onPromptToSendRequest = () => {
      isShow.value = false;
      emit('onClose');
    };
    return {
      t: i18n.t,
      membersToSubmittedHeaders,
      numberOfPeopleSelected,
      iconPlus,
      departmentList,
      workingStyleList,
      personSelected,
      personToSubmittedData,
      personToUnSubmittedData,
      requestToSubmittedInfo,
      iconSend,
      isShow,
      onPromptToSendRequest,
      onClosePopup,
      onSelectAllPerson,
    };
  },
});
