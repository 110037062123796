import api, { ApiResponse } from '@/clients/api';
import {
  JSONApiResponseBody,
  JSONApiResponseErrorBody,
} from '@/api/types/general/JSONApiResponse';
import {
  MyTimeCardByMonthlyResult,
  MyTimeCardByMonthlyParams,
} from '@/api/types/MyTimeCardByMonthly';
import { ErrorCode } from '@/api/types/general/ErrorCode';
import ConstantsAPI from '@/constants/api';

export async function getListMyTimeCardByMonthly(
  id: string,
  params: MyTimeCardByMonthlyParams
): Promise<
  | ApiResponse<200, JSONApiResponseBody<MyTimeCardByMonthlyResult[]>>
  | ApiResponse<404, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.get(ConstantsAPI.MyTimeCardByMonthlyList(id), { params });
}
