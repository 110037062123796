{
  "add": "Add",
  "stamp": "Stamp",
  "nameCompany": "Ain Group",
  "timeCard": "Time Card",
  "checkIn": "Commuting",
  "checkOut": "Leave work",
  "year": "Year",
  "month": "Month",
  "monday": "Monday",
  "tuesday": "Tuesday",
  "wednesday": "Wednesday",
  "thursday": "Thursday",
  "friday": "Friday",
  "saturday": "Saturday",
  "sunday": "Sunday",
  "commuted": "I made a commute.",
  "goneout": "I left the office.",
  "checkInSuccess": "Check In successful",
  "checkOutSuccess": "Check Out successful",
  "attendanceRecord": "Attendance record",
  "humanResources": "Human Resources Department",
  "dailyAttendanceHeaders": {
    "apply": "Apply",
    "date": "Date",
    "finalConfirmation": "Final confirmation",
    "confirmationOfSuperior": "Confirmation of superior",
    "confirmationOfApplicant": "Confirmation of applicant",
    "workSchedule": "Work schedule",
    "performanceType": "Performance type",
    "checkin": "Checkin",
    "checkout": "Checkout",
    "breakStartTimeRounding": "Break start time rounding",
    "breakEndTimeRounding": "Break start time rounding",
    "breakStartTime": "Break start time",
    "breakEndTime": "Break end time",
    "timeWorkFixed": "Time work fixed",
    "overtime": "Overtime",
    "statutoryOvertime": "Statutory overtime",
    "nightWorkingTime": "Night working time",
    "statutoryHolidayTime": "Statutory holiday time",
    "break": "Break",
    "late": "Late",
    "early": "Early",
    "deemedPaidTime": "Deemed paid time",
    "organization": "Organization",
    "office": "Office",
    "teleWork": "Tele work",
    "unapprovedOvertime": "Unapproved overtime",
    "actualWorkingHours": "Actual working hours",
    "specialAllowance": "Special allowance",
    "note": "Note",
    "actualCheckin": "Actual checkin",
    "actualCheckout": "Actual checkout",
    "overtimeDivision1": "Overtime division 1",
    "overtimeDivision2": "Overtime division 2",
    "schedule": "Schedule",
    "workingDayType": "Working day type",
    "midnightCount": "Midnight count",
    "semiWaiting": "Semi waiting",
    "prescribed": "Prescribed",
    "overDuty": "Over duty",
    "legalHolidayPrescribed": "Legal holiday prescribed",
    "prescribedHolidaysPrescribed": "Prescribed holidays prescribed",
    "midnightOvertime": "Midnight overtime",
    "lateEarlyDepartureTime": "Late early departure time",
    "totalWorkingHours": "Total working hours",
    "applicationMessage": "Application message"
  },
  "required": "mandatory",
  "requiredMessage": "Please fill in this field to continue",
  "breakStartMsg": "The break start was stamped.",
  "breakEndMsg": "The end of the break was stamped.",
  "dailyAttendanceRecord": "Daily attendance record",
  "loadingText": "loading",
  "configuration": "Configuration",
  "organizationStaffMaster": "Organization / Staff Master",
  "attendanceMaster": "Attendance master",
  "vacationMaster": "Vacation master",
  "vacationHeaders": {
    "vacationCode": "Vacation code",
    "vacationName": "Vacation name",
    "abbreviation": "Abbreviation",
    "vacationType": "Vacation type",
    "displayImage": "Display image",
    "paidType": "Paid/Unpaid type",
    "additionType": "Addition/Subtraction type",
    "halfDayUnitAcquisition": "Half day unit acquisition",
    "timeUnitAcquisition": "Time unit acquisition"
  },
  "scheduledWorkingMaster": "Scheduled work master",
  "vacationMasterSetting": "Vacation master setting",
  "vacationGroupSetting": "Vacation group setting",
  "scheduledWorkHolidayMaster": "Scheduled work / holiday master",
  "workingStyleMaster": "Working style master",
  "timeZoneDivisionSetting": "Time zone division setting",
  "administratorSettings": "Administrator settings",
  "displayNotificationSettings": "Display / notification settings",
  "scheduledWorkMaster": "Scheduled work master",
  "systemWideSettingsAndConfirmation": "System usage status and system-wide settings and confirmation",
  "leaveMaster": "Leave Master",
  "holidayMaster": "Regular working・Holiday master",
  "timeZoneDivisionGroup": "Time zone division group",
  "timeFrameDivison": "Time frame divison settings",
  "applicableDate": "Applicable Date :",
  "showAllEmploymentTypes": "Show all employment types",
  "showAllWorkingStyleTypes": "Show all working styles",
  "narrowSearch": "Search",
  "individualApplicationPolicyCreate": {
    "individualApplicationPolicySetting": "Individual Application Policy Setting",
    "basicSetting": "Basic Setting",
    "targetEmployee": "Target Employee",
    "applicationStartDate": "Application Start Date",
    "applicationEndDate": "Application End Date",
    "applyMaster": "Apply Master",
    "companyCalendar": "Company Calendar",
    "prescribedLaborPattern": "Prescribed Labor Pattern",
    "closingDatePattern": "Closing Date Pattern",
    "workingStyle": "Working Style",
    "holidayGroup": "Holiday Group",
    "timezoneDivisionGroup": "Timezone Division Group",
    "userTimeZoneDivisionGroup": "User Timezone Division Group",
    "authority": "Authority",
    "save": "Save"
  },
  "attendanceDataHeaders": {
    "employmentTypeName": "Employment Type Name",
    "employmentTypeCode": "Employment Type Code",
    "workingStyleName": "Working style name",
    "workingStyleCode": "Working style code",
    "operationStartDate": "Operation Start Date",
    "editAction": "Edit",
    "removeAction": "Delete",
    "copyAction": "Copy"
  },
  "addAction": "To Add",
  "automatictBreak": {
    "overtimeSetting": "Overtime setting",
    "treatmentOfOvertime": "Treatment of overtime hours",
    "noteTreatmentOfOvertime": "* The legal time for a day is 8 hours, and the legal time for a week is 40 hours.",
    "treatOptionLegalHours": "All are recorded as outside the legal hours",
    "treatOptionNoRules": "Separately record non-scheduled hours and non-statutory hours",
    "weeklyCalculationOutside": "Weekly calculation outside legal working hours",
    "do": "Do",
    "doNot": "Do not",
    "calculateWeekCrossedDealine": "How to calculate the week that crosses the deadline",
    "calculateIncludingPreviousMonth": "Calculate including the previous month",
    "calculateOnDailyBasic": "Calculate on a daily basis",
    "overtimeApplicationFunc": "Overtime application function",
    "extraOvertimeFunction": "Extra overtime function",
    "use": "Use",
    "notUse": "Not use",
    "overtimeAppUse": "Use",
    "overtimeAppNotUse": "Not use",
    "overtimeAppOvertimeHoursAllowed": "Overtime hours within the shift are allowed",
    "extraOvertimeFunc": "Extra overtime function",
    "summaryItemsPremiumHours": "Selection of summary items outside the premium hours",
    "summaryIncludeHolidayWork": "Including legal holiday work",
    "summaryNotIncludeHolidayWork": "Does not include legal holiday work",
    "extraOver": "Extra overtime",
    "afterExtraHours": "After special extra hours",
    "overtimeWorkOfMonth": "Overtime work of the month",
    "overtimeHours": "Overtime hours",
    "recordTimeExceeded": "Record when the time is exceeded",
    "roundingCalculatingWeeks": "Rounding when calculating weeks across deadlines (first minority)",
    "truncate": "Truncate",
    "roundUp": "Round up",
    "rounding": "Rounding"
  },
  "placeholderSelectWorkingStyle": "Please select",
  "placeholderSelect": "Please select",
  "beingLateTime": {
    "roundingSetting": "Rounding setting",
    "seconds": "Seconds",
    "attendanceTimeStamp": "Attendance time stamp",
    "truncate": "Truncate",
    "roundUp": "Round up",
    "doNotRound": "Do not round",
    "leaveTime": "Leave time",
    "breakTimeStart": "Break start stamping time",
    "breakTimeEnd": "Break end stamping time",
    "minutesTitle": "Minutes",
    "attendanceStamp": "Attendance stamp",
    "unitMinuties": "Minutes",
    "leaveWorkStamp": "Leave work stamp",
    "roundingForWorkSchedule": "Rounding for work schedule",
    "fixCommutingTime": "Fix commuting time to shift start time",
    "fixedAction": "Fix",
    "notFixedAction": "Not fixed",
    "fixLeaveTime": "Fix leave time to end time of shift",
    "roundUpTime": "Round from the end of the shift to the specified time",
    "fixCommutingHint": "Select 'Fix' to round the commute time to the start time of the shift. Applies only if the work time is earlier than the shift start time.",
    "fixLeaveTimeHint": "Select 'Fix' to round the commute time to the start time of the shift. Applies only if the work time is earlier than the shift start time.",
    "fromTheShiftEndTime": "From the shift end time",
    "roundToLessMinutes": "Round to less than minutes",
    "ifYouSelectFix": "If you select 'Fix', the attendance of the work record will be rounded to the start time of the scheduled work. Applies only if the commute time is earlier than the scheduled start time."
  },
  "attendanceNote": "Make detailed settings for totaling working hours. Even if the working style is the same, if the overtime tabulation, break time setting, closing date and rounding of fractional hours are different, it is necessary to make new settings after creating a new one.",
  "basicSettings": "Basic settings",
  "update": "Update",
  "employmentTypeCode": "Employment type code",
  "employmentTypeName": "Employment type name",
  "employmentStatus": "Employment status",
  "workingStyleName": "Working style name",
  "workingStyleCode": "Working style code",
  "businessDaySwitchTime": "Business day switch time",
  "attendanceDeadline": "Attendance deadline",
  "weeklyClosingDay": "Weekly closing day",
  "lateNightWorkingTimeFrame": "Late Night working time frame",
  "followingDay": "Following day",
  "pleaseEnter": "Please enter",
  "pleaseSelect": "Please select",
  "time": "Time",
  "day": "Day",
  "daysOfWeek": {
    "mon": "Monday",
    "tue": "Tuesday",
    "wed": "Wednesday",
    "thu": "Thursday",
    "fri": "Friday",
    "sat": "Saturday",
    "sun": "Sunday"
  },
  "workingStyleSettings": "Working style settings",
  "dutySystem": "Duty system",
  "noteDutySystem": "The manager sets the daily scheduled working hours and calculates overtime work every day and week. Holiday working hours and midnight working hours are also calculated.",
  "standardLabor": "Standard labor",
  "fixedService": "Fixed service",
  "shiftWorkService": "Shift work",
  "transformationLabor": "Transformation labor",
  "discretionaryLabor": "Discretionary labor",
  "flextimeSystem": "Flextime system",
  "fixedDuty": "Fixed duty",
  "shiftWork": "Shift work",
  "weeklyWorkPatternAndWorkingHours": "Weekly work pattern and working hours",
  "workingStyleHeaders": {
    "dayOfWeek": "Day of week",
    "workingType": "Working type",
    "fixedWorkingHours": "Fixed working hours",
    "break": "Break"
  },
  "dayOfWeek": "Day of week",
  "workingType": "Working type",
  "fixedWorkingHours": "Fixed working hours",
  "dateAndTime": "Date and time",
  "nationalHoliday": "National holiday",
  "employeeWorkday": "Employee workday",
  "legalHoliday": "Legal holiday",
  "scheduledHoliday": "Scheduled holiday",
  "breakSetting": "Break Setting",
  "automaticBreakFunction": "Automatic break function",
  "automaticallyTakeBreaks": "Automatically take breaks",
  "standardWorkBreaks": "Standard work breaks",
  "notAutomaticBreak": "Not automatic break",
  "breakYourWorkPattern": "Get a break in your work pattern",
  "automaticBreak": "Automatic break",
  "workingHours": "Working hours",
  "moreThanMinutes": "More than minutes",
  "oneMinuteBreak": "One minute break",
  "priorityOfAutomaticBreaks": "Priority of automatic breaks",
  "timeStampAndAutomaticBreakOverlap": "Time stamp and automatic break overlap",
  "selectWorkDescription": "Please select a work place for this sunrise",
  "officeAttendance": "Office attendance",
  "teleworkCommute": "Telework commute",
  "labelAttendance": "Attendance organization:",
  "labelAttendancePlaceholder": "Weekly",
  "weekly": "Weekly",
  "onTheDay": "On the day",
  "beforeDay": "Befor day",
  "nextDay": "Next day",
  "monthy": "monthly",
  "1st": "1st",
  "2st": "2st",
  "3st": "3st",
  "4st": "4st",
  "5st": "5st",
  "jan": "January",
  "feb": "February",
  "mar": "March",
  "apr": "April",
  "may": "May",
  "jun": "June",
  "jul": "July",
  "aug": "August",
  "sep": "September",
  "oct": "October",
  "nov": "November",
  "dec": "December",
  "priorityBreakList": {
    "normalLateToNight": "Normal late to night",
    "lateNightToNormal": "Late night to normal",
    "one": "Predetermined> Non-prescribed> Midnight",
    "two": "Scheduled> Midnight> Non-scheduled",
    "three": "Midnight> Predetermined> Non-prescribed",
    "four": "Midnight> Overtime> Midnight",
    "five": "Non-scheduled> Prescribed> Midnight",
    "six": "Non-scheduled> midnight> scheduled"
  },
  "onlyTimedBreak": "Only the timed break is the break time",
  "automaticBreaksAsBreaks": "Automatic breaks as breaks",
  "sumOfStampedBreakAndAutomaticBreak": "The sum of the stamped break and the automatic break is the break time",
  "noteAttendanceDeadline": "※If you enter 0, it will be treated as the end of the month",
  "errors": {
    "required": "The field is required",
    "max": "The field must be less than",
    "min": "The field must be greater than",
    "characters": "characters",
    "range": "Please enter in",
    "timeInvalid": "Time is invalid",
    "serverError": "An error has occurred, please try again",
    "shiftPatternDeleteFail": "The work pattern could not be deleted",
    "updateTimeCardFail": "The display item could not be set"
  },
  "timeZone": {
    "buttonApply": "Apply",
    "placeholder": "Refined search",
    "description": "Set this when you want to total the working hours in a specific time zone. By applying the time zone division master set here to each staff member, it will be possible to aggregate.",
    "timeZoneCode": "Time zone code",
    "timeZoneName": "Time zone name",
    "lastModified": "Last Modified "
  },
  "successMessage": {
    "workStyleMasterHasBeenCreated": "Work style master has been created",
    "workStyleMasterHasBeenUpdated": "Work style master has been updated",
    "timezoneDivisionCreated": "Time zone division setting has been created",
    "timezoneDivisionUpdated": "Time zone division setting has been updated",
    "workPatternCreated": "Work pattern created",
    "timezoneDivisionCreatedFail": "The time zone could not be created",
    "timezoneDivisionUpdatedFail": "The time zone could not be updated",
    "workPatternUpdated": "Work pattern updated",
    "workPatternDeleted": "Work pattern deleted",
    "monthlyClosingDayDeleteSuccess": "The deadline has been removed",
    "createClosingDaySuccess": "The deadline has been created",
    "updateClosingDaySuccess": "The closing date has been updated",
    "successMessageDeleteScheduleWorking": "Prescribed labor patterns have been removed",
    "privilegeRoleCreated": "Privilege role created",
    "privilegeRoleUpdated": "Privilege role updated",
    "privilegeRoleDeleted": "Privilege role removed",
    "workingScheduleCreated": "The scheduled working pattern has been created",
    "workingScheduleUpdated": "The scheduled working pattern has been updated",
    "personaSettingApplyCreat": "Employee application was created",
    "personaSettingApplyUpdate": "Employee application could be updated",
    "personaSettingApplyDelete": "Employee application could be deleted",
    "theShiftHasBeenConfirmed": "The shift has been confirmed",
    "wasAbleToCancelTheShift": "I was able to cancel the shift",
    "wasAbleToNotifyTheShift": "I was able to notify the shift",
    "wasAbleToCancelTheNotificationOfTheShift": "I was able to cancel the notification of the shift",
    "theShiftWasSaved": "The shift was saved",
    "shiftPlanHasBeenCreated": "Shift plan has been created",
    "shiftPlanHasBeenUpdated": "Shift plan has been updated",
    "updateTimeCard": "Display items have been set",
    "shiftSubmitted": "Shift submitted"
  },
  "failMessage": {
    "monthlyClosingDayFail": " The deadline could not be deleted",
    "scheduleWorkingFail": "The prescribed labor pattern could not be deleted",
    "privilegeRoleDeletedFail": "Could not be deleted"
  },
  "timezoneCode": "Timezone code",
  "timezoneName": "Timezone name",
  "settingTargetDate": "Setting target date",
  "timezoneDetails": "Timezone details",
  "timezoneDetailsNote": "Text text text text text text text text",
  "timezoneHeaders": {
    "dayOfWeek": "Day of week",
    "settingTargetDate": "Setting target date",
    "fixedWorkingHours": "Fixed working hours"
  },
  "overtimeRegister": {
    "overtimeApplication": "Overtime Application",
    "targetDate": "Target date",
    "exampleDate": "2021/04/05（月）",
    "overtimeApplicationTime": "Overtime application time",
    "labelCheckin": "Attendance at work:",
    "textDateExample": "hh:mm",
    "totalWorkingHours": "Total working hours：",
    "overtime": "Overtime:",
    "buttonApply": "Apply",
    "buttonCancel": "Cancel",
    "attendanceAtWorkLb": "Attendance at work",
    "leavingWork": "Leaving work",
    "rest": "Rest",
    "workingTime": "Working time",
    "schedule": "Schedule",
    "stampingRecord": "Stamping record",
    "timeExample": "8:00",
    "timeExample2": "17:00",
    "timeExample3": "7:00",
    "timeExample4": "1:00",
    "timeExample5": "10:00",
    "timeExample6": "2:00",
    "ReasonApplication": "Reason for application",
    "approvalRoute": "Approval route",
    "primaryApproval": "Primary approval",
    "placeholderSelectOvertime": "Please select or enter"
  },
  "removeItemTitle": "Delete {name}",
  "removeItemBody": "All the set {name} will be deleted.",
  "confirmDelete": "Are you sure you want to delete it?",
  "actionDelete": "Delete",
  "deteleTimeZoneSuccessfully": "The time zone has been deleted",
  "deteleTimeZoneFailure": "The time zone could not be deleted",
  "deteleWorkingStyleMasterSuccessfully": "Delete working style master successfully",
  "deteleWorkingStyleMasterFailure": "Delete working style master failure",
  "apply": "Apply",
  "newRegistration": "New registration",
  "noData": "No data",
  "generalErrorMessage": "{name} has {error}",
  "timeZoneEnd": "Time zone end",
  "timeZoneStart": "Time zone start",
  "daysTotal": "Days total",
  "weekdays": "Weekdays",
  "holiday": "Holiday",
  "earlyLeave": "Early leave",
  "closed": "Closed",
  "absence": "Absence",
  "exchangeDay": "Exchange day",
  "summerVacation": "Summer vacation",
  "newYearHolidays": "New year holidays",
  "paidSpecialLeave": "Paid：Special leave",
  "unpaidSpecialLeave": "Unpaid：Special leave",
  "adjustmentDate": "Adjustment date",
  "timeSummary": "Time summary",
  "laborStandardsHours": "Labor standards hours",
  "timeWorkFixed": "timeWorkFixed",
  "overDuty": "Over duty",
  "extraOvertimeFunc": "Extra overtime func",
  "legalHolidayPrescribed": "Legal holiday prescribed",
  "prescribedHolidaysPrescribed": "Prescribed holidays prescribed",
  "extraOver": "Extra over",
  "break": "Break",
  "laborTotal": "Labor total",
  "workData": "Work data",
  "businessMonth": "Business month",
  "lateEarlyApplication": "Late early application",
  "targetDate": "Target date",
  "scheduledToWork": "Scheduled to work",
  "stampingRecord": "Stamping record",
  "totalWorkingHours": "Total working hours",
  "applicationType": "Application type",
  "reasonsForBeingLateEarly": "Reasons for being late / leaving early",
  "attachment": "Attachment",
  "uploadFile": "Upload File",
  "register": "Register",
  "cancel": "Cancel",
  "late": "Late",
  "early": "Early",
  "checkin": "Checkin",
  "checkout": "Checkout",
  "breakStartTimeRounding": "Break start time rounding",
  "breakEndTimeRounding": "Break end time rounding",
  "enterTheApplicationMessage": "Enter the application message",
  "noFileChosen": "No file chosen...",
  "files": "files",
  "applicationApproval": "Application approval",
  "searchByName": "Search byName",
  "approvalPending": "Approval pending",
  "inProgress": "In progress",
  "confessed": "Confessed",
  "allTypesOfApplicationNote": "All types of application can be displayed and selected from the pull-down menu",
  "operateOnAllChecked": "Operate on all checked",
  "approve": "Approve",
  "reject": "Reject",
  "representationPeriod": "Representation period",
  "allApplications": "All applications",
  "engravingApplication": "Engraving application",
  "vacationRequest": "Vacation request",
  "disabilityApplication": "Disability application",
  "holidayCommutingApplication": "Holiday commuting application",
  "applicationForGoingOut": "Application for going out",
  "lateEarlyDepartureApplication": "Late early departure application",
  "deploy": "Deploy",
  "employmentDivision": "Employment division",
  "targetEmployees": "Target employees",
  "commentOfApprover": "Comment of approver",
  "approverInResponse": "Approver in response",
  "finalAppointer": "Final appointer",
  "enterAdministratorComment": "Enter administrator comment",
  "settingApplication": "Application flow setting",
  "case": "case",
  "applyForRecognition": "Apply for recognition",
  "applicationHistory": "Application history",
  "applicationDate": "Application date",
  "monthDesignation": "Month designation",
  "specifyThePeriod": "Specify the period",
  "applicationTargetDate": "Application target date",
  "affiliationOrganization": "Affiliation organization",
  "allAffiliatedOrganizations": "All affiliated organizations",
  "director": "Director",
  "allJobTitles": "All job titles",
  "workingStyle": "Working style",
  "allWorkingStyle": "All working style",
  "applicationType2": "Application type",
  "engraveApplication": "Engrave application",
  "overtimeApplication": "Overtime application",
  "transferApplication": "Transfer application",
  "waitingAllowanceApplication": "Waiting allowance application",
  "status": "Status",
  "waitingForPrimaryApproval": "Waiting for primary approval",
  "waitingForSecondApproval": "Waiting for second approval",
  "remand": "Remand",
  "completion": "Completion",
  "search0": "Search",
  "export": "Export",
  "acknowledgementTimeOfDay": "Acknowledgement time of day",
  "staffCode": "Staff code",
  "fullName": "Full name",
  "applicationMessage": "Application message",
  "stampRegister": {
    "desc": "Stamp application",
    "targetDate": "Target date",
    "dateExample": "2021年04月05日（月）",
    "workPlace": "Work place",
    "stampEditing": "Stamp editing",
    "stampType": "Stamp type",
    "stampingDateAndTime": "Stamping dateand time",
    "attendanceOrganization": "Attendance organization",
    "delete": "Delete",
    "applicationMessage": "Application message",
    "attendanceWork": "Attendance work",
    "buttonApply": "Apply",
    "buttonCancel": "cancel",
    "leavingWork": "leaving work",
    "breakStart": "Break start",
    "breakEnd": "Break end",
    "goOut": "Go out",
    "goingBack": "Going back",
    "textDateExample": "hh:mm",
    "placeholderTextExample": "Enter the application message",
    "placeholderTextExample1": "East Japan Sales Headquarters Inside Sales Team",
    "approvalRoute": "Approval route",
    "applicationTime": "ApplicationTime",
    "setterAndCommunicator": "Setter / privilege role",
    "approvalDateAndTime": "Approval date and time",
    "comment": "Comment",
    "officeAttendance": "Office commuting",
    "teleworkCommute": "Telework commute",
    "checkInSuccess": "I made an attendance stamp",
    "checkOutSuccess": "I made a departure stamp",
    "breakStartSuccess": "I made a break start stamp",
    "breakEndSuccess": "I stamped the end of the break",
    "goutStart": "I made an outing stamp",
    "goOutEnd": "I went out and stamped"
  },
  "allEmployeesCanView": "All employees can view",
  "onlyViewableByEmpOfTheDepartment": "Only viewable by employees of the department",
  "onlyYouCanView": "Only you can view",
  "notViewable": "Not viewable",
  "applicationReason": "Application reason",
  "permissionSetting": "Permission setting",
  "applicationFlow": "Application Flow",
  "timeClockSettings": "Time clock settings",
  "monthlySummaryOutputLayoutSettings": "Monthly summary output layout settings",
  "iCCardRegistration": "IC card registration",
  "permissionCode": "Permission code",
  "authorityName": "Authority name",
  "permissionRoleList": "Permission role list",
  "privilegeEditing": "Privilege editing",
  "selectAllPermissions": "Select all permissions",
  "employeeAuthority": "Employee authority",
  "leaderAuthority": "Leader authority",
  "selectAuthToEdit": "Select an authorization role to edit",
  "managerPrivileges": "Manager privileges",
  "personnelManagerAuthority": "Personnel manager authority",
  "sysAdminPrivi": "System administrator privileges",
  "addPermissions": "Add permissions",
  "employeeCode": "Employee code",
  "shiftRegistration": {
    "menu": "shift",
    "submitDesired": "Submit the desired",
    "myShift": "My shift",
    "everyoneShift": "Everyone's shift",
    "buttonText": "hand over",
    "targetTime": "Target time",
    "timeTotal": "Time total",
    "confirmedShift": "Confirmed shift",
    "hopeShift": "Hope shift",
    "director": "Director",
    "name": "Name",
    "9AM": "9:00",
    "10AM": "10:00",
    "11AM": "11:00",
    "12AM": "12:00",
    "1PM": "13:00",
    "2PM": "14:00",
    "3PM": "15:00",
    "4PM": "16:00",
    "5PM": "17:00",
    "6PM": "18:00",
    "7PM": "19:00",
    "8PM": "20:00",
    "9PM": "21:00",
    "10PM": "22:00",
    "11PM": "23:00",
    "12PM": "24:00"
  },
  "workingScheduleRegister": {
    "workScheduleChangeApplication": "Work schedule change application",
    "currentWorkSchedule": "Current work schedule",
    "shiftPatternName": "Shift pattern name",
    "patternTimeExample": "Pattern A (8: 00-17: 00)",
    "workScheduleAfterChange": "Work schedule after change",
    "selectFromShiftPatterns": "Select from shift patterns",
    "enterDirectly": "Enter directly"
  },
  "workingTimeRegister": {
    "leaveTime": "Leave Time",
    "leaveApplication": "Leave Application",
    "checkin": "Checkin",
    "checkout": "Checkout",
    "breakStart": "Break Start",
    "breakEnd": "Break End",
    "breakTime": "Break Time",
    "goOut": "Go Out",
    "goBack": "Go Back",
    "overtime": "Overtime",
    "checkinPlace": "Checkin Place",
    "workingOrganization": "Working Organization",
    "workingPlan": "Working Plan",
    "stampingRecord": "Stamping Record",
    "lateTime": "Late Time",
    "apply": "Apply",
    "planTime": "Plan Time",
    "reason": "Application Reason",
    "attach": "Attachment",
    "lateApplication": "Late Application",
    "earlyApplication": "Early Application",
    "deleteApplication": "Delete Application"
  },
  "scheduledLaborMaster": "Prescribed labor master",
  "workPatternMaster": "Work pattern master",
  "workingStyleMaster2": "Working style master",
  "holidaySettings": "Holiday settings",
  "scheduleLaborPattern": "Schedule labor pattern",
  "closeDayMaster": "Close Day master",
  "belong": "Belong",
  "allAffiliations": "All affiliations",
  "express": "Express",
  "signUp": "Sign up",
  "japaneseHolidayRegistration": "Japanese holiday registration",
  "exportExcel": "Export excel",
  "holidayName": "Holiday name",
  "edit": "Edit",
  "delete": "Delete",
  "date": "Date",
  "dateStamp": "Date",
  "patternCode": "Pattern code",
  "patternName": "Pattern name",
  "copy": "Copy",
  "closeDayCode": "Close ady code",
  "closeDayName": "Close day name",
  "closeDayMasterSettings": "Close day master settings",
  "closeDay": "Close day",
  "toRegister": "Register",
  "backToTheList": "Back to the list",
  "scheduledWorkingDays": "Scheduled working days",
  "scheduledWorkingHours": "Scheduled working hours",
  "totalOutTime": "Going out time",
  "totalOfficeWorkTime": "office",
  "totalRemoteWorkTime": "Telework",
  "legalWorkingHours": "Legal working hours",
  "hour": "Hour",
  "minute": "Minute",
  "scheduledLaborPatternSetting": "Scheduled labor pattern setting",
  "targetYear": "Target year",
  "end": "End",
  "hours": "Hours",
  "registerMember": {
    "menu": "Shift management",
    "shiftSubmissionStatus": "Shift submission status",
    "shiftRequest": "Shift request",
    "notifyMembersCreated": "Notify members of created shifts",
    "target": "Display all target members",
    "targetPeriod": "Target period",
    "textExample": "2021/04/01~2021/04/10",
    "addMember": "Add member",
    "workPattern": "Work pattern"
  },
  "organizationalInformation": {
    "No": "No",
    "departmentCode": "Department code",
    "departmentName": "Department name",
    "dateChangeTime": "Date change time",
    "desc": "Affiliation information is a management unit such as an office, branch office, or store. Basically, create it for each time clock installation unit.",
    "title": "Since it is linked with the organization information of CYDAS PEOPLE, it cannot be edited or deleted on this screen.",
    "buttonAdd": "Registration of date change time",
    "affiliationInformation": "Affiliation information",
    "searchByDepartment": "Search by department code or department name"
  },
  "employeeInformation": {
    "employeeSettings": "Employee settings",
    "searchName": "Search by name",
    "allAffiliations": "All affiliations",
    "employee": "employee",
    "display": "display",
    "allEmploymentCategories": "All employment categories",
    "displayedResults": "Displayed results",
    "100Cases": "100 cases",
    "employeeCode": "Employee code",
    "loginID": "Login ID",
    "iCRegistration": "IC registration",
    "fingerRegistration": "Finger vein registration",
    "dateOfJoining": "Date of joining",
    "emailAddress": "Email address",
    "passwordLastUpdate": "Password last update date and time"
  },
  "shiftManagement": "Shift management",
  "requestSubmissionOfDesiredShift": "Request submission of desired shift",
  "submitDesiredShift": "Submit desired shift",
  "departmentForShiftCreation": "Department for shift creation",
  "applicationSubmit": "Application submit",
  "submissionDeadline": "Deadline for submission",
  "membersToSubmitted": "Members to be submitted",
  "beingCreated": "Plan a shift",
  "addTargetPerson": "Add target person",
  "name": "name",
  "organization": "Organization",
  "requestHistory": "Request history",
  "numberOfTargetPeople": "Number of target people",
  "requestDateAndTime": "Request date and time",
  "numberOfPeopleToBeSubmitted": "Number of people to be submitted",
  "requestSubmissionOfDesiredShiftBtn": "Request submission of desired shift",
  "person": "Person",
  "buttonCancel": "Cancel",
  "department": "Department",
  "selectAll": "Select all",
  "choosing": "Choosing",
  "pleaseSelectTheTargetPerson": "Please select the target person",
  "choice": "Choice",
  "departmentToBeShifted": "Department to be shifted",
  "submittedMembers": "Submitted members",
  "unSubmittedMembers": "Un submitted members",
  "promptToSendRequest": "Prompt to send request",
  "approvedSetting": {
    "btnBasicFlowSetting": "Basic flow settings",
    "btnBasicFlowApplication": "Basic flow application",
    "organizationCode": "Organization code",
    "organization": "organization",
    "basicFlowApply": "Basic flow application",
    "individualFlowSetting": "Individual flow setting",
    "organizationName": "Organization name"
  },
  "approvedFlow": {
    "settingApplication": "Application flow setting (basic flow setting)",
    "vacationRequest": "Vacation request",
    "approvedCopy": "Application flow copy",
    "stampCorrection": "Engraving amendment application",
    "btnAddApproved": "Add approver",
    "toRegister": "To register",
    "backToTheList": "Back to the list",
    "approvalFlowTextHeader": "Approval flow",
    "authorityRoleTextHeader": "Approver / Approval Authority Role",
    "authorizer": "Recognizer",
    "privilegeRole": "Privilege role",
    "manager": "Manager",
    "generalAffairs": "General Affairs Division"
  },
  "shiftSubmissionStatus": "Shift submission status",
  "stampPage": {
    "stampingMethod": "Stamping method",
    "stampingTime": "Stamping time",
    "attendanceOrganizationCode": "Attendance organization code",
    "stampingPoint": "Stamping point",
    "changeOrganization": "Change attendance organization",
    "selectOrganization": "Select an attendance organization",
    "searchOrganizations": "Search for attendance organizations"
  },
  "areYouSureDeleteTitle": "Do you want to delete?",
  "areYouSureDeleteBody": "Are you sure you want to delete this item?",
  "sureSubmissionRequestDelete": "Do you want to cancel the submission request?",
  "sureSubmissionRequestDeleteContent": "The desired shift already submitted by the staff will also be deleted.",
  "submissionRequestBtn": "Cancel the submission request",
  "affiliationDepartment": "Affiliation department",
  "shiftsRequestedToBeSubmitted": "Shifts requested to be submitted",
  "desiredShiftInput": "Desired shift input",
  "enterTheDesiredShift": "Enter the desired shift",
  "handOver": "hand over",
  "desiredWorkPattern": "Desired work pattern",
  "targetTime": "Target time",
  "withdrawSubmission": "Withdraw submission",
  "workPatternSetting": "Work pattern setting",
  "stampingBreak": "Stamping break",
  "notUse": "Not use",
  "use": "Use",
  "addABreak": "Add a break",
  "breakType": "break type",
  "standardWorkBreakTime": "Standard work break time",
  "breakTimeWhenStampedBreaks": "Break time when stamped breaks are used",
  "stampedBreak": "Get a stamped break",
  "stampedBreakAsBreakTime": "Make the stamped break a break time",
  "totalBreakTimeIsBreakTime": "The sum of the stamped breaks is taken as the break time",
  "autoBreakAndPatternBreaksOverlap": "When automatic breaks and work pattern breaks overlap",
  "workPatternsAsBreaks": "Set breaks in work patterns as breaks",
  "standardWorkAndPatternOverlap": "When a break of standard work and a break of work pattern overlap",
  "stampBreakAndPatternOverlap": "When the time stamp break and the work pattern break overlap",
  "sumTimeStampedBreakAndWorkPatternBreak": "The break time is the sum of the time stamped break and the work pattern break",
  "roundingCalculatingWeeksAcrossDeadlines": "Rounding when calculating weeks across deadlines (first minority)",
  "pleaseEnterTheApplicableDate": "Please enter the applicable date",
  "nextMonth": "Next month",
  "closeBtn": "Close",
  "theWorkingHoursOfRegisteredShiftValid": "The working hours of the registered shift will be valid",
  "addWork": "Add work pattern",
  "workPatternCode": "Work pattern code",
  "workPatternName": "Work pattern name",
  "ApplicableOra": "Applicable organization",
  "workHoursAndBreaks": "Working hours and breaks",
  "halfHolidaySetting": "Half-holiday setting",
  "halfOfhalfHoliday": "Half of the scheduled working hours will be half-holiday",
  "advanSetting": "Advanced Setting",
  "lateEarlyLeave": "Late / early leave",
  "invalid": "invalid",
  "valid": "Valid",
  "halfADayOff": "Half a day off",
  "halfAfternoon": "Half afternoon",
  "workingHou": "Working hours",
  "none": "none",
  "canBe": "can be",
  "deemedTime": "Deemed time",
  "contrasWorkHours": "Contract working hours per day x 0.5 (rounded up to the nearest whole number)",
  "breakTimes": "Break times",
  "breakTime": "Break time",
  "tokyoMar": "T000 Tokyo Marunouchi store",
  "applicationApprovalContent": {
    "title": " Application contents",
    "textArea": "Because I forgot to leave the office",
    "approverComment": "Approver comment",
    "updateDateAndTime": "Update date and time",
    "btnClose": "Close",
    "withdrawApplication": "Withdraw application ",
    "applicationStatus": "Application status",
    "exampleTime": "-- : --",
    "applicationList": "Application list",
    "approvalRequest": "approval request",
    "ownApplication": "Own application",
    "hideSearchConditions": "Hide search conditions",
    "attribute": "attribute",
    "allAttribute": "All attributes",
    "applicationTypeDisplaySelection": "Application type display selection",
    "all": "all",
    "approveAndReject": "Approve / Reject",
    "detailApplication": "Application details",
    "nextApprover": "Next approver",
    "applicationMessage": "Application message",
    "applicant": "Applicant",
    "subsequentApplication": "Subsequent application"
  },
  "calendarCode": "Calendar code",
  "calendarName": "Calendar name",
  "companyCalendarSettings": "Company calendar settings",
  "incoJapanHolidays": "Incorporate Japanese holidays",
  "japanHolidays": "Japanese holidays",
  "setCompanyHolidays": "Set company holidays",
  "compHolidaysName": "Company holiday name",
  "actionEdit": "change",
  "actionCopy": "copy",
  "loginPage": {
    "systemLogin": "Attendance system login",
    "id": "ID",
    "password": "Password",
    "holdLoginId": "Hold login ID",
    "forgetYourPassword": "Did you forget your password?",
    "login": "Login",
    "termsOfService": "Terms of service",
    "privacyPolicy": "Privacy policy",
    "agreeing": "Please use after agreeing to.",
    "loginSuccess": "You are now logged",
    "loginFail": "Wrong ID or password"
  },
  "selectAppOrangBtn": "Select applicable organization",
  "selectAppOrang": "Select applicable organization",
  "orangName": "Organization name",
  "select": "Select",
  "forgotPasswordPage": {
    "forgotPassword": "Forgot Password"
  },
  "logout": "Logout",
  "roundingOfMonthlyAggregation": "Rounding of monthly aggregation",
  "notRound": "Not round",
  "round": "Round",
  "achievementRoundingUnit": "Achievement rounding unit",
  "achievementRounding": "Achievement rounding",
  "truncate": "Truncate",
  "roundUp": "Round up",
  "rounding": "Rounding",
  "distinguishingBetweenLegalHolidaysAndRegularHolidays": "Distinguishing between legal holidays and regular holidays",
  "notDistinguish": "Do not distinguish",
  "distinguish": "Distinguish",
  "changeTheRegularHolidaysAcquiredWithinThe4thWeek": "Change the regular holidays acquired within the 4th week of the current month to legal holidays",
  "companyHolidayDeleted": "The company calendar has been deleted",
  "companyHolidayCouldNotDeleted": "The company calendar could not be deleted",
  "companyCalendarCreated": "A company calendar has been created",
  "companyClendarCreatedError": "Could not create company calendar",
  "pleaseSetWithTheButtonOnTheRight": "Please set with the button on the right",
  "llArgument": "Illegal argument.",
  "importTheFile": "Import the file",
  "exportTheFile": "Export the file",
  "basicFlowApplied": "Basic flow applied",
  "basicFlowCantApplied": "Basic flow could not be applied",
  "basicFlowHasCreated": "The basic flow has been created",
  "basicFlowHasCreatedFail": "Could not create basic flow",
  "timezoneDivisionGroupCode": "Time zone division group code",
  "timezoneDivisionGroupName": "Time zone division group name",
  "timezoneDivisionGroupDetails": "Time zone division group details",
  "timezoneDivisionGroupSetting": "Time zone division group setting",
  "timezoneDivisionCode": "Time zone division code",
  "timezoneDivisionName": "Time zone division name",
  "timezoneGroupHasBeenCreated": "A time zone group has been created.",
  "workingScheduleCode": "Working schedule code",
  "workingScheduleName": "Working schedule name",
  "deteleTimeZoneGroupSuccessfully": "The time zone group has been deleted",
  "deteleTimeZoneGroupFailure": "The time zone group could not be deleted",
  "timezoneDivisionGroupUpdated": "Time zone group updated",
  "organizationRegistrationEditing": "Organization registration/editing",
  "organizationHierarchySetting": "Organization hierarchy setting",
  "staffRegistrationEditing": "Staff registration/editing",
  "jobTitleSetting": "Job title setting",
  "attendanceSetting": "Attendance setting",
  "timezoneDivisionMaster": "Timezone division master",
  "displaySettings": "Display settings",
  "errorAlertSettings": "Error alert settings",
  "workStyleReformAlertSetting": "Work style reform alert setting",
  "notificationSettings": "Notification settings",
  "shiftRegistrationSettings": "Shift registration settings",
  "shiftCollectionMaster": "Shift collection master",
  "userStatus": "User status",
  "timezone": "Timezone",
  "copyMemberFromPreMonth": "Copy members from the previous month",
  "attributeOrOccupation": "Attribute / occupation",
  "doyouwantSendAShiftSubRequest": "Do you want to send a shift submission request?",
  "permissionsEdited": "The permissions have been edited",
  "authorSettingTarget": "Authority setting target",
  "send": "Send",
  "proceedToShiftCreation": "Proceed to shift creation",
  "saveShiftCreation ": "Save",
  "cancelShiftCreation": "Cancel",
  "confirmed": "Confirmed",
  "notified": "Notified",
  "hopeShift": "Hope shift",
  "shiftPlanUnder": "Shift plan under construction",
  "pastShiftPlans": "Past shift plans",
  "newShiftPlan": "New shift plan",
  "membersShifted": "Members to be shifted",
  "stationSquare": "Gotanda station square store",
  "planNewShift": "Plan a new shift",
  "shiftPlanName": "Shift plan name",
  "creationTargetPeriod": "Creation target period",
  "shiftCreation": "Shift creation",
  "requestToSubmitDesiredShift": "Request to submit desired shift",
  "displayItemSettings": "Display item settings",
  "selectDisplayItems": "Select display items",
  "displayItemList": "Display item list",
  "columnName": "Column name",
  "additionOfDisplay": "Addition of display",
  "hide": "Hide",
  "dateFormatError": "Please enter in the date format",
  "checkSelectedRoleList": "Permission role selection is required to access the permission edit screen",
  "permissionsEditedFail": "The permissions could not be edited",
  "cardRegistration": {
    "cardRegistrationInputText": "Enter your card number or read the card with a card reader to register",
    "connectPaSoRi": "Connect with PaSoRi",
    "staff": "Staff",
    "icCardUnregisteredOnly": "IC card unregistered only",
    "import": "Import",
    "textSelectExample": "T000 Tokyo Marunouchi store",
    "textSelectExample2": "Part-time job A",
    "numberCardExample": "949234232"
  },
  "fixedShift": "Fixed shift",
  "notificationDateAndTime": "Notification date and time",
  "submissionStatus": "Submission status",
  "batchConfirmation": "Batch confirmation",
  "cancelAllBatchConfirmation": "Cancel all batch confirmation",
  "bulkNotification": "Bulk notification",
  "cancelAllNotifications": "Cancel all notifications",
  "keep": "Keep",
  "excelDownload": "Excel download",
  "import": "Import",
  "sure": "Sure",
  "notice": "Notice",
  "kinds": "Kinds",
  "hope": "Hope",
  "confirmToRelease": "Confirm to release",
  "noticeCancellation": "Notice cancellation",
  "diligent": "Diligent",
  "stop": "Stop",
  "unSelect": "Un select",
  "reflectYourWishesInYourWorkSchedule": "Reflect your wishes in your work schedule",
  "selectFromWorkPatterns": "Select from work patterns",
  "addManually": "Add manually",
  "legalHolidays": "Legal holiday",
  "makeItDesignatedHoliday": "Make it a designated Holiday",
  "employeeStatus": "Employee status",
  "position": "Position",
  "confirmShift": {
    "deadlineForSubmit": "Deadline for submitting desired shifts",
    "submissionDeadline": "Set the submission deadline at once",
    "submissionDeadlineIndividually": "Set the submission deadline individually",
    "titlePopupCancelShift": "Do you want to cancel the shift submission request?",
    "subtitlePopupCancelShift": "Do you want to cancel the shift submission request?"
  },
  "bulkCancellation": "Bulk cancellation",
  "bulkTransmission": "Bulk transmission",
  "personalSettingApply": {
    "successMessage": "Individual application policy has been set",
    "buttonAdd": "Apply new employee settings",
    "successMessageCopy": "The application flow has been copied",
    "transferAttendance ": "Transfer attendance application",
    "updateSuccessMessage": "Application flow has been updated",
    "updateErrorMessage": "Application flow has been updated",
    "substituteLeaveApplication": "Substitute leave application",
    "transferAttendance": "Transfer attendance application",
    "absenceApplication": "Absence application"
  },
  "shiftPlanRequest": {
    "shiftName": "Shift name",
    "planning": "Making",
    "decided": "Confirmed",
    "announced": "Notified",
    "achieve": "Achive"
  },
  "returnScreen": "Return",
  "distinguishingBetweenOfficeAndTelework": "Distinguishing between office commuting and telework commuting",
  "markWhenYouGoOut": "Mark the time when you go out",
  "goingBackStampingTime": "Going back stamping time",
  "startOut": "Start out",
  "goBackStamping": "Going back stamping",
  "goOutSetting": "Going out setting",
  "managementOfOutingTime": "Management of outing time",
  "handlingOfOutingTime": "Handling of outing time",
  "useOutingStamping": "Use outing stamping",
  "notUseOutingStamping": "Do not use outing stamping",
  "notIncludedInWorkingHours": "Not included in working hours",
  "includedInWorkingHours": "Include in working hours",
  "takeAnAutomaticBreak": "If the work pattern break is not set, take an automatic break",
  "distinguishingBetweenOvertimeAndNonStatutoryWorking": "Distinguishing between overtime and non-statutory working hours",
  "settingRegularWorkingHoursPerDay": "Setting regular working hours per day",
  "scheduledWorkingHoursAsPrescribedWorking": "Scheduled working hours shall be the prescribed working hours of the day",
  "prescribedWorkingInPrescribedWorkingPattern": "Specify the prescribed working hours on the day set in the prescribed working pattern",
  "scheduledWorkingIsPrescribedAnPrescribedWorkingPattern": "The scheduled working hours shall be the prescribed working hours of the day, up to the prescribed working hours of the day set in the prescribed working pattern.",
  "theShiftPlanHasBeenDeleted": "The shift plan has been deleted",
  "theShiftPlanCouldNotBeDeleted": "The shift plan could not be deleted",
  "aShiftSubmissionRequestHasBeenSent": "A shift submission request has been sent",
  "theShiftSubmissionRequestCouldNotBeSent": "The shift submission request could not be sent",
  "addManual": "Add manually",
  "impossibility": "Impossibility",
  "workingOrganizationName": "Working organization name",
  "staffName": "Staff name",
  "desiredWorkingHours": "Desired working hours",
  "fixedWorkPattern": "Fixed work pattern",
  "fixedWorkHours": "Fixed working hours",
  "workingOrganization": "Working organization",
  "workingPatternHours": "Working hours",
  "duplicate": "Duplicate",
  "deletionOfWorkStyleMaster": "Deletion of work style master",
  "workingStyleMasterName": "Working style master name",
  "toRemove": "To remove",
  "checkTheStampingRecord": "Check the stamping record",
  "pleaseEnterYourName": "Please enter your name",
  "displayConditions": "Display conditions",
  "narrowDown": "Narrow down",
  "resetMember": "Reset",
  "closeUp": "Close up",
  "previousDayOfStamps": "Attendance stamps before this time will be treated as the previous day's stamps",
  "whatIsModifiedWork": "What is modified work: The manager sets the prescribed working hours every day and calculates overtime work every day or week. Holiday working hours and late night working hours are also calculated",
  "notChange": "Not change",
  "change": "Change",
  "workingHour": "Working hour",
  "fixedCheckoutNote1": "If you select fix, round your work results at the end of the scheduled work. Applies only if the end time is slower than the end time of work.",
  "fixedCheckoutNote2": "If you select Round from the scheduled end time to the time of work, if the set time is passed from the scheduled end time, the time will be retired to the scheduled end time.",
  "fixedCheckoutNote": "If you select Fix, round your work results at the end of the scheduled work. Applies only if the end time is slower than the end time of work. If you select Round from the scheduled end time to the time of work, if the set time is passed from the scheduled end time, the time will be retired to the scheduled end time.",
  "next": "Next",
  "roundingOfActualTime": "Rounding of actual time",
  "desBreakTimeSetting": "If there is no break time setting, the break setting for the working style will be applied.However, if the break setting enables breaks in the work pattern, it will be treated as no break.",
  "timeCardNew": {
    "employeeNumber": "Employee number",
    "viewAggregates": "View aggregates",
    "totalNumber": "Total number of days",
    "timeTabulation": "Time tabulation",
    "attendanceLock": "Attendance lock not yet",
    "targetMonth": "Target month",
    "collectiveIdentity": "Collective identity confirmation",
    "myAttendanceRecord": "My attendance record",
    "everyoneAttendanceRecord": "Everyone's attendance record",
    "numberOfHolidays": "Number of holidays",
    "numberOfDayHolidays": "Number of days of paid holidays taken",
    "numberOfDayTakeSpecial": "Number of days to take special holidays (paid)",
    "numberOfDayTakeSpecialHoliday": "Number of days of special holidays (unpaid)",
    "numberLateArrivals": "Number of late arrivals",
    "numberOfEarlyDepartures": "Number of early departures",
    "numberOfWaits": "Number of waits",
    "GoingOutTime": "Going out time"
  },
  "useOfStampingWhenGoingOut": "Use of stamping when going out",
  "time2": "time",
  "monthlyWorkingHour": "Monthly working hours",
  "detailedSettingsOutsideSpecialExtraHours": "Detailed settings outside special extra hours",
  "automaticBreakAdvancedSettings": "Automatic break advanced settings",
  "calculate": "Calculate",
  "notCalculate": "Not calculate",
  "breakStartTime": "Break start time",
  "goOutAndStartEngraving": "Go out and start engraving",
  "breakEndTime": "Break end time",
  "applicationDateFind": "Application date",
  "remandFind": "remand",
  "cancellation": "Cancellation of application",
  "workRecordApplication": "Work record application",
  "workRecordApplicationCheckTime": "Waiting allowance application",
  "pleaseEnterTheWorkingStyle": "Please enter the working style",
  "pleaseEnterTheDepartment": "Please enter the department",
  "nameApplication": "Yuki Yamada",
  "permanentEmployeeFixedA": "Permanent employee fixed A",
  "generalAffairsDivision": "General affairs division",
  "expressCondition": "Express condition",
  "period": "Period",
  "attendanceOrganization": "Attendance organization",
  "attribute": "Attribute",
  "thereIsAnError": "There is an error",
  "thereIsAnAlert": "There is an alert",
  "reset": "Reset",
  "unsupportedApplication": "Unsupported application",
  "error": "Error",
  "weekdayCommutingDays": "Weekday commuting days",
  "holidayWorkDays": "Holiday work days",
  "numberOfAbsenteeDays": "Number of absentee days",
  "holidayDays": "Holiday days",
  "numberOfSubstituteHolidays": "Number of substitut holidays",
  "thereAreDaysToObtain": "There are days to obtain",
  "numberOfDaysOfSpecialLeaveTakenPaid": "Number of days of special leave (paid) taken",
  "numberOfDaysOfSpecialLeaveTakenUnpaid": "Number of days to take special leave (unpaid)",
  "waitingDays": "Waiting days",
  "overtimeWorkingHours": "Overtime working hours",
  "nonStatutoryWorkingHours": "Non-statutory working hours",
  "outsideSpecialExtraHours": "Outside special extra hours",
  "nightWorkingTime": "Midnight time",
  "legalHolidayWorkingHours": "Legal holiday working hours",
  "legalHolidayMidnightTime": "Legal holiday midnight time",
  "paidDeemedTime": "Paid deemed time",
  "outsideSpecialExtra": "Extra hours",
  "actualWorkingHours": "Actual working hours",
  "sumWorkingHours": "Total working hours",
  "seeMore": "See more",
  "deleteWorkPattern": "Delete work pattern",
  "workPattern": "Work pattern",
  "pleaseEnterYourOrganization": "Please enter your organization",
  "pleaseEnterYourJobTitle": "Please enter your job title",
  "pleaseEnterTheAttribute": "Please enter the attribute",
  "pleaseEnterTheAttendanceOrganization": "Please enter the attendance organization",
  "actionDuplicate": "Duplicate",
  "basicFlowNote": "Please set the application approval flow for each application type. If you need to change by department, please set in Flow by department.",
  "formatDownload": "Format download",
  "numberOfApprovedFlows": "Number of approved flows",
  "initialize": "Initialize",
  "basicFlow": "Basic flow",
  "flowByDepartment": "Flow by department",
  "basicFlowApplicationType": "Application type",
  "approvedPersonFlowNote": "Please set the application approval flow for each application type. If you need to change by department, please set in \"Flow by department\". If you reset the application approval flow, it will become the basic flow.",
  "404": {
    "homePage": "Return to HOME",
    "desc404": "I'm sorry but,",
    "descNotFound404": "The page was not found. ,",
    "descNotFoundMain": "The page you're looking for has been deleted or renamed,",
    "descNotFound": "It may be temporarily unavailable.",
    "signOut": "Sign out"
  },
  "500": {
    "textHeaderSystem": "System Error",
    "descSystem": "If this screen still appears after a while, please contact your in-house administrator."
  },
  "copyTheApplicationFlow": "Copy the application flow",
  "youCanCopyTheApplicationFlowOfOtherApplications": "You can copy the application flow of other applications",
  "primaryApprovalFlow": "Primary approval flow",
  "authorizer": "Authorizer",
  "save": "Save",
  "applyBtn": "Apply",
  "personalDesignation": "Personal designation",
  "privilegeRole": "Privilege role",
  "times": "times",
  "approverMessage": "Approver message",
  "exampleTextRecord": "I went to work in another department with help. Prior oral approval of the superior.",
  "timeApprover": "1 time approver",
  "2timeApprover": "2 time approver",
  "personal": "personal",
  "privilegeRoleAdministrator": "Privilege role: Administrator",
  "sendBack": "Send Back",
  "rejectRecord": "Reject",
  "approveRecord": "Approve",
  "deleteTimezoneModal": {
    "title": "Delete time zone",
    "body": "Delete the time zone {name}."
  },
  "invalidInput": "There is an input error",
  "pleaseEnterTheStampDateAndTime": "Please enter the stamp date and time",
  "1": "One",
  "2": "Two",
  "3": "Three",
  "4": "Four",
  "5": "Five",
  "6": "Six",
  "7": "Seven",
  "8": "Eight",
  "9": "Night",
  "10": "Ten",
  "theApplicationHasBeenCreated": "The application has been created",
  "workPatternAlreadyDeleted": "It is a work pattern that has already been deleted",
  "workStyleMasterAlreadyDeleted.": "It is a work style master that has already been deleted.",
  "basicFlowAlreadyDeleted": "This is the basic flow that has already been deleted.",
  "becauseOfTheWaitingCharge": "Because of the waiting charge",
  "recalculate": "Recalculate",
  "messageRecalculateSuccess": "Recalculation has started",
  "messageRecalculateFail": "Recalculation could not start",
  "basicFlowHasCopied": "The basic flow has been copied",
  "bulkApproval": "Bulk approval",
  "bulkRemand": "Bulk remand",
  "updateSuccessMessagePersonalFlowSetting": "Department flow updated",
  "updateErrorMessagePersonalFlowSetting": "Department flow updated",
  "createSuccessMessagePersonalFlowSetting": "The department flow has been created",
  "createErrorMessagePersonalFlowSetting": "The department flow could not be created",
  "workScheduleChangeApplication": "Work schedule change application",
  "buttonApply": "Apply",
  "goOut": "Go out",
  "goBack": "Go back",
  "scheduledOvertime": "Scheduled overtime",
  "attendancePlace": "Attendance place",
  "checkinUnit": "Checkin unit",
  "application": "Application",
  "draft": "draft",
  "deleted": "Delete",
  "thereIsNoData": "There is no data",
  "approveSuccess": "The application has been approved",
  "rejectSuccess": "The application has been remanded",
  "deleteApplicationSuccess": "I canceled my application",
  "resendApplicationSuccess": "The application has been reapplied",
  "resendApplicationFail": "The application could not be reapplied",
  "notifyWorkRecord": "It is not possible to make an additional application to the work record being applied for. Please cancel the application and then apply again.",
  "reapply": "Reapply",
  "cancelTheApplication": "Cancel the application",
  "longNightWorkingHours": "Long-night working hours",
  "workRecord": "Work record",
  "stampType": "Stamp type",
  "time3": "Time",
  "fixedWorkResults": "Correction of work performance",
  "fixedWorkResultsNote": "If you want to fix and add the work record, click the Add button to add a row. If you want to delete the work record, please check and apply.",
  "breakTimeCorrection": "Break time correction",
  "breakTimeTotal": "Break time (total)",
  "pleaseEnterAnApplicationMessage": "Please enter an application message",
  "unit": "Unit",
  "checkInOffice": "Check in office",
  "administratorComments": "Administrator comments",
  "numberOfFlows": "Number of flows",
  "approveStatus": "Approve",
  "approveMultiple": "Approve multiple applications",
  "telework": "Telework",
  "office": "Office",
  "allRequiredFields": "All are required items.",
  "other": "+ Other",
  "oneDayConsecutiveWorkingHours": "One day consecutive working hours",
  "afterThisTimeTheStampScreenSwitchesToWorkMode": "After this time, the stamp screen switches to work mode",
  "correspondenceDateEndTime": "Correspondence date and time",
  "message": "Message",
  "cannotBeApproved": "Not approved or remanded",
  "copyOf": "copy",
  "ifYouSelectNotDistinguishAllWillBeLegalHolidays": "If you select not distinguish, all will be legal holidays.",
  "extraHours1": "Extra hours extra 1",
  "extraHours2": "Extra hours extra 2",
  "extraHours3": "Extra hours extra 3",
  "selectTheStartDateToApplyTheConfigurationChange": "Select the start date to apply the configuration change. Canceling to return to the previous setting screen.",
  "overtime": "Overtime",
  "lateApplication": "Late application",
  "earlyApplication": "Early application",
  "planTime": "Plan time",
  "behindTime": "Behind time",
  "attach": "Attachment",
  "leaveTime": "LeaveTime",
  "lateTime": "LateTime",
  "lateOrEarlyApplication": "Late / Early Application",
  "approvalRequest": "Approval request",
  "myApplication": "My application",
  "substituteApplication": {
    "numberOfDays": "Number of days left",
    "checkExpirationDate": "Check the expiration date",
    "acquisitionUnit": "Acquisition unit",
    "allDay": "All day",
    "halfDay": "Half day",
    "timeOff": "Time off",
    "expirationDateTitle": "Expiration date of available holidays",
    "holidayCommutingDay": "Holiday commuting day",
    "availableHolidays": "Available holidays",
    "dateExpiry": "Date of expiry",
    "from": "from",
    "makeVacation": "Make time a vacation",
    "defineTimeMorning": "Half-afternoon holiday   8:00 ～ 12:00",
    "defineTimeAfternoon": "Half-morning holiday 13:00 ～ 17:00"
  },
  "noDataAllow": "No data",
  "transferAttendanceApplication": "Transfer attendance application",
  "transitAttendanceDay": "Transit attendance day",
  "transferHoliday": "Transfer holiday",
  "makeVacation": "Make time a vacation",
  "defineTimeMorning": "Half-afternoon holiday   8:00 ～ 12:00",
  "defineTimeAfternoon": "Half-morning holiday 13:00 ～ 17:00",
  "icCardRegistration": "Ic Card Registration",
  "icCardNumber": "Ic Card Number",
  "cardNumberIdm": "Card Number (IDm)",
  "scanAndRegisterIdm": "Scan and register your card with a card reader. You can also enter your card number.",
  "theIcCardReader": "The IC card reader is working.",
  "deleteRegistered": "Do you want to delete the registered IC card?",
  "messageDeleteRegister": "Modal message.",
  "individualAuthorityRole": "Individual, Authority Role: Administrator",
  "individualAuthorityRoleAdministrator": "Permission role: administrator",
  "theDayOfStamps": "Take work-up earlier than this time is treated as the day of the day.",
  "calculationLegalWorkingHoursInWeeks": "Calculation of legal working hours in weeks",
  "deleteIcCard": "The IC card has been deleted",
  "registerIcCard": "IC card has been registered",
  "specialExtraAmountOutside": "Special extra amount outside",
  "specialExtraAmountOutside1": "Special extra amount outside 1",
  "specialExtraAmountOutside2": "Special extra amount outside 2",
  "specialExtraAmountOutside3": "Special extra amount outside 3",
  "departmentCode": "Department code",
  "fullWidth1": "１",
  "fullWidth2": "２",
  "fullWidth3": "３",
  "fullWidth4": "４",
  "fullWidth5": "５",
  "fullWidth6": "６",
  "fullWidth7": "７",
  "fullWidth8": "８",
  "fullWidth9": "９",
  "fullWidth10": "１０",
  "waitingAllowanceDeleteApplication": "Waiting allowance application-Delete application",
  "waitingAllowanceTargetBeDelete": "Target to be deleted",
  "deleteAchievement": "Delete this achievement",
  "WaitingAllowanceDeleteApplication": "Waiting allowance application-Delete application",
  "WaitingAllowanceTargetBeDelete": "Target to be deleted",
  "displayedResults": "Displayed results",
  "no": "No",
  "title": "Title",
  "member": "Member",
  "shiftPlan": "Shift plan",
  "allAreRequiredFields": "All are required fields.",
  "memberSelection": "Member selection",
  "shiftPlanNote": "The members of the target department are displayed. Add / remove as necessary. If the number of target members is 0, the shift cannot be confirmed.",
  "addOtherMembers": "Add other members",
  "targetDepartment": "Target department",
  "targetPeriod": "Target period",
  "resetToBasicFlow": "Reset to basic flow",
  "workInHoliday": {
    "targetDay": "Target day (holiday commuting day)",
    "scheduledWorkHolidays": "Scheduled to work on holidays",
    "scheduledSubstituteVacation": "Scheduled to take a substitute vacation",
    "scheduledDate": "Scheduled date for taking substitute holidays",
    "requiredScheduledDate": "※〇/〇～〇/〇 Please set the scheduled holiday acquisition date in the period of"
  },
  "employeeNumber": "Employee number",
  "importShiftData": "Import shift data",
  "importTheCSVFileAndRegisterTheShift": "Import the CSV file and register the shift.",
  "exportShiftPlanNote": "For shifts, only work patterns and holidays that can be used in the target department can be registered. The data is fetched from the second line migration.",
  "exportShiftPlanNote2": "To edit and update the shift data for the target period, output the current shift data from Shift data",
  "templateFile": "Template file",
  "currentShiftData": "Current shift data",
  "fileSelection": "File selection",
  "fileSizeLimit": "File size limit",
  "extension": "Extension",
  "shiftsCanBeAddedManuallyLater": "Shifts can be added manually later",
  "enterPlaceAdministratorComment": "Please enter the approve message",
  "overwritingUpdateByFileUploadPossible": "Overwrite update by file upload is possible",
  "breakOfWordPattern": "Break of word pattern",
  "wordPatternBreakNotSet": "Work pattern break (if not set, automatic break is reflected)",
  "selectWhatToTreat": "Select what to treat as a break when the automatic break and the break of the work pattern overlap",
  "multipleApplication": "Remand multiple applications",
  "availableWorkPatterns": "Available work patterns",
  "refer": "Refer",
  "targetPerson": "Target person",
  "theNameHasBeenSelected": "The name has been selected.",
  "backToIndex": "Back to index",
  "making": "Making",
  "resolved": "Resolved",
  "expired": "Expired",
  "removeShift": "Remove shift",
  "switchUnitMemberSelectionsUpdate": "When you switch departments, the list of member selections at the bottom is updated.",
  "settingTimeRecoder": {
    "workingStyle" : "Working style",
    "browserStamping" : "Browser stamping",
    "terminalStamping": "Terminal stamping",
    "faceRecognitionStamp" : "Face recognition stamp",
    "toApprove" : "To approve",
    "notAllowed": "Not allowed",
    "usagePermissionSetting": "Usage permission setting",
    "timeClockType": "Time clock type",
    "standardWork": "Standard work",
    "standardWorkShift": "Standard work shift",
    "transformationLabor":"Transformation labor",
    "discretionaryLabor": "Discretionary labor",
    "partTimeJob": "Part-time job",
    "messageCreatSuccess": "Time clock settings have been created",
    "messageUpdateSuccess": "Time clock settings have been updated"
  },
  "confirmationOfCapturedData": "Confirmation of captured data",
  "theFileIsBeingRead": "The file is being read. Please wait",
  "takeIn": "Take in",
  "allLines": "All lines",
  "thisCSVFileContainsAnError": "This CSV file contains an error. Check the error details below and reselect the corrected file.",
  "ifYouWantToImportOnlyValidRows": "If you want to import only valid rows that do not contain any errors, press Import",
  "validLines": "Valid lines",
  "inValidLines": "Invalid Lines",
  "importShiftPlanSuccess": "Import is complete",
  "importShiftPlanError": "Failed to import. please try again.",
  "errorContents": "Error contents",
  "theFileSizeLimitHasBeenExceeded": "The file size limit has been exceeded",
  "importingRowData": "Importing row data",
  "abort": "Abort",
  "alertSetting": {
    "timeAttendanceData": "Time and attendance data",
    "workSchedule":"There is a work schedule and there is no stamping record",
    "noHolidayCommuting": "No application for work on holidays",
    "noOvertimeApplication": "No overtime application",
    "duplicateAttendanceData": "Duplicate attendance data",
    "duplicateStamps": "Duplicate stamps",
    "workStyleAlert": "Work style alert",
    "upperLimitOvertimeWork": "Upper limit of overtime work",
    "upperLimitOvertimeHolidayWork" : "Upper limit for overtime and holiday work",
    "upperLimitOvertimeWorkProvisions": "Upper limit of overtime work regarding special provisions",
    "errorNotification": "Error notification",
    "alertNotification": "Alert notification",
    "doNotNotify": "Do not notify",
    "messageCreatSuccess": "Error alert settings have been created",
    "messageUpdateSuccess": "Updated error alert settings"
  },
  "vacationCode": "Vacation code",
  "vacationName": "Vacation name",
  "abbreviation": "Abbreviation",
  "vacationType": "Vacation type",
  "set10FullWidthCharacters": "Please set within 10 full-width characters",
  "setNameNotOverlap": "Please set so that the name does not overlap with other masters",
  "advancedSetting": "Advanced setting",
  "annualPaidLeaveSetting": "Annual paid leave setting",
  "paidCalculationStandard": "Paid calculation standard",
  "additionToTheNumberWorkingDays": "Addition to the number of working days",
  "toAdd": "To add",
  "notAdd": "Do not add",
  "handlingOfPaidDeemedTime": "Handling of paid deemed time",
  "includedWorkingHours": "Included in working hours",
  "notIncludedWorkingHours": "Not included in working hours",
  "takingHalfDayLeave": "Taking half-day leave",
  "notAllowed": "Not allowed",
  "toApprove" : "To approve",
  "halfDayAcquisitionTimeSetting": "Half-day acquisition time setting",
  "acquisitionTimeSetScheduledWorkingHours": "The acquisition time set by the scheduled working hours on the scheduled acquisition date",
  "contractedWorkingHoursBeAcquisitionTime": "Half of the contracted working hours will be the acquisition time",
  "contractWorkingHoursStaffMaster": "Please set in the contract working hours of the staff master.",
  "takeHourlyVacation": "Take hourly vacation",
  "maximumNumberOfHoursForAcquisition": "Maximum number of hours for acquisition in hours",
  "setUniformly": "Set uniformly",
  "setIndividually": "Set individually",
  "pleaseSetMaximumNumberOfHours": "Please set in the Maximum number of hours for acquisition in units of time of the staff master.",
  "allowAcquisitionByTime": "Allow acquisition by time",
  "maximumNumberOfDaysForPaidHourlyLeave": "Maximum number of days for paid hourly leave",
  "takeVacationDuringTheTrialPeriod": "Take vacation during the trial period",
  "additionType": "Addition / subtraction type",
  "additionMethod": "Addition method",
  "subtractionMethod": "Subtraction method",
  "automaticPaidManagement": "Automatic paid management",
  "toManage": "To manage",
  "notManage": "Do not manage",
  "validityPeriod": "Validity period",
  "fromTheDateOfGrant": "From the date of grant",
  "validForTheYear": "Valid for the year",
  "maximumHoldingDays": "Maximum holding days",
  "initialGrantDays": "Initial grant days",
  "setTheInitialGrantInALumpSumAccordingOfMonth": "Set the initial grant in a lump sum according to the number of months elapsed from the paid start date",
  "fromThePaidStartDate": "From the paid start date",
  "and": "And",
  "grantDay": "Grant day",
  "setTheInitialGrantInALumpSumAccordingForEach": "Set the initial grant individually for each paid start date",
  "thePaidStartDateIs": "The paid start date is",
  "inTheCaseOf": "In the case of",
  "from": "From",
  "to": "To",
  "numberOfDaysGrantedFromTheNextTimeOnwards": "Number of days granted from the next time onwards",
  "grantFromTheNextTimeOnwardsFromTheFirstGrantDate": "Grant from the next time onwards from the first grant date",
  "giveTo": "Give to",
  "grantedEveryYearFromTheNextTime": "Granted every year from the next time onwards",
  "withHolidayPayAndDaysTable": "With holiday pay and days table",
  "withHolidayPaymentAndCalculationMethodOfDays": "With holiday payment and calculation method of days",
  "useNumberPerWeek": "Use contract working days per week",
  "useActualNumberOfWorkingDays": "Use actual working days",
  "colorSettings": "Color settings",
  "displayImage": "Display image",
  "annualPaid": "Annual paid",
  "scheduledWorkingDaysPerWeek": "Scheduled working days per week",
  "scheduledWorkingDaysPerYear": "Scheduled number of working days per year",
  "moreThanYear": "More than a year"
}
