import api, { ApiResponse } from '@/clients/api';
import { ErrorCode } from './types/general/ErrorCode';
import {
  JSONApiResponseBody,
  JSONApiResponseErrorBody,
} from './types/general/JSONApiResponse';
import ConstantsAPI from '@/constants/api';
import {
  RoleApiDeleteResult,
  RoleApiParamSearch,
  RoleApiPostData,
  RoleApiPostResult,
  RoleApiUpdateData,
  RoleApiUpdateResult,
  RoleGetData,
} from '@/api/types/Role';

export async function postRole(
  postData: RoleApiPostData
): Promise<
  | ApiResponse<200, JSONApiResponseBody<RoleApiPostResult>>
  | ApiResponse<201, JSONApiResponseBody<RoleApiPostResult>>
  | ApiResponse<400, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.post(ConstantsAPI.RoleCreate, postData);
}

export async function getRole(
  params: RoleApiParamSearch
): Promise<
  | ApiResponse<200, JSONApiResponseBody<RoleGetData[]>>
  | ApiResponse<404, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.get(ConstantsAPI.RoleList, { params: params });
}

export async function getRoleDetail(
  id: string
): Promise<
  | ApiResponse<200, JSONApiResponseBody<RoleApiUpdateData>>
  | ApiResponse<400, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.get(ConstantsAPI.RoleDetail(id));
}

export async function updateRole(
  id: string,
  updateData: RoleApiUpdateData
): Promise<
  | ApiResponse<200, JSONApiResponseBody<RoleApiUpdateResult>>
  | ApiResponse<400, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.put(ConstantsAPI.RoleDetail(id), updateData);
}

export async function deleteRole(
  id: string
): Promise<
  | ApiResponse<200, JSONApiResponseBody<RoleApiDeleteResult>>
  | ApiResponse<400, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.delete(ConstantsAPI.RoleDetail(id));
}
