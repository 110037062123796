import { ref } from 'vue';
import {
  ShiftPlanRequestParams,
  ShiftPlanRequestResultData,
} from '@/api/types/ShiftPlanRequest';
import { getListShiftPlanRequest } from '@/api/shiftRequest';

export default function () {
  const shiftPlanRequestData = ref<ShiftPlanRequestResultData[]>();
  const loading = ref(false);
  const response = ref();
  const pagination = ref({
    current_page: 1,
    per_page: 20,
    total: 0,
  });

  async function getListShiftPlanRequestData(params: ShiftPlanRequestParams) {
    try {
      loading.value = true;
      const res = await getListShiftPlanRequest(params);
      switch (res.status) {
        case 200:
          response.value = res.data;
          shiftPlanRequestData.value = response.value.data;
          pagination.value = response.value.meta.pagination;
          break;
        default:
          shiftPlanRequestData.value = [];
          break;
      }
    } catch (error) {
      console.error(error);
    } finally {
      loading.value = false;
    }
  }

  return {
    shiftPlanRequestData,
    loading,
    pagination,
    getListShiftPlanRequest,
    getListShiftPlanRequestData,
  };
}
