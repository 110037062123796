import { InjectionKey } from '@vue/runtime-core';
import { createStore, Store } from 'vuex';

export interface State {
  data: {
    [key: string]: string;
  };
}

export const key: InjectionKey<Store<State>> = Symbol();

export function usePersonalApplySettingStore() {
  return store;
}

export const store = createStore<State>({
  state() {
    return {
      data: {},
    };
  },
  mutations: {
    setPersonalApplySetting(state, setting) {
      state.data = setting.data;
    },
  },
  actions: {},
});
export default store;
