
import { ref, toRefs, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { defineComponent } from '@vue/runtime-core';
import { MONTH_FORMAT } from '@/constants/date';
import moment from 'moment';
import { isEmpty } from '@/util/useValidateForm';
import MonthInput from '@/components/share/Calendar/MonthInput.vue';

export default defineComponent({
  components: { MonthInput },
  props: {
    width: {
      type: String,
    },
    format: {
      type: String,
      default: MONTH_FORMAT,
    },
    isIconChevronLeft: {
      type: Boolean,
      default: true,
    },
    isIconChevronRight: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: 'month',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    validated: {
      type: Boolean,
      default: false,
    },
    errorServer: {
      type: Array,
      default: () => [],
    },
    required: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
  setup(props, ctx) {
    const i18n = useI18n();
    const t = i18n.t;
    const { format, type, modelValue } = toRefs(props);
    const date = ref(modelValue.value);

    const onNextMonth = (value) => {
      if (isEmpty(date.value)) return;

      if (type.value === 'month') {
        date.value = moment(date.value)
          .add(value, 'months')
          .format(format.value);
      }
    };

    watch(date, () => {
      ctx.emit('update:modelValue', date.value);
    });
    watch(props, () => {
      date.value = modelValue.value;
    });
    return {
      t,
      date,
      onNextMonth,
      MONTH_FORMAT,
    };
  },
});
