
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';
import systemError from '@/assets/svg/systemError.svg';

export default defineComponent({
  name: 'NotFound',
  components: {},
  setup() {
    const i18n = useI18n();
    const t = i18n.t;
    return {
      t,
      systemError,
    };
  },
});
