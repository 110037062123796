import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", {
    class: "cursor-pointer",
    onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)))
  }, [
    _renderSlot(_ctx.$slots, "default", {}, () => [
      _createVNode("div", {
        ref: "fileSelectName",
        class: "truncate"
      }, _toDisplayString(_ctx.modelValue ? _ctx.fileName : _ctx.placeholder), 513)
    ]),
    _createVNode("input", {
      class: "hidden",
      ref: "fileUpload",
      type: "file",
      name: "file-upload-input",
      id: "file-upload-input",
      onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onChangeFile($event))),
      multiple: _ctx.multiple
    }, null, 40, ["multiple"])
  ]))
}