import { ref } from 'vue';
import { getAllMemberTimeCard } from '@/api/allMemberTimeCard';
import {
  AllMemberTimeCardApiGetParams,
  AllMemberTimeCardApiGetResult,
} from '@/api/types/AllMemberTimeCardApi';

export function useGetAllMemberTimeCard() {
  const allMemberTimeCardList = ref<AllMemberTimeCardApiGetResult[]>([]);
  const loading = ref(false);
  const response = ref();

  async function getAllMemberTimeCardData(
    params: AllMemberTimeCardApiGetParams
  ) {
    try {
      loading.value = true;
      const res = await getAllMemberTimeCard(params);

      switch (res.status) {
        case 200:
          allMemberTimeCardList.value = res.data.data;
          response.value = res.data;
          break;
        default:
          allMemberTimeCardList.value = [];
          break;
      }
    } catch (error) {
      console.error(error);
    } finally {
      loading.value = false;
    }
  }

  return { allMemberTimeCardList, loading, getAllMemberTimeCardData };
}
