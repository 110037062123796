
import { computed, defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import Table from '@/components/Table.vue';
import Input from '@/components/Input.vue';
import Select from '@/components/Select.vue';
import Label from '@/components/Label.vue';
import Button from '@/components/Button.vue';
import iconSearch from '@/assets/svg/iconSearch.svg';
import { useStore } from '@/store';
import Plus from '@/assets/svg/Plus.svg';
import router from '@/router';
import backNavigation from '@/assets/svg/nav/backNavigation.svg';
import { getSearchEmploy } from '@/api/composables/useGetSearchEmployee';
import { watchEffect } from '@vue/runtime-core';
export default defineComponent({
  components: { Label, Input, Table, Select, Button },
  setup() {
    const i18n = useI18n();
    const store = useStore();
    const currentPage = computed(() => store.state.root.currentPage);
    const backRouterPath = computed(() => store.state.root.backRouterPath);
    const employeeInformationOptionHeader = [
      {
        text: i18n.t('organizationalInformation.No'),
        value: 'no',
        width: '50px',
      },
      {
        text: i18n.t('organizationalInformation.departmentName'),
        value: 'unit_name',
      },
      {
        text: i18n.t('employeeInformation.employeeCode'),
        value: 'employee_cd',
      },
      {
        text: i18n.t('shiftRegistration.name'),
        value: 'name',
      },
      {
        text: i18n.t('employeeInformation.loginID'),
        value: 'employee_cd',
      },
      {
        text: i18n.t('employeeInformation.iCRegistration'),
        value: '',
      },
      {
        text: i18n.t('employeeInformation.fingerRegistration'),
        value: '',
      },
      {
        text: i18n.t('employeeInformation.dateOfJoining'),
        value: '',
      },
      {
        text: i18n.t('employeeInformation.emailAddress'),
        value: 'working_mail',
      },
      {
        text: i18n.t('employeeInformation.passwordLastUpdate'),
        value: '',
      },
    ];
    const allAffiliationsOption = [
      {
        text: i18n.t('employeeInformation.allAffiliations'),
        value: 0,
      },
    ];
    const allAffiliationCategoriesOption = [
      {
        text: i18n.t('employeeInformation.allEmploymentCategories'),
        value: 0,
      },
    ];
    const displayedResultsOption = [
      {
        text: i18n.t('employeeInformation.100Cases'),
        value: 0,
      },
    ];
    const employeeInformationData = ref([]);
    const listApprovalEmployee = ref([]);
    getSearchEmploy({ name: '佐', enrollClass: '20,40,90' }).then((res) => {
      listApprovalEmployee.value = res.data;
    });
    watchEffect(() => {
      if (listApprovalEmployee.value) {
        employeeInformationData.value = listApprovalEmployee.value;
      }
    });
    const convertNoNumber = (index) => {
      return index + 1;
    };
    const employeeInformationSelected = ref([]);
    return {
      t: i18n.t,
      router,
      employeeInformationOptionHeader,
      employeeInformationData,
      allAffiliationsOption,
      allAffiliationCategoriesOption,
      iconSearch,
      employeeInformationSelected,
      displayedResultsOption,
      Plus,
      currentPage,
      backRouterPath,
      backNavigation,
      listApprovalEmployee,
      convertNoNumber,
    };
  },
  methods: {
    backRoute() {
      this.router.push(this.backRouterPath);
    },
  },
});
