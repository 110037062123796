import { ref } from 'vue';
import { convertErrorFromServerToJson } from '@/util/useValidateForm';
import { TransferAttendanceApplicationApiPostData } from '@/api/types/TransferAttendanceApplicationApi';
import {
  getTransferAttendanceApplicationDetail,
  postTransferAttendanceApplication,
} from '@/api/transferAttendanceApplication';
import { TransferAttendanceApplicationParams } from '@/model/TransferAttendanceApplication';

export function usePostTransferAttendanceApplication(
  data: TransferAttendanceApplicationApiPostData
) {
  const errors = ref({});
  const errorsCommon = ref<string>();
  return {
    registered: (async () => {
      const response = await postTransferAttendanceApplication(data);
      switch (response.status) {
        case 200:
          return null;
        default:
          if (response.data.errors[0].source) {
            errors.value = convertErrorFromServerToJson(response.data.errors);
          } else {
            errorsCommon.value = response.data.errors[0].title;
          }
          return {
            errors,
            errorsCommon,
          };
      }
    })(),
  };
}

export function useDetailTransferAttendanceApplication() {
  const transferAttendanceApplicationDetail = ref<TransferAttendanceApplicationParams>(
    {
      exchange_workday_details: [],
    }
  );
  const loading = ref(false);

  async function getTransferAttendanceApplicationDetailData(id) {
    try {
      loading.value = true;
      const res = await getTransferAttendanceApplicationDetail(id);
      const response = ref();
      switch (res.status) {
        case 200:
          response.value = res.data;
          transferAttendanceApplicationDetail.value = response.value.data;
          return transferAttendanceApplicationDetail.value;
        default:
          transferAttendanceApplicationDetail.value = {
            exchange_workday_details: [],
          };
          return transferAttendanceApplicationDetail.value;
      }
    } catch (error) {
      console.error(error);
      return {};
    } finally {
      loading.value = false;
    }
  }

  return {
    transferAttendanceApplicationDetail,
    loading,
    getTransferAttendanceApplicationDetailData,
  };
}
