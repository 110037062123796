import api, { ApiResponse } from '@/clients/api';
import { ErrorCode } from './types/general/ErrorCode';
import {
  JSONApiResponseBody,
  JSONApiResponseErrorBody,
} from '@/api/types/general/JSONApiResponse';
import ConstantsAPI from '@/constants/api';
import { AllMemberCheckTimeParams } from '@/model/AllMemberCheckTime';

export async function getAllMembersCheckTime(
  params: AllMemberCheckTimeParams
): Promise<
  | ApiResponse<200, JSONApiResponseBody<AllMemberCheckTimeParams>>
  | ApiResponse<400, JSONApiResponseErrorBody<ErrorCode>>
  | ApiResponse<500, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.get(ConstantsAPI.AllMemberCheckTime, { params });
}
