<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="10" cy="16" r="1.5" fill="#333333" />
    <circle cx="16" cy="16" r="1.5" fill="#333333" />
    <circle cx="22" cy="16" r="1.5" fill="#333333" />
  </svg>
</template>
