
import { defineComponent, ref } from '@vue/runtime-core';
import DatePickerIcon from '@/assets/svg/DatePickerIcon.svg';
import moment from 'moment';
import { watch } from 'vue';
export default defineComponent({
  props: {
    placeholder: {
      type: String,
    },
    width: {
      type: String,
    },
    modelValue: { type: String, default: '' },
    disabled: {
      type: Boolean,
      default: false,
    },
    format: {
      type: String,
      default: 'YYYY/MM',
    },
    icon: {},
  },
  setup(props, { emit }) {
    const isShowCalendar = ref(false);
    const currentDate = ref(new Date());
    const currentYear = ref(new Date().getFullYear());
    const currentMonth = ref(new Date().getMonth() + 1);
    const formatValue = ref(props.modelValue);
    if (new Date(props.modelValue).getTime()) {
      currentDate.value = new Date(props.modelValue);
      currentYear.value = new Date(props.modelValue).getFullYear();
      currentMonth.value = new Date(props.modelValue).getMonth() + 1;
      formatValue.value = moment(
        currentYear.value + '-' + currentMonth.value,
        'YYYY-MM'
      ).format(props.format);
    }
    const monthLabels = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

    const openCalendar = () => {
      isShowCalendar.value = true;
    };
    const closeCalendar = () => {
      isShowCalendar.value = false;
    };
    const onSelectMonth = (item) => {
      currentMonth.value = item + 1;
      formatValue.value = moment(new Date(currentYear.value, item)).format(
        props.format
      );
      isShowCalendar.value = false;
      emit('update:modelValue', formatValue.value);
    };
    const onChangeInput = (e) => {
      formatValue.value = e.target.value;
      emit('update:modelValue', e.target.value);
    };
    watch(props, () => {
      formatValue.value = props.modelValue;
    });
    return {
      isShowCalendar,
      DatePickerIcon,
      monthLabels,
      currentYear,
      currentMonth,
      formatValue,
      closeCalendar,
      openCalendar,
      onSelectMonth,
      onChangeInput,
    };
  },
});
