
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  ref,
  watchEffect,
} from '@vue/runtime-core';
import DatePickerIcon from '@/assets/svg/DatePickerIcon.svg';
import moment from 'moment';
import Input from '@/components/share/Input/index.vue';
import { watch } from 'vue';
import { useI18n } from 'vue-i18n';
import CalendarYear from '@/components/share/Calendar/CalendarYear.vue';
import '@/components/share/Calendar/calendar.scss';
import { REGEX_DATE } from '@/constants/date';
import { isValidFormatDate } from '@/util/useValidateForm';
import { isAllValidMonth, formatTimeMonth } from '@/util/time';
export default defineComponent({
  components: {
    CalendarYear,
    Input,
  },
  props: {
    placeholder: {
      type: String,
    },
    width: {
      type: String,
    },
    modelValue: {
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    format: {
      type: String,
      default: 'YYYY/MM',
    },
    isFixed: {
      type: Boolean,
      default: false,
    },
    errorServer: {
      type: Array,
      default: () => [],
    },
    required: {
      type: Boolean,
      default: false,
    },
    validated: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const i18n = useI18n();
    const t = i18n.t;
    const heightCalendar = 270;
    const heightCalendarYear = 292;
    const calendarMonth = ref<HTMLDivElement>();
    const popupCalendarMonth = ref<HTMLDivElement>();
    const popupCalendarYear = ref<HTMLDivElement>();
    const isShowCalendarMonth = ref(false);
    const currentYear = ref(new Date().getFullYear());
    const currentMonth = ref(new Date().getMonth() + 1);
    const formatValue = ref(props.modelValue);
    if (isValidFormatDate(props.modelValue.replace('/', '-'), 'YYYY-MM')) {
      currentYear.value = new Date(
        props.modelValue.replace('/', '-')
      ).getFullYear();
      currentMonth.value =
        new Date(props.modelValue.replace('/', '-')).getMonth() + 1;
      formatValue.value = moment(
        currentYear.value + '-' + currentMonth.value,
        'YYYY-MM'
      ).format(props.format);
    }
    const monthLabels = [
      t('jan'),
      t('feb'),
      t('mar'),
      t('apr'),
      t('may'),
      t('jun'),
      t('jul'),
      t('aug'),
      t('sep'),
      t('oct'),
      t('nov'),
      t('dec'),
    ];
    const regexDate = new RegExp(REGEX_DATE);
    const openCalendar = () => {
      isShowCalendarMonth.value = true;
    };
    const closeCalendar = () => {
      isShowCalendarMonth.value = false;
    };
    const isMonthToday = (index) => {
      return (
        currentYear.value === new Date().getFullYear() &&
        index === new Date().getMonth()
      );
    };
    const isMonthSelected = (month) => {
      return (
        currentYear.value === moment(formatValue.value, 'YYYY/MM').year() &&
        month === moment(formatValue.value, 'YYYY/MM').month()
      );
    };
    const isShowCalendarYear = ref(false);
    const onClickYear = () => {
      isShowCalendarYear.value = true;
      isShowCalendarMonth.value = false;
    };
    const closeCalendarYear = () => {
      isShowCalendarMonth.value = true;
      isShowCalendarYear.value = false;
    };

    const onSelectMonth = (item) => {
      currentMonth.value = item + 1;
      formatValue.value = moment(
        currentYear.value + '-' + currentMonth.value,
        'YYYY-MM'
      ).format(props.format);
      isShowCalendarMonth.value = false;
      emit('update:modelValue', formatValue.value);
    };
    const onChangeInput = (e) => {
      formatValue.value = e.target.value;
      emit('update:modelValue', e.target.value);
    };
    const valueShow = computed(() => {
      if (isAllValidMonth(formatValue.value))
        return formatTimeMonth(formatValue.value);
      return formatValue.value;
    });
    watch(props, () => {
      formatValue.value = props.modelValue;
    });

    watchEffect(() => {
      if (
        isShowCalendarMonth.value &&
        calendarMonth?.value &&
        popupCalendarMonth.value
      ) {
        // show popup calendar month
        const distanceElementToBottom =
          window.innerHeight - calendarMonth.value.getBoundingClientRect().top;
        if (!props.isFixed) {
          if (distanceElementToBottom < heightCalendar) {
            popupCalendarMonth.value.style.bottom = 58 + 'px';
          } else {
            popupCalendarMonth.value.style.bottom = 'unset';
          }
        }
      }
    });

    watchEffect(() => {
      if (popupCalendarYear?.value && calendarMonth.value) {
        // show popup calendar year
        const distanceElementToBottom =
          window.innerHeight - calendarMonth.value.getBoundingClientRect().top;
        if (!props.isFixed) {
          if (distanceElementToBottom < heightCalendar) {
            popupCalendarYear.value.style.top = 'unset';
            popupCalendarYear.value.style.bottom = heightCalendarYear + 'px';
          } else {
            popupCalendarYear.value.style.bottom = 'unset';
            popupCalendarYear.value.style.top = 54 + 'px';
          }
        }
      }
    });

    const errorMessage = ref();
    watchEffect(() => {
      emit('update:errors', errorMessage.value);
    });
    onBeforeUnmount(() => {
      emit('update:errors', '');
    });

    return {
      isShowCalendarMonth,
      DatePickerIcon,
      monthLabels,
      currentYear,
      currentMonth,
      formatValue,
      valueShow,
      isShowCalendarYear,
      errorMessage,
      closeCalendar,
      openCalendar,
      onSelectMonth,
      onChangeInput,
      isMonthToday,
      isMonthSelected,
      onClickYear,
      closeCalendarYear,
      calendarMonth,
      popupCalendarMonth,
      popupCalendarYear,
      regexDate,
    };
  },
});
