
import { defineComponent, onBeforeUnmount, onBeforeMount, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from '@/store';
import router from '@/router';
import ConstantsRouter from '@/constants/router';

export default defineComponent({
  components: {},
  setup() {
    const i18n = useI18n();
    const t = i18n.t;
    const store = useStore();

    onBeforeMount(() => {
      store.commit('root/SET_BACK_ROUTER_PATH', ConstantsRouter.Settings);
      store.commit('root/SET_CURRENT_PAGE', i18n.t('administratorSettings'));
    });
    onBeforeUnmount(() => {
      store.commit('root/SET_CURRENT_PAGE', '');
    });
    const toggleTabs = (item) => {
      router.push(item.path);
    };
    const currentPath = computed(() => router.currentRoute.value.path);
    const dataTabs = [
      {
        openTab: 1,
        text: t('permissionSetting'),
        path: ConstantsRouter.AminSettingRoleList,
      },
      {
        openTab: 2,
        text: t('applicationFlow'),
        path: ConstantsRouter.ApprovedSetting,
      },
      {
        openTab: 3,
        text: t('timeClockSettings'),
      },
      {
        openTab: 4,
        text: t('iCCardRegistration'),
        path: ConstantsRouter.ApprovedCardRegistrationSetting,
      },
      {
        openTab: 5,
        text: t('monthlySummaryOutputLayoutSettings'),
      },
    ];

    return {
      t,
      store,
      dataTabs,
      toggleTabs,
      currentPath,
    };
  },
});
