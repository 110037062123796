import { ref } from 'vue';
import { convertErrorFromServerToJson } from '@/util/useValidateForm';
import {
  deleteShiftPlan,
  deleteShiftPlanDetail,
  getShiftPlanDetail,
  updateShiftPlanDetail,
} from '@/api/shiftPlanDetail';
import { ShiftPlanSubmit } from '../types/ShiftPlanSubmit';

export default function () {
  const shiftPlanDetail = ref();

  async function getPlanDetailData(params) {
    try {
      const res = await getShiftPlanDetail(params);
      switch (res.status) {
        case 200:
          shiftPlanDetail.value = res.data.data;
          break;
        default:
          shiftPlanDetail.value = {};
          break;
          shiftPlanDetail;
      }
    } catch (error) {
      console.error(error);
    }
  }

  return {
    shiftPlanDetail,
    getPlanDetailData,
  };
}

export function useUpdatePlanDetail(id: string, data: ShiftPlanSubmit) {
  const errors = ref({});
  const errorsCommon = ref<string>();
  const loading = ref(false);
  return {
    update: (async () => {
      loading.value = true;
      const response = await updateShiftPlanDetail(id, data);
      switch (response.status) {
        case 200:
          loading.value = false;
          return null;
        default:
          if (response.data.errors[0].source) {
            errors.value = convertErrorFromServerToJson(response.data.errors);
          } else {
            errorsCommon.value = response.data.errors[0].title;
          }
          loading.value = false;
          return {
            errors,
            errorsCommon,
          };
      }
    })(),
  };
}

export function useDeleteShiftPlan(id) {
  const errors = ref({});
  const errorsCommon = ref<string>();
  return {
    deleteData: (async () => {
      const response = await deleteShiftPlan(id);
      switch (response.status) {
        case 200:
          return null;
        default:
          if (response.data.errors[0].source) {
            errors.value = convertErrorFromServerToJson(response.data.errors);
          } else {
            errorsCommon.value = response.data.errors[0].title;
          }
          return {
            errors,
            errorsCommon,
            // response.data.errors[0].title
          };
      }
    })(),
  };
}

export function useDeleteShiftPlanEmployee(params) {
  return {
    deleteData: (async () => {
      const response = await deleteShiftPlanDetail(params);
      switch (response.status) {
        case 200:
          return null;
        default:
          return response.data.errors[0].title;
      }
    })(),
  };
}
