export function scrollError() {
  setTimeout(() => {
    const elementsInErrors = document.getElementsByClassName(
      'text-error-message'
    );
    if (elementsInErrors && elementsInErrors.length > 0) {
      elementsInErrors[0].scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest',
      });
    }
  });
}

export function scrollFirstErrorMessage() {
  setTimeout(() => {
    const elementsInErrors = document.getElementsByClassName('error');
    if (elementsInErrors && elementsInErrors.length > 0) {
      elementsInErrors[0].scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest',
      });
    }
  });
}

export function scrollIntoView(
  element,
  optionSetting = {
    behavior: 'smooth',
    block: 'center',
    inline: 'nearest',
  }
) {
  setTimeout(() => {
    if (element) {
      element.scrollIntoView(optionSetting);
    }
  });
}

export const onScrollToTopMainContent = () => {
  const elements = document.getElementsByClassName('main-content');
  elements[0]?.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth',
  });
};
