import { InjectionKey } from '@vue/runtime-core';
import { createStore, Store, useStore as baseUseStore } from 'vuex';

export interface State {
  copyAction: {
    monthly_closing_day_code: string;
    monthly_closing_day_name: string;
    monthly_closing_day: string;
  };
}

export const key: InjectionKey<Store<State>> = Symbol();

export function useActionStore() {
  return baseUseStore(key);
}

export default createStore<State>({
  state() {
    return {
      copyAction: {
        monthly_closing_day_code: '',
        monthly_closing_day_name: '',
        monthly_closing_day: '',
      },
    };
  },
  mutations: {
    SET_ACTION_COPY(state, data) {
      state.copyAction = { ...state.copyAction, ...data };
    },
  },
  actions: {
    async setAction(context, data) {
      context.commit('SET_ACTION_COPY', data);
    },
  },
});
