
import { defineComponent, onBeforeMount, onBeforeUnmount, reactive } from 'vue';
import { useI18n } from 'vue-i18n';
import Plus from '@/assets/svg/Plus.svg';
import { useStore } from '@/store';
import DatePicker from '@/components/DatePicker.vue';
import Input from '@/components/Input.vue';
import Label from '@/components/Label.vue';
import Select from '@/components/Select.vue';

export default defineComponent({
  components: {
    DatePicker,
    Input,
    Label,
    Select,
  },
  setup() {
    const i18n = useI18n();
    const t = i18n.t;
    const store = useStore();

    onBeforeMount(() => {
      store.commit('root/SET_BACK_ROUTER_PATH', '/time-card');
      store.commit('root/SET_CURRENT_PAGE', i18n.t(''));
    });
    onBeforeUnmount(() => {
      store.commit('root/SET_BACK_ROUTER_PATH', '');
      store.commit('root/SET_CURRENT_PAGE', '');
    });
    const optionReasonApplication = reactive([
      { text: i18n.t('dailyAttendanceHeaders.apply'), value: '' },
      { text: i18n.t('dailyAttendanceHeaders.date'), value: '' },
      { text: i18n.t('dailyAttendanceHeaders.finalConfirmation'), value: '' },
      { text: i18n.t('dailyAttendanceHeaders.finalConfirmation'), value: '' },
      {
        text: i18n.t('dailyAttendanceHeaders.confirmationOfApplicant'),
        value: '',
      },
      {
        text: i18n.t('dailyAttendanceHeaders.confirmationOfApplicant'),
        value: '',
      },
    ]);
    return {
      optionReasonApplication,
      t,
      Plus,
    };
  },
});
