import api, { ApiResponse } from '@/clients/api';
import { ErrorCode } from './types/general/ErrorCode';
import {
  JSONApiResponseBody,
  JSONApiResponseErrorBody,
} from './types/general/JSONApiResponse';
import ConstantsAPI from '@/constants/api';
import {
  RequestMyShiftApiGetParams,
  RequestMyShiftApiGetResult,
  RequestMyShiftApiPostResult,
  RequestMyShiftApiUpdateData,
} from '@/api/types/RequestShiftApi';

export async function getRequestMyShift(
  params: RequestMyShiftApiGetParams
): Promise<
  | ApiResponse<200, JSONApiResponseBody<RequestMyShiftApiGetResult>>
  | ApiResponse<404, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.get(ConstantsAPI.RequestMyShift, { params: params });
}

export async function putRequestMyShift(
  id: string,
  updateData: RequestMyShiftApiUpdateData
): Promise<
  | ApiResponse<200, JSONApiResponseBody<RequestMyShiftApiPostResult>>
  | ApiResponse<404, JSONApiResponseErrorBody<ErrorCode>>
  | ApiResponse<400, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.put(ConstantsAPI.RequestMyShiftDetail(id), updateData);
}
