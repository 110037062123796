
import { defineComponent, PropType, toRefs, watch } from 'vue';
import { useRoute } from 'vue-router';

export default defineComponent({
  emits: ['update:mainEl'],
  props: {
    mainEl: {
      type: (null as unknown) as PropType<HTMLDivElement | null>,
      default: null,
    },
  },
  setup(props) {
    const { mainEl } = toRefs(props);
    const route = useRoute();
    const { path } = toRefs(route);

    watch(path, () => {
      if (mainEl.value) {
        mainEl.value.scrollTop = 0;
      }
    });
  },
});
