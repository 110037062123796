
import { computed, defineComponent, onBeforeMount, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import workingStyleMasterRegister, {
  useDetailWorkingStyleMaster,
  useUpdateWorkingStyleMaster,
} from '@/api/composables/useWorkingStyleMaster';
import { useStore } from 'vuex';
import {
  WorkingStyleMasterDefault,
  WorkingStyleMasterParams,
} from '@/model/WorkingStyleMaster';
import router from '@/router';
import Button from '@/components/Button.vue';
import Input from '@/components/Input.vue';
import Checkbox from '@/components/Checkbox.vue';
import Label from '@/components/Label.vue';
import Select from '@/components/Select.vue';
import RadioGroup from '@/components/RadioGroup.vue';
import InputTime from '@/components/InputTime.vue';
import WorkingSchedule from '@/views/WorkingStyleMasterSettings/WorkingSchedule.vue';
import BreakSetting from '@/views/WorkingStyleMasterSettings/BreakSetting.vue';
import GoOutSetting from '@/views/WorkingStyleMasterSettings/GoOutSetting.vue';
import BeingLateTime from '@/views/WorkingStyleMasterSettings/BeingLateTime.vue';
import AutomaticBreak from '@/views/WorkingStyleMasterSettings/AutomaticBreak.vue';
import ConfirmRegisterWorkingStyleMasterPopup from '@/views/WorkingStyleMasterSettings/ConfirmRegisterWorkingStyleMasterPopup.vue';
import ConstantsRouter from '@/constants/router';
import {
  DISTINGUISHING_HOLIDAY_TYPE,
  MIDNIGHT_DAY_TYPE,
  WAY_OF_WORKING,
  WEEK_DAY,
  WORKING_TYPE,
} from '@/constants/workingStyleMaster';
import { showToast } from '@/util/useToastMessage';
import { isEmpty, isValidateForm } from '@/util/useValidateForm';
import moment from 'moment';
import { MONTH_FORMAT } from '@/constants/date';

export default defineComponent({
  components: {
    GoOutSetting,
    BreakSetting,
    WorkingSchedule,
    InputTime,
    ConfirmRegisterWorkingStyleMasterPopup,
    Button,
    AutomaticBreak,
    BeingLateTime,
    Select,
    Label,
    Checkbox,
    Input,
    RadioGroup,
  },
  setup() {
    const i18n = useI18n();
    const store = useStore();
    onBeforeMount(() => {
      store.commit(
        'root/SET_BACK_ROUTER_PATH',
        ConstantsRouter.WorkingStyleMaster
      );
      store.commit('root/SET_CURRENT_PAGE', i18n.t('workingStyleMaster'));
    });

    const labelCheckboxFollowingDay = {
      label: i18n.t('followingDay'),
    };
    const dayOfWeekList = [
      { key: WEEK_DAY.SUNDAY, value: i18n.t('daysOfWeek.sun') },
      { key: WEEK_DAY.MONDAY, value: i18n.t('daysOfWeek.mon') },
      { key: WEEK_DAY.TUESDAY, value: i18n.t('daysOfWeek.tue') },
      { key: WEEK_DAY.WEDNESDAY, value: i18n.t('daysOfWeek.wed') },
      { key: WEEK_DAY.THURSDAY, value: i18n.t('daysOfWeek.thu') },
      { key: WEEK_DAY.FRIDAY, value: i18n.t('daysOfWeek.fri') },
      { key: WEEK_DAY.SATURDAY, value: i18n.t('daysOfWeek.sat') },
    ];
    const closingDayOptionList = ref();
    // init list closingDayOptionList is 31 days
    closingDayOptionList.value = Array.from(Array(31)).map((e, i) => {
      return { value: i + 1, text: i + 1 };
    });
    closingDayOptionList.value.push({ text: i18n.t('end'), value: 0 });
    const distinguishingOption = [
      {
        value: DISTINGUISHING_HOLIDAY_TYPE.DISTINGUISHING,
        label: i18n.t('distinguish'),
      },
      {
        value: DISTINGUISHING_HOLIDAY_TYPE.NOT_DISTINGUISHING,
        label: i18n.t('notDistinguish'),
      },
    ];
    const workingTypeList = [
      {
        value: WORKING_TYPE.STANDARD_LABOR,
        label: i18n.t('standardLabor'),
      },
      {
        value: WORKING_TYPE.TRANSFORMATION_LABOR,
        label: i18n.t('transformationLabor'),
        textTooltip: i18n.t('noteDutySystem'),
      },
      {
        value: WORKING_TYPE.DISCRETIONARY_LABOR,
        label: i18n.t('discretionaryLabor'),
      },
      { value: WORKING_TYPE.FLEXTIME_SYSTEM, label: i18n.t('flextimeSystem') },
    ];
    const wayOfWorkingList = [
      { value: WAY_OF_WORKING.FIXED, label: i18n.t('fixedDuty') },
      { value: WAY_OF_WORKING.SHIFT, label: i18n.t('shiftWork') },
    ];
    const optionsChoseTime = [
      { text: i18n.t('onTheDay'), value: MIDNIGHT_DAY_TYPE.ON_DAY },
      { text: i18n.t('beforeDay'), value: MIDNIGHT_DAY_TYPE.BEFORE_DAY },
      { text: i18n.t('nextDay'), value: MIDNIGHT_DAY_TYPE.NEXT_DAY },
    ];
    const isRequiredMidnightTime = computed(
      () =>
        !isEmpty(workingStyleMasterParams.value.midnight_working_start) ||
        !isEmpty(workingStyleMasterParams.value.midnight_start_day_type) ||
        !isEmpty(workingStyleMasterParams.value.midnight_working_end) ||
        !isEmpty(workingStyleMasterParams.value.midnight_end_day_type)
    );

    const errors = ref({});
    let workingStyleMasterParams = ref<WorkingStyleMasterParams>(
      JSON.parse(JSON.stringify(WorkingStyleMasterDefault))
    );
    const validated = ref(false);
    const errorServer = ref({});
    const isValid = () => {
      validated.value = true;
      return isValidateForm(errors);
    };

    const isShowRegisterWorkingStyleMasterPopup = ref(false);
    const openRegisterWorkingStyleMaster = () => {
      if (!isValid()) {
        return;
      }
      isShowRegisterWorkingStyleMasterPopup.value = true;
    };
    const closeRegisterWorkingStyleMaster = () => {
      isShowRegisterWorkingStyleMasterPopup.value = false;
    };

    const workingStyleMasterUpdateId = router.currentRoute.value.query
      .id as string;
    const isCopyAction =
      ConstantsRouter.WorkingStyleMasterCopy === router.currentRoute.value.path;
    const isUpdateAction = workingStyleMasterUpdateId && !isCopyAction;
    const {
      workingStyleMasterDetail,
      getDetailData,
    } = useDetailWorkingStyleMaster();
    const getWorkingStyleMasterDetail = async () => {
      await getDetailData(workingStyleMasterUpdateId);
      // set default value for workingStyleMasterParams
      if (workingStyleMasterUpdateId && workingStyleMasterDetail.value) {
        // set min a element for working_style_standard_session_breaks
        if (
          workingStyleMasterDetail.value
            .working_style_standard_session_breaks &&
          workingStyleMasterDetail.value.working_style_standard_session_breaks
            .length === 0
        ) {
          workingStyleMasterDetail.value.working_style_standard_session_breaks =
            workingStyleMasterParams.value.working_style_standard_session_breaks;
        }
        // set min a element for working_style_standard_automatic_breaks
        if (
          workingStyleMasterDetail.value
            .working_style_standard_automatic_breaks &&
          workingStyleMasterDetail.value.working_style_standard_automatic_breaks
            .length === 0
        ) {
          workingStyleMasterDetail.value.working_style_standard_automatic_breaks =
            workingStyleMasterParams.value.working_style_standard_automatic_breaks;
        }
        // set min a element for working_style_special_overtimes
        if (
          workingStyleMasterDetail.value.working_style_special_overtimes &&
          workingStyleMasterDetail.value.working_style_special_overtimes
            .length === 0
        ) {
          workingStyleMasterDetail.value.working_style_special_overtimes =
            workingStyleMasterParams.value.working_style_special_overtimes;
        }
        workingStyleMasterParams.value = workingStyleMasterDetail.value;
        workingStyleMasterParams.value.apply_start_date = moment(
          workingStyleMasterParams.value.apply_start_date
        ).format(MONTH_FORMAT);
      }
    };
    if (workingStyleMasterUpdateId) {
      getWorkingStyleMasterDetail();
    }

    const submitCreateWorkingStyleMaster = async () => {
      const { registered } = workingStyleMasterRegister(
        workingStyleMasterParams.value
      );
      await registered
        .then((error) => {
          if (error) {
            errorServer.value = error.errors.value;
            if (error.errorsCommon.value) {
              showToast('error', error.errorsCommon.value);
            }
          } else {
            showToast(
              'success',
              i18n.t('successMessage.workStyleMasterHasBeenCreated')
            );
            router.push(ConstantsRouter.WorkingStyleMaster);
          }
        })
        .catch(() => {
          showToast('error', i18n.t('errors.serverError'));
        });
    };
    const submitUpdateWorkingStyleMaster = async () => {
      const { update } = useUpdateWorkingStyleMaster(
        workingStyleMasterUpdateId,
        workingStyleMasterParams.value
      );
      await update
        .then((error) => {
          if (error) {
            errorServer.value = error.errors.value;
            if (error.errorsCommon.value) {
              showToast('error', error.errorsCommon.value);
            }
          } else {
            showToast(
              'success',
              i18n.t('successMessage.workStyleMasterHasBeenUpdated')
            );
            router.push(ConstantsRouter.WorkingStyleMaster);
          }
        })
        .catch(() => {
          showToast('error', i18n.t('errors.serverError'));
        });
    };
    const submitCreateOrUpdateWorkingStyleMaster = async () => {
      if (isUpdateAction) {
        await submitUpdateWorkingStyleMaster();
      } else {
        await submitCreateWorkingStyleMaster();
      }
      if (!errorServer.value['apply_start_date']) {
        closeRegisterWorkingStyleMaster();
      }
    };
    const backToListWorkingStyleMaster = () => {
      router.push(ConstantsRouter.WorkingStyleMaster);
    };
    return {
      t: i18n.t,
      optionsChoseTime,
      labelCheckboxFollowingDay,
      dayOfWeekList,
      workingStyleMasterParams,
      closingDayOptionList,
      store,
      errors,
      isShowRegisterWorkingStyleMasterPopup,
      distinguishingOption,
      workingTypeList,
      wayOfWorkingList,
      validated,
      errorServer,
      workingStyleMasterDetail,
      isRequiredMidnightTime,
      DISTINGUISHING_HOLIDAY_TYPE,
      WORKING_TYPE,
      isValid,
      submitCreateWorkingStyleMaster,
      openRegisterWorkingStyleMaster,
      closeRegisterWorkingStyleMaster,
      submitUpdateWorkingStyleMaster,
      submitCreateOrUpdateWorkingStyleMaster,
      backToListWorkingStyleMaster,
    };
  },
});
