import api, { ApiResponse } from '@/clients/api';
import {
  JSONApiResponseBody,
  JSONApiResponseErrorBody,
} from '@/api/types/general/JSONApiResponse';
import { TimeKeepingGetData, TimeKeepingParam } from '@/api/types/TimeKeeping';
import { ErrorCode } from '@/api/types/general/ErrorCode';
import ConstantsAPI from '@/constants/api';
export async function getDailyAttendance(
  params: TimeKeepingParam
): Promise<
  | ApiResponse<200, JSONApiResponseBody<TimeKeepingGetData[]>>
  | ApiResponse<404, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.get(ConstantsAPI.TimeKeepingList, { params: params });
}
