<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.5 3.75H4.5C4.08579 3.75 3.75 4.08579 3.75 4.5V19.5C3.75 19.9142 4.08579 20.25 4.5 20.25H19.5C19.9142 20.25 20.25 19.9142 20.25 19.5V4.5C20.25 4.08579 19.9142 3.75 19.5 3.75Z"
      stroke="#333333"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.81836 11.0684L12.0003 14.2503L15.1823 11.0684"
      stroke="#333333"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12 6.75V14.25"
      stroke="#333333"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M3.75 15H7.18934C7.28783 15 7.38536 15.0194 7.47635 15.0571C7.56735 15.0948 7.65003 15.15 7.71967 15.2197L9.53033 17.0303C9.59997 17.1 9.68265 17.1552 9.77365 17.1929C9.86464 17.2306 9.96217 17.25 10.0607 17.25H13.9393C14.0378 17.25 14.1354 17.2306 14.2264 17.1929C14.3173 17.1552 14.4 17.1 14.4697 17.0303L16.2803 15.2197C16.35 15.15 16.4327 15.0948 16.5236 15.0571C16.6146 15.0194 16.7122 15 16.8107 15H20.25"
      stroke="#333333"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
