
import moment from 'moment';
import { defineComponent, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import PopupDelete from '@/components/share/Modal/PopupDelete.vue';
import LoadingScreen from '@/components/LoadingScreen.vue';
import ToastMessage from '@/components/share/Toast/ToastMessage.vue';

export default defineComponent({
  components: { ToastMessage, LoadingScreen, PopupDelete },
  setup() {
    const i18n = useI18n();
    moment.locale(i18n.locale.value);
    watch(i18n.locale, (newLocale) => moment.locale(newLocale));
    return {};
  },
});
