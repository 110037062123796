
import { defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import Table from '@/components/share/Table/Table.vue';
import BaseIcon from '@/components/Icons/BaseIcon.vue';
import router from '@/router';
import ConstantsRouter from '@/constants/router';
import { useGetApproveFlowList } from '@/api/composables/approvalSettings';
import { useApplicationDetailFlow } from '@/store/modules/application-flows';
import { usePersonalFlowStore } from '@/store/modules/personal-flows';

export default defineComponent({
  components: { BaseIcon, Table },
  setup() {
    const i18n = useI18n();
    const t = i18n.t;
    router.push({
      path: `/new${ConstantsRouter.PersonalFlowSetting}`,
      query: {
        page_active: 1,
      },
    });
    const paramsUnit = ref({
      page: 1,
      is_all: true,
    });
    const storeFlow = usePersonalFlowStore();
    storeFlow.commit('clearPersonalFlowData');
    const personFlowHeaders = [
      {
        text: t('departmentCode'),
        value: 'unit_code',
        width: '80px',
      },
      {
        text: t('unit'),
        value: 'unit_name',
        width: '260px',
      },
      {
        text: t('workRecordApplication'),
        value: 'work_record_application',
        whitespace: 'normal',
        width: '80px',
      },
      {
        text: t('workingScheduleRegister.workScheduleChangeApplication'),
        value: 'work_schedule_change_application',
        whitespace: 'normal',
        width: '80px',
      },
      {
        text: t('approvedFlow.vacationRequest'),
        value: 'vacation_request',
        whitespace: 'normal',
        width: '80px',
      },
      {
        text: t('personalSettingApply.transferAttendance'),
        value: 'transfer_attendance',
        whitespace: 'normal',
        width: '80px',
      },
      {
        text: t('holidayCommutingApplication'),
        value: 'holiday_commuting_application',
        whitespace: 'normal',
        width: '80px',
      },
      {
        text: t('personalSettingApply.substituteLeaveApplication'),
        value: 'substitute_leave_application',
        whitespace: 'normal',
        width: '80px',
      },
      {
        text: t('workingTimeRegister.leaveApplication'),
        value: 'leave_application',
        whitespace: 'normal',
        width: '80px',
      },
      {
        text: t('personalSettingApply.absenceApplication'),
        value: 'absence_application',
        whitespace: 'normal',
        width: '80px',
      },
      {
        text: t('waitingAllowanceApplication'),
        value: 'waiting_allowance_application',
        whitespace: 'normal',
        width: '80px',
      },
      {
        text: t('edit'),
        value: 'edit',
        width: '48px',
      },
    ];
    const detailStoreFlowApplication = useApplicationDetailFlow();

    const isCheckUnit = ref();
    const arrPersonalItem = ref([]);
    const {
      handleGetApproveFlowList,
      approveFlowListData,
      loading,
      pagination,
    } = useGetApproveFlowList(paramsUnit.value);

    const editPersonalFlowSettings = (item) => {
      const newList = item.application_flows.sort(function (a, b) {
        return a.application_define_type - b.application_define_type;
      });
      const applicationFlowIds = newList.map(
        (item) => item.application_flow_id
      );
      const applicationDefineIds = newList.map(
        (item) => item.application_define_id
      );
      const applicationDefineNames = newList.map(
        (item) => item.application_define_name
      );
      detailStoreFlowApplication.state.applicationDetailFlow = item;
      router.push({
        path: `/new${ConstantsRouter.PersonalFlowSettingCreate}`,
        query: {
          applicationFlowIds,
          applicationDefineIds,
          applicationDefineNames,
          unit_id: item.unit_id,
          unit_name: item.unit_name,
          page_active: paramsUnit.value.page,
        },
      });
    };
    const onChangePage = () => {
      handleGetApproveFlowList(paramsUnit.value);
      router.replace({
        path: `/new${ConstantsRouter.PersonalFlowSetting}`,
        query: {
          page_active: paramsUnit.value.page,
        },
      });
    };

    const handleItemIsBasic = (item) => {
      if (item === null) {
        return '';
      } else if (item === false) {
        return t('isPersonalFlow');
      }
      return t('isBasicFlow');
    };

    return {
      t,
      personFlowHeaders,
      isCheckUnit,
      pagination,
      paramsUnit,
      loading,
      arrPersonalItem,
      approveFlowListData,
      detailStoreFlowApplication,
      handleItemIsBasic,
      handleGetApproveFlowList,
      editPersonalFlowSettings,
      onChangePage,
    };
  },
});
