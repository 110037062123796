
import { defineComponent, toRefs, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import Label from '@/components/Label.vue';
import Select from '@/components/Select.vue';
import Input from '@/components/Input.vue';
import RadioGroup from '@/components/RadioGroup.vue';
import { ref } from '@vue/runtime-core';
import { WorkingStyleMasterParams } from '@/model/WorkingStyleMaster';
import {
  CALC_NON_STATUTORY_OVERTIME_BY_WEEK_TYPE,
  DISTINGUISHING_BETWEEN_OVERTIME_NON_STATUTORY_TYPE,
  SETTING_REGULAR_WORKING_PER_DAY_TYPE,
  SPECIAL_OVERTIME_MAX,
  USING_SPECIAL_OVERTIME_TYPE,
  WAY_CALC_OVERTIME_TYPE,
  WAY_CALC_WEEKLY_OVERTIME_MONTHLY_CLOSING_TYPE,
  WAY_ROUNDING_OVERTIME_BY_WEEK_TYPE,
} from '@/constants/workingStyleMaster';

export default defineComponent({
  props: {
    modelValue: { default: {} },
    validated: {
      default: false,
    },
    errorServer: {
      default: '',
    },
  },
  components: {
    Label,
    Select,
    RadioGroup,
    Input,
  },
  setup(props) {
    const i18n = useI18n();
    const t = i18n.t;
    const { modelValue } = toRefs(props);
    const roundingWeeklyOvertimeList = [
      {
        value: WAY_ROUNDING_OVERTIME_BY_WEEK_TYPE.ROUND_DOWN,
        label: t('automatictBreak.truncate'),
      },
      {
        value: WAY_ROUNDING_OVERTIME_BY_WEEK_TYPE.ROUND_UP,
        label: t('automatictBreak.roundUp'),
      },
      {
        value: WAY_ROUNDING_OVERTIME_BY_WEEK_TYPE.ROUND_OFF,
        label: t('automatictBreak.rounding'),
      },
    ];
    const distinguishingBetweenOvertimeNonStatutoryList = [
      {
        value: DISTINGUISHING_BETWEEN_OVERTIME_NON_STATUTORY_TYPE.NOT_USE,
        label: t('automatictBreak.doNot'),
      },
      {
        value: DISTINGUISHING_BETWEEN_OVERTIME_NON_STATUTORY_TYPE.USE,
        label: t('automatictBreak.do'),
      },
    ];
    const settingRegularWorkingHourPerDayList = [
      {
        value: SETTING_REGULAR_WORKING_PER_DAY_TYPE.PRESCRIBED_WORKING,
        text: t('scheduledWorkingHoursAsPrescribedWorking'),
      },
      {
        value: SETTING_REGULAR_WORKING_PER_DAY_TYPE.WORKING_PATTERN,
        text: t('prescribedWorkingInPrescribedWorkingPattern'),
      },
      {
        value:
          SETTING_REGULAR_WORKING_PER_DAY_TYPE.PRESCRIBED_AND_WORKING_PATTERN,
        text: t('scheduledWorkingIsPrescribedAnPrescribedWorkingPattern'),
      },
    ];
    const optionsTreatmentOfOvertime = [
      {
        value: WAY_CALC_OVERTIME_TYPE.ALL_AS_OVERTIME,
        text: t('automatictBreak.treatOptionLegalHours'),
      },
      {
        value: WAY_CALC_OVERTIME_TYPE.OVERTIME_AND_NON_STATUTORY_ARE_SEPARATELY,
        text: t('automatictBreak.treatOptionNoRules'),
      },
    ];
    const optionsWeeklyOutside = [
      {
        value: CALC_NON_STATUTORY_OVERTIME_BY_WEEK_TYPE.NOT_DO,
        label: t('automatictBreak.doNot'),
      },
      {
        value: CALC_NON_STATUTORY_OVERTIME_BY_WEEK_TYPE.DO,
        label: t('automatictBreak.do'),
      },
    ];
    const optionsWeekCrossedDeadline = [
      {
        value: WAY_CALC_WEEKLY_OVERTIME_MONTHLY_CLOSING_TYPE.PREVIOUS_MONTH,
        text: t('automatictBreak.calculateIncludingPreviousMonth'),
      },
      {
        value: WAY_CALC_WEEKLY_OVERTIME_MONTHLY_CLOSING_TYPE.DAILY_BASIS,
        text: t('automatictBreak.calculateOnDailyBasic'),
      },
    ];
    const extraOvertimeFunctionList = [
      {
        value: USING_SPECIAL_OVERTIME_TYPE.USE,
        text: t('automatictBreak.use'),
      },
      {
        value: USING_SPECIAL_OVERTIME_TYPE.NOT_USE,
        text: t('automatictBreak.notUse'),
      },
    ];

    const removeSpecialOverTime = (item, index) => {
      const length =
        workingStyleMasterParams.value.working_style_special_overtimes
          ?.length || 0;
      if (length > 1) {
        workingStyleMasterParams.value.working_style_special_overtimes?.splice(
          index,
          1
        );
      }
      for (let i = index; i < length; i++) {
        errorServerData.value[
          `working_style_special_overtimes.${i}.special_overtime`
        ] =
          errorServerData.value[
            `working_style_special_overtimes.${i + 1}.special_overtime`
          ];
      }
    };
    const addSpecialOverTime = (index) => {
      if (index + 1 >= SPECIAL_OVERTIME_MAX) return;
      workingStyleMasterParams.value.working_style_special_overtimes?.push({
        special_overtime_type: index + 1,
      });
    };
    const isAddSpecialOverTime = (index) => {
      return (
        index + 1 < SPECIAL_OVERTIME_MAX &&
        index + 1 ===
          workingStyleMasterParams.value.working_style_special_overtimes?.length
      );
    };
    const workingStyleMasterParams = ref<WorkingStyleMasterParams>({});
    workingStyleMasterParams.value = modelValue.value;
    const errorServerData = ref({});
    watch(props, () => {
      workingStyleMasterParams.value = modelValue.value;
      errorServerData.value = props.errorServer;
    });
    return {
      t,
      distinguishingBetweenOvertimeNonStatutoryList,
      settingRegularWorkingHourPerDayList,
      roundingWeeklyOvertimeList,
      workingStyleMasterParams,
      optionsTreatmentOfOvertime,
      optionsWeeklyOutside,
      optionsWeekCrossedDeadline,
      extraOvertimeFunctionList,
      errorServerData,
      USING_SPECIAL_OVERTIME_TYPE,
      CALC_NON_STATUTORY_OVERTIME_BY_WEEK_TYPE,
      addSpecialOverTime,
      removeSpecialOverTime,
      isAddSpecialOverTime,
    };
  },
});
