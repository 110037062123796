import api, { ApiResponse } from '@/clients/api';
import { ErrorCode } from './types/general/ErrorCode';
import {
  JSONApiResponseBody,
  JSONApiResponseErrorBody,
} from './types/general/JSONApiResponse';
import ConstantsAPI from '@/constants/api';
import { UnitApiGetResult, UnitApiIsAll } from '@/api/types/UnitApi';

export async function getUnit(
  params: UnitApiIsAll
): Promise<
  | ApiResponse<200, JSONApiResponseBody<UnitApiGetResult[]>>
  | ApiResponse<404, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.get(ConstantsAPI.Unit, {
    params,
  });
}
