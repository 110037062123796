import { toDisplayString as _toDisplayString, createVNode as _createVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-grey-3 px-10 mt-12" }
const _hoisted_2 = { class: "title font-size-32 font-bold text-center" }
const _hoisted_3 = { class: "flex justify-center my-5 mt-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")
  const _component_Popup = _resolveComponent("Popup")

  return (_openBlock(), _createBlock(_component_Popup, {
    modelValue: _ctx.isShow,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.isShow = $event)),
    width: "630px",
    noScrollBody: ""
  }, {
    default: _withCtx(() => [
      _createVNode("div", _hoisted_1, [
        _createVNode("div", _hoisted_2, _toDisplayString(_ctx.t('doyouwantSendAShiftSubRequest')), 1),
        _createVNode("div", _hoisted_3, [
          _createVNode(_component_Button, { class: "btn-gradation-light-blue w-50 h-12 mr-10" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('send')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_Button, {
            onClick: _ctx.handleCancel,
            class: "btn-gradation-secondary w-50 h-12"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('cancel')), 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ])
      ])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}