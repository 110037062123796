
import { computed, defineComponent, ref, watch } from 'vue';
import ConstantsRouter from '@/constants/router';
import MultiSelect from '@/components/share/Dropdown/MultiSelect.vue';
import Button from '@/components/share/Button/index.vue';
import Checkbox from '@/components/share/Checkbox/CheckboxInput.vue';
import DatePicker from '@/components/share/Calendar/DatePickerInput.vue';
import { useI18n } from 'vue-i18n';
import Input from '@/components/share/Input/index.vue';
import Table from '@/components/share/Table/ScrollTable.vue';
import { handleMonth, handleYear } from '@/util/composables';
import useUnitApi from '@/api/composables/useUnit';
import router from '@/router';
import {
  APPLICATION_MY_REQUEST,
  APPLICATION_STATUS_TYPE,
  APPLICATION_STATUS_TEXT_DELETE,
  APPLICATION_TYPE,
} from '@/constants/typeApplication';
import {
  ApplicationApprovalData,
  ApplicationApprovalParams,
  ApplicationApprovalSearchParams,
} from '@/model/ApplicationApprovalRequest';
import useApplicationApproval, {
  usePutApprovalApplicationReason,
  usePutRejectApplicationReason,
} from '@/api/composables/useApplicationApproval';
import { useLoginAccountStore } from '@/store/modules/login-account';
import { useGetListAppFormDefines } from '@/api/composables/approvalSettings';
import { showToast } from '@/util/useToastMessage';
import PopupApproveAllForm from '@/views/New/Application/PopupApproveAllForm.vue';
import PopupRejectAllForm from '@/views/New/Application/PopupRejectAllForm.vue';
import useGetWaitingData from '@/api/composables/waitingAllowance';
import moment from 'moment';
import { useDetailCheckTimeApplication } from '@/api/composables/useCheckTimeApplication';
import { CheckTimeApplicationApiGetResult } from '@/api/types/CheckTimeApplicationApi';
import { CHECK_TIME_TYPE } from '@/constants/checkTime';
import { useLoadingStore } from '@/store/modules/use-loading';
import ApplicationSearchArea from '@/components/Application/ApplicationSearchArea.vue';

export default defineComponent({
  components: {
    MultiSelect,
    Button,
    Checkbox,
    DatePicker,
    Input,
    Table,
    PopupApproveAllForm,
    PopupRejectAllForm,
    ApplicationSearchArea,
  },
  setup: function () {
    const i18n = useI18n();
    const t = i18n.t;
    const showSearchDetail = ref(false);
    const { listAppFormDefines } = useGetListAppFormDefines();
    const selectedData = ref<number[]>([]);
    const isAllParams = ref(true);
    const { unitList } = useUnitApi({
      is_all: isAllParams.value,
    });
    const accountStore = useLoginAccountStore();
    const userInfo = computed(() => accountStore.state.myAccount);
    const ppEmployeeId = userInfo.value.pp_employee_id;
    const listDefine = ref([]);
    const isShowButton = computed(() => {
      return selectedData.value.length;
    });
    const isShowPopupApproveAllForm = ref(false);
    const isShowPopupRejectAllForm = ref(false);
    const paramsSearchApplication = ref<ApplicationApprovalSearchParams>({
      pp_employee_id: userInfo.value.pp_employee_id,
      submission_date: '',
      target_date: '',
      working_style_id: [],
      unit_ids: [],
      checkin_unit_id: [],
      employee_name: '',
      application_form_status: [],
      application_define_id: [],
    });
    const ENABLE_APPROVAL = {
      DISABLE: false,
      ENABLE: true,
    };
    const applicationHeaders = [
      {
        text: '',
        value: 'checkbox',
        width: 40,
      },
      {
        text: i18n.t('applicationApprovalContent.approveAndReject'),
        value: 'approve',
        width: 192,
      },
      {
        text: i18n.t('targetDate'),
        value: 'target_date',
        width: 140,
      },
      {
        text: i18n.t('applicationApprovalContent.applicant'),
        value: 'employee_name',
        width: 200,
      },
      {
        text: i18n.t('applicationDate'),
        value: 'submission_date',
        width: 100,
      },
      {
        text: i18n.t('applicationType2'),
        value: 'application_type',
        width: 150,
      },
      {
        text: i18n.t('applicationApprovalContent.title'),
        value: 'application_info',
        width: 260,
      },
      {
        text: i18n.t('applicationMessage'),
        value: 'application_reason',
        width: 120,
      },
      {
        text: i18n.t('applicationApprovalContent.nextApprover'),
        value: 'next_approval_name',
        width: 120,
      },
      {
        text: i18n.t('status'),
        value: 'application_status',
        width: 120,
      },
    ];
    const listStatus = ref([
      {
        value: 0,
        label: t('draft'),
      },
      {
        value: 1,
        label: t('inProgress'),
      },
      {
        value: 2,
        label: t('completion'),
      },
      {
        value: 3,
        label: t('remandFind'),
      },
      {
        value: 4,
        label: t('cancelShiftCreation'),
      },
    ]);
    const listIdHistory = computed(() => {
      const listChecked: ApplicationApprovalData[] = applicationData.value.filter(
        (item: ApplicationApprovalData) => {
          if (item.id) {
            return (
              selectedData.value.includes(item.id) &&
              !(
                item.application_status === APPLICATION_STATUS_TYPE.FINISHED ||
                item.application_status === APPLICATION_STATUS_TYPE.REJECTED
              )
            );
          }
          return false;
        }
      );
      return listChecked.map((item) => item.application_approval_history_id);
    });
    const applicationData = ref<ApplicationApprovalParams[]>([]);
    const dataWaiting = ref();
    const { waitingDetail, getWaitingData } = useGetWaitingData();

    const checkAllSelectedStatus = computed(
      () =>
        paramsSearchApplication.value?.application_form_status.length ===
        listStatus.value.length
    );
    const formatDate = (value, format) => {
      if (!value) return '';
      return moment(value).isValid() ? moment(value).format(format) : '';
    };
    const checkAllSelected = computed(
      () =>
        paramsSearchApplication.value?.application_define_id.length ===
        listAppFormDefines.value?.length
    );
    const nextApproval = (item) => {
      return item.join(`<br />`);
    };
    const handleTypeApplication = (dataRow) => {
      const query = {
        application_form_id: dataRow.application_form_id,
        enable_approval: dataRow.enable_approval,
        is_my_application: APPLICATION_MY_REQUEST,
        application_approval_history_id:
          dataRow.application_approval_history_id,
        target_date: dataRow.target_date,
        application_define_id: dataRow.application_define_id,
      };
      switch (dataRow.application_type) {
        case APPLICATION_TYPE.WAITING_ALLOWANCE:
          if (
            dataRow.application_type_name.includes(
              APPLICATION_STATUS_TEXT_DELETE
            )
          ) {
            router.push({
              path: `/new${ConstantsRouter.WaitingAllowanceApplicationDelete}`,
              query: {
                ...query,
              },
            });
          } else {
            router.push({
              path: `/new${ConstantsRouter.WorkRecordApplication}`,
              query: {
                ...query,
              },
            });
          }

          break;
        case APPLICATION_TYPE.CHECK_TIME_APPLICATION:
          router.push({
            path: `/new${ConstantsRouter.CheckTimeApplication}`,
            query: {
              ...query,
            },
          });
          break;
        case APPLICATION_TYPE.ABSENCE_APPLICATION:
          router.push({
            path: `/new${ConstantsRouter.AbsenceApplicationDetail}`,
            query: {
              ...query,
            },
          });
          break;
        case APPLICATION_TYPE.LATE_EARLY_APPLICATION:
          router.push({
            path: `/new${ConstantsRouter.EarlyApplication}`,
            query: {
              ...query,
            },
          });
          break;
        case APPLICATION_TYPE.TRANSFER_APPLICATION:
          router.push({
            path: `/new${ConstantsRouter.TransferAttendanceApplicationDetail}`,
            query: {
              ...query,
            },
          });
          break;
        case APPLICATION_TYPE.HOLIDAY_APPLICATION:
          router.push({
            path: `/new${ConstantsRouter.WorkInHolidayApplicationDetail}`,
            query: {
              ...query,
            },
          });
          break;
      }
    };
    watch(listAppFormDefines, () => {
      if (
        listAppFormDefines &&
        listAppFormDefines.value &&
        listAppFormDefines.value[0]
      ) {
        listDefine.value = listAppFormDefines.value.map((item) => {
          return {
            value: item.value,
            label: item.text,
          };
        });
      }
    });

    //selected all status checkbox
    const checkAllStatus = () => {
      if (checkAllSelectedStatus.value) {
        paramsSearchApplication.value.application_form_status = [];
      } else {
        paramsSearchApplication.value.application_form_status = listStatus.value.map(
          (item) => item.value
        );
      }
    };
    //selected all define checkbox
    const checkAll = () => {
      if (checkAllSelected.value) {
        paramsSearchApplication.value.application_define_id = [];
      } else {
        paramsSearchApplication.value.application_define_id = listAppFormDefines.value.map(
          (item) => item.value
        );
      }
    };
    const showSearchRequired = () => {
      showSearchDetail.value = !showSearchDetail.value;
    };

    const closeSearchDetail = () => {
      showSearchDetail.value = false;
      paramsSearchApplication.value = {
        ...paramsSearchApplication.value,
        submission_date: router.currentRoute.value.query
          .submission_date as string,
        target_date: router.currentRoute.value.query.target_date as string,
        working_style_id: [],
        unit_ids: router.currentRoute.value.query.unit_ids
          ? ([...router.currentRoute.value.query.unit_ids] as string[])
          : [],
        checkin_unit_id: router.currentRoute.value.query.checkin_unit_id
          ? ([...router.currentRoute.value.query.checkin_unit_id] as string[])
          : [],
        application_form_status: router.currentRoute.value.query
          .application_form_status
          ? [
              ...router.currentRoute.value.query.application_form_status,
            ].map((e) => Number(e))
          : [],
        application_define_id: router.currentRoute.value.query
          .application_define_id
          ? ([
              ...router.currentRoute.value.query.application_define_id,
            ] as string[])
          : [],
      };
    };
    const resetSearchInput = () => {
      paramsSearchApplication.value = {
        page: 1,
        pp_employee_id: userInfo.value.pp_employee_id,
        submission_date: '',
        target_date: '',
        working_style_id: [],
        unit_ids: [],
        checkin_unit_id: [],
        employee_name: '',
        application_form_status: [],
        application_define_id: [],
      };
      router.push({
        path: `/new${ConstantsRouter.ApplicationApprovalRequest}`,
        query: {
          ...paramsSearchApplication.value,
        },
      });
    };
    const searchMembers = () => {
      paramsSearchApplication.value.page = 1;
      router.push({
        path: `/new${ConstantsRouter.ApplicationApprovalRequest}`,
        query: {
          ...paramsSearchApplication.value,
        },
      });
    };

    const unitNames = computed(() =>
      unitList.value
        .filter((item) =>
          paramsSearchApplication.value.unit_ids.includes(item.unit_id)
        )
        .map((item) => item.unit_name)
    );
    const unitNamesCheckin = computed(() =>
      unitList.value
        .filter((item) =>
          paramsSearchApplication.value.checkin_unit_id.includes(item.unit_id)
        )
        .map((item) => item.unit_name)
    );
    const {
      membersApplication,
      getAllApplication,
      pagination,
    } = useApplicationApproval();
    const getWaitingAllowanceApplicationInfo = async () => {
      for (const item of membersApplication.value) {
        if (
          item.application_form_id &&
          item.application_type === APPLICATION_TYPE.WAITING_ALLOWANCE
        ) {
          await getWaitingData(item.application_form_id);
          dataWaiting.value = waitingDetail.value;
          item.application_info = {
            checkin_unit_name: waitingDetail.value?.checkin_unit_name,
            wait_numbers: waitingDetail.value?.wait_numbers,
          };
        }
      }
    };
    const loadingStore = useLoadingStore();
    const getDataApplication = async () => {
      loadingStore.commit('increaseCountApiWaiting');
      await getAllApplication(paramsSearchApplication.value);
      if (membersApplication.value) {
        await getCheckTimeApplicationInfo();
        await getWaitingAllowanceApplicationInfo();

        applicationData.value = membersApplication.value.map((item, index) => {
          return { ...item, ...{ id: index + 1, reason: '' } };
        });
      }
      loadingStore.commit('decreaseCountApiWaiting');
    };
    const updateDataApplication = async () => {
      await getAllApplication(paramsSearchApplication.value);
      selectedData.value = [];
      if (membersApplication.value) {
        applicationData.value = membersApplication.value.map((item, index) => {
          return { ...item, ...{ id: index + 1, reason: '' } };
        });
      }
    };
    getDataApplication();

    const applicationStatusTypeList = [
      {
        value: APPLICATION_STATUS_TYPE.DRAFT,
        color: 'draft',
        text: t('draft'),
      },
      {
        value: APPLICATION_STATUS_TYPE.ON_GOING,
        color: 'on-going',
        text: t('inProgress'),
      },
      {
        value: APPLICATION_STATUS_TYPE.FINISHED,
        color: 'finished',
        text: t('completion'),
      },
      {
        value: APPLICATION_STATUS_TYPE.REJECTED,
        color: 'rejected',
        text: t('remandFind'),
      },
      {
        value: APPLICATION_STATUS_TYPE.DELETED,
        color: 'deleted',
        text: t('deleted'),
      },
    ];

    const approveForm = (index) => {
      const { updated } = usePutApprovalApplicationReason({
        pp_employee_id: ppEmployeeId,
        application_approval_histories: [
          {
            application_approval_history_id:
              applicationData.value[index]['application_approval_history_id'],
            reason: applicationData.value[index]['reason'],
          },
        ],
      });
      updated
        .then((error) => {
          if (error) {
            if (error.errorsCommon.value) {
              showToast('error', error.errorsCommon.value);
            }
          } else {
            showToast('success', t('approveSuccess'));
            getDataApplication();
          }
        })
        .catch(() => {
          showToast('error', i18n.t('errors.serverError'));
        });
    };
    const closeApproveAllForm = () => {
      isShowPopupApproveAllForm.value = false;
    };
    const closeRejectAllForm = () => {
      isShowPopupRejectAllForm.value = false;
    };
    const approveAllApplication = () => {
      isShowPopupApproveAllForm.value = true;
    };
    const rejectAllApplication = () => {
      isShowPopupRejectAllForm.value = true;
    };
    const rejectForm = (index) => {
      const { updated } = usePutRejectApplicationReason({
        pp_employee_id: ppEmployeeId,
        application_approval_histories: [
          {
            application_approval_history_id:
              applicationData.value[index]['application_approval_history_id'],
            reason: applicationData.value[index]['reason'],
          },
        ],
      });
      updated
        .then((error) => {
          if (error) {
            if (error.errorsCommon.value) {
              showToast('error', error.errorsCommon.value);
            }
          } else {
            showToast('success', t('rejectSuccess'));
            getDataApplication();
          }
        })
        .catch(() => {
          showToast('error', i18n.t('errors.serverError'));
        });
    };

    watch(
      () => router.currentRoute.value.query,
      async () => {
        await getAllApplication(paramsSearchApplication.value);
        applicationData.value = membersApplication.value.map((item, index) => {
          return { ...item, ...{ id: index + 1, reason: '' } };
        });
      }
    );

    // for detail check-time application
    const {
      checkTimeApplicationDetail,
      getCheckTimeApplicationDetailData,
    } = useDetailCheckTimeApplication();
    const checkTimeApplicationInfos = ref<CheckTimeApplicationApiGetResult[]>(
      []
    );
    const getCheckTimeApplicationInfo = async () => {
      for (const item of membersApplication.value) {
        if (
          item.application_form_id &&
          item.application_type === APPLICATION_TYPE.CHECK_TIME_APPLICATION
        ) {
          await getCheckTimeApplicationDetailData(item.application_form_id);
          checkTimeApplicationInfos.value.push(
            checkTimeApplicationDetail.value
          );
        }
      }
    };
    const applicationCheckTimeInfo = (applicationFormId) => {
      const appInfo = checkTimeApplicationInfos.value.find(
        (item) => item.application_form_id === applicationFormId
      );
      if (appInfo) {
        return appInfo.check_time_detail.filter(
          (item) => item.is_delete || !item.time_keeping_id
        );
      }
      return [];
    };
    const checkTimeTypeList = [
      { value: CHECK_TIME_TYPE.CHECK_IN, label: t('checkIn') },
      { value: CHECK_TIME_TYPE.CHECK_OUT, label: t('checkOut') },
      {
        value: CHECK_TIME_TYPE.BREAK_START,
        label: t('breakStartTimeRounding'),
      },
      { value: CHECK_TIME_TYPE.BREAK_END, label: t('breakEndTimeRounding') },
      { value: CHECK_TIME_TYPE.GO_OUT, label: t('goOut') },
      { value: CHECK_TIME_TYPE.GO_BACK, label: t('goBack') },
      { value: CHECK_TIME_TYPE.TELE_WORK, label: t('teleworkCommute') },
      { value: CHECK_TIME_TYPE.CHECK_IN_OFFICE, label: t('checkInOffice') },
    ];
    const displayCheckTimeType = (type) => {
      return checkTimeTypeList.find((item) => item.value === type)?.label;
    };
    const isSeeMore = ref({});
    const onClickSeeMore = (checkTimeApplication, indexRow) => {
      isSeeMore.value[indexRow] = !isSeeMore.value[indexRow];
    };
    const isShowSeeMore = (checkTimeApplication) => {
      return checkTimeApplication.length > 3;
    };
    // end get detail check-time application

    const listDisabled = computed(() => {
      if (applicationData.value) {
        return applicationData.value.map((item: ApplicationApprovalParams) => {
          if (
            item.application_status === APPLICATION_STATUS_TYPE.FINISHED ||
            item.application_status === APPLICATION_STATUS_TYPE.REJECTED ||
            item.enable_approval === ENABLE_APPROVAL.DISABLE
          )
            return item.id;
          return;
        });
      }
      return [];
    });

    const displayTimeFormat = (time, format) => {
      if (moment(time).isValid()) {
        return moment(time).format(format);
      }
      return '';
    };
    const heightRequestSearch = computed(() => {
      if (searchRequest.value) {
        return searchRequest.value.getBoundingClientRect().height;
      }
      return 0;
    });
    const searchRequest = ref<HTMLDivElement>();
    const onChangePage = () => {
      router.push({
        path: router.currentRoute.value.fullPath,
        query: {
          ...paramsSearchApplication.value,
        },
      });
    };
    const onChangeItemPerPage = () => {
      paramsSearchApplication.value.page = 1;
      router.push({
        path: router.currentRoute.value.fullPath,
        query: {
          ...paramsSearchApplication.value,
        },
      });
    };

    return {
      t,
      i18n,
      showSearchRequired,
      membersApplication,
      closeSearchDetail,
      showSearchDetail,
      resetSearchInput,
      searchMembers,
      applicationHeaders,
      applicationData,
      handleYear,
      unitNamesCheckin,
      selectedData,
      handleMonth,
      unitList,
      paramsSearchApplication,
      unitNames,
      listDefine,
      listStatus,
      userInfo,
      checkAllStatus,
      checkAllSelectedStatus,
      checkAll,
      checkAllSelected,
      isShowButton,
      getDataApplication,
      approveForm,
      rejectForm,
      applicationStatusTypeList,
      handleTypeApplication,
      APPLICATION_TYPE,
      APPLICATION_STATUS_TEXT_DELETE,
      approveAllApplication,
      isShowPopupApproveAllForm,
      closeApproveAllForm,
      closeRejectAllForm,
      rejectAllApplication,
      isShowPopupRejectAllForm,
      listIdHistory,
      updateDataApplication,
      nextApproval,
      waitingDetail,
      formatDate,
      ENABLE_APPROVAL,
      checkTimeApplicationDetail,
      getCheckTimeApplicationDetailData,
      checkTimeApplicationInfos,
      checkTimeTypeList,
      isSeeMore,
      isShowSeeMore,
      displayCheckTimeType,
      applicationCheckTimeInfo,
      onClickSeeMore,
      listDisabled,
      moment,
      displayTimeFormat,
      heightRequestSearch,
      searchRequest,
      APPLICATION_STATUS_TYPE,
      pagination,
      onChangePage,
      onChangeItemPerPage,
    };
  },
});
