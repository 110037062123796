import {
  ShiftPatternsApiParamSearch,
  ShiftPatternsApiPostData,
  ShiftPatternsApiUpdateData,
  ShiftPatternsGetData,
} from '@/api/types/ShiftPatern';
import {
  deleteShiftPattern,
  getShiftPattern,
  getShiftPatternDetail,
  postShiftPattern,
  updateShiftPattern,
} from '@/api/shiftPattern';
import { ref } from 'vue';
import { convertErrorFromServerToJson } from '@/util/useValidateForm';

export default function (data: ShiftPatternsApiPostData) {
  const errors = ref({});
  const errorsCommon = ref<string>();
  const loading = ref(false);
  return {
    registered: (async () => {
      loading.value = true;
      const response = await postShiftPattern(data);
      switch (response.status) {
        case 200:
          loading.value = false;
          return null;
        case 201:
          loading.value = false;
          return null;
        default:
          if (response.data.errors[0].source) {
            errors.value = convertErrorFromServerToJson(response.data.errors);
          } else {
            errorsCommon.value = response.data.errors[0].title;
          }
          loading.value = false;
          return {
            errors,
            errorsCommon,
          };
      }
    })(),
  };
}

export function useUpdateShiftPattern(
  id: string,
  data: ShiftPatternsApiUpdateData
) {
  const errors = ref({});
  const errorsCommon = ref<string>();
  const loading = ref(false);
  return {
    update: (async () => {
      loading.value = true;
      const response = await updateShiftPattern(id, data);
      switch (response.status) {
        case 200:
          loading.value = false;
          return null;
        default:
          if (response.data.errors[0].source) {
            errors.value = convertErrorFromServerToJson(response.data.errors);
          } else {
            errorsCommon.value = response.data.errors[0].title;
          }
          loading.value = false;
          return {
            errors,
            errorsCommon,
          };
      }
    })(),
  };
}

export function useDetailShiftPattern() {
  const shiftPatternDetail = ref<ShiftPatternsApiUpdateData>({});
  const loading = ref(false);

  async function getDetail(id) {
    try {
      loading.value = true;
      const res = await getShiftPatternDetail(id);
      const response = ref();
      switch (res.status) {
        case 200:
          response.value = res.data.data;
          shiftPatternDetail.value = response.value;
          return shiftPatternDetail.value;
        default:
          shiftPatternDetail.value = {};
          return shiftPatternDetail.value;
      }
    } catch (error) {
      console.error(error);
      return {};
    } finally {
      loading.value = false;
    }
  }

  return { shiftPatternDetail, loading, getDetail };
}

export function useGetShiftPatternList() {
  const shiftPatternData = ref<ShiftPatternsGetData[]>([]);
  const loading = ref(false);
  const response = ref();
  const pagination = ref({
    current_page: 1,
    per_page: 20,
    total: 0,
  });

  async function getShiftPatternList(params: ShiftPatternsApiParamSearch) {
    try {
      loading.value = true;
      const res = await getShiftPattern(params);
      switch (res.status) {
        case 200:
          response.value = res.data;
          shiftPatternData.value = response.value.data;
          pagination.value = response.value.meta.pagination;
          break;
        default:
          shiftPatternData.value = [];
          break;
      }
    } catch (error) {
      console.error(error);
    } finally {
      loading.value = false;
    }
  }
  return { shiftPatternData, loading, pagination, getShiftPatternList };
}

export function useDeleteShiftPattern(id) {
  const loading = ref(false);
  return {
    deleteData: (async () => {
      loading.value = true;
      const response = await deleteShiftPattern(id);
      switch (response.status) {
        case 200:
          loading.value = false;
          return null;
        default:
          loading.value = false;
          return response.data.errors[0].title;
      }
    })(),
  };
}
