
import {
  defineComponent,
  ref,
  watch,
  watchEffect,
  PropType,
  computed,
} from 'vue';
import { useI18n } from 'vue-i18n';
import Dropdown from '@/components/share/Dropdown/Dropdown.vue';
import TableFlow from './TableFlow.vue';
import Button from '@/components/share/Button/index.vue';
import router from '@/router';
import ConstantsRouter from '@/constants/router';
import { isValidateForm } from '@/util/useValidateForm';
import { usePersonalFlowStore } from '@/store/modules/personal-flows';
import { useRoute } from 'vue-router';
import { PersonalFlowType } from '@/model/PersonalFlows';
import {
  handleCreatePersonalFlowSetting,
  handleUpdatePersonalFlowSetting,
  useResetPersonalSettingFlow,
} from '@/api/composables/approvalSettings';
import { showToast } from '@/util/useToastMessage';

export default defineComponent({
  components: {
    TableFlow,
    Dropdown,
    Button,
  },
  props: {
    data: {
      type: Object as PropType<PersonalFlowType>,
      required: true,
    },
    parentKey: {
      type: Number,
      require: true,
    },
    listEmployee: {},
    listRole: {},
  },
  setup(props) {
    const i18n = useI18n();
    const t = i18n.t;
    const route = useRoute();
    const store = usePersonalFlowStore();
    const dataFlowElement = ref<PersonalFlowType>(props.data);
    const dataFlowElementInit = JSON.parse(JSON.stringify(props.data));
    const validated = ref(false);
    const unit_id = ref(route.query.unit_id || 0);
    const errorServer = ref({});
    const errors = ref({});
    const isBasicFlow = ref();
    const errorsServer = ref({});

    const isValid = () => {
      validated.value = true;
      return isValidateForm(errors);
    };
    watchEffect(() => {
      isBasicFlow.value = props.data.is_basic_flow;
    });
    watchEffect(() => {
      dataFlowElement.value = props.data;
    });
    watch(
      () => props.data.total_level,
      (newVal, oldVal) => {
        let data = JSON.parse(
          JSON.stringify(props.data?.application_flow_details)
        );
        // increase item
        if (oldVal < newVal) {
          for (let i = oldVal; i < newVal; i++) {
            const emptyData = {
              setting_register_flow: 0,
              approval_level: i + 1,
              level_order: 1,
              role_ids: [],
              pp_employee_ids: [],
            };
            data.push(emptyData);
          }
        }
        // decrease item
        else {
          data = JSON.parse(
            JSON.stringify(props.data?.application_flow_details)
          ).filter((item) => item.approval_level <= newVal);
        }
        store.commit('updateApplicationFlowDetails', {
          parentIndex: props.parentKey,
          data,
        });
      }
    );
    const numberOfApprovedFlowsList = Array.from(Array(10).keys()).map(
      (item) => item + 1
    );

    const backPersonalFlowSettingsList = () => {
      router.push({
        path: `/new${ConstantsRouter.PersonalFlowSetting}`,
        query: {
          page_active: route.query?.page_active,
        },
      });
    };
    const checkDataChanged = (listInit, listActive) => {
      if (listInit?.length !== listActive?.length) return true;
      for (let i = 0; i < listInit.length; i++) {
        if (listInit[i] !== listActive[i]) return true;
      }
      return false;
    };
    const checkNoExistDataFlow = () => {
      if (!props.data.application_flow_details?.length) return true;
      if (props.data.total_level > props.data.application_flow_details?.length)
        return true;
      for (let i = 1; i <= props.data.total_level; i++) {
        const listFollowApprovalLevel = props.data.application_flow_details.filter(
          (item) => item.approval_level == i
        );
        if (!listFollowApprovalLevel?.length) return true;
      }
      return false;
    };

    const isDisableSubmit = computed(() => {
      let isDataChange = false;
      if (checkNoExistDataFlow()) return true;
      for (let i = 0; i < props.data.application_flow_details?.length; i++) {
        // validate input roles or employee
        if (
          !props.data.application_flow_details[i]?.role_ids?.length &&
          !props.data.application_flow_details[i]?.pp_employee_ids?.length
        )
          return true;
        // Have data changed length?
        if (
          props.data.application_flow_details[i]?.pp_employee_ids?.length !==
            dataFlowElementInit.application_flow_details[i]?.pp_employee_ids
              ?.length ||
          props.data.application_flow_details[i]?.role_ids?.length !==
            dataFlowElementInit.application_flow_details[i]?.role_ids?.length
        )
          isDataChange = true;
        // Have data changed value
        if (
          (props.data.application_flow_details[i]?.pp_employee_ids?.length &&
            checkDataChanged(
              props.data.application_flow_details[i]?.pp_employee_ids,
              dataFlowElementInit.application_flow_details[i]?.pp_employee_ids
            )) ||
          checkDataChanged(
            props.data.application_flow_details[i]?.role_ids,
            dataFlowElementInit.application_flow_details[i]?.role_ids
          )
        )
          isDataChange = true;
      }
      // delete value
      if (
        props.data.application_flow_details?.length !==
        dataFlowElementInit.application_flow_details?.length
      )
        return false;
      return !isDataChange;
    });

    const onSubmitCreateBasicFlow = async () => {
      if (!isValid()) return;

      const isCreate = props.data.is_basic_flow;
      const unitParams: { unit_id: string } = {
        unit_id: unit_id.value as string,
      };
      if (
        (isCreate && unit_id.value) ||
        (isCreate === null && unit_id.value) ||
        (isCreate === undefined && unit_id.value)
      ) {
        const { create } = handleCreatePersonalFlowSetting({
          unit_id: unit_id.value as string,
          title: '',
          total_level: props.data.total_level,
          application_define_id: props.data.application_define_id,
          application_flow_details: props.data.application_flow_details,
        });
        if (!isValid()) return;
        await create.then((error) => {
          if (error) {
            showToast('error', t('createErrorMessagePersonalFlowSetting'));
          } else {
            showToast('success', t('createSuccessMessagePersonalFlowSetting'));
            backPersonalFlowSettingsList();
          }
        });
      } else {
        const { update } = handleUpdatePersonalFlowSetting(
          `${props.data.application_flow_id}`,
          {
            ...unitParams,
            ...{
              total_level: props.data.total_level,
              application_define_id: props.data.application_define_id,
              application_flow_details: props.data.application_flow_details,
            },
          }
        );
        if (!isValid()) return;
        await update
          .then((error) => {
            if (error) {
              showToast('error', t('updateErrorMessagePersonalFlowSetting'));
            } else {
              showToast(
                'success',
                i18n.t('updateSuccessMessagePersonalFlowSetting')
              );
              backPersonalFlowSettingsList();
            }
          })
          .catch(() => {
            showToast('error', i18n.t('errors.serverError'));
          });
      }
    };
    const resetPersonalFlowSettingsList = async () => {
      const { deleted } = useResetPersonalSettingFlow(
        props.data.application_flow_id
      );
      await deleted
        .then((error) => {
          if (error) {
            errorsServer.value = error.errors.value;
            if (error?.errorsCommon.value) {
              showToast('error', error?.errorsCommon.value);
            }
          } else {
            showToast('success', t('resetToBasicFlow'));
            backPersonalFlowSettingsList();
          }
        })
        .catch(() => {
          showToast('error', t('errors.serverError'));
        });
    };

    return {
      t,
      dataFlowElement,
      numberOfApprovedFlowsList,
      errorServer,
      errors,
      validated,
      isDisableSubmit,
      dataFlowElementInit,
      backPersonalFlowSettingsList,
      onSubmitCreateBasicFlow,
      resetPersonalFlowSettingsList,
      isBasicFlow,
      errorsServer,
    };
  },
});
