
import { computed, ref } from 'vue';
import Button from '@/components/share/Button/index.vue';
import ArrowDownBlueIcon from '@/components/Icons/ArrowDownBlueIcon.vue';
import { useI18n } from 'vue-i18n';
import CloseIcon from '@/components/Icons/CloseIcon.vue';

export default {
  components: {
    Button,
    ArrowDownBlueIcon,
    CloseIcon,
  },
  emits: ['close', 'reset', 'search'],
  setup(props, { emit }) {
    const i18n = useI18n();
    const t = i18n.t;
    const showSearchDetail = ref(false);
    const toggleSearchDetail = () => {
      showSearchDetail.value = !showSearchDetail.value;
    };
    const heightRequestSearch = computed(() => {
      if (searchRequest.value) {
        return searchRequest.value.getBoundingClientRect().height;
      }
      return 0;
    });
    const searchRequest = ref<HTMLDivElement>();

    const closeSearchDetail = () => {
      showSearchDetail.value = false;
      emit('close');
    };
    const resetSearchConditions = () => {
      emit('reset');
    };
    const searchForm = () => {
      emit('search');
    };
    return {
      t,
      showSearchDetail,
      heightRequestSearch,
      searchRequest,
      closeSearchDetail,
      resetSearchConditions,
      searchForm,
      toggleSearchDetail,
    };
  },
};
