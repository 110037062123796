import { toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createVNode as _createVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "sys-input__label"
}
const _hoisted_2 = { class: "normal-label" }
const _hoisted_3 = {
  key: 0,
  class: "label-required"
}
const _hoisted_4 = {
  key: 0,
  class: "normal-note"
}
const _hoisted_5 = {
  class: "absolute",
  style: {"left":"5px"}
}
const _hoisted_6 = { class: "sys-input__notify" }
const _hoisted_7 = {
  key: 0,
  class: "sub-label"
}
const _hoisted_8 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")

  return (_openBlock(), _createBlock("div", {
    class: `sys-input ${_ctx.classes}`
  }, [
    (_ctx.isRequiredLabel || _ctx.label || _ctx.noteMessage)
      ? (_openBlock(), _createBlock("div", _hoisted_1, [
          _createVNode("div", _hoisted_2, [
            (_ctx.isRequiredLabel)
              ? (_openBlock(), _createBlock("div", _hoisted_3, _toDisplayString(_ctx.t('required')), 1))
              : _createCommentVNode("", true),
            _createTextVNode(" " + _toDisplayString(_ctx.label), 1)
          ]),
          (_ctx.noteMessage)
            ? (_openBlock(), _createBlock("div", _hoisted_4, "※" + _toDisplayString(_ctx.noteMessage), 1))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _createVNode("div", {
      class: "items-center flex relative",
      style: { height: _ctx.height }
    }, [
      _createVNode("div", _hoisted_5, [
        _renderSlot(_ctx.$slots, "append", {}, () => [
          _createVNode("span", {
            class: `text-2xl ${_ctx.iconAppend}`,
            style: { color: _ctx.colorIcon }
          }, null, 6)
        ])
      ]),
      _createVNode("input", {
        placeholder: _ctx.placeholder,
        readonly: _ctx.readonly,
        disabled: _ctx.disabled,
        type: _ctx.type,
        value: _ctx.modelValue,
        onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onChangeInput && _ctx.onChangeInput(...args))),
        max: _ctx.max,
        min: _ctx.min,
        class: ["base-input", {
          'validate-error-input':
            (_ctx.isRequiredError && !!_ctx.errorMessage) || _ctx.errorServer.length,
        }],
        style: {
          background: _ctx.readonly ? '#efefef' : '',
          cursor: _ctx.readonly ? 'default' : '',
          paddingLeft: _ctx.iconAppend ? '2rem' : '',
          paddingRight: _ctx.iconPrepend ? '2rem' : '',
        },
        ref: "refInput",
        autofocus: ""
      }, null, 46, ["placeholder", "readonly", "disabled", "type", "value", "max", "min"]),
      _createVNode("div", {
        class: ["absolute cursor-pointer", _ctx.disabled ? 'pointer-events-none' : ''],
        style: {"right":"5px"}
      }, [
        _renderSlot(_ctx.$slots, "prepend", {}, () => [
          _createVNode("span", {
            class: `text-2xl ${_ctx.iconPrepend}`,
            style: { color: _ctx.colorIcon }
          }, null, 6)
        ])
      ], 2)
    ], 4),
    _createVNode("div", _hoisted_6, [
      (!_ctx.isRequiredError && !!_ctx.subLabel)
        ? (_openBlock(), _createBlock("div", _hoisted_7, _toDisplayString(_ctx.subLabel), 1))
        : _createCommentVNode("", true),
      (_ctx.isRequiredError || _ctx.errorServer.length)
        ? (_openBlock(), _createBlock("div", _hoisted_8, [
            _createVNode(_component_ErrorMessage, {
              "error-server": _ctx.errorServer,
              "error-message": _ctx.errorMessage
            }, null, 8, ["error-server", "error-message"])
          ]))
        : _createCommentVNode("", true)
    ])
  ], 2))
}