<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.25 10.5H18"
      stroke="#333333"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14.25 13.5H18"
      stroke="#333333"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.63477 13.5C9.87741 13.5 10.8848 12.4926 10.8848 11.25C10.8848 10.0074 9.87741 9 8.63477 9C7.39212 9 6.38477 10.0074 6.38477 11.25C6.38477 12.4926 7.39212 13.5 8.63477 13.5Z"
      stroke="#333333"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.72852 15.75C5.89497 15.106 6.27066 14.5355 6.79655 14.1283C7.32245 13.721 7.96874 13.5 8.63389 13.5C9.29904 13.5 9.94534 13.7209 10.4713 14.1282C10.9972 14.5354 11.3729 15.1058 11.5394 15.7498"
      stroke="#333333"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M20.25 4.5H3.75C3.33579 4.5 3 4.83579 3 5.25V18.75C3 19.1642 3.33579 19.5 3.75 19.5H20.25C20.6642 19.5 21 19.1642 21 18.75V5.25C21 4.83579 20.6642 4.5 20.25 4.5Z"
      stroke="#333333"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
