import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-full flex mr-12" }
const _hoisted_2 = { class: "table-list-role" }
const _hoisted_3 = { class: "flex justify-end mt-8 items-center" }
const _hoisted_4 = { class: "mr-8 font-bold" }
const _hoisted_5 = { class: "input-search" }
const _hoisted_6 = { class: "flex justify-end mt-8 items-center" }
const _hoisted_7 = { class: "mr-8 font-bold" }
const _hoisted_8 = { class: "input-search" }
const _hoisted_9 = { class: "flex justify-center mt-12 ml-40" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Input = _resolveComponent("Input")
  const _component_Button = _resolveComponent("Button")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode("div", _hoisted_3, [
        _createVNode("div", _hoisted_4, _toDisplayString(_ctx.t('permissionCode')) + ":", 1),
        _createVNode("div", _hoisted_5, [
          _createVNode(_component_Input, {
            modelValue: _ctx.roleApiPostData.role_code,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.roleApiPostData.role_code = $event)),
            required: "",
            validated: _ctx.validated,
            "error-server": _ctx.errorsServer.role_code,
            errors: _ctx.errors.role_code,
            "onUpdate:errors": _cache[2] || (_cache[2] = ($event: any) => (_ctx.errors.role_code = $event))
          }, null, 8, ["modelValue", "validated", "error-server", "errors"])
        ])
      ]),
      _createVNode("div", _hoisted_6, [
        _createVNode("div", _hoisted_7, _toDisplayString(_ctx.t('authorityName')) + ":", 1),
        _createVNode("div", _hoisted_8, [
          _createVNode(_component_Input, {
            modelValue: _ctx.roleApiPostData.role_name,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.roleApiPostData.role_name = $event)),
            required: "",
            validated: _ctx.validated,
            "error-server": _ctx.errorsServer.role_name,
            errors: _ctx.errors.role_name,
            "onUpdate:errors": _cache[4] || (_cache[4] = ($event: any) => (_ctx.errors.role_name = $event))
          }, null, 8, ["modelValue", "validated", "error-server", "errors"])
        ])
      ]),
      _createVNode("div", _hoisted_9, [
        _createVNode(_component_Button, {
          onClick: _ctx.handleSubmitCreateOrUpdateRole,
          class: "btn-gradation-light-blue w-50 h-12 mr-4"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t('toRegister')), 1)
          ]),
          _: 1
        }, 8, ["onClick"]),
        _createVNode(_component_Button, {
          onClick: _ctx.handleBackToTheList,
          class: "btn-gradation-secondary w-50 h-12"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t('backToTheList')), 1)
          ]),
          _: 1
        }, 8, ["onClick"])
      ])
    ])
  ]))
}