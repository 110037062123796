
import { defineComponent, ref, toRefs, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import RadioGroup from '@/components/share/Radio/RadioGroup.vue';
import { WorkingStyleMasterParams } from '@/model/WorkingStyleMaster';
import {
  MINUS_GO_OUT_TIME_IN_WORKING_TIME_TYPE,
  USING_GO_OUT_CHECK_TIME_TYPE,
} from '@/constants/workingStyleMaster';

export default defineComponent({
  components: {
    RadioGroup,
  },
  props: {
    modelValue: { default: {} },
  },
  setup(props) {
    const i18n = useI18n();
    const { modelValue } = toRefs(props);
    const t = i18n.t;
    const managementOfOutingTimeList = [
      {
        value: USING_GO_OUT_CHECK_TIME_TYPE.NOT_USE,
        label: t('notUse'),
      },
      {
        value: USING_GO_OUT_CHECK_TIME_TYPE.USE,
        label: t('use'),
      },
    ];
    const handlingOfOutingTimeList = [
      {
        value: MINUS_GO_OUT_TIME_IN_WORKING_TIME_TYPE.NOT_USE,
        label: t('notIncludedInWorkingHours'),
      },
      {
        value: MINUS_GO_OUT_TIME_IN_WORKING_TIME_TYPE.USE,
        label: t('includedInWorkingHours'),
      },
    ];
    const workingStyleMasterParams = ref<WorkingStyleMasterParams>({});
    workingStyleMasterParams.value = modelValue.value;
    watch(props, () => {
      workingStyleMasterParams.value = modelValue.value;
    });
    return {
      t,
      workingStyleMasterParams,
      managementOfOutingTimeList,
      handlingOfOutingTimeList,
    };
  },
});
