
import arrowDown from '@/assets/svg/arrowDown.svg';
import {
  defineComponent,
  onBeforeUnmount,
  ref,
  toRefs,
  watchEffect,
  watch,
} from '@vue/runtime-core';
import { useI18n } from 'vue-i18n';
import { computed, PropType } from 'vue';
import Input from '@/components/share/Input/index.vue';
import './dropdown.scss';

export type DropdownOption = {
  value: string | number | null;
  text: string;
  disabled?: boolean;
};

export default defineComponent({
  components: { Input },
  props: {
    optionsSelect: {
      type: Array as PropType<DropdownOption[]>,
      default: () => [],
    },
    modelValue: {
      type: ((Number || String || null) as unknown) as PropType<DropdownOption>,
      default: null,
    },
    itemValue: {
      type: String,
      default: 'value',
    },
    itemText: {
      type: String,
      default: 'text',
    },
    placeholder: {
      type: String,
    },
    background: {
      type: String,
    },
    height: {
      type: String,
    },
    classes: {
      type: String,
    },
    required: {
      type: Boolean,
      default: false,
    },
    validated: {
      type: Boolean,
      default: false,
    },
    errorServer: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    noteMessage: {
      type: String,
      required: false,
    },
    isRequiredLabel: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
    },
    subLabel: {
      type: String,
    },
    colorIcon: {
      type: String,
      default: '#666',
    },
    isFixed: {
      type: Boolean,
      default: false,
    },
    heightToAlwayBot: {
      type: Number,
      default: 0,
    },
  },
  setup(props, { emit }) {
    const selectTags = ref<HTMLHtmlElement>();
    const listOptionDropdown = ref<HTMLDivElement>();
    const dropdown = ref<HTMLDivElement>();
    const { modelValue, itemText, itemValue, optionsSelect } = toRefs(props);
    const i18n = useI18n();
    const t = i18n.t;
    const heightToShowBot = 54;

    const isShowOption = ref(false);
    const widthOptionSelect = ref();
    const keySearch = ref('');
    const toggleSelect = () => {
      keySearch.value = '';
      isShowOption.value = !isShowOption.value;
      widthOptionSelect.value = selectTags.value?.offsetWidth;
    };
    const clickOutside = () => {
      keySearch.value = '';
      isShowOption.value = false;
    };

    const selectOption = (item) => {
      let value = item;
      if (typeof item === 'object') {
        value = item[itemValue.value];
      }

      emit('update:modelValue', value);
      emit('change', value, item);
      isShowOption.value = false;
    };
    const indexSelected = computed(() => {
      if (typeof optionsSelect.value[0] !== 'object') {
        return optionsSelect.value.indexOf(modelValue.value);
      }
      const item = optionsSelect.value.find(
        (item) => item[itemValue.value] === modelValue.value
      );
      return item ? optionsSelect.value.indexOf(item) : null;
    });
    const value = computed(() => {
      if (indexSelected.value === null || indexSelected.value === -1) {
        return '';
      }
      if (typeof optionsSelect.value[0] === 'object') {
        return optionsSelect.value[indexSelected.value][itemText.value];
      }
      return optionsSelect.value[indexSelected.value];
    });

    const handleSearchFilter = (key: string) => {
      if (keySearch.value) {
        if (typeof optionsSelect.value[0] === 'object') {
          return optionsSelect.value.filter((item) =>
            item[itemText.value].includes(key)
          );
        }
        return optionsSelect.value.filter((item) =>
          item.toString().includes(key)
        );
      } else {
        return optionsSelect.value;
      }
    };
    const optionsSelectFilter = computed(() => {
      return handleSearchFilter(keySearch.value);
    });

    const onChange = (e) => {
      keySearch.value = e.target.value;
      if (!isShowOption.value) {
        isShowOption.value = true;
      }
    };

    const errorMessage = ref();
    watchEffect(() => {
      emit('update:errors', errorMessage.value);
    });
    onBeforeUnmount(() => {
      emit('update:errors', '');
    });

    // logic for position show list option
    watch(
      () => isShowOption.value,
      async () => {
        if (listOptionDropdown.value && isShowOption.value && dropdown.value) {
          const distanceElementToBottom =
            window.innerHeight - dropdown.value.getBoundingClientRect().bottom;
          const heightListOption =
            optionsSelectFilter.value.length > 11
              ? optionsSelectFilter.value.length
              : 11 * 40;
          if (!props.isFixed) {
            if (distanceElementToBottom < heightListOption) {
              listOptionDropdown.value.style.top = 'unset';
              listOptionDropdown.value.style.bottom = 60 + 'px';
            } else {
              listOptionDropdown.value.style.bottom = 'unset';
              listOptionDropdown.value.style.top = 54 + 'px';
            }
          } else {
            if (props.heightToAlwayBot > heightListOption + heightToShowBot) {
              if (distanceElementToBottom < heightListOption) {
                listOptionDropdown.value.style.bottom = heightToShowBot + 'px';
              } else {
                listOptionDropdown.value.style.bottom = 'unset';
                listOptionDropdown.value.style.top = 54 + 'px';
              }
            } else {
              listOptionDropdown.value.style.bottom = 'unset';
              listOptionDropdown.value.style.top = 54 + 'px';
            }
          }
        }
      }
    );
    return {
      t,
      arrowDown,
      selectTags,
      isShowOption,
      indexSelected,
      widthOptionSelect,
      value,
      optionsSelectFilter,
      errorMessage,
      onChange,
      toggleSelect,
      selectOption,
      clickOutside,
      listOptionDropdown,
      dropdown,
    };
  },
});
