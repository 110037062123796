import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "flex items-center" }
const _hoisted_2 = { class: "w-40 relative" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DateInput = _resolveComponent("DateInput")
  const _component_MonthInput = _resolveComponent("MonthInput")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    (_ctx.isIconChevronLeft)
      ? (_openBlock(), _createBlock("a", {
          key: 0,
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onNextDate(-1))),
          class: "cursor-pointer hover:opacity-75 cp-icon-chevron-left text-2xl"
        }))
      : _createCommentVNode("", true),
    _createVNode("div", _hoisted_2, [
      (_ctx.type === 'date')
        ? (_openBlock(), _createBlock(_component_DateInput, {
            key: 0,
            modelValue: _ctx.date,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.date = $event)),
            disabled: _ctx.disabled,
            placeholder: _ctx.placeholder,
            format: _ctx.format,
            isFixed: _ctx.isFixed,
            heightToAlwayBot: _ctx.heightToAlwayBot,
            "error-server": _ctx.errorServer,
            required: _ctx.required,
            validated: _ctx.validated,
            errors: _ctx.errorMessage,
            "onUpdate:errors": _cache[3] || (_cache[3] = ($event: any) => (_ctx.errorMessage = $event))
          }, null, 8, ["modelValue", "disabled", "placeholder", "format", "isFixed", "heightToAlwayBot", "error-server", "required", "validated", "errors"]))
        : (_openBlock(), _createBlock(_component_MonthInput, {
            key: 1,
            modelValue: _ctx.date,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (_ctx.date = $event)),
            disabled: _ctx.disabled,
            placeholder: _ctx.placeholder,
            format: _ctx.format,
            isFixed: _ctx.isFixed,
            "error-server": _ctx.errorServer,
            required: _ctx.required,
            validated: _ctx.validated,
            errors: _ctx.errorMessage,
            "onUpdate:errors": _cache[5] || (_cache[5] = ($event: any) => (_ctx.errorMessage = $event))
          }, null, 8, ["modelValue", "disabled", "placeholder", "format", "isFixed", "error-server", "required", "validated", "errors"]))
    ]),
    (_ctx.isIconChevronRight)
      ? (_openBlock(), _createBlock("a", {
          key: 1,
          onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.onNextDate(1))),
          class: "cursor-pointer hover:opacity-75 cp-icon-chevron-right text-2xl"
        }))
      : _createCommentVNode("", true)
  ]))
}