
import { defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import Table from '@/components/Table.vue';
import Button from '@/components/Button.vue';
import router from '@/router';
import { usePopupDeleteStore } from '@/store/modules/popup-delete';
import { useActionStore } from '@/store/modules/copy-action';
import useListMonthlyClosingDay from '@/api/composables/useMonthlyClosingDay';
import { useDeleteMonthlyClosingDay } from '@/api/composables/useMonthlyClosingDay';
import { useStore } from 'vuex';
import ConstantsRouter from '@/constants/router';
export default defineComponent({
  components: { Button, Table },
  setup() {
    const i18n = useI18n();
    const isId = ref();
    const store = useStore();
    const storePopup = usePopupDeleteStore();
    const currentPage = ref(1);
    const {
      pagination,
      closingDayMasterData,
      loading,
      useGetListMonthlyClosingDay,
    } = useListMonthlyClosingDay({
      page: currentPage.value,
      per_page: '',
      total: '',
    });

    const closingDaySelected = ref([]);
    const holidayHeaders = [
      {
        text: i18n.t('closeDayCode'),
        value: 'monthly_closing_day_code',
        width: '20%',
      },
      { text: i18n.t('closeDayName'), value: 'monthly_closing_day_name' },
      {
        text: i18n.t('edit'),
        value: 'editAction',
        width: '8%',
        background: '#f2f8fc',
      },
      {
        text: i18n.t('delete'),
        value: 'removeAction',
        width: '8%',
        background: '#f2f8fc',
      },
      {
        text: i18n.t('copy'),
        value: 'copyAction',
        width: '8%',
        background: '#f2f8fc',
      },
    ];
    const registerCloseDayMaster = () => {
      router.push(ConstantsRouter.MonthlyClosingDayCreat);
    };
    const openDeleteClosingDayPopup = (item) => {
      isId.value = item.id;
      storePopup.dispatch('show', {
        isShown: true,
        onSubmit: onDeleteClosingDay,
        item,
      });
    };
    const onDeleteMonthlyClosingDay = async () => {
      const { deleteData } = useDeleteMonthlyClosingDay(isId.value);
      deleteData
        .then((error) => {
          if (error) {
            store.commit('root/SET_NOTIFICATION', {
              type: 'error',
              message: i18n.t('failMessage.monthlyClosingDayFail'),
            });
          } else {
            store.commit('root/SET_NOTIFICATION', {
              type: 'success',
              message: i18n.t('successMessage.monthlyClosingDayDeleteSuccess'),
            });
          }
          useGetListMonthlyClosingDay({
            page: currentPage.value,
            per_page: 20,
            total: 1,
          });
          close();
        })
        .catch(() => {
          close();
          store.commit('root/SET_NOTIFICATION', {
            type: 'error',
            message: i18n.t('errors.serverError'),
          });
        });
    };
    const storeAction = useActionStore();
    const onDeleteClosingDay = () => {
      storePopup.dispatch('hide');
      onDeleteMonthlyClosingDay();
    };
    const redirectCopyAction = (item) => {
      storeAction.commit('SET_ACTION_COPY', item);
      router.push(ConstantsRouter.MonthlyClosingDayCopy);
    };
    const onChangePage = () => {
      useGetListMonthlyClosingDay({
        page: currentPage.value,
      });
    };
    const redirectClosingDayUpdate = (item) => {
      router.push({
        path: ConstantsRouter.MonthlyClosingDayUpdate,
        query: {
          id: item.id,
        },
      });
    };
    return {
      t: i18n.t,
      holidayHeaders,
      loading,
      pagination,
      closingDayMasterData,
      closingDaySelected,
      currentPage,
      onChangePage,
      registerCloseDayMaster,
      openDeleteClosingDayPopup,
      onDeleteClosingDay,
      redirectCopyAction,
      redirectClosingDayUpdate,
    };
  },
});
