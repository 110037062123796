import { ref } from 'vue';
import { UnitApiGetResult } from '@/api/types/UnitApi';
import { getUnit } from '@/api/unit';
import { Pagination } from '@/model/Pagination';

export default function (params) {
  const unitList = ref<UnitApiGetResult[]>([]);
  const pagination = ref<Pagination>({
    current_page: 1,
    per_page: 1,
    total: 0,
  });
  const loading = ref(false);
  const response = ref();

  async function getUnitData(params) {
    try {
      loading.value = true;
      const res = await getUnit(params);
      switch (res.status) {
        case 200:
          unitList.value = res.data.data;
          response.value = res.data;
          pagination.value = response.value.meta.pagination;
          break;
        default:
          unitList.value = [];
          break;
      }
    } catch (error) {
      console.error(error);
    } finally {
      loading.value = false;
    }
  }

  getUnitData(params);

  return { unitList, loading, getUnitData, pagination };
}
