import type {
  CurrentPageType,
  NotificationType,
  BackRouterPath,
  ValidateType,
  ValidType,
} from '@/types';

export enum TypeErrors {
  required = 'REQUIRED',
  max = 'MAX',
  min = 'MIN',
}

export type State = {
  currentPage: CurrentPageType;
  notification: Partial<NotificationType>;
  backRouterPath: BackRouterPath;
  validate: Partial<ValidateType>;
  valid: ValidType;
};

export const state: State = {
  currentPage: '',
  notification: {},
  backRouterPath: '',
  validate: [TypeErrors.required, TypeErrors.max, TypeErrors.min],
  valid: [],
};
