import moment from 'moment-timezone';
import { isArray } from 'lodash';
import { COMPANY, showLoginError } from '@/router';
import { isEmpty } from 'lodash';
export const KEY_TOKEN = 'draco_idp_token';
export const SUB_DOMAIN =
  isArray(location.host?.split('-')) && location.host?.split('-').length > 1
    ? location.host.split('-')[0]
    : '';
export const PP_EMPLOYEE_ID = 'pp_employee_id';
export const UNIT_NAME = 'unit_name';
export const EMPLOYEE_NAME = 'employee_name';
export const UNIT_ID = 'unit_id';
export const redirLoginCydas = (hrefEnCode) => {
  return (window.location.href = `https://${SUB_DOMAIN}-login.cydas.com/${COMPANY}/sso_idp_token?url=${hrefEnCode}`);
};
export const redirLogoutCydas = () => {
  return (window.location.href = `https://${SUB_DOMAIN}-login.cydas.com/${COMPANY}/single_logout`);
};
export const handleCheckAuthen = (isLogout?: boolean) => {
  console.log('SUB_DOMAIN :>> ', SUB_DOMAIN);
  deleteCookie(PP_EMPLOYEE_ID);
  if (!SUB_DOMAIN) {
    //localhost env
    showLoginError();
  } else {
    //production env
    const hrefEnCode = window.btoa(
      `${window.location.origin}/${COMPANY}/new/stamp`
    );
    isLogout ? redirLogoutCydas() : redirLoginCydas(hrefEnCode);
  }
};
export function getFullElementDate() {
  const momentDate = moment().tz('Asia/Tokyo');
  const month = handlePrefixZero(momentDate.month() + 1);
  const day = handlePrefixZero(momentDate.day());
  const year = handlePrefixZero(momentDate.year());
  const date = handlePrefixZero(momentDate.date());
  const hours = handlePrefixZero(momentDate.hours());
  const minutes = handlePrefixZero(momentDate.minutes());
  const seconds = handlePrefixZero(momentDate.seconds());
  return { month, date, day, year, hours, minutes, seconds };
}

export function handlePrefixZero(number) {
  if (number > 9) {
    return number.toString();
  }
  return `0${number}`;
}

export const daysOfWeek = {
  '00': 'sunday',
  '01': 'monday',
  '02': 'tuesday',
  '03': 'wednesday',
  '04': 'thursday',
  '05': 'friday',
  '06': 'saturday',
};

export const ActionType = {
  UPDATE: 'update',
  CREATE: 'create',
  COPY: 'copy',
};

export function convertToDateTime(number, format) {
  if (!number) {
    return '';
  }
  if (typeof number === 'string') {
    if (number.indexOf(':') !== -1) {
      return number;
    }
  }
  number = number * 1;
  let sign = '';
  if (number < 0) {
    sign = '- ';
  }
  const hour = Math.trunc(Math.abs(number) / 60);
  const minutes = Math.abs(number) - hour * 60;
  return (
    sign +
    (moment(hour, 'HH').isValid() ? moment(hour, 'HH').format('HH') : hour) +
    moment(minutes, 'mm').format(':mm')
  );
}

export const convertErrorCodeToObjective = (data, keyMapingError) => {
  const handleData = data.map((r) => {
    return {
      key: r.source.pointer.split('/').join('.').substring(1),
      value: r.title,
    };
  });
  let pointerMapingError = handleData.filter((r) => {
    return keyMapingError.some((k) => {
      return (
        r.key.replace(/[0-9]/g, '').replace('[', '').replace(']', '') === k
      );
    });
  });

  const pointerNotKeyMapingError = convertKeyFun(
    handleData.filter(
      ({ key: id1 }) => !pointerMapingError.some(({ key: id2 }) => id2 === id1)
    )
  );

  pointerMapingError = convertKeyFun(pointerMapingError);
  return { pointerMapingError, pointerNotKeyMapingError };
};

export const convertKeyFun = (convertKeyParam) => {
  return convertKeyParam.reduce(
    (obj, item) => Object.assign(obj, { [item.key]: item.value }),
    {}
  );
};

export function getCookie(cname) {
  const name = cname + '=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let c of ca) {
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

export function deleteCookie(name) {
  document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

export function saveCookie(key: string, value) {
  document.cookie = `${key}=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT`;
  document.cookie = `${key}=${value};path=/;`;
}
export function handleYear(number) {
  if (number) {
    return number.slice(0, 4);
  }
  return '';
}

export function handleMonth(number) {
  if (number)
    return moment(number.slice(5, 10)).isValid()
      ? moment(number.slice(5, 10)).format('MM/DD')
      : '';
  return '';
}

export const queryStringFromObject = (params) => {
  params = Object.fromEntries(
    Object.entries(params).filter(([, v]) => !isEmpty(v) && v && v !== 0)
  );
  if (!isEmpty(params)) {
    return Object.keys(params)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
      )
      .join('&');
  }
  return '';
};

export const displayDateFormat = (date, format) => {
  if (!date) {
    return '';
  }
  return moment(date).utc(true).format(format);
};
