
import router from '@/router';
import { defineComponent } from '@vue/runtime-core';
export default defineComponent({
  props: {
    dataTabs: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const currentRouter = router.currentRoute;
    return {
      currentRouter,
    };
  },
});
