
import { defineComponent } from '@vue/runtime-core';

export default defineComponent({
  props: {
    placeholder: {
      type: String,
    },
    modelValue: {},
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {};
  },
});
