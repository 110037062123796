
import Avatar from '@/components/share/Avatar/index.vue';
import { defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import Input from '@/components/share/Input/index.vue';
import Table from '@/components/share/Table/ScrollTable.vue';
import DatePickerInput from '@/components/share/Calendar/DatePickerInput.vue';
import Select from '@/components/share/Dropdown/Dropdown.vue';
import Button from '@/components/share/Button/index.vue';
import PopupConfirmSubmit from '@/views/PopupShiftCreate/PopupConfirmSubmit.vue';
import { computed, onMounted } from '@vue/runtime-core';
import { usePostShiftPlanSubmit } from '@/api/composables/useShiftPlanSubmit';
import useGetPlanDepartment from '@/api/composables/useShiftPlanDepartment';
import { useStore } from 'vuex';
import { showToast } from '@/util/useToastMessage';
import { getSearchEmploy } from '@/api/composables/useGetSearchEmployee';
import { useLoginAccountStore } from '@/store/modules/login-account';
import { isValidateForm } from '@/util/useValidateForm';
import ConstantsRouter from '@/constants/router';
import router from '@/router';
import useGetPlanDetail, {
  useUpdatePlanDetail,
} from '@/api/composables/useShiftPlanDetail';
import { ShiftPlanSubmit } from '@/api/types/ShiftPlanSubmit';
import { getCookie } from '@/util/composables';
import BackPreviousPage from '@/components/share/BackPreviousPage/index.vue';
import ImportOrExportShiftPlan from '@/views/ShiftManagement/ImportOrExportShiftPlan.vue';
import AddTargetPersonPopup from '@/views/ShiftManagement/AddTargetPersonPopup.vue';
import WorkPatternShiftPlan from '@/views/ShiftManagement/WorkPatternShiftPlan.vue';

export default defineComponent({
  components: {
    WorkPatternShiftPlan,
    AddTargetPersonPopup,
    ImportOrExportShiftPlan,
    BackPreviousPage,
    PopupConfirmSubmit,
    Table,
    Select,
    Input,
    DatePickerInput,
    Button,
    Avatar,
  },

  setup() {
    const rootStore = useStore();
    const pp_employee_id = getCookie('pp_employee_id') || '';
    const i18n = useI18n();
    let shiftPlanId = router.currentRoute.value.query.id as string;
    const { shiftPlanOption, getPlanDepartmentData } = useGetPlanDepartment();

    const ITEM_STATUS = 3;
    const isShowPopupConfirmSubmit = ref(false);
    const isShowAddTargetPersonPopup = ref(false);
    const accountStore = useLoginAccountStore();

    const { shiftPlanDetail, getPlanDetailData } = useGetPlanDetail();
    const membersToSubmittedHeaders = [
      {
        text: '',
        value: 'action',
        width: 50,
      },
      {
        text: i18n.t('employeeNumber'),
        value: 'employee_cd',
        width: 120,
      },
      {
        text: i18n.t('name'),
        value: 'name',
        width: 200,
        justify: 'start',
      },
      {
        text: i18n.t('unit'),
        value: 'unit_name',
        width: 120,
        bodyClass: 'truncate-2-line',
      },
      {
        text: i18n.t('workingStyle'),
        value: 'working_style',
        width: 120,
        bodyClass: 'truncate-2-line',
      },
    ];
    const itemPerPages = ref(10);
    const currentPage = ref(1);
    const myAccount = computed(() => accountStore.state.myAccount);

    const apiPostData = ref<ShiftPlanSubmit>({
      shift_plan_name: '',
      shift_plan_creater_id: '',
      unit_id: myAccount.value.unit_id,
      register_start_date: '',
      register_end_date: '',
      pp_employee_ids: [],
    });
    const errors = ref({});
    const validated = ref(false);
    const errorServer = ref({});
    const statusNewShift = ref();

    // computed area
    const membersToSubmittedData = computed(
      () => rootStore.state.shiftPlan.listUsersSelected
    );
    const employeesData = computed(() => rootStore.state.shiftPlan.listUsers);
    const isDisabledBtn = computed(
      () =>
        Object.values(errors.value).some((e) => !!e) ||
        membersToSubmittedData.value.length === 0
    );

    // function area
    const backToListShiftPlan = () => {
      router.push('/new' + ConstantsRouter.ShiftBeingCreatedNew);
    };
    const proceedToShiftCreation = async () => {
      let idPlanShift = '';
      apiPostData.value.pp_employee_ids = membersToSubmittedData.value.map(
        (item) => item.id
      );
      apiPostData.value.shift_plan_creater_id = pp_employee_id;
      if (!shiftPlanId) {
        idPlanShift = await apiCreateShiftDetail();
      }
      const id = shiftPlanId ? shiftPlanId : idPlanShift;
      if (id) {
        await router.push({
          name: ConstantsRouter.ShiftTimeManagerName,
          params: { id },
        });
      }
    };

    const openConfirmSubmit = () => {
      isShowPopupConfirmSubmit.value = true;
    };
    const closeConfirmSubmit = () => {
      isShowPopupConfirmSubmit.value = false;
    };

    const openAddTargetPerson = async () => {
      isShowAddTargetPersonPopup.value = true;
    };
    const closeAddTargetPerson = () => {
      currentPage.value = 1;
      isShowAddTargetPersonPopup.value = false;
    };
    const deletePlanEmployee = (index) => {
      const newList = membersToSubmittedData.value.filter(
        (_item, i) => index != i
      );
      rootStore.commit('shiftPlan/SET_LIST_USER_SELECTED', newList);
      if (
        currentPage.value > 1 &&
        membersToSubmittedData.value.length % itemPerPages.value === 0 &&
        currentPage.value >
          Math.trunc(membersToSubmittedData.value.length / itemPerPages.value)
      ) {
        currentPage.value -= 1;
      }
    };

    const openShiftConfirm = async () => {
      let idPlanShift = '';
      apiPostData.value.pp_employee_ids = membersToSubmittedData.value.map(
        (item) => item.id
      );
      apiPostData.value.shift_plan_creater_id = pp_employee_id;
      if (!shiftPlanId) {
        idPlanShift = await apiCreateShiftDetail();
      }
      const id = shiftPlanId ? shiftPlanId : idPlanShift;
      if (id) {
        await router.push({
          name: ConstantsRouter.ConfirmNewShiftName,
          params: { id },
        });
      }
    };

    const isValid = () => {
      validated.value = true;
      return isValidateForm(errors);
    };
    const resetValidate = () => {
      validated.value = false;
      errors.value = {};
      errorServer.value = {};
    };
    const submitCreateOrUpdateShiftPlan = async () => {
      if (!isValid()) {
        return;
      }
      apiPostData.value.pp_employee_ids = membersToSubmittedData.value.map(
        (item) => item.id
      );
      apiPostData.value.shift_plan_creater_id = pp_employee_id;
      if (!shiftPlanId) {
        await apiCreateShiftDetail();
      } else {
        await apiUpdateShiftDetail();
      }
    };
    const apiCreateShiftDetail = async () => {
      const { registered, shiftPlanCreatedId } = usePostShiftPlanSubmit(
        apiPostData.value
      );
      await registered
        .then((error) => {
          if (error) {
            errorServer.value = error.errors.value;
            if (error.errorsCommon.value) {
              showToast('error', error.errorsCommon.value);
            } else {
              showToast('warning', i18n.t('invalidInput'));
            }
          } else {
            showToast(
              'success',
              i18n.t('successMessage.shiftPlanHasBeenCreated')
            );
            shiftPlanId = shiftPlanCreatedId.value;
            goToImportOrExportShiftPlan();
          }
        })
        .catch(() => {
          showToast('error', i18n.t('errors.serverError'));
        });
      return shiftPlanCreatedId.value;
    };
    const apiUpdateShiftDetail = () => {
      const { update } = useUpdatePlanDetail(shiftPlanId, apiPostData.value);
      update
        .then((error) => {
          if (error) {
            errorServer.value = error.errors.value;
            if (error.errorsCommon.value) {
              showToast('error', error.errorsCommon.value);
            } else {
              showToast('warning', i18n.t('invalidInput'));
            }
          } else {
            showToast(
              'success',
              i18n.t('successMessage.shiftPlanHasBeenUpdated')
            );
            goToImportOrExportShiftPlan();
          }
        })
        .catch(() => {
          showToast('error', i18n.t('errors.serverError'));
        });
    };

    const isImportOrExportShiftPlan = ref(false);
    const goToImportOrExportShiftPlan = () => {
      resetValidate();
      router.replace({
        path: router.currentRoute.value.fullPath,
        query: { id: shiftPlanId },
      });
      isImportOrExportShiftPlan.value = true;
    };
    const backShiftPlanRegister = () => {
      isImportOrExportShiftPlan.value = false;
    };

    const onChangeUnitId = async (_value, item) => {
      const res = await getSearchEmploy({
        unitId: item.unit_id,
      });
      rootStore.commit('shiftPlan/SET_LIST_USER_SELECTED', res.data);
    };

    const getShiftPlanDetail = async () => {
      //Call api get detail shift
      await getPlanDetailData(shiftPlanId);
      apiPostData.value = {
        ...shiftPlanDetail.value,
        register_end_date: shiftPlanDetail.value.register_end_date.replaceAll(
          '-',
          '/'
        ),
        register_start_date: shiftPlanDetail.value.register_start_date.replaceAll(
          '-',
          '/'
        ),
      };
      statusNewShift.value = shiftPlanDetail.value.shift_plan_status;
      const employeeIds = shiftPlanDetail.value.shift_plan_employees.map(
        (item) => item.ppEmployeeId
      );
      await rootStore.commit(
        'shiftPlan/SET_LIST_USER_SELECTED',
        employeesData.value.filter((item) => employeeIds.includes(item.id))
      );
    };
    onMounted(async () => {
      const res = await getSearchEmploy();
      const resUserSelected = await getSearchEmploy({
        unitId: apiPostData.value.unit_id,
      });
      rootStore.commit('shiftPlan/SET_LIST_USER', res.data);
      if (!shiftPlanId) {
        await rootStore.commit(
          'shiftPlan/SET_LIST_USER_SELECTED',
          resUserSelected.data
        );
      } else {
        getShiftPlanDetail();
      }
      getPlanDepartmentData();
    });

    return {
      t: i18n.t,
      isDisabledBtn,
      membersToSubmittedHeaders,
      membersToSubmittedData,
      isShowPopupConfirmSubmit,
      isShowAddTargetPersonPopup,
      shiftPlanOption,
      errors,
      shiftPlanDetail,
      shiftPlanId,
      statusNewShift,
      ITEM_STATUS,
      apiPostData,
      accountStore,
      myAccount,
      validated,
      errorServer,
      currentPage,
      itemPerPages,
      isImportOrExportShiftPlan,
      employeesData,
      openConfirmSubmit,
      closeConfirmSubmit,
      openAddTargetPerson,
      closeAddTargetPerson,
      deletePlanEmployee,
      backToListShiftPlan,
      openShiftConfirm,
      submitCreateOrUpdateShiftPlan,
      isValid,
      getShiftPlanDetail,
      proceedToShiftCreation,
      backShiftPlanRegister,
      onChangeUnitId,
    };
  },
});
