
import { defineComponent, ref, watchEffect } from 'vue';
import { useI18n } from 'vue-i18n';
import Button from '@/components/share/Button/index.vue';
import Table from '@/components/share/Table/Table.vue';
import RadioGroup from '@/components/share/Radio/RadioGroup.vue';
import PlusIcon from '@/components/Icons/PlusIcon.vue';
import {
  DEFINE_TYPE_WORKING_STYLE,
  DEFINE_TYPE_APPROVE,
} from '@/constants/defineTypeWorkingStyle';
import BackPreviousPage from '@/components/share/BackPreviousPage/index.vue';
import router from '@/router';
import ConstantsRouter from '@/constants/router';
import {
  useGetDetailTimeRecoder,
  usePostTimeRecoder,
  useUpdateTimeRecoder,
} from '@/api/composables/useTimeRecoder';
import { showToast } from '@/util/useToastMessage';
import { isValidateForm } from '@/util/useValidateForm';
import { TimeRecoderParamsDetail } from '@/model/TimeRecoder';

import { useRoute } from 'vue-router';

export default defineComponent({
  components: {
    Button,
    Table,
    BackPreviousPage,
    RadioGroup,
    PlusIcon,
  },
  setup: function () {
    const i18n = useI18n();
    const t = i18n.t;
    const route = useRoute();
    const timeCoderId = route.query.unitId as string;
    const timeCoderAccountId = route.query.unitAccountId as string;
    const timeCoderName = route.query.unitName as string;
    const timeRecoderHeader = [
      {
        text: i18n.t('settingTimeRecoder.workingStyle'),
        value: 'type_of_work_form_define_name',
        width: '200px',
      },
      {
        text: i18n.t('settingTimeRecoder.browserStamping'),
        value: 'is_browser_stamping',
        width: '300px',
      },
      {
        text: i18n.t('settingTimeRecoder.terminalStamping'),
        value: 'is_ic_card_check_time',
        width: '300px',
      },
      {
        text: i18n.t('settingTimeRecoder.faceRecognitionStamp'),
        value: 'is_face_recognition_stamping',
        width: '300px',
      },
    ];

    const statusRecoderList = [
      {
        value: DEFINE_TYPE_APPROVE.TO_APPROVE,
        label: i18n.t('settingTimeRecoder.toApprove'),
      },
      {
        value: DEFINE_TYPE_APPROVE.NOT_ALLOWED,
        label: i18n.t('settingTimeRecoder.notAllowed'),
      },
    ];
    const returnScreen = () => {
      router.push(`/new${ConstantsRouter.TimeRecoderList}`);
    };
    const apiPostTimeRecoder = ref<TimeRecoderParamsDetail>({
      unit_id: timeCoderId,
      time_recorder_details: [
        {
          type_of_work_form_define_id: '',
          type_of_work_form_define_name: '',
          is_browser_stamping: true,
          is_ic_card_check_time: true,
          is_face_recognition_stamping: true,
        },
        {
          type_of_work_form_define_id: '',
          type_of_work_form_define_name: '',
          is_browser_stamping: true,
          is_ic_card_check_time: true,
          is_face_recognition_stamping: true,
        },
        {
          type_of_work_form_define_id: '',
          type_of_work_form_define_name: '',
          is_browser_stamping: true,
          is_ic_card_check_time: true,
          is_face_recognition_stamping: true,
        },
        {
          type_of_work_form_define_id: '',
          type_of_work_form_define_name: '',
          is_browser_stamping: true,
          is_ic_card_check_time: true,
          is_face_recognition_stamping: true,
        },
        {
          type_of_work_form_define_id: '',
          type_of_work_form_define_name: '',
          is_browser_stamping: true,
          is_ic_card_check_time: true,
          is_face_recognition_stamping: true,
        },
      ],
    });
    const errorsServer = ref({});
    const errors = ref({});
    const validated = ref(false);
    const isValid = () => {
      validated.value = true;
      return isValidateForm(errors);
    };
    const createPostDataTimeRecoder = async () => {
      if (!isValid()) return;
      const { postData } = usePostTimeRecoder(apiPostTimeRecoder.value);
      postData
        .then((errors) => {
          if (errors) {
            errorsServer.value = errors.errors.value;
            if (errors?.errorsCommon.value) {
              showToast('error', errors?.errorsCommon.value);
            } else {
              showToast('warning', t('invalidInput'));
            }
          } else {
            showToast('success', t('settingTimeRecoder.messageCreatSuccess'));
            returnScreen();
          }
        })
        .catch(() => {
          showToast('error', t('errors.serverError'));
        });
    };
    const updateDataTimeRecoder = async () => {
      if (!isValid()) return;
      const { update } = useUpdateTimeRecoder(timeCoderId, {
        ...timeRecoder.value,
        ...apiPostTimeRecoder.value,
      });
      update
        .then((errors) => {
          if (errors) {
            errorsServer.value = errors.errors.value;
            if (errors?.errorsCommon.value) {
              showToast('error', errors?.errorsCommon.value);
            } else {
              showToast('warning', t('invalidInput'));
            }
          } else {
            showToast('success', t('settingTimeRecoder.messageUpdateSuccess'));
            returnScreen();
          }
        })
        .catch(() => {
          showToast('error', t('errors.serverError'));
        });
    };
    const {
      timeRecoder,
      useDetailTimeRecoder,
      loading,
      isCheckCreateTimeCoder,
    } = useGetDetailTimeRecoder();
    watchEffect(() => {
      if (timeRecoder.value) {
        apiPostTimeRecoder.value.time_recorder_details = timeRecoder.value;
      }
    });

    const handleNavigationTimeRecoder = async () => {
      if (isCheckCreateTimeCoder.value) {
        await createPostDataTimeRecoder();
      }
      if (
        (timeCoderId && !isCheckCreateTimeCoder.value) ||
        timeCoderAccountId
      ) {
        await updateDataTimeRecoder();
      }
    };
    useDetailTimeRecoder(timeCoderId);
    const handleTypeOfWorkingStyleDefines = (item) => {
      if (item === DEFINE_TYPE_WORKING_STYLE.STANDARD_WORK) {
        return i18n.t('settingTimeRecoder.standardWork');
      } else if (item === DEFINE_TYPE_WORKING_STYLE.STANDARD_WORK_SHIFT) {
        return i18n.t('settingTimeRecoder.standardWorkShift');
      } else if (item === DEFINE_TYPE_WORKING_STYLE.TRANSFORMATION_LABOR) {
        return i18n.t('settingTimeRecoder.transformationLabor');
      } else if (item === DEFINE_TYPE_WORKING_STYLE.DISCRETIONARY_LABOR) {
        return i18n.t('settingTimeRecoder.discretionaryLabor');
      } else if (item === DEFINE_TYPE_WORKING_STYLE.PART_TIME_JOB) {
        return i18n.t('settingTimeRecoder.partTimeJob');
      }
    };

    return {
      t,
      i18n,

      timeRecoderHeader,
      statusRecoderList,
      errorsServer,
      errors,
      validated,
      timeRecoder,
      loading,
      apiPostTimeRecoder,
      isCheckCreateTimeCoder,
      timeCoderId,
      timeCoderName,
      timeCoderAccountId,
      isValid,
      returnScreen,
      createPostDataTimeRecoder,
      handleNavigationTimeRecoder,
      handleTypeOfWorkingStyleDefines,
    };
  },
});
