import moment from 'moment';

const FORMAT_MONTH = ['YYYY/MM', 'YYYY-MM', 'MM/YYYY', 'MM-YYYY', 'YYYY年MM月'];
type FormatMonthTypes = typeof FORMAT_MONTH;
type FormatMonthType = FormatMonthTypes[number];
const FORMAT_DATE = [
  'YYYY/MM/DD',
  'YYYY-MM-DD',
  'DD/MM/YYYY',
  'DD-MM-YYYY',
  'YYYY年MM月DD日(dd)',
];
export const DEFINE_DAY = 'YYYY年MM月DD日(dd)';
export const DAY_FORMAT = 'YYYY/MM/DD';
type FormatDateTypes = typeof FORMAT_DATE;
type FormatDateType = FormatDateTypes[number];

export const isValidTime = (time, format) => {
  const timeMoment = moment(time, format);
  return timeMoment.isValid();
};

export const isValidFormat = (checked_date, format) => {
  return moment(checked_date, format).format(format) === checked_date;
};

//function support for month
export const isAllValidMonth = (time) => {
  for (let i = 0; i < FORMAT_MONTH.length; i++) {
    if (
      isValidTime(time, FORMAT_MONTH[i]) &&
      isValidFormat(time, FORMAT_MONTH[i])
    ) {
      return true;
    }
  }
  return false;
};

export const formatTimeMonth = (
  time,
  format: FormatMonthType = 'YYYY年MM月'
) => {
  for (let i = 0; i < FORMAT_MONTH.length; i++) {
    if (isValidTime(time, FORMAT_MONTH[i])) {
      return moment(time, FORMAT_MONTH[i]).format(format);
    }
  }
  return '';
};

export const getMonthYearFromDate = (
  time,
  format: FormatMonthType = 'YYYY/MM'
) => {
  if (isAllValidDate(time)) {
    for (let i = 0; i < FORMAT_DATE.length; i++) {
      if (isValidTime(time, FORMAT_DATE[i])) {
        return moment(time, FORMAT_DATE[i]).format(format);
      }
    }
  }
  return '';
};

//function support for date
export const isAllValidDate = (time) => {
  for (let i = 0; i < FORMAT_DATE.length; i++) {
    if (isValidTime(time, FORMAT_DATE[i])) {
      return true;
    }
  }
  return false;
};
export const formatTimeDate = (time, format: FormatDateType = 'YYYY/MM/DD') => {
  for (let i = 0; i < FORMAT_DATE.length; i++) {
    if (isValidTime(time, FORMAT_DATE[i])) {
      return moment(time, FORMAT_DATE[i]).format(format);
    }
  }
  return '';
};
