import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = { tabindex: "0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RadioInput = _resolveComponent("RadioInput")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.optionsRadio, (item) => {
      return (_openBlock(), _createBlock(_component_RadioInput, {
        class: ["mr-5", _ctx.vertical ? 'w-full mt-2' : ''],
        key: item.value,
        value: item.value,
        label: item.label,
        modelValue: _ctx.selected,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.selected = $event))
      }, null, 8, ["class", "value", "label", "modelValue"]))
    }), 128))
  ]))
}