import { MutationTree } from 'vuex';

import type { AvatarUserState, userInfo } from '@/types';

import { State } from './state';
import { RootIndexMutationTypes as MutationTypes } from './mutation-types';

export type Mutations<S = State> = {
  [MutationTypes.SET_LIST_USER](state: S, payload: userInfo[]): void;
  [MutationTypes.SET_LIST_USER_SELECTED](state: S, payload: userInfo[]): void;
  [MutationTypes.SET_AVATAR_USERS](state: S, payload: AvatarUserState): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.SET_LIST_USER](state: State, listUsers: userInfo[]) {
    state.listUsers = listUsers.map((u) => ({
      ...u,
      ...state.avatarUsers[u.id as string],
      photo: u.photo || state.avatarUsers[u.id as string]?.profileFile,
    }));
  },
  [MutationTypes.SET_LIST_USER_SELECTED](
    state: State,
    listUsersSelected: userInfo[]
  ) {
    state.listUsersSelected = listUsersSelected;
  },
  [MutationTypes.SET_AVATAR_USERS](state: State, avatarUsers: AvatarUserState) {
    state.avatarUsers = {
      ...state.avatarUsers,
      ...avatarUsers,
    };
  },
};
