import { InjectionKey } from '@vue/runtime-core';
import { createStore, Store } from 'vuex';
type ToastMessage = {
  id: number;
  message: string;
  timeout?: number;
  type: string;
  isShow?: boolean;
};
export interface State {
  toastMessages: ToastMessage[];
  isAll: boolean;
}

export const key: InjectionKey<Store<State>> = Symbol();

export function useToastMessageStore() {
  return store;
}

export const store = createStore<State>({
  state() {
    return {
      toastMessages: [],
      isAll: false,
    };
  },
  mutations: {
    openToastMessage(state, item: ToastMessage) {
      item = {
        ...{
          isShow: true,
          timeout: 3000,
          id: new Date().getTime() + Math.random(),
        },
        ...item,
      };
      state.toastMessages.push(item);
      setTimeout(() => {
        const index = state.toastMessages.indexOf(item);
        state.toastMessages[index].isShow = false;
      }, item.timeout);

      if (!state.isAll && state.toastMessages.length > 1) {
        state.toastMessages[state.toastMessages.length - 2].isShow = false;
      }
    },

    closeToast(state, item: ToastMessage) {
      const index = state.toastMessages.indexOf(item);
      state.toastMessages[index].isShow = false;
    },
  },
});
export default store;
