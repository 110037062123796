import { useToastMessageStore } from '@/store/modules/toast-message';

type TypeToast = 'success' | 'info' | 'warning' | 'error';
export const showToast = (type: TypeToast, message) => {
  const store = useToastMessageStore();
  return store.commit('openToastMessage', {
    type: type,
    message: message,
  });
};
