
import { defineComponent, ref, watchEffect, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import useApplicationApproveFlowDetail from '@/api/composables/useApplicationApproveFlowDetail';
import { getCookie } from '@/util/composables';
import { ApplicationApprovalHistoryAppliesType } from '@/api/types/applicationApproveFlowDetail';
import { getListAvatarUser } from '@/api/composables/useGetListAvatarUser';
import Avatar from '@/components/share/Avatar/index.vue';
import useApplicationApprovalFlow from '@/api/composables/applicationApprovalFlowStamps';
import ApproveIcon from '@/components/Icons/ApproveIcon.vue';
import PendingIcon from '@/components/Icons/PendingIcon.vue';
import RejectIcon from '@/components/Icons/RejectIcon.vue';
import { usePersonalApplySettingStore } from '@/store/modules/personal-setting-apply';
import { useDetailWorkingStyleMaster } from '@/api/composables/useWorkingStyleMaster';
import {
  APPLICATION_STATUS_TYPE,
  APPLICATION_ALLOWANCE_DELETE,
} from '@/constants/typeApplication';
import { SETTING_REGISTER_FLOW } from '@/constants/workFlowSetting';
import router from '@/router';

export type AvatarUser = {
  id: string;
  fullName: string;
  fullNameKana: string;
  nameFaceImage: string;
  profileFile: string;
};
export default defineComponent({
  components: { Avatar, ApproveIcon, PendingIcon, RejectIcon },
  props: {
    id: {
      type: String,
    },
    status: {
      type: Number,
      default: null,
    },
    createdAt: {
      type: String,
    },
  },
  setup(props) {
    const i18n = useI18n();
    const t = i18n.t;
    const route = useRoute();
    // waiting allow delete
    const isWaitingAllowDelete =
      router.currentRoute.value.query.delete == APPLICATION_ALLOWANCE_DELETE
        ? true
        : false;
    const applicationDefineId = route.query.application_define_id;
    const id = ref(
      isWaitingAllowDelete ? applicationDefineId : route.query.id || props.id
    );
    const unitId = getCookie('unit_id');
    const paramsGetDetail = route.query.application_form_id;
    const STATUS = { PENDING: 0, DONE: 1, REJECT: 2 };
    const employeeInfo = ref({
      pp_employee_id: getCookie('pp_employee_id'),
      name: getCookie('employee_name'),
      unit_name: getCookie('unit_name'),
    });

    const personalApplySetting = usePersonalApplySettingStore();
    const {
      applicationApproveFlowDetail,
      getApplicationApprove,
    } = useApplicationApproveFlowDetail();

    const {
      applicationApprovalData,
      getApplicationApprovalFlowData,
    } = useApplicationApprovalFlow();
    if (paramsGetDetail && !isWaitingAllowDelete) {
      getApplicationApprovalFlowData(paramsGetDetail);
    } else if (id.value) {
      getApplicationApprove({ id: id.value, unitId });
    }

    watchEffect(async () => {
      if (applicationApproveFlowDetail.value?.application_flow_details) {
        listApprove.value = await convertDataApproveFlow();
        await getAvatarEmployee();
      }
      if (applicationApprovalData.value) {
        listApprove.value = await convertDataApplicationFlow();
        await getAvatarEmployee();
      }
    });

    const listIdEmployee = ref<string[]>([]);
    const listAvatarEmployee = ref<AvatarUser[]>([]);
    const getAvatarEmployee = async () => {
      const listAvatar = await getListAvatarUser(listIdEmployee.value);
      listAvatarEmployee.value = listAvatar.data;
    };

    const listApprove = ref<{
      [key: string]: ApplicationApprovalHistoryAppliesType[];
    }>({});

    const checkAvatar = (id) => {
      if (!id) return;
      return listAvatarEmployee.value.filter((item) => item.id === id);
    };

    const convertDataApplicationFlow = () => {
      const data: ApplicationApprovalHistoryAppliesType[] =
        applicationApprovalData.value;
      let dataFormat: {
        [key: string]: ApplicationApprovalHistoryAppliesType[];
      } = {};
      for (let i = 0; i < data.length; i++) {
        if (!dataFormat[data[i].approval_level]) {
          dataFormat[data[i].approval_level] = [data[i]];
        } else {
          dataFormat[data[i].approval_level].push(data[i]);
        }
      }

      listIdEmployee.value = [
        ...getListEmployee(dataFormat),
        employeeInfo.value.pp_employee_id,
      ];
      return dataFormat;
    };

    const convertDataApproveFlow = () => {
      const data: ApplicationApprovalHistoryAppliesType[] =
        applicationApproveFlowDetail.value.application_flow_details;
      let dataFormat: {
        [key: string]: ApplicationApprovalHistoryAppliesType[];
      } = {};
      for (let i = 0; i < data.length; i++) {
        if (!dataFormat[data[i].approval_level]) {
          dataFormat[data[i].approval_level] = [data[i]];
        } else {
          dataFormat[data[i].approval_level].push(data[i]);
        }
      }
      listIdEmployee.value = [
        ...getListEmployee(dataFormat),
        employeeInfo.value.pp_employee_id,
      ];
      return dataFormat;
    };

    const getListEmployee = (data: {
      [key: string]: ApplicationApprovalHistoryAppliesType[];
    }) => {
      const employees: string[] = [];
      if (data !== {}) {
        for (const key in data) {
          if (Object.prototype.hasOwnProperty.call(data, key)) {
            const element = data[key];
            element.map((item) => {
              if (item.application_approval_applies) {
                item.application_approval_applies.map((app) => {
                  if (app.pp_employee_id) {
                    employees.push(app.pp_employee_id);
                  }
                  if (app.role_id && app.employees_one_role?.length) {
                    app.employees_one_role.map((role) => {
                      if (role.pp_employee_id)
                        employees.push(role.pp_employee_id);
                    });
                  }
                });
              } else if (item.application_approval_history_applies) {
                item.application_approval_history_applies.map((app) => {
                  if (app.pp_employee_id) {
                    employees.push(app.pp_employee_id);
                  }
                  if (app.role_id && app.employees_one_role?.length) {
                    app.employees_one_role.map((role) => {
                      if (role.pp_employee_id)
                        employees.push(role.pp_employee_id);
                    });
                  }
                });
              }
            });
          }
        }
      }
      return employees;
    };

    const {
      workingStyleMasterDetail,
      getDetailData,
    } = useDetailWorkingStyleMaster();
    const getWorkingStyleMasterDetail = async () => {
      if (personalApplySetting.state.data?.working_style_id) {
        await getDetailData(personalApplySetting.state.data?.working_style_id);

        const isDistinguishingWorkplace =
          workingStyleMasterDetail.value?.is_distinguishing_workplace;
        await router.replace({
          query: {
            ...router.currentRoute.value.query,
            is_distinguishing_workplace: isDistinguishingWorkplace ? 1 : 0,
          },
        });
      }
    };
    getWorkingStyleMasterDetail();
    const workingStyleName = computed(() => {
      if (workingStyleMasterDetail?.value)
        return workingStyleMasterDetail.value.working_style_name || '';
      return '';
    });

    return {
      t,
      i18n,
      STATUS,
      listApprove,
      employeeInfo,
      workingStyleName,
      isWaitingAllowDelete,
      checkAvatar,
      SETTING_REGISTER_FLOW,
      APPLICATION_STATUS_TYPE,
      APPLICATION_ALLOWANCE_DELETE,
    };
  },
});
