import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "relative inline-block"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (!$props.isBadge)
    ? (_openBlock(), _createBlock("img", {
        key: 0,
        class: $setup.classes,
        style: $setup.styles,
        src: $props.src,
        alt: "",
        srcset: ""
      }, null, 14, ["src"]))
    : (_openBlock(), _createBlock("div", _hoisted_1, [
        (!$props.isBadge)
          ? (_openBlock(), _createBlock("img", {
              key: 0,
              class: $setup.classes,
              src: $props.src,
              alt: "",
              srcset: ""
            }, null, 10, ["src"]))
          : _createCommentVNode("", true),
        _createVNode("span", { class: $setup.classesBadge }, null, 2)
      ]))
}