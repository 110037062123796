
import useSample from '@/api/composables/useSample';
import useSampleRegistration from '@/api/composables/useSampleRegistration';
import { defineComponent, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import Input from '@/components/share/Input/index.vue';
import Button from '@/components/share/Button/index.vue';
import Dropdown from '@/components/share/Dropdown/Dropdown.vue';
import MultiSelect from '@/components/share/Dropdown/MultiSelect.vue';
import Switch from '@/components/share/Switch/index.vue';
import { showToast } from '@/util/useToastMessage';
import Table from '@/components/share/Table/ScrollTable.vue';
import DatePickerInput from '@/components/share/Calendar/DatePickerInput.vue';
import YearInput from '@/components/share/Calendar/YearInput.vue';
import Avatar from '@/components/share/Avatar/index.vue';
import Paging from '@/components/share/Pagination/index.vue';
import InputSearch from '@/components/share/Input/InputSearchEmployee.vue';
import { connectDevice, detectCard } from '@/util/IcCard/index';

export default defineComponent({
  components: {
    YearInput,
    DatePickerInput,
    Dropdown,
    Input,
    Button,
    Switch,
    Table,
    Avatar,
    MultiSelect,
    Paging,
    InputSearch,
  },
  setup() {
    const i18n = useI18n();
    const { sample, loading, updateData } = useSample();
    const pageActive = ref(1);
    const title = ref('');
    const content = ref('');
    const text = ref('');
    const validated = ref(false);
    const selected = ref();
    const selected2 = ref([]);
    const optionSelect = [
      { text: '0', value: 0 },
      { text: '1', value: 1 },
      { text: '2', value: 2 },
      { text: '3', value: 3 },
      { text: '4', value: 5 },
      { text: '5', value: 6 },
      { text: '6', value: 7 },
      { text: '7', value: 8 },
      { text: '8', value: 9 },
      { text: '9', value: 10 },
    ];
    const show = ref(false);
    const switchVal = ref(false);
    const connect = async () => {
      const device = await connectDevice();
      await detectCard(device);
    };
    const optionStampingHeader = [
      {
        text: '休憩開始休憩開始休憩開始休憩開始',
        value: 'check_in',
        width: 120,
      },
      {
        text: i18n.t('stampRegister.leavingWork'),
        value: 'leaving_work',
        width: 80,
      },
      {
        text: i18n.t('stampRegister.breakStart'),
        value: 'break_start',
        width: 120,
      },
      {
        text: i18n.t('stampRegister.breakEnd'),
        value: 'break_end',
        width: 150,
      },
      {
        text: i18n.t('stampRegister.goOut'),
        value: 'go_out',
        width: 120,
      },
      {
        text: i18n.t('stampRegister.goingBack'),
        value: 'going_back',
        width: 120,
      },
      {
        text: i18n.t('stampRegister.workPlace'),
        value: 'work_place',
        width: 120,
      },
      {
        text: i18n.t('stampRegister.attendanceOrganization'),
        value: 'attendance_organization',
        width: 120,
      },
      {
        text: i18n.t('stampRegister.attendanceOrganization'),
        value: 'attendance',
        width: 120,
      },
      {
        text: i18n.t('stampRegister.attendanceOrganization'),
        value: 'organization',
        width: 120,
      },
    ];

    const dataExample = {
      check_in: 'a',
      leaving_work: 'b',
      break_start: 'c',
      break_end: 'd',
      go_out: 'e',
      going_back: 'f',
      work_place: 'g',
      attendance_organization: 'h',
      attendance: 'i',
      organization: 'j',
    };

    const dataTable = ref([
      dataExample,
      dataExample,
      dataExample,
      dataExample,
      dataExample,
    ]);
    const date = ref();
    const month = ref();
    const year = ref();
    const currentPage = ref(1);
    watch(
      () => currentPage.value,
      () => {
        dataTable.value = [
          ...dataTable.value,
          dataExample,
          dataExample,
          dataExample,
          dataExample,
          dataExample,
        ];
      }
    );
    const listEmployee = ref([]);
    return {
      t: i18n.t,
      text,
      validated,
      sample,
      loading,
      title,
      content,
      selected,
      selected2,
      optionSelect,
      switchVal,
      optionStampingHeader,
      dataTable,
      date,
      month,
      year,
      currentPage,
      pageActive,
      listEmployee,
      showToast,
      open,
      onSubmit(e) {
        show.value = !show.value;
        validated.value = true;
        console.log(e);
      },
      addSample() {
        const { registered } = useSampleRegistration({
          content: content.value,
          title: title.value,
        });

        registered
          .then((id) => {
            updateData();
            alert(`登録しました。ID：${id}`);
          })
          .catch(() => {
            alert('登録に失敗しました。');
          });
      },
      connect,
    };
  },
});
