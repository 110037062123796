
import { defineComponent, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import Button from '@/components/Button.vue';
import Table from '@/components/Table.vue';
import DatePicker from '@/components/DatePicker.vue';
import Input from '@/components/Input.vue';
import router from '@/router';
import {
  handleGetListNationalHolidayJa,
  handleCreateNationalHolidays,
  handleGetNationalHolidayDetail,
  handleUpdateNationalHolidays,
} from '@/api/composables/nationalHolidays';
import moment from 'moment';
import {
  NationalHolidayJa,
  NationalHolidayList,
} from '@/model/NationalHolidays';
import { showToast } from '@/util/useToastMessage';
import { isValidateForm } from '@/util/useValidateForm';
import ConstantsRouter from '@/constants/router';
import Label from '@/components/Label.vue';
import YearInput from '@/components/YearInput.vue';
import { ActionType } from '@/util/composables';
import { computed } from '@vue/runtime-core';
export default defineComponent({
  components: {
    Button,
    Table,
    DatePicker,
    Input,
    Label,
    YearInput,
  },
  setup() {
    const i18n = useI18n();
    const onReloadJpList = ref(false);
    const keyEl = ref(0);
    const loadingCreateUpdate = ref(false);
    const holidayType = {
      ITEM_TYPE_JP_HOLIDAY: 0,
      ITEM_TYPE_NATIONAL_HOLIDAY: 1,
    };

    const optionYear = Array.from({ length: 66 }, (_, i) => i + 1960).reverse();
    const datas = ref({
      national_holiday_group_id: '',
      national_holiday_group_code: '',
      national_holiday_group_name: '',
      national_holidays: [{}],
      national_holiday_group_year_apply: moment().year(),
    });
    const {
      nationalHolidaysJaList,
      loadingNationalHolidayJa,
      loadListNationalHolidayJp,
    } = handleGetListNationalHolidayJa(
      datas.value.national_holiday_group_year_apply
    );

    const companyHolidayData = ref<NationalHolidayList[]>([
      {
        national_holiday_date: '',
        national_holiday_name: '',
        national_holiday_type: holidayType.ITEM_TYPE_NATIONAL_HOLIDAY,
      },
    ]);
    const JpHolidayData = ref<NationalHolidayJa[]>();

    const idItem = router.currentRoute.value.params.id || '';
    const actionType = router.currentRoute.value.query.action || '';
    //get detail
    const {
      nationalHolidaysDetail,
      loadingDetailNationalHolidays,
    } = handleGetNationalHolidayDetail(idItem);

    watch(nationalHolidaysDetail, () => {
      if (
        nationalHolidaysDetail.value &&
        idItem &&
        actionType !== ActionType.COPY
      ) {
        datas.value = {
          ...datas.value,
          national_holiday_group_code:
            nationalHolidaysDetail.value.national_holiday_group_code,
          national_holiday_group_name:
            nationalHolidaysDetail.value.national_holiday_group_name,
          national_holiday_group_year_apply:
            nationalHolidaysDetail.value.national_holiday_group_year_apply,
        };
        let cpHolidaysDatas: NationalHolidayList[] = [];
        let jpHolidaysDatas: NationalHolidayJa[] = [];
        nationalHolidaysDetail.value.national_holidays &&
          nationalHolidaysDetail.value.national_holidays.length > 0 &&
          nationalHolidaysDetail.value.national_holidays.map((list) => {
            if (list.national_holiday_type === 0)
              jpHolidaysDatas.push({
                national_holiday_name: list.national_holiday_name,
                national_holiday_date: list.national_holiday_date,
                national_holiday_type: list.national_holiday_type,
              });
            if (list.national_holiday_type === 1)
              cpHolidaysDatas.push({
                national_holiday_name: list.national_holiday_name,
                national_holiday_date: list.national_holiday_date,
                national_holiday_type: list.national_holiday_type,
              });
          });
        companyHolidayData.value = cpHolidaysDatas;
        JpHolidayData.value = jpHolidaysDatas;
        keyEl.value += 1;
      } else if (
        nationalHolidaysDetail.value &&
        idItem &&
        actionType === ActionType.COPY
      ) {
        datas.value = {
          ...datas.value,
          national_holiday_group_code:
            nationalHolidaysDetail.value.national_holiday_group_code,
          national_holiday_group_name:
            nationalHolidaysDetail.value.national_holiday_group_name,
          national_holiday_group_year_apply:
            nationalHolidaysDetail.value.national_holiday_group_year_apply,
        };
        let cpHolidaysDatas: NationalHolidayList[] = [];
        let jpHolidaysDatas: NationalHolidayJa[] = [];
        nationalHolidaysDetail.value.national_holidays &&
          nationalHolidaysDetail.value.national_holidays.length > 0 &&
          nationalHolidaysDetail.value.national_holidays.map((list) => {
            if (list.national_holiday_type === 0)
              jpHolidaysDatas.push({
                national_holiday_name: list.national_holiday_name,
                national_holiday_date: list.national_holiday_date,
                national_holiday_type: list.national_holiday_type,
              });
            if (list.national_holiday_type === 1)
              cpHolidaysDatas.push({
                national_holiday_name: list.national_holiday_name,
                national_holiday_date: list.national_holiday_date,
                national_holiday_type: list.national_holiday_type,
              });
          });
        JpHolidayData.value = jpHolidaysDatas;
        keyEl.value += 1;
      }
    });
    watch(nationalHolidaysJaList, () => {
      if (!idItem || onReloadJpList.value) {
        JpHolidayData.value = nationalHolidaysJaList.value;
      }
    });
    const isDisableAction = computed(() => {
      return actionType === ActionType.COPY;
    });

    const holidayJpHeaders = [
      {
        text: i18n.t('dailyAttendanceHeaders.date'),
        value: 'national_holiday_date',
        width: '25%',
      },
      {
        text: i18n.t('nationalHoliday'),
        value: 'national_holiday_name',
        width: '65%',
      },
      {
        text: '',
        value: 'action',
        width: '10%',
      },
    ];

    const companyHolidayHeader = [
      {
        text: i18n.t('dailyAttendanceHeaders.date'),
        value: 'national_holiday_date',
        width: '25%',
        verticalAlign: 'top',
      },
      {
        text: i18n.t('compHolidaysName'),
        value: 'national_holiday_name',
        width: '65%',
        verticalAlign: 'top',
      },
      {
        text: '',
        value: 'action',
        width: '10%',
      },
    ];

    const removeNationalHoliday = (index) => {
      if (JpHolidayData.value) {
        JpHolidayData.value.splice(index, 1);
      }
    };
    const mapListNationalList = () => {
      let national_holidays: NationalHolidayList[] = [];
      JpHolidayData.value &&
        JpHolidayData.value.map((el) => {
          national_holidays.push({
            national_holiday_name: el.national_holiday_name,
            national_holiday_date: el.national_holiday_date,
            national_holiday_type: holidayType.ITEM_TYPE_JP_HOLIDAY,
          });
        });
      companyHolidayData.value &&
        companyHolidayData.value.map((el) => {
          national_holidays.push({
            national_holiday_name: el.national_holiday_name,
            national_holiday_date: el.national_holiday_date,
            national_holiday_type: holidayType.ITEM_TYPE_NATIONAL_HOLIDAY,
          });
        });
      return {
        national_holidays,
      };
    };
    const errors = ref({});
    const errorsServer = ref({});
    const validated = ref(false);
    const isValid = () => {
      validated.value = true;
      return isValidateForm(errors);
    };
    const handleCreateNational = (params) => {
      const { create } = handleCreateNationalHolidays(params);
      create.then((error) => {
        loadingCreateUpdate.value = false;
        if (error) {
          errorsServer.value = error.errors.value;
          if (error?.errorsCommon.value) {
            showToast('error', error?.errorsCommon.value);
          }
        } else {
          handleBackToTheList();
          showToast('success', i18n.t('companyCalendarCreated'));
        }
      });
    };
    const handleUpdateNational = (id, params) => {
      const { update } = handleUpdateNationalHolidays(id, params);
      update.then((error) => {
        loadingCreateUpdate.value = false;
        if (error) {
          errorsServer.value = error.errors.value;
          if (error?.errorsCommon.value) {
            showToast('error', error?.errorsCommon.value);
          }
        } else {
          handleBackToTheList();
          showToast('success', i18n.t('companyCalendarCreated'));
        }
      });
    };
    const handleRegister = () => {
      if (!isValid()) {
        return;
      }
      const { national_holidays } = mapListNationalList();
      loadingCreateUpdate.value = true;

      datas.value = {
        ...datas.value,
        national_holidays,
      };

      if (idItem && actionType === ActionType.UPDATE) {
        handleUpdateNational(idItem, datas.value);
      } else if (idItem && actionType === ActionType.COPY) {
        datas.value.national_holiday_group_id = idItem.toString();
        handleCreateNational(datas.value);
      } else {
        handleCreateNational(datas.value);
      }
    };
    const handleBackToTheList = () => {
      router.push(ConstantsRouter.Holidays);
    };
    const removeCompHolidays = (index) => {
      if (companyHolidayData.value) {
        companyHolidayData.value.splice(index, 1);
      }
    };
    const addCompHolidays = () => {
      companyHolidayData.value.push({
        national_holiday_date: '',
        national_holiday_name: '',
        national_holiday_type: holidayType.ITEM_TYPE_NATIONAL_HOLIDAY,
      });
    };
    const handleLoadListNationalHolidayJp = () => {
      onReloadJpList.value = true;
      loadListNationalHolidayJp(datas.value.national_holiday_group_year_apply);
    };
    const selectYear = (value) => {
      onReloadJpList.value = true;
      datas.value.national_holiday_group_year_apply = value;
      loadListNationalHolidayJp(value);
    };
    return {
      t: i18n.t,
      holidayJpHeaders,
      companyHolidayHeader,
      companyHolidayData,
      nationalHolidaysDetail,
      JpHolidayData,
      loadingDetailNationalHolidays,
      loadingNationalHolidayJa,
      datas,
      optionYear,
      loadingCreateUpdate,
      errors,
      errorsServer,
      validated,
      keyEl,
      ActionType,
      isDisableAction,
      removeNationalHoliday,
      handleRegister,
      handleBackToTheList,
      removeCompHolidays,
      addCompHolidays,
      handleLoadListNationalHolidayJp,
      selectYear,
    };
  },
});
