import {
  postTimezoneSettings,
  deleteTimezoneSetting,
  updateTimezoneSettings,
  getTimezoneDetail,
} from '@/api/timezoneSettings';
import {
  TimezoneSettingsApiPostData,
  TimezoneSettingsApiUpdateData,
  UseCURDTimezoneSetting,
} from '@/api/types/TimezoneSettings';
import { ref } from 'vue';
import { convertErrorFromServerToJson } from '@/util/useValidateForm';

export default function timezoneSettingsRegister(
  data: TimezoneSettingsApiPostData
) {
  const errors = ref({});
  const errorsCommon = ref<string>();
  return {
    registered: (async () => {
      const response = await postTimezoneSettings(data);
      switch (response.status) {
        case 200:
          return null;
        default:
          if (response.data.errors[0].source) {
            errors.value = convertErrorFromServerToJson(response.data.errors);
          } else {
            errorsCommon.value = response.data.errors[0].title;
          }
          return {
            errors,
            errorsCommon,
          };
      }
    })(),
  };
}

export function deleteTimezoneSettingID(id) {
  return {
    deleteData: (async () => {
      const response = await deleteTimezoneSetting(id);
      switch (response.status) {
        case 200:
          return null;
        default:
          return response.data.errors[0].title;
      }
    })(),
  };
}

export function updateTimezone(
  id: string,
  data: TimezoneSettingsApiUpdateData
) {
  const errors = ref({});
  const errorsCommon = ref<string>();
  return {
    update: (async () => {
      const response = await updateTimezoneSettings(id, data);
      switch (response.status) {
        case 200:
          return null;
        default:
          if (response.data.errors[0].source) {
            errors.value = convertErrorFromServerToJson(response.data.errors);
          } else {
            errorsCommon.value = response.data.errors[0].title;
          }
          return {
            errors,
            errorsCommon,
          };
      }
    })(),
  };
}

export function useTimezoneDetailSetting() {
  const timezoneDetail = ref<TimezoneSettingsApiUpdateData>();
  const loading = ref(false);

  async function getTimezoneDetailSettings(id) {
    try {
      loading.value = true;
      const res = await getTimezoneDetail(id);
      switch (res.status) {
        case 200:
          timezoneDetail.value = res.data.data;
          //res.data.data;
          break;
        default:
          timezoneDetail.value = {};
          break;
      }
    } catch (error) {
      console.error(error);
    } finally {
      loading.value = false;
    }
  }
  return { timezoneDetail, loading, getTimezoneDetailSettings };
}

export function useCURDTimezoneSetting(): UseCURDTimezoneSetting {
  const errorServer = ref({});
  const creating = ref(false);
  const updating = ref(false);
  const deleting = ref(false);

  const onCreateTimezone = async ({ data, onSuccess, onFail }) => {
    try {
      creating.value = true;
      const error = await timezoneSettingsRegister(data).registered;
      creating.value = false;
      if (!error) {
        errorServer.value = {};
        onSuccess();
        return;
      }
      errorServer.value = error.errors.value;
      if (error?.errorsCommon.value) {
        onFail(error);
        return;
      }
      onFail(error);
    } catch (err) {
      creating.value = false;
      onFail(err);
    }
  };

  const onDeleteTimezone = async ({ id, onSuccess, onFail }) => {
    try {
      deleting.value = true;
      const error = await deleteTimezoneSettingID(id).deleteData;
      deleting.value = false;
      if (error) {
        onFail(error);
        return;
      }
      onSuccess();
    } catch (err) {
      onFail(err);
    }
  };

  const onUpdateTimezone = async ({
    data = {} as TimezoneSettingsApiUpdateData,
    onSuccess,
    onFail,
  }) => {
    try {
      updating.value = true;
      const error = await updateTimezone(data.id as string, data).update;
      updating.value = false;
      if (!error) {
        errorServer.value = {};
        onSuccess();
        return;
      }
      errorServer.value = error.errors.value;
      if (error?.errorsCommon.value) {
        onFail(error);
        return;
      }
      onFail(error);
    } catch (err) {
      updating.value = false;
      onFail(err);
    }
  };

  const onClearErrorServer = () => {
    errorServer.value = {};
  };

  return [
    { errorServer, creating, updating, deleting },
    {
      onCreateTimezone,
      onDeleteTimezone,
      onUpdateTimezone,
      onClearErrorServer,
    },
  ];
}
