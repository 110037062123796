
import { defineComponent } from '@vue/runtime-core';

export default defineComponent({
  props: {
    errorMessage: {
      type: String,
      default: '',
    },
    errorServer: {
      type: Array,
      default: () => [],
    },
  },
});
