<template>
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.9497 11.9497C9.21608 14.6834 4.78392 14.6834 2.05025 11.9497C-0.683417 9.21608 -0.683417 4.78392 2.05025 2.05025C4.78392 -0.683418 9.21608 -0.683417 11.9497 2.05025C14.6834 4.78392 14.6834 9.21608 11.9497 11.9497ZM6.99996 6.52863L8.64988 4.87872C8.78005 4.74854 8.99111 4.74854 9.12128 4.87872C9.25146 5.00889 9.25146 5.21995 9.12128 5.35012L7.47137 7.00004L9.12128 8.64995C9.25146 8.78013 9.25146 8.99118 9.12128 9.12136C8.99111 9.25153 8.78005 9.25153 8.64988 9.12136L6.99996 7.47144L5.35005 9.12136C5.21987 9.25153 5.00882 9.25153 4.87864 9.12136C4.74847 8.99118 4.74847 8.78013 4.87864 8.64995L6.52856 7.00004L4.87864 5.35012C4.74847 5.21995 4.74847 5.00889 4.87864 4.87872C5.00882 4.74854 5.21987 4.74854 5.35005 4.87872L6.99996 6.52863ZM11.4783 2.52166C13.9517 4.99498 13.9517 9.00502 11.4783 11.4783C9.00502 13.9517 4.99498 13.9517 2.52166 11.4783C0.0483365 9.00502 0.0483365 4.99498 2.52166 2.52166C4.99498 0.0483363 9.00502 0.0483363 11.4783 2.52166Z"
      fill="#999999"
    />
  </svg>
</template>
