
import { defineComponent, toRefs, watchEffect } from '@vue/runtime-core';
import { useI18n } from 'vue-i18n';
import { ref } from 'vue';
import { isEmpty } from '@/util/useValidateForm';
export default defineComponent({
  props: {
    placeholder: {
      type: String,
    },
    label: {
      type: String,
    },
    modelValue: {
      type: Number || String,
      default: '',
    },
    value: {
      type: String,
    },
    type: {
      type: String,
      default: 'text',
    },
    required: {
      type: Boolean,
      default: false,
    },
    max: {
      type: Number,
    },
    min: {
      type: Number,
    },
    error: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    iconPassword: {
      type: Boolean,
      default: false,
    },
    iconTime: {
      type: Boolean,
      default: false,
    },
    iconSearch: {
      type: Boolean,
      default: false,
    },
    icon: {},
    radius: {},
    classes: {},
    validated: {
      type: Boolean,
      default: false,
    },
    errorServer: {
      type: String,
      default: '',
    },
    leftSearch: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: '',
    },
  },
  emits: ['update:errors', 'onshowPassword', 'update:modelValue'],
  setup(props, { emit }) {
    const { required, modelValue, error } = toRefs(props);
    const i18n = useI18n();
    const t = i18n.t;
    const errorMessage = ref(error.value);
    const checkRequired = () => {
      if (required.value && isEmpty(modelValue.value)) {
        errorMessage.value = t('errors.required');
      } else {
        errorMessage.value = '';
      }
      emit('update:errors', errorMessage.value);
    };
    watchEffect(() => {
      checkRequired();
    });
    const showPassword = () => {
      emit('onshowPassword');
    };
    const firstEnter = ref(false);
    const onChangeInput = (e) => {
      emit('update:modelValue', e.target.value.trim());
      firstEnter.value = true;
    };
    return {
      t,
      errorMessage,
      firstEnter,
      isEmpty,
      checkRequired,
      showPassword,
      onChangeInput,
    };
  },
});
