import {
  postWorkingStyleMaster,
  deleteWorkingStyleMaster,
  updateWorkingStyleMaster,
  getWorkingStyleMasterDetail,
  getWorkingStyleMaster,
} from '@/api/workingStyleMaster';
import {
  WorkingStyleMasterApiPostData,
  WorkingStyleMasterApiUpdateData,
} from '@/api/types/WorkingStyleMaster';
import { ref } from 'vue';
import { convertErrorFromServerToJson } from '@/util/useValidateForm';
import { WorkingStyleMasterGetList } from '@/model/WorkingStyleMaster';

export default function (data: WorkingStyleMasterApiPostData) {
  const errors = ref({});
  const errorsCommon = ref<string>();
  return {
    registered: (async () => {
      const response = await postWorkingStyleMaster(data);
      switch (response.status) {
        case 200:
          return null;
        default:
          if (response.data.errors[0].source) {
            errors.value = convertErrorFromServerToJson(response.data.errors);
          } else {
            errorsCommon.value = response.data.errors[0].title;
          }
          return {
            errors,
            errorsCommon,
          };
      }
    })(),
  };
}

export function deleteWorkingStyleMasterByID(id) {
  return {
    deleteData: (async () => {
      const response = await deleteWorkingStyleMaster(id);
      switch (response.status) {
        case 200:
          return null;
        default:
          return response.data.errors[0].title;
      }
    })(),
  };
}

export function useUpdateWorkingStyleMaster(
  id: string,
  data: WorkingStyleMasterApiUpdateData
) {
  const errors = ref({});
  const errorsCommon = ref<string>();
  const loading = ref(false);
  return {
    update: (async () => {
      loading.value = true;
      const response = await updateWorkingStyleMaster(id, data);
      switch (response.status) {
        case 200:
          loading.value = false;
          return null;
        default:
          if (response.data.errors[0].source) {
            errors.value = convertErrorFromServerToJson(response.data.errors);
          } else {
            errorsCommon.value = response.data.errors[0].title;
          }
          loading.value = false;
          return {
            errors,
            errorsCommon,
          };
      }
    })(),
  };
}

export function useDetailWorkingStyleMaster() {
  const workingStyleMasterDetail = ref<WorkingStyleMasterApiUpdateData>();
  const loading = ref(false);

  async function getDetailData(id) {
    try {
      loading.value = true;
      const res = await getWorkingStyleMasterDetail(id);
      const response = ref();
      switch (res.status) {
        case 200:
          response.value = res.data;
          workingStyleMasterDetail.value = response.value.data;
          return workingStyleMasterDetail.value;
        default:
          workingStyleMasterDetail.value = {};
          return workingStyleMasterDetail.value;
      }
    } catch (error) {
      console.error(error);
      return {};
    } finally {
      loading.value = false;
    }
  }

  return { workingStyleMasterDetail, loading, getDetailData };
}

export function useGetListWorkingStyleMaster(params) {
  const workingStyleMasterData = ref<WorkingStyleMasterGetList[]>();
  const loading = ref(false);
  const pagination = ref({
    current_page: 1,
    per_page: 20,
    total: 0,
  });
  const response = ref();

  async function getWorkingStyleMasterList(params) {
    try {
      loading.value = true;
      const res = await getWorkingStyleMaster(params);

      switch (res.status) {
        case 200:
          workingStyleMasterData.value = res.data.data;
          response.value = res.data;
          pagination.value = response.value.meta.pagination;
          break;
        default:
          workingStyleMasterData.value = [];
          break;
      }
    } catch (error) {
      console.error(error);
    } finally {
      loading.value = false;
    }
  }

  getWorkingStyleMasterList(params);

  return {
    pagination,
    workingStyleMasterData,
    loading,
    getWorkingStyleMasterList,
  };
}
