import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-lg" }
const _hoisted_2 = { class: "flex items-center" }
const _hoisted_3 = { class: "ml-40" }
const _hoisted_4 = { class: "flex justify-center items-center" }
const _hoisted_5 = { class: "flex items-center mt-6" }
const _hoisted_6 = { class: "ml-40 w-1/2" }
const _hoisted_7 = { class: "flex items-center mt-6" }
const _hoisted_8 = { class: "ml-40 w-1/2" }
const _hoisted_9 = { class: "ml-16 text-lg mt-16" }
const _hoisted_10 = { class: "ml-40 mt-4" }
const _hoisted_11 = { class: "flex justify-between items-end" }
const _hoisted_12 = { class: "mt-6" }
const _hoisted_13 = { class: "text-center py-2" }
const _hoisted_14 = { class: "text-center ml-4" }
const _hoisted_15 = { class: "text-center" }
const _hoisted_16 = { class: "tooltiptext" }
const _hoisted_17 = { class: "ml-16 text-lg mt-12" }
const _hoisted_18 = { class: "ml-40 mt-8" }
const _hoisted_19 = { class: "mt-6" }
const _hoisted_20 = { class: "text-center p-1" }
const _hoisted_21 = { class: "text-left p-1" }
const _hoisted_22 = { class: "text-center flex justify-evenly" }
const _hoisted_23 = { class: "tooltiptext" }
const _hoisted_24 = { class: "tooltiptext" }
const _hoisted_25 = { class: "flex justify-center mt-10 pb-10 ml-40" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Label = _resolveComponent("Label")
  const _component_YearInput = _resolveComponent("YearInput")
  const _component_Input = _resolveComponent("Input")
  const _component_Button = _resolveComponent("Button")
  const _component_Table = _resolveComponent("Table")
  const _component_DatePicker = _resolveComponent("DatePicker")

  return (_openBlock(), _createBlock("div", {
    class: "holiday-settings-sign-upcontainer mt-12 mb-12",
    key: _ctx.keyEl
  }, [
    _createVNode("div", _hoisted_1, [
      _createVNode("div", _hoisted_2, [
        _createVNode("div", _hoisted_3, [
          _createVNode(_component_Label, {
            mandatory: "",
            "text-label": _ctx.t('targetYear')
          }, null, 8, ["text-label"]),
          _createVNode("div", _hoisted_4, [
            _createVNode("div", null, [
              _createVNode(_component_YearInput, {
                isShowChevron: "",
                class: "w-50",
                modelValue: _ctx.datas.national_holiday_group_year_apply,
                "onUpdate:modelValue": [
                  _cache[1] || (_cache[1] = ($event: any) => (_ctx.datas.national_holiday_group_year_apply = $event)),
                  _ctx.selectYear
                ],
                validated: _ctx.validated,
                required: "",
                errors: _ctx.errors.national_holiday_group_year_apply,
                "onUpdate:errors": _cache[2] || (_cache[2] = ($event: any) => (_ctx.errors.national_holiday_group_year_apply = $event)),
                "error-server": _ctx.errorsServer.national_holiday_group_year_apply
              }, null, 8, ["modelValue", "onUpdate:modelValue", "validated", "errors", "error-server"])
            ])
          ])
        ])
      ]),
      _createVNode("div", _hoisted_5, [
        _createVNode("div", _hoisted_6, [
          _createVNode(_component_Label, {
            mandatory: "",
            "text-label": _ctx.t('calendarCode')
          }, null, 8, ["text-label"]),
          _createVNode(_component_Input, {
            disabled: _ctx.isDisableAction,
            validated: _ctx.validated,
            required: "",
            errors: _ctx.errors.national_holiday_group_code,
            "onUpdate:errors": _cache[3] || (_cache[3] = ($event: any) => (_ctx.errors.national_holiday_group_code = $event)),
            "error-server": _ctx.errorsServer.national_holiday_group_code,
            modelValue: _ctx.datas.national_holiday_group_code,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (_ctx.datas.national_holiday_group_code = $event))
          }, null, 8, ["disabled", "validated", "errors", "error-server", "modelValue"])
        ])
      ]),
      _createVNode("div", _hoisted_7, [
        _createVNode("div", _hoisted_8, [
          _createVNode(_component_Label, {
            mandatory: "",
            "text-label": _ctx.t('calendarName')
          }, null, 8, ["text-label"]),
          _createVNode(_component_Input, {
            disabled: _ctx.isDisableAction,
            modelValue: _ctx.datas.national_holiday_group_name,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (_ctx.datas.national_holiday_group_name = $event)),
            validated: _ctx.validated,
            required: "",
            errors: _ctx.errors.national_holiday_group_name,
            "onUpdate:errors": _cache[6] || (_cache[6] = ($event: any) => (_ctx.errors.national_holiday_group_name = $event)),
            "error-server": _ctx.errorsServer.national_holiday_group_name
          }, null, 8, ["disabled", "modelValue", "validated", "errors", "error-server"])
        ])
      ])
    ]),
    _createVNode("div", _hoisted_9, _toDisplayString(_ctx.t('incoJapanHolidays')), 1),
    _createVNode("div", _hoisted_10, [
      _createVNode("div", _hoisted_11, [
        _createVNode("div", null, _toDisplayString(_ctx.t('japanHolidays')), 1),
        _createVNode(_component_Button, {
          class: "btn-outline-blue btn-add w-48 h-12",
          onClick: _ctx.handleLoadListNationalHolidayJp
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t('incoJapanHolidays')), 1)
          ]),
          _: 1
        }, 8, ["onClick"])
      ]),
      _createVNode("div", _hoisted_12, [
        _createVNode(_component_Table, {
          data: _ctx.JpHolidayData,
          headers: _ctx.holidayJpHeaders,
          fixedHeader: "",
          class: "max-h-62"
        }, {
          national_holiday_date: _withCtx(({ item }) => [
            _createVNode("div", _hoisted_13, _toDisplayString(item.national_holiday_date), 1)
          ]),
          national_holiday_name: _withCtx(({ item }) => [
            _createVNode("div", _hoisted_14, _toDisplayString(item.national_holiday_name), 1)
          ]),
          action: _withCtx(({ index }) => [
            _createVNode("div", _hoisted_15, [
              _createVNode("span", {
                style: {
                  color: '#FF0000',
                },
                onClick: ($event: any) => (_ctx.removeNationalHoliday(index)),
                class: "cp-icon-minus-circle cursor-pointer text-3xl"
              }, [
                _createVNode("span", _hoisted_16, _toDisplayString(_ctx.t('actionDelete')), 1)
              ], 8, ["onClick"])
            ])
          ]),
          _: 1
        }, 8, ["data", "headers"])
      ])
    ]),
    _createVNode("div", _hoisted_17, _toDisplayString(_ctx.t('setCompanyHolidays')), 1),
    _createVNode("div", _hoisted_18, [
      _createVNode("div", _hoisted_19, [
        _createVNode(_component_Table, {
          data: _ctx.companyHolidayData,
          headers: _ctx.companyHolidayHeader,
          visibleTable: ""
        }, {
          national_holiday_date: _withCtx(({ item }) => [
            _createVNode("div", _hoisted_20, [
              _createVNode(_component_DatePicker, {
                modelValue: item.national_holiday_date,
                "onUpdate:modelValue": ($event: any) => (item.national_holiday_date = $event),
                defaultValue: item.national_holiday_date,
                defaultNull: "",
                width: "150",
                format: "YYYY/MM/DD",
                justifyCenter: "",
                validated: _ctx.validated,
                required: "",
                errors: _ctx.errors.national_holiday_date,
                "onUpdate:errors": _cache[7] || (_cache[7] = ($event: any) => (_ctx.errors.national_holiday_date = $event)),
                "error-server": _ctx.errorsServer.national_holiday_date
              }, null, 8, ["modelValue", "onUpdate:modelValue", "defaultValue", "validated", "errors", "error-server"])
            ])
          ]),
          national_holiday_name: _withCtx(({ item, index }) => [
            _createVNode("div", _hoisted_21, [
              _createVNode(_component_Input, {
                modelValue: item.national_holiday_name,
                "onUpdate:modelValue": ($event: any) => (item.national_holiday_name = $event),
                class: "w-full",
                validated: _ctx.validated,
                required: "",
                errors: _ctx.errors.national_holiday_name,
                "onUpdate:errors": _cache[8] || (_cache[8] = ($event: any) => (_ctx.errors.national_holiday_name = $event)),
                "error-server": 
                  _ctx.errorsServer[
                    'national_holidays.' +
                      (_ctx.JpHolidayData?.length + index) +
                      '.national_holiday_name'
                  ]
                
              }, null, 8, ["modelValue", "onUpdate:modelValue", "validated", "errors", "error-server"])
            ])
          ]),
          action: _withCtx(({ index }) => [
            _createVNode("div", _hoisted_22, [
              _createVNode("span", {
                style: {
                  color: '#FF0000',
                  display: _ctx.companyHolidayData.length === 1 ? 'none' : 'block',
                },
                onClick: ($event: any) => (_ctx.removeCompHolidays(index)),
                class: "cp-icon-minus-circle cursor-pointer text-3xl"
              }, [
                _createVNode("span", _hoisted_23, _toDisplayString(_ctx.t('actionDelete')), 1)
              ], 12, ["onClick"]),
              _createVNode("span", {
                style: {
                  color: '#0711FF',
                  opacity: index === _ctx.companyHolidayData.length - 1 ? 1 : 0,
                  pointerEvents:
                    index === _ctx.companyHolidayData.length - 1 ? 'auto' : 'none',
                },
                onClick: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.addCompHolidays && _ctx.addCompHolidays(...args))),
                class: "cp-icon-plus-circle cursor-pointer text-3xl"
              }, [
                _createVNode("span", _hoisted_24, _toDisplayString(_ctx.t('addAction')), 1)
              ], 4)
            ])
          ]),
          _: 1
        }, 8, ["data", "headers"])
      ])
    ]),
    _createVNode("div", _hoisted_25, [
      _createVNode(_component_Button, {
        onClick: _ctx.handleRegister,
        class: "btn-gradation-light-blue w-50 h-12 mr-10"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t('toRegister')), 1)
        ]),
        _: 1
      }, 8, ["onClick"]),
      _createVNode(_component_Button, {
        onClick: _ctx.handleBackToTheList,
        class: "btn-gradation-secondary w-50 h-12"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t('backToTheList')), 1)
        ]),
        _: 1
      }, 8, ["onClick"])
    ])
  ]))
}