import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-grey-3 pb-4 mr-40 ml-40" }
const _hoisted_2 = { class: "title text-center mb-3 font-bold text-4xl" }
const _hoisted_3 = { class: "content m-auto" }
const _hoisted_4 = { class: "flex justify-between mb-4" }
const _hoisted_5 = { class: "flex" }
const _hoisted_6 = { class: "mb-1 mt-3 mr-3" }
const _hoisted_7 = { class: "flex" }
const _hoisted_8 = { class: "mb-1 mt-3 mr-3" }
const _hoisted_9 = { class: "flex justify-between mb-4" }
const _hoisted_10 = { class: "flex" }
const _hoisted_11 = { class: "mb-1 mt-3 mr-3" }
const _hoisted_12 = { class: "flex" }
const _hoisted_13 = { class: "mb-1 mt-3 mr-3" }
const _hoisted_14 = { class: "mb-1" }
const _hoisted_15 = { class: "text-right text-light-blue cursor-pointer hover:opacity-75 mb-2 font-size-14" }
const _hoisted_16 = { class: "flex items-center" }
const _hoisted_17 = { class: "flex items-center justify-center" }
const _hoisted_18 = { class: "mr-12" }
const _hoisted_19 = { class: "mr-4 font-bold text-4xl" }
const _hoisted_20 = { class: "mr-4 font-bold text-5xl text-grey-67" }
const _hoisted_21 = { class: "font-bold text-4xl" }
const _hoisted_22 = { class: "mb-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Select = _resolveComponent("Select")
  const _component_Avatar = _resolveComponent("Avatar")
  const _component_Table = _resolveComponent("Table")
  const _component_Button = _resolveComponent("Button")
  const _component_Popup = _resolveComponent("Popup")

  return (_openBlock(), _createBlock(_component_Popup, {
    modelValue: _ctx.isShow,
    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (_ctx.isShow = $event)),
    width: "1086px",
    persistent: ""
  }, {
    default: _withCtx(() => [
      _createVNode("div", _hoisted_1, [
        _createVNode("div", _hoisted_2, _toDisplayString(_ctx.t('pleaseSelectTheTargetPerson')), 1),
        _createVNode("div", _hoisted_3, [
          _createVNode("div", _hoisted_4, [
            _createVNode("div", _hoisted_5, [
              _createVNode("span", _hoisted_6, _toDisplayString(_ctx.t('department')), 1),
              _createVNode(_component_Select, {
                "options-select": _ctx.departmentList,
                "item-text": "name",
                "item-value": "id",
                modelValue: _ctx.formValue.department,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.formValue.department = $event))
              }, null, 8, ["options-select", "modelValue"])
            ]),
            _createVNode("div", _hoisted_7, [
              _createVNode("div", _hoisted_8, _toDisplayString(_ctx.t('workingStyle')), 1),
              _createVNode(_component_Select, {
                "options-select": _ctx.workingStyleList,
                "item-text": "name",
                "item-value": "id",
                modelValue: _ctx.formValue.working_style,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.formValue.working_style = $event))
              }, null, 8, ["options-select", "modelValue"])
            ])
          ]),
          _createVNode("div", _hoisted_9, [
            _createVNode("div", _hoisted_10, [
              _createVNode("span", _hoisted_11, _toDisplayString(_ctx.t('position')), 1),
              _createVNode(_component_Select, {
                "options-select": _ctx.positionList,
                "item-text": "name",
                "item-value": "id",
                modelValue: _ctx.formValue.position,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.formValue.position = $event))
              }, null, 8, ["options-select", "modelValue"])
            ]),
            _createVNode("div", _hoisted_12, [
              _createVNode("div", _hoisted_13, _toDisplayString(_ctx.t('attributeOrOccupation')), 1),
              _createVNode(_component_Select, {
                "options-select": _ctx.attributeOrOccupationList,
                "item-text": "name",
                "item-value": "id",
                modelValue: _ctx.formValue.attribute_occupation,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (_ctx.formValue.attribute_occupation = $event))
              }, null, 8, ["options-select", "modelValue"])
            ])
          ]),
          _createVNode("div", _hoisted_14, [
            _createVNode("div", _hoisted_15, _toDisplayString(_ctx.t('copyMemberFromPreMonth')), 1),
            _createVNode(_component_Table, {
              class: "mb-4 h-110 text-center",
              headers: _ctx.membersToSubmittedHeaders,
              data: _ctx.personToSubmittedData,
              "fixed-header": "",
              "show-select": "",
              "selected-item": _ctx.formValue.person_selected,
              "onUpdate:selected-item": _cache[5] || (_cache[5] = ($event: any) => (_ctx.formValue.person_selected = $event))
            }, {
              name: _withCtx(({ item }) => [
                _createVNode("div", _hoisted_16, [
                  _createVNode(_component_Avatar, {
                    width: "40px",
                    height: "40px",
                    src: item.avatar,
                    class: "mr-2"
                  }, null, 8, ["src"]),
                  _createVNode("div", null, _toDisplayString(item.name), 1)
                ])
              ]),
              _: 1
            }, 8, ["headers", "data", "selected-item"])
          ]),
          _createVNode("div", _hoisted_17, [
            _createVNode("div", _hoisted_18, [
              _createVNode("span", _hoisted_19, _toDisplayString(_ctx.t('choosing')), 1),
              _createVNode("span", _hoisted_20, _toDisplayString(_ctx.numberOfPeopleSelected), 1),
              _createVNode("span", _hoisted_21, _toDisplayString(_ctx.t('person')), 1)
            ]),
            _createVNode("div", null, [
              _createVNode("div", _hoisted_22, [
                _createVNode(_component_Button, {
                  class: "btn-gradation-light-blue w-50 h-12 font-bold",
                  onClick: _ctx.onClosePopup
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t('addAction')), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _createVNode("div", null, [
                _createVNode(_component_Button, {
                  class: "btn-gradation-secondary w-50 h-12 font-bold",
                  onClick: _ctx.onClosePopup
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t('buttonCancel')), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ])
            ])
          ])
        ])
      ])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}