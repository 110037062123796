
import { defineComponent, ref, toRefs } from '@vue/runtime-core';
import { useI18n } from 'vue-i18n';
import DatePickerIcon from '@/assets/svg/DatePickerIcon.svg';
import { watch } from 'vue';
import '@/components/share/Calendar/calendar.scss';

export default defineComponent({
  props: {
    placeholder: {
      type: String,
    },
    modelValue: { type: Number, default: null },
    disabled: {
      type: Boolean,
      default: false,
    },
    isShowChevron: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
    },
    isShowCalendar: {
      type: Boolean,
      default: false,
    },
    isClickYearHeader: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const i18n = useI18n();
    const t = i18n.t;
    const { modelValue } = toRefs(props);
    const value = ref(modelValue.value);
    let startYear = ref(
      value.value
        ? value.value - (value.value % 10)
        : new Date().getFullYear() - (new Date().getFullYear() % 10)
    );

    const prevYear = () => {
      startYear.value -= 10;
    };
    const nextYear = () => {
      startYear.value += 10;
    };
    const handleBackYear = () => {
      value.value -= 1;
      emit('update:modelValue', value.value);
    };
    const handleNextYear = () => {
      value.value += 1;
      emit('update:modelValue', value.value);
    };
    const year = new Date().getFullYear();

    const onChangeInput = () => {
      if (!isNaN(Number(value.value))) {
        startYear.value = value.value - (value.value % 10);
      }
      emit('update:modelValue', value.value);
    };
    const onSelectYear = (item) => {
      value.value = item;
      emit('update:modelValue', value.value);
      emit('selectYear');
    };
    watch(props, (newValue) => {
      value.value = newValue.modelValue;
      if (value.value && !isNaN(Number(value.value))) {
        startYear.value = value.value - (value.value % 10);
      }
    });
    const onClickYearHeader = () => {
      emit('onClickYearHeader');
    };

    return {
      t,
      value,
      startYear,
      DatePickerIcon,
      year,
      prevYear,
      nextYear,
      onSelectYear,
      onChangeInput,
      handleBackYear,
      handleNextYear,
      onClickYearHeader,
    };
  },
});
