
import { computed, defineComponent, watchEffect } from 'vue';
import SuccessIcon from '@/assets/svg/toast/success.svg';
import DangerIcon from '@/assets/svg/toast/danger.svg';
import WarningIcon from '@/assets/svg/toast/warning.svg';
import InfoIcon from '@/assets/svg/toast/info.svg';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import CloseNotify from '@/components/Icons/CloseNotify.vue';

export default defineComponent({
  props: {
    icon: String,
    title: String,
    subtitle: String,
    link: String,
  },
  components: {
    CloseNotify,
  },
  setup() {
    const store = useStore();
    const notification = computed(() => store.state.root.notification);
    const i18n = useI18n();
    const t = i18n.t;
    const packages = {
      success: {
        icon: SuccessIcon,
        classes: 'bg-success',
      },
      info: {
        icon: InfoIcon,
        classes: 'bg-info',
      },
      warning: {
        classes: 'bg-warning',
        icon: WarningIcon,
      },
      error: {
        icon: DangerIcon,
        classes: 'bg-error',
      },
    };
    watchEffect(() => {
      if (notification.value.type) {
        setTimeout(() => {
          store.commit('root/SET_NOTIFICATION', {});
        }, 3000);
      }
    });
    return {
      packages,
      notification,
      t,
    };
  },
});
