import { showLoginError } from '@/router';
import axios, { AxiosResponse } from 'axios';
import { useLoadingStore } from '@/store/modules/use-loading';
import { handleCheckAuthen } from '@/util/composables';
export const COMPANY = location.pathname.split('/')[1];
const axiosInstance = axios.create({
  baseURL: `/${COMPANY}/api`,
  validateStatus(status) {
    switch (status) {
      case 401:
        handleCheckAuthen();
        // showLoginError();
        break;
      default:
        break;
    }
    return status <= 500;
  },
});

axiosInstance.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    const useLoading = useLoadingStore();
    useLoading.commit('increaseCountApiWaiting');
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
  function (response) {
    // Do something with response data
    const useLoading = useLoadingStore();
    useLoading.commit('decreaseCountApiWaiting');
    return response;
  },
  function (error) {
    // Do something with response error
    const useLoading = useLoadingStore();
    useLoading.commit('decreaseCountApiWaiting');
    return Promise.reject(error);
  }
);
export default axiosInstance;

export type ApiResponse<TCode extends number, TData> = AxiosResponse<TData> & {
  status: TCode;
};
export function handleErrorResponse(error: unknown): never {
  if (axios.isAxiosError(error)) {
    switch (error.response?.status) {
      case 401:
        showLoginError(error);
        break;
      default:
        break;
    }
  } else if (error instanceof Error) {
    console.log('error :>> ', error);
  }

  // システムエラーかログインの画面に移動するため
  return undefined as never;
}
