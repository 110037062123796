<template>
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.6673 35H15.0007V25C15.0001 24.08 15.7455 23.3338 16.6654 23.3333C16.6661 23.3333 16.6667 23.3333 16.6673 23.3333H23.334C24.2539 23.3328 25.0001 24.0781 25.0006 24.9981C25.0006 24.9987 25.0006 24.9994 25.0006 25V35H21.6673ZM16.6673 20H15.0007C14.0802 20 13.334 19.2538 13.334 18.3333C13.334 17.4128 14.0802 16.6667 15.0007 16.6667H16.6673C17.5878 16.6667 18.334 17.4128 18.334 18.3333C18.334 19.2538 17.5878 20 16.6673 20ZM16.6673 13.3333H15.0007C14.0802 13.3333 13.334 12.5871 13.334 11.6667C13.334 10.7462 14.0802 10 15.0007 10H16.6673C17.5878 10 18.334 10.7462 18.334 11.6667C18.334 12.5871 17.5878 13.3333 16.6673 13.3333ZM25.0006 13.3333H23.334C22.4135 13.3333 21.6673 12.5871 21.6673 11.6667C21.6673 10.7462 22.4135 10 23.334 10H25.0006C25.9211 10 26.6673 10.7462 26.6673 11.6667C26.6673 12.5871 25.9211 13.3333 25.0006 13.3333ZM25.0006 20H23.334C22.4135 20 21.6673 19.2538 21.6673 18.3333C21.6673 17.4128 22.4135 16.6667 23.334 16.6667H25.0006C25.9211 16.6667 26.6673 17.4128 26.6673 18.3333C26.6673 19.2538 25.9211 20 25.0006 20Z"
      fill="#B2B1FF"
    />
    <path
      d="M31.666 3.33337H8.33268C8.33207 3.33337 8.33147 3.33337 8.33075 3.33337C7.41085 3.33389 6.6655 4.08014 6.66602 5.00004V35C6.66602 35.0007 6.66602 35.0013 6.66602 35.002C6.66653 35.9219 7.41278 36.6672 8.33268 36.6667H31.666C31.6666 36.6667 31.6672 36.6667 31.6679 36.6667C32.5878 36.6662 33.3332 35.9199 33.3327 35V5.00004C33.3327 4.99942 33.3327 4.99882 33.3327 4.99811C33.3322 4.07821 32.5859 3.33286 31.666 3.33337ZM14.9993 10H16.666C17.5865 10 18.3327 10.7462 18.3327 11.6667C18.3327 12.5872 17.5865 13.3334 16.666 13.3334H14.9993C14.0788 13.3334 13.3327 12.5872 13.3327 11.6667C13.3327 10.7462 14.0788 10 14.9993 10ZM13.3327 18.3334C13.3327 17.4129 14.0788 16.6667 14.9993 16.6667H16.666C17.5865 16.6667 18.3327 17.4129 18.3327 18.3334C18.3327 19.2539 17.5865 20 16.666 20H14.9993C14.0788 20 13.3327 19.2539 13.3327 18.3334ZM24.9993 25V35H21.666H14.9993V25C14.9988 24.0801 15.7442 23.3339 16.6641 23.3334C16.6648 23.3334 16.6654 23.3334 16.666 23.3334H23.3327C24.2526 23.3329 24.9988 24.0782 24.9993 24.9981C24.9993 24.9988 24.9993 24.9994 24.9993 25ZM24.9993 20H23.3327C22.4122 20 21.666 19.2539 21.666 18.3334C21.666 17.4129 22.4122 16.6667 23.3327 16.6667H24.9993C25.9199 16.6667 26.666 17.4129 26.666 18.3334C26.666 19.2539 25.9199 20 24.9993 20ZM24.9993 13.3334H23.3327C22.4122 13.3334 21.666 12.5872 21.666 11.6667C21.666 10.7462 22.4122 10 23.3327 10H24.9993C25.9199 10 26.666 10.7462 26.666 11.6667C26.666 12.5872 25.9199 13.3334 24.9993 13.3334Z"
      fill="#D8D8FF"
    />
    <path
      d="M35.0007 36.6667H5.00065C4.08018 36.6667 3.33398 35.9205 3.33398 35C3.33398 34.0796 4.08018 33.3334 5.00065 33.3334H35.0007C35.9211 33.3334 36.6673 34.0796 36.6673 35C36.6673 35.9205 35.9211 36.6667 35.0007 36.6667Z"
      fill="#6563FF"
    />
  </svg>
</template>
