import api, { ApiResponse } from '@/clients/api';
import { ErrorCode } from './types/general/ErrorCode';
import {
  JSONApiResponseBody,
  JSONApiResponseErrorBody,
} from './types/general/JSONApiResponse';
import ConstantsAPI from '@/constants/api';

import { AuthorityData, FunctionPolicies } from './types/Authority';

export async function getAuthority(
  params: string
): Promise<
  | ApiResponse<200, JSONApiResponseBody<AuthorityData>>
  | ApiResponse<404, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.get(ConstantsAPI.AuthorityData(params));
}
updateAuthority;
export async function updateAuthority(
  params: FunctionPolicies[]
): Promise<
  | ApiResponse<200, JSONApiResponseBody<AuthorityData>>
  | ApiResponse<404, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.post(ConstantsAPI.UpdateAuthority, {
    function_policies: params,
  });
}
