import { ActionTree } from 'vuex';

import { RootState } from '@/store';

import { State } from './state';
import { RootIndexActionTypes } from './action-types';

export interface Actions {
  [RootIndexActionTypes.SET_LIST_USER](): void;
}

export const actions: ActionTree<State, RootState> & Actions = {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  [RootIndexActionTypes.SET_LIST_USER]() {},
};
