
import { defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import Button from '@/components/AdminButton.vue';
import Input from '@/components/Input.vue';
import {
  useDetailRole,
  usePostRole,
  useUpdateRole,
} from '@/api/composables/useRole';
import { Role } from '@/model/Role';
import { showToast } from '@/util/useToastMessage';
import router from '@/router';
import ConstantsRouter from '@/constants/router';
import { isValidateForm } from '@/util/useValidateForm';
import { watchEffect } from '@vue/runtime-core';

export default defineComponent({
  components: {
    Button,
    Input,
  },
  setup() {
    const i18n = useI18n();
    const t = i18n.t;
    const roleApiPostData = ref<Role>({});
    const errorsServer = ref({});
    const errors = ref({});
    const validated = ref(false);
    const handleBackToTheList = () => {
      router.push({
        path: ConstantsRouter.AminSettingRoleList,
      });
    };
    const isValid = () => {
      validated.value = true;
      return isValidateForm(errors);
    };
    const roleId = router.currentRoute.value.query.id as string;
    const isCopyAction =
      router.currentRoute.value.path === ConstantsRouter.AminSettingRoleCopy;
    const isUpdate = roleId && !isCopyAction;
    const handleSubmitCreateRole = () => {
      const { registered } = usePostRole(roleApiPostData.value);
      registered
        .then((errors) => {
          if (errors) {
            errorsServer.value = errors.errors.value;
            if (errors?.errorsCommon.value) {
              showToast('error', errors?.errorsCommon.value);
            }
          } else {
            showToast('success', t('successMessage.privilegeRoleCreated'));
            router.push(ConstantsRouter.AminSettingRoleList);
          }
        })
        .catch(() => {
          showToast('error', t('errors.serverError'));
        });
    };
    const handleSubmitUpdateRole = () => {
      const { update } = useUpdateRole(roleId, roleApiPostData.value);
      update
        .then((errors) => {
          if (errors) {
            errorsServer.value = errors.errors.value;
            if (errors?.errorsCommon.value) {
              showToast('error', errors?.errorsCommon.value);
            }
          } else {
            showToast('success', t('successMessage.privilegeRoleUpdated'));
            router.push(ConstantsRouter.AminSettingRoleList);
          }
        })
        .catch(() => {
          showToast('error', t('errors.serverError'));
        });
    };
    const handleSubmitCreateOrUpdateRole = () => {
      if (!isValid()) return;
      if (isUpdate) {
        handleSubmitUpdateRole();
        return;
      }
      handleSubmitCreateRole();
    };
    const { roleDetail, getRoleDetailData } = useDetailRole();
    if (roleId) {
      getRoleDetailData(roleId);
    }
    watchEffect(() => {
      if (roleId && roleDetail.value) {
        roleApiPostData.value = { ...roleDetail.value };
      }
    });
    return {
      t,
      roleApiPostData,
      errorsServer,
      errors,
      validated,
      roleDetail,
      getRoleDetailData,
      handleBackToTheList,
      handleSubmitCreateRole,
      handleSubmitCreateOrUpdateRole,
    };
  },
});
