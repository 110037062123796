import { MutationTree } from 'vuex';

import type {
  BackRouterPath,
  CurrentPageType,
  NotificationType,
  ValidateType,
} from '@/types';

import { State } from './state';
import { RootIndexMutationTypes as MutationTypes } from './mutation-types';

export type Mutations<S = State> = {
  [MutationTypes.SET_CURRENT_PAGE](state: S, payload: CurrentPageType): void;
  [MutationTypes.SET_NOTIFICATION](state: S, payload: NotificationType): void;
  [MutationTypes.SET_BACK_ROUTER_PATH](state: S, payload: BackRouterPath): void;
  [MutationTypes.SET_VALIDATE](state: S, payload: ValidateType): void;
  [MutationTypes.SET_VALID](state: S, payload): void;
  [MutationTypes.REST_SET_VALID](state: S): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.SET_CURRENT_PAGE](state: State, curPage: CurrentPageType) {
    state.currentPage = curPage;
  },
  [MutationTypes.SET_NOTIFICATION](
    state: State,
    notification: NotificationType
  ) {
    state.notification = notification;
  },
  [MutationTypes.SET_BACK_ROUTER_PATH](state: State, payload: BackRouterPath) {
    state.backRouterPath = payload;
  },
  [MutationTypes.SET_VALIDATE](state: State, payload: ValidateType) {
    state.validate = payload;
  },
  [MutationTypes.SET_VALID](state: State, payload) {
    state.valid.push(payload);
  },
  [MutationTypes.REST_SET_VALID](state: State) {
    state.valid = [];
  },
};
