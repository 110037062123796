
import { defineComponent, ref } from 'vue';
import Tooltip from '@/components/Tooltip.vue';

export default defineComponent({
  components: {
    Tooltip,
  },
  props: {
    optionsRadio: {
      type: Array,
      default: () => [],
    },
    default: {
      type: Number,
      default: 0,
    },
    itemValue: {
      type: String,
      default: 'value',
    },
    defaultValue: {
      type: String || Number,
    },
    modelValue: {
      type: String || Number || Boolean,
      default: 0,
    },
  },
  setup(props, ctx) {
    const containerRadio = ref<HTMLDivElement>();
    const actionSubmit = (item) => {
      ctx.emit('update:modelValue', item.value);
    };
    return {
      containerRadio,
      actionSubmit,
    };
  },
});
