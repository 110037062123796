
import { defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import Modal from '@/components/share/Modal/index.vue';
import Button from '@/components/share/Button/index.vue';

export default defineComponent({
  components: { Modal, Button },
  setup(props, { emit }) {
    const i18n = useI18n();
    const isShow = ref(false);
    const handleCancel = () => {
      emit('onClose');
    };
    //delete item
    const handleSubmitIcCardNumber = () => {
      emit('onDelete');
    };
    return {
      t: i18n.t,
      isShow,
      handleCancel,
      handleSubmitIcCardNumber,
    };
  },
});
