import api, { ApiResponse } from '@/clients/api';
import {
  JSONApiResponseBody,
  JSONApiResponseErrorBody,
} from '@/api/types/general/JSONApiResponse';
import { LoginParam, LoginGetData } from '@/api/types/Login';
import { ErrorCode } from '@/api/types/general/ErrorCode';
import ConstantsAPI from '@/constants/api';
import { UserInfor } from '@/model/Login';
export async function login(
  params: LoginParam
): Promise<
  | ApiResponse<200, JSONApiResponseBody<LoginGetData>>
  | ApiResponse<404, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.post(ConstantsAPI.Login, params);
}

export async function checkLogin(): Promise<
  | ApiResponse<200, JSONApiResponseBody<UserInfor>>
  | ApiResponse<404, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.get(ConstantsAPI.CheckLogin);
}
