<template>
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M30.5109 20.3691C29.0395 21.6775 27.1233 22.4976 25 22.5001C24.5473 22.5001 24.1056 22.4582 23.6741 22.3867C23.6286 22.3475 23.5755 22.3177 23.5293 22.2791C26.4586 24.7229 28.3334 28.3865 28.3334 32.5001C28.3336 33.4203 27.5878 34.1665 26.6676 34.1667H36.6667C36.667 34.1667 36.6673 34.1667 36.6676 34.1667C37.5878 34.1665 38.3336 33.4203 38.3334 32.5001C38.3334 27.104 35.1216 22.4675 30.5109 20.3691Z"
      fill="#445C8F"
    />
    <path
      d="M15.0001 22.5002C10.3977 22.5002 6.66675 18.7692 6.66675 14.1668C6.66675 9.56446 10.3977 5.8335 15.0001 5.8335C19.6024 5.8335 23.3334 9.56446 23.3334 14.1668C23.328 18.767 19.6002 22.4948 15.0001 22.5002Z"
      fill="#D8D8FF"
    />
    <path
      d="M15.0001 22.5002C10.3977 22.5002 6.66675 18.7692 6.66675 14.1668C6.66675 9.56446 10.3977 5.8335 15.0001 5.8335C19.6024 5.8335 23.3334 9.56446 23.3334 14.1668C23.328 18.767 19.6002 22.4948 15.0001 22.5002Z"
      fill="#BFC4DC"
    />
    <path
      d="M20.5109 20.3691C19.0396 21.6775 17.1234 22.4976 15.0001 22.5001C12.8751 22.5001 10.9573 21.6802 9.48535 20.371C4.8768 22.4702 1.66675 27.1055 1.66675 32.5001V32.501C1.66695 33.4212 2.41321 34.1669 3.33341 34.1667H26.6667H26.6677C27.5879 34.1665 28.3336 33.4203 28.3334 32.5001C28.3334 27.104 25.1216 22.4675 20.5109 20.3691Z"
      fill="#808CBA"
    />
    <path
      d="M25 5.8335C23.1151 5.8335 21.3961 6.48281 20 7.5379C20.4553 7.88203 20.8678 8.27093 21.2434 8.6995C21.3485 8.81831 21.4475 8.9415 21.5457 9.06601C21.7499 9.32755 21.9402 9.59518 22.1124 9.88033C22.2665 10.1318 22.4079 10.3907 22.535 10.6588C22.6058 10.8108 22.6768 10.9601 22.7386 11.1169C22.8997 11.5187 23.0296 11.935 23.1262 12.3654C23.1488 12.4676 23.1642 12.5711 23.183 12.6748C23.2725 13.1598 23.3333 13.655 23.3333 14.1668C23.3327 14.6914 23.2726 15.1997 23.1777 15.6955C23.164 15.769 23.1559 15.8437 23.1403 15.9165C23.0479 16.3358 22.9161 16.7388 22.7622 17.1313C22.7017 17.2891 22.6426 17.4453 22.5729 17.5983C22.4338 17.8977 22.2749 18.1856 22.1022 18.4645C21.668 19.1799 21.1326 19.8163 20.5104 20.3695C21.6621 20.8935 22.7258 21.5726 23.6741 22.3867C24.1055 22.4582 24.5473 22.5002 25 22.5002C29.6001 22.4948 33.3279 18.7669 33.3333 14.1668C33.3333 9.56448 29.6023 5.8335 25 5.8335Z"
      fill="#5F72A9"
    />
  </svg>
</template>
