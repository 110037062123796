import { ref } from 'vue';
import { ViewConfirmShifts } from '@/api/types/ViewConfirmShift';
import { getViewConfirmShift } from '@/api/viewConfirmShift';

export function useGetViewConfirmShift() {
  const viewConfirmShiftList = ref<ViewConfirmShifts[]>([]);
  const loading = ref(false);

  async function getViewConfirmShiftData(params) {
    try {
      loading.value = true;
      const res = await getViewConfirmShift(params);

      switch (res.status) {
        case 200:
          viewConfirmShiftList.value = res.data.data;
          break;
        default:
          viewConfirmShiftList.value = [];
          break;
      }
    } catch (error) {
      console.error(error);
    } finally {
      loading.value = false;
    }
  }

  return { viewConfirmShiftList, loading, getViewConfirmShiftData };
}
