
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';
import Table from '@/components/share/Table/Table.vue';
import BaseIcon from '@/components/Icons/BaseIcon.vue';
import router from '@/router';
import ConstantsRouter from '@/constants/router';
import { usePopupDeleteStore } from '@/store/modules/popup-delete';
import Button from '@/components/share/Button/index.vue';
import {
  useDeleteYearPaidHoliday,
  useGetListYearPaidHoliday,
} from '@/api/composables/useYearPaidHoliday';
import { showToast } from '@/util/useToastMessage';

export default defineComponent({
  components: { Button, BaseIcon, Table },
  setup() {
    const i18n = useI18n();
    const t = i18n.t;
    const yearPaidHolidayHeaders = [
      {
        text: t('vacationHeaders.vacationCode'),
        value: 'vacation_code',
        width: '5%',
      },
      {
        text: t('vacationHeaders.vacationName'),
        value: 'vacation_name',
        width: '15%',
        whitespace: true,
      },
      {
        text: t('vacationHeaders.abbreviation'),
        value: 'abbreviation',
        width: '13%',
        whitespace: true,
      },
      {
        text: t('vacationHeaders.vacationType'),
        value: 'vacation_type',
        width: '10%',
        whitespace: true,
      },
      {
        text: t('vacationHeaders.displayImage'),
        value: 'display_image',
        width: '10%',
      },
      {
        text: t('vacationHeaders.paidType'),
        value: 'paid_type',
        width: '10%',
        whitespace: true,
      },
      {
        text: t('vacationHeaders.additionType'),
        value: 'addition_type',
        width: '10%',
        whitespace: true,
      },
      {
        text: t('vacationHeaders.halfDayUnitAcquisition'),
        value: 'half_day_unit_acquisition',
        width: '10%',
        whitespace: true,
      },
      {
        text: t('vacationHeaders.timeUnitAcquisition'),
        value: 'time_unit_acquisition',
        width: '7%',
        whitespace: true,
      },
      {
        text: t('edit'),
        value: 'edit',
        width: '5%',
      },
      {
        text: t('delete'),
        value: 'delete',
        width: '5%',
      },
    ];
    const yearPaidHolidayData = [
      {
        id: '123',
        vacation_code: 300,
        vacation_name: '代休',
        abbreviation: '代休',
        vacation_type: '代休',
        display_image: '代休',
        paid_type: 'ー',
        addition_type: '減算',
        half_day_unit_acquisition: '許可',
        time_unit_acquisition: '不許可',
      },
    ];
    const registerYearPaidHoliday = () => {
      router.push(`/new${ConstantsRouter.YearPaidHolidayCreate}`);
    };
    const updateYearPaidHoliday = (item) => {
      router.push({
        path: `/new${ConstantsRouter.YearPaidHolidayUpdate}`,
        query: { id: item.id },
      });
    };

    const store = usePopupDeleteStore();
    const deleteYearPaidHoliday = (item) => {
      store.dispatch('show', {
        isShown: true,
        body: `「${item.vacation_name}」${t('toRemove')}`,
        onSubmit: () => submitDeleteYearPaidHoliday(item.id),
      });
    };
    const submitDeleteYearPaidHoliday = async (id) => {
      const { deleteData } = useDeleteYearPaidHoliday(id);
      deleteData
        .then((error) => {
          if (error) {
            showToast('error', 'error');
          } else {
            showToast('success', 'success');
          }
        })
        .catch(() => {
          showToast('error', t('errors.serverError'));
        });
    };
    const {
      getYearPaidHolidayData,
      yearPaidHolidayList,
    } = useGetListYearPaidHoliday();

    return {
      t,
      yearPaidHolidayHeaders,
      yearPaidHolidayData,
      yearPaidHolidayList,
      registerYearPaidHoliday,
      updateYearPaidHoliday,
      submitDeleteYearPaidHoliday,
      deleteYearPaidHoliday,
      getYearPaidHolidayData,
    };
  },
});
