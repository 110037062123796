
import { defineComponent, ref } from '@vue/runtime-core';
import DatePickerIcon from '@/assets/svg/DatePickerIcon.svg';
import iconChevronLeft from '@/assets/svg/iconChevronLeftSingle.svg';
import iconChevronRight from '@/assets/svg/iconChevronRightSingle.svg';
import Calendar from '@/components/Calendar.vue';
import { toRefs, watch, watchEffect } from 'vue';
import moment from 'moment';
import { DATE_FORMAT } from '@/constants/date';
export default defineComponent({
  components: {
    Calendar,
  },
  props: {
    placeholder: {
      type: String,
      default: DATE_FORMAT,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    format: {
      type: String,
      default: DATE_FORMAT,
    },
    width: {
      type: String,
    },
    icon: {},
    modelValue: { type: String, default: '' },
  },
  setup(props, { emit }) {
    const isShowCalendar = ref(false);
    const { modelValue, format } = toRefs(props);
    const calendar = ref<HTMLDivElement>();
    const top = ref(0);
    const left = ref(0);
    const openCalendar = () => {
      if (calendar.value) {
        const bottom = calendar.value.getBoundingClientRect().bottom;
        const right = calendar.value.getBoundingClientRect().right;
        top.value = window.innerHeight - bottom < 400 ? -300 : 0;
        left.value = window.innerWidth - right < 200 ? -140 : 0;
      }
      isShowCalendar.value = true;
    };
    const closeCalendar = () => {
      isShowCalendar.value = false;
    };
    let date = ref(
      modelValue.value ? moment(modelValue.value).format(format.value) : ''
    );
    const onChangeInput = (e) => {
      date.value = e.target.value;
    };
    watch(props, () => {
      date.value = modelValue.value;
    });
    watchEffect(() => {
      emit('update:modelValue', date.value);
    });
    return {
      isShowCalendar,
      DatePickerIcon,
      iconChevronLeft,
      iconChevronRight,
      date,
      top,
      left,
      calendar,
      closeCalendar,
      openCalendar,
      onChangeInput,
    };
  },
});
