
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';
import Table from '@/components/Table.vue';
import Select from '@/components/Select.vue';
import Checkbox from '@/components/Checkbox.vue';
import DatePicker from '@/components/DatePicker.vue';
import Plus from '@/assets/svg/Plus.svg';
import moment from 'moment';
import GradationButton from '@/components/GradationButton.vue';
import { WEEK_DAY } from '@/constants/workingStyleMaster';

export default defineComponent({
  components: {
    Table,
    Select,
    Checkbox,
    DatePicker,
    GradationButton,
  },
  setup() {
    const i18n = useI18n();
    const shiftPatternOptions = [
      { text: 'A）', value: 0 },
      { text: 'B）', value: 1 },
      { text: 'C）', value: 2 },
      { text: 'D）', value: 3 },
      { text: 'F）', value: 4 },
      { text: 'G）', value: 5 },
      { text: 'H', value: 6 },
    ];
    const shiftRegisterDataHeader = [
      {
        text: i18n.t('shiftRegistration.name'),
        value: 'shift_pattern_name',
      },
      {
        text: i18n.t('registerMember.workPattern'),
        value: 'shift_pattern',
      },
      {
        text: i18n.t('shiftRegistration.targetTime'),
        value: 'target_time',
      },
      {
        text: i18n.t('shiftRegistration.9AM'),
        value: '9AM',
        width: '100px',
      },
      {
        text: i18n.t('shiftRegistration.10AM'),
        value: '',
        width: '100px',
      },
      {
        text: i18n.t('shiftRegistration.11AM'),
        value: '10AM',
        width: '100px',
      },
      {
        text: i18n.t('shiftRegistration.12AM'),
        value: '',
        width: '100px',
      },
      {
        text: i18n.t('shiftRegistration.1PM'),
        value: '',
        width: '100px',
      },
      {
        text: i18n.t('shiftRegistration.2PM'),
        value: '',
        width: '100px',
      },
      {
        text: i18n.t('shiftRegistration.3PM'),
        value: '',
        width: '100px',
      },
      {
        text: i18n.t('shiftRegistration.4PM'),
        value: '',
        width: '100px',
      },
      {
        text: i18n.t('shiftRegistration.5PM'),
        value: '',
        width: '100px',
      },
      {
        text: i18n.t('shiftRegistration.6PM'),
        value: '',
        width: '100px',
      },
      {
        text: i18n.t('shiftRegistration.7PM'),
        value: '',
        width: '100px',
      },
      {
        text: i18n.t('shiftRegistration.8PM'),
        value: '',
        width: '100px',
      },
      {
        text: i18n.t('shiftRegistration.9PM'),
        value: '',
        width: '100px',
      },
      {
        text: i18n.t('shiftRegistration.10PM'),
        value: '',
        width: '100px',
      },
      {
        text: i18n.t('shiftRegistration.11PM'),
        value: '',
        width: '100px',
      },
      {
        text: i18n.t('shiftRegistration.12PM'),
        value: '',
        width: '100px',
      },
    ];
    const shiftRegisterData = [
      {
        text: 'A',
        date: '2021-09-01',
        shift_pattern: 0,
        shift_pattern_name: '斉藤 亜沙美',
        shift_pattern_job: 'アルバイト',
        target_start_time: '9:00',
        target_end_time: '11:00',
        start_time: '9:00',
        end_time: '15:00',
        color: '#45D1D1',
      },
      {
        text: 'A',
        date: '2021-09-02',
        shift_pattern: 1,
        shift_pattern_name: '斉藤 亜沙美',
        shift_pattern_job: 'アルバイト',
        target_start_time: '9:00',
        target_end_time: '11:00',
        start_time: '17:00',
        end_time: '23:00',
        color: '#F8BF2D',
      },
      {
        text: 'A',
        date: '2021-09-03',
        shift_pattern: 2,
        shift_pattern_name: '斉藤 亜沙美',
        shift_pattern_job: 'アルバイト',
      },
      {
        text: 'A',
        date: '2021-09-04',
        shift_pattern: 3,
        shift_pattern_name: '斉藤 亜沙美',
        shift_pattern_job: 'アルバイト',
        target_start_time: '9:00',
        target_end_time: '11:00',
        start_time: '14:00',
        end_time: '20:00',
        color: '#007BC3',
      },
      {
        text: 'A',
        date: '2021-09-05',
        shift_pattern: 4,
        shift_pattern_name: '斉藤 亜沙美',
        shift_pattern_job: 'アルバイト',
        target_start_time: '9:00',
        target_end_time: '11:00',
        start_time: '11:00',
        end_time: '17:00',
        color: '#ED5D5D',
      },
      {
        text: 'A',
        date: '2021-09-06',
        shift_pattern_name: '斉藤 亜沙美',
        shift_pattern_job: 'アルバイト',
        shift_pattern: 5,
        start_time: '11:00',
        target_start_time: '12:00',
      },
      {
        text: 'A',
        date: '2021-09-07',
        shift_pattern_name: '斉藤 亜沙美',
        shift_pattern_job: 'アルバイト',
        shift_pattern: 5,
        start_time: '11:00',
      },
      {
        text: 'A',
        date: '2021-09-07',
        shift_pattern_name: '斉藤 亜沙美',
        shift_pattern_job: 'アルバイト',
        shift_pattern: 5,
        start_time: '11:00',
      },
      {
        text: 'A',
        date: '2021-09-07',
        shift_pattern_name: '斉藤 亜沙美',
        shift_pattern_job: 'アルバイト',
        shift_pattern: 5,
        start_time: '11:00',
      },
      {
        text: 'A',
        date: '2021-09-07',
        shift_pattern_name: '斉藤 亜沙美',
        shift_pattern_job: 'アルバイト',
        shift_pattern: 5,
        start_time: '11:00',
      },
      {
        text: 'A',
        date: '2021-09-05',
        shift_pattern: 4,
        shift_pattern_name: '斉藤 亜沙美',
        shift_pattern_job: 'アルバイト',
        target_start_time: '9:00',
        target_end_time: '11:00',
        start_time: '11:00',
        end_time: '17:00',
        color: '#007BC3',
      },
      {
        text: 'A',
        date: '2021-09-07',
        shift_pattern_name: '斉藤 亜沙美',
        shift_pattern_job: 'アルバイト',
        shift_pattern: 5,
        start_time: '11:00',
      },
      {
        text: 'A',
        date: '2021-09-07',
        shift_pattern_name: '斉藤 亜沙美',
        shift_pattern_job: 'アルバイト',
        shift_pattern: 5,
        start_time: '11:00',
      },
      {
        text: 'A',
        date: '2021-09-07',
        shift_pattern_name: '斉藤 亜沙美',
        shift_pattern_job: 'アルバイト',
        shift_pattern: 5,
        start_time: '11:00',
      },
      {
        text: 'A',
        date: '2021-09-07',
        shift_pattern_name: '斉藤 亜沙美',
        shift_pattern_job: 'アルバイト',
        shift_pattern: 5,
        start_time: '11:00',
        color: '#007BC3',
      },
      {
        text: 'A',
        date: '2021-09-05',
        shift_pattern: 4,
        shift_pattern_name: '斉藤 亜沙美',
        shift_pattern_job: 'アルバイト',
        target_start_time: '7:00',
        target_end_time: '15:00',
        start_time: '11:00',
        end_time: '17:00',
        color: '#45D1D1',
      },
    ];
    const styleTimeLine = (item) => {
      if (item.target_start_time) {
        const minTime = moment(item.target_start_time, 'HH:mm:ss');
        const startTime = moment(item.start_time, 'HH:mm:ss');
        let endTime = moment(item.end_time, 'HH:mm:ss');
        const minutes = endTime.diff(startTime, 'minutes');
        const startMinutes = startTime.diff(minTime, 'minutes');
        return {
          width: (minutes / 60) * 100 + 'px',
          left: (startMinutes / 60) * 100 + 'px',
          background: item.color,
        };
      }
    };
    const displayDate = (date) => {
      if (date) {
        return moment(date).format('MM / DD (dd)');
      }
      return '';
    };
    const getColorDate = (date) => {
      if (!date) {
        return '';
      }
      const day = moment(date).day();
      if (day === WEEK_DAY.SATURDAY) {
        return '#22AEE5';
      }
      if (day === WEEK_DAY.SUNDAY) {
        return '#ED5D5D';
      }
      return '#333333';
    };
    const displayTargetTime = (item) => {
      if (item.target_start_time && item.target_end_time) {
        return item.target_start_time + ' ~ ' + item.target_end_time;
      }
    };
    const onChoseColorRanDom = () => {
      const r = () => Math.floor(256 * Math.random());
      const colorCache = `rgb(${r()}, ${r()}, ${r()})`;
      return colorCache;
    };
    return {
      t: i18n.t,
      shiftRegisterData,
      shiftRegisterDataHeader,
      shiftPatternOptions,
      Plus,
      styleTimeLine,
      displayTargetTime,
      displayDate,
      getColorDate,
      onChoseColorRanDom,
    };
  },
});
