import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "title font-size-24 mb-8 flex items-center" }
const _hoisted_2 = { class: "mb-8" }
const _hoisted_3 = { class: "font-size-14 font-bold mb-1" }
const _hoisted_4 = { class: "flex justify-between" }
const _hoisted_5 = { class: "text-grey-666" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserIcon = _resolveComponent("UserIcon")
  const _component_InputSearchEmployee = _resolveComponent("InputSearchEmployee")
  const _component_Button = _resolveComponent("Button")
  const _component_Popup = _resolveComponent("Popup")

  return (_openBlock(), _createBlock(_component_Popup, {
    modelValue: _ctx.isShow,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.isShow = $event)),
    classes: "cus-popup",
    width: "720px"
  }, {
    default: _withCtx(() => [
      _createVNode("div", _hoisted_1, [
        _createVNode(_component_UserIcon, { class: "mr-4" }),
        _createTextVNode(" " + _toDisplayString(_ctx.t('addOtherMembers')), 1)
      ]),
      _createVNode("div", _hoisted_2, [
        _createVNode("div", _hoisted_3, _toDisplayString(_ctx.t('targetPerson')), 1),
        _createVNode(_component_InputSearchEmployee, {
          placeholder: _ctx.t('pleaseEnterYourName'),
          "item-url": "photo",
          "item-text": "name",
          "item-value": "id",
          options: _ctx.employeesOptions,
          modelValue: _ctx.employeeSelectedIds,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.employeeSelectedIds = $event))
        }, null, 8, ["placeholder", "options", "modelValue"])
      ]),
      _createVNode("div", _hoisted_4, [
        _createVNode("div", _hoisted_5, _toDisplayString(_ctx.employeeSelectedIds.length) + _toDisplayString(_ctx.t('theNameHasBeenSelected')), 1),
        _createVNode("div", null, [
          _createVNode(_component_Button, {
            onClick: _ctx.addTargetPerson,
            disabled: _ctx.isDisableBtnAdd
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('addAction')), 1)
            ]),
            _: 1
          }, 8, ["onClick", "disabled"])
        ])
      ])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}