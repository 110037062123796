import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Tab = _resolveComponent("Tab")
  const _component_router_view = _resolveComponent("router-view")

  return (_openBlock(), _createBlock("div", null, [
    _createVNode(_component_Tab, { "data-tabs": _ctx.dataTabs }, null, 8, ["data-tabs"]),
    _createVNode(_component_router_view)
  ]))
}