
import { defineComponent, ref, reactive } from 'vue';
import { useI18n } from 'vue-i18n';
import Input from '@/components/Input.vue';
import GradationButton from '@/components/GradationButton.vue';
import useLogin from '@/api/composables/login';
import ConstantsRouter from '@/constants/router';
import router from '@/router';
import ToastApp from '@/components/share/Toast/index.vue';
import { SUB_DOMAIN, redirLoginCydas } from '@/util/composables';
import { COMPANY } from '@/clients/api';
export default defineComponent({
  components: {
    Input,
    GradationButton,
    ToastApp,
  },
  setup() {
    const showLogin = ref(false);
    if (!SUB_DOMAIN) {
      // localhost env
      showLogin.value = true;
    } else {
      //product env
      const hrefEncd = `${window.location.origin}/${COMPANY}/stamp`;
      redirLoginCydas(window.btoa(hrefEncd));
    }
    const error = ref({
      messageId: 'error',
    });
    const i18n = useI18n();
    const t = i18n.t;
    const requesting = ref<boolean>(false);
    const id = ref('');
    const passwordLogin = reactive({
      password: '',
      passwordFieldType: 'password',
    });
    const isSubmit = ref(false);
    const loginFailState = ref(false);
    const loginFail = () => {
      loginFailState.value = true;
    };
    const { loading, _login } = useLogin();
    const login = () => {
      isSubmit.value = true;
      loginFailState.value = false;
      if (id.value && passwordLogin.password) {
        _login(
          { username: id.value, password: passwordLogin.password },
          {
            errors: t('errors.serverError'),
            success: t('loginPage.loginSuccess'),
          },
          loginFail
        );
      }
    };
    const showPasswordLogin = () => {
      passwordLogin.passwordFieldType =
        passwordLogin.passwordFieldType === 'password' ? 'text' : 'password';
    };
    const holdLoginId = ref(true);
    const forgotPassword = () => {
      router.push(ConstantsRouter.AuthForgotPassword);
    };
    return {
      id,
      passwordLogin,
      requesting,
      error,
      loading,
      isSubmit,
      holdLoginId,
      ConstantsRouter,
      loginFailState,
      showLogin,
      login,
      showPasswordLogin,
      t: useI18n().t,
      forgotPassword,
    };
  },
});
