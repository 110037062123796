
import { defineComponent, ref, toRefs, watchEffect } from '@vue/runtime-core';
import { useI18n } from 'vue-i18n';
import DatePickerIcon from '@/assets/svg/DatePickerIcon.svg';
import { watch } from 'vue';
import { isEmpty } from '@/util/useValidateForm';
export default defineComponent({
  props: {
    placeholder: {
      type: String,
    },
    modelValue: { type: Number, default: null },
    disabled: {
      type: Boolean,
      default: false,
    },
    isShowChevron: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
    },
    icon: {},
    validated: {
      type: Boolean,
      default: false,
    },
    errorServer: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const i18n = useI18n();
    const t = i18n.t;
    const { required, modelValue } = toRefs(props);
    const isShowCalendar = ref(false);
    const value = ref(modelValue.value);
    let startYear = ref(
      value.value
        ? value.value - (value.value % 10)
        : new Date().getFullYear() - (new Date().getFullYear() % 10)
    );

    const openCalendar = () => {
      isShowCalendar.value = true;
    };
    const closeCalendar = () => {
      isShowCalendar.value = false;
    };
    const onSelectYear = (item) => {
      value.value = item;
      emit('update:modelValue', value.value);
      isShowCalendar.value = false;
    };
    const prevYear = () => {
      startYear.value -= 10;
    };
    const nextYear = () => {
      startYear.value += 10;
    };

    const firstEnter = ref(false);
    const onChangeInput = (e) => {
      firstEnter.value = true;
      value.value = e.target.value;
      if (!isNaN(Number(value.value))) {
        startYear.value = value.value - (value.value % 10);
      }
      emit('update:modelValue', value.value);
    };
    const errorMessage = ref('');
    const checkRequired = () => {
      if (required.value && isEmpty(modelValue.value)) {
        errorMessage.value = t('errors.required');
      } else {
        errorMessage.value = '';
      }
      emit('update:errors', errorMessage.value);
    };
    watch(props, (newValue) => {
      value.value = newValue.modelValue;
      if (value.value && !isNaN(Number(value.value))) {
        startYear.value = value.value - (value.value % 10);
      }
    });
    watchEffect(() => {
      checkRequired();
    });
    const handleBackYear = () => {
      value.value -= 1;
      emit('update:modelValue', value.value);
    };
    const handleNextYear = () => {
      value.value += 1;
      emit('update:modelValue', value.value);
    };
    return {
      t: i18n.t,
      isShowCalendar,
      value,
      startYear,
      DatePickerIcon,
      errorMessage,
      firstEnter,
      prevYear,
      nextYear,
      closeCalendar,
      openCalendar,
      onSelectYear,
      onChangeInput,
      handleBackYear,
      handleNextYear,
    };
  },
});
