import { ref } from 'vue';
import { postCheckinTimeKeepingGoOutStart } from '@/api/timeKeepingGoOutStart';

import { CheckinTimeKeepingGoOutStartParams } from '@/api/types/TimeKeepingGoOutStart';

import { convertErrorFromServerToJson } from '@/util/useValidateForm';

export function usePostCheckinTimeKeepingGoOutStart(
  params: CheckinTimeKeepingGoOutStartParams
) {
  const errors = ref({});
  const errorsCommon = ref<string>();
  return {
    postData: (async () => {
      const response = await postCheckinTimeKeepingGoOutStart(params);
      switch (response.status) {
        case 200:
          return null;
        default:
          if (response.data.errors[0].source) {
            errors.value = convertErrorFromServerToJson(response.data.errors);
          } else {
            errorsCommon.value = response.data.errors[0].title;
          }
          return {
            errors,
            errorsCommon,
          };
      }
    })(),
  };
}
