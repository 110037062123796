import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "loader"
}
const _hoisted_2 = {
  key: 3,
  class: "whitespace-no-wrap"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("button", {
    id: $props.isNav ? $setup.id : '',
    type: "button",
    class: [$setup.classes, "hover:opacity-75"],
    disabled: $props.loading || $props.disabled,
    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($setup.onClick && $setup.onClick(...args))),
    onMousedown: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => ($setup.onMouseDown && $setup.onMouseDown(...args))),
    style: $setup.style
  }, [
    ($props.loading)
      ? (_openBlock(), _createBlock("div", _hoisted_1))
      : _createCommentVNode("", true),
    ($setup.image.src && !$props.loading)
      ? (_openBlock(), _createBlock("img", {
          key: 1,
          style: $setup.styleImage,
          src: `${$setup.image.src}`,
          alt: `${$setup.image.alt}`,
          srcset: ""
        }, null, 12, ["src", "alt"]))
      : _createCommentVNode("", true),
    (!$props.loading)
      ? _renderSlot(_ctx.$slots, "default", { key: 2 })
      : _createCommentVNode("", true),
    (!$props.loading)
      ? (_openBlock(), _createBlock("span", _hoisted_2, _toDisplayString($props.label), 1))
      : _createCommentVNode("", true)
  ], 46, ["id", "disabled"]))
}