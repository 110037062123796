import { SampleFoundation } from '@/model/SampleFoundation';
import { exhaustiveCheck } from '@/util/typescript-utility';
import { postSample } from '../sample';

export default function (sampleFoundation: SampleFoundation) {
  return {
    registered: (async () => {
      const response = await postSample(sampleFoundation);

      switch (response.status) {
        case 200:
          return response.data.data.sample_id;
        case 400:
          break;
        default: {
          exhaustiveCheck(response);
        }
      }
    })(),
  };
}
