import { InjectionKey } from '@vue/runtime-core';
import { createStore, Store } from 'vuex';
import { ApplicationFlowDetails } from '@/model/PersonApplicationFlow';

export interface State {
  applicationDetailFlow: {
    unit_code?: string | number;
    unit_id?: string | number;
    unit_name?: string | number;
    unit_update?: string | number;
    application_flows: ApplicationFlowDetails[];
  };
}

export const key: InjectionKey<Store<State>> = Symbol();

export function useApplicationDetailFlow() {
  return store;
}

export const store = createStore<State>({
  state() {
    return {
      applicationDetailFlow: {
        unit_code: '',
        unit_id: '',
        unit_name: '',
        unit_update: '',
        application_flows: [],
      },
    };
  },
  mutations: {
    setLoginAccount(state, applicationFlow) {
      state.applicationDetailFlow = applicationFlow;
    },
  },
  actions: {},
});
export default store;
