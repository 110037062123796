
import { computed, defineComponent, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import Button from '@/components/share/Button/index.vue';
import InputSearchEmployee from '@/components/share/Input/InputSearchEmployee.vue';
import MultiSelect from '@/components/share/Dropdown/MultiSelect.vue';
import useUnitApi from '@/api/composables/useUnit';
import Checkbox from '@/components/share/Checkbox/CheckboxInput.vue';
import Table from '@/components/share/Table/Table.vue';
import PlusIcon from '@/components/Icons/PlusIcon.vue';
import UploadSimpleIcon from '@/components/Icons/UploadSimpleIcon.vue';
import DownloadSimpleIcon from '@/components/Icons/DownloadSimpleIcon.vue';
import PopupRegisterCard from '@/views/New/RegisterIcCard/PopupRegisterCard.vue';
import PopupDeleteRegister from '@/views/New/RegisterIcCard/PopupDeleteRegister.vue';
import {
  useDeleteIcCard,
  useDataIcCard,
} from '@/api/composables/registerIcCard';
import { ParamsGetIcCard } from '@/model/RegisterIcCard';
import { showToast } from '@/util/useToastMessage';
import ConstantsRouter from '@/constants/router';
import router from '@/router';
import { getSearchEmploy } from '@/api/composables/useGetSearchEmployee';
import ApplicationSearchArea from '@/components/Application/ApplicationSearchArea.vue';

export default defineComponent({
  components: {
    Button,
    InputSearchEmployee,
    MultiSelect,
    Checkbox,
    Table,
    PlusIcon,
    UploadSimpleIcon,
    DownloadSimpleIcon,
    PopupRegisterCard,
    PopupDeleteRegister,
    ApplicationSearchArea,
  },
  setup: function () {
    const i18n = useI18n();
    const t = i18n.t;
    const isAllParams = ref(true);
    const icCardNumbers = ref<string[]>([]);
    const showSearchDetail = ref(false);
    const isShowPopupRegister = ref(false);
    const isShowPopupDeleteRegister = ref(false);
    const numberCardRegisterItem = ref();
    const paramsSearchRegister = ref<ParamsGetIcCard>({
      pp_employee_ids: [],
      unit_ids: [],
      ic_card: '1',
    });
    const registerHeader = [
      {
        text: i18n.t('applicationApprovalContent.approveAndReject'),
        value: 'pp_employee_code',
        width: '100px',
      },
      {
        text: i18n.t('name'),
        value: 'pp_employee_name',
        width: '120px',
      },
      {
        text: i18n.t('affiliationOrganization'),
        value: 'unit_name',
        width: '120px',
      },
      {
        text: i18n.t('workingStyle'),
        value: 'working_style_name',
        width: '120px',
      },
      {
        text: i18n.t('icCardNumber'),
        value: 'ic_cards',
        width: '260px',
      },
    ];
    const isHandInputCardNumber = ref(false);
    const infoEmployee = ref({});
    const handleSubmitIcCardNumber = async (cardNumber) => {
      await icCardNumbers.value.push(cardNumber);
      isShowPopupRegister.value = false;
      await getDataIcCard();
    };

    const handleSearchMembers = () => {
      router.push({
        path: `/new${ConstantsRouter.RegisterIcCard}`,
        query: {
          pp_employee_ids: paramsSearchRegister.value.pp_employee_ids as [],
          unit_ids: paramsSearchRegister.value.unit_ids as [],
          ic_card: paramsSearchRegister.value.ic_card,
        },
      });
      if (
        JSON.stringify({
          ...paramsSearchRegister.value,
          ...{ ic_card: paramsSearchRegister.value.ic_card?.toString() },
        }) === JSON.stringify(router.currentRoute.value.query)
      ) {
        getDataIcCard();
      }
    };
    const resetCount = ref(0);

    const resetSearchInput = () => {
      paramsSearchRegister.value = {
        unit_ids: [],
        pp_employee_ids: [],
      };
      resetCount.value++;
      router.push({
        path: `/new${ConstantsRouter.RegisterIcCard}`,
        query: {
          unit_ids: [],
        },
      });
    };
    const closeSearchDetail = () => {
      paramsSearchRegister.value = {
        unit_ids: router.currentRoute.value.query.unit_ids
          ? ([...router.currentRoute.value.query.unit_ids] as string[])
          : [],
        pp_employee_ids: router.currentRoute.value.query.pp_employee_ids
          ? ([...router.currentRoute.value.query.pp_employee_ids] as string[])
          : [],
      };
      resetCount.value++;
      showSearchDetail.value = false;
    };
    watch(
      () => router.currentRoute.value.query,
      () => {
        getDataIcCard();
      }
    );
    const { unitList } = useUnitApi({
      is_all: isAllParams.value,
    });
    const unitNames = computed(() =>
      unitList.value
        .filter((item) =>
          paramsSearchRegister.value.unit_ids.includes(item.unit_id)
        )
        .map((item) => item.unit_name)
    );
    const optionListEmployee = ref<{ value: string; text: string }[]>([]);
    getSearchEmploy().then((res) => {
      optionListEmployee.value = res.data.map((el) => {
        return {
          value: el.id,
          text: el.name,
          url: el.photo,
          name_face_image: el.name_face_image,
        };
      });
    });
    const getNameEmployee = (id) => {
      return (
        optionListEmployee.value.filter((item) => item.value == id)[0]?.text ||
        ''
      );
    };
    const heightRequestSearch = computed(() => {
      if (searchRequest.value) {
        return searchRequest.value.getBoundingClientRect().height;
      }
      return 0;
    });
    const { membersIcCard, getAllDataIcCard } = useDataIcCard();
    const getDataIcCard = async () => {
      await getAllDataIcCard(paramsSearchRegister.value);
    };
    getDataIcCard();
    const searchRequest = ref<HTMLDivElement>();
    const showSearchRequired = () => {
      showSearchDetail.value = !showSearchDetail.value;
    };
    const openPopupDeleteRegister = (cardId) => {
      numberCardRegisterItem.value = cardId;
      isShowPopupDeleteRegister.value = true;
    };
    const closePopupDeleteRegister = () => {
      isShowPopupDeleteRegister.value = false;
    };
    const openPopupRegister = (isHandleNumberCard, item) => {
      isHandInputCardNumber.value = isHandleNumberCard;
      infoEmployee.value = item;
      isShowPopupRegister.value = true;
    };
    const closePopupRegister = async () => {
      isShowPopupRegister.value = false;
    };
    const errorsServer = ref({});
    const deleteIcCardNumber = async () => {
      const { deleted } = useDeleteIcCard(numberCardRegisterItem.value);
      await deleted
        .then((error) => {
          if (error) {
            errorsServer.value = error.errors.value;
            if (error?.errorsCommon.value) {
              showToast('error', error?.errorsCommon.value);
            }
          } else {
            showToast('success', t('deleteIcCard'));
            getDataIcCard();
          }
        })
        .catch(() => {
          showToast('error', t('errors.serverError'));
        });
      isShowPopupDeleteRegister.value = false;
    };
    return {
      t,
      i18n,
      registerHeader,
      heightRequestSearch,
      searchRequest,
      paramsSearchRegister,
      showSearchRequired,
      closeSearchDetail,
      showSearchDetail,
      unitList,
      isShowPopupRegister,
      openPopupRegister,
      closePopupRegister,
      icCardNumbers,
      handleSubmitIcCardNumber,
      isShowPopupDeleteRegister,
      openPopupDeleteRegister,
      closePopupDeleteRegister,
      deleteIcCardNumber,
      numberCardRegisterItem,
      isHandInputCardNumber,
      membersIcCard,
      infoEmployee,
      handleSearchMembers,
      unitNames,
      getNameEmployee,
      resetSearchInput,
    };
  },
});
