
import { computed, defineComponent, ref, watch, watchEffect } from 'vue';
import { useI18n } from 'vue-i18n';
import router from '@/router';
import ConstantsRouter from '@/constants/router';
import DatePicker from '@/components/share/Calendar/DatePickerInput.vue';
import MultiSelect from '@/components/share/Dropdown/MultiSelect.vue';
import useUnitApi from '@/api/composables/useUnit';
import Table from '@/components/share/Table/Table.vue';
import Button from '@/components/share/Button/index.vue';
import CloseIcon from '@/components/Icons/CloseIcon.vue';
import ArrowDownBlueIcon from '@/components/Icons/ArrowDownBlueIcon.vue';
import { AllMemberCheckTimeParams } from '@/model/AllMemberCheckTime';
import useAllMemberCheckTime from '@/api/composables/useAllMemberCheckTime';
import { usePersonalApplySettingStore } from '@/store/modules/personal-setting-apply';
import { useDetailWorkingStyleMaster } from '@/api/composables/useWorkingStyleMaster';
import BackPreviousPage from '@/components/share/BackPreviousPage/index.vue';
import InputSearchEmployee from '@/components/share/Input/InputSearchEmployee.vue';
import { getSearchEmploy } from '@/api/composables/useGetSearchEmployee';
import { getCookie } from '@/util/composables';

export default defineComponent({
  components: {
    DatePicker,
    MultiSelect,
    Table,
    Button,
    CloseIcon,
    ArrowDownBlueIcon,
    BackPreviousPage,
    InputSearchEmployee,
  },
  setup() {
    const i18n = useI18n();
    const t = i18n.t;
    const showSearchDetail = ref(false);
    const MAX_UNIT_SHOW = 3;
    const unitId = getCookie('unit_id');
    const returnScreen = () => {
      router.push(`/new${ConstantsRouter.Stamp}`);
    };
    const personalApplySetting = usePersonalApplySettingStore();
    const {
      workingStyleMasterDetail,
      getDetailData,
    } = useDetailWorkingStyleMaster();
    const getParamArray = (params) => {
      if (!params || params == 'undefined') return [];
      if (typeof params === 'string') return [params];
      return params;
    };
    const TYPE_TRUE = 0;
    const TYPE_FALSE = 1;
    const date = new Date();
    const m = String(date.getMonth() + 1).padStart(2, '0');
    const d = String(date.getDate()).padStart(2, '0');
    const y = String(date.getFullYear());
    const currentDate =
      typeof router.currentRoute.value.query?.date_check === 'string'
        ? router.currentRoute.value.query?.date_check
        : [y, m, d].join('/');
    const unitIdInit = ref<string[]>([]);
    const employeeId = ref<string[]>([]);

    const setParamsSearchFromRouter = () => {
      unitIdInit.value = getParamArray(
        router.currentRoute.value.query.unit_ids
      );
      employeeId.value = getParamArray(
        router.currentRoute.value.query.pp_employee_ids
      );
    };
    setParamsSearchFromRouter();
    const resetCount = ref(0);
    // set search default
    const paramsSearchMembers = ref<AllMemberCheckTimeParams>({
      date_check: currentDate,
      unit_ids: [...unitIdInit.value],
      pp_employee_ids: [...employeeId.value],
    });

    const { membersCheckTime, getAllMember } = useAllMemberCheckTime(
      paramsSearchMembers.value
    );
    const allMembersHeaders = [
      {
        text: i18n.t('stampRegister.stampType'),
        value: 'check_time_type',
      },
      {
        text: i18n.t('stampPage.stampingTime'),
        value: 'check_time',
      },
      {
        text: i18n.t('fullName'),
        value: 'employee_name',
      },
      {
        text: i18n.t('department'),
        value: 'unit_name',
      },
      {
        text: `${i18n.t('dailyAttendanceHeaders.organization')}`,
        value: 'check_time_unit_name',
      },
      {
        text: `${i18n.t('stampPage.stampingPoint')}`,
        value: 'location',
      },
    ];
    const stampTypeList = [
      i18n.t('checkin'),
      i18n.t('checkout'),
      i18n.t('stampRegister.goOut'),
      i18n.t('stampRegister.return'),
      i18n.t('stampRegister.breakStart'),
      i18n.t('stampRegister.breakEnd'),
    ];

    getAllMember(paramsSearchMembers.value);
    const isAllParams = ref(true);
    const { unitList } = useUnitApi({
      is_all: isAllParams.value,
    });
    const optionListEmployee = ref<{ value: string; text: string }[]>([]);
    getSearchEmploy().then((res) => {
      optionListEmployee.value = res.data.map((el) => {
        return {
          value: el.id,
          text: el.name,
          url: el.photo,
          name_face_image: el.name_face_image,
        };
      });
    });
    const handleSearchMembers = () => {
      if (paramsSearchMembers.value.date_check === '') {
        paramsSearchMembers.value.date_check = currentDate;
      }
      router.push({
        path: `/new${ConstantsRouter.AllMemberCheckTime}`,
        query: {
          unit_ids: paramsSearchMembers.value.unit_ids,
          date_check: paramsSearchMembers.value.date_check,
          pp_employee_ids: paramsSearchMembers.value.pp_employee_ids,
        },
      });
      const paramSearch = {
        unit_ids: paramsSearchMembers.value.unit_ids,
        date_check: paramsSearchMembers.value.date_check,
        pp_employee_ids: paramsSearchMembers.value.pp_employee_ids,
      };
      if (
        JSON.stringify(paramSearch) ===
        JSON.stringify(router.currentRoute.value.query)
      ) {
        getAllMember(paramsSearchMembers.value);
      }
      resetCount.value++;
      showSearchDetail.value = false;
    };

    watch(
      () => router.currentRoute.value.query,
      () => {
        getAllMember(paramsSearchMembers.value);
        setParamsSearchFromRouter();
      }
    );

    const showSearchRequired = () => {
      showSearchDetail.value = !showSearchDetail.value;
    };
    const closeSearchDetail = () => {
      paramsSearchMembers.value = {
        date_check: router.currentRoute.value.query.date_check as string,
        unit_ids: [...unitIdInit.value],
        pp_employee_ids: [...employeeId.value],
      };
      resetCount.value++;
      showSearchDetail.value = false;
    };
    const resetSearchInput = () => {
      paramsSearchMembers.value = {
        date_check: currentDate,
        unit_ids: [unitId],
        pp_employee_ids: [],
      };
      resetCount.value++;
      router.push({
        path: `/new${ConstantsRouter.AllMemberCheckTime}`,
        query: {
          date_check: currentDate,
          unit_ids: [unitId],
        },
      });
    };
    const unitNames = computed(() =>
      unitList.value
        .filter((item) =>
          paramsSearchMembers.value.unit_ids.includes(item.unit_id)
        )
        .map((item) => item.unit_name)
    );
    const CHECK_TIME_TYPE = {
      CHECK_IN: 0,
      CHECK_OUT: 1,
      GO_OUT: 2,
      RETURN: 3,
      BREAK_START: 4,
      BREAK_END: 5,
    };
    const STATUS = {
      CHECKIN_REMOTE: 'checkin-remote',
      CHECKIN_OFFICE: 'checkin-office',
      CHECKIN: 'checkin',
      CHECK_OUT: 'checkout',
      GO_OUT: 'go-out',
      RETURN: 'return',
      BREAK_START: 'break-start',
      BREAK_END: 'break-end',
    };
    watchEffect(async () => {
      if (personalApplySetting.state.data?.working_style_id) {
        await getDetailData(personalApplySetting.state.data?.working_style_id);
      }
    });
    const convertStatus = (check_time_type, is_remote) => {
      let classStyle = '';
      if (check_time_type === CHECK_TIME_TYPE.CHECK_IN) {
        if (is_remote) classStyle = STATUS.CHECKIN_REMOTE;
        else if (workingStyleMasterDetail.value?.is_distinguishing_workplace) {
          classStyle = STATUS.CHECKIN_OFFICE;
        } else {
          classStyle = STATUS.CHECKIN;
        }
      }
      if (check_time_type === CHECK_TIME_TYPE.CHECK_OUT)
        classStyle = STATUS.CHECK_OUT;
      if (check_time_type === CHECK_TIME_TYPE.GO_OUT)
        classStyle = STATUS.GO_OUT;
      if (check_time_type === CHECK_TIME_TYPE.RETURN)
        classStyle = STATUS.RETURN;
      if (check_time_type === CHECK_TIME_TYPE.BREAK_START)
        classStyle = STATUS.BREAK_START;
      if (check_time_type === CHECK_TIME_TYPE.BREAK_END)
        classStyle = STATUS.BREAK_END;
      return classStyle;
    };
    const getNameEmployee = (id) => {
      return (
        optionListEmployee.value.filter((item) => item.value == id)[0]?.text ||
        ''
      );
    };
    const heightRequestSearch = computed(() => {
      if (searchRequest.value) {
        return searchRequest.value.getBoundingClientRect().height;
      }
      return 0;
    });
    const searchRequest = ref<HTMLDivElement>();
    return {
      t,
      returnScreen,
      isAllParams,
      unitList,
      allMembersHeaders,
      paramsSearchMembers,
      handleSearchMembers,
      showSearchDetail,
      showSearchRequired,
      closeSearchDetail,
      resetSearchInput,
      unitNames,
      membersCheckTime,
      currentDate,
      stampTypeList,
      TYPE_FALSE,
      TYPE_TRUE,
      convertStatus,
      STATUS,
      optionListEmployee,
      MAX_UNIT_SHOW,
      getNameEmployee,
      heightRequestSearch,
      searchRequest,
      resetCount,
    };
  },
});
