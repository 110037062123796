
import './button.scss';
import { computed } from 'vue';

interface PropTypes {
  loading: boolean;
  label: string;
  size: string;
  disabled: boolean;
  variant: string;
}
export default {
  props: {
    label: {
      type: String,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      validator: function (value: string) {
        return ['small', 'medium', 'large'].indexOf(value) !== -1;
      },
      default: 'medium',
    },
    disabled: {
      type: Boolean,
    },
    variant: {
      type: String,
      validator: function (value: string) {
        return (
          [
            'primary',
            'secondary',
            'success',
            'warning',
            'danger',
            'info',
            'light',
            'dark',
            'outline-primary',
            'outline-secondary',
            'outline-success',
            'outline-warning',
            'outline-danger',
            'outline-info',
            'outline-light',
            'outline-dark',
          ].indexOf(value) !== -1
        );
      },
      default: 'primary',
    },
  },
  emits: ['click', 'mousedown'],
  setup(props: Partial<PropTypes>, { emit }) {
    return {
      classes: computed(() => ({
        'sys-btn': true,
        // style for variants
        'sys-btn-primary': props.variant === 'primary',
        'sys-btn-secondary': props.variant === 'secondary',
        'sys-btn-success': props.variant === 'success',
        'sys-btn-warning': props.variant === 'warning',
        'sys-btn-danger': props.variant === 'danger',
        'sys-btn-info': props.variant === 'info',
        'sys-btn-outline-primary': props.variant === 'outline-primary',
        'sys-btn-outline-secondary': props.variant === 'outline-secondary',
        'sys-btn-outline-success': props.variant === 'outline-success',
        'sys-btn-outline-warning': props.variant === 'outline-warning',
        'sys-btn-outline-danger': props.variant === 'outline-danger',
        'sys-btn-outline-info': props.variant === 'outline-info',

        // style for size
        'sys-btn-small': props.size === 'small',
        'sys-btn-medium': props.size === 'medium',
        'sys-btn-large': props.size === 'large',

        // style for disabled
        'sys-btn-disabled': props.disabled,
      })),
      onClick() {
        emit('click');
      },
      onMouseDown() {
        emit('mousedown');
      },
    };
  },
};
