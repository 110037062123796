
import { ref, toRefs } from 'vue';

export default {
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: '',
    },
    persistent: {
      type: Boolean,
      default: false,
    },
    noScrollBody: {
      type: Boolean,
      default: false,
    },
    classes: {},
  },
  setup(props, { emit }) {
    const dialogContent = ref<HTMLDivElement>();
    const { persistent } = toRefs(props);
    const onclickOutside = (e) => {
      if (persistent.value && !dialogContent.value?.contains(e.target)) {
        emit('update:modelValue', false);
      }
    };
    return {
      dialogContent,
      onclickOutside,
    };
  },
};
