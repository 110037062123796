
import { defineComponent, ref, toRefs, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import Select from '@/components/share/Dropdown/Dropdown.vue';
import RadioGroup from '@/components/share/Radio/RadioGroup.vue';
import Checkbox from '@/components/share/Checkbox/CheckboxInput.vue';
import Input from '@/components/share/Input/index.vue';
import { WorkingStyleMasterParams } from '@/model/WorkingStyleMaster';
import { isEmpty } from '@/util/useValidateForm';
import {
  AUTO_BREAK_AND_SHIFT_TIME_OVERLAP_TYPE,
  BREAK_TYPE,
  BREAK_WITH_CHECK_TIME_TYPE,
  CHECK_TIME_AND_SHIFT_TIME_OVERLAP_TYPE,
  MIDNIGHT_DAY_TYPE,
  PRIORITY_BREAK_TYPE,
  STANDARD_AUTOMATIC_BREAKS_MAX,
  STANDARD_BREAK_AND_SHIFT_TIME_OVERLAP_TYPE,
  USING_BREAK_TIME,
} from '@/constants/workingStyleMaster';
import { watchEffect } from '@vue/runtime-core';
import ErrorMessage from '@/components/share/Input/ErrorMessage.vue';

export default defineComponent({
  components: { ErrorMessage, Input, Checkbox, RadioGroup, Select },
  props: {
    modelValue: {
      default: {},
    },
    validated: {
      default: false,
    },
    errorServer: {
      default: '',
    },
    errors: {
      default: {},
    },
  },
  setup(props) {
    const i18n = useI18n();
    const { modelValue } = toRefs(props);
    const typeBreakList = [
      {
        value: BREAK_TYPE.BREAK_AUTOMATICALLY,
        label: i18n.t('automaticBreak'),
      },
      {
        value: BREAK_TYPE.WORK_PATTERN_BREAK,
        label: i18n.t('breakOfWordPattern'),
      },
    ];
    const typeBreakStampedList = [
      {
        value: BREAK_TYPE.BREAK_AUTOMATICALLY,
        label: i18n.t('automaticBreak'),
      },
      {
        value: BREAK_TYPE.WORK_PATTERN_BREAK,
        label: i18n.t('breakOfWordPattern'),
      },
    ];
    const typeBreakForStampBreakList = [
      {
        key: BREAK_WITH_CHECK_TIME_TYPE.BREAK_TIME,
        value: i18n.t('stampedBreakAsBreakTime'),
      },
      {
        key: BREAK_WITH_CHECK_TIME_TYPE.SUM_OF_THE_TIME_BREAKS,
        value: i18n.t('totalBreakTimeIsBreakTime'),
      },
    ];
    const autoBreakAndPatternBreaksOverlapList = [
      {
        key: AUTO_BREAK_AND_SHIFT_TIME_OVERLAP_TYPE.AUTO_BREAKS_IS_BREAK,
        value: i18n.t('automaticBreak'),
      },
      {
        key: AUTO_BREAK_AND_SHIFT_TIME_OVERLAP_TYPE.WORK_PATTERNS_IS_BREAK,
        value: i18n.t('breakOfWordPattern'),
      },
      {
        key: AUTO_BREAK_AND_SHIFT_TIME_OVERLAP_TYPE.WORK_PATTERNS_NOT_SET,
        value: i18n.t('wordPatternBreakNotSet'),
      },
    ];
    const standardWorkAndPatternOverlapList = [
      {
        key:
          STANDARD_BREAK_AND_SHIFT_TIME_OVERLAP_TYPE.BREAKS_FOR_STANDARD_WORK,
        value: i18n.t('breakTimeForStandardWork'),
      },
      {
        key:
          STANDARD_BREAK_AND_SHIFT_TIME_OVERLAP_TYPE.BREAKS_FOR_WORK_PATTERNS,
        value: i18n.t('workPatternsAsBreaks'),
      },
    ];
    const stampBreakAndPatternOverlapList = [
      {
        key: CHECK_TIME_AND_SHIFT_TIME_OVERLAP_TYPE.TIMED_BREAK,
        value: i18n.t('stampedBreak'),
      },
      {
        key: CHECK_TIME_AND_SHIFT_TIME_OVERLAP_TYPE.WORK_PATTERN_AS_BREAK,
        value: i18n.t('workPatternsAsBreaks'),
      },
      {
        key:
          CHECK_TIME_AND_SHIFT_TIME_OVERLAP_TYPE.SUM_OF_BREAK_AND_BREAK_IN_WORK_PATTERN,
        value: i18n.t('sumTimeStampedBreakAndWorkPatternBreak'),
      },
    ];
    const priorityList = [
      {
        key: PRIORITY_BREAK_TYPE.PRESCRIBED_NON_PRESCRIBED_MIDNIGHT,
        value: i18n.t('priorityBreakList.normalLateToNight'),
      },
      {
        key: PRIORITY_BREAK_TYPE.SCHEDULED_MIDNIGHT_NON_SCHEDULED,
        value: i18n.t('priorityBreakList.lateNightToNormal'),
      },
      //* Current at not used to, Ph1.5 will use or later
      // {
      //   key: PRIORITY_BREAK_TYPE.PRESCRIBED_NON_PRESCRIBED_MIDNIGHT,
      //   value: i18n.t('priorityBreakList.one'),
      // },
      // {
      //   key: PRIORITY_BREAK_TYPE.SCHEDULED_MIDNIGHT_NON_SCHEDULED,
      //   value: i18n.t('priorityBreakList.two'),
      // },
      // {
      //   key: PRIORITY_BREAK_TYPE.MIDNIGHT_SCHEDULED_NON_SCHEDULED,
      //   value: i18n.t('priorityBreakList.three'),
      // },
      // {
      //   key: PRIORITY_BREAK_TYPE.MIDNIGHT_OVERTIME_MIDNIGHT,
      //   value: i18n.t('priorityBreakList.four'),
      // },
      // {
      //   key: PRIORITY_BREAK_TYPE.NON_SCHEDULED_PRESCRIBED_MIDNIGHT,
      //   value: i18n.t('priorityBreakList.five'),
      // },
      // {
      //   key: PRIORITY_BREAK_TYPE.NON_SCHEDULED_MIDNIGHT_PRESCRIBED,
      //   value: i18n.t('priorityBreakList.six'),
      //* },
    ];
    const stampingBreakList = [
      { value: USING_BREAK_TIME.USE, label: i18n.t('use') },
      { value: USING_BREAK_TIME.NOT_USE, label: i18n.t('notUse') },
    ];
    const tomorrowOption = [
      { text: i18n.t('onTheDay'), value: MIDNIGHT_DAY_TYPE.ON_DAY },
      { text: i18n.t('beforeDay'), value: MIDNIGHT_DAY_TYPE.BEFORE_DAY },
      { text: i18n.t('nextDay'), value: MIDNIGHT_DAY_TYPE.NEXT_DAY },
    ];

    const isAddBreakTime = (index) => {
      return (
        index + 1 < STANDARD_AUTOMATIC_BREAKS_MAX &&
        index + 1 ===
          workingStyleMasterParams.value.working_style_standard_automatic_breaks
            ?.length
      );
    };
    const addBreakTime = (index) => {
      if (index + 1 >= STANDARD_AUTOMATIC_BREAKS_MAX) return;
      workingStyleMasterParams.value.working_style_standard_automatic_breaks?.push(
        {}
      );
    };
    const removeAutomaticBreak = (index) => {
      const length =
        workingStyleMasterParams.value.working_style_standard_automatic_breaks
          ?.length || 0;
      if (length > 1) {
        workingStyleMasterParams.value.working_style_standard_automatic_breaks?.splice(
          index,
          1
        );
      }
      for (let i = index; i < length; i++) {
        errorServerData.value[
          `working_style_standard_automatic_breaks.${i}.working_time`
        ] =
          errorServerData.value[
            `working_style_standard_automatic_breaks.${i + 1}.working_time`
          ];
        errorServerData.value[
          `working_style_standard_automatic_breaks.${i}.break_time`
        ] =
          errorServerData.value[
            `working_style_standard_automatic_breaks.${i + 1}.break_time`
          ];
      }
    };

    const errorServerData = ref({});
    const errorsData = ref({});
    const workingStyleMasterParams = ref<WorkingStyleMasterParams>({});
    workingStyleMasterParams.value = modelValue.value;
    watch(props, () => {
      workingStyleMasterParams.value = modelValue.value;
      errorsData.value = props.errors;
      errorServerData.value = props.errorServer;
    });
    const isRequiredBreakTime = (index) => {
      return (
        workingStyleMasterParams.value.working_style_standard_session_breaks &&
        (!isEmpty(
          workingStyleMasterParams.value.working_style_standard_session_breaks[
            index
          ].break_start_time
        ) ||
          !isEmpty(
            workingStyleMasterParams.value
              .working_style_standard_session_breaks[index].break_end_time
          ) ||
          !isEmpty(
            workingStyleMasterParams.value
              .working_style_standard_session_breaks[index]
              .break_start_time_day_type
          ) ||
          !isEmpty(
            workingStyleMasterParams.value
              .working_style_standard_session_breaks[index]
              .break_end_time_day_type
          ))
      );
    };
    const pad = (str) => {
      if (!str) {
        return '';
      }
      return str && str.length === 1 ? `0${str}` : str || '';
    };

    watchEffect(() => {
      workingStyleMasterParams.value.working_style_standard_automatic_breaks?.forEach(
        (item) => {
          item.working_time = `${pad(item['working_time_hour'])}:${pad(
            item['working_time_minutes']
          )}`;
          item.break_time = `${pad(item['break_time_hour'])}:${pad(
            item['break_time_minutes']
          )}`;
        }
      );
    });

    return {
      t: i18n.t,
      typeBreakList,
      stampingBreakList,
      priorityList,
      errorsData,
      tomorrowOption,
      typeBreakForStampBreakList,
      autoBreakAndPatternBreaksOverlapList,
      standardWorkAndPatternOverlapList,
      typeBreakStampedList,
      stampBreakAndPatternOverlapList,
      workingStyleMasterParams,
      errorServerData,
      pad,
      BREAK_TYPE,
      AUTO_BREAK_AND_SHIFT_TIME_OVERLAP_TYPE,
      USING_BREAK_TIME,
      isRequiredBreakTime,
      addBreakTime,
      removeAutomaticBreak,
      isAddBreakTime,
    };
  },
});
