import api, { ApiResponse } from '@/clients/api';
import { ErrorCode } from './types/general/ErrorCode';
import {
  JSONApiResponseBody,
  JSONApiResponseErrorBody,
} from '@/api/types/general/JSONApiResponse';
import {
  MonthlyClosingParamsId,
  MonthlyClosingParams,
} from '@/api/types/MonthlyClosingDay';
import { MonthlyClosingDayParamsId } from '@/model/MonthlyClosingDay';

export async function deleteMonthlyClosingDay(
  id: MonthlyClosingParamsId
): Promise<
  | ApiResponse<200, JSONApiResponseBody<MonthlyClosingParamsId>>
  | ApiResponse<400, JSONApiResponseErrorBody<ErrorCode>>
  | ApiResponse<500, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.delete(`monthly-closing-day/${id}`);
}

export async function getListMonthlyClosingDay(
  TypeClosingDayMasterApiGetListResult
): Promise<
  | ApiResponse<200, JSONApiResponseBody<MonthlyClosingParamsId[]>>
  | ApiResponse<400, JSONApiResponseErrorBody<ErrorCode>>
  | ApiResponse<500, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.get(`monthly-closing-days`, {
    params: TypeClosingDayMasterApiGetListResult,
  });
}

export async function getListDetailMonthlyClosingDay(
  id: string
): Promise<
  | ApiResponse<200, JSONApiResponseBody<MonthlyClosingParams>>
  | ApiResponse<400, JSONApiResponseErrorBody<ErrorCode>>
  | ApiResponse<500, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.get(`monthly-closing-day/${id}`);
}

export async function postMonthlyClosingDay(
  postData: MonthlyClosingParams
): Promise<
  | ApiResponse<200, JSONApiResponseBody<MonthlyClosingParams[]>>
  | ApiResponse<400, JSONApiResponseErrorBody<ErrorCode>>
  | ApiResponse<500, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.post(`monthly-closing-day`, postData);
}

export async function updateMonthlyClosingDay(
  id: MonthlyClosingDayParamsId,
  data: MonthlyClosingParams
): Promise<
  | ApiResponse<200, JSONApiResponseBody<MonthlyClosingParams[]>>
  | ApiResponse<400, JSONApiResponseErrorBody<ErrorCode>>
  | ApiResponse<500, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.put(`monthly-closing-day/${id}`, data);
}
