import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "p-8 mr-24 ml-24" }
const _hoisted_2 = { class: "config-menu-container" }
const _hoisted_3 = { class: "header" }
const _hoisted_4 = { class: "ml-4 header-text" }
const _hoisted_5 = { class: "submenu-item" }
const _hoisted_6 = { class: "config-menu-container" }
const _hoisted_7 = { class: "header" }
const _hoisted_8 = { class: "ml-4 header-text" }
const _hoisted_9 = { class: "submenu-item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.settingsMenu.slice(0, 3), (items) => {
        return (_openBlock(), _createBlock("div", {
          key: items.text,
          class: "config-menu"
        }, [
          _createVNode("div", _hoisted_3, [
            _createVNode("div", _hoisted_4, _toDisplayString(items.text), 1)
          ]),
          _createVNode("div", _hoisted_5, [
            (_openBlock(true), _createBlock(_Fragment, null, _renderList(items.child, (subItem) => {
              return (_openBlock(), _createBlock("div", {
                key: subItem.text,
                class: "item"
              }, [
                _createVNode("button", {
                  onClick: ($event: any) => (_ctx.goToPage(subItem.path))
                }, _toDisplayString(subItem.text), 9, ["onClick"])
              ]))
            }), 128))
          ])
        ]))
      }), 128))
    ]),
    _createVNode("div", _hoisted_6, [
      (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.settingsMenu.slice(3), (items) => {
        return (_openBlock(), _createBlock("div", {
          key: items.text,
          class: "config-menu"
        }, [
          _createVNode("div", _hoisted_7, [
            _createVNode("div", _hoisted_8, _toDisplayString(items.text), 1)
          ]),
          _createVNode("div", _hoisted_9, [
            (_openBlock(true), _createBlock(_Fragment, null, _renderList(items.child, (subItem) => {
              return (_openBlock(), _createBlock("div", {
                key: subItem.text,
                class: "item"
              }, [
                _createVNode("button", {
                  onClick: ($event: any) => (_ctx.goToPage(subItem.path))
                }, _toDisplayString(subItem.text), 9, ["onClick"])
              ]))
            }), 128))
          ])
        ]))
      }), 128))
    ])
  ]))
}