<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 22C19.3137 22 22 19.3137 22 16C22 12.6863 19.3137 10 16 10C12.6863 10 10 12.6863 10 16C10 19.3137 12.6863 22 16 22Z"
      stroke="#333333"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M22.9575 8.1357C23.2777 8.41924 23.5805 8.72197 23.864 9.04221L27.2793 9.5299C27.8332 10.4943 28.2602 11.5262 28.5498 12.6L26.4805 15.359C26.4805 15.359 26.5065 16.2139 26.4805 16.6408L28.5507 19.4007C28.2603 20.4742 27.8326 21.5058 27.2781 22.4699L23.864 22.9576C23.864 22.9576 23.2779 23.5805 22.9577 23.864L22.47 27.2793C21.5056 27.8332 20.4737 28.2602 19.3999 28.5498L16.6411 26.4805C16.2141 26.5065 15.786 26.5065 15.3591 26.4806L12.5992 28.5507C11.5257 28.2604 10.494 27.8326 9.52999 27.2781L9.04239 23.8642C8.72215 23.5807 8.41942 23.2779 8.13587 22.9577L4.72058 22.47C4.16673 21.5056 3.73971 20.4737 3.45012 19.3999L5.51942 16.6409C5.51942 16.6409 5.4934 15.786 5.51934 15.3591L3.44922 12.5992C3.73955 11.5257 4.16726 10.494 4.72176 9.52999L8.1357 9.0424C8.41924 8.72215 8.72197 8.41942 9.04221 8.13587L9.52991 4.72058C10.4943 4.16673 11.5262 3.73972 12.6 3.45012L15.3588 5.51935C15.7858 5.4934 16.2139 5.4934 16.6408 5.51934L19.4007 3.44922C20.4742 3.73954 21.5058 4.16726 22.4699 4.72176L22.9575 8.1357Z"
      stroke="#333333"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
