import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "tabs-container flex flex-wrap pt-3 p-8 pb-0" }
const _hoisted_2 = { class: "tabs-wrap flex flex-wrap mr-20 ml-20" }
const _hoisted_3 = { class: "flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row" }
const _hoisted_4 = { class: "relative flex flex-col min-w-0 break-words bg-white w-full" }
const _hoisted_5 = { class: "tab-content mr-20 ml-20 mt-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")
  const _component_router_view = _resolveComponent("router-view")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode("ul", _hoisted_3, [
        (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.dataTabs, (item, index) => {
          return (_openBlock(), _createBlock(_component_router_link, {
            class: ["tab-link text-grey-999 text-center cursor-pointer px-5 py-3 rounded block", _ctx.currentPage.path.includes(item.name) ? 'active-page' : ''],
            key: index,
            to: item.path,
            exact: ""
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(item.text), 1)
            ]),
            _: 2
          }, 1032, ["to", "class"]))
        }), 128))
      ])
    ]),
    _createVNode("div", _hoisted_4, [
      _createVNode("div", _hoisted_5, [
        _createVNode(_component_router_view)
      ])
    ])
  ]))
}