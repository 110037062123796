import { LoginData } from '@/model/Login';
import { ref } from 'vue';
import { checkLogin, login } from '@/api/login';
import { showStamp } from '@/router';
import {
  handleCheckAuthen,
  PP_EMPLOYEE_ID,
  UNIT_NAME,
  EMPLOYEE_NAME,
  UNIT_ID,
  saveCookie,
} from '@/util/composables';
import { handleErrorResponse } from '@/clients/api';
import { showToast } from '@/util/useToastMessage';
import { useLoginAccountStore } from '@/store/modules/login-account';
import { usePersonalApplySettingStore } from '@/store/modules/personal-setting-apply';
import { checkPersonalSettingApply } from '@/api/personalSettingApply';
import { showNotFound } from '@/router';
export default function useLogin() {
  const loginList = ref<LoginData>();
  const loading = ref(false);
  async function _login(params, messages, loginFail) {
    try {
      loading.value = true;
      const res = await login(params);

      switch (res.status) {
        case 200:
          if (res.data.data?.status === 'ok') {
            showToast('success', messages.success);
            showStamp();
          } else {
            loginFail();
          }
          loginList.value = res.data.data;
          break;
        default:
          showToast('error', messages.errors);
          break;
      }
    } catch (error) {
      console.error(error);
      handleErrorResponse(error);
    } finally {
      loading.value = false;
    }
  }

  return { loginList, loading, _login };
}

export async function handleUserInfor() {
  const user = ref({});
  const accountStore = useLoginAccountStore();
  const personalApplySetting = usePersonalApplySettingStore();
  try {
    const res = await checkLogin();
    if (res.status !== 200) {
      handleCheckAuthen();
      return null;
    }
    // check personal setting
    const checkSettingApply = await checkPersonalSettingApply({
      pp_employee_id: res.data.data.pp_employee_id,
    });
    if (checkSettingApply.status === 200 && !checkSettingApply.data.data.id) {
      showNotFound();
      return;
    }
    // save data
    saveCookie(PP_EMPLOYEE_ID, res.data.data.pp_employee_id);
    saveCookie(UNIT_NAME, res.data.data.unit_name);
    saveCookie(EMPLOYEE_NAME, res.data.data.employee_name);
    saveCookie(UNIT_ID, res.data.data.unit_id);
    user.value = res.data.data;
    accountStore.commit('setLoginAccount', user.value);
    personalApplySetting.commit(
      'setPersonalApplySetting',
      checkSettingApply.data
    );
    return user;
  } catch (error) {
    handleCheckAuthen();
  }
}
