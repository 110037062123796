
import { defineComponent, ref, toRefs, watchEffect } from '@vue/runtime-core';
import { useI18n } from 'vue-i18n';
import DatePickerIcon from '@/assets/svg/DatePickerIcon.svg';
import { watch } from 'vue';
import Input from '@/components/share/Input/index.vue';
import '@/components/share/Calendar/calendar.scss';

export default defineComponent({
  components: {
    Input,
  },
  props: {
    placeholder: {
      type: String,
    },
    modelValue: {
      type: Number,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isShowChevron: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
    },
    validated: {
      type: Boolean,
      default: false,
    },
    errorServer: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const i18n = useI18n();
    const t = i18n.t;
    const { modelValue } = toRefs(props);
    const isShowCalendar = ref(false);
    const calendarYear = ref<HTMLDivElement>();
    const popupCalendar = ref<HTMLDivElement>();
    const heightCalendar = 234;

    const value = ref(modelValue.value);
    let startYear = ref(
      value.value
        ? value.value - (value.value % 10)
        : new Date().getFullYear() - (new Date().getFullYear() % 10)
    );

    const openCalendar = () => {
      isShowCalendar.value = true;
    };
    const closeCalendar = () => {
      isShowCalendar.value = false;
    };
    const prevYear = () => {
      startYear.value -= 10;
    };
    const nextYear = () => {
      startYear.value += 10;
    };
    const handleBackYear = () => {
      value.value -= 1;
      emit('update:modelValue', value.value);
    };
    const handleNextYear = () => {
      value.value += 1;
      emit('update:modelValue', value.value);
    };
    const year = new Date().getFullYear();

    const onChangeInput = () => {
      if (!isNaN(Number(value.value))) {
        startYear.value = value.value - (value.value % 10);
      }
      emit('update:modelValue', value.value);
    };
    const onSelectYear = (item) => {
      value.value = item;
      emit('update:modelValue', value.value);
      isShowCalendar.value = false;
    };
    watch(props, (newValue) => {
      value.value = newValue.modelValue;
      if (value.value && !isNaN(Number(value.value))) {
        startYear.value = value.value - (value.value % 10);
      }
    });

    watchEffect(() => {
      if (isShowCalendar.value && calendarYear?.value && popupCalendar.value) {
        // show popup calendar
        const distanceElementToBottom =
          window.innerHeight - calendarYear.value.getBoundingClientRect().top;
        if (distanceElementToBottom < heightCalendar) {
          popupCalendar.value.style.bottom = 60 + 'px';
          popupCalendar.value.style.top = 'unset';
        } else {
          popupCalendar.value.style.bottom = 'unset';
          popupCalendar.value.style.top = 50 + 'px';
        }
      }
    });

    return {
      t,
      isShowCalendar,
      value,
      startYear,
      DatePickerIcon,
      year,
      calendarYear,
      popupCalendar,
      prevYear,
      nextYear,
      closeCalendar,
      openCalendar,
      onSelectYear,
      onChangeInput,
      handleBackYear,
      handleNextYear,
    };
  },
});
