export const tooltip = {
  beforeMount: (el, binding) => {
    el.addEventListener('mouseover', function (evt) {
      const targetEl = evt.target;
      const position = binding.arg || '';
      targetEl.classList.add('data-v-tooltip');
      targetEl.setAttribute('data-v-tooltip', binding.value);

      if (position === 'left') {
        targetEl.classList.add('tooltip-location-left');
      } else if (position === 'right') {
        targetEl.classList.add('tooltip-location-right');
      } else if (position === 'bottom') {
        targetEl.classList.add('tooltip-location-bottom');
      }
    });
  },
};
