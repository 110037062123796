import { ref } from 'vue';

import { ShiftPlanSubmit } from '@/api/types/ShiftPlanSubmit';

import { postShiftPlanSubmit } from '@/api/shiftPlanSubmit';

import { convertErrorFromServerToJson } from '@/util/useValidateForm';

export function usePostShiftPlanSubmit(data: ShiftPlanSubmit) {
  const errors = ref({});
  const errorsCommon = ref<string>();
  const loading = ref(false);
  const shiftPlanCreatedId = ref();
  return {
    registered: (async () => {
      loading.value = true;
      const response = await postShiftPlanSubmit(data);
      switch (response.status) {
        case 200:
          loading.value = false;
          shiftPlanCreatedId.value = response.data.data.id;
          return null;
        default:
          if (response.data.errors[0].source) {
            errors.value = convertErrorFromServerToJson(response.data.errors);
          } else {
            errorsCommon.value = response.data.errors[0].title;
          }
          loading.value = false;
          return {
            errors,
            errorsCommon,
          };
      }
    })(),
    shiftPlanCreatedId,
  };
}
