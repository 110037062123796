import { ApplicationApprovalApiPutParams } from '@/model/ApplicationApprovalRequest';
import { convertErrorFromServerToJson } from '@/util/useValidateForm';
import { ref } from 'vue';
import {
  getAllApplicationApproval,
  putApprovalApplicationForm,
  putRejectApplicationForm,
  deleteApplicationForm,
  resendApplicationForm,
} from '../applicationApprovalRequest';

export default function () {
  const membersApplication = ref();
  const pagination = ref({
    current_page: 1,
    per_page: 20,
    total: 0,
  });

  async function getAllApplication(params) {
    try {
      const res = await getAllApplicationApproval(params);
      switch (res.status) {
        case 200:
          if (res.data.meta) {
            pagination.value = res.data.meta.pagination;
          }
          membersApplication.value = res.data.data;
          break;
        default:
          membersApplication.value = [];
          break;
      }
    } catch (error) {
      console.error(error);
    }
  }

  return {
    pagination,
    membersApplication,
    getAllApplication,
  };
}

export function usePutApprovalApplicationReason(
  data: ApplicationApprovalApiPutParams
) {
  const errors = ref({});
  const errorsCommon = ref<string>();
  return {
    updated: (async () => {
      const response = await putApprovalApplicationForm(data);
      switch (response.status) {
        case 200:
          return null;
        default:
          if (response.data.errors[0].source) {
            errors.value = convertErrorFromServerToJson(response.data.errors);
          } else {
            errorsCommon.value = response.data.errors[0].title;
          }
          return {
            errors,
            errorsCommon,
          };
      }
    })(),
  };
}

export function usePutRejectApplicationReason(
  data: ApplicationApprovalApiPutParams
) {
  const errors = ref({});
  const errorsCommon = ref<string>();
  return {
    updated: (async () => {
      const response = await putRejectApplicationForm(data);
      switch (response.status) {
        case 200:
          return null;
        default:
          if (response.data.errors[0].source) {
            errors.value = convertErrorFromServerToJson(response.data.errors);
          } else {
            errorsCommon.value = response.data.errors[0].title;
          }
          return {
            errors,
            errorsCommon,
          };
      }
    })(),
  };
}

export function useDeleteApplication(id: string) {
  const errors = ref({});
  const errorsCommon = ref<string>();
  return {
    deleted: (async () => {
      const response = await deleteApplicationForm(id);
      switch (response.status) {
        case 200:
          return null;
        default:
          if (response.data.errors[0].source) {
            errors.value = convertErrorFromServerToJson(response.data.errors);
          } else {
            errorsCommon.value = response.data.errors[0].title;
          }
          return {
            errors,
            errorsCommon,
          };
      }
    })(),
  };
}

export function useResendApplication(id: string) {
  const errors = ref({});
  const errorsCommon = ref<string>();
  return {
    resend: (async () => {
      const response = await resendApplicationForm(id);
      switch (response.status) {
        case 200:
          return null;
        default:
          if (response.data.errors[0].source) {
            errors.value = convertErrorFromServerToJson(response.data.errors);
          } else {
            errorsCommon.value = response.data.errors[0].title;
          }
          return {
            errors,
            errorsCommon,
          };
      }
    })(),
  };
}
