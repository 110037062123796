export const DISTINGUISHING_HOLIDAY_TYPE = {
  NOT_DISTINGUISHING: false,
  DISTINGUISHING: true,
};
export const MIDNIGHT_DAY_TYPE = {
  ON_DAY: 0,
  BEFORE_DAY: 1,
  NEXT_DAY: 2,
};

export const WORKING_TYPE = {
  STANDARD_LABOR: 0,
  TRANSFORMATION_LABOR: 1,
  DISCRETIONARY_LABOR: 2,
  FLEXTIME_SYSTEM: 3,
  STANDARD_LABOR_WORK_SHIFT: 4,
};
export const WAY_OF_WORKING = {
  FIXED: 0,
  SHIFT: 1,
};
export const USING_BREAK_TIME = {
  NOT_USE: false,
  USE: true,
};
export const BREAK_TYPE = {
  BREAK_AUTOMATICALLY: 0,
  WORK_PATTERN_BREAK: 1,
  STAMP_BREAK: 2,
};

export const PRIORITY_BREAK_TYPE = {
  PRESCRIBED_NON_PRESCRIBED_MIDNIGHT: 0,
  SCHEDULED_MIDNIGHT_NON_SCHEDULED: 1,
  MIDNIGHT_SCHEDULED_NON_SCHEDULED: 2,
  MIDNIGHT_OVERTIME_MIDNIGHT: 3,
  NON_SCHEDULED_PRESCRIBED_MIDNIGHT: 4,
  NON_SCHEDULED_MIDNIGHT_PRESCRIBED: 5,
};
export const STANDARD_BREAK_AND_SHIFT_TIME_OVERLAP_TYPE = {
  BREAKS_FOR_STANDARD_WORK: 0,
  BREAKS_FOR_WORK_PATTERNS: 1,
};

export const AUTO_BREAK_AND_SHIFT_TIME_OVERLAP_TYPE = {
  AUTO_BREAKS_IS_BREAK: 0,
  WORK_PATTERNS_IS_BREAK: 1,
  WORK_PATTERNS_NOT_SET: 2,
};

export const BREAK_WITH_CHECK_TIME_TYPE = {
  BREAK_TIME: 0,
  SUM_OF_THE_TIME_BREAKS: 1,
};
export const CHECK_TIME_AND_SHIFT_TIME_OVERLAP_TYPE = {
  TIMED_BREAK: 0,
  WORK_PATTERN_AS_BREAK: 1,
  SUM_OF_BREAK_AND_BREAK_IN_WORK_PATTERN: 2,
};
export const SETTING_WORKING_DAY_TYPE = {
  WEEKDAYS: 0,
  LEGAL_HOLIDAYS: 1,
  SCHEDULED_HOLIDAYS: 2,
};
export const WAY_CALC_OVERTIME_TYPE = {
  ALL_AS_OVERTIME: 0,
  OVERTIME_AND_NON_STATUTORY_ARE_SEPARATELY: 1,
};
export const WAY_CALC_WEEKLY_OVERTIME_MONTHLY_CLOSING_TYPE = {
  PREVIOUS_MONTH: 0,
  DAILY_BASIS: 1,
};
export const USING_SPECIAL_OVERTIME_TYPE = {
  NOT_USE: false,
  USE: true,
};
export const CALC_NON_STATUTORY_OVERTIME_BY_WEEK_TYPE = {
  NOT_DO: false,
  DO: true,
};
export const WAY_ROUNDING_OVERTIME_BY_WEEK_TYPE = {
  ROUND_DOWN: 0,
  ROUND_UP: 1,
  ROUND_OFF: 2,
};
export const DISTINGUISHING_BETWEEN_OVERTIME_NON_STATUTORY_TYPE = {
  NOT_USE: false,
  USE: true,
};
export const SETTING_REGULAR_WORKING_PER_DAY_TYPE = {
  PRESCRIBED_WORKING: 0,
  WORKING_PATTERN: 1,
  PRESCRIBED_AND_WORKING_PATTERN: 2,
};
export const USING_GO_OUT_CHECK_TIME_TYPE = {
  NOT_USE: false,
  USE: true,
};
export const MINUS_GO_OUT_TIME_IN_WORKING_TIME_TYPE = {
  NOT_USE: false,
  USE: true,
};
export const WAY_ROUNDING_SECONDS_CHECKIN_TYPE = {
  TRUNCATE: 0,
  ROUND_UP: 1,
};
export const WAY_ROUNDING_SECONDS_CHECKOUT_TYPE = {
  TRUNCATE: 0,
  ROUND_UP: 1,
};
export const WAY_ROUNDING_SECONDS_BREAK_START_TYPE = {
  TRUNCATE: 0,
  ROUND_UP: 1,
};
export const WAY_ROUNDING_SECONDS_BREAK_END_TYPE = {
  TRUNCATE: 0,
  ROUND_UP: 1,
};
export const WAY_ROUNDING_MINUTE_CHECKIN_TYPE = {
  TRUNCATE: 0,
  ROUND_UP: 1,
  DO_NOT_ROUND: 2,
};
export const WAY_ROUNDING_MINUTE_CHECKOUT_TYPE = {
  TRUNCATE: 0,
  ROUND_UP: 1,
  DO_NOT_ROUND: 2,
};
export const WAY_ROUNDING_MINUTE_BREAK_START_TYPE = {
  TRUNCATE: 0,
  ROUND_UP: 1,
  DO_NOT_ROUND: 2,
};
export const WAY_ROUNDING_MINUTE_BREAK_END_TYPE = {
  TRUNCATE: 0,
  ROUND_UP: 1,
  DO_NOT_ROUND: 2,
};
export const FIX_CHECKIN_TO_START_SHIFT_TYPE = {
  NOT_FIXED: false,
  FIXED: true,
};
export const FIX_CHECKOUT_TO_END_SHIFT_TYPE = {
  NOT_FIXED: 0,
  FIXED: 1,
  ROUND_UP_TIME: 2,
};
export const ROUNDING_OF_AGGREGATION_TIME_TYPE = {
  NOT_ROUND: false,
  ROUND: true,
};
export const ACHIEVEMENT_ROUNDING_TYPE = {
  TRUNCATE: 0,
  ROUND_UP: 1,
  ROUNDING: 2,
};
export const ACHIEVEMENT_ROUNDING_UNIT_MINUTES_LIST = [5, 10, 25, 30, 60];
export const SPECIAL_OVERTIME_MAX = 3;
export const STANDARD_AUTOMATIC_BREAKS_MAX = 10;
export const WEEK_DAY = {
  SUNDAY: 0,
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6,
  NATIONAL_HOLIDAY: 7,
  HOLIDAY: 8,
  LEGAL_HOLIDAY: 9,
};
