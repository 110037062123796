import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 0,
  class: ""
}
const _hoisted_3 = { class: "font-bold pt-3 pb-1 border-b border-blue-600 mb-4" }
const _hoisted_4 = {
  key: 1,
  class: "flex mt-8 mb-6"
}
const _hoisted_5 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputArea = _resolveComponent("InputArea")
  const _component_Button = _resolveComponent("Button")

  return (_openBlock(), _createBlock(_Fragment, null, [
    (_ctx.isMyApplication == _ctx.APPLICATION_MY_REQUEST)
      ? (_openBlock(), _createBlock("div", _hoisted_1, [
          (_ctx.status == _ctx.ON_GOING && _ctx.enableApproval != 0)
            ? (_openBlock(), _createBlock("div", _hoisted_2, [
                _createVNode("div", _hoisted_3, _toDisplayString(_ctx.t('administratorComments')), 1),
                _createVNode(_component_InputArea, {
                  modelValue: _ctx.approvalMessage,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.approvalMessage = $event)),
                  placeholder: _ctx.t('administratorComments')
                }, null, 8, ["modelValue", "placeholder"])
              ]))
            : _createCommentVNode("", true),
          (_ctx.status == _ctx.ON_GOING && _ctx.enableApproval != 0)
            ? (_openBlock(), _createBlock("div", _hoisted_4, [
                _createVNode(_component_Button, {
                  onClick: _ctx.approveForm,
                  size: "lager",
                  class: "w-32 h-12 text-base mr-4"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t('approve')), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"]),
                _createVNode(_component_Button, {
                  class: "w-32 h-12 reset text-base",
                  onClick: _ctx.rejectForm,
                  size: "lager",
                  variant: "danger"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t('remandFind')), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.isMyApplication == _ctx.APPLICATION_MY_APPROVAL)
      ? (_openBlock(), _createBlock("div", _hoisted_5, [
          (_ctx.status == _ctx.ON_GOING)
            ? (_openBlock(), _createBlock(_component_Button, {
                key: 0,
                class: "w-32 h-12 reset text-base mt-4",
                onClick: _ctx.deleteForm,
                size: "lager",
                variant: "danger"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t('cancelTheApplication')), 1)
                ]),
                _: 1
              }, 8, ["onClick"]))
            : _createCommentVNode("", true),
          (_ctx.status == _ctx.DRAFF)
            ? (_openBlock(), _createBlock(_component_Button, {
                key: 1,
                class: "w-32 h-12 reset text-base mt-4",
                onClick: _ctx.resendApplication,
                size: "lager"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t('reapply')), 1)
                ]),
                _: 1
              }, 8, ["onClick"]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ], 64))
}