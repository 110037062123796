import moment from 'moment';
import { MONTH_FORMAT } from '@/constants/date';
import {
  AUTO_BREAK_AND_SHIFT_TIME_OVERLAP_TYPE,
  BREAK_WITH_CHECK_TIME_TYPE,
  CHECK_TIME_AND_SHIFT_TIME_OVERLAP_TYPE,
  FIX_CHECKOUT_TO_END_SHIFT_TYPE,
  MIDNIGHT_DAY_TYPE,
  PRIORITY_BREAK_TYPE,
  SETTING_REGULAR_WORKING_PER_DAY_TYPE,
  SETTING_WORKING_DAY_TYPE,
  WEEK_DAY,
} from '@/constants/workingStyleMaster';

export type WorkingSchedulesParam = {
  type_of_day?: number;
  setting_working_day?: number;
  shift_pattern_id?: string;
};
export type WorkingStyleStandard = {
  break_time_priority?: number;
  auto_break_and_shift_time_overlap?: number;
  standard_break_and_shift_time_overlap?: number;
  break_with_check_time?: number;
  check_time_and_shift_time_overlap?: number;
  is_shift_pattern_break_time_setting?: boolean;
};
export type WorkingStyleStandardAutomaticBreak = {
  working_time?: string;
  break_time?: string;
};
export type WorkingStyleStandardSessionBreak = {
  session_break_type?: number;
  break_start_time_day_type?: number;
  break_start_time?: string;
  break_end_time_day_type?: number;
  break_end_time?: string;
};
export type WorkingStyleDeformation = {
  way_aggregation?: number;
  way_holiday_aggregation?: number;
  setting_daily_schedule_working_time?: number;
  daily_scheduled_working_minute?: number;
  setting_weekly_scheduled_working_time?: number;
  setting_schedule_for_week?: number;
  weekly_scheduled_working_minute?: number;
  way_calc_week_over_month_closing_day?: number;
  setting_legal_working_time_for_month?: number;
  scheduled_working_time_for_month?: number;
  prescribed_labor_pattern?: number;
};
export type WorkingStyleSpecialOvertime = {
  special_overtime_type?: number;
  special_overtime?: string;
};

export type WorkingStyleMasterParams = {
  working_style_code?: string;
  working_style_name?: string;
  daily_closing_time?: string;
  maximum_working_hours_per_day?: string;
  is_distinguishing_holiday_type?: boolean;
  is_convert_holiday_type?: boolean;
  weekly_closing_day?: number;
  midnight_working_start?: string;
  midnight_start_day_type?: number;
  midnight_working_end?: string;
  midnight_end_day_type?: number;
  is_distinguishing_workplace?: boolean;
  working_type?: number;
  way_of_working?: number;
  is_using_break_time?: boolean;
  break_type?: number;
  working_style_standard?: WorkingStyleStandard;
  working_style_standard_automatic_breaks?: WorkingStyleStandardAutomaticBreak[];
  working_style_standard_session_breaks?: WorkingStyleStandardSessionBreak[];
  working_style_deformation?: WorkingStyleDeformation;
  working_style_schedule_weeks?: WorkingSchedulesParam[];
  way_calc_overtime?: number;
  is_calc_non_statutory_overtime_by_week?: boolean;
  way_calc_weekly_over_monthly_closing_day?: number;
  way_rounding_overtime_by_week?: number;
  is_using_special_overtime?: boolean;
  working_style_special_overtimes?: WorkingStyleSpecialOvertime[];
  way_rounding_seconds_checkin?: number;
  way_rounding_minute_checkin?: number;
  minute_rounding_checkin?: number;
  way_rounding_seconds_checkout?: number;
  way_rounding_minute_checkout?: number;
  minute_rounding_checkout?: number;
  way_rounding_seconds_break_start?: number;
  way_rounding_minute_break_start?: number;
  minute_rounding_break_start?: number;
  way_rounding_seconds_break_end?: number;
  way_rounding_minute_break_end?: number;
  minute_rounding_break_end?: number;
  is_fix_checkin_to_start_shift?: boolean;
  fix_checkout_to_end_shift?: number;
  minute_to_rounding?: number;
  is_rounding_of_aggregation_time?: boolean;
  achievement_rounding_unit_minutes?: number;
  achievement_rounding?: number;
  apply_start_date?: string;
  is_using_go_out_check_time?: boolean;
  is_minus_go_out_time_in_working_time?: boolean;
  way_rounding_seconds_go_out_start?: number;
  way_rounding_seconds_go_out_end?: number;
  way_rounding_minute_go_out_start?: number;
  minute_rounding_go_out_start?: number;
  way_rounding_minute_go_out_end?: number;
  minute_rounding_go_out_end?: number;
  is_distinguishing_overtime_and_non_statutory?: boolean;
  setting_work_time_type?: number;
};

export type WorkingStyleMasterGetList = {
  id: string;
  working_style_code: string;
  working_style_name: string;
  working_style_apply_date: string;
};
export type WorkingStyleMasterGetListParams = {
  month: string;
  year: string;
  keyword: string;
  is_all: boolean;
  page: number;
};

export const WorkingStyleMasterDefault = {
  daily_closing_time_day_type: MIDNIGHT_DAY_TYPE.NEXT_DAY,
  daily_closing_time: '00:00',
  maximum_working_hours_per_day: 20,
  weekly_closing_day: WEEK_DAY.SATURDAY,
  midnight_start_day_type: MIDNIGHT_DAY_TYPE.ON_DAY,
  midnight_working_start: '22:00',
  midnight_end_day_type: MIDNIGHT_DAY_TYPE.NEXT_DAY,
  midnight_working_end: '05:00',
  apply_start_date: moment().format(MONTH_FORMAT),
  is_convert_holiday_type: true,
  is_using_special_overtime: true,
  working_type: 0,
  way_of_working: 0,
  is_using_break_time: true,
  break_type: 0,
  working_style_standard: {
    break_time_priority: PRIORITY_BREAK_TYPE.PRESCRIBED_NON_PRESCRIBED_MIDNIGHT,
    is_shift_pattern_break_time_setting: true,
    auto_break_and_shift_time_overlap:
      AUTO_BREAK_AND_SHIFT_TIME_OVERLAP_TYPE.AUTO_BREAKS_IS_BREAK,
    break_with_check_time: BREAK_WITH_CHECK_TIME_TYPE.BREAK_TIME,
    check_time_and_shift_time_overlap:
      CHECK_TIME_AND_SHIFT_TIME_OVERLAP_TYPE.TIMED_BREAK,
  },
  working_style_standard_automatic_breaks: [{}],
  working_style_standard_session_breaks: [
    { session_break_type: 0 },
    { session_break_type: 1 },
    { session_break_type: 2 },
  ],
  working_style_schedule_weeks: [
    {
      type_of_day: WEEK_DAY.MONDAY,
      setting_working_day: SETTING_WORKING_DAY_TYPE.WEEKDAYS,
    },
    {
      type_of_day: WEEK_DAY.TUESDAY,
      setting_working_day: SETTING_WORKING_DAY_TYPE.WEEKDAYS,
    },
    {
      type_of_day: WEEK_DAY.WEDNESDAY,
      setting_working_day: SETTING_WORKING_DAY_TYPE.WEEKDAYS,
    },
    {
      type_of_day: WEEK_DAY.THURSDAY,
      setting_working_day: SETTING_WORKING_DAY_TYPE.WEEKDAYS,
    },
    {
      type_of_day: WEEK_DAY.FRIDAY,
      setting_working_day: SETTING_WORKING_DAY_TYPE.WEEKDAYS,
    },
    {
      type_of_day: WEEK_DAY.SATURDAY,
      setting_working_day: SETTING_WORKING_DAY_TYPE.SCHEDULED_HOLIDAYS,
    },
    {
      type_of_day: WEEK_DAY.SUNDAY,
      setting_working_day: SETTING_WORKING_DAY_TYPE.SCHEDULED_HOLIDAYS,
    },
    {
      type_of_day: WEEK_DAY.NATIONAL_HOLIDAY,
      setting_working_day: SETTING_WORKING_DAY_TYPE.LEGAL_HOLIDAYS,
    },
  ],
  way_calc_weekly_over_monthly_closing_day: 0,
  working_style_special_overtimes: [
    {
      special_overtime_type: 0,
    },
  ],
  is_calc_non_statutory_overtime_by_week: true,

  way_rounding_seconds_checkin: 0,
  way_rounding_minute_checkin: 0,
  way_rounding_seconds_checkout: 1,
  way_rounding_minute_checkout: 0,
  way_rounding_seconds_break_start: 1,
  way_rounding_minute_break_start: 0,
  way_rounding_seconds_break_end: 0,
  way_rounding_minute_break_end: 0,
  is_fix_checkin_to_start_shift: true,
  fix_checkout_to_end_shift: FIX_CHECKOUT_TO_END_SHIFT_TYPE.FIXED,
  way_rounding_overtime_by_week: 0,
  is_rounding_of_aggregation_time: true,
  minute_rounding_break_end: 5,
  minute_rounding_break_start: 5,
  minute_rounding_checkin: 5,
  minute_rounding_checkout: 5,
  achievement_rounding_unit_minutes: 5,
  achievement_rounding: 0,
  is_distinguishing_holiday_type: true,
  is_distinguishing_workplace: false,
  is_using_go_out_check_time: false,
  is_minus_go_out_time_in_working_time: false,
  way_rounding_seconds_go_out_start: 1,
  way_rounding_seconds_go_out_end: 0,
  way_rounding_minute_go_out_start: 0,
  minute_rounding_go_out_start: 5,
  way_rounding_minute_go_out_end: 0,
  minute_rounding_go_out_end: 5,
  is_distinguishing_overtime_and_non_statutory: true,
  setting_work_time_type:
    SETTING_REGULAR_WORKING_PER_DAY_TYPE.PRESCRIBED_WORKING,
};
