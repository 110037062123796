import { createStore, createLogger } from 'vuex';
import {
  store as auth,
  ProfileStore,
  State as ProfileState,
} from '@/store/modules/auth/index';
import {
  store as root,
  RootIndexStore,
  State as RootIndexStoreState,
} from '@/store/modules/root/index';
import {
  store as shiftPlan,
  ShiftPlanStore,
  State as ShiftPlanStoreState,
} from '@/store/modules/shiftPlan/index';

export type RootState = {
  auth: ProfileState;
  root: RootIndexStoreState;
  shiftPlan: ShiftPlanStoreState;
};

export type Store = ProfileStore<Pick<RootState, 'auth'>> &
  RootIndexStore<Pick<RootState, 'root'>> &
  ShiftPlanStore<Pick<RootState, 'shiftPlan'>>;

// Plug in logger when in development environment
const debug = process.env.NODE_ENV !== 'production';
const plugins = debug ? [createLogger({})] : [];

export const store = createStore({
  plugins,
  modules: {
    auth,
    root,
    shiftPlan,
  },
});

export function useStore(): Store {
  return store as Store;
}
