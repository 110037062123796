import { MutationTree } from 'vuex';
import type { UserProfiles } from '@/types';
import moment from 'moment';
import { State } from './state';
import { ProfileMutationTypes as MutationTypes } from './mutation-types';

export type Mutations<S = State> = {
  [MutationTypes.SET_PROFILE](state: S, payload: UserProfiles): void;
  [MutationTypes.SET_BREAK_START_TIME](state: S, payload): void;
  [MutationTypes.SET_BREAK_END_TIME](state: S, payload): void;
  [MutationTypes.SET_CHECK_IN_TIME](state: S, payload): void;
  [MutationTypes.SET_CHECK_OUT_TIME](state: S, payload): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.SET_PROFILE](state: State, profile: UserProfiles) {
    state.profile = profile;
  },
  [MutationTypes.SET_CHECK_IN_TIME](state: State, payload) {
    state.profile.checkInTime = moment(payload.check_in_time).format(
      'HH:mm:ss'
    );
  },
  [MutationTypes.SET_CHECK_OUT_TIME](state: State, payload) {
    state.profile.checkOutTime = moment(payload.check_out_time).format(
      'HH:mm:ss'
    );
  },
  [MutationTypes.SET_BREAK_START_TIME](state: State, payload) {
    state.profile.breakStartTime = moment(payload.break_start_time).format(
      'HH:mm:ss'
    );
  },
  [MutationTypes.SET_BREAK_END_TIME](state: State, payload) {
    state.profile.breakEndTime = moment(payload.break_end_time).format(
      'HH:mm:ss'
    );
  },
};
