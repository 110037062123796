import { ref } from 'vue';
import {
  deleteMonthlyClosingDay,
  getListDetailMonthlyClosingDay,
  getListMonthlyClosingDay,
  postMonthlyClosingDay,
  updateMonthlyClosingDay,
} from '@/api/monthlyClosingDay';
import { convertErrorFromServerToJson } from '@/util/useValidateForm';

export default function (params) {
  const closingDayMasterData = ref();
  const loading = ref(false);
  const pagination = ref({
    current_page: 1,
    per_page: '',
    total: '',
  });
  const response = ref();

  async function useGetListMonthlyClosingDay(params) {
    try {
      loading.value = true;
      const res = await getListMonthlyClosingDay(params);
      switch (res.status) {
        case 200:
          closingDayMasterData.value = res.data.data;

          response.value = res.data;
          pagination.value = response.value.meta.pagination;
          break;
        default:
          closingDayMasterData.value = [];
          break;
      }
    } catch (error) {
      console.error(error);
    } finally {
      loading.value = false;
    }
  }
  useGetListMonthlyClosingDay(params)
    .then((r) => () => {
      console.log(r);
    })
    .catch((e) => {
      console.log(e);
    });

  return {
    pagination,
    closingDayMasterData,
    loading,
    useGetListMonthlyClosingDay,
  };
}

export function usePostMonthlyClosing(data) {
  const errors = ref({});
  const errorsCommon = ref<string>();
  return {
    postData: (async () => {
      const response = await postMonthlyClosingDay(data);
      switch (response.status) {
        case 200:
          return null;
        default:
          if (response.data.errors[0].source) {
            errors.value = convertErrorFromServerToJson(response.data.errors);
          } else {
            errorsCommon.value = response.data.errors[0].title;
          }
          return {
            errors,
            errorsCommon,
          };
      }
    })(),
  };
}
export function useUpdateMonthlyClosingDay(id, data) {
  const errors = ref({});
  const errorsCommon = ref<string>();
  return {
    update: (async () => {
      const response = await updateMonthlyClosingDay(id, data);
      switch (response.status) {
        case 200:
          return null;
        default:
          if (response.data.errors[0].source) {
            errors.value = convertErrorFromServerToJson(response.data.errors);
          } else {
            errorsCommon.value = response.data.errors[0].title;
          }
      }
      return {
        errors,
        errorsCommon,
      };
    })(),
  };
}

export function useDeleteMonthlyClosingDay(id) {
  return {
    deleteData: (async () => {
      const response = await deleteMonthlyClosingDay(id);
      switch (response.status) {
        case 200:
          return null;
        default:
          return response.data.errors[0].title;
      }
    })(),
  };
}
export function useMonthlyClosingDayDetail() {
  const monthlyClosingDetail = ref();
  const loading = ref(false);

  async function getMonthlyClosingDayDetail(id) {
    try {
      loading.value = true;
      const res = await getListDetailMonthlyClosingDay(id);

      switch (res.status) {
        case 200:
          monthlyClosingDetail.value = res.data.data;
          break;
        case 400:
          break;
        case 500:
          break;
        default:
          monthlyClosingDetail.value = {};
          break;
      }
    } catch (error) {
      console.error(error);
    } finally {
      loading.value = false;
    }
  }
  return {
    monthlyClosingDetail,
    loading,
    getMonthlyClosingDayDetail,
  };
}
