
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  emits: ['click'],
  setup(props, { emit }) {
    const i18n = useI18n();
    const t = i18n.t;
    const handleClickBackPrevious = () => {
      emit('click');
    };
    return { t, i18n, handleClickBackPrevious };
  },
});
