
import { defineComponent, onBeforeMount, onBeforeUnmount, reactive } from 'vue';
import { useI18n } from 'vue-i18n';
import { ref } from '@vue/runtime-core';
import { useStore } from '@/store';
import router from '@/router';
import Plus from '@/assets/svg/Plus.svg';
import Input from '@/components/Input.vue';
import Select from '@/components/Select.vue';
import Table from '@/components/Table.vue';
import Checkbox from '@/components/Checkbox.vue';
import DatePicker from '@/components/DatePicker.vue';
import MainLayoutContentMedium from '@/views/layouts/MainLayoutContentMedium.vue';
import RadioGroup from '@/components/RadioGroup.vue';
import ButtonGreenGradation from '@/components/ButtonGreenGradation.vue';

export default defineComponent({
  components: {
    RadioGroup,
    MainLayoutContentMedium,
    Input,
    Table,
    Checkbox,
    Select,
    DatePicker,
    ButtonGreenGradation,
  },
  setup() {
    const i18n = useI18n();
    const t = i18n.t;
    const store = useStore();

    onBeforeMount(() => {
      store.commit('root/SET_BACK_ROUTER_PATH', '/time-card');
      store.commit('root/SET_CURRENT_PAGE', i18n.t(''));
    });
    onBeforeUnmount(() => {
      store.commit('root/SET_BACK_ROUTER_PATH', '');
      store.commit('root/SET_CURRENT_PAGE', '');
    });

    const optionStampingHeader = [
      {
        text: i18n.t('checkIn'),
        value: 'check_in',
      },
      {
        text: i18n.t('stampRegister.leavingWork'),
        value: 'leaving_work',
      },
      {
        text: i18n.t('stampRegister.breakStart'),
        value: 'break_start',
      },
      {
        text: i18n.t('stampRegister.breakEnd'),
        value: 'break_end',
      },
      {
        text: i18n.t('stampRegister.goOut'),
        value: 'go_out',
      },
      {
        text: i18n.t('stampRegister.goingBack'),
        value: 'going_back',
      },
      {
        text: i18n.t('stampRegister.workPlace'),
        value: 'work_place',
      },
      {
        text: i18n.t('stampRegister.attendanceOrganization'),
        value: 'attendance_organization',
      },
    ];
    const optionStampingData = [
      {
        check_in: '8:55',
        leaving_work: '14:01',
        break_start: '12:00',
        break_end: '13:01',
        go_out: '',
        going_back: '',
        work_place: 'オフィス',
        attendance_organization: '東日本営業本部インサイドセールスチーム',
      },
      {
        check_in: '14:01',
        leaving_work: '18:30',
        break_start: '',
        break_end: '',
        go_out: '15:01',
        going_back: '',
        work_place: 'オフィス',
        attendance_organization: '東日本営業本部インサイドセールスチーム',
      },
      {
        check_in: '14:01',
        leaving_work: '18:30',
        break_start: '',
        break_end: '',
        go_out: '',
        going_back: '',
        work_place: 'オフィス',
        attendance_organization: '東日本営業本部インサイドセールスチーム',
      },
      {
        check_in: '14:01',
        leaving_work: '18:30',
        break_start: '',
        break_end: '',
        go_out: '',
        going_back: '',
        work_place: 'オフィス',
        attendance_organization: '東日本営業本部インサイドセールスチーム',
      },
      {
        check_in: '14:01',
        leaving_work: '18:30',
        break_start: '',
        break_end: '18:02',
        go_out: '',
        going_back: '',
        work_place: 'オフィス',
        attendance_organization: '東日本営業本部インサイドセールスチーム',
      },
      {
        check_in: '14:01',
        leaving_work: '18:30',
        break_start: '',
        break_end: '',
        go_out: '',
        going_back: '16:09',
        work_place: 'オフィス',
        attendance_organization: '東日本営業本部インサイドセールスチーム',
      },
      {
        check_in: '14:01',
        leaving_work: '18:30',
        break_start: '6:30',
        break_end: '11:01',
        go_out: '',
        going_back: '15:10',
        work_place: 'オフィス',
        attendance_organization: '東日本営業本部インサイドセールスチーム',
      },
      {
        check_in: '14:01',
        leaving_work: '18:30',
        break_start: '',
        break_end: '',
        go_out: '19:22',
        going_back: '',
        work_place: 'オフィス',
        attendance_organization: '東日本営業本部インサイドセールスチーム',
      },
      {
        check_in: '14:01',
        leaving_work: '18:30',
        break_start: '',
        break_end: '',
        go_out: '',
        going_back: '',
        work_place: 'オフィス',
        attendance_organization: '東日本営業本部インサイドセールスチーム',
      },
      {
        check_in: '14:01',
        leaving_work: '18:30',
        break_start: '',
        break_end: '17:01',
        go_out: '',
        going_back: '',
        work_place: 'オフィス',
        attendance_organization: '東日本営業本部インサイドセールスチーム',
      },
      {
        check_in: '14:01',
        leaving_work: '18:30',
        break_start: '',
        break_end: '',
        go_out: '',
        going_back: '',
        work_place: 'オフィス',
        attendance_organization: '東日本営業本部インサイドセールスチーム',
      },
      {
        check_in: '14:01',
        leaving_work: '18:30',
        break_start: '',
        break_end: '22:00',
        go_out: '',
        going_back: '',
        work_place: 'オフィス',
        attendance_organization: '東日本営業本部インサイドセールスチーム',
      },
      {
        check_in: '14:01',
        leaving_work: '18:30',
        break_start: '',
        break_end: '',
        go_out: '',
        going_back: '15:09',
        work_place: 'オフィス',
        attendance_organization: '東日本営業本部インサイドセールスチーム',
      },
    ];

    const optionWorkPlace = [
      { value: 0, label: i18n.t('dailyAttendanceHeaders.office') },
      { value: 1, label: i18n.t('dailyAttendanceHeaders.teleWork') },
    ];
    const stampTypeOption = reactive([
      { text: i18n.t('stampRegister.attendanceWork'), value: '' },
      { text: i18n.t('stampRegister.leavingWork'), value: '' },
      { text: i18n.t('stampRegister.breakStart'), value: '' },
      { text: i18n.t('stampRegister.breakEnd'), value: '' },
      { text: i18n.t('stampRegister.goOut'), value: '' },
      { text: i18n.t('stampRegister.goingBack'), value: '' },
    ]);

    const headquarters = reactive([
      { text: i18n.t('stampRegister.attendanceWork'), value: '' },
      { text: i18n.t('stampRegister.leavingWork'), value: '' },
      { text: i18n.t('stampRegister.breakStart'), value: '' },
      { text: i18n.t('stampRegister.breakEnd'), value: '' },
      { text: i18n.t('stampRegister.goOut'), value: '' },
      { text: i18n.t('stampRegister.goingBack'), value: '' },
    ]);
    const stampRegisterHeader = [
      {
        text: i18n.t('stampRegister.stampType'),
        value: 'stamp_type',
      },
      {
        text: i18n.t('stampRegister.stampingDateAndTime'),
        value: '',
      },
      {
        text: i18n.t('stampRegister.attendanceOrganization'),
        value: '',
      },
      {
        text: i18n.t('stampRegister.attendanceOrganization'),
        value: '',
      },
      {
        text: i18n.t('stampRegister.delete'),
        value: '',
      },
      {
        text: i18n.t('stampRegister.applicationMessage'),
        value: '',
      },
      {
        text: i18n.t(''),
        value: '',
      },
    ];
    const optionApprovalHeader = [
      {
        text: i18n.t('overtimeRegister.targetDate'),
        value: 'target_date',
      },
      {
        text: i18n.t('stampRegister.applicationTime'),
        value: 'application_time',
      },
      {
        text: i18n.t('dailyAttendanceHeaders.organization'),
        value: 'organization',
      },
    ];
    const approvalRouteData = [
      {
        target_date: '2021/04/05',
        application_time: '9：04',
        organization: '東日本営業本部インサイドセールスチーム',
      },
      {
        target_date: '2021/04/05',
        application_time: '9：04',
        organization: '東日本営業本部インサイドセールスチーム',
      },
      {
        target_date: '2021/04/05',
        application_time: '9：04',
        organization: '東日本営業本部インサイドセールスチーム',
      },
      {
        target_date: '2021/04/05',
        application_time: '9：04',
        organization: '東日本営業本部インサイドセールスチーム',
      },
      {
        target_date: '2021/04/05',
        application_time: '9：04',
        organization: '東日本営業本部インサイドセールスチーム',
      },
      {
        target_date: '2021/04/05',
        application_time: '9：04',
        organization: '東日本営業本部インサイドセールスチーム',
      },
    ];

    const optionApprovalPrivilegeHeader = [
      {
        text: i18n.t(''),
        value: 'first_approver',
      },
      {
        text: i18n.t('stampRegister.setterAndCommunicator'),
        value: 'setter_and_communicator',
      },
      {
        text: i18n.t('approvedSetting.organization'),
        value: 'organization',
      },
      {
        text: i18n.t('fullName'),
        value: 'full_name',
      },
      {
        text: i18n.t('status'),
        value: 'status',
      },
      {
        text: i18n.t('stampRegister.approvalDateAndTime'),
        value: 'approval_date_time',
      },
      {
        text: i18n.t('stampRegister.comment'),
        value: 'comment',
      },
    ];

    const approvalRoutePrivilegeData = [
      {
        first_approver: '第１承認者',
        setter_and_communicator: '山田　太郎',
        organization: '営業課',
        full_name: '山田　太郎',
        status: '－',
        approval_date_time: '2021/04/22 15:46:45',
        comment: '事前確認済み',
      },
      {
        first_approver: '第１承認者',
        setter_and_communicator: '山田　太郎',
        organization: '営業課',
        full_name: '山田　太郎',
        status: '－',
        approval_date_time: '2021/04/22 15:46:45',
        comment: '事前確認済み',
      },
      {
        first_approver: '第１承認者',
        setter_and_communicator: '山田　太郎',
        organization: '営業課',
        full_name: '山田　太郎',
        status: '－',
        approval_date_time: '2021/04/22 15:46:45',
        comment: '事前確認済み',
      },
      {
        first_approver: '第１承認者',
        setter_and_communicator: '山田　太郎',
        organization: '営業課',
        full_name: '山田　太郎',
        status: '－',
        approval_date_time: '2021/04/22 15:46:45',
        comment: '事前確認済み',
      },
      {
        first_approver: '第１承認者',
        setter_and_communicator: '山田　太郎',
        organization: '営業課',
        full_name: '山田　太郎',
        status: '－',
        approval_date_time: '2021/04/22 15:46:45',
        comment: '事前確認済み',
      },
      {
        first_approver: '第１承認者',
        setter_and_communicator: '山田　太郎',
        organization: '営業課',
        full_name: '山田　太郎',
        status: '－',
        approval_date_time: '2021/04/22 15:46:45',
        comment: '事前確認済み',
      },
      {
        first_approver: '第１承認者',
        setter_and_communicator: '山田　太郎',
        organization: '営業課',
        full_name: '山田　太郎',
        status: '－',
        approval_date_time: '2021/04/22 15:46:45',
        comment: '事前確認済み',
      },
      {
        first_approver: '第１承認者',
        setter_and_communicator: '山田　太郎',
        organization: '営業課',
        full_name: '山田　太郎',
        status: '－',
        approval_date_time: '2021/04/22 15:46:45',
        comment: '事前確認済み',
      },
    ];

    const optionsRadioDefault = [
      {
        value: 0,
        label: t('dailyAttendanceHeaders.office'),
      },
      {
        value: 1,
        label: t('dailyAttendanceHeaders.teleWork'),
      },
    ];
    let paramsAddStampRegister = ref({ stamp_register_details: [{}] });
    const addStampEditing = () => {
      paramsAddStampRegister.value.stamp_register_details.push({});
    };
    const deleteStampEditing = () => {
      if (paramsAddStampRegister.value.stamp_register_details.length <= 1) {
        alert('削除できません');
        //Todo => Action popup
        return false;
      }
      paramsAddStampRegister.value.stamp_register_details.splice(-1);
    };
    const toggleTabs = (item) => {
      router.push(item.path);
    };
    return {
      headquarters,
      t,
      Plus,
      optionWorkPlace,
      stampRegisterHeader,
      optionStampingData,
      paramsAddStampRegister,
      stampTypeOption,
      optionStampingHeader,
      optionsRadioDefault,
      optionApprovalHeader,
      approvalRouteData,
      optionApprovalPrivilegeHeader,
      approvalRoutePrivilegeData,
      toggleTabs,
      addStampEditing,
      deleteStampEditing,
    };
  },
});
