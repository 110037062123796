
import { defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import Table from '@/components/Table.vue';
import Plus from '@/assets/svg/Plus.svg';
import router from '@/router';
import {
  deleteTimezoneGroupID,
  getDataTimezoneGroupListData,
} from '@/api/composables/timezoneGroup';
import { usePopupDeleteStore } from '@/store/modules/popup-delete';
import { ActionType } from '@/util/composables';
import { showToast } from '@/util/useToastMessage';
import ConstantsRouter from '@/constants/router';
import Button from '@/components/Button.vue';
export default defineComponent({
  components: {
    Table,
    Button,
  },
  setup() {
    const i18n = useI18n();
    const t = i18n.t;
    const itemRemove = ref({
      id: '',
      body: '',
      title: '',
      isOpenModal: false,
    });
    const goToTimezoneRegister = () => {
      router.push(ConstantsRouter.TimezoneGroupRegister);
    };
    const storePopupDel = usePopupDeleteStore();
    const showPopupConfirmDel = (item) => {
      storePopupDel.dispatch('show', {
        isShown: true,
        onSubmit: () => deleteAction(item.id),
        item,
      });
    };
    const deleteAction = async (id) => {
      const { deleteData } = deleteTimezoneGroupID(id);
      deleteData
        .then((error) => {
          if (error) {
            showToast('error', t('deteleTimeZoneGroupFailure'));
          } else {
            showToast('success', t('deteleTimeZoneGroupSuccessfully'));
            if (
              timezoneGroupData.value?.length === 1 &&
              apiTimezoneGroupListParams.value.page > 1
            ) {
              apiTimezoneGroupListParams.value.page -= 1;
            }
            getDataTimezoneGroupList(apiTimezoneGroupListParams.value);
          }
        })
        .catch(() => {
          close();
          showToast('error', t('errors.serverError'));
        });
    };

    const apiTimezoneGroupListParams = ref({
      keyword: '',
      page: 1,
    });

    const onChangePage = () => {
      getDataTimezoneGroupList(apiTimezoneGroupListParams.value);
    };
    const {
      timezoneGroupData,
      pagination,
      getDataTimezoneGroupList,
    } = getDataTimezoneGroupListData(apiTimezoneGroupListParams.value);

    const updateTimezoneGroup = (item, actionType) => {
      router.push({
        name: ConstantsRouter.TimezoneGroupUpdate,
        params: { id: item.id },
        query: { action: actionType },
      });
    };

    return {
      t,
      deleteAction,
      showPopupConfirmDel,
      getDataTimezoneGroupList,
      goToTimezoneRegister,
      updateTimezoneGroup,
      onChangePage,
      itemRemove,
      pagination,
      ActionType,
      apiTimezoneGroupListParams,
      timezoneGroupData,
      Plus,
      showAllEmployementTypes: {
        label: t('showAllEmploymentTypes'),
      },

      timeZoneDataHeaders: [
        {
          text: t('timezoneDivisionGroupCode'),
          value: 'time_zone_session_group_code',
          width: '20%',
          align: 'center',
        },
        {
          text: t('timezoneDivisionGroupName'),
          value: 'time_zone_session_group_name',
          width: '52%',
          align: 'center',
        },

        {
          text: i18n.t('attendanceDataHeaders.editAction'),
          value: 'editAction',
          width: '6%',
          background: '#f2f8fc',
        },
        {
          text: i18n.t('attendanceDataHeaders.removeAction'),
          value: 'removeAction',
          width: '6%',
          background: '#f2f8fc',
        },
        {
          text: i18n.t('attendanceDataHeaders.copyAction'),
          value: 'copyAction',
          width: '6%',
          background: '#f2f8fc',
        },
      ],
    };
  },
});
