
import { defineComponent, ref, toRefs, watch } from 'vue';
import Tooltip from '@/components/Tooltip.vue';
import RadioInput from '@/components/share/Radio/RadioInput.vue';

export default defineComponent({
  components: {
    RadioInput,
    Tooltip,
  },
  props: {
    optionsRadio: {
      type: Array,
      default: () => [],
    },
    itemValue: {
      type: String,
      default: 'value',
    },
    modelValue: {
      default: 0,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, ctx) {
    const containerRadio = ref<HTMLDivElement>();
    const { modelValue } = toRefs(props);
    const selected = ref(modelValue.value);
    watch(props, () => {
      selected.value = modelValue.value;
    });
    const actionSubmit = (item) => {
      ctx.emit('update:modelValue', item.value);
      ctx.emit('change', item.value);
    };
    return {
      selected,
      containerRadio,
      actionSubmit,
    };
  },
});
