
import { defineComponent, onBeforeMount, onBeforeUnmount } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from '@/store';
import router from '@/router';
import ConstantsRouter from '@/constants/router';

export default defineComponent({
  props: {
    currentTab: Number,
  },
  setup() {
    const i18n = useI18n();
    const store = useStore();
    const dataTabs = [
      {
        text: i18n.t('shiftRegistration.submitDesired'),
        path: ConstantsRouter.SubmitDesiredShift,
      },
      {
        text: i18n.t('shiftRegistration.myShift'),
        path: '/shift-registration/my-shift',
      },
      {
        text: i18n.t('shiftRegistration.everyoneShift'),
        path: '/shift-registration/everyone-shift',
      },
    ];

    onBeforeMount(() => {
      store.commit('root/SET_CURRENT_PAGE', i18n.t('shiftRegistration.menu'));
    });
    onBeforeUnmount(() => {
      store.commit('root/SET_CURRENT_PAGE', '');
    });
    const toggleTabs = (item) => {
      router.push(item.path);
    };
    const currentPage = router.currentRoute;

    return {
      t: i18n.t,
      dataTabs,
      currentPage,
      toggleTabs,
    };
  },
});
