export const APPLICATION_TYPE = {
  CHECK_TIME_APPLICATION: 0,
  SCHEDULE_APPLICATION: 1,
  VACATION_REQUEST: 2,
  TRANSFER_APPLICATION: 3,
  HOLIDAY_APPLICATION: 4,
  SUBSTITUTE_APPLICATION: 5,
  LATE_EARLY_APPLICATION: 6,
  ABSENCE_APPLICATION: 7,
  WAITING_ALLOWANCE: 8,
};
export const APPLICATION_MY_REQUEST = 1;
export const APPLICATION_MY_APPROVAL = 0;
export const ON_GOING = 1;
export const DRAFF = 0;

export const APPLICATION_STATUS_TYPE = {
  DRAFT: 0,
  ON_GOING: 1,
  FINISHED: 2,
  REJECTED: 3,
  DELETED: 4,
};
export const APPLICATION_STATUS_TEXT_DELETE = '(削除)';

export const APPLICATION_ALLOWANCE_DELETE = '1';
