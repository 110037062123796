import { ref } from 'vue';
import {
  CheckTimeApplicationApiGetResult,
  CheckTimeApplicationApiPostParams,
  CheckTimeApplicationSearchParams,
  EmployeeCheckTimeAndWorkScheduleApiGetResult,
} from '@/api/types/CheckTimeApplicationApi';
import {
  getCheckTimeApplicationDetail,
  getEmployeeChekTimeAndWorkSchedule,
  getCheckTimeApplication,
} from '@/api/checkTimeApplication';
import { convertErrorFromServerToJson } from '@/util/useValidateForm';
import { EmployeeCheckTime } from '@/model/CheckTimeApplication';

export function useEmployeeChekTimeAndWorkSchedule() {
  const employeeCheckTimeAndWorkSchedule = ref<EmployeeCheckTimeAndWorkScheduleApiGetResult>(
    {
      actual_check_time: [],
    }
  );
  const checkTimeApplication = ref<EmployeeCheckTime[]>([]);
  const loading = ref(false);

  async function getEmployeeChekTimeAndWorkScheduleData(
    params: CheckTimeApplicationSearchParams
  ) {
    try {
      loading.value = true;
      const res = await getEmployeeChekTimeAndWorkSchedule(params);
      const response = ref();
      switch (res.status) {
        case 200:
          response.value = res.data;
          employeeCheckTimeAndWorkSchedule.value = response.value.data;
          checkTimeApplication.value.push(
            response.value.data.after_processing_check_time
              .employee_work_schedule,
            response.value.data.after_processing_check_time.employee_check_time
          );
          return employeeCheckTimeAndWorkSchedule.value;
        default:
          return employeeCheckTimeAndWorkSchedule.value;
      }
    } catch (error) {
      console.error(error);
      return {};
    } finally {
      loading.value = false;
    }
  }

  return {
    checkTimeApplication,
    employeeCheckTimeAndWorkSchedule,
    loading,
    getEmployeeChekTimeAndWorkScheduleData,
  };
}

export function useGetCheckTimeApplication(
  data: CheckTimeApplicationApiPostParams
) {
  const errors = ref({});
  const errorsCommon = ref<string>();
  return {
    registered: (async () => {
      const response = await getCheckTimeApplication(data);
      switch (response.status) {
        case 200:
          return null;
        default:
          if (response.data.errors[0].source) {
            errors.value = convertErrorFromServerToJson(response.data.errors);
          } else {
            errorsCommon.value = response.data.errors[0].title;
          }
          return {
            errors,
            errorsCommon,
          };
      }
    })(),
  };
}

export function useDetailCheckTimeApplication() {
  const checkTimeApplicationDetail = ref<CheckTimeApplicationApiGetResult>({
    check_time_detail: [],
  });
  const loading = ref(false);

  async function getCheckTimeApplicationDetailData(id) {
    try {
      loading.value = true;
      const res = await getCheckTimeApplicationDetail(id);
      const response = ref();
      switch (res.status) {
        case 200:
          response.value = res.data;
          checkTimeApplicationDetail.value = response.value.data;
          return checkTimeApplicationDetail.value;
        default:
          checkTimeApplicationDetail.value = { check_time_detail: [] };
          return checkTimeApplicationDetail.value;
      }
    } catch (error) {
      console.error(error);
      return {};
    } finally {
      loading.value = false;
    }
  }

  return {
    checkTimeApplicationDetail,
    loading,
    getCheckTimeApplicationDetailData,
  };
}
