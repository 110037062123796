import api, { ApiResponse } from '@/clients/api';
import { ErrorCode } from './types/general/ErrorCode';
import ConstantsAPI from '@/constants/api';
import {
  JSONApiResponseBody,
  JSONApiResponseErrorBody,
} from './types/general/JSONApiResponse';

import { CheckinTimeKeepingGoOutStartParams } from '@/api/types/TimeKeepingGoOutStart';

export async function postCheckinTimeKeepingGoOutStart(
  postData: CheckinTimeKeepingGoOutStartParams
): Promise<
  | ApiResponse<200, JSONApiResponseBody<CheckinTimeKeepingGoOutStartParams[]>>
  | ApiResponse<400, JSONApiResponseErrorBody<ErrorCode>>
  | ApiResponse<500, JSONApiResponseErrorBody<ErrorCode>>
> {
  return await api.post(ConstantsAPI.TimeKeepingGoOutStart, postData);
}
