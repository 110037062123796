<template>
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M33.4294 36.6673H6.57232C5.68812 36.6672 4.81955 36.4343 4.05385 35.9921C3.28816 35.55 2.65231 34.9141 2.2102 34.1484C1.76808 33.3826 1.53527 32.514 1.53516 31.6299C1.53504 30.7457 1.76762 29.877 2.20953 29.1112L15.6381 5.85271C16.0802 5.08691 16.7162 4.451 17.482 4.00887C18.2479 3.56674 19.1166 3.33398 20.0009 3.33398C20.8852 3.33398 21.7539 3.56674 22.5197 4.00887C23.2855 4.451 23.9215 5.08691 24.3637 5.85271L37.7922 29.1112C38.2341 29.877 38.4667 30.7457 38.4666 31.6299C38.4665 32.514 38.2336 33.3826 37.7915 34.1484C37.3494 34.9141 36.7136 35.55 35.9479 35.9921C35.1822 36.4343 34.3136 36.6672 33.4294 36.6673Z"
      fill="#FC9C95"
      fill-opacity="0.8"
    />
    <path
      d="M20.0007 29.9993C20.9211 29.9993 21.6673 29.2532 21.6673 28.3327C21.6673 27.4122 20.9211 26.666 20.0007 26.666C19.0802 26.666 18.334 27.4122 18.334 28.3327C18.334 29.2532 19.0802 29.9993 20.0007 29.9993Z"
      fill="#ED5D5D"
    />
    <path
      d="M20.0007 23.334C19.5586 23.334 19.1347 23.1584 18.8221 22.8458C18.5096 22.5333 18.334 22.1093 18.334 21.6673V15.0007C18.334 14.5586 18.5096 14.1347 18.8221 13.8221C19.1347 13.5096 19.5586 13.334 20.0007 13.334C20.4427 13.334 20.8666 13.5096 21.1792 13.8221C21.4917 14.1347 21.6673 14.5586 21.6673 15.0007V21.6673C21.6673 22.1093 21.4917 22.5333 21.1792 22.8458C20.8666 23.1584 20.4427 23.334 20.0007 23.334Z"
      fill="#ED5D5D"
    />
  </svg>
</template>
