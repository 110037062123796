
import { computed, defineComponent, onBeforeMount } from 'vue';
import { useI18n } from 'vue-i18n';
import Label from '@/components/Label.vue';
import Button from '@/components/share/Button/index.vue';
import Select from '@/components/Select.vue';
import DatePicker from '@/components/DatePicker.vue';
import Checkbox from '@/components/Checkbox.vue';
import { useStore } from '@/store';
import { ref, reactive, watchEffect } from '@vue/runtime-core';
import { useRoute } from 'vue-router';
import ConstantsRouter from '@/constants/router';
import { DATE_FORMAT } from '@/constants/date';
import { usePopupDeleteStore } from '@/store/modules/popup-delete';
import { IndividualApplicationPolicySettingPostApiParams } from '@/api/types/IndividualApplicationPolicySetting';
import { RoleApiParamSearch } from '@/api/types/Role';
import useListMonthlyClosingDay from '@/api/composables/useMonthlyClosingDay';
import getNationalHolidaysList from '@/api/composables/nationalHolidays';
import { getSearchEmploy } from '@/api/composables/useGetSearchEmployee';
import useGetListSchedulesWorking from '@/api/composables/scheduleWorking';
import { useGetListWorkingStyleMaster } from '@/api/composables/useWorkingStyleMaster';
import { getDataTimezoneGroupListData } from '@/api/composables/timezoneGroup';
import { useGetListRole } from '@/api/composables/useRole';
import { showToast } from '@/util/useToastMessage';
import { isValidateForm } from '@/util/useValidateForm';
import {
  useCreatePersonalSettingApply,
  useGetDetailPersonalSettingApply,
  useDeletePersonalSettingApply,
  useUpdatePersonalSettingApply,
} from '@/api/composables/usePersonalSettingApply';
import router from '@/router';
import moment from 'moment';

export default defineComponent({
  components: {
    Label,
    Button,
    Select,
    DatePicker,
    Checkbox,
  },
  setup() {
    const i18n = useI18n();
    const store = useStore();
    const route = useRoute();
    const storePopup = usePopupDeleteStore();

    const individualApplicationParams = ref<IndividualApplicationPolicySettingPostApiParams>(
      {
        administrative_working_schedule_id: '',
        apply_end_date: '',
        apply_start_date: '',
        is_use_time_zone_session_type: false,
        monthly_closing_day_id: '',
        national_holiday_group_id: '',
        pp_employee_id: '',
        time_zone_session_type_id: '',
        working_style_id: '',
        role_id: '',
      }
    );
    const isCreatePersonal = computed(() => {
      return ConstantsRouter.IndividualApplicationPolicyCreate;
    });
    const isUpdatePersonal = computed(() => {
      return ConstantsRouter.IndividualApplicationPolicySettingUpdate;
    });
    const isCheckButtonDelete = computed(() => {
      return (
        route.path === ConstantsRouter.IndividualApplicationPolicySettingUpdate
      );
    });
    const isCheckDisabled = reactive({
      is_use_time_zone_group: true,
    });
    const currentPath = computed(() => router.currentRoute.value.path);
    const validated = ref(false);
    const isValid = () => {
      validated.value = true;
      return isValidateForm(errors);
    };

    onBeforeMount(() => {
      store.commit('root/SET_BACK_ROUTER_PATH', ConstantsRouter.Settings);
      store.commit('root/SET_CURRENT_PAGE', i18n.t('configuration'));
    });
    const listApprovalEmployee = ref([]);
    getSearchEmploy({}).then((res) => {
      listApprovalEmployee.value = res.data;
    });
    const { closingDayMasterData } = useListMonthlyClosingDay({});
    const { nationalHolidaysList } = getNationalHolidaysList({});
    const { resultScheduleWorking } = useGetListSchedulesWorking({});

    const apiWorkingStyleMasterListParams = ref({
      keyword: '',
      page: 1,
    });
    const { workingStyleMasterData } = useGetListWorkingStyleMaster(
      apiWorkingStyleMasterListParams.value
    );
    const apiTimezoneGroupListParams = ref({
      keyword: '',
      page: 1,
    });
    const { timezoneGroupData } = getDataTimezoneGroupListData(
      apiTimezoneGroupListParams.value
    );

    const roleParams = ref<RoleApiParamSearch>({});
    const { roleData } = useGetListRole(roleParams.value);
    const redirectEmployeeInformation = () => {
      router.push(ConstantsRouter.IndividualApplicationPolicyList);
    };

    const controlTimezoneDivisionGroups = (e) => {
      isCheckDisabled.is_use_time_zone_group = !e.target.checked;
      if (!e.target.checked) {
        individualApplicationParams.value.time_zone_session_type_id = '';
      }
    };

    const storePopupDel = usePopupDeleteStore();
    const deleteItem = () => {
      storePopupDel.dispatch('show', {
        isShown: true,
        onSubmit: () => undefined,
      });
    };
    const errors = ref({});
    const errorsServer = ref({});
    const loadingCreateUpdate = ref(false);
    const creatPersonalSetting = () => {
      const { create } = useCreatePersonalSettingApply(
        individualApplicationParams.value
      );
      create.then((error) => {
        loadingCreateUpdate.value = false;
        if (error) {
          errorsServer.value = error.errors.value;
          if (error?.errorsCommon.value) {
            showToast('error', error?.errorsCommon.value);
          }
        } else {
          showToast(
            'success',
            i18n.t('successMessage.personaSettingApplyCreat')
          );
          router.push(ConstantsRouter.IndividualApplicationPolicyList);
        }
      });
    };
    const handleUpdatePersonalSetting = () => {
      if (!isValid()) {
        return;
      }
      const { update } = useUpdatePersonalSettingApply(
        `${route.query.id}`,
        individualApplicationParams.value
      );
      update
        .then((errors) => {
          if (errors) {
            errorsServer.value = errors.errors.value;
            if (errors?.errorsCommon.value) {
              showToast('error', errors?.errorsCommon.value);
            }
          } else {
            showToast(
              'success',
              i18n.t('successMessage.personaSettingApplyUpdate')
            );
            router.push(ConstantsRouter.IndividualApplicationPolicyList);
          }
        })
        .catch(() => {
          showToast('error', i18n.t('errors.serverError'));
        });
    };
    const getIdDetailPersonalApply = route.query.id;
    const { personalSettingApplyDetail } = useGetDetailPersonalSettingApply(
      getIdDetailPersonalApply
    );
    watchEffect(() => {
      if (
        personalSettingApplyDetail &&
        personalSettingApplyDetail.value &&
        getIdDetailPersonalApply
      ) {
        individualApplicationParams.value = personalSettingApplyDetail.value;
        if (moment(personalSettingApplyDetail.value.apply_start_date).isValid())
          individualApplicationParams.value.apply_start_date = moment(
            personalSettingApplyDetail.value.apply_start_date
          ).format('YYYY/MM/DD');
        if (moment(personalSettingApplyDetail.value.apply_end_date).isValid())
          individualApplicationParams.value.apply_end_date = moment(
            personalSettingApplyDetail.value.apply_end_date
          ).format('YYYY/MM/DD');
      }
    });

    const onDeletePersonalSettingApply = async () => {
      const { deleteData } = useDeletePersonalSettingApply(
        getIdDetailPersonalApply
      );
      deleteData
        .then((error) => {
          if (error) {
            showToast('error', i18n.t('failMessage.monthlyClosingDayFail'));
          } else {
            showToast(
              'success',
              i18n.t('successMessage.personaSettingApplyDelete')
            );
            router.push(ConstantsRouter.IndividualApplicationPolicyList);
          }
          close();
        })
        .catch(() => {
          close();
          store.commit('root/SET_NOTIFICATION', {
            type: 'error',
            message: i18n.t('errors.serverError'),
          });
        });
    };
    const onDeletePersonalSetting = () => {
      storePopup.dispatch('hide');
      onDeletePersonalSettingApply();
    };
    const openDeletePersonalPopup = () => {
      storePopup.dispatch('show', {
        isShown: true,
        onSubmit: onDeletePersonalSetting,
      });
    };

    const handleFnPersonalSetting = () => {
      if (!isValid()) {
        return;
      }
      if (route.path.includes(isCreatePersonal.value)) {
        loadingCreateUpdate.value = true;
        creatPersonalSetting();
      }
      if (route.path.includes(isUpdatePersonal.value)) {
        handleUpdatePersonalSetting();
      }
    };

    return {
      t: i18n.t,
      individualApplicationParams,
      errors,
      closingDayMasterData,
      listApprovalEmployee,
      nationalHolidaysList,
      resultScheduleWorking,
      workingStyleMasterData,
      apiTimezoneGroupListParams,
      apiWorkingStyleMasterListParams,
      roleData,
      currentPath,
      errorsServer,
      loadingCreateUpdate,
      roleParams,
      validated,
      isCheckButtonDelete,
      isCreatePersonal,
      isUpdatePersonal,
      timezoneGroupData,
      DATE_FORMAT,
      isCheckDisabled,
      creatPersonalSetting,
      controlTimezoneDivisionGroups,
      deleteItem,
      redirectEmployeeInformation,
      openDeletePersonalPopup,
      handleFnPersonalSetting,
    };
  },
});
