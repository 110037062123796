<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.25 7.5V19.0986C14.25 19.2221 14.2805 19.3436 14.3387 19.4525C14.397 19.5614 14.4812 19.6542 14.584 19.7226L15.6145 20.4097C15.7145 20.4763 15.829 20.518 15.9485 20.5311C16.068 20.5443 16.1889 20.5285 16.301 20.4852C16.4131 20.4418 16.5131 20.3722 16.5927 20.2821C16.6722 20.192 16.729 20.0841 16.7581 19.9675L18 15"
      stroke="#333333"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M18 15.0004C18.9946 15.0004 19.9484 14.6053 20.6517 13.902C21.3549 13.1987 21.75 12.2449 21.75 11.2504C21.75 10.2558 21.3549 9.30196 20.6517 8.5987C19.9484 7.89544 18.9946 7.50035 18 7.50035H14.25C14.25 7.50035 9.14515 7.50035 4.23271 3.38026C4.12346 3.2883 3.99022 3.22946 3.84866 3.21064C3.70711 3.19182 3.56312 3.21382 3.43364 3.27404C3.30416 3.33426 3.19456 3.4302 3.11774 3.55057C3.04092 3.67095 3.00007 3.81076 3 3.95356V18.5471C3.00007 18.6899 3.04092 18.8297 3.11774 18.9501C3.19456 19.0705 3.30416 19.1664 3.43364 19.2267C3.56312 19.2869 3.70711 19.3089 3.84866 19.2901C3.99022 19.2712 4.12346 19.2124 4.23271 19.1204C9.14515 15.0004 14.25 15.0004 14.25 15.0004H18Z"
      stroke="#333333"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
