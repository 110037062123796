
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';
import Button from '@/components/Button.vue';
import Table from '@/components/Table.vue';
import YearInput from '@/components/YearInput.vue';
import { ref } from '@vue/runtime-core';
import iconExcel from '@/assets/svg/iconExcel.svg';
import { usePopupDeleteStore } from '@/store/modules/popup-delete';
import router from '@/router';
import moment from 'moment';
import getNationalHolidaysList, {
  deleteNationalHolidayID,
} from '@/api/composables/nationalHolidays';
import { showToast } from '@/util/useToastMessage';
import ConstantsRouter from '@/constants/router';
import { ActionType } from '@/util/composables';
export default defineComponent({
  components: { Table, Button, YearInput },
  setup() {
    const i18n = useI18n();

    const holidayHeaders = [
      {
        text: i18n.t('calendarCode'),
        value: 'national_holiday_group_code',
        width: '20%',
      },
      {
        text: i18n.t('calendarName'),
        value: 'national_holiday_group_name',
        width: '60%',
      },
      {
        text: i18n.t('edit'),
        value: 'editAction',
        width: '10%',
        background: '#f2f8fc',
      },
      {
        text: i18n.t('delete'),
        value: 'removeAction',
        width: '10%',
        background: '#f2f8fc',
      },
      {
        text: i18n.t('copy'),
        value: 'copyAction',
        width: '10%',
        background: '#f2f8fc',
      },
    ];
    const optionSelect = [{ text: i18n.t('allAffiliations'), value: 0 }];
    const year = ref(moment().year());
    const optionYear = Array.from({ length: 66 }, (_, i) => i + 1960).reverse();
    const holidaySelected = ref([]);
    const storeDelete = usePopupDeleteStore();
    const currentPage = ref(1);
    const {
      nationalHolidaysList,
      loading,
      getNationalList,
      pagination,
    } = getNationalHolidaysList({
      keyword: year.value,
      page: currentPage.value,
    });

    const openDeleteHolidayPopup = (item) => {
      const isBackPage =
        nationalHolidaysList.value.length === 1 && currentPage.value > 1;
      storeDelete.dispatch('show', {
        isShown: true,
        onSubmit: () => onDeleteHoliday(item.id, isBackPage),
        item,
      });
    };
    const handleHidePopupDelete = () => {
      storeDelete.dispatch('hide');
    };
    const onDeleteHoliday = (id: string, isBackPage) => {
      loading.value = true;
      const { deleteData } = deleteNationalHolidayID(id);
      deleteData.then((error) => {
        if (error) {
          showToast('error', i18n.t('companyHolidayCouldNotDeleted'));
          handleHidePopupDelete();
        } else {
          currentPage.value = isBackPage
            ? currentPage.value - 1
            : currentPage.value;
          showToast('success', i18n.t('companyHolidayDeleted'));
          handleHidePopupDelete();
          handleGetNationalList(year.value, currentPage.value);
        }
      });
    };

    const handleGetNationalList = (year, page) => {
      getNationalList({
        page,
        keyword: year,
      });
    };
    const signUpHoliday = () => {
      router.push(ConstantsRouter.HolidaysSignsUp);
    };
    const handleEdit = (item, action) => {
      router.push({
        name: ConstantsRouter.HolidaysUpdateName,
        params: { id: item.id },
        query: { action: action },
      });
    };
    const handleCopy = (item, action) => {
      router.push({
        name: ConstantsRouter.HolidaysUpdateName,
        params: { id: item.id },
        query: { action: action },
      });
    };
    const selectYear = (value) => {
      currentPage.value = 1;
      handleGetNationalList(value, currentPage.value);
    };
    const onChangePage = () => {
      getNationalList({
        keyword: year.value,
        page: currentPage.value,
      });
    };
    return {
      t: i18n.t,
      holidayHeaders,
      optionYear,
      holidaySelected,
      optionSelect,
      iconExcel,
      year,
      pagination,
      currentPage,
      loading,
      nationalHolidaysList,
      ActionType,
      selectYear,
      getNationalList,
      onChangePage,
      onDeleteHoliday,
      openDeleteHolidayPopup,
      signUpHoliday,
      handleEdit,
      handleCopy,
    };
  },
});
