
import { useLoadingStore } from '@/store/modules/use-loading';
import { computed } from 'vue';
import LoadingIcon from '@/components/Icons/LoadingIcon.vue';
export default {
  components: { LoadingIcon },
  setup() {
    const useLoading = useLoadingStore();
    const isLoading = computed(() => useLoading.state.countApiWaiting > 0);
    return {
      isLoading,
    };
  },
};
