import { ref } from 'vue';
import { showDownload } from '@/util/useDownLoadFile';
import { COMPANY } from '@/router';
import { useLoadingStore } from '@/store/modules/use-loading';

export function useExportShiftPlan() {
  const loading = ref(false);
  const fileName = 'download.csv';
  const useLoading = useLoadingStore();

  async function exportShiftPlanData(id: string) {
    loading.value = true;
    useLoading.commit('increaseCountApiWaiting');
    showDownload(
      `${window.location.origin}/${COMPANY}/api/export-shift-plan-employees/${id}`,
      fileName
    );
    useLoading.commit('decreaseCountApiWaiting');
    loading.value = false;
  }

  return { loading, exportShiftPlanData };
}
