
import { computed, defineComponent, onBeforeMount, onBeforeUnmount } from 'vue';
import { useI18n } from 'vue-i18n';
import router from '@/router';
import { useStore } from '@/store';
import { useApplySettingForSystemStore } from '@/store/modules/apply-setting-for-system';
import {
  FUNCTION_DEFINES_CODE,
  getNavigationMenusByParentId,
} from '@/util/useFunctionDefine';

export default defineComponent({
  setup() {
    const i18n = useI18n();
    const store = useStore();
    const applySettingForSystemStore = useApplySettingForSystemStore();
    const settingsMenu = computed(() => {
      return getNavigationMenusByParentId(
        applySettingForSystemStore.state.functionDefines.function_defines,
        FUNCTION_DEFINES_CODE.SETTINGS
      );
    });

    const goToPage = (page) => {
      router.push({ path: page });
    };
    onBeforeMount(() => {
      store.commit('root/SET_CURRENT_PAGE', i18n.t('configuration'));
    });
    onBeforeUnmount(() => {
      store.commit('root/SET_CURRENT_PAGE', '');
    });

    return {
      t: i18n.t,
      settingsMenu,
      goToPage,
    };
  },
});
