
import { defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import Button from '@/components/share/Button/index.vue';
import { computed } from '@vue/runtime-core';
import { useLoginAccountStore } from '@/store/modules/login-account';
import { useGetShiftPatternList } from '@/api/composables/useShiftPattern';

export default defineComponent({
  components: {
    Button,
  },
  setup() {
    const i18n = useI18n();
    const accountStore = useLoginAccountStore();
    const myAccount = computed(() => accountStore.state.myAccount);

    const isExpandShiftPattern = ref(false);
    const { shiftPatternData, getShiftPatternList } = useGetShiftPatternList();
    getShiftPatternList({ unit_id: myAccount.value.unit_id });

    return {
      t: i18n.t,
      isExpandShiftPattern,
      shiftPatternData,
    };
  },
});
