
import { defineComponent } from '@vue/runtime-core';
import { computed } from 'vue';

export default defineComponent({
  props: {
    label: {
      type: String,
    },
    modelValue: {
      type: Array || String || Boolean || Object || Number,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    labelBold: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Array || String || Boolean || Object || Number,
      default: false,
    },
    color: {
      type: String,
      default: 'default',
    },
    fontSize: {
      type: Number,
      default: 16,
    },
    trueValue: {
      type: Array || String || Boolean || Object || Number,
      default: true,
    },
    falseValue: {
      type: Array || String || Boolean || Object || Number,
      default: false,
    },
  },
  setup(props, ctx) {
    const isSelected = computed(() => {
      if (isModelArray()) {
        return props.modelValue.includes(props.value);
      } else if (props.value) {
        return props.modelValue === props.value;
      }
      return props.modelValue === props.trueValue;
    });
    const isModelArray = () => {
      return Array.isArray(props.modelValue);
    };
    const removeItemFromModel = (newModel) => {
      const index = newModel.indexOf(props.value);
      if (index !== -1) {
        newModel.splice(index, 1);
      }
    };
    const handleArrayCheckbox = () => {
      const newModel = props.modelValue;
      if (!isSelected.value) {
        newModel.push(props.value);
      } else {
        removeItemFromModel(newModel);
      }
      ctx.emit('update:modelValue', newModel);
    };
    const handleSingleSelectCheckbox = () => {
      ctx.emit('update:modelValue', isSelected.value ? null : props.value);
    };
    const handleSimpleCheckbox = () => {
      ctx.emit(
        'update:modelValue',
        isSelected.value ? props.falseValue : props.trueValue
      );
    };

    const toggleCheck = () => {
      if (isModelArray()) {
        handleArrayCheckbox();
      } else if (props.value) {
        handleSingleSelectCheckbox();
      } else {
        handleSimpleCheckbox();
      }
      ctx.emit('change');
    };
    return {
      isSelected,
      removeItemFromModel,
      handleArrayCheckbox,
      handleSingleSelectCheckbox,
      handleSimpleCheckbox,
      isModelArray,
      toggleCheck,
    };
  },
});
