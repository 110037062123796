
import { computed, defineComponent, reactive, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import Input from '@/components/Input.vue';
import Select from '@/components/Select.vue';
import Plus from '@/assets/svg/Plus.svg';
import Checkbox from '@/components/Checkbox.vue';
import router from '@/router';
import Popup from '@/components/Popup.vue';
import Table from '@/components/Table.vue';
import useShiftPattern, {
  useDetailShiftPattern,
  useUpdateShiftPattern,
} from '@/api/composables/useShiftPattern';
import { ShiftPatterns } from '@/model/ShiftPattern';
import { isValidateForm } from '@/util/useValidateForm';
import InputTime from '@/components/InputTime.vue';
import { showToast } from '@/util/useToastMessage';
import useUnitApi from '@/api/composables/useUnit';
import ConstantsRouter from '@/constants/router';
import { watchEffect } from '@vue/runtime-core';
import { UnitApiGetResult } from '@/api/types/UnitApi';
import Label from '@/components/Label.vue';

export default defineComponent({
  components: {
    InputTime,
    Input,
    Select,
    Checkbox,
    Popup,
    Table,
    Label,
  },
  setup() {
    const i18n = useI18n();
    const t = i18n.t;
    const optionsChoseTime = reactive([
      { text: i18n.t('onTheDay'), value: 0 },
      { text: i18n.t('beforeDay'), value: 1 },
      { text: i18n.t('nextDay'), value: 2 },
    ]);
    const valueChoseHaflHoliday = ref(1);
    const valueLateEarly = ref(1);
    const removeTimezoneDetail = (index) => {
      apiPostData.value.shift_pattern_times?.splice(index, 1);
    };
    const isDisableAdd = (item) => {
      return !(
        item &&
        (item.start_time_day_type || item.start_time_day_type === 0) &&
        item.start_time &&
        (item.end_time_day_type || item.end_time_day_type === 0) &&
        item.end_time
      );
    };
    const addTimezoneDetail = (item) => {
      if (isDisableAdd(item)) {
        return;
      }
      apiPostData.value.shift_pattern_times?.push({
        start_time_day_type: item.end_time_day_type,
        start_time: item.end_time,
        end_time_day_type: item.end_time_day_type,
        end_time: item.end_time,
        is_break: item.is_break,
      });
    };
    const onClickName = (item: never) => {
      const index = organizationSelected.value.indexOf(item);
      if (item && index !== -1) {
        organizationSelected.value.splice(index, 1);
      } else {
        organizationSelected.value.push(item);
      }
    };
    const apiPostData = ref<ShiftPatterns>({
      unit_ids: [],
      shift_pattern_times: [
        {
          start_time: '00:00',
          end_time: '00:00',
          is_break: false,
        },
      ],
    });
    const errorsServer = ref({});
    const validated = ref(false);
    const errors = ref({});
    const isValid = () => {
      validated.value = true;
      return isValidateForm(errors);
    };
    const organizationText = computed(() =>
      organizationSelectedConfirm.value
        .map((item) => item['unit_name'])
        .join(', ')
    );
    const submitRegisterShiftPattern = () => {
      if (!isValid()) {
        return;
      }
      const { registered } = useShiftPattern(apiPostData.value);
      registered
        .then((errors) => {
          if (errors) {
            errorsServer.value = errors.errors.value;
            if (errors?.errorsCommon.value) {
              showToast('error', errors?.errorsCommon.value);
            }
          } else {
            showToast('success', t('successMessage.workPatternCreated'));
            router.push(ConstantsRouter.WorkPatternMaster);
          }
        })
        .catch(() => {
          showToast('error', t('errors.serverError'));
        });
    };
    const handleRegisterOrUpdate = () => {
      if (isUpdateAction) {
        submitUpdateShiftPattern();
        return;
      }
      submitRegisterShiftPattern();
    };
    const handleCancel = () => {
      router.push(ConstantsRouter.WorkPatternMaster);
    };
    const organizationSelected = ref<UnitApiGetResult[]>([]);
    const organizationSelectedConfirm = ref<UnitApiGetResult[]>([]);
    const isShow = ref(false);
    const showPopupSelect = () => {
      organizationSelected.value = [...organizationSelectedConfirm.value];
      isShow.value = true;
    };
    const hiddenPopupSelect = () => {
      isShow.value = false;
    };
    const saveSelectedOrganization = () => {
      organizationSelectedConfirm.value = [...organizationSelected.value];
      apiPostData.value.unit_ids = organizationSelectedConfirm.value.map(
        (item) => item['unit_id']
      );
      hiddenPopupSelect();
    };
    const headerDatas = [
      {
        text: '',
        value: 'checkbox',
        width: '20%',
      },
      {
        text: t('orangName'),
        value: 'unit_name',
        width: '80%',
      },
    ];
    const shiftPatternId = router.currentRoute.value.query.id as string;
    const isCopyAction =
      ConstantsRouter.WorkPatternCopy === router.currentRoute.value.path;
    const isUpdateAction = shiftPatternId && !isCopyAction;
    const { shiftPatternDetail, getDetail } = useDetailShiftPattern();
    if (shiftPatternId) {
      getDetail(shiftPatternId);
    }
    const isAllParams = ref(true);
    const { unitList } = useUnitApi({
      is_all: isAllParams.value,
    });
    watchEffect(() => {
      if (shiftPatternId && shiftPatternDetail.value && unitList.value) {
        apiPostData.value = { ...shiftPatternDetail.value };
        organizationSelectedConfirm.value = unitList.value.filter((item) =>
          shiftPatternDetail.value.unit_ids?.includes(item.unit_id)
        );
      }
    });
    const submitUpdateShiftPattern = () => {
      if (!isValid()) {
        return;
      }
      const { update } = useUpdateShiftPattern(
        shiftPatternId,
        apiPostData.value
      );
      update
        .then((errors) => {
          if (errors) {
            errorsServer.value = errors.errors.value;
            if (errors?.errorsCommon.value) {
              showToast('error', errors?.errorsCommon.value);
            }
          } else {
            showToast('success', t('successMessage.workPatternUpdated'));
            router.push(ConstantsRouter.WorkPatternMaster);
          }
        })
        .catch(() => {
          showToast('error', t('errors.serverError'));
        });
    };
    return {
      t,
      Plus,
      optionsChoseTime,
      valueChoseHaflHoliday,
      valueLateEarly,
      apiPostData,
      isShow,
      headerDatas,
      validated,
      errors,
      organizationSelected,
      organizationSelectedConfirm,
      organizationText,
      shiftPatternDetail,
      errorsServer,
      unitList,
      isAllParams,
      isDisableAdd,
      removeTimezoneDetail,
      addTimezoneDetail,
      handleRegisterOrUpdate,
      handleCancel,
      showPopupSelect,
      hiddenPopupSelect,
      isValid,
      saveSelectedOrganization,
      onClickName,
    };
  },
});
