
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    selected: {
      type: Boolean,
      default: false,
    },
    isSelect: {
      type: Boolean,
      default: false,
    },
  },
  components: {},
  setup() {
    return {};
  },
});
