
import { computed, defineComponent, ref, watchEffect } from 'vue';
import { useI18n } from 'vue-i18n';
import Table from '@/components/Table.vue';
import MainLayoutContentMedium from '@/views/layouts/MainLayoutContentMedium.vue';
import GradationButton from '@/components/GradationButton.vue';
import router from '@/router';
import {
  handleUpdateApproveFlowList,
  useGetApproveFlowList,
  useGetListAppFormDefines,
} from '@/api/composables/approvalSettings';
import { ApproveFlowListDatasUpdate } from '@/model/ApproveSetting';
import Button from '@/components/Button.vue';
import { map } from 'lodash';
import { showToast } from '@/util/useToastMessage';
import Checkbox from '@/components/Checkbox.vue';
import ConstantsRouter from '@/constants/router';
import useUnitApi from '@/api/composables/useUnit';
import { UnitApiGetResult } from '@/api/types/UnitApi';
export default defineComponent({
  components: {
    GradationButton,
    MainLayoutContentMedium,
    Table,
    Button,
    Checkbox,
  },
  setup() {
    const i18n = useI18n();
    const t = i18n.t;
    const approvedSelected = ref<UnitApiGetResult[] | Record<string, never>>(
      []
    );
    const approvedSelectedDefault = ref<
      UnitApiGetResult[] | Record<string, never>
    >([]);
    const params = ref({
      page: 1,
      is_all: false,
    });
    const {
      handleGetApproveFlowList,
      approveFlowListData,
    } = useGetApproveFlowList({
      page: 1,
      is_all: true,
    });
    const { pagination, unitList, getUnitData } = useUnitApi(params.value);
    const keyEl = ref(0);
    watchEffect(() => {
      keyEl.value += 1;
      const listSelected = unitList.value
        ? [...unitList.value].filter(
            (el) =>
              !map(approveFlowListData.value, 'unit_id')?.includes(el.unit_id)
          )
        : [];
      approvedSelected.value = [...listSelected];
      approvedSelectedDefault.value = [...listSelected];
    });

    const redirectApplicationFlowSetting = () => {
      router.push(ConstantsRouter.ApprovedSettingFlow);
    };
    const isDisabledBtnApply = computed(() => {
      return approvedSelectedDefault.value.length === 5;
    });

    const redirectApplicationFlowPersonal = (item) => {
      let idUpdatePersonalFlow: UnitApiGetResult[] = approveFlowListData.value?.filter(
        (el) => {
          return el.unit_id === item.unit_id;
        }
      );
      if (idUpdatePersonalFlow[0]?.unit_workflow_id) {
        router.push({
          path: ConstantsRouter.ApprovedPersonalFlow,
          query: {
            id: idUpdatePersonalFlow[0].unit_workflow_id,
            unitId: item.unit_id,
            defineId: listAppFormDefines.value[0]?.value,
          },
        });
      } else {
        router.push({
          path: ConstantsRouter.ApprovedPersonalFlow,
          query: {
            unitId: item.unit_id,
          },
        });
      }
    };
    const isCheckAll = computed(() => {
      if (unitList.value && approvedSelected.value) {
        return approvedSelected.value.length === unitList.value.length;
      }
      return false;
    });
    const onChangePage = () => {
      getUnitData(params.value);
    };

    const onSelectedAll = () => {
      if (!isCheckAll.value) {
        approvedSelected.value = unitList.value ? unitList.value.slice() : [];
      } else {
        approvedSelected.value = approvedSelectedDefault.value.slice();
      }
    };
    const { listAppFormDefines } = useGetListAppFormDefines();

    const handleApplyList = () => {
      const listIdApprove = map(approvedSelected.value, 'unit_id');
      let cloneApproveFlowListData = approveFlowListData.value
        ? approveFlowListData.value
        : [];

      const application_flow_list: ApproveFlowListDatasUpdate[] = [];
      cloneApproveFlowListData.map((list) => {
        if (listIdApprove.includes(list.unit_id)) {
          application_flow_list.push({
            id: list.unit_workflow_id,
          });
        }
      });

      const { update } = handleUpdateApproveFlowList(application_flow_list);
      update.then((error) => {
        if (error) {
          showToast('error', t('basicFlowCantApplied'));
        } else {
          getUnitData(params.value);
          handleGetApproveFlowList({
            page: 1,
            is_all: true,
          });
          showToast('success', t('basicFlowApplied'));
        }
      });
    };
    const approvedSettingHeaders = [
      {
        text: t('approvedSetting.organizationCode'),
        value: 'unit_code',
        width: '100px',
      },
      {
        text: t('approvedSetting.organizationName'),
        value: 'unit_name',
        width: '50%',
      },
      {
        text: t('approvedSetting.basicFlowApply'),
        value: 'checkbox',
        width: '100px',
      },
      {
        text: t('approvedSetting.individualFlowSetting'),
        value: 'copy',
        width: '100px',
      },
    ];

    return {
      t,
      approvedSettingHeaders,
      approveFlowListData,
      approvedSelected,
      pagination,
      unitList,
      keyEl,
      isDisabledBtnApply,
      approvedSelectedDefault,
      isCheckAll,
      params,
      redirectApplicationFlowSetting,
      handleApplyList,
      handleGetApproveFlowList,
      redirectApplicationFlowPersonal,
      onSelectedAll,
      onChangePage,
      listAppFormDefines,
    };
  },
});
