<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.0625 7.68647L12 3.75L15.9375 7.68647"
      stroke="#007BC3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12 14.2492V3.75195"
      stroke="#007BC3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M20.25 14.25V19.5C20.25 19.6989 20.171 19.8897 20.0303 20.0303C19.8897 20.171 19.6989 20.25 19.5 20.25H4.5C4.30109 20.25 4.11032 20.171 3.96967 20.0303C3.82902 19.8897 3.75 19.6989 3.75 19.5V14.25"
      stroke="#007BC3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
