const ConstantsAPI = {
  Login: '/local/login',
  CheckLogin: '/login-account',
  Sample: '/sample',
  TimeKeepingCheckout: `time-keeping-check-out`,
  TimeKeepingBreakStart: `time-keeping-break-start`,
  TimeKeepingCheckIn: `time-keeping-check-in`,
  TimeKeepingBreakEnd: `time-keeping-break-end`,
  TimeKeepingGoOutStart: `time-keeping-go-out-start`,
  TimeKeepingGoOutEnd: `time-keeping-go-out-end`,
  TimeKeepingTeleworkCommute: 'time-keeping-telework-commute',
  TimeKeepingList: '/time-cards',
  TimeKeepingCurrentStatus: (targetDate) =>
    `/time-keeping-current-status?target_date=${targetDate}`,
  TimeZoneSessions: (id) => `time-zone-session-type/${id}`,
  PostDesiredShiftDetail: `requirement-personnel-register-shift`,
  PostRecallDesiredShiftDetail: 'recall-requirement-register-shift-plan',
  TimeZoneSessionList: 'time-zone-session-types',
  WorkingStylePolicy: (id) => `/working-style-policy/${id}`,
  WorkingStylePolicyList: '/working-style-policy-list',
  NationalHolidaysGetList: 'national-holidays',
  NationalHolidaysGetDetail: (id) => `national-holiday/${id}`,
  NationalHolidaysDelete: (id) => `national-holiday/${id}`,
  NationalHolidaysJp: (year) => `national-holidays.jp/${year}`,
  NationalHolidaysCreate: 'national-holiday',
  NationalHolidaysUpdate: (id) => `national-holiday/${id}`,
  Unit: 'units',
  ApprovalFlowList: 'unit-workflows',
  ApprovalFlowListUpdate: 'remove-unit-workflows',
  DetailAppBasicFlowSetting: (id) => `application-basic-flow-setting/${id}`,
  FormDefines: 'application-defines',
  BasicFlowSettingUpdate: `application-basic-flow-setting`,
  PersonalFlowSetting: `application-personal-flow-setting`,
  PersonalUpdateFlowSetting: (id) => `/application-personal-flow-setting/${id}`,
  WorkingSchedules: (id) => `administrative-working-schedule/${id}`,
  WorkingSchedulesList: `administrative-working-schedules`,
  WorkingSchedulesCreate: `administrative-working-schedule`,
  ShiftPatternCreate: `shift-pattern`,
  ShiftPatternList: 'shift-patterns',
  ShiftPatternDetail: (id) => `shift-pattern/${id}`,
  RoleCreate: `role`,
  RoleList: 'roles',
  RoleDetail: (id) => `role/${id}`,
  TimeZoneGroupCreate: `time-zone-session-group`,
  TimezoneGroupList: `time-zone-session-types`,
  TimeZoneGroupDetail: (id) => `time-zone-session-group/${id}`,
  TimeZoneGroupUpdate: (id) => `/time-zone-session-group/${id}`,
  TimeZoneGroupDelete: (id) => `time-zone-session-group/${id}`,
  AuthorityData: (listRoleId) => `authority?roleIds[]=${listRoleId}`,
  UpdateAuthority: 'authority',
  WorkingStyleMasterCreate: `working-style-master`,
  WorkingStyleMasterList: `working-style-masters`,
  WorkingStyleMasterDetail: (id) => `working-style-master/${id}`,
  PersonalSettingDetail: (idFlow) =>
    `application-personal-flow-setting/${idFlow}`,
  PersonalFlowSettingList: `application-personal-flow-settings`,
  PersonalFlowSettingListFollowUnitId: (unitId) =>
    `application-personal-flow-settings?unit_id=${unitId}`,
  StreetAddressFrom: `https://maps.googleapis.com/maps/api/geocode/json?latlng=`,
  ShiftPlanList: 'shift-plans',
  SettingViewItemList: (id) => `setting-view-time-card/${id}`,
  SettingViewItemPost: `setting-view-time-card`,
  SettingViewItemUpdate: (id) => `setting-view-time-card/${id}`,
  DepartmentSelect: '/units?is_all=true',
  RequirementPersonnelRegisterShiftDetail: (id) =>
    `requirement-personnel-register-shift/${id}`,
  ManagerRegisterShiftForPersonnel: `manager-register-shift-for-personnel`,
  ChangeConfirmationStatus: `change-confirmation-status-shift`,
  ChangeNotificationStatusShift: `change-notification-status-shift`,
  ShiftPlanSubmit: 'shift-plan',
  ShiftPlanEmployee: 'shift-plan-employee',
  ShiftPlanDetail: (id) => `shift-plan/${id}`,
  PersonalSettingApply: `personal-setting-apply`,
  PersonalSettingApplyList: `personal-setting-applies`,
  PersonalSettingApplyListDetail: (id) => `personal-setting-apply/${id}`,
  PersonalSettingApplyCheck: (id) => `employee-personal-setting/${id}`,
  PersonalSettingApplyUpdate: (id) => `personal-setting-apply/${id}`,
  PersonalSettingApplyDelete: (id) => `personal-setting-apply/${id}`,
  DesiredShiftDetail: (id) => `manager-register-shift-for-personnel/${id}`,
  ShiftPlanRequest: 'shift-plan-requests',
  RequestMyShift: `submit-desired-shift`,
  RequestMyShiftDetail: (id) => `employee-register-shift/${id}`,
  ViewConfirmShift: 'view-confirmed-shift',
  ApplySettingForSystem: 'apply-setting-for-systems',
  MembersPreviousMonthDetail: `list-employee-by-month`,
  MyTimeCardByMonthlyList: (id) => `my-timecard-by-monthly/${id}`,
  AllMemberCheckTime: `check-time-members`,
  AllMemberTimeCard: `time-card-members`,
  applicationApproval: `application-form-approvals`,
  ApplicationMyApproval: `application-forms-of-employee`,
  ApplicationBasicFlowSettings: `application-basic-flow-settings`,
  WaitingAllowance: `application-form-waiting-allowance`,
  WaitingAllowanceDetail: (id) => `application-form-waiting-allowance/${id}`,
  RecalculateTimeKeeping: `local/calculate-time-keeping`,
  WorkingScheduleChangeApplication: `application-form-change-work-schedule`,
  WorkingScheduleChangeApplicationDetail: (id) =>
    `application-form-change-work-schedule/${id}`,
  ApprovalApplicationForm: 'approve-application-form',
  RejectApplicationForm: 'reject-application-form',
  ApplicationApproveFlowDetail: (id, unitId) =>
    `unit-application-flow/${id}/${unitId}`,
  EmployeeChekTimeAndWorkSchedule: `employee-check-time-and-work-schedule`,
  CheckTimeApplication: `check-time-application`,
  CheckTimeApplicationDetail: (id) => `check-time-application/${id}`,
  AbsenceApplicationCreat: 'application-form-absence',
  AbsenceApplicationDetail: (applicationFormId) =>
    `application-form-absence/${applicationFormId}`,
  ApplicationApprovalFlowStamps: (id) =>
    `application-approval-flow-stamps/${id}`,
  DeleteApplication: (id) => `cancel-application-form/${id}`,
  ResendApplication: (id) => `resend-application-form/${id}`,
  LateEarlyApplication: `application-form-late-or-early`,
  LateEarlyApplicationDetail: (id) => `application-form-late-or-early/${id}`,
  TransferAttendanceApplication: `exchange-workday-for-holiday`,
  TransferAttendanceApplicationDetail: (id) =>
    `exchange-workday-for-holiday/${id}`,
  SubstituteLeaveApplication: `application-form-substitute-leave`,
  SubstituteLeaveApplicationDetail: (id) =>
    `application-form-substitute-leave/${id}`,
  RegisterIcCardPost: '/ic-card',
  IcCardDelete: (id) => `/ic-card/${id}`,
  IcCardData: '/ic-card-detail',
  PersonalFlowSettingReset: (id) =>
    `/reset-application-basic-flow-setting/${id}`,
  WorkInHolidayApplication: `application-form-work-in-holiday`,
  WorkInHolidayApplicationDetail: (id) =>
    `application-form-work-in-holiday/${id}`,
  ImportShiftPlanDraft: (shiftPlanId) =>
    `import-shift-plan-employees-draft/${shiftPlanId}`,
  ImportShiftPlanDraftGet: (shiftPlanImportId) =>
    `import-shift-plan-employees/${shiftPlanImportId}`,
  ImportShiftPlan: (shiftPlanId, fileId) =>
    `import-shift-plan-employees/${shiftPlanId}/${fileId}`,
  AlertSettingCreate: 'alert-setting',
  AlertSettingDetail: (id) => `alert-setting/${id}`,
  AlertSettingUpdate: (id) => `alert-setting/${id}`,
  TimeRecoderCreate: 'time-recorder',
  TimeRecoderDetail: (unitId) => `time-recorder/${unitId}`,
  TimeRecoderUpdate: (unitId) => `time-recorder/${unitId}`,
  YearPaidHolidayCreate: `year-paid-holiday`,
  YearPaidHoliday: `year-paid-holidays`,
  YearPaidHolidayDetail: (id) => `year-paid-holidays/${id}`,
};
export default ConstantsAPI;
