
import { defineComponent, PropType, ref, toRefs, watch } from 'vue';
import RadioInput from '@/components/share/Radio/RadioInput.vue';
import { watchEffect } from '@vue/runtime-core';

export default defineComponent({
  components: { RadioInput },
  emits: ['update:modelValue', 'change'],
  props: {
    modelValue: {
      type: (null as unknown) as PropType<string | number | null>,
      default: null,
    },
    optionsRadio: {
      type: Array,
      default: () => [],
    },
    vertical: {
      type: Boolean,
      default: false,
    },
    valueDefault: {
      type: (null as unknown) as PropType<string | number | null>,
      default: null,
    },
  },
  setup(props, { emit }) {
    const { modelValue } = toRefs(props);
    const selected = ref(
      props.valueDefault !== null ? props.valueDefault : modelValue.value
    );
    // case auto change
    watch(props, () => {
      if (props.valueDefault === null) selected.value = modelValue.value;
    });
    watchEffect(() => {
      if (props.valueDefault === null)
        emit('update:modelValue', selected.value);
    });

    // case handle change
    watch(
      () => selected.value,
      (old, newVal) => {
        if (old !== newVal) {
          emit('change', selected.value);
        }
      }
    );
    return { selected };
  },
});
