import { ActionTree, ActionContext } from 'vuex';
import { ProfileMutationTypes } from './mutation-types';
import { RootState } from '@/store';
import { postStampApi } from '@/api/stamp';

import { State } from './state';
import { ProfileActionTypes } from './action-types';
import ConstantsAPI from '@/constants/api';

export interface Actions {
  [ProfileActionTypes.SET_CHECK_OUT_TIME_ACTION](
    context: ActionContext<State, RootState>
  ): Promise<unknown>;
  [ProfileActionTypes.SET_CHECK_IN_TIME_ACTION](
    context: ActionContext<State, RootState>
  ): Promise<unknown>;
  [ProfileActionTypes.SET_BREAK_START_TIME_ACTION](
    context: ActionContext<State, RootState>
  ): Promise<unknown>;
  [ProfileActionTypes.SET_BREAK_END_TIME_ACTION](
    context: ActionContext<State, RootState>
  ): Promise<unknown>;
  [ProfileActionTypes.SET_TELEWORK_COMMUTE_ACTION](
    context: ActionContext<State, RootState>
  ): Promise<unknown>;
}

export const actions: ActionTree<State, RootState> & Actions = {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  [ProfileActionTypes.SET_CHECK_OUT_TIME_ACTION]({ commit }) {
    return new Promise((resolve, reject) => {
      postStampApi(ConstantsAPI.TimeKeepingCheckout)
        .then((response) => {
          if (response.status === 200) {
            commit(ProfileMutationTypes.SET_CHECK_OUT_TIME, response.data.data);
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [ProfileActionTypes.SET_CHECK_IN_TIME_ACTION]({ commit }) {
    return new Promise((resolve, reject) => {
      postStampApi(ConstantsAPI.TimeKeepingCheckIn)
        .then((response) => {
          if (response.status === 200) {
            commit(ProfileMutationTypes.SET_CHECK_IN_TIME, response.data.data);
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [ProfileActionTypes.SET_BREAK_START_TIME_ACTION]({ commit }) {
    return new Promise((resolve, reject) => {
      postStampApi(ConstantsAPI.TimeKeepingBreakStart)
        .then((response) => {
          if (response.status === 200) {
            commit(
              ProfileMutationTypes.SET_BREAK_START_TIME,
              response.data.data
            );
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [ProfileActionTypes.SET_BREAK_END_TIME_ACTION]({ commit }) {
    return new Promise((resolve, reject) => {
      postStampApi(ConstantsAPI.TimeKeepingBreakEnd)
        .then((response) => {
          if (response.status === 200) {
            commit(ProfileMutationTypes.SET_BREAK_END_TIME, response.data.data);
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [ProfileActionTypes.SET_TELEWORK_COMMUTE_ACTION]({ commit }) {
    return new Promise((resolve, reject) => {
      postStampApi(ConstantsAPI.TimeKeepingTeleworkCommute)
        .then((response) => {
          if (response.status === 200) {
            commit(ProfileMutationTypes.SET_COMMUTE_ACTION, response.data.data);
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
