
import arrowDown from '@/assets/svg/arrowDown.svg';
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  ref,
  toRefs,
  watchEffect,
} from '@vue/runtime-core';
import { useI18n } from 'vue-i18n';
import { PropType } from 'vue';
import Input from '@/components/share/Input/index.vue';
import DeleteItemSelect from '@/components/Icons/DeleteItemSelect.vue';
import DefaultAvatar from '@/components/Icons/DefaultAvatar.vue';
import ErrorMessage from '@/components/share/Input/ErrorMessage.vue';
import { isEmpty } from '@/util/useValidateForm';

export type DropdownOption = {
  value: string | number | null;
  text: string;
};

export default defineComponent({
  components: { ErrorMessage, Input, DeleteItemSelect, DefaultAvatar },
  props: {
    options: {
      type: Array as PropType<DropdownOption[]>,
      default: () => [],
    },
    modelValue: {
      type: Array,
      default: () => [],
    },
    itemValue: {
      type: String,
      default: 'value',
    },
    itemText: {
      type: String,
      default: 'text',
    },
    itemUrl: {
      type: String,
      default: 'url',
    },
    placeholder: {
      type: String,
    },
    background: {
      type: String,
    },
    height: {
      type: String,
    },
    classes: {
      type: String,
    },
    required: {
      type: Boolean,
      default: false,
    },
    validated: {
      type: Boolean,
      default: false,
    },
    errorServer: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hasIconDefault: {
      type: Boolean,
      default: false,
    },
    fontSize: {
      type: Number,
      default: 16,
    },
    limit: {
      Number,
      default: 0,
    },
  },
  setup(props, { emit }) {
    const selectTags = ref<HTMLHtmlElement>();
    const { itemValue, itemText, modelValue, options, required } = toRefs(
      props
    );
    const i18n = useI18n();
    const t = i18n.t;
    const valueInput = ref('');
    const isShowOption = ref(false);
    const widthOptionSelect = ref();
    const optionsFilter = computed(() => {
      return handleSearchFilter(keySearch.value);
    });
    const keySearch = ref('');

    const toggleSelect = () => {
      keySearch.value = '';
      isShowOption.value = !isShowOption.value;
      widthOptionSelect.value = selectTags.value?.offsetWidth;
    };
    const clickOutside = () => {
      keySearch.value = '';
      valueInput.value = '';
      isShowOption.value = false;
    };
    const removeItemFromModel = (newModel, item) => {
      const index = newModel.indexOf(item);
      if (index !== -1) {
        newModel.splice(index, 1);
      }
    };
    const selectOption = (item) => {
      if (typeof item === 'object') {
        item = item[itemValue.value];
      }
      const newModel = modelValue.value;
      if (newModel.indexOf(item) === -1) {
        newModel.push(item);
      } else {
        removeItemFromModel(newModel, item);
      }
      emit('update:modelValue', newModel);
      emit('change', item, newModel);
      valueInput.value = '';
      isShowOption.value = false;
    };
    const isActive = (item) => {
      if (typeof options.value[0] === 'object')
        return modelValue.value.indexOf(item[itemValue.value]) !== -1;
      return modelValue.value.indexOf(item) !== -1;
    };
    const handleSearchFilter = (key: string) => {
      if (!keySearch.value) {
        return options.value;
      }
      if (typeof options.value[0] === 'object') {
        return options.value.filter(
          (item) =>
            item[itemText.value].includes(key.toLowerCase()) ||
            item[itemText.value].includes(key.toUpperCase())
        );
      }
      return options.value.filter(
        (item) =>
          item.toString().includes(key.toLowerCase()) ||
          item.toString().includes(key.toUpperCase())
      );
    };
    const changeTextSearch = (e) => {
      keySearch.value = e.target.value;
      if (!isShowOption.value) {
        isShowOption.value = true;
      }
    };

    const widthInput = computed(() => {
      return `${valueInput.value.length * 14 + 20}px`;
    });
    const optionSelectedItem = computed(() => {
      if (!options.value[0]) {
        return [];
      }
      // options is object
      if (typeof options.value[0] === 'object')
        return options.value.filter((item) =>
          modelValue.value.includes(item[itemValue.value])
        );
      // option is string | number
      return options.value.filter((item) => modelValue.value.includes(item));
    });

    const errorMessage = ref();
    const checkRequired = () => {
      if (required.value && isEmpty(optionSelectedItem.value)) {
        errorMessage.value = t('errors.required');
        emit('update:errors', t('errors.required'));
      } else {
        errorMessage.value = '';
        emit('update:errors', '');
      }
    };
    watchEffect(() => {
      checkRequired();
    });
    onBeforeUnmount(() => {
      emit('update:errors', '');
    });

    return {
      t,
      arrowDown,
      isShowOption,
      optionSelectedItem,
      optionsFilter,
      selectTags,
      valueInput,
      widthInput,
      widthOptionSelect,
      errorMessage,
      checkRequired,
      changeTextSearch,
      clickOutside,
      isActive,
      selectOption,
      toggleSelect,
    };
  },
});
